import { useParams } from 'react-router'
import { useHasStudentStartedAssignment } from 'app/frontend/pages/material/teach/compositions/connected/has-student-started-assignment'
import { useCheckInstructorAccessForCourse } from 'app/frontend/pages/material/teach/compositions/connected/check-instructor-access-for-course'
import { mapLoadingAndErrorToProps, RenderProps } from 'app/frontend/helpers/apollo/adopt'
import type { ApolloError } from '@apollo/client'
import { useAssignment } from 'app/frontend/pages/material/teach/compositions/connected/get-assignment'
import { ParentEntityType } from 'app/typings/commons'

export type useAssessmentBuilderPermissionStateResults = RenderProps<{
  hasStudentStartedAssignment: boolean
  hasEditPermission: boolean
  isCourseAssignmentOnSection: boolean
  isReadOnly: boolean
  parentEntityType: string
  error: ApolloError
  loading: boolean
}>

/**
 * This hook relies on the URL params to determine if the assessment can be edited.
 */

export const useAssessmentBuilderPermissionState = (): useAssessmentBuilderPermissionStateResults => {
  const { courseId, sectionId, assessmentId } = useParams()

  const hasStudentStartedAssignmentData = useHasStudentStartedAssignment(assessmentId)

  const assessmentData = useAssignment(assessmentId)

  /**
   * Don't need check permissions on section level assessments.
   * As a fact both section and course level instructors have edit permission on section level assessments.
   */
  const permissionData = useCheckInstructorAccessForCourse(courseId, {
    skip: !courseId,
  })

  const {
    loading,
    error,
    assessmentData: { assignment: assessment },
    permissionData: { hasAccess },
    hasStudentStartedAssignmentData: { hasStudentStartedAssignment },
  } = mapLoadingAndErrorToProps({
    assessmentData,
    permissionData,
    hasStudentStartedAssignmentData,
  })

  const isCourseAssignmentOnSection = !!(sectionId && assessment?.courseId)
  const parentEntityType = sectionId ? ParentEntityType.Section : ParentEntityType.Course
  const hasEditPermission = hasAccess || !!assessment?.sectionId

  return {
    error,
    loading,
    hasStudentStartedAssignment,
    hasEditPermission,
    isCourseAssignmentOnSection,
    parentEntityType,
    isReadOnly: isCourseAssignmentOnSection || !hasEditPermission,
  }
}
