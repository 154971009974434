import * as React from 'react'
import { connect } from 'react-redux'
import { hideModal } from 'app/frontend/components/material/modal/modal-actions'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { redirect } from 'app/frontend/helpers/redirect'
import * as styles from './session-expired-modal.css'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('session_expired_modal')

export const SESSION_EXPIRED_MODAL = 'SESSION_EXPIRED_MODAL'

export namespace SessionExpiredModal {
  export interface OwnProps {}

  export interface DispatchProps {
    goToLogin: () => void
  }

  export type Props = OwnProps & DispatchProps
}

export const SessionExpiredModal: React.FunctionComponent<SessionExpiredModal.Props> = ({
  goToLogin,
}) => {
  return (
    <Modal name={SESSION_EXPIRED_MODAL} dataBi="session-expired-modal">
      <ModalBody>
        <h1 id="modalTitle" className={styles.modalTitle}>
          {t('modal_title')}
        </h1>
        <p>{t('modal_body')}</p>
      </ModalBody>
      <ModalFooter>
        <ButtonMaterial data-bi="go-to-login-button" onClick={goToLogin} label={t('go_to_login')} />
      </ModalFooter>
    </Modal>
  )
}

SessionExpiredModal.displayName = 'SessionExpiredModal'

const mapDispatchToProps = (dispatch): SessionExpiredModal.DispatchProps => ({
  goToLogin: () => {
    dispatch(hideModal(SESSION_EXPIRED_MODAL))
    // redirect user to login page
    const location = window.location,
      redirectTo = location.pathname + (location.search || '')
    redirect('/login?r=' + encodeURIComponent(redirectTo))
  },
})

export default connect<{}, SessionExpiredModal.DispatchProps, SessionExpiredModal.OwnProps>(
  null,
  mapDispatchToProps
)(SessionExpiredModal)
