import {
  SHOW_REGRADE_SNACKBAR,
  HIDE_REGRADE_SNACKBAR,
  RegradeSnackbarActions,
} from './regrade-snackbar-actions'
import { getId } from 'app/frontend/helpers/current-user'

export interface RegradeSnackbarReducerState {
  userId: string
  updatedAfter: number
  lastShownAt: number
}

const defaultState = {
  userId: null,
  updatedAfter: null,
  lastShownAt: null,
}

export const getRegradeSnackbarState = (state: any): RegradeSnackbarReducerState | undefined => {
  return state.global.ui.regradesnackbars
}

export function regradeSnackbarReducer(
  state: RegradeSnackbarReducerState = defaultState,
  action: RegradeSnackbarActions
): RegradeSnackbarReducerState {
  switch (action.type) {
    case SHOW_REGRADE_SNACKBAR:
      const now = Date.now()
      return {
        userId: getId(),
        updatedAfter: state.updatedAfter === null ? now - 10000 : state.updatedAfter,
        lastShownAt: now,
      }
    case HIDE_REGRADE_SNACKBAR:
      return defaultState
    default:
      return state
  }
}
