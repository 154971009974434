import paymentsModalReducer, { PaymentsModalState } from './payments/reducer'
import rescueModalReducer, { RescueModalState } from './rescue/reducer'
import { combineReducers } from 'redux'

export type ModalState = {
  payments: Partial<PaymentsModalState>
  rescue: Partial<RescueModalState>
}

export default combineReducers<ModalState>({
  payments: paymentsModalReducer,
  rescue: rescueModalReducer,
})
