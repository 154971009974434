import * as html2canvas from 'html2canvas'

/**
 * Takes a screenshot of the given element and its children (document.body) by default.
 * The returned image is a png base64 image string
 */
export const screenshot = (element: HTMLElement = document.body): Promise<string> => {
  return new Promise((resolve, reject) => {
    const width = Math.min(800, window.innerWidth)
    // html2canvas uses a weird promise implementation so we wrap it in a regular promise
    html2canvas(element, {
      width,
      windowWidth: width,
      scale: 0.5,
      imageTimeout: 5000,
      logging: false,
    })
      .then(canvas => {
        resolve(canvas.toDataURL('image/png'))
      })
      .catch(reject)
  })
}

/**
 * Convert base64/URLEncoded data component to raw binary data held in a string.
 *
 * Copied from https://stackoverflow.com/questions/4998908/
 * convert-data-uri-to-file-then-append-to-formdata
 *
 * @param dataURI the stringified base64 image
 */
export function dataURItoBlob(dataURI: string): Blob {
  /* tslint:disable */

  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}
