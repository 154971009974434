import * as React from 'react'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { FieldCheckboxLabel } from 'app/frontend/compositions/data/redux-form-fields'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { CONFIRM_LDB_MODAL } from './confirm-ldb-modal'
import { ASSESSMENT_SETTINGS_FORM, FormNames } from './helper'
import * as styles from '../settings-modal.css'

const t = tns('teach:assessment_builder')

const DOWNLOAD_LINK =
  'https://support.knewton.com/s/article/Knewton-LockDown-Browser-powered-by-Respondus-Installation-Guide'

type OwnProps = {
  isReadOnly: boolean
  isCourseAssignmentOnSection?: boolean
}

export const LockDownBrowserSetting: React.FunctionComponent<OwnProps> = ({
  isReadOnly,
  isCourseAssignmentOnSection,
}) => {
  const dispatch = useDispatch()

  const onLDBChange = e => {
    if (isCourseAssignmentOnSection) {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.LDB_OVERRIDDEN, true))
    }
    if (e.target.checked) {
      dispatch(showModal(CONFIRM_LDB_MODAL))
    }
  }

  return (
    <Box separator="top" full="page" pad="medium" data-test="ldb-setting">
      <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
        <FieldCheckboxLabel
          name={FormNames.USE_LDB}
          disabled={isReadOnly}
          className={styles.fieldCheckbox}
          onChange={onLDBChange}
          label={
            <Paragraph margin="none" weight="semibold">
              {t('settings_ldb_header')}
            </Paragraph>
          }
          labelPlacement={'start'}
          aria-describedby="settings-ldb-description settings-ldb-description-warning"
        />
      </Box>
      <Paragraph size="small" margin="small" id="settings-ldb-description">
        {t('settings_ldb_description')}
      </Paragraph>
      <Paragraph size="small" margin="small" id="settings-ldb-description-warning">
        {t('settings_ldb_description_warning')}
      </Paragraph>

      <Paragraph size="small" margin="small">
        {t('settings_ldb_click')}
        {
          <a
            target="_blank"
            href={DOWNLOAD_LINK}
            data-bi="download-ldb-link"
            className={styles.supportLink}
            aria-label={`${t('settings_ldb_click')}${t('settings_ldb_link_text')} ${t(
              'settings_ldb_download_text'
            )}`}
          >
            {t('settings_ldb_link_text')}
          </a>
        }{' '}
        {t('settings_ldb_download_text')}
      </Paragraph>
    </Box>
  )
}

LockDownBrowserSetting.displayName = 'LockDownBrowserSetting'
