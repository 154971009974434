import * as moment from 'moment'

export const DEFAULT_COURSE_DURATION = moment.duration(2, 'weeks')
export const DEFAULT_DATE_FORMAT = 'MMM DD YYYY'
export const MAX_NUMBER_OF_TABS = 3
export const DATE_TIME_FORMAT = 'MMM DD YYYY hh:mm A'
// Used for in the course / assessment topics filter
export const IN_USE = 'IN_USE'
export const NOT_IN_USE = 'NOT_IN_USE'
export const ALL = 'ALL'

export type START_DATE = 'START_DATE'
export type END_DATE = 'END_DATE'
export type MANUAL = 'MANUAL'
export type sortType = START_DATE | END_DATE | MANUAL

export interface ISORT {
  START_DATE: START_DATE
  END_DATE: END_DATE
  MANUAL: MANUAL
}

export const SORT: ISORT = {
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
  MANUAL: 'MANUAL',
}

/**
 * Submission grace period in minutes after the due time.
 * Similar to GRACE_PERIOD_MINUTES in RetailStudents.
 * (retailstudents-server/src/main/java/com/knewton/retail/students/QuizServiceImpl.java)
 */
export const GRACE_PERIOD_MINUTES = 10
