import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { GetAssignmentQuery } from 'app/frontend/pages/material/teach/compositions/connected/get-assignment'
import { GetCourseQuery } from 'app/frontend/compositions/connected/get-course'
import Hero from './hero/hero'
import { PageHero } from 'app/frontend/components/material/page'
import { AssessmentBody } from './assessment-body/assessment-body'
import { GetAssignmentWithSectionQuery } from '../compositions/connected/get-assignment-with-section'

type Props = RouteComponentProps<{ courseId?: string; sectionId?: string; assessmentId: string }>

export class AssessmentBuilder extends React.Component<Props, {}> {
  renderPage(
    assessment?: GQL.GetAssignment.Assignment,
    course?: GQL.GetCourse.Course,
    section?: GQL.GetSection.Section,
    override?: GQL.GetAssignmentOverride.AssignmentOverride
  ) {
    return (
      <>
        <PageHero background="light">
          <Hero
            assessment={assessment}
            section={section}
            course={course}
            assignmentOverrides={override}
          />
        </PageHero>
        <AssessmentBody
          assessment={assessment}
          isLmsIntegrated={course && course.lmsType !== 'NONE'}
          course={course}
          section={section}
        />
      </>
    )
  }

  render() {
    const {
      match: {
        params: { courseId, sectionId, assessmentId },
      },
    } = this.props

    return (
      <>
        {courseId && (
          <GetCourseQuery id={courseId}>
            {({ course, loading: courseLoading, error: courseError }) => (
              // TODO ALPACA-759
              <GetAssignmentQuery id={assessmentId} fetchPolicy={'cache-and-network'}>
                {({ assignment, loading: assignmentLoading, error: assignmentError }) => {
                  if (assignmentLoading || assignmentError || courseLoading || courseError) {
                    return null
                  }
                  return this.renderPage(assignment, course, null)
                }}
              </GetAssignmentQuery>
            )}
          </GetCourseQuery>
        )}
        {sectionId && (
          <GetAssignmentWithSectionQuery assignmentId={assessmentId} sectionId={sectionId}>
            {({ section, assignment, override }) =>
              section && (
                <GetCourseQuery id={section.courseId}>
                  {({ course, loading, error }) => {
                    if (loading || error || !assignment) {
                      return null
                    }
                    return this.renderPage(assignment, course, section, override)
                  }}
                </GetCourseQuery>
              )
            }
          </GetAssignmentWithSectionQuery>
        )}
      </>
    )
  }
}

export default withRouter(AssessmentBuilder)
