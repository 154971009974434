export type SHOW_GOAL_UPDATE_SNACKBAR = 'SHOW_GOAL_UPDATE_SNACKBAR'
export const SHOW_GOAL_UPDATE_SNACKBAR: SHOW_GOAL_UPDATE_SNACKBAR = 'SHOW_GOAL_UPDATE_SNACKBAR'
export type HIDE_GOAL_UPDATE_SNACKBAR = 'HIDE_GOAL_UPDATE_SNACKBAR'
export const HIDE_GOAL_UPDATE_SNACKBAR: HIDE_GOAL_UPDATE_SNACKBAR = 'HIDE_GOAL_UPDATE_SNACKBAR'

export type ShowGoalUpdateSnackbarAction = {
  type: SHOW_GOAL_UPDATE_SNACKBAR
  payload: any
}

export const showGoalUpdateSnackbarAction = (courseId: string): ShowGoalUpdateSnackbarAction => {
  return {
    type: SHOW_GOAL_UPDATE_SNACKBAR,
    payload: {
      courseId,
    },
  }
}

export type HideGoalUpdateSnackbarAction = {
  type: HIDE_GOAL_UPDATE_SNACKBAR
}

export const hideGoalUpdateSnackbarAction = (): HideGoalUpdateSnackbarAction => {
  return {
    type: HIDE_GOAL_UPDATE_SNACKBAR,
  }
}

export type GoalUpdateSnackbarActions = ShowGoalUpdateSnackbarAction | HideGoalUpdateSnackbarAction
