import * as React from 'react'
import { groupBy } from 'lodash'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { Heading } from 'app/frontend/components/material/heading'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { hideModal as hideModalAction } from 'app/frontend/components/material/modal/modal-actions'
import * as HAS_STUDENT_STARTED_ASSIGNMENT_QUERY from 'app/frontend/pages/material/teach/compositions/connected/has-student-started-assignment/has-student-started-assignment.gql'
import * as UPDATE_ASSESSMENT from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/update-assessment.gql'
import { GET_ASSESSMENT_SEQUENCES } from 'app/frontend/compositions/connected/get-assessment-sequences'
import { getModalOptions } from 'app/frontend/components/material/modal/modal-reducer'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import * as styles from './delete-objective-modal.css'
import { Icon } from 'app/frontend/components/material/icon'
import { Box } from 'app/frontend/components/material/box'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { useApolloClient, ApolloQueryResult } from '@apollo/client'

const t = tns('teach:delete_objective_modal')

type StateResults = {
  assessmentId: string
  taxons: GQL.TaxonsType[]
}
export const DELETE_OBJECTIVE_MODAL = 'DELETE_OBJECTIVE_MODAL'

export const DeleteObjectiveModal: React.FunctionComponent = () => {
  const [hasError, setHasError] = React.useState(false)
  const dispatch = useDispatch()
  const client = useApolloClient()
  const stateResult: StateResults = useSelector(state => {
    const { assessmentId = null, learningObjectiveId = null, pathLearningObjectives = [] } =
      getModalOptions(state, DELETE_OBJECTIVE_MODAL) || {}

    // Filter out the objective that's being deleted
    const remainingPathLearningObjectives = pathLearningObjectives.filter(
      plo => plo.learningObjectiveId !== learningObjectiveId
    )

    // Group the flat list of path learning objectives by topic ID
    const plosByTopicId = groupBy(remainingPathLearningObjectives, plo => plo.topicId)
    const taxons: GQL.TaxonsType[] = []
    for (const topicId of Object.keys(plosByTopicId)) {
      taxons.push({
        topicId,
        learningObjectiveIds: plosByTopicId[topicId].map(plo => plo.learningObjectiveId),
      })
    }
    return {
      assessmentId,
      taxons,
    }
  })

  const onRemove = async (): Promise<void> => {
    try {
      // fetch the status of the student assessment started
      const { data } = (await client.query({
        query: HAS_STUDENT_STARTED_ASSIGNMENT_QUERY,
        variables: {
          assignmentId: stateResult.assessmentId,
        },
        fetchPolicy: 'network-only',
        context: { silenceErrors: true },
      })) as ApolloQueryResult<GQL.HasStudentStartedAssignment.Query>

      if (!data?.hasStudentStartedAssignment) {
        const assessmentId = stateResult.assessmentId
        await client.mutate({
          mutation: UPDATE_ASSESSMENT,
          variables: {
            request: {
              id: assessmentId,
              taxons: stateResult.taxons,
            },
          },
          refetchQueries: [
            {
              query: GET_ASSESSMENT_SEQUENCES,
              variables: { assessmentId },
            },
          ],
          context: { silenceErrors: true },
        })
        dispatch(
          showSnackbar({
            message: t('objective_removed'),
            iconName: 'icon-progress-circle-complete',
          })
        )
        setHasError(false)
        dispatch(hideModalAction(DELETE_OBJECTIVE_MODAL))
      } else {
        // show snackbar since the student has started the assessment
        dispatch(hideModalAction(DELETE_OBJECTIVE_MODAL))
        dispatch(
          showSnackbar({ message: tns('teach:assessment_questions')('assessment_already_started') })
        )
      }
    } catch (e) {
      setHasError(true)
    }
  }
  const onCancel = (): void => {
    setHasError(false)
    dispatch(hideModalAction(DELETE_OBJECTIVE_MODAL))
  }
  return (
    <Modal
      name={DELETE_OBJECTIVE_MODAL}
      dataBi="delete-objective-modal"
      fullScreen={false}
      preventClose={true}
      data-test="delete-objective-modal"
    >
      <ModalBody>
        <Heading id="modalTitle" size="h4" weight="semibold" margin={{ bottom: 'medium' }}>
          {t('title')}
        </Heading>
        <Paragraph data-test="remove_confirm_text">{t('remove_confirm_text')}</Paragraph>
        {hasError && (
          <Box direction="row" margin={{ top: 'small' }} justify="end">
            <Icon name="icon-error-outline" size="medium" className={styles.errorIcon} />
            <span className={styles.errorMsg}>{t('objective_error_msg')}</span>
          </Box>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonMaterial
          label={t('cancel_btn')}
          data-bi="cancel-button"
          theme="secondary"
          onClick={onCancel}
        />
        <ButtonMaterial
          label={t('remove_btn')}
          data-bi="remove-objective-button"
          theme="warn"
          onClick={onRemove}
        />
      </ModalFooter>
    </Modal>
  )
}

DeleteObjectiveModal.displayName = 'DeleteObjectiveModal'
