import * as React from 'react'
import * as _ from 'lodash'
import { useSelector } from 'react-redux'
import { MenuItem } from '@mui/material'
import { getFormValues } from 'redux-form'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { FieldCheckboxLabel, FieldSelect } from 'app/frontend/compositions/data/redux-form-fields'
import * as Feature from 'app/frontend/helpers/feature'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/settings-modal.css'
import { ASSESSMENT_SETTINGS_FORM, FormNames } from './helper'

const t = tns('teach:assessment_builder')

export const MAX_TEST_VERSIONS = 5

interface Props {
  isReadOnly: boolean
}

export const OfflineAssessmentSetting: React.FunctionComponent<Props> = ({ isReadOnly }) => {
  const formValues = useSelector(getFormValues(ASSESSMENT_SETTINGS_FORM))
  const printable = formValues[FormNames.PRINTABLE]

  const getChoices = () => _.times(MAX_TEST_VERSIONS, n => ({ value: n + 1, label: `${n + 1}` }))

  return (
    <Box full="page" separator="top" pad="medium" data-test="offline-assessment">
      <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
        <FieldCheckboxLabel
          className={styles.fieldCheckbox}
          name={FormNames.PRINTABLE}
          disabled={isReadOnly}
          label={
            <Paragraph margin="none" weight="semibold">
              {t('settings_offline_assessment_header')}
            </Paragraph>
          }
          labelPlacement={'start'}
          aria-describedby="settings-offline-assessment-description"
        />
      </Box>
      <Paragraph size="small" margin="small" id="settings-offline-assessment-description">
        {t('settings_offline_assessment_description')}
      </Paragraph>
      {printable && (
        <Box>
          <Box className={styles.dropdownContainerSmall}>
            <FieldSelect
              MenuProps={{ MenuListProps: { dense: true } }}
              fullWidth
              disabled={isReadOnly}
              name={FormNames.PRINTABLE_VARIATIONS_COUNT}
              label={t('settings_offline_assessment_dropdown')}
              data-bi="offline-assessment-dropdown"
              outerPb={0}
              className={styles.dropdown}
            >
              {getChoices().map(item => (
                <MenuItem key={item.label} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </FieldSelect>
          </Box>
          {Feature.isEnabled('alertOnMultipleAttemptsVersionFlag') && (
            <Paragraph
              className={styles.warning}
              margin="small"
              size="small"
              data-test="multiple-versions-warning"
            >
              {t('settings_multiple_versions_warning')}
            </Paragraph>
          )}
        </Box>
      )}
    </Box>
  )
}
