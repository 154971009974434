export enum SettingName {
  GRADE = 'grade',
  RESULTS = 'results',
  EXPLANATION = 'explanation',
  COMMENT = 'comment',
}

export enum DropdownValues {
  AFTER_SUBMISSION = 'AFTER_SUBMISSION',
  AFTER_DUE_DATE = 'AFTER_DUE_DATE',
  MANUALLY = 'MANUALLY',
  NEVER = 'NEVER',
  IMMEDIATELY = 'IMMEDIATELY',
}

export interface Props {
  isReadOnly: boolean
  selectedSettingOptions?: SettingValueMap
}

export const menuItemsPriority = {
  [DropdownValues.AFTER_SUBMISSION]: 1,
  [DropdownValues.IMMEDIATELY]: 1,
  [DropdownValues.AFTER_DUE_DATE]: 2,
  [DropdownValues.MANUALLY]: 3,
  [DropdownValues.NEVER]: 4,
}

export type InputField = {
  value: DropdownValues
  onChange: (val) => void
}

export type SettingValueMap = {
  [key in SettingName]: DropdownValues
}

export const isGreaterThan = (firstValue: DropdownValues, secondValue: DropdownValues): boolean => {
  return menuItemsPriority[firstValue] > menuItemsPriority[secondValue]
}
