import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Heading } from 'app/frontend/components/material/heading'
import * as styles from './table-of-contents.css'

type OwnProps = {
  section: GQL.SectionTaxonFields.Fragment
  children: JSX.Element | JSX.Element[]
}

type Props = OwnProps

export class TableOfContentsSection extends React.PureComponent<Props> {
  render() {
    const { section, children } = this.props

    return (
      <Box alignSelf="stretch" pad={{ bottom: 'small' }}>
        <Heading tag="h4" size="h6" uppercase={true} data-test="section-name">
          {section.name}
        </Heading>
        <Box
          separator={['all', 'between']}
          alignSelf="stretch"
          direction="column"
          className={styles.cardContainer}
        >
          {children}
        </Box>
      </Box>
    )
  }
}
