export const SET_MATHJAX_STATUS = 'SET_MATHJAX_STATUS'

export type MathjaxStatus = 'INIT' | 'IDLE' | 'BUSY' | 'ERROR'
export type SetMathjaxStatus = {
  type: string
  status: MathjaxStatus
}

export const setMathjaxStatus = (status: MathjaxStatus): SetMathjaxStatus => ({
  type: SET_MATHJAX_STATUS,
  status: status,
})

export const setMathjaxIdle = () => {
  return setMathjaxStatus('IDLE')
}
export const setMathjaxBusy = () => {
  return setMathjaxStatus('BUSY')
}

export const setMathjaxError = () => {
  return setMathjaxStatus('ERROR')
}
