import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
import * as GET_ASSIGNMENT_OVERRIDE_QUERY from './get-assignment-override.gql'

export interface GetAssignmentOverrideRenderProps {
  override?: GQL.GetAssignmentOverride.AssignmentOverride
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryAssignmentOverrideArgs & {
  skip?: boolean
  children: (props: GetAssignmentOverrideRenderProps) => JSX.Element
}

export const GetAssignmentOverrideQuery: React.FunctionComponent<Props> = ({
  assignmentId,
  sectionId,
  children,
  skip = false,
}) => (
  <Query<GQL.GetAssignmentOverride.Query, GQL.GetAssignmentOverride.Variables>
    query={GET_ASSIGNMENT_OVERRIDE_QUERY}
    variables={{ assignmentId, sectionId, skip }}
  >
    {({ data, loading, error }) =>
      children({
        override: isEmpty(data) ? null : data.assignmentOverride,
        loading,
        error,
      })
    }
  </Query>
)

GetAssignmentOverrideQuery.displayName = 'GetAssignmentOverrideQuery'

export default GetAssignmentOverrideQuery
