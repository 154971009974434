import * as React from 'react'
import * as SadAtomSvg from 'app/public/img/sad-atom.svg'
import { getStaticUrl } from 'app/frontend/helpers/assets'

interface Props extends Commons.Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  size?: sizeType
}

type sizeType = 'small' | 'medium' | 'large'

const SadAtom: React.FunctionComponent<Props> = ({ size, ...props }) => {
  return <img src={getStaticUrl(SadAtomSvg)} height={resolveHeight(size)} {...props} />
}

const resolveHeight = (size?: sizeType): number => {
  switch (size) {
    case 'small':
      return 50
    case 'large':
      return 300
    case 'medium':
    default:
      return 150
  }
}

export default SadAtom
