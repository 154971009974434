import * as React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import RegradeSnackbar from './regrade-snackbar'
import { RegradeSnackbarReducerState } from './regrade-snackbar-reducer'

interface IRegradeSnackbarContainerProps {
  regradeSnackbars: RegradeSnackbarReducerState
}

const RegradeSnackbarContainer: React.FunctionComponent<IRegradeSnackbarContainerProps> = ({
  regradeSnackbars,
}) => {
  if (
    !regradeSnackbars.userId! ||
    _.isNil(regradeSnackbars.updatedAfter) ||
    _.isNil(regradeSnackbars.lastShownAt)
  ) {
    return null
  }

  return <RegradeSnackbar />
}

const mapStateToProps = state => {
  return {
    regradeSnackbars: state.global.ui.regradesnackbars,
  }
}

export default connect<IRegradeSnackbarContainerProps, void, {}>(mapStateToProps)(
  RegradeSnackbarContainer
)
