/**
 * The userAgent string is by far the most documented and consistent string to depend on for
 * platform information. All major browsers document how they interpret various devices.
 *
 * This code may be inconsistent when mobile devices are set to "desktop mode" depending on the
 * browser and platform. If you need to respect (or disrespect) this mode, please update
 * these regex to be more sensitive.
 *
 * Firefox: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent/Firefox
 * Chrome: https://developer.chrome.com/multidevice/user-agent
 * Safari: no official page
 */

/**
 * Checks the userAgent to determine if the user is in a lockdown browser or not
 *
 * This differs from isSecureBrowser because isSecureBrowser checks the window.context object for secureBrowser, which
 * is a field set from the backend and is set when the backend detects that an endpoint is being called from respondus
 * lockdown browser
 */
const isLockDownBrowser = (): boolean => {
  // ANGEL Secure is the user agent used for Respondus LDB
  return new RegExp('ANGEL Secure').test(navigator.userAgent)
}

export const Browser = {
  isAndroid: (): boolean => !!navigator.userAgent.match(/Android/),
  isIos: (): boolean => !!navigator.userAgent.match(/iPhone|iPod|iPad/),
  isIframe: (): boolean => window.top !== window.self,
  isSecureBrowser: (): boolean => window.context && window.context.secureBrowser,
  isLockDownBrowser,
}
