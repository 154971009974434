import * as React from 'react'
import Content from 'app/frontend/components/content'
import * as atomStyles from 'app/frontend/components/atom/atom.css'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('learn_flow')

interface ILearnosityResponsePromptExplanationProps {
  explanation?: string
  media?: GQL.AtomMedia[]
}

export const LearnosityResponsePromptExplanation: React.FunctionComponent<ILearnosityResponsePromptExplanationProps> = ({
  explanation,
  media,
}) =>
  explanation ? (
    <div className={atomStyles['general-explanation-wrapper']}>
      <h5>{t('general_explanation')}</h5>
      <Content className={atomStyles.userContent} html={explanation} media={media} />
    </div>
  ) : null

LearnosityResponsePromptExplanation.displayName = 'LearnosityResponsePromptExplanation'

export default LearnosityResponsePromptExplanation
