import { combineReducers } from 'redux'
import previewReducer, { PreviewState } from './preview/preview-reducer'

export type AssignmentsReducerState = {
  preview: PreviewState
}

export default combineReducers<AssignmentsReducerState>({
  preview: previewReducer,
})
