import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client'
import * as GET_ASSIGNMENT_QUERY from './get-assignment.gql'

export interface GetAssignmentRenderProps {
  assignment?: GQL.GetAssignment.Assignment
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryAssignmentArgs & {
  children: (props: GetAssignmentRenderProps) => JSX.Element
  fetchPolicy?: WatchQueryFetchPolicy
}

export const GetAssignmentQuery: React.FunctionComponent<Props> = ({
  id,
  children,
  fetchPolicy = 'cache-first',
}) => (
  // TODO ALPACA-759
  <Query<GQL.GetAssignment.Query, GQL.GetAssignment.Variables>
    query={GET_ASSIGNMENT_QUERY}
    variables={{ id }}
    fetchPolicy={fetchPolicy}
    nextFetchPolicy="cache-first"
  >
    {({ data, loading, error }) =>
      children({
        assignment: isEmpty(data) ? null : data.assignment,
        loading,
        error,
      })
    }
  </Query>
)

GetAssignmentQuery.displayName = 'GetAssignmentQuery'

export default GetAssignmentQuery
