import { every } from 'lodash'

// https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
// Tell the ApolloClient where to look in the cache for a query
export const cacheRedirectQueries = {
  Query: {
    fields: {
      searchDistributorDomainWhitelist: {
        // We manually remove a domain from the list in the cache when it's deleted.
        // See the logic in the deleteDistributorDomainMutation update function.
        // merge: false is already the default behavior but being explicit
        // prevents console warnings for possible data loss
        merge: false,
      },
      getRoles: {
        merge: false,
      },
      course(_, { args, toReference }) {
        return toReference({
          __typename: 'Course',
          ...args,
        })
      },
      section(_, { args, toReference }) {
        return toReference({
          __typename: 'Section',
          ...args,
        })
      },
      loConceptByIds(_, { args, toReference, canRead }) {
        const refs = args.loConceptIds.map(id => toReference({ __typename: 'LoConcept', id: id }))
        // Return cached values iff we have a cached entry for every
        // requested LoConcept
        if (every(refs, ref => canRead(ref))) {
          return refs
        }
      },
      getAssessmentSequencesForAssessments(_, { args, toReference, canRead }) {
        const refs = args.assessmentIds.map(id =>
          toReference({ __typename: 'AssessmentSequenceListType', assessmentId: id })
        )
        // Return cached values iff we have a cached entry for every
        // requested AssessmentSequenceList
        if (every(refs, ref => canRead(ref))) {
          return refs
        }
      },
      activeAssessmentAttempt(_, { args, toReference }) {
        return toReference({
          __typename: 'AssessmentAttempt',
          ...args,
        })
      },
    },
  },
}
