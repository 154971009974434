import * as moment from 'moment'
import { tns } from 'app/frontend/helpers/translations/i18n'
const t = tns('eula_update_banner')

export const EULA_URL = 'https://www.knewton.com/privacy-policy'
// @TODO: Update the EULA_EFFECTIVE_DATE when we plan to release this
export const EULA_EFFECTIVE_DATE = moment('2020-08-12').startOf('day').valueOf()
export const EULA_DESCRIPTION = t('eula_description', {
  date: moment(EULA_EFFECTIVE_DATE).format('MMMM DD, YYYY'),
})
export const EULA_NAME = 'privacy-policy'

export const getEulaStorageKey = () => {
  return `${EULA_NAME}_${moment(EULA_EFFECTIVE_DATE).format('MM-DD-YYYY')}_banner-closed`
}

/**
 * Returns true if the current date is within eula range
 */
export const isCurrentDateWithinRange = (): boolean => {
  const twoWeeksBeforeEffectiveDate = moment(EULA_EFFECTIVE_DATE)
    .subtract(2, 'weeks')
    .startOf('day')
    .valueOf()
  const twoWeeksAfterEffectiveDate = moment(EULA_EFFECTIVE_DATE)
    .add(2, 'weeks')
    .endOf('day')
    .valueOf()
  // The last param of isBetween is for inclusivity
  // https://momentjs.com/docs/#/query/is-between/
  return moment().isBetween(
    twoWeeksBeforeEffectiveDate,
    twoWeeksAfterEffectiveDate,
    undefined,
    '[]'
  )
}
