import { TypographyOptions } from '@mui/material/styles/createTypography'

const fontFamily = '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif'
const fontWeightRegular = 400
const fontWeightMedium = 600

export const typography: TypographyOptions = {
  fontFamily,
  fontWeightMedium,
  h1: {
    fontSize: '3rem',
    lineHeight: '3rem',
    fontWeight: fontWeightRegular,
    letterSpacing: '-0.01562em', // Default from material-ui
    padding: 0,
  },
  h2: {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontWeight: fontWeightRegular,
    letterSpacing: '-0.00833em', // Default from material-ui
    padding: 0,
  },
  h3: {
    fontWeight: fontWeightMedium,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: 0, // Default from material-ui
    padding: 0,
  },
  h4: {
    fontWeight: fontWeightRegular,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    letterSpacing: '0.00735em', // Default from material-ui
    padding: 0,
  },
  h5: {
    fontWeight: fontWeightMedium,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: 0, // Default from material-ui
    padding: 0,
  },
  h6: {
    fontWeight: fontWeightRegular,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.0075em', // Default from material-ui
    padding: 0,
  },
  body1: {
    fontWeight: fontWeightRegular,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#484848',
  },
  body2: {
    fontWeight: fontWeightRegular,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  overline: {
    fontWeight: fontWeightRegular,
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    letterSpacing: '0.08333em', // Default from material-ui
    textTransform: 'uppercase',
  },
}
