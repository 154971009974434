import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, Field, formValueSelector, clearSubmitErrors } from 'redux-form'
import * as _ from 'lodash'
import { CardMaterial } from 'app/frontend/components/material/card/card'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { TextSeparator } from 'app/frontend/components/material/text-separator/text-separator'
import { validateEmail } from 'app/frontend/helpers/validation'
import LegalStuff from 'app/frontend/components/material/legal-stuff/legal-stuff'
import { MainMaterial } from 'app/frontend/layout/material/main'
import GoogleOAuthWidget from 'app/frontend/components/material/login/google-oauth-widget'
import ThirdPartyCookieChecker from 'app/frontend/components/third-party-cookie-checker/third-party-cookie-checker'
import { t } from 'app/frontend/helpers/translations/i18n'
import { Redirect, Username, PasswordError, Password, Remember, Recaptcha } from './login-fields'
import SignedOutLogo from '../signed-out-logo'
import { submitForm } from './login-actions'
import * as styles from './login.css'

interface ILoginProps {
  fields?: any
  handleSubmit?: () => void
  submitting?: boolean
  google?: { authContext?: string; error?: string }
  redir?: string
  pristine?: boolean
  valid?: boolean
  passwordValue?: string
  usernameValue?: string
  loginError?: boolean
  clearSubmitErrors?: (formName: string) => void
}

export class Login extends React.Component<ILoginProps, {}> {
  private readonly loginFormRef: React.RefObject<HTMLFormElement>
  state = {
    showPassword: false,
  }
  constructor(props) {
    super(props)
    this.loginFormRef = React.createRef<HTMLFormElement>()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Clear error on password if there was a login error
    //  allowing users to modify username and submit
    if (this.props.loginError && nextProps.usernameValue !== this.props.usernameValue) {
      this.props.clearSubmitErrors('login')
    }
  }
  componentDidUpdate() {
    // returning focus to the input field on error is better for ADA
    if (!!this.props.loginError) {
      this.loginFormRef.current.getElementsByTagName('input')['username'].focus()
    }
  }
  togglePasswordVisibility = e => {
    e.preventDefault()
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    const { handleSubmit, google, submitting, pristine, valid, passwordValue, redir } = this.props

    const redirQuery = redir ? `?r=${encodeURIComponent(redir)}` : ''

    return (
      <div className={styles.container}>
        <ThirdPartyCookieChecker />
        <SignedOutLogo />
        <MainMaterial>
          <CardMaterial className={styles.loginCard}>
            <form ref={this.loginFormRef} onSubmit={handleSubmit}>
              <Field name="r" component={Redirect} />
              <h1 className={styles.signInTitle}>{t('sign_in')}</h1>
              <GoogleOAuthWidget google={google} authContext="login" />
              <TextSeparator>OR</TextSeparator>
              <Field name="password" component={PasswordError} />
              <Field name="username" component={Username} label={t('email')} />
              <Field
                name="password"
                component={Password}
                label={t('password')}
                showPassword={this.state.showPassword}
                togglePasswordVisibility={this.togglePasswordVisibility}
                passwordText={t('show_password')}
              />
              <Field name="rememberMe" component={Remember} label={t('remember_me')} />
              <ButtonMaterial
                name="login"
                label={t('sign_in')}
                type="submit"
                data-bi="login-button"
                className={styles.signInBtn}
                disabled={!valid || pristine || submitting || !passwordValue}
              />
              <Link to="/forgot" data-bi="forgot-link" className={styles.link}>
                {t('forgot_password')}
              </Link>

              <TextSeparator />
              <div className={styles.registerText}>
                {t('dont_have_an_account')}{' '}
                <Link
                  to={`/account/register${redirQuery}`}
                  data-bi="register-link"
                  className={styles.link}
                >
                  {t('create_an_account')}
                </Link>
              </div>
            </form>
            <Field name="recaptcha" component={Recaptcha} />
          </CardMaterial>
        </MainMaterial>
        <LegalStuff />
      </div>
    )
  }
}

type ValidatedValues = {
  username: string
}

const validate = (values): ValidatedValues => ({
  username: validateEmail(values.username),
})

const mapStateToProps = (state, props) => {
  return {
    usernameValue: formValueSelector('login')(state, 'username'),
    passwordValue: formValueSelector('login')(state, 'password'),
    loginError: _.get(state, 'form.login.submitErrors.password'),
    initialValues: {
      rememberMe: true,
      r: props.redir,
    },
  }
}

let WrappedLogin = reduxForm({ form: 'login', validate, enableReinitialize: true })(Login)
WrappedLogin = connect(mapStateToProps, { onSubmit: submitForm, clearSubmitErrors })(WrappedLogin)
const LoginForm = WrappedLogin

LoginForm.displayName = 'Login'

export default LoginForm
