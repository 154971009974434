/**
 * Modifies the fetch function to send metrics to GA
 */

import * as ReactGA from 'react-ga'
const oldFetch = fetch

const sendTimingToGA = (urlTemplate: string, startTimeMs: number): void => {
  const endTimeMs = new Date().getTime()
  try {
    ReactGA.timing({
      category: 'AJAX',
      variable: urlTemplate,
      value: endTimeMs - startTimeMs,
      label: 'fetch',
    })
  } catch (e) {
    console.warn('Error sending analytics', e)
  }
}

window.fetch = (input: Request | string, init?: RequestInit): Promise<Response> => {
  const start = new Date().getTime()
  const promise = oldFetch(input, init)
  let url = ''
  if (typeof input === 'string') {
    url = input
  } else if (input instanceof Request) {
    url = input.url
  }
  const urlTemplate = url
    .split(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
    .join('<uuid>')

  promise
    .then(resp => {
      sendTimingToGA(urlTemplate, start)
      return resp
    })
    .catch(reject => {
      sendTimingToGA(urlTemplate, start)
      return reject
    })
  return promise
}
