import * as _ from 'lodash'
import * as React from 'react'
import * as Papa from 'papaparse'
import ReactDataGrid from './react-data-grid'
import { Icon } from 'app/frontend/components/material/icon'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'

import DatasetDownloadButton from './dataset-download-button'
import DatasetCopyButton from './dataset-copy-button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as classNames from 'classnames'
import * as buttonStyles from 'app/frontend/components/material/button/button.css'
import * as styles from './dataset-atom.css'

const t = tns('dataset')

export interface OwnProps {
  atom: {
    id: string
    data: {
      csvString: string
      headerLocation: string
    }
  }
  platform: string
  textWidth: (text: string, font: any) => number
  contentManager: ContentManagerActionsType
}

export interface ActionProps {
  onHelpButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  onCopyError: (error) => void
}

export type DatasetAtomProps = OwnProps & ActionProps

export interface IDatasetAtomState {
  columns: IDatasetAtomColumn[]
  rows: string[][]
  gridRefWidth: number
  loaded: boolean
  error: boolean
  isCopied: boolean
}

export interface IDatasetAtomColumn {
  key: string
  name: string
  width: number
  formatter: () => void
  locked: boolean
}

export default class DatasetAtom extends React.Component<DatasetAtomProps, IDatasetAtomState> {
  static readonly MAX_GRID_HEIGHT = 350
  /* react-grid-Canvas is the css class where we add the custom scrollbar */
  static readonly MAX_GRID_CANVAS_HEIGHT = 315
  static readonly MIN_COLUMN_WIDTH = 15
  /* This is about the size of the letter "i" */
  static readonly MIN_CHAR_TO_PIXEL_FACTOR = 5
  /* This is an average size for any letter (based on our current font) */
  static readonly CHAR_TO_PIXEL_FACTOR = 12
  static readonly COLUMN_PADDING = 50
  static readonly ROW_HEIGHT = 35
  static readonly FONT = {
    family: 'Open Sans',
    size: 16,
    weight: 700,
  }

  // the ref to ReactDataGrid
  private readonly gridRef

  // for cancelling the timeout of changing copy button ui
  private timeoutId: number

  constructor(props: DatasetAtomProps) {
    super(props)
    this.gridRef = React.createRef<Element>()
    this.state = {
      columns: [],
      rows: [],
      gridRefWidth: 0,
      loaded: false,
      error: false,
      isCopied: false,
    }
    this.displayRowGetter = this.displayRowGetter.bind(this)
    this.handleWindowResize = _.debounce(this.handleWindowResize.bind(this), 100)
    this.onCopyDatasetClick = this.onCopyDatasetClick.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.formatCsvToGrid()
  }

  componentDidMount() {
    if (!this.state.error) {
      this.setState({ gridRefWidth: this.gridRef.current?.gridWidth(), loaded: true })
      window.addEventListener('resize', this.handleWindowResize)
    } else {
      // If component in error state, trigger contentError callback
      this.props.contentManager.addError({ message: 'Error loading dataset.', isRetryable: true })
    }
  }

  componentDidUpdate(_prevProps: DatasetAtomProps, prevState: IDatasetAtomState) {
    // If component has reached error state, trigger contentError callback
    if (!prevState.error && this.state.error) {
      this.props.contentManager.addError({ message: 'Error loading dataset.', isRetryable: true })
    } else if (!prevState.loaded && this.state.loaded) {
      // If component is now loaded, trigger contentFinishedLoading callback
      this.props.contentManager.success()
    }

    if (!prevState.isCopied && this.state.isCopied) {
      window.clearTimeout(this.timeoutId)
      this.timeoutId = window.setTimeout(() => this.setState({ isCopied: false }), 3000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.clearTimeout(this.timeoutId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.atom, this.props.atom)) {
      console.error('Dataset Atom has changed', {
        currentAtom: this.props.atom,
        nextAtom: nextProps.atom,
        internalIssueId: 'CE-3609',
      })
      this.formatCsvToGrid()
    }
  }

  /**
   * to record the grid width for checking whether it overflows
   */
  handleWindowResize() {
    this.setState({ gridRefWidth: this.gridRef.current.gridWidth() })
  }
  /**
   * Attempts to compute the width of the give text. Either it uses a canvas API or an average
   * size per character. The result might be slightly inaccurate.
   */
  computeTextWidth(text: string) {
    let width = 0
    try {
      // We are adding 50 here for padding and because textWidth() might not be 100% accurate.
      width = this.props.textWidth(text, DatasetAtom.FONT)
    } catch (e) {
      // Operation not supported by browser. Ignore errors since we will fallback to some other way
      // of computing the width.
    }

    // In case there was an exception OR the API is not supported OR the width is
    // suspiciously small.
    if (width < text.length * DatasetAtom.MIN_CHAR_TO_PIXEL_FACTOR) {
      // Try to estimate using an average width for each characters.
      width = text.length * DatasetAtom.CHAR_TO_PIXEL_FACTOR
    }

    return width
  }

  formatCsvToGrid() {
    const atom = this.props.atom

    /* we always turn off the option of header and use the index as the column key in order to
         handle the duplicate fields and the empty string field.
         */
    const parseResult = Papa.parse(atom.data.csvString, {
      delimiter: ',',
      newline: '\n',
      header: false,
      skipEmptyLines: true,
    })

    if (parseResult.errors.length !== 0) {
      console.error('parsing dataset CSV string failed', {
        internalIssueId: 'CE-3610',
        errors: JSON.stringify(parseResult.errors),
      })
      this.setState({ error: true })
    }

    parseResult.data.reduce((prev, curr) => {
      if (prev.length !== curr.length) {
        console.error('parsing dataset CSV string failed: rows contain different column size!', {
          prevLength: prev.length,
          currentLength: curr.length,
          internalIssueId: 'CE-3610',
        })
        this.setState({ error: true })
      }
      return curr
    })

    const rows = parseResult.data

    const columns = rows[0].map((value, idx) => {
      let minColumnWidth = DatasetAtom.MIN_COLUMN_WIDTH
      // Estimate the size of the column from the first 20 cells.
      // This is too avoid issue with large datasets.
      rows.slice(0, 20).forEach(currRow => {
        // We are adding 50 here for padding and because textWidth() might not be 100% accurate.
        minColumnWidth = Math.max(
          minColumnWidth,
          this.computeTextWidth(currRow[idx]) + DatasetAtom.COLUMN_PADDING
        )
      })
      return { key: idx.toString(), name: value, width: minColumnWidth }
    })

    if (this.hasLeftHeader()) {
      columns[0].formatter = headerColumnFormatter
    }

    this.setState({ rows, columns })
  }

  getGridWidth() {
    const canvasRowNum = this.hasTopHeader() ? this.state.rows.length - 1 : this.state.rows.length
    const canvasHeight = DatasetAtom.ROW_HEIGHT * canvasRowNum
    const sumOfWidth = this.state.columns.reduce(
      (prevSum, currColumn) => prevSum + currColumn.width,
      0
    )
    // we need extend the width for the custom vertical scrollbar when the grid height overflows
    return canvasHeight > DatasetAtom.MAX_GRID_CANVAS_HEIGHT ? sumOfWidth + 8 : sumOfWidth
  }

  getGridHeight() {
    // ReactDataSet is buggy on Windows. Scrollbars (both vertical and horizontal are always visible
    // and causing the content to be hard to see. Adding 23 pixels (size of the vertical scrollbar)
    // makes things slightly better.
    // Adding 2 pixels on other OS because the hack we have to hide the header causes an extra
    // small scroll (see hack in getHeaderRowHeight).
    const platform = this.props.platform
    const extraHeight = platform && platform.indexOf('Win') !== -1 ? 23 : 2
    return Math.min(
      DatasetAtom.ROW_HEIGHT * this.state.rows.length + extraHeight,
      DatasetAtom.MAX_GRID_HEIGHT
    )
  }

  getHeaderRowHeight() {
    // ReactDataGrid considers 0 as undefined and uses the default height 35px
    return this.hasTopHeader() ? 0 : 0.1
  }

  displayRowGetter(i) {
    return this.hasTopHeader() ? this.state.rows[i + 1] : this.state.rows[i]
  }

  getDisplayRowsCount() {
    return this.hasTopHeader() ? this.state.rows.length - 1 : this.state.rows.length
  }

  hasTopHeader() {
    const headerLocation = this.props.atom.data.headerLocation.toUpperCase()
    return headerLocation === 'TOP' || headerLocation === 'BOTH'
  }

  hasLeftHeader() {
    const headerLocation = this.props.atom.data.headerLocation.toUpperCase()
    return headerLocation === 'LEFT' || headerLocation === 'BOTH'
  }

  onCopyDatasetClick() {
    this.setState({ isCopied: true })
  }

  /**
   * Renders the component.
   */
  render(): JSX.Element {
    // show error placeholder when an error has occurred.
    const errorElement = <div className={styles.error}>{t('error')}</div>

    if (this.state.error) {
      return errorElement
    }

    const gridWidth = this.getGridWidth()
    let gridHeight = this.getGridHeight()
    // we need extend the height for the custom horizontal scrollbar when the grid width overflows
    if (gridWidth > this.state.gridRefWidth + 2) {
      // add 2 pixels for the robustness
      gridHeight += 8
    }

    return (
      <div className={styles.datasetAtom} data-dataset-atom aria-busy={!this.state.loaded}>
        <div className={styles.datasetWrapper} style={{ maxWidth: gridWidth }}>
          <ReactDataGrid
            columns={this.state.columns}
            rowGetter={this.displayRowGetter}
            rowsCount={this.getDisplayRowsCount()}
            headerRowHeight={this.getHeaderRowHeight()}
            minHeight={gridHeight}
            ref={this.gridRef}
            enableCellAutoFocus={false}
          />
        </div>
        <button
          className={classNames(buttonStyles.nakedButton, styles.helpButton)}
          onClick={this.props.onHelpButtonClick}
          aria-label={t('dataset_help_aria')}
          id="dataset-help-modal-open"
        >
          <Icon name="icon-info-outline" className={styles.iconDatasetButton} />
          {t('help')}
        </button>
        <DatasetCopyButton
          onClick={this.onCopyDatasetClick}
          parsedRows={this.state.rows}
          isCopied={this.state.isCopied}
          onError={this.props.onCopyError}
        />
        <DatasetDownloadButton csvString={this.props.atom.data.csvString} />
      </div>
    )
  }
}

const headerColumnFormatter = ({ value }) => (
  <div className={styles.datasetAtomHeaderColumn}>{value}</div>
)
