import * as React from 'react'
import { useTitle } from 'react-use'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import * as classnames from 'classnames'
import { Icon } from 'app/frontend/components/material/icon'
import {
  PrevPathInfo,
  getPrevPathInfo,
} from 'app/frontend/pages/teach/ui/course/prev-path-info-reducer'
import { Heading } from 'app/frontend/components/material/heading'
import { Box, BoxProps } from 'app/frontend/components/material/box'
import { BoxContextProps, withBoxContext } from 'app/frontend/components/material/box/box'
import * as styles from './hero-title-nav.css'
import { Hidden, Link } from '@mui/material'
import { Breadcrumbs, Breadcrumb } from 'app/frontend/compositions/data/breadcrumbs'

interface Props extends BoxProps, BoxContextProps {
  headingLabel: string
  headingContainsPii?: boolean
  headingEllipsis?: boolean
  breadcrumbs?: Breadcrumb[]
  backPath?: string // back arrow icon link
  backLabel?: string // back arrow icon title attr
  backOnClick?: () => void // for event tracking
  backDataBi?: string // attribute for tests
}

export const _HeroTitleNav: React.FunctionComponent<Props> = ({
  headingLabel,
  headingContainsPii,
  breadcrumbs = [],
  backPath,
  backLabel,
  backOnClick,
  backDataBi,
  background,
  headingEllipsis,
  ...boxProps
}): JSX.Element => {
  useTitle(`${headingLabel} | Knewton`)

  const prevPathInfo = useSelector<any, PrevPathInfo>(getPrevPathInfo)
  // This useHistory never seems to be true, so we should fix or remove this logic
  // TODO: ALPACA-1083
  const useHistory = !!prevPathInfo && prevPathInfo.pathname === backPath
  const backLink = useHistory ? `${prevPathInfo.pathname}${prevPathInfo.search}` : backPath

  return (
    <Box {...boxProps}>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        backLink={backLink}
        backOnClick={backOnClick}
        backLabel={backLabel}
        backDataBi={backDataBi}
        background={background}
      />
      {/* HEADING TITLE */}
      <Box
        direction="row"
        alignItems="center"
        responsive={false}
        full={headingEllipsis ? 'horizontal' : undefined}
      >
        {backLink && (
          <Hidden lgDown>
            <Link
              component={RouterLink}
              to={backLink}
              onClick={backOnClick}
              title={backLabel}
              className={styles.backIconLink}
              data-bi={backDataBi}
            >
              <Icon name="icon-arrow-back" className={styles.backIcon} />
            </Link>
          </Hidden>
        )}
        <Heading
          data-test="hero-title"
          margin="none"
          data-pii={headingContainsPii || undefined}
          className={classnames(styles.heading, headingEllipsis && styles.headingEllipsis)}
        >
          {headingLabel}
        </Heading>
      </Box>
    </Box>
  )
}

export const HeroTitleNav = withBoxContext<any, any>(_HeroTitleNav)
