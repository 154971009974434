import * as React from 'react'
import * as moment from 'moment'
import * as classnames from 'classnames'
import * as keycode from 'keycode'
import { IconButton, TextField } from '@mui/material'
import TodayIcon from '@mui/icons-material/Today'
import { DateTimeFormats } from 'app/helpers/timezone'
import { useDatePicker } from './use-datepicker'
import { PopperCalendar, Calendar } from './calendar'
import { useStyles } from './styles'

export interface Props {
  value: moment.Moment | undefined
  label: string
  dataBi: string
  error?: string
  minDate?: moment.Moment
  maxDate?: moment.Moment
  dateFormat?: DateTimeFormats
  onChange: (val: moment.Moment) => void
  disabled?: boolean
  inputStyle?: string
  allowUndefined?: boolean
  acceptAnyFormat?: boolean
  setError?: (text: string) => void
  fullWidth?: boolean
  variant?: 'filled' | 'standard' | 'outlined'
  /**
   * Variant for calendar.
   * 'standard' allows calendar to be embedded.
   * 'popper' allows it to float like a menu pop-up.
   */
  calendarVariant?: 'standard' | 'popper'
  iconButtonAriaLabel?: string
  customError?: string
}

export const Datepicker: React.FunctionComponent<Props> = ({
  value,
  label,
  dataBi,
  error,
  minDate = moment().add(-2, 'years').startOf('day'),
  maxDate = moment().add(2, 'years').endOf('day'),
  dateFormat,
  disabled,
  onChange,
  allowUndefined = false,
  acceptAnyFormat,
  inputStyle,
  setError,
  fullWidth = true,
  variant = 'standard',
  calendarVariant = 'standard',
  iconButtonAriaLabel,
  customError,
}) => {
  const styles = useStyles()
  const container = React.useRef<HTMLDivElement>()
  const inputField = React.useRef<HTMLDivElement>()
  const buttonRef = React.useRef<HTMLButtonElement>()

  React.useEffect(() => {
    const handleOutsideClick = e => {
      if (!container.current?.contains(e.target)) {
        hidePicker()
      }
    }
    window.addEventListener('mousedown', handleOutsideClick)

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const {
    formattedInputValue,
    isPickerOpen,
    showPicker,
    hidePicker,
    setText,
    handleTextBlur,
    error: dateError,
  } = useDatePicker({
    dateFormat,
    value,
    minDate,
    maxDate,
    onChange,
    allowUndefined,
    acceptAnyFormat,
    setError,
    customError,
  })

  const handleIconKeyDown = (e: React.KeyboardEvent<SVGElement>) => {
    if (keycode(e.keyCode) === 'space' || keycode(e.keyCode) === 'enter') {
      e.preventDefault()
      showPicker()
      return
    }
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // When the user is focused on the textField and presses enter, that should
    // trigger the "blur" event which sets the underlying value (if possible)
    if (keycode(e.keyCode) === 'enter') {
      handleTextBlur()
      return
    }
  }

  const isError = error || dateError

  const calendarProps = {
    pickerOpen: isPickerOpen,
    hidePicker: () => {
      hidePicker()
      // after closing the datepicker popover, the focus is getting reset to the root.
      // to avoid such behaviour, this explicit button focus event is added to keep
      // focus within the clicked datepicker field.
      buttonRef.current.focus()
    },
    onChange,
    value,
    minDate,
    maxDate,
  }

  return (
    <div ref={container} className={classnames(styles.static)}>
      <div ref={inputField} data-bi={dataBi}>
        <TextField
          error={!!(error || dateError)}
          value={formattedInputValue}
          disabled={disabled}
          onChange={e => setText(e.target.value)}
          label={label}
          fullWidth={fullWidth}
          className={inputStyle}
          InputLabelProps={{
            classes: {
              root: classnames(styles.label, variant !== 'standard' && styles.variantLabel),
            },
            htmlFor: dataBi,
          }}
          InputProps={{
            classes: {
              root: classnames(styles.inputRoot, variant !== 'standard' && styles.variantInputRoot),
              underline: variant === 'standard' && styles.underline,
            },
            id: dataBi,
          }}
          onBlur={handleTextBlur}
          onFocus={hidePicker}
          onKeyDown={handleEnterKeyDown}
          variant={variant as any}
        />
        <IconButton
          ref={buttonRef}
          id="date-picker"
          className={styles.icon}
          onClick={() => {
            if (isPickerOpen) {
              hidePicker()
            } else if (!disabled) {
              showPicker()
            }
          }}
          aria-label={iconButtonAriaLabel}
        >
          <TodayIcon onKeyDown={handleIconKeyDown} />
        </IconButton>
        {isError && (
          <span
            className={classnames(styles.error, variant !== 'standard' && styles.variantError)}
            role="alert"
          >
            {error || dateError}
          </span>
        )}
      </div>
      {calendarVariant === 'popper' ? (
        <PopperCalendar {...calendarProps} anchorEl={inputField.current} />
      ) : (
        <Calendar {...calendarProps} />
      )}
    </div>
  )
}
