import * as React from 'react'
import GoogleOAuthWidget from 'app/frontend/components/material/login/google-oauth-widget'
import { TextSeparator } from 'app/frontend/components/material/text-separator/text-separator'
import * as styles from './register.css'

interface GogoleOauthMessagingProps {
  google: any
  googled: boolean
  t: any
}

const GoogleOauthMessaging: React.FunctionComponent<GogoleOauthMessagingProps> = ({
  google,
  googled,
  t,
}) => {
  return (
    <div className={styles.messagingWrapper}>
      {!googled ? (
        <div>
          <GoogleOAuthWidget
            google={google}
            authContext="register"
            className={styles.registerBtn}
            label={t('registration_page:continue_with_google')}
          />
          <TextSeparator className={styles.registerSeparator}>
            {t('registration_page:or')}
          </TextSeparator>
        </div>
      ) : (
        <div className={styles.connectedText}>{t('registration_page:connected_account')}</div>
      )}
    </div>
  )
}

GoogleOauthMessaging.displayName = 'GogoleOauthMessaging'
export default GoogleOauthMessaging
