import {
  AssessmentSequenceSelectionAction,
  SetSequenceStatusAction,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-sequence-selection-actions'
import { HIDE_MODAL, HideModalAction } from 'app/frontend/components/material/modal/modal-actions'
import { SequenceStatusMap } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import { AssessmentBuilderModalType } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'

export interface SequenceSelectionState {
  sequenceStatuses: SequenceStatusMap
}

type RelevantActionTypes = SetSequenceStatusAction | HideModalAction

const defaultState = {
  sequenceStatuses: {},
}

export function assessmentSequenceSelectionReducer(
  state: SequenceSelectionState = defaultState,
  action: RelevantActionTypes
): SequenceSelectionState {
  switch (action.type) {
    case AssessmentSequenceSelectionAction.SetSequenceStatus:
      return {
        sequenceStatuses: {
          ...state.sequenceStatuses,
          [action.sequenceId]: action.state,
        },
      }
    case HIDE_MODAL:
      if (action.name === AssessmentBuilderModalType.QuestionSelection) {
        // clear the store when the questions modal is closed
        return defaultState
      }
  }

  return state
}
