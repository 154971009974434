import { makeApiActions } from 'app/frontend/api/api-redux'

export const API_LOGIN = makeApiActions('API_LOGIN')
export type API_LOGIN = 'API_LOGIN'

export const SUBMIT_FORM = 'SUBMIT_FORM'
export type SUBMIT_FORM = 'SUBMIT_FORM'

export type LoginData = {
  username: string
  password: string
  rememberMe: boolean
  recaptcha?: string
  r?: string
}

export type LoginAction = {
  type: SUBMIT_FORM
  data: LoginData
}

export const submitForm = (data: LoginData): LoginAction => {
  return {
    type: SUBMIT_FORM,
    data,
  }
}
