import * as _ from 'lodash'
import { Reducer } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_GET_LEARN_FLOW,
  ENROLLMENT_COMPLETE,
  LearnFlowLoadAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { API_SEND_LEARN_EVENTS } from 'app/frontend/pages/material/learn/learn-flow/events/learn-flow-events-actions'
import {
  API_GET_COURSE,
  API_GET_LTI_ENROLLMENT,
  API_GET_REGISTERED_ENROLLMENT,
  API_REFRESH_ENROLLMENT_DUE_DATE,
  API_GET_ENROLLMENT_DUE_DATES_FOR_STUDENT,
} from 'app/frontend/pages/material/learn/learn-actions'
import {
  API_GET_QUIZ,
  API_START_QUIZ,
} from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { API_GET_ASSIGNMENT } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export const getEnrollmentByAssignmentId = (
  state: LearnControllerState,
  assignmentId: string
): Commons.IEnrollment => getAllEnrollmentsByAssignmentId(state)[assignmentId] || null

export const findEnrollmentsByAssignmentIds = (
  enrollmentsByAssignmentId: EnrollmentsByAssignmentId,
  assignmentIds: string[]
): Commons.IEnrollment[] =>
  _.filter(enrollmentsByAssignmentId, e => assignmentIds.includes(e.pathId))

export const getAllEnrollmentsByAssignmentId = (
  state: LearnControllerState
): EnrollmentsByAssignmentId => state.entities.enrollments.byAssignmentId

export interface IEnrollmentsReducer {
  byAssignmentId: EnrollmentsByAssignmentId
}

export type EnrollmentsByAssignmentId = {
  // TODO: the types of the enrollment store are not consistent right now. Most of the enrollments
  // here are actually of type Commons.IEnrollmentLean. However, some of the actions cause
  // a Commons.IEnrollment to be added/updated in the store. These actions are ENROLLMENT_COMPLETE
  // and API_GET_LEARN_FLOW and possibly others. The correct solution would be to change the type
  // here to Commons.IEnrollmentLean, and if the path is ever required, get it from
  // state.entities.assignments.byId.
  [pathId: string]: Commons.IEnrollment
}

export const reducer = (
  state: Partial<IEnrollmentsReducer> = { byAssignmentId: {} },
  action: LearnFlowLoadAction & ApiSuccessAction
): Partial<IEnrollmentsReducer> => {
  if (!action) {
    return state
  }

  let newState
  switch (action.type) {
    // TODO: add action to handle call to get enrollments with path
    case API_GET_LTI_ENROLLMENT.SUCCESS:
    case API_GET_LTI_ENROLLMENT.ERROR:
      const enrollmentId = action.args[0]
      const enrollment = _.find(state.byAssignmentId, e => enrollmentId === e.id)
      const { pathId } = enrollment
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [pathId]: {
            ...state.byAssignmentId[pathId],
            ltiEnrollment: _.isEmpty(action.response) ? null : action.response,
          },
        },
      }
    case API_SEND_LEARN_EVENTS.SUCCESS:
      if (!action.response.enrollment) {
        // sequence preview mode
        return state
      }

      const currentEnrollment = state.byAssignmentId[action.response.enrollment.pathId]

      if (currentEnrollment.startedAt) {
        // does not need to be updated
        return state
      }

      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [action.response.enrollment.pathId]: {
            ...action.response.enrollment,
          },
        },
      }
    case API_GET_COURSE.SUCCESS:
      newState = Object.assign({}, state)
      const { enrollmentsByAssignmentId } = action.response

      _.forEach(enrollmentsByAssignmentId, (enrollmentData, id) => {
        newState = {
          byAssignmentId: {
            ...newState.byAssignmentId,
            [id]: {
              ...newState.byAssignmentId[id],
              ...enrollmentData,
            },
          },
        }
      })

      return newState
    case ENROLLMENT_COMPLETE:
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [action.response.enrollment.pathId]: {
            ...action.response.enrollment,
            completed: true,
            completedAt: Date.now(),
          },
        },
      }
    case API_GET_QUIZ.SUCCESS:
    case API_START_QUIZ.SUCCESS:
    case API_GET_LEARN_FLOW.SUCCESS:
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [action.response.enrollment.pathId]: action.response.enrollment,
        },
      }
    case API_GET_ASSIGNMENT.SUCCESS:
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [action.response.enrollment.pathId]: action.response.enrollment,
        },
      }
    case API_GET_REGISTERED_ENROLLMENT.SUCCESS:
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [action.response.pathId]: action.response,
        },
      }
    case API_REFRESH_ENROLLMENT_DUE_DATE.SUCCESS:
      const assignmentId = action.args[1]
      const enrollmentDueDate = action.response

      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [assignmentId]: {
            ...state.byAssignmentId[assignmentId],
            dueDate: enrollmentDueDate,
          },
        },
      }
    case API_GET_ENROLLMENT_DUE_DATES_FOR_STUDENT.SUCCESS:
      newState = Object.assign({}, state)
      const enrollmentDueDates = action.response.enrollmentDueDates

      _.forEach(enrollmentDueDates, (dueDate, id) => {
        newState = {
          byAssignmentId: {
            ...newState.byAssignmentId,
            [id]: {
              ...newState.byAssignmentId[id],
              dueDate,
            },
          },
        }
      })

      return newState
    default:
      return state
  }
}

export default reducer as Reducer<IEnrollmentsReducer>
