import { combineReducers } from 'redux'
import eventQueueReducer, { EventQueueState } from './quiz-flow-event-queue-reducer'
import compoundsReducer, { CompoundsReducerState } from './quiz-flow-compounds-reducer'
import activeIdReducer, { ActiveIdState } from './quiz-flow-active-id-reducer'

export type QuizFlowEntitiesState = {
  compounds: CompoundsReducerState
  eventQueue: EventQueueState
  activeId: ActiveIdState
}

export default combineReducers({
  compounds: compoundsReducer,
  eventQueue: eventQueueReducer,
  activeId: activeIdReducer,
})
