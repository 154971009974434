import { combineReducers } from 'redux'
import courseReducer, { CourseReducerState } from './course/course-reducer'
import analyticsReducer, { AnalyticsReducerState } from './analytics/analytics-reducer'
import assignmentMenuItemReducer, {
  IAssignmentMenuItemsReducerState,
} from 'app/frontend/pages/material/teach/compositions/data/assignment-edit-list-item/assignment-menu-items-reducer'
import {
  default as paymentsReducer,
  PaymentsState,
} from 'app/frontend/pages/material/learn/ui/payments/reducer'
import browseReducer, { BrowseState } from 'app/frontend/pages/teach/browse/browse-reducer'
import mathjaxReducer, {
  MathjaxStateType,
} from 'app/frontend/components/compound/mathjax/mathjax-reducer'
import { PrevPathInfo, prevPathInfoReducer } from './course/prev-path-info-reducer'
import {
  assessmentBuilderReducer,
  AssessmentBuilderState,
} from 'app/frontend/pages/material/teach/assessment-builder'

export function baseUrl(state = null) {
  return state
}

export type TeachUiReducerState = {
  course: CourseReducerState
  browse: BrowseState
  payments: PaymentsState
  prevPathInfo: PrevPathInfo
  baseUrl: string
  analytics: AnalyticsReducerState
  mathjax: MathjaxStateType
  newAssignments: IAssignmentMenuItemsReducerState
  // 2019+ reducers
  assessmentBuilder: AssessmentBuilderState
}

export default combineReducers<TeachUiReducerState>({
  course: courseReducer,
  browse: browseReducer,
  payments: paymentsReducer,
  prevPathInfo: prevPathInfoReducer,
  analytics: analyticsReducer,
  baseUrl,
  mathjax: mathjaxReducer,
  newAssignments: assignmentMenuItemReducer,
  // 2019+ reducers
  assessmentBuilder: assessmentBuilderReducer,
})
