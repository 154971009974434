/* tslint:disable:max-file-line-count */
import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import * as classNames from 'classnames'
import { QuestionStates } from 'app/frontend/api/learnosity-api'
import { getLearnosityState } from 'app/frontend/components/compound/learnosity/learnosity-reducer'
import { tns } from 'app/frontend/helpers/translations/i18n'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'
import { ContentManagerActions } from 'app/frontend/components/content/manager'
import * as styles from 'app/frontend/components/compound/learnosity/learnosity.css'
import * as atomStyles from 'app/frontend/components/atom/atom.css'

import ILearnosityAnswer = Commons.ILearnosityAnswer
const t = tns('learnosity_component')

interface IStateFromProps {
  securityPacket: any
}

interface IComponentOwnProps {
  cheat?: any
  atom: Content.IAtom | GQL.Atom
  onChange?: (answer: Commons.ILearnosityAnswer | null) => any
  submittedAnswer?: ILearnosityAnswer
  questionState?: QuestionStates
  showCorrectAnswers?: boolean
  hideInputAnswers?: boolean
  skipped?: boolean
  placeholder?: JSX.Element
  isCustomQuestionChanged?: boolean
  isFromAnswerExplanationComponent?: boolean
  isFromPreview?: boolean
}

interface ContentManagerProps {
  contentManager: ContentManagerActionsType
}

type LearnosityProps = IStateFromProps & IComponentOwnProps & ContentManagerProps

interface LearnosityState {
  status: 'READY' | 'LOADING' | 'ERROR'
}

interface IQuestionsArray {
  [index: number]: { response_id: string }
}

interface ILearnosityOptions {
  state: string
  questions: IQuestionsArray
  responses?: ILearnosityAnswer
  math_renderer: string
  showCorrectAnswers?: boolean
}

export class Learnosity extends React.Component<LearnosityProps, LearnosityState> {
  static displayName = 'Learnosity'
  static defaultProps: Partial<LearnosityProps> = {
    questionState: QuestionStates.REVIEW,
  }

  /**
   * The Learnosity connected component.
   */
  learnosity: ILearnosityInstance

  /**
   * Unique Id for this component.
   * We will use this for Learnosity's response_id.
   */
  componentId: string

  timerId: number

  constructor(props: LearnosityProps) {
    super(props)

    this.componentId = _.uniqueId()

    this.state = {
      status: 'LOADING',
    }
  }

  componentDidMount() {
    if (this.props.securityPacket) {
      this.mountLearnosity(this.props)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // trigger appropriate callbacks based on new state
    if (prevState.status !== 'ERROR' && this.state.status === 'ERROR') {
      this.props.contentManager.addError({ message: t('error_for_manager'), isRetryable: true })
    } else if (prevState.status === 'LOADING' && this.state.status === 'READY') {
      this.props.contentManager.success()
    }
    if (!prevProps.securityPacket && this.props.securityPacket) {
      this.mountLearnosity(this.props)
    }

    // Update showCorrectAnswers if it changed in the props
    if (this.props.showCorrectAnswers !== prevProps.showCorrectAnswers) {
      this.learnosity?.validateQuestions?.({
        showCorrectAnswers: this.props.showCorrectAnswers,
      })
    }
  }

  componentWillUnmount() {
    if (this.timerId) {
      window.clearInterval(this.timerId)
    }
    if (this.state.status === 'LOADING') {
      // /!\ We called LearnosityApp.init but the ready callback wasn't called.
      // Learnosity will show an error on ALL components if it cannot find a placeholder.
      // This creates a dummy invisible div to avoid this learnosity "feature".
      const divEle = document.createElement('div')
      divEle.setAttribute('class', styles.ghost)
      // we are putting another div in this div because learnosity will replace the inner div
      // and we need to reference to the parent for garbage collection.
      divEle.innerHTML = `<div class="learnosity-response question-${this.componentId}"></div>`
      document.body.appendChild(divEle)

      // Garbage collect this temporary element (after learnosity mounts) in 30 seconds
      setTimeout(() => {
        if (divEle.parentElement === document.body) {
          document.body.removeChild(divEle)
        }
        this.tearDown()
      }, 30000)
    } else {
      this.tearDown()
    }
  }

  tearDown = () => {
    if (window.__cleanupLearnosityDesmosInstances) {
      // This is temporary until Desmos hooks its cleanup into learnosity's reset hook.
      // https://support.learnosity.com/hc/en-us/requests/8824
      window.__cleanupLearnosityDesmosInstances()
    }
    if (this.learnosity && this.learnosity.reset) {
      this.learnosity.reset()
    }
  }

  /**
   * Called when learnosity experiences an issue.
   * Error codes: https://docs.learnosity.com/assessment/questions/troubleshooting
   */
  learnosityError = (error: Commons.ILearnosityError, options: any): void => {
    if (error && error.code === 10019) {
      // 10019: Failed validating math
      // Usually due to a timeout during grading. Since we don't care about frontend
      // grading (we already do grading in RetailStudents), this error is not important.
      // It is "Alert Only" which means that it won't be seen by users.
      return
    }
    const learnosityUiErrorCodes = [10001, 10002, 10003, 10005, 10007, 10010, 10016]
    if (error && learnosityUiErrorCodes.includes(error.code)) {
      // Retry initializing Learnosity once if any of the UI error codes
      // are seen. This means the question is uninteractable. Wait 5 seconds
      // to allow properly initialized instances to render so this error is
      // not propagated to those instances as is the default behavior. This also
      // gives time between requests to the authentication service.
      // The codes are found here:
      // https://docs.learnosity.com/assessment/questions/troubleshooting
      setTimeout(() => {
        if (this.state.status === 'LOADING') {
          console.error('Error initializing Learnosity', {
            error,
            options,
            internalIssueId: 'CE-3466',
          })
          this.setState({
            status: 'ERROR',
          })
        }
      }, 5000)

      return
    }

    console.error('Error initializing Learnosity', {
      error,
      options,
      internalIssueId: 'CE-3466',
    })
  }

  /**
   * Called when LearnosityApp.init() succeeds.
   */
  learnosityReady = async (): Promise<void> => {
    const { onChange, questionState, showCorrectAnswers, atom } = this.props
    if (!this.learnosity) {
      // we don't know why this can happen.
      console.error('Learnosity ready handler fired, but this.learnosity does not exist', {
        internalIssueId: 'CE-2238',
      })
      this.setState({
        status: 'ERROR',
      })
      return
    }

    const question = this.learnosity.question(this.componentId)
    if (!question) {
      // we don't know why this can happen.
      console.error('Learnosity ready handler fired, but question does not exist', {
        componentId: this.componentId,
        internalIssueId: 'CE-4010',
      })
      this.setState({
        status: 'ERROR',
      })
      return
    }

    question.on('change', () => {
      if (onChange) {
        onChange(this.getValue())
      }
    })

    // This is needed for Desmos questions because it doesn't support showCorrectAnswers passed
    // in through Learnosity.init().
    const { content } = atom.data as GQL.AtomLearnosityBlock
    const learnosityType = content.type
    if (
      questionState === QuestionStates.REVIEW &&
      showCorrectAnswers !== false &&
      learnosityType === 'custom'
    ) {
      this.timerId = window.setInterval(() => {
        question.validate({
          showCorrectAnswers: true,
        })
      }, 1000)
    }

    // Validate Learnosity images
    try {
      await question.checkImages()
    } catch (error) {
      console.error('A Learnosity image failed to load', {
        error,
        internalIssueId: 'CE-3508',
      })
      this.setState({
        status: 'ERROR',
      })
      return
    }

    // Validate that Learnosity widget is loaded and not just the loading spinner.
    const spinnerRemoved = await this.waitForLearnosityWidget()
    if (!spinnerRemoved) {
      console.error('Learnosity still loading after 10s', {
        internalIssueId: 'CE-3466',
      })
      this.setState({
        status: 'ERROR',
      })
      return
    }
    this.setState({ status: 'READY' })
  }

  /**
   * Pause execution for millis milliseconds
   */
  static _delay = (millis: number) => {
    return new Promise(resolve => setTimeout(() => resolve(true), millis))
  }

  /**
   * Returns a promise that resolves when the Learnosity spinner has removed itself
   * from the page and been replaced by the Learnosity widget.
   *
   * We do this because sometimes Learnosity reports itself as ready but is still showing its
   * loading spinner. Every 50 ms we check when the spinner has been removed so we do
   * not report this component as ready until the Learnosity is interactable.
   *
   * If the Learnosity spinner is still present after 10s, we resolve the Promise as false.
   * @returns {Promise<boolean>}
   */
  waitForLearnosityWidget = async (): Promise<boolean> => {
    // check if spinner has removed itself once every 50ms for 10 seconds
    for (let numIterations = 0; numIterations < 200; numIterations++) {
      if (
        !document.querySelector(`.learnosity-${this.componentId} .lrn_widget`) &&
        document.querySelector(`.learnosity-${this.componentId} .lrn_spinner`)
      ) {
        await Learnosity._delay(50)
      } else {
        return true
      }
    }
    return false
  }

  mountLearnosity(props) {
    // This could be undefined if the learnosity script failed to load
    if (!window.LearnosityApp) {
      console.error('Learnosity did not load properly', {
        internalIssueId: 'CE-2474',
      })
      this.setState({
        status: 'ERROR',
      })
      return
    }

    const options = {
      ...props.securityPacket,
      ...this.initOptions(props),
    }

    this.learnosity = window.LearnosityApp.init(options, {
      readyListener: () =>
        // uncaught exceptions thrown here won't actually force the user to re-render the page
        // so we catch them explicitly and render our error state.
        this.learnosityReady()
          .then(() => {
            // Render all math stuff. ALPACA-669
            // Sometimes, formulas don't render properly when loaded.
            this.learnosity.renderMath()
          })
          .catch(err => {
            console.error('Unhandled exception in learnosityReady', {
              internalIssueId: 'CE-3466',
              err,
            })
            this.setState({
              status: 'ERROR',
            })
          }),
      errorListener: error => this.learnosityError(error, options),
    })
  }

  /**
   * Returns the user response in the Learnosity component.
   * Null is returned if the answer is empty (no answer was selected, typed, ..)
   */
  getValue(): Commons.ILearnosityAnswer | null {
    // getValue is called
    const responses =
      (this.learnosity && this.learnosity.getResponses && this.learnosity.getResponses()) || {}
    const atomId = this.props.atom.id.atomId
    const response = responses[this.componentId] || null
    const data = this.props.atom.data as GQL.AtomLearnosityBlock

    // If the user did not interact with the component, the question can't be answered
    if (!response) {
      return null
    }

    /*
     * This utility function returns true when we want the submit
     * button to be disabled. For cloze-type questions we want to
     * disable the submit button until a user has a value in each
     * input. This will prevent students from overlooking inputs.
     * This will always return false for template questions since
     * it is a lot of additional complexity to determine what is
     * the user's input and what is the template.
     */
    const hasEmptyAnswers = (values): boolean => {
      // cloze questions
      if (Array.isArray(values)) {
        if (values.length === 0) {
          return true
        }
        for (const value of values) {
          // if a user has interacted with the input and removed their
          // answer, the value will be an empty string
          if (value === '' || value === null) {
            return true
          }
        }
        return false
      }

      // return null if the value is falsy (not set, or empty string)
      if (!values) {
        return true
      }
      // graphing questions: return null if there is nothing in
      // composition (a list of things added to the graph)
      if (values.composition && values.composition.length === 0) {
        return true
      }

      return false
    }

    const learnosityType = data && data.content && data.content.type

    // this is for Desmos, where we want to return the answer and never
    // disable the submit button
    if (learnosityType === 'custom') {
      const scores = this.learnosity.getScores()
      response['score'] = scores[this.componentId]
    } else {
      if (hasEmptyAnswers(response.value)) {
        return null
      }
    }

    // Returns the responses object that RetailStudents will grade.
    // The key has to be the layout atom id (it has to match the response_id
    // returned by the CMS)
    return { [atomId]: response }
  }

  initOptions(props): ILearnosityOptions {
    const cheatData = props.cheat && props.cheat.data
    const answerData = props.submittedAnswer || cheatData

    let responses
    if (answerData) {
      // create the responses object with the component id
      // (needs to match the response_id of the content)
      const answer = answerData[_.keys(answerData)[0]]
      responses = { [this.componentId]: answer }
    }

    // Add unique response_id to the content
    const content = {
      ...props.atom.data.content,
      response_id: this.componentId,
    }

    /**
     * Alchemie has implemented a requirement for responses to be provided in the review state
     * for questions with the new fix. However, the learnosity init function encounters a failure
     * due to the absence of a user response in the answer explanation. To fix this issue,
     * the correct answer is used as the value of the response.
     */
    const isAlchemieQuestionWithExplanation =
      props.isFromAnswerExplanationComponent &&
      ['alchemie_lewis2_question', 'alchemie_vsepr2_question'].includes(
        props.atom?.data?.content?.['custom_type']
      )

    if (isAlchemieQuestionWithExplanation) {
      responses = { [this.componentId]: { value: content?.valid_response?.value } }
    }

    /**
     * Sets the initial response value for Alchemie VSEPR 2.0 questions in preview mode.
     *
     * When previewing Alchemie VSEPR 2.0 questions, the component usually loads in a review state.
     * In this review state, Alchemie requires that responses be provided. To ensure this,
     * a valid_response is set for the component if the conditions are met.
     *
     */

    if (
      props.isFromPreview &&
      props.questionState === QuestionStates.REVIEW &&
      props.atom?.data?.content?.['custom_type'] === 'alchemie_vsepr2_question'
    ) {
      responses = { [this.componentId]: { value: content?.valid_response?.value } }
    }

    return {
      questions: [content],
      state:
        props.questionState === QuestionStates.INITIAL && responses
          ? QuestionStates.RESUME
          : props.questionState,
      responses,
      math_renderer: 'mathquill',
      showCorrectAnswers: props.showCorrectAnswers,
    }
  }

  render(): JSX.Element {
    if (this.props.skipped) {
      return (
        <div className={styles.ghost}>
          <div className={`learnosity-response question-${this.componentId}`} />
        </div>
      )
    }
    const errorElement = (
      <div className={styles.error}>
        {t('error_1')}
        <span
          className={styles.link}
          onClick={() => {
            location.reload()
          }}
        >
          {t('error_2')}
        </span>
        {t('error_3')}
      </div>
    )

    if (this.state.status === 'ERROR') {
      return errorElement
    }

    return (
      <div className={classNames(styles.learnosity, `learnosity-${this.componentId}`)}>
        <div
          className={classNames(
            {
              hide_response_input: this.props.hideInputAnswers,
            },
            atomStyles.answer,
            atomStyles.userContent
          )}
        >
          {this.state.status === 'LOADING' && (
            <div aria-label={t('loading')}>{this.props.placeholder}</div>
          )}
          {/* The learnosity widget will render before it calls our learnosityReady callback.
          To prevent this, we wrap (and hide) the widget while our component is loading. */}
          <div
            className={classNames({
              [styles.ghost]: this.state.status === 'LOADING',
            })}
          >
            <div className={`learnosity-response question-${this.componentId}`} />
          </div>
        </div>
      </div>
    )
  }
}

interface ownState {
  key?: string
}

function mapStateToProps(state) {
  const learnosityState = getLearnosityState(state)
  return {
    securityPacket: learnosityState.learnosityCredentials,
  }
}

const ReduxConnectedLearnosityComponent = connect<
  IStateFromProps,
  {},
  IComponentOwnProps & ContentManagerProps & { ref: any },
  {}
>(mapStateToProps, null, null, {
  forwardRef: true,
})(Learnosity)

class ReadyAwareLearnosityComponent extends React.Component<IComponentOwnProps, ownState> {
  static displayName = 'ReadyAwareLearnosityComponent'

  // Stores references to inner LearnosityComponent
  private learnosityComponent: React.RefObject<Learnosity>

  constructor(props) {
    super(props)

    this.learnosityComponent = React.createRef<Learnosity>()
    this.state = {
      key: _.uniqueId(`${this.props.atom?.id?.variationId}-`),
    }
  }

  // This was used to remount the Learnosity component when a custom question was edited.
  componentDidUpdate(prevProps) {
    // This is a special scenario that is triggered only when the correct answer to a custom question is edited.
    if (
      this.props.isCustomQuestionChanged &&
      prevProps.atom.data?.['content']?.['validation']?.['valid_response']?.['value']?.[0] !==
        this.props.atom.data?.['content']?.['validation']?.['valid_response']?.['value']?.[0]
    ) {
      this.setState({
        key: _.uniqueId(`${this.props.atom?.id?.variationId}-`),
      })
    }
  }

  // Expose the LearnosityComponent's getValue method
  getValue = (): Commons.ILearnosityAnswer | null => {
    return this.learnosityComponent?.current?.getValue() ?? null
  }

  render() {
    const { key } = this.state
    return (
      <ContentManagerActions initialDetails={{ type: 'LEARNOSITY' }}>
        {contentManager => (
          <ReduxConnectedLearnosityComponent
            key={key}
            {...this.props}
            contentManager={contentManager}
            ref={this.learnosityComponent}
          />
        )}
      </ContentManagerActions>
    )
  }
}

export default ReadyAwareLearnosityComponent
