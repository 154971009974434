import {
  AssessmentConceptsSelectionAction,
  EditConceptsStagedAction,
  EditConceptsFailedAction,
  EditConceptsSucceededAction,
} from './assessment-lo-selection-actions'
import { ConceptStatusesByLoMap, ConceptStatus, ConceptsByLoMap } from '../assessment-builder-types'
import { entries, forEach, isNil, some, values } from 'lodash'

export interface ConceptStatusesByTopic {
  [topicId: string]: ConceptStatusesByLoMap
}

export interface SingleAssessmentConceptSelectionState {
  conceptStatuses: ConceptStatusesByTopic
}

export interface ConceptSelectionState {
  [assessmentId: string]: SingleAssessmentConceptSelectionState
}

const defaultState = {}
const defaultSingleAssessmentState = {
  conceptStatuses: {},
}

type RelevantActions =
  | EditConceptsStagedAction
  | EditConceptsFailedAction
  | EditConceptsSucceededAction

export const assessmentConceptSelectionReducer = (
  state: ConceptSelectionState = defaultState,
  action: RelevantActions
) => {
  const currentStateForAssessment =
    action.assessmentId && getSingleAssessmentState(state, action.assessmentId)
  let newSingleAssessmentState

  switch (action.type) {
    case AssessmentConceptsSelectionAction.EditStaged:
      newSingleAssessmentState = withNewConceptStatuses(
        currentStateForAssessment,
        action.topicId,
        action.affectedConceptsByLo,
        ConceptStatus.Saving
      )
      return withSingleAssessmentState(state, action.assessmentId, newSingleAssessmentState)
    case AssessmentConceptsSelectionAction.EditSucceeded:
      newSingleAssessmentState = withNewConceptStatuses(
        currentStateForAssessment,
        action.topicId,
        action.affectedConceptsByLo,
        ConceptStatus.None
      )
      return withSingleAssessmentState(state, action.assessmentId, newSingleAssessmentState)
    case AssessmentConceptsSelectionAction.EditFailed:
      newSingleAssessmentState = withNewConceptStatuses(
        currentStateForAssessment,
        action.topicId,
        action.affectedConceptsByLo,
        ConceptStatus.Error
      )
      return withSingleAssessmentState(state, action.assessmentId, newSingleAssessmentState)
    default:
      return state
  }
}

/**
 * Get the effective single-assessment concept selection state for a specific assessment
 */
function getSingleAssessmentState(
  state: ConceptSelectionState,
  assessmentId: string
): SingleAssessmentConceptSelectionState {
  return state[assessmentId] || defaultSingleAssessmentState
}

/**
 * Update the reducer state with a new assessment-specific concept selection state
 */
function withSingleAssessmentState(
  state: ConceptSelectionState,
  assessmentId: string,
  singleAssessmentState: SingleAssessmentConceptSelectionState
): ConceptSelectionState {
  return {
    ...state,
    [assessmentId]: singleAssessmentState,
  }
}

/**
 * Update the concept selection state for a specific assessment, setting the given status on
 * all of the given affected concepts.
 */
function withNewConceptStatuses(
  state: SingleAssessmentConceptSelectionState,
  topicId: string,
  conceptsByLo: ConceptsByLoMap,
  newStatus: ConceptStatus
): SingleAssessmentConceptSelectionState {
  const conceptStatusUpdates: ConceptStatusesByLoMap = {}
  for (const [loId, conceptIds] of entries(conceptsByLo)) {
    conceptStatusUpdates[loId] =
      (state.conceptStatuses[topicId] && state.conceptStatuses[topicId][loId]) || {}
    forEach(conceptIds, conceptId => (conceptStatusUpdates[loId][conceptId] = newStatus))
  }

  return {
    ...state,
    conceptStatuses: {
      ...state.conceptStatuses,
      [topicId]: {
        ...state.conceptStatuses[topicId],
        ...conceptStatusUpdates,
      },
    },
  }
}

export const getConceptStatus = (
  conceptStatuses: ConceptStatusesByLoMap,
  conceptId: string,
  loId: string
): ConceptStatus => {
  return conceptStatuses?.[loId]?.[conceptId] ?? ConceptStatus.None
}

export const isAnyConceptSavingForLo = (
  conceptStatuses: ConceptStatusesByLoMap,
  loId: string
): boolean => {
  if (!isNil(conceptStatuses?.[loId])) {
    return some(values(conceptStatuses[loId]), v => v === ConceptStatus.Saving)
  }
  return false
}
