import { makeApiActions } from 'app/frontend/api/api-redux'
import { Action } from 'redux'

export const GET_COURSEPACK_AND_DOMAIN_TAXONS = 'GET_COURSEPACK_AND_DOMAIN_TAXONS'
export function getCoursepackAndDomainTaxons() {
  return {
    type: GET_COURSEPACK_AND_DOMAIN_TAXONS,
  }
}
export const API_GET_COURSEPACK_AND_DOMAIN_TAXONS = makeApiActions(
  'API_GET_COURSEPACK_AND_DOMAIN_TAXONS'
)

export type SetDomainFilterAction = { domainId: Taxonomy.TaxonId } & Action
export const SET_DOMAIN_FILTER = 'SET_DOMAIN_FILTER'
export function setDomainFilter(domainId: Taxonomy.TaxonId): SetDomainFilterAction {
  return {
    type: SET_DOMAIN_FILTER,
    domainId,
  }
}

export type SelectCatalogCoursepackAction = { coursepackId: string } & Action
export const SELECT_CATALOG_COURSEPACK = 'SELECT_CATALOG_COURSEPACK'
export function selectCatalogCoursepack(coursepackId: string): SelectCatalogCoursepackAction {
  return {
    type: SELECT_CATALOG_COURSEPACK,
    coursepackId,
  }
}

export type SELECT_CATALOG_LEARNING_OBJECTIVE = 'SELECT_CATALOG_LEARNING_OBJECTIVE'
export const SELECT_CATALOG_LEARNING_OBJECTIVE = 'SELECT_CATALOG_LEARNING_OBJECTIVE'
export type SelectCatalogLearningObjectiveAction = {
  type: SELECT_CATALOG_LEARNING_OBJECTIVE
  learningObjectiveId: Taxonomy.TaxonId
}
export function selectCatalogLearningObjective(
  learningObjectiveId: Taxonomy.TaxonId
): SelectCatalogLearningObjectiveAction {
  return {
    type: SELECT_CATALOG_LEARNING_OBJECTIVE,
    learningObjectiveId,
  }
}

export type OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL = 'OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL'
export const OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL = 'OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL'
export type OpenCatalogLearningObjectiveModalAction = {
  type: OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL
  learningObjectiveId: Commons.LearningObjectiveId
  topicId: Taxonomy.TaxonId
  conceptId?: string
}
export function openCatalogLearningObjectiveModal(
  topicId: Taxonomy.TaxonId,
  learningObjectiveId: Commons.LearningObjectiveId,
  conceptId?: string
): OpenCatalogLearningObjectiveModalAction {
  return {
    type: OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL,
    learningObjectiveId,
    topicId,
    conceptId,
  }
}

export const LOAD_SAMPLE_SEQUENCES = 'LOAD_SAMPLE_SEQUENCES'
export type LOAD_SAMPLE_SEQUENCES = 'LOAD_SAMPLE_SEQUENCES'
export type LoadSampleSequencesAction = {
  type: LOAD_SAMPLE_SEQUENCES
  learningObjectiveId: Commons.LearningObjectiveId
  topicId: Taxonomy.TaxonId
  conceptId?: string
  purposeType?: string
}
export function loadSampleSequences(
  topicId: Taxonomy.TaxonId,
  learningObjectiveId: Commons.LearningObjectiveId,
  conceptId?: string,
  purposeType?: string
): LoadSampleSequencesAction {
  return {
    type: LOAD_SAMPLE_SEQUENCES,
    learningObjectiveId,
    topicId,
    conceptId,
    purposeType,
  }
}

export const API_GET_SAMPLE_SEQUENCES = makeApiActions('API_GET_SAMPLE_SEQUENCES')

export type SelectCatalogChapterAction = { chapterId: Taxonomy.TaxonId } & Action
export const SELECT_CATALOG_CHAPTER = 'SELECT_CATALOG_CHAPTER'
export function selectCatalogChapter(chapterId: Taxonomy.TaxonId): SelectCatalogChapterAction {
  return {
    type: SELECT_CATALOG_CHAPTER,
    chapterId,
  }
}

export type SelectCatalogTopicAction = { topicId: Taxonomy.TaxonId } & Action
export const SELECT_CATALOG_TOPIC = 'SELECT_CATALOG_TOPIC'
export function selectCatalogTopic(topicId: Taxonomy.TaxonId): SelectCatalogTopicAction {
  return {
    type: SELECT_CATALOG_TOPIC,
    topicId,
  }
}

export type SelectCatalogSamplePurposeTypeAction = {
  purposeType: string
  type: SELECT_CATALOG_SAMPLE_PURPOSE_TYPE
}
export type SELECT_CATALOG_SAMPLE_PURPOSE_TYPE = 'SELECT_CATALOG_SAMPLE_PURPOSE'
export const SELECT_CATALOG_SAMPLE_PURPOSE_TYPE = 'SELECT_CATALOG_SAMPLE_PURPOSE'
export function selectCatalogSamplePurposeType(
  purposeType: string
): SelectCatalogSamplePurposeTypeAction {
  return {
    type: SELECT_CATALOG_SAMPLE_PURPOSE_TYPE,
    purposeType,
  }
}

export type OPEN_CATALOG_TOPIC_PREVIEW = 'OPEN_CATALOG_TOPIC_PREVIEW'
export const OPEN_CATALOG_TOPIC_PREVIEW = 'OPEN_CATALOG_TOPIC_PREVIEW'
export type OpenCatalogTopicPreviewAction = {
  type: OPEN_CATALOG_TOPIC_PREVIEW
  topicId: Taxonomy.TaxonId
  name?: string
}
export function openCatalogTopicPreview(topicId: Taxonomy.TaxonId): OpenCatalogTopicPreviewAction {
  return {
    type: OPEN_CATALOG_TOPIC_PREVIEW,
    topicId,
  }
}

export type SELECT_CATALOG_CONCEPT = 'SELECT_CATALOG_CONCEPT'
export const SELECT_CATALOG_CONCEPT = 'SELECT_CATALOG_CONCEPT'
export type SelectCatalogConceptAction = {
  type: SELECT_CATALOG_CONCEPT
  conceptId: string
}
export const selectCatalogConcept = (conceptId: string): SelectCatalogConceptAction => {
  return {
    type: SELECT_CATALOG_CONCEPT,
    conceptId,
  }
}

export const UPDATE_SAMPLE_SEQUENCES = 'UPDATE_SAMPLE_SEQUENCES'
export type UPDATE_SAMPLE_SEQUENCES = 'UPDATE_SAMPLE_SEQUENCES'
export type UpdateSampleSequencesAction = {
  type: UPDATE_SAMPLE_SEQUENCES
  objectiveId: string
  conceptId: string
  purpose: Content.PurposeType
  page: number
  content: any
}
export function updateSampleSequences(
  objectiveId,
  conceptId,
  purpose: Content.PurposeType,
  page: number,
  content: Commons.ContentForSearchFilters
): UpdateSampleSequencesAction {
  return {
    type: UPDATE_SAMPLE_SEQUENCES,
    objectiveId,
    conceptId,
    purpose,
    page,
    content,
  }
}

export const SET_CONTENT_FETCHING = 'SET_CONTENT_FETCHING'
export type SET_CONTENT_FETCHING = 'SET_CONTENT_FETCHING'
export type SetContentFetchingAction = {
  type: SET_CONTENT_FETCHING
  isContentFetching: boolean
}
export function setContentFetching(isContentFetching: boolean): SetContentFetchingAction {
  return {
    type: SET_CONTENT_FETCHING,
    isContentFetching,
  }
}
