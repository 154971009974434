export type UPDATE_QUESTION_COUNT = 'UPDATE_QUESTION_COUNT'
export const UPDATE_QUESTION_COUNT: UPDATE_QUESTION_COUNT = 'UPDATE_QUESTION_COUNT'
export type UPDATE_HISTORY_STATES = 'UPDATE_HISTORY_STATES'
export const UPDATE_HISTORY_STATES: UPDATE_HISTORY_STATES = 'UPDATE_HISTORY_STATES'

export type UpdateQuestionCountAction = {
  type: UPDATE_QUESTION_COUNT
  questionCount: Commons.IQuestionCount
  assignmentId: string
}

export const updateQuestionCount = (
  questionCount: Commons.IQuestionCount,
  assignmentId: string
): UpdateQuestionCountAction => {
  return {
    type: UPDATE_QUESTION_COUNT,
    questionCount,
    assignmentId,
  }
}

export type UpdateHistoryStatesAction = {
  type: UPDATE_HISTORY_STATES
  historyStates: Learn.IStateAndContent[]
  assignmentId: string
}
export const updateHistoryStates = (
  historyStates: Learn.IStateAndContent[],
  assignmentId: string
): UpdateHistoryStatesAction => {
  return {
    type: UPDATE_HISTORY_STATES,
    historyStates,
    assignmentId,
  }
}
