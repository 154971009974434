import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { CollapsibleCard } from 'app/frontend/components/collapsible-card'

type OwnProps = {
  chapter: GQL.ChapterTaxonFields.Fragment
  defaultExpanded: boolean
  children: JSX.Element | JSX.Element[]
  keyChange?: string
  tag?: JSX.Element
}

type Props = OwnProps

export class TableOfContentsChapter extends React.PureComponent<Props> {
  render() {
    const { chapter, defaultExpanded, children, keyChange, tag } = this.props

    return (
      <CollapsibleCard
        defaultExpanded={defaultExpanded}
        title={chapter.name}
        keyChange={keyChange}
        tag={tag}
      >
        <Box alignSelf="stretch">
          <Box pad={{ vertical: 'small' }} alignSelf="stretch" direction="column">
            {children}
          </Box>
        </Box>
      </CollapsibleCard>
    )
  }
}
