import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences.css'
import { Heading } from 'app/frontend/components/material/heading'
import { QuestionPreference } from 'app/frontend/data/question-preference-enum'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as _ from 'lodash'
import {
  MAX_NUM_QUESTION_PER_LO,
  MIN_NUM_QUESTION_PER_LO,
} from 'app/frontend/data/question-preferences-constants'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import ToggleButtonGroup from 'app/frontend/components/material/toggle-button-group/toggle-button-group'

const t = tns('teach:assessment_lo_selection_modal')

export interface Props {
  questionType: string
  numQuestionsPerLo: number
  onRequestClose?: () => void
  updateState: (questionType: string, numOgQuestionPerLo: number) => void
}

export const QuestionPreferencesOption: React.FunctionComponent<Props> = ({
  onRequestClose,
  questionType,
  numQuestionsPerLo,
  updateState,
}) => {
  const [questionTypeLocal, setQuestionType] = React.useState(questionType)
  const [numQuestionsPerLoLocal, setNumQuestionsPerLocal] = React.useState(numQuestionsPerLo)

  /**
   * Function that update the local state and closes popover
   */
  const handleApply = () => {
    updateState(questionTypeLocal, numQuestionsPerLoLocal)
    onRequestClose()
  }

  /**
   * Function that update the state using props values and closes popover
   */
  const handleClickCancel = () => {
    setQuestionType(questionType)
    setNumQuestionsPerLocal(numQuestionsPerLo)
    onRequestClose()
  }

  /**
   * Function that update the state when change of button group
   */
  const handleChangeFilterByType = (selectedQuestionType: QuestionPreference) => {
    setQuestionType(selectedQuestionType)
  }

  /**
   * Function that update the state when change of button group
   */
  const handleQuestionsPerLo = (selectedNumQuestions: string) => {
    setNumQuestionsPerLocal(parseInt(selectedNumQuestions, 10))
  }

  const optionValues = _.range(MIN_NUM_QUESTION_PER_LO, MAX_NUM_QUESTION_PER_LO + 1).map(n => ({
    value: n.toString(),
    label: n.toString(),
  }))

  return (
    <Box>
      <Heading data-test="preference-heading" tag="h5" pad={{ horizontal: 'medium' }} size="h5">
        {t('question_preferences_type_title')}
      </Heading>
      <Box margin={{ vertical: 'small', horizontal: 'medium' }}>
        <ToggleButtonGroup
          options={[
            {
              value: QuestionPreference.STATIC,
              label: t('question_type_static'),
            },
            {
              value: QuestionPreference.POOL,
              label: t('question_type_pool'),
            },
          ]}
          name={'filter-by-type'}
          selectedOption={questionTypeLocal}
          onSelectionChange={handleChangeFilterByType}
        />
        <Paragraph size="small" margin="none">
          <span data-test="question-type" className={styles.bold}>
            {questionTypeLocal === QuestionPreference.STATIC
              ? t('question_type_static')
              : t('question_type_pool')}
          </span>{' '}
          {t('question_text_plural')}
        </Paragraph>
      </Box>
      <Heading tag="h5" pad={{ horizontal: 'medium' }} size="h5">
        {t('question_preferences_lo_per_objective_title')}
      </Heading>
      <Box margin={{ vertical: 'small', horizontal: 'medium' }}>
        <ToggleButtonGroup
          options={optionValues}
          name={'questions-per-lo'}
          selectedOption={numQuestionsPerLoLocal.toString()}
          onSelectionChange={handleQuestionsPerLo}
        />
        <Paragraph size="small" margin="none">
          <span data-test="num-of-lo-per-question" className={styles.bold}>
            {numQuestionsPerLoLocal}{' '}
          </span>
          {t('question_text', { count: numQuestionsPerLoLocal })}
        </Paragraph>
      </Box>

      <Box
        direction={'row'}
        alignSelf="end"
        responsive={true}
        margin="medium"
        pad={{ between: 'medium' }}
      >
        <ButtonMaterial
          label={t('label_cancel')}
          theme={'bordered'}
          onClick={handleClickCancel}
          data-bi="question-preferences-cancel"
        />
        <ButtonMaterial
          label={t('label_apply')}
          onClick={handleApply}
          data-bi="question-preferences-apply"
        />
      </Box>
    </Box>
  )
}

QuestionPreferencesOption.displayName = 'QuestionPreferencesOption'
