import * as _ from 'lodash'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import {
  SHOW_MODAL,
  HIDE_MODAL,
  UPDATE_MODAL,
  REFOCUS_MODAL,
  CLEAR_REFOCUS,
  ModalActions,
} from './modal-actions'

export interface ISingleModalReducerState {
  options: object
  shouldRefocus?: boolean
}

export interface IModalReducerState {
  [modal: string]: ISingleModalReducerState
}

/**
 * Retrieve the options for the modal with the given name, if it is open
 * @param state The root redux state
 * @param modalName The name of the modal whose options to return
 * @returns The modal-specific options provided by the dispatcher of the
 * SHOW_MODAL action that caused the modal to open, or undefined if the
 * modal is not open.
 */
export const getModalOptions = (state: any, modalName: string): any | undefined => {
  const modalState: ISingleModalReducerState = state.global.ui.modal[modalName]
  return modalState && modalState.options
}

/**
 * Determine if any modal is currently open
 * @param state The root redux state
 * @returns True if any modal is open. False otherwise.
 */
export const isAnyModalOpen = (state: any): boolean => {
  const modalState: IModalReducerState = state.global.ui.modal
  return Object.keys(modalState).length > 0
}

/**
 * Determine if given modal is currently opened or not
 *
 * @param state The root redux state
 * @param modalName The name of the modal whose open state to return
 * @returns True if given modal is open. False otherwise.
 */
export const isModalOpen = (state: any, modalName: string): boolean => {
  return !!state.global.ui.modal[modalName]
}

export function modalReducer(
  state: IModalReducerState = {},
  action: ModalActions | LocationChangeAction
): IModalReducerState {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        [action.name]: {
          options: action.options,
        },
      }
    case HIDE_MODAL:
      return _.omit(state, action.name) as Partial<IModalReducerState>
    case UPDATE_MODAL:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          options: {
            ...state[action.name].options,
            ...action.options,
          },
        },
      }
    case REFOCUS_MODAL:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          shouldRefocus: true,
        },
      }
    case CLEAR_REFOCUS:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          shouldRefocus: false,
        },
      }
    case LOCATION_CHANGE:
      return {}
    default:
      return state
  }
}

export default modalReducer
