import { combineReducers } from 'redux'
import {
  taxonomyReducer,
  taxonomyReducerState,
} from 'app/frontend/helpers/taxonomy/taxonomy-reducer'

export const getTaxonomyById = state => state.entities.taxonomy.byId

export type TaxonomyReducerState = {
  byId: taxonomyReducerState
}

export default combineReducers<TaxonomyReducerState>({
  byId: taxonomyReducer,
})
