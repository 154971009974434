import { init } from 'i18next'
import { isProduction } from '../environment'

/**
 * Translation setup config
 */
export const i18n = init({
  lng: 'en',
  ns: ['common'],
  fallbackNS: 'common',
  defaultNS: 'common',
  resources: {
    en: {
      'material-demo': require('./en/material-demo.toml'),
      teach: require('./en/teach.toml'),
      nav: require('./en/nav.toml'),
      comps: require('./en/comps.toml'), // components and compositions
      ...require('./en/common.toml'),
    },
  },
  saveMissing: true,
  missingKeyHandler: (_lng, ns, key) => {
    const message = `i18next: ${ns}:${key} is undefined.`
    // Log an error in prod, throw an error otherwise
    if (isProduction()) {
      console.error(message)
    } else {
      throw Error(message)
    }
  },
})

/**
 * Replacement for translate() HoC
 *
 * t('namespace:key:subkey')
 */
export const t = (key: string, options?: any): string => i18n.t(key, options)

/**
 * Replacement for translate() HoC
 *
 * const t = tns('namespace')
 * t('key:subkey')
 */
export const tns = (namespace: string) => (key: string, options?: any) =>
  t(`${namespace}:${key}`, options)
