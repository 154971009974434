export const ACTIVITY_MODAL = 'ACTIVITY_MODAL'
export const ASSIGNMENT_COMPLETE_MODAL = 'ASSIGNMENT_COMPLETE_MODAL'
export const COMPLETE_MODAL = 'COMPLETE_MODAL'
export const PREVIEW_COMPLETE_MODAL = 'PREVIEW_COMPLETE_MODAL'
export const FEEDBACK_MODAL = 'FEEDBACK_MODAL'
export const FEEDBACK_MODAL_FORM = 'FEEDBACK_MODAL_FORM'
export const MORE_INSTRUCTION_MODAL = 'MORE_INSTRUCTION_MODAL'
export const JOIN_COURSE_MODAL = 'JOIN_COURSE_MODAL'
export const JOIN_COURSE_MODAL_FORM = 'JOIN_COURSE_MODAL_FORM'
export const MISSING_ANSWERS_MODAL = 'MISSING_ANSWERS_MODAL'
export const ASSIGNMENT_TIME_UP_MODAL = 'ASSIGNMENT_TIME_UP_MODAL'
export const QUIZ_TIME_UP_MODAL = 'QUIZ_TIME_UP_MODAL'
export const QUIZ_TIMER_ERROR_MODAL = 'QUIZ_TIMER_ERROR_MODAL'
export const ASSESSMENT_TIMES_UP_MODAL = 'ASSESSMENT_TIMES_UP_MODAL'
export const NEW_PASSWORD_MODAL = 'NEW_PASSWORD_MODAL'
export const PASSWORD_MODAL = 'PASSWORD_MODAL'
export const PASSWORD_MODAL_FORM = 'PASSWORD_MODAL_FORM'
export const ACCESS_CODE_MODAL = 'ACCESS_CODE_MODAL'
export const ACCESS_CODE_FORM = 'ACCESS_CODE_FORM'
export const PAYMENTS_MODAL = 'PAYMENTS_MODAL'
export const PROGRESS_MODAL = 'PROGRESS_MODAL'
export const GET_HELP_MODAL = 'GET_HELP_MODAL'
export const BOOSTERS_MODAL = 'BOOSTERS_MODAL'
export const BREAK_MODAL = 'BREAK_MODAL'
export const DATASET_HELP_MODAL = 'DATASET_HELP_MODAL'
export const FORFEIT_ATTEMPTS_MODAL = 'FORFEIT_ATTEMPTS_MODAL'
export const DEBUG_MODAL = 'DEBUG_MODAL'
export const TIMED_TEST_MODAL = 'TIMED_TEST_MODAL'
export const SCAFFOLD_DEPARTURE_MODAL = 'SCAFFOLD_DEPARTURE_MODAL'
export const SUBMIT_ASSESSMENT_MODAL = 'SUBMIT_ASSESSMENT_MODAL'
