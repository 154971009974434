import * as React from 'react'
import * as _ from 'lodash'
import * as QueryString from 'querystring'
import { LOGIN_ATTEMPT } from 'app/frontend/data/mixpanel-events'
import { sendEvent } from 'app/frontend/helpers/mixpanel'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import * as LogoGoogleSvg from 'app/public/img/logo-google.svg'
import * as styles from './google-oauth-button-material.css'
import { getStaticUrl } from 'app/frontend/helpers/assets'

interface IGoogleOAuthButtonMaterialProps {
  role?: string
  institutionId?: string
  authUrl?: string
  noAuthUrl?: string
  authContext?: any
  className?: string
  google?: any
  label: string
}

class GoogleOAuthButtonMaterial extends React.Component<IGoogleOAuthButtonMaterialProps, {}> {
  buttonHref = (): string => {
    const { origin, pathname, search } = window.location

    let queryObj: any = {},
      currentUrl = origin + pathname

    if (search && search.length > 0) {
      const queryBlacklist = ['role']
      queryObj = QueryString.parse(search.slice(1))

      queryBlacklist.forEach(param => {
        delete queryObj[param]
      })
    }

    if (this.props.role) {
      queryObj.role = this.props.role
    }

    if (this.props.institutionId) {
      queryObj.institution = this.props.institutionId
    }

    if (!_.isEmpty(queryObj)) {
      currentUrl += '?' + QueryString.stringify(queryObj)
    }

    const params: any = {
      authUrl: this.props.authUrl || '/home',
      noAuthUrl: this.props.noAuthUrl || currentUrl,
    }

    if (this.props.authContext) {
      params.authContext = this.props.authContext
    }

    return `/oauth/google/connect?${QueryString.stringify(params)}`
  }

  trackLoginAttempt = (): void => {
    sendEvent(LOGIN_ATTEMPT, {})
  }

  render(): JSX.Element {
    return (
      <ButtonMaterial
        href={this.buttonHref()}
        onClick={this.trackLoginAttempt}
        className={styles.button}
        id="google-oauth-login-button"
        data-bi="google-oauth-login-button"
        icon={
          <img
            className={styles.googleIcon}
            src={getStaticUrl(LogoGoogleSvg)}
            role={'presentation'}
            alt=""
          />
        }
        label={this.props.label}
        theme="secondary"
      />
    )
  }
}

export default GoogleOAuthButtonMaterial
