import { useQuery } from '@apollo/client'
import * as GET_TABLE_OF_CONTENTS_FOR_COURSEPACK from './get-table-of-contents-for-coursepack.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseTableOfContentsForCoursepackResults = RenderProps<{
  titles: GQL.GetTableOfContentsForCoursepack.Titles[]
}>

export const useTableOfContentsForCoursepack = (
  coursepackId: string,
  skip?: boolean
): UseTableOfContentsForCoursepackResults => {
  const { loading, error, data } = useQuery<
    GQL.GetTableOfContentsForCoursepack.Query,
    GQL.GetTableOfContentsForCoursepack.Variables
  >(GET_TABLE_OF_CONTENTS_FOR_COURSEPACK, {
    variables: {
      coursepackId,
      skip: !!skip,
    },
  })

  return {
    loading,
    error,
    titles:
      data && data.getTableOfContentsForCoursepack && data.getTableOfContentsForCoursepack.titles,
  }
}
