import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('datepicker')

export const handleFocusTrap = (e: React.KeyboardEvent<HTMLDivElement>, focusRefs) => {
  let currentFocusIndex = 0
  let nextFocusIndex = 0
  for (let i = 0; i < focusRefs.length; i++) {
    if ((e.target as HTMLDivElement) === focusRefs[i].current) {
      currentFocusIndex = i
    }
  }
  do {
    nextFocusIndex = e.shiftKey ? currentFocusIndex - 1 : currentFocusIndex + 1
    if (nextFocusIndex === -1) {
      nextFocusIndex = focusRefs.length - 1
    }
    if (nextFocusIndex === focusRefs.length) {
      nextFocusIndex = 0
    }
    if (focusRefs[nextFocusIndex].current?.tabIndex === -1) {
      nextFocusIndex = e.shiftKey ? nextFocusIndex - 1 : nextFocusIndex + 1
      if (nextFocusIndex === -1) {
        nextFocusIndex = focusRefs.length - 1
      }
      if (nextFocusIndex === focusRefs.length) {
        nextFocusIndex = 0
      }
    }
    focusRefs[nextFocusIndex].current?.focus()
    currentFocusIndex = nextFocusIndex
  } while (focusRefs[nextFocusIndex].current?.disabled)
}

export const dateErrors = {
  OUTOFBOUNDS: t('date_out_of_bounds'),
  INVALID: t('date_invalid'),
}

// ex: 7/12/21, 7 12 21, 7-12-21, 7 12, 7/12 etc.
export const anyDateFormatRegex = /^(0*[1-9]|1[012])[- /.](0*[1-9]|[12][0-9]|3[01])?([- /.](20\d\d|\d\d))?$/

// ex: 7/12/21 10:22 AM, 7 12 21 3:42, 7-12-21 22:12, 7 12 4:12 AM, 7/12 3:22 PM etc.
export const anyDateTimeFormatRegex = /^(0*[1-9]|1[012])[- /.](0*[1-9]|[12][0-9]|3[01])?([- /.](20\d\d|\d\d)),? ((2[0-3]|[01]?[0-9]):([0-5][0-9])( ?([AP]M))?)$/

// ex: Jan 13, 2021; January 13, 2021, etc.
export const defaultDateYearFormatRegex = /^((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)|(January|February|March|April|May|June|July|August|September|October|November|December)) (([0-9])|([0-2][0-9])|([3][0-1])), \d{4}$/i

// ex: Jan 13; January 13; etc.
export const defaultDateFormatRegex = /^((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)|(January|February|March|April|May|June|July|August|September|October|November|December)) (([0-9])|([0-2][0-9])|([3][0-1]))$/i

// ex Mar 12, 2021 10:32 PM; March 12, 2022 18:03
export const defaultDateTimeYearFormatRegex = /^((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)|(January|February|March|April|May|June|July|August|September|October|November|December)) (([0-9])|([0-2][0-9])|([3][0-1])), \d{4} ((2[0-3]|[01]?[0-9]):([0-5][0-9])( ?([AP]M))?)$/i

// ex Mar 12, 11:11 AM; March 12, 19:59
export const defaultDateTimeFormatRegex = /^((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)|(January|February|March|April|May|June|July|August|September|October|November|December)) (([0-9])|([0-2][0-9])|([3][0-1])), ((2[0-3]|[01]?[0-9]):([0-5][0-9])( ?([AP]M))?)$/i

// ex 12:34 PM, 8:04 AM
export const defaultTimeFormatRegex = /^((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AP]M))/i

// ex 23:59, 0:02, 5:34, 13:00
export const anyTimeFormatRegex = /^(2[0-3]|[01]?[0-9])(:([0-5])\d)?/

// This is used to predict the time as the user inputs it.
// 1 -> 1:00 AM; 7 -> 7:00 AM; 16 -> 4:00 PM
// Also works for minutes
// 1:3 -> 1:30 AM; 4:2 -> 4:15 AM; 7:5 -> 7:45 AM; 75 -> 7:45 AM;
export const predictTimeRegex = /^(2[0-3]|[01]?[0-9]):?([0-5]\d?)?/
