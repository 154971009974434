import * as React from 'react'
import { createPortal } from 'react-dom'
import { Link } from '@mui/material'
import { t } from 'app/frontend/helpers/translations/i18n'
import * as styles from './material-layout.css'

/**
 * Injects (and re-Injects) a Back-To-Top link as the very last element of the page body.
 * This is for accessibility and needs to be *after* asynchronously loaded
 * integrations like the ServiceCloud help button.
 */
export const BackToTopPortal: React.FunctionComponent = () => {
  const div = useAppendDivAsLastChild()

  const handleClick = React.useCallback(() => {
    // See material-layout.tsx
    const target = document.getElementById('skip-to-main')
    if (target) {
      window.scrollTo(0, 0)
      target.focus()
    }
  }, [])

  return createPortal(
    <Link
      href="#"
      role="button"
      onClick={handleClick}
      className={styles.layoutBackToTop}
      data-bi="back-to-top"
    >
      {t('back_to_top')}
    </Link>,
    div
  )
}

/**
 * Creates a  div and forces it to the end of the document body.
 *
 * NOTE: This observes the document body and enforces our node is the last child.
 * You should probably only ever use this once or things will be battling for the bottom.
 */
export const useAppendDivAsLastChild = (node = document.body) => {
  const { current: div } = React.useRef<HTMLDivElement>(
    Object.assign(document.createElement('div'), { className: 'back-to-top-mount' })
  )

  const { current: observer } = React.useRef(
    new MutationObserver(mutationsList => {
      // There are some caveats not being considered here, like nodes being added in batches,
      // but it should work well enough.
      const addedNodes: Node[] = mutationsList.reduce(
        (prev, current) => [...prev, ...current.addedNodes],
        []
      )

      const removedNodes: Node[] = mutationsList.reduce(
        (prev, current) => [...prev, ...current.removedNodes],
        []
      )

      // appendChild will remove you from the parent and (re)add you
      // as the last child. We only want to do this if we've added something
      // new (otherwise we get an infinite loop) and we're not unmounting
      if (!addedNodes.includes(div) && !removedNodes.includes(div)) {
        node.appendChild(div)
      }
    })
  )

  React.useLayoutEffect(() => {
    node.appendChild(div)
    observer.observe(node, { childList: true })
    return () => {
      observer.disconnect()
      node.removeChild(div)
    }
  }, [node])

  return div
}
