export type Context = Pick<WindowContext, 'cdnHost' | 'isDevelopment'>

/**
 * Return a static url for files that are hosted in Frackend. This only applies
 * to absolute urls:
 * NOT changed:
 *  - http://[...]
 *  - https://
 *  - //something/something
 *  - ../something/something
 * Changed:
 *  - /bla/bla/bla
 */
export const getStaticUrl = (url: string, context: Context = window.context): string => {
  // Prefix an absolute url with the cdn host
  if (context.cdnHost && url.match(/^\/[^/]/)) {
    // Turns the url into a cdn url: /asset.1234.js -> https://cdn.com/asset.1234.js
    url = context.cdnHost + url
  }

  // If hot loader enabled, we need to serve jsx files and styles from the webpack dev server
  if (context.isDevelopment && url.match(/\/jsx\/.+\.(js|css|svg|png|jpg|gif|gson)/)) {
    url = `http://${process.env.LOCAL_ASSETS_HOST}:3000${url}`
  }

  return url
}
