import { SequenceStatus } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'

export enum AssessmentSequenceSelectionAction {
  SetSequenceStatus = 'SET_SEQUENCE_STATUS',
}

export type SetSequenceStatusAction = {
  type: AssessmentSequenceSelectionAction.SetSequenceStatus
  sequenceId: string
  state: SequenceStatus
}

export const setSequenceStatus = (
  sequenceId: string,
  state: SequenceStatus
): SetSequenceStatusAction => ({
  type: AssessmentSequenceSelectionAction.SetSequenceStatus,
  sequenceId,
  state,
})
