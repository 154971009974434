import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  icon: {
    position: 'absolute',
    top: '.75rem',
    right: '0',
    fill: theme.palette?.grey[400],
    outlineOffset: '0.12rem',
  },
  static: {
    position: 'relative',
    '& .MuiCalendarPicker-root': {
      backgroundColor: '#FFFFFF',
    },
  },
  input: {
    borderTopLeftRadius: '.25rem',
    borderTopRightRadius: '.25rem',
    minWidth: '8.125rem',
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette?.grey[400]} !important`,
    },
  },
  inputRoot: {
    height: '2.5rem',
    color: theme.palette?.grey[600],
    fontFamily: theme.typography?.fontFamily,
    '& input': {
      width: '70%',
    },
  },
  variantInputRoot: {
    height: 'unset',
  },
  label: {
    paddingTop: '.25rem',
    color: theme.palette?.grey[400],
    fontFamily: theme.typography?.fontFamily,
  },
  variantLabel: {
    paddingTop: 0,
  },
  error: {
    position: 'absolute',
    fontSize: '.75rem',
    color: theme.palette?.error.main,
    display: 'block',
    width: '100%',
    paddingTop: '.1rem',
  },
  variantError: {
    paddingLeft: '.75rem',
  },
}))

export const useCalendarStyles = makeStyles(theme => ({
  day: {
    color: theme.palette?.grey[600],
    width: 36,
    height: 36,
    fontSize: '1rem',
    margin: '0 2px',
    fontFamily: theme.typography?.fontFamily,
    padding: 0,
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette?.blue[100],
    },
  },
  dayFocused: {
    backgroundColor: 'rgba(33, 33, 33, 0.16)',
  },
  dayFocusedNotCalendar: {
    border: '.175rem solid',
    borderColor: theme.palette?.grey[300],
  },
  daySelected: {
    border: 'none',
    color: theme.palette?.primary.contrastText,
    backgroundColor: theme.palette?.primary.main,
    '&:hover': {
      backgroundColor: theme.palette?.primary.main,
    },
  },
  dayDisabled: {
    pointerEvents: 'none',
    color: theme.palette?.grey[300],
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  today: {
    fontWeight: 600,
  },
  calendar: {
    position: 'absolute',
    zIndex: 1,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 8px rgba(0, 0, 0, 0.24)',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
  },
}))
