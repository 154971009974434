import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import MaterialSnackbar from 'accessible-react-toolbox/lib/snackbar'
import { SEARCH_TASK_STATUSES_QUERY } from 'app/frontend/pages/material/teach/compositions/connected/search-task-statuses'
import {
  hideGradeUpdateSnackbarAction,
  updateGradeUpdateSnackbarStatusAction,
} from 'app/frontend/components/material/grade-update-snackbar/grade-update-snackbar-actions'
import {
  getGradeUpdateSnackbarState,
  GradeUpdateSnackbarReducerState,
} from 'app/frontend/components/material/grade-update-snackbar/grade-update-snackbar-reducer'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as LogReporter from 'app/frontend/helpers/log-reporter'
import * as styles from './grade-update-snackbar.css'

const t = tns('teach:grade_update_snackbar')

const POLL_INTERVAL_MS = 5000

const snackbarTheme = {
  snackbar: styles.snackbar,
  label: styles.label,
  button: styles.button,
  active: styles.active,
}

export enum GradeUpdateState {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export const GradeUpdateSnackbar: React.FunctionComponent = () => {
  const [active, setActive] = React.useState(false)
  const [clicked, setClicked] = React.useState(false)
  const [updateGradeState, setUpdateGradeState] = React.useState(GradeUpdateState.IN_PROGRESS)
  const [pendingTasks, setPendingTasks] = React.useState(0)
  const client = useApolloClient()

  const {
    userId,
    updatedAfter,
    lastShownAt,
    sectionId,
  } = useSelector((state: GradeUpdateSnackbarReducerState) => getGradeUpdateSnackbarState(state))

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (updateGradeState === GradeUpdateState.IN_PROGRESS) {
      setActive(true)
      const intervalId = setInterval(getPendingAsyncGradeUpdateTasks, POLL_INTERVAL_MS)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [lastShownAt, updateGradeState, pendingTasks])

  const hide = () => {
    setActive(false)
    setTimeout(() => {
      dispatch(hideGradeUpdateSnackbarAction())
    }, 750)
  }

  const onClick = () => {
    if (!clicked) {
      setClicked(true)
      hide()
      window.location.reload()
    }
  }

  const createSearchRequest = (
    initiatorId: string,
    count: number,
    taskStatus?: GQL.TaskStatusStatus,
    taskCreatedAfter?: number
  ): GQL.TaskStatusSearchInput => {
    const filters = {
      type: 'ENROLLMENT_REGRADE' as GQL.TaskType,
      initiatorId,
      status: taskStatus,
      createdAfter: taskCreatedAfter,
      sectionId: sectionId,
    }
    return {
      filters: filters,
      pagination: {
        count: count,
      },
    }
  }

  const getTaskStatusResult = async (searchTaskRequest: GQL.TaskStatusSearchInput) => {
    const searchTaskStatusesResult = (await client.query({
      query: SEARCH_TASK_STATUSES_QUERY,
      variables: { request: searchTaskRequest },
      fetchPolicy: 'network-only',
    })) as ApolloQueryResult<GQL.SearchTaskStatuses.Query>

    if (searchTaskStatusesResult.errors) {
      LogReporter.error(t('error_searching'), searchTaskStatusesResult.errors)
      return 0
    }
    return searchTaskStatusesResult.data.searchForTaskStatuses.results.length
  }

  const getPendingAsyncGradeUpdateTasks = async () => {
    const pendingTaskRequest = createSearchRequest(
      userId,
      100,
      'PENDING' as GQL.TaskStatusStatus,
      updatedAfter
    )

    const pendingTasksCount = await getTaskStatusResult(pendingTaskRequest)
    if (pendingTasksCount > 0) {
      setPendingTasks(pendingTasksCount)
    } else {
      const inprogressTaskRequest = createSearchRequest(
        userId,
        100,
        'IN_PROGRESS' as GQL.TaskStatusStatus,
        updatedAfter
      )
      const inprogressTasksCount = await getTaskStatusResult(inprogressTaskRequest)
      if (inprogressTasksCount > 0) {
        setPendingTasks(inprogressTasksCount)
      } else {
        const failedTaskRequest = createSearchRequest(
          userId,
          100,
          'FAILED' as GQL.TaskStatusStatus,
          updatedAfter
        )
        const failedTasksCount = await getTaskStatusResult(failedTaskRequest)
        setUpdateGradeState(failedTasksCount > 0 ? GradeUpdateState.FAILED : GradeUpdateState.DONE)
        if (failedTasksCount === 0) {
          dispatch(updateGradeUpdateSnackbarStatusAction(null))
        } else {
          setPendingTasks(failedTasksCount)
        }
      }
    }
  }

  const getPendingTasksMessage = (pendingFailedTasks: number): JSX.Element => {
    const labelForPendingTask = t('grade_update_failed')
    const pendingTasksText = t('pending_grade_update_tasks', {
      pendingTasks: pendingFailedTasks < 100 ? `${pendingFailedTasks}` : '100+',
    })
    return (
      <>
        <div className={styles.label}>{labelForPendingTask}</div>
        <div className={styles.progressText}>{pendingTasksText}</div>
      </>
    )
  }

  const label =
    updateGradeState === GradeUpdateState.IN_PROGRESS
      ? t('updating_grades')
      : updateGradeState === GradeUpdateState.DONE
      ? t('grade_updated')
      : getPendingTasksMessage(pendingTasks)

  const action = updateGradeState === GradeUpdateState.DONE ? t('refresh') : null
  return (
    <MaterialSnackbar
      label={label}
      action={action}
      onClick={onClick}
      active={active}
      timeout={null}
      theme={snackbarTheme}
    />
  )
}
