import * as React from 'react'
import { AlertBox } from 'app/frontend/components/material/alert-box/alert-box'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions.css'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { ParentEntityType } from 'app/typings/commons'
import { useAssessmentQuestionsMetadata } from 'app/frontend/pages/material/teach/assessment-builder/assessment-question-lo-counts'
import {
  QuestionCountWarnings,
  UsedQuestions,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/warning-messages'
import { Box } from 'app/frontend/components/material/box'
import { Icon } from 'app/frontend/components/material/icon'
import { Paragraph } from 'app/frontend/components/material/paragraph'

const t = tns('teach:assessment_questions')

interface Props {
  parentEntityType: ParentEntityType
  assessmentId: string
  parentEntityId: string
  customItemVariationLength?: number
}

export const AssessmentBuilderWarningAlert: React.FunctionComponent<Props> = ({
  parentEntityType,
  assessmentId,
  parentEntityId,
  customItemVariationLength,
}) => {
  const [displayAlertBox, setDisplayAlertBox] = React.useState(true)
  const {
    assessmentQuestionMetadata: {
      notLdbFriendlyCount,
      notPrintFriendlyCount,
      repeatablePoolQuestionCount,
      repeatableStaticQuestionCount,
      printableVariantsCount,
      maxNumAttempts,
      usedQuestionsCount,
    },
    error,
    loading,
  } = useAssessmentQuestionsMetadata(
    assessmentId,
    parentEntityId,
    parentEntityType,
    customItemVariationLength
  )

  const usedQuestionsData: UsedQuestions = {
    questionCount: usedQuestionsCount,
    assessmentType: parentEntityType,
  }

  // check for multiple attempts & versions warning messages are available or not
  const multipleAttemptsWarning =
    (repeatablePoolQuestionCount || repeatableStaticQuestionCount) &&
    (maxNumAttempts > 1 || printableVariantsCount > 1)
  // Alert component should display if one of following variable having value grater than 0
  const displayDismissibleAlertBox =
    usedQuestionsCount || notLdbFriendlyCount || notPrintFriendlyCount || multipleAttemptsWarning

  if (error || loading || !displayDismissibleAlertBox) {
    return null
  }

  /**
   * Function that handle the visibility of AlertBox
   */
  const handleOnHide = () => {
    setDisplayAlertBox(false)
  }

  return (
    displayAlertBox && (
      <AlertBox onDismiss={handleOnHide}>
        <Box
          direction="row"
          responsive={true}
          alignItems="center"
          justify="center"
          margin={{ top: 'medium' }}
          data-test="header-container"
        >
          <Icon name={'icon-warning'} className={styles.icon} />
          <Paragraph className={styles.header} margin="none" data-test="header">
            <strong>{t('assessment_builder_warning_alert_header')}</strong>
          </Paragraph>
        </Box>

        <QuestionCountWarnings
          usedQuestionsCount={usedQuestionsData}
          messageStyle={styles.message}
          ldbIncompatibleCount={notLdbFriendlyCount}
          poolQuestionCount={repeatablePoolQuestionCount}
          staticQuestionCount={repeatableStaticQuestionCount}
          printIncompatibleCount={notPrintFriendlyCount}
          printableVersionsCount={printableVariantsCount}
          multipleAttemptsCount={maxNumAttempts}
        />
      </AlertBox>
    )
  )
}

AssessmentBuilderWarningAlert.displayName = 'AssessmentBuilderWarningAlert'
