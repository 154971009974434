import type { ApolloError } from '@apollo/client'
import type { ErrorInfo } from 'apollo-errors'
import type { GraphQLError } from 'graphql'
import { Maybe } from 'graphql/jsutils/Maybe'

/**
 * Data populated by our backend resolvers and middleware.
 */
export interface CustomErrorInfo extends ErrorInfo {
  data?: {
    errorType?: string
    statusCode?: number
    [key: string]: any
  }
}

/**
 * A GraphQLError that contains our CustomErrorInfo
 */
export interface CustomGraphQLError extends GraphQLError {
  extensions: Maybe<{
    exception?: CustomErrorInfo
  }>
}

/**
 * The top-most error thrown by the ApolloClient. Your application will catch
 * these.
 */
export interface CustomApolloError extends ApolloError {
  graphQLErrors: ReadonlyArray<CustomGraphQLError>
}

/**
 * Go through the graphql errors created
 * from a failed Apollo query
 */
export const eachGqlError = (
  e: CustomApolloError | ApolloError,
  callback: (gqlError: CustomErrorInfo) => void
): void => {
  if (e.graphQLErrors && e.graphQLErrors.length) {
    for (const ge of e.graphQLErrors) {
      const gqlError = ge?.extensions?.exception as CustomErrorInfo
      if (!!gqlError?.data) {
        callback(gqlError)
      }
    }
  }
}

/**
 * Go through the graphql errors and look
 * for a specific error code or type on error.extensions.exception.data
 */
export const hasGqlError = (
  e: CustomApolloError | ApolloError,
  match: { errorType?: string; statusCode?: number }
): boolean => {
  let hasError = false
  if (e) {
    eachGqlError(e, ge => {
      if (
        (match.errorType && ge.data.errorType === match.errorType) ||
        (match.statusCode && ge.data.statusCode === match.statusCode)
      ) {
        hasError = true
      }
    })
  }
  return hasError
}
