import { Action } from 'redux'
import { LtiCredentialsSearchActions } from 'app/frontend/pages/material/knerd/lti/lti-credentials-search-actions'

const ActionTypes = LtiCredentialsSearchActions.ActionTypes

export interface LtiCredentialsSearchState {
  query: string
  debouncedQuery: string
  sortBy: GQL.LtiCredentialSearchSortBy
  sortOrder: GQL.SearchSortOrder
}

const defaultState = {
  query: '',
  debouncedQuery: '',
  sortBy: 'MODIFIED_AT' as GQL.LtiCredentialSearchSortBy,
  sortOrder: 'DESC' as GQL.SearchSortOrder,
}

export function reducer(state: LtiCredentialsSearchState = defaultState, action: Action & any) {
  switch (action.type) {
    case ActionTypes.UpdateLtiCredentialsQuery:
      return {
        ...state,
        query: action.query,
      }
    case ActionTypes.UpdateLtiCredentialsDebouncedQuery:
      return {
        ...state,
        debouncedQuery: action.query,
      }
    case ActionTypes.UpdateLtiCredentialsSortBy:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case ActionTypes.UpdateLtiCredentialsSortOrder:
      return {
        ...state,
        sortOrder: action.sortOrder,
      }
    default:
      return state
  }
}
