import * as React from 'react'

interface OwnProps {
  videoId: string
  clipStartAtSeconds?: number
  clipEndAtSeconds?: number
}

/**
 * Constructs a link to a specific YouTube video.
 *
 * YouTube supports both start and end parameters to splice the video, but ONLY if you use the
 * /embed route. From what I can gather it is no longer supported on /watch. This seems to be
 * an undocumented API, thus may be prone to breakage.
 */
export const YouTubeLink: React.FunctionComponent<OwnProps> = props => {
  const params = makeQueryParams({
    start: props.clipStartAtSeconds,
    end: props.clipEndAtSeconds,
  })

  return (
    <a
      href={`https://www.youtube.com/embed/${props.videoId}?${params}`}
      target="_blank"
      data-bi="youtube-link"
    >
      {props.children}
    </a>
  )
}

YouTubeLink.displayName = 'YouTubeLink'

/**
 * Constructs a link to a specific Vimeo video.
 *
 * Vimeo does not support clipping the end of a video via hyperlink. Since we only use this
 * component to rectify errors, we'll just assume this case is minimally hit.
 */
export const VimeoLink: React.FunctionComponent<OwnProps> = props => {
  const params = makeQueryParams({
    t: props.clipStartAtSeconds && props.clipStartAtSeconds + 's', // mark it as seconds
  })

  return (
    <a href={`https://vimeo.com/${props.videoId}#${params}`} target="_blank" data-bi="vimeo-link">
      {props.children}
    </a>
  )
}

VimeoLink.displayName = 'VimeoLink'

/**
 * Creates a bunch of '&' separated key=value pairs, removing any that are not truthy.
 * Because of the limited usage, we don't bother url encoding the keys or values.
 *
 * makeQueryParams({
 *   foo: 0,
 *   bar: 'Yes',
 *   baz: True,
 * }) // bar=Yes&baz=True
 */
function makeQueryParams(params) {
  return Object.keys(params)
    .sort()
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
}
