'use strict'

/**
 * Google reCaptcha Helper
 */
export const ReCaptcha = {
  /**
   * List of callbacks queue while the widget is getting loaded.
   */
  callbacks: [],

  /**
   * State of the library (NOT_LOADED, LOADING, LOADED).
   */
  state: 'NOT_LOADED',

  /**
   * Loads the reCaptcha widget and calls the callback when is it loaded.
   */
  load: function (callback) {
    // set callback
    if (!window.onGoogleRecaptchaAPIReady) {
      window.onGoogleRecaptchaAPIReady = this.onLoaded.bind(this)
    }

    if (this.state === 'NOT_LOADED') {
      // add script to page
      const tag = document.createElement('script')
      const head = document.getElementsByTagName('head')[0]
      tag.src = '//www.google.com/recaptcha/api.js?onload=onGoogleRecaptchaAPIReady&render=explicit'
      tag.async = true
      tag.onerror = this.onError.bind(this)
      head.appendChild(tag)

      // update state
      this.state = 'LOADING'
    }

    if (this.state === 'LOADED') {
      callback()
    } else {
      this.callbacks.push(callback)
    }
  },

  /**
   * Called the the script failed to be loaded.
   */
  onError: function () {
    this.state = 'NOT_LOADED'
    console.error('Failed to load the google recaptcha script')
  },

  /**
   * Called by the google recaptcha when loaded.
   */
  onLoaded: function () {
    // update state
    this.state = 'LOADED'

    // call callbacks
    this.callbacks.forEach(callback => callback())

    this.callbacks = []
  },
}

export default ReCaptcha
