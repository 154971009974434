import { listenForMathProcessingError, listenForTexJaxError } from './signals'

/**
 * Light-weight API on top of the various MathJax Message handlers and signals.
 *
 * Instantiate this object and listen for both LaTeX and Math Processing Errors.
 *
 *     const errors = MathJaxErrors()
 *     console.log(numErrors)
 *     errors.unsubscribe() // stop listening to errors
 *
 * These handlers do not "replay" old messages, so will only record errors encountered after the
 * registration.
 *
 */
class ErrorHandler {
  /**
   * The number of errors signaled
   */
  numErrors = 0

  private unsubscriptions: MathJaxHookCb[] = []

  /**
   * Increments our error count
   *
   * NOTE: This is an arrow function, as it needs to be bound to 'this' when passed as a callback
   */
  handleError = () => {
    this.numErrors++
  }

  /**
   * Unsubscribe to our error handlers
   */
  unsubscribe() {
    for (const unsub of this.unsubscriptions) {
      unsub()
    }
    this.unsubscriptions = []
  }

  /**
   * Subscribe to our error handlers
   *
   * NOTE: Only call this if you are sure MathJax is loaded on the page!
   */
  subscribe() {
    if (this.unsubscriptions.length > 0) {
      return // already subscribed
    }
    this.unsubscriptions = [
      listenForMathProcessingError(this.handleError),
      listenForTexJaxError(this.handleError),
    ]
  }

  /**
   * Resets our mechanisms for tracking errors
   */
  reset() {
    this.numErrors = 0
  }
}

export default ErrorHandler
