import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import * as keycode from 'keycode'
import { copyToClipboard } from 'app/frontend/helpers/copy-to-clipboard'

// Get all the data-bi attributes in this element and its parents
const getDataBiAttrs = (ele: Element): string[] => {
  const tags = []
  while (ele) {
    if (ele.hasAttribute('data-bi')) {
      tags.push(ele.getAttribute('data-bi'))
    }
    ele = ele.parentElement
  }
  return tags.reverse()
}

export const PendoTagger: React.FunctionComponent<{}> = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const [currentElement, setCurrentElement] = useState<Element>(null)
  const [attrs, setAttrs] = useState<string[]>([])
  const attrsRef = useRef<string[]>([])

  const onMouseMove = (e: MouseEvent) => {
    const ele = document.elementFromPoint(e.clientX, e.clientY)
    if (
      ele.parentElement?.getAttribute('id') !== 'pendo-tagger' &&
      ele.getAttribute('id') !== 'pendo-tagger'
    ) {
      setCurrentElement(ele)
      setAttrs(getDataBiAttrs(ele))
      attrsRef.current = getDataBiAttrs(ele)
    }
  }

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.altKey && !e.shiftKey && keycode(e) === 't') {
      setVisible(true)
      document.addEventListener('mousemove', onMouseMove, false)
    }
  }

  const onKeyUp = (e: KeyboardEvent) => {
    if (keycode(e) === 't') {
      // Copy to clipboard and hide elements
      copyToClipboard(attrsRef.current.map(attr => `[data-bi="${attr}"]`).join(' '))
      setVisible(false)
      document.removeEventListener('mousemove', onMouseMove, false)
    }
  }

  useEffect(() => {
    // Listen to key events
    document.addEventListener('keydown', onKeyDown, false)
    document.addEventListener('keyup', onKeyUp, false)

    return () => {
      // Stop listening for key events
      document.removeEventListener('keydown', onKeyDown, false)
      document.removeEventListener('keyup', onKeyUp, false)
    }
  }, [])

  if (!currentElement || !visible) {
    return null
  }

  const rect = currentElement.getBoundingClientRect()
  return (
    <div id="pendo-tagger" style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0 }}>
      <div
        style={{
          top: rect.top + window.pageYOffset - 3,
          left: rect.left + window.pageXOffset - 3,
          width: rect.width + 3 * 2,
          height: rect.height + 3 * 2,
          position: 'absolute',
          border: `3px dashed ${attrs.length ? '#00A87D' : '#F36469'}`,
          pointerEvents: 'none',
          zIndex: 2147483647,
        }}
      />
      <div
        style={{
          top: 0,
          left: 0,
          right: 0,
          position: 'fixed',
          background: attrs.length ? '#00A87D' : '#F36469',
          whiteSpace: 'pre',
          padding: 8,
          zIndex: 2147483647,
        }}
      >
        {attrs.length > 0 ? (
          attrs.map((attr, i) => <div key={i}>[data-bi={attr}]</div>)
        ) : (
          <div>No data-bi found for the current selection</div>
        )}
      </div>
    </div>
  )
}
