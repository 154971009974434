import * as React from 'react'
import * as styles from './main.css'

interface OwnProps {
  className?: string
}

/**
 *
 * The .mainInner is a decoy element
 * inside of the actual <main />
 *
 * tabIndex="-1" is meant to apply
 * focus to an element programatically,
 * however it has the unfortunate
 * side-effect but being focusable just
 * by clicking on a nested element,
 * unnecessarily triggering the focus
 * outline display.
 *
 * .mainInner is meant to hijack the focus
 * onClick of a descendent element, and
 * intenetionally show no outline.
 *
 * The second nested <div /> is for applying
 * custom CSS (max-width, padding, margin, etc.).
 *
 */
export const MainMaterial: React.FunctionComponent<OwnProps> = props => {
  return (
    <main role="main" tabIndex={-1}>
      <div tabIndex={-1} className={styles.mainInner}>
        <div className={props.className}>{props.children}</div>
      </div>
    </main>
  )
}

MainMaterial.displayName = 'MainMaterial'

export default MainMaterial
