import { useQuery } from '@apollo/client'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import * as LEARNING_OBJECTIVE_BY_ID from './learning-objective-by-id.gql'

export type UseLearningObjectiveByIdResults = RenderProps<GQL.GetLearningObjectiveById.Query>

export const useLearningObjectiveById = (
  id: string,
  skip?: boolean
): UseLearningObjectiveByIdResults => {
  const { loading, error, data } = useQuery<
    GQL.GetLearningObjectiveById.Query,
    GQL.GetLearningObjectiveById.Variables
  >(LEARNING_OBJECTIVE_BY_ID, {
    variables: { id, skip: !!skip },
  })

  return {
    loading,
    error,
    ...data,
  }
}
