import * as React from 'react'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { connect } from 'react-redux'
import { hideModal } from 'app/frontend/components/material/modal/modal-actions'
import * as styles from './child-user.css'
import { tns } from 'app/frontend/helpers/translations/i18n'

export const CHILD_USER_MODAL = 'CHILD_USER_MODAL'
const t = tns(CHILD_USER_MODAL)

namespace ChildUserModal {
  export interface StateProps {}

  export interface DispatchProps {
    closeModal: () => void
  }

  export interface OwnProps {}

  export type Props = StateProps & DispatchProps & OwnProps
}

const ChildUserModal: React.FunctionComponent<ChildUserModal.Props> = ({ closeModal }) => (
  <Modal name={CHILD_USER_MODAL} dataBi="child-user-modal" fullScreen={true}>
    <ModalBody>
      <h1 id="modalTitle" className={styles.header}>
        {t('header')}
      </h1>
      {t('text')}
    </ModalBody>
    <ModalFooter>
      <ButtonMaterial label={t('ok_button')} data-bi="ok-button" onClick={closeModal} />
    </ModalFooter>
  </Modal>
)

ChildUserModal.displayName = 'ChildUserModal'

const mapDispatchToProps = (dispatch): ChildUserModal.DispatchProps => ({
  closeModal: () => dispatch(hideModal(CHILD_USER_MODAL)),
})

export default connect<
  ChildUserModal.StateProps,
  ChildUserModal.DispatchProps,
  ChildUserModal.OwnProps
>(
  null,
  mapDispatchToProps
)(ChildUserModal)
