import type { ModalOptions } from './types'

export type SHOW_CONTENT_FEEDBACK_MODAL = 'SHOW_CONTENT_FEEDBACK_MODAL'
export const SHOW_CONTENT_FEEDBACK_MODAL: SHOW_CONTENT_FEEDBACK_MODAL =
  'SHOW_CONTENT_FEEDBACK_MODAL'

export type ShowContentFeedbackModalAction = {
  type: SHOW_CONTENT_FEEDBACK_MODAL
  options: ModalOptions
}

/**
 * Thin wrapper around showModal(CONTENT_FEEDBACK_MODAL)
 */
export const showContentFeedbackModal = (options: ModalOptions): ShowContentFeedbackModalAction => {
  return {
    type: SHOW_CONTENT_FEEDBACK_MODAL,
    options,
  }
}
