import * as React from 'react'
import CircleSpinner from 'app/frontend/content/circle-spinner/circle-spinner'
import * as classNames from 'classnames'
import { tns } from 'app/frontend/helpers/translations/i18n'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'
import { RefObject } from 'react'
import { getDesmos } from 'app/frontend/content/atoms/desmos-atom/desmos-helper'

const t = tns('desmos_atom')
const styles = require('./desmos-atom.css')

export interface Props {
  atom: GQL.AtomMedia
  contentManager: ContentManagerActionsType
}

export type State = {
  status: 'LOADING' | 'READY' | 'ERROR'
}

export default class DesmosAtom extends React.Component<Props, State> {
  private timeoutId: number

  private readonly desmosRef: RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.state = { status: 'LOADING' }

    this.desmosRef = React.createRef<HTMLDivElement>()
  }

  async componentDidMount() {
    try {
      await getDesmos()
      this.observeDesmos()
      this.mountDesmosGraph()
    } catch (err) {
      this.setState({ status: 'ERROR' })
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.status !== 'ERROR' && this.state.status === 'ERROR') {
      this.props.contentManager.addError({ message: 'Error loading desmos.', isRetryable: true })
    } else if (prevState.status === 'LOADING' && this.state.status === 'READY') {
      this.props.contentManager.success()
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
    }
  }

  /**
   * The observer will set the loading status to "READY" once the children list of this.desmosRef
   * changes. The graph table will be rendered when the callback is triggered based on my test.
   * It should be good enough as the graph details (e.g. the lines on the table) are not predictable
   * and this solution tries to minimize the overhead of the observer.
   */
  observeDesmos(): void {
    const callback = (mutationsList, observer) => {
      // Cancel the observer and mark the status ready once the childList changes
      const childNodesChanged = mutationsList.some(mutation => mutation.type === 'childList')
      if (childNodesChanged) {
        observer.disconnect()
        this.setState({ status: 'READY' })
      }
    }

    const mutationObserver = new window.MutationObserver(callback)
    mutationObserver.observe(this.desmosRef.current, {
      attributes: false,
      childList: true,
      subtree: false,
    })

    // The timeout of 10 seconds is based on the test case of rendering 20 Desmos graphs at a time.
    this.timeoutId = window.setTimeout(() => {
      if (this.state.status !== 'READY') {
        mutationObserver.disconnect()
        console.error('Loading Desmos failed due to the timeout', {
          internalIssueId: 'CE-3634',
        })
        this.setState({ status: 'ERROR' })
      }
    }, 10000)
  }

  /**
   * Generates Desmos graph with the input media data
   */
  mountDesmosGraph(): void {
    const desmosGraphData = this.props.atom.data as GQL.AtomDesmosGraphData
    const { graphState, showAlgebra, allowZoom } = desmosGraphData
    const options = {
      // Details: https://www.desmos.com/api/v1.2/docs/index.html#document-calculator
      // Whether to allow the student to enter and manipulate algebra expressions
      expressions: showAlgebra,
      // Whether to allow the student to zoom and/or pan the window
      lockViewport: !allowZoom,
      // Do not show the wrench settings menu to students on any graph
      // If we ever wanted to enable this, we could make it an option on the author side
      settingsMenu: false,
    }
    const calculator = window.Desmos.GraphingCalculator(this.desmosRef.current, options)
    try {
      calculator.setState(JSON.parse(graphState))
    } catch (err) {
      console.error('Desmos failed to parse the graph state', {
        internalIssueId: 'CE-3634',
      })
      this.setState({ status: 'ERROR' })
    }
  }

  renderErrorElement = (): JSX.Element => (
    <div className={classNames(styles.atomImage, styles.error)}>{t('error')}</div>
  )

  /**
   * Renders the component.
   */
  render(): JSX.Element {
    if (this.state.status === 'ERROR') {
      return this.renderErrorElement()
    }

    return (
      <div className={styles.atomDesmos} aria-busy={this.state.status === 'LOADING'}>
        {this.state.status === 'LOADING' && (
          <div aria-label={t('loading')} className={styles.spinner}>
            <CircleSpinner />
          </div>
        )}
        <div ref={this.desmosRef} data-test="atom-desmos-wrapper" className={styles.desmosAnchor} />
      </div>
    )
  }
}
