import * as React from 'react'
import { Field } from 'redux-form'
import { MenuItem } from '@mui/material'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { FormNames } from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/form-fields/helper'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'
import { isEnabled } from 'app/frontend/helpers/feature'
import { DropdownValues, SettingName, Props } from './helper'
import { useStyles } from './styles'
import { SettingFieldWithSelect } from './setting-field-with-select'

const t = tns('teach:assessment_builder')

export const renderGradeSetting = (field: WrappedFieldPropsStub & Props): React.ReactNode => {
  const styles = useStyles()
  const { isReadOnly, input } = field
  const gradeSettingDropdownOptions: React.ReactNode[] = [
    <MenuItem
      key={`${SettingName.GRADE}-${DropdownValues.AFTER_SUBMISSION}`}
      value={DropdownValues.AFTER_SUBMISSION}
      classes={{ root: styles.menuItemRoot }}
      data-bi={DropdownValues.AFTER_SUBMISSION}
    >
      {t('setting_dropdown_after_submission')}
    </MenuItem>,
    <MenuItem
      key={`${SettingName.GRADE}-${DropdownValues.AFTER_DUE_DATE}`}
      value={DropdownValues.AFTER_DUE_DATE}
      classes={{ root: styles.menuItemRoot }}
      data-bi={DropdownValues.AFTER_DUE_DATE}
    >
      {t('setting_dropdown_on_due_date')}
    </MenuItem>,
    // TODO: FF cleanup under YALA-1322
    ...(isEnabled('upgradeAssessmentSettingM2Flag') && [
      <MenuItem
        key={`${SettingName.GRADE}-${DropdownValues.MANUALLY}`}
        value={DropdownValues.MANUALLY}
        classes={{ root: styles.menuItemRoot }}
        data-bi={DropdownValues.MANUALLY}
      >
        {t('setting_dropdown_manually')}
      </MenuItem>,
    ]),
  ]

  return (
    <SettingFieldWithSelect
      header={t('setting_results_grade')}
      description={t('setting_results_grade_description')}
      isReadOnly={isReadOnly}
      settingName={SettingName.GRADE}
      menuItems={gradeSettingDropdownOptions}
      input={input}
    />
  )
}

export const GradeSettingField: React.FunctionComponent<Props> = ({ isReadOnly }) => (
  <Field
    isReadOnly={isReadOnly}
    name={FormNames.VIEW_RESULTS_GRADE_SETTING}
    component={renderGradeSetting}
  />
)
