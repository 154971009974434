export namespace CoursepackSearchActions {
  export enum ActionTypes {
    UpdateCoursepacksQuery = 'UPDATE_COURSEPACKS_QUERY',
    UpdateCoursepacksDebouncedQuery = 'UPDATE_COURSEPACKS_DEBOUNCED_QUERY',
    UpdateCoursepacksSortBy = 'UPDATE_COURSEPACKS_SORT_BY',
    UpdateCoursepacksSortOrder = 'UPDATE_COURSESPACKS_SORT_ORDER',
    ToggleIncludeTestCoursepacks = 'TOGGLE_INCLUDE_TEST_COURSESPACKS',
  }

  export function updateQuery(query: string) {
    return {
      type: ActionTypes.UpdateCoursepacksQuery,
      query,
    }
  }

  export function updateDebouncedQuery(query: string) {
    return {
      type: ActionTypes.UpdateCoursepacksDebouncedQuery,
      query,
    }
  }

  export function updateSortBy(sortBy: string) {
    return {
      type: ActionTypes.UpdateCoursepacksSortBy,
      sortBy,
    }
  }

  export function updateSortOrder(sortOrder: string) {
    return {
      type: ActionTypes.UpdateCoursepacksSortOrder,
      sortOrder,
    }
  }

  export function toggleIncludeTestCoursepacks() {
    return {
      type: ActionTypes.ToggleIncludeTestCoursepacks,
    }
  }
}
