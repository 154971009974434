import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Icon } from 'app/frontend/components/material/icon'
import { isPrintable } from 'app/frontend/helpers/assignment'
import { copyToClipboard } from 'app/frontend/helpers/copy-to-clipboard'
import * as LtiConstants from 'app/frontend/data/lti-constants'
import { connect } from 'react-redux'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './lms-resync-warning.css'

const t = tns('teach:assessment_builder')

export interface OwnProps {
  assessment: GQL.GetAssignment.Assignment
}

interface DispatchProps {
  showMessage: (messaage: string) => void
}

export interface State {
  active: boolean
  isOffline: boolean
}

type Props = OwnProps & DispatchProps

export class _LmsResyncWarning extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { active: false, isOffline: isPrintable(props.assessment) }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const isOffline = isPrintable(props.assessment)
    const switchedToOnline = !isOffline && state.isOffline
    const active = switchedToOnline || state.active

    return { ...state, active, isOffline }
  }

  onDismiss = () => this.setState({ active: false })

  onCopy = () => {
    const { assessment, showMessage } = this.props
    const ltiLaunchUrl = `${window.location.origin}${LtiConstants.COURSEWORK_LAUNCH}${assessment.lmsCourseworkId}`
    copyToClipboard(ltiLaunchUrl)
      .then(() => showMessage(t('lms_sync_copy_success')))
      .catch(err => {
        console.warn('Failed to copy text', err)
        showMessage(t('lms_sync_copy_error'))
      })
  }

  render(): JSX.Element {
    const { active } = this.state
    if (!active) {
      return null
    }

    return (
      <Box
        direction="row"
        responsive={false}
        pad="medium"
        margin={{ bottom: 'xlarge' }}
        className={styles.container}
      >
        <Icon name="icon-warning" size="large" className={styles.warningIcon} />
        <div>
          <Paragraph weight="semibold" margin="none" className={styles.text}>
            {t('lms_sync_warning_title')}
          </Paragraph>
          <Paragraph className={styles.text}>{t('lms_sync_warning_description')}</Paragraph>
          <Box direction="row" responsive={false} justify="end">
            <ButtonMaterial
              theme="bordered"
              label={t('lms_sync_cta_hide')}
              onClick={this.onDismiss}
              className={styles.button}
              data-bi="lms-resync-dismiss"
            />
            <ButtonMaterial
              theme="bordered"
              label={t('lms_sync_cta')}
              icon={<Icon name="icon-copy" size="medium" className={styles.copyIcon} />}
              onClick={this.onCopy}
              className={styles.button}
              data-bi="lms-resync-cta"
            />
          </Box>
        </div>
      </Box>
    )
  }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showMessage: (message: string) => dispatch(showSnackbar({ message })),
})

export const LmsResyncWarning = connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(_LmsResyncWarning)
