import * as React from 'react'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './refresh-atom-variation-button.css'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Tooltip } from 'app/frontend/components/tooltip'
import { sendEvent } from 'app/frontend/helpers/mixpanel'

const t = tns('teach:assessment_builder')

interface Props {
  showTooltip?: boolean
  onRefreshAtomVariation: () => void
  mpEventType?: string
}

export const RefreshAtomVariationButton: React.FunctionComponent<Props> = ({
  onRefreshAtomVariation,
  showTooltip = true,
  mpEventType,
}) => {
  const onClickRefreshButton = () => {
    onRefreshAtomVariation()
    if (mpEventType) {
      sendEvent(mpEventType)
    }
  }

  return (
    <Tooltip title={t('refresh_content_tooltip')} placement="bottom" disabled={!showTooltip}>
      <ButtonMaterial
        icon={<Icon name="icon-refresh" className={styles.refreshIcon} />}
        data-bi="refresh-atom-variation-button"
        label={t('refresh_content')}
        theme="bordered"
        className={styles.refreshBtn}
        onClick={onClickRefreshButton}
      />
    </Tooltip>
  )
}

RefreshAtomVariationButton.displayName = 'RefreshAtomVariationButton'
