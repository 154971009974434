import { delay, SagaIterator } from 'redux-saga'
import { actionChannel, call, flush, put, take, select } from 'redux-saga/effects'
import { getQuestionPreferencesState } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences-reducer'
import {
  AssessmentLoSelectionAction,
  editLosFailed,
  editLosSucceeded,
  LosStagedAction,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-lo-selection-actions'
import { entries, flatten, groupBy, last } from 'lodash'
import { getOrCreateClient as getApolloClient } from 'app/frontend/helpers/apollo'
import {
  LoConceptMap,
  TopicLo,
  TopicLoErrorType,
  TopicLoMap,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'

import * as UPDATE_ASSESSMENT from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/update-assessment.gql'
import { GET_ASSESSMENT_SEQUENCES } from 'app/frontend/compositions/connected/get-assessment-sequences'
import { hasGqlError } from 'app/frontend/helpers/apollo/error'
import CODES from 'app/frontend/api/codes'

/**
 * Perform apollo mutation to persist the given LO selection state. Also re-fetches assessment
 * sequences as adding an LO causes a sequence to be generated.
 *
 * @param assessmentId The ID of the assessment to update
 * @param selectedLosByTopic The new set of LOs selected for the assessment, keyed by topic ID
 * @param affectedTopicLos The set of topic LOs that were interacted with in order to generate this
 *        request. Used to track saving/error states for the individual LOs.
 * @param affectedConceptsByLo The map of LOs to available concepts that were interacted with in
 *        order to generate this request.
 */
function* performAssignmentLoSelections(
  assessmentId: string,
  selectedLosByTopic: TopicLoMap,
  affectedTopicLos: TopicLo[],
  affectedConceptsByLo: LoConceptMap
) {
  const client = getApolloClient()
  const questionPreferencesStateValue = yield select(getQuestionPreferencesState)
  const loConcepts = entries(affectedConceptsByLo).map(([learningObjectiveId, conceptIds]) => ({
    learningObjectiveId,
    conceptIds: Array.from(conceptIds),
  }))

  const variables: GQL.UpdateAssessment.Variables = {
    request: {
      id: assessmentId,
      taxons: entries(selectedLosByTopic).map(([topicId, loIds]) => ({
        topicId,
        learningObjectiveIds: Array.from(loIds),
      })),
      loConcepts: loConcepts,
      maxQuizQuestionsPerLo: questionPreferencesStateValue.count,
      questionCardinalityPreference: questionPreferencesStateValue.questionType,
    },
  }

  const refetchSequencesVariables: GQL.GetAssessmentSequences.Variables = {
    assessmentId,
  }

  try {
    yield call(client.mutate, {
      mutation: UPDATE_ASSESSMENT,
      variables,
      context: { silenceErrors: true },
    })

    // Doing this here instead of as a refetchQueries on the mutation because we want to
    // maintain the loading state until it is complete
    yield call(client.query, {
      query: GET_ASSESSMENT_SEQUENCES,
      variables: refetchSequencesVariables,
      fetchPolicy: 'network-only',
      context: { silenceErrors: true },
    })

    yield put(editLosSucceeded(assessmentId, affectedTopicLos))
  } catch (e) {
    let errorType
    // identify the specific error type
    if (hasGqlError(e, { errorType: CODES.TOO_MANY_LEARNING_OBJECTIVES })) {
      errorType = TopicLoErrorType.TooManyObjectives
    }
    yield put(editLosFailed(assessmentId, affectedTopicLos, errorType))
  }
}

/**
 * Generates the affected LO concepts for an array of LO selection actions. Exposed for testing.
 *
 * @param stagedEditsForAssessment array of LosStagedAction to get LO concepts from
 * @returns aggregated LO concepts of the given LO selection actions
 */
export function generateAffectedConceptsByLo(
  stagedEditsForAssessment: LosStagedAction[]
): LoConceptMap {
  const affectedConceptsByLoEntries = flatten(
    stagedEditsForAssessment.map(e => entries(e.affectedConceptsByLo))
  )
  return affectedConceptsByLoEntries.reduce((affectedConceptsByLo, loConcepts) => {
    const loId = loConcepts[0]
    const conceptIds = loConcepts[1]
    if (!affectedConceptsByLo[loId]) {
      affectedConceptsByLo[loId] = new Set()
    }
    conceptIds.forEach(affectedConceptsByLo[loId].add, affectedConceptsByLo[loId])
    return affectedConceptsByLo
  }, {})
}

export function* processAssignmentLoSelections(): SagaIterator {
  const editLoSelectionChannel = yield actionChannel(AssessmentLoSelectionAction.EditStaged)
  while (true) {
    // Block until an edit is staged
    const firstStagedEdit = yield take(editLoSelectionChannel)

    // Wait a second to see if there are other updates we can put in the same batch
    yield call(delay, 1000)

    // Get any other staged updates that accrued
    const stagedEdits: LosStagedAction[] = [
      firstStagedEdit,
      ...(yield flush(editLoSelectionChannel)),
    ]

    // In case the user is really clever and somehow staged updates to multiple assessments within
    // the brief time we spent waiting, we'll group by assessment ID
    const stagedEditsByAssessmentId = groupBy(stagedEdits, e => e.assessmentId)

    for (const [assessmentId, stagedEditsForAssessment] of entries(stagedEditsByAssessmentId)) {
      const affectedTopicLos = flatten(stagedEditsForAssessment.map(e => e.affectedTopicLos))
      const selectedLosByTopic = last(stagedEditsForAssessment).selectedLosByTopic
      const affectedConceptsByLo = generateAffectedConceptsByLo(stagedEditsForAssessment)

      yield* performAssignmentLoSelections(
        assessmentId,
        selectedLosByTopic,
        affectedTopicLos,
        affectedConceptsByLo
      )
    }
  }
}
