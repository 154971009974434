import * as React from 'react'
import { Field } from 'redux-form'
import { Box } from 'app/frontend/components/material/box'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Password as PasswordField } from 'app/frontend/components/material/password-field'
import { FormNames, PasswordType } from './helper'
import { RadioGroup, RadioPropsForGroup } from 'app/frontend/components/radio-group'

const t = tns('teach:assessment_builder')

interface OwnProps {
  isReadOnly: boolean
  hasCoursePassword: boolean
  passwordType: PasswordType
  onOptionChange: (_event: React.FormEvent, type: string) => void
  onPasswordChange: () => void
  passwordError: JSX.Element
}

interface PasswordTypesProps extends WrappedFieldPropsStub, OwnProps {
  coursePasswordField: JSX.Element
  sectionPasswordField: JSX.Element
}

export const PasswordOptions: React.FunctionComponent<OwnProps> = ({
  isReadOnly,
  hasCoursePassword,
  passwordType,
  onOptionChange,
  onPasswordChange,
  passwordError,
}) => (
  <Box role="group">
    <Field
      name={FormNames.PASSWORD_TYPE}
      component={renderPasswordTypes}
      onChange={onOptionChange}
      props={{
        passwordType,
        hasCoursePassword,
        isReadOnly,
        coursePasswordField: renderPasswordFieldWithError(
          FormNames.COURSE_PASSWORD,
          onPasswordChange,
          true,
          passwordError
        ),
        sectionPasswordField: renderPasswordFieldWithError(
          FormNames.PASSWORD,
          onPasswordChange,
          isReadOnly,
          passwordError
        ),
      }}
    />
  </Box>
)

const renderPassword = (field: WrappedFieldPropsStub & OwnProps): JSX.Element => (
  <PasswordField
    value={field.input.value}
    disabled={field.isReadOnly}
    onChange={field.input.onChange}
    hideLabel={true}
  />
)

export const renderPasswordFieldWithError = (
  name: FormNames,
  onChange: () => void,
  isReadOnly,
  error: JSX.Element
) => (
  <>
    <Box direction="row" responsive={false} alignItems="center">
      <Field
        name={name}
        component={renderPassword}
        onChange={onChange}
        props={{
          isReadOnly,
        }}
      />
    </Box>
    {error}
  </>
)

export const renderPasswordTypes = (field: PasswordTypesProps): JSX.Element => {
  const passwordOptions: RadioPropsForGroup[] = [
    {
      label: t('settings_password_use_course_level'),
      value: PasswordType.COURSE,
      disabled: !field.hasCoursePassword,
      children: field.passwordType === PasswordType.COURSE && field.coursePasswordField,
    },
    {
      label: t('settings_password_customize_for_section'),
      value: PasswordType.SECTION,
      children: field.passwordType === PasswordType.SECTION && field.sectionPasswordField,
    },
  ]
  return (
    <RadioGroup
      name={FormNames.PASSWORD_TYPE}
      disabled={field.isReadOnly}
      radioGroupProps={{
        value: field.input.value,
        onChange: field.input.onChange,
      }}
      radioProps={passwordOptions}
    />
  )
}
