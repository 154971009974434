import * as React from 'react'
import { Field } from 'redux-form'
import * as RegisterFields from './register-fields'

const HiddenFields: React.FunctionComponent<{ googled: boolean }> = ({ googled }) => {
  return (
    <div>
      {!googled && (
        <div>
          <Field name="email" component={RegisterFields.HiddenEmail} />
          <Field name="signature" component={RegisterFields.HiddenSignature} />
          <Field name="googleId" component={RegisterFields.HiddenGoogleId} />
        </div>
      )}
      <Field name="redir" component={RegisterFields.Redirect} />
    </div>
  )
}

HiddenFields.displayName = 'HiddenFields'
export default HiddenFields
