import { stringify } from 'app/helpers/query-string'

export const courseCoverRoute = (courseId: string) => {
  return `/learn/course/${courseId}`
}

export const quizCoverRoute = (courseId: string, quizId: string) => {
  return `/learn/course/${courseId}/quiz/${quizId}/cover`
}

export const quizFlowRoute = (courseId: string, quizId: string) => {
  return `/learn/course/${courseId}/quiz/${quizId}`
}

export const assessmentFlowRoute = (sectionId: string, quizId: string) => {
  return `/learn/section/${sectionId}/quiz/${quizId}`
}

export const assessmentResultsRoute = (sectionId: string, quizId: string) => {
  return `/learn/section/${sectionId}/quiz/${quizId}/results`
}

export const relatedInstructionRoute = (
  courseId: string,
  assignmentId: string,
  conceptId: string
) => {
  return `/learn/course/${courseId}/assignment/${assignmentId}/instruction/${conceptId}`
}

export const learnCoverRoute = (courseId: string, assignmentId: string) => {
  return `/learn/course/${courseId}/assignment/${assignmentId}/cover`
}

export const learnFlowRoute = (courseId: string, assignmentId: string) => {
  return `/learn/course/${courseId}/assignment/${assignmentId}`
}

export const learnFlowPracticeRoute = (courseId: string, assignmentId: string) => {
  return `/learn/course/${courseId}/assignment/${assignmentId}/practice`
}

export const learnFlowHistoryRoute = (
  courseId: string,
  assignmentId: string,
  prevSequenceId: string
) => {
  return `/learn/course/${courseId}/assignment/${assignmentId}/history/${prevSequenceId}`
}

export const sequencePreviewRoute = (sequenceId: string) => {
  return `/learn/sequence/${sequenceId}/preview`
}

export const variationPreviewRoute = (sequenceId: string, atomId: string, variationId: string) => {
  return `/learn/sequence/${sequenceId}/${atomId}/${variationId}/preview`
}

export const learnPreviewRoute = (assignmentId: string) => {
  return `/learn/assignment/${assignmentId}/preview`
}

export const learnPreviewHistoryRoute = (assignmentId: string, prevSequenceId: string) => {
  return `/learn/assignment/${assignmentId}/history/${prevSequenceId}/preview`
}

export const quizFlowPreviewRoute = (quizId: string) => {
  return `/learn/quiz/${quizId}/preview`
}

export const quizReviewCenterRoute = (courseId: string, quizId: string) => {
  return `/learn/course/${courseId}/quiz/${quizId}/review-center`
}

export const unifiedReviewCenterRoute = (courseId: string) => {
  return `/learn/course/${courseId}/review-center`
}

export const getAssignmentActivityDetailsRoute = (
  sectionId: string,
  assignmentId: string,
  learningObjectiveId?: string
) => {
  const qs = learningObjectiveId
    ? `?${stringify({
        loId: learningObjectiveId,
      })}`
    : ''

  return `/learn/course/${sectionId}/assignment/${assignmentId}/activity${qs}`
}

export const assessmentAnswersRoute = (sectionId: string, quizId: string) => {
  return `/learn/section/${sectionId}/quiz/${quizId}/answers`
}
