import { makeApiActions } from 'app/frontend/api/api-redux'

export type GET_TAXONOMY_SUBTREE = 'GET_TAXONOMY_SUBTREE'
export const GET_TAXONOMY_SUBTREE: GET_TAXONOMY_SUBTREE = 'GET_TAXONOMY_SUBTREE'
export const API_GET_TAXONOMY_SUBTREE = makeApiActions('API_GET_TAXONOMY_SUBTREE')

export type GetTaxonomySubtreeAction = {
  type: GET_TAXONOMY_SUBTREE
  taxonId: string
}
export const getTaxonomySubtree = (taxonId: string): GetTaxonomySubtreeAction => {
  return {
    type: GET_TAXONOMY_SUBTREE,
    taxonId,
  }
}
