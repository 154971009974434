import 'isomorphic-fetch'
import * as React from 'react'
import * as KnewconsSvg from 'app/public/icons/symbol/knewcons.svg'
import { getStaticUrl, Context } from 'app/frontend/helpers/assets'
import * as styles from './knewcons.css'

/**
 * Makes an AJAX request to fetch the knewcons.svg file. Once completed, called the callback with
 * the content of the file.
 */
const loadKnewcons = async (callback: (data: string) => void, context: Context): Promise<void> => {
  try {
    const knewconsUrl = getStaticUrl(KnewconsSvg, context)
    const response = await fetch(knewconsUrl)
    if (response.ok) {
      callback(await response.text())
    }
  } catch (e) {
    console.error('Failed to load knewcons', e)
  }
}

export interface Props {
  /**
   * Provide a custom Context to this function, otherwise window.context is used.
   * This component does *not* listen for changes to this prop.
   */
  initialContext?: Context
}

/**
 * Component that downloads and injects the knewcons.svg file into the page.
 * Because of CORS we cannot just reference the url directly in xlinkHref. This uses AJAX to
 * download the file and inject it into the page.
 */
export const Knewcons: React.FunctionComponent<Props> = ({ initialContext }) => {
  const [svgData, setSvgData] = React.useState<string>(null)

  React.useEffect(() => {
    loadKnewcons(setSvgData, initialContext)
  }, [])

  if (svgData) {
    return (
      <div
        className={styles.knewcons}
        aria-hidden={true}
        dangerouslySetInnerHTML={{ __html: svgData }}
      />
    )
  }
  return null
}

Knewcons.displayName = 'Knewcons'
