import { combineReducers } from 'redux'
import institutionsReducer from './institutions/institutions-reducer'
import { InstitutionsReducerState } from 'app/frontend/pages/institution-admin/entities/institutions/institutions-reducer'

export type EntitiesState = {
  institutions: InstitutionsReducerState
}

export default combineReducers<EntitiesState>({
  institutions: institutionsReducer,
})
