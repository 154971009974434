import * as React from 'react'
import { connect } from 'react-redux'
import { ButtonMaterial, ButtonTheme } from 'app/frontend/components/material/button/button'
import { getPopover } from 'app/frontend/components/popover/popover-reducer'
import { openPopover, closePopover } from 'app/frontend/components/popover/popover-actions'
import Popover from 'app/frontend/components/popover'
import * as classNames from 'classnames'
import * as styles from './popover-button.css'
import { Icon } from '../icon'

interface OwnProps {
  ariaLabel?: string
  id: string
  label: string
  disabled?: boolean
  theme?: ButtonTheme
  children: JSX.Element
  dataBi: string
  customPopoverCls?: string
  icon?: string
  iconCls?: string
  btnCls?: string
  onOpenMenu?: (id: string) => void
  onCloseMenu?: () => void
}

interface StateProps {
  open: boolean
}

interface DispatchProps {
  onOpenMenu: (id: string) => void
  onCloseMenu: () => void
}

type Props = OwnProps & StateProps & DispatchProps

export class PopoverButton extends React.Component<Props, {}> {
  private menu: any

  onClick = () => {
    const { id, open, onOpenMenu, onCloseMenu } = this.props
    if (!open) {
      onOpenMenu(id)
    } else {
      onCloseMenu()
    }
  }

  render() {
    const {
      ariaLabel,
      children,
      onCloseMenu,
      open,
      label,
      disabled = false,
      theme = 'bordered',
      dataBi,
      customPopoverCls,
      icon,
      iconCls,
      btnCls,
    } = this.props

    return (
      <div
        ref={c => {
          this.menu = c
        }}
      >
        <ButtonMaterial
          onClick={this.onClick}
          aria-expanded={open}
          aria-haspopup="true"
          aria-label={ariaLabel}
          label={label}
          name={label}
          disabled={disabled}
          theme={theme}
          data-bi={dataBi}
          icon={icon && <Icon name={icon} className={classNames(styles.icon, iconCls)} />}
          className={btnCls}
        />
        <Popover
          anchorEl={this.menu}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={open}
          onRequestClose={onCloseMenu}
          className={classNames(styles.popover, customPopoverCls)}
        >
          {React.cloneElement(children, {
            onRequestClose: onCloseMenu,
          })}
        </Popover>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps): StateProps {
  const popover = getPopover(state)
  return {
    open: ownProps.id === popover,
  }
}

function mapDispatchToProps(dispatch, ownProps): DispatchProps {
  const { onOpenMenu, onCloseMenu } = ownProps
  return {
    onOpenMenu: (id: string) => {
      dispatch(openPopover(id))
      if (onOpenMenu) {
        onOpenMenu(id)
      }
    },
    onCloseMenu: () => {
      dispatch(closePopover())
      if (onCloseMenu) {
        onCloseMenu()
      }
    },
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PopoverButton)
