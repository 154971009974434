import * as React from 'react'
import * as _ from 'lodash'
import { useSelector } from 'react-redux'
import * as classNames from 'classnames'
import { getLearnosityState } from 'app/frontend/components/compound/learnosity/learnosity-reducer'
import { tns } from 'app/frontend/helpers/translations/i18n'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'
import * as styles from 'app/frontend/content/atoms/alchemie-vsper-atom/alchemie-vsper-atom.css'
import { usePrevious } from 'app/frontend/hooks/use-previous'

const t = tns('learnosity_component')

type AlchemieLearnosityProps = {
  atom: GQL.AtomMedia
  contentManager: ContentManagerActionsType
  placeholder?: JSX.Element
}

type IFeatureArray = {
  [index: number]: { feature_id?: string }
}

type ILearnosityOptions = {
  features?: IFeatureArray
}

export const AlchemieVSEPRrAtom: React.FunctionComponent<AlchemieLearnosityProps> = ({
  atom,
  contentManager,
  placeholder,
}) => {
  /**
   * The Learnosity connected component.
   */
  let learnosity: ILearnosityInstance

  const { learnosityCredentials: securityPacket } = useSelector(state => getLearnosityState(state))
  const previousSec = usePrevious(securityPacket)
  const [status, setStatus] = React.useState('LOADING')
  const [componentId] = React.useState(_.uniqueId())

  React.useEffect(() => {
    if (securityPacket) {
      mountLearnosity()
    }

    return () => {
      tearDown()
    }
  }, [])

  React.useEffect(() => {
    if (status === 'ERROR') {
      contentManager.addError({ message: t('error_for_manager'), isRetryable: true })
    } else if (status === 'READY') {
      contentManager.success()
    }

    if (previousSec === null && securityPacket) {
      mountLearnosity()
    }
  }, [securityPacket, status])

  /**
   * Called when learnosity experiences an issue.
   * Error codes: https://docs.learnosity.com/assessment/questions/troubleshooting
   */
  const learnosityError = (error: Commons.ILearnosityError, options: any): void => {
    if (error && error.code === 10019) {
      // 10019: Failed validating math
      // Usually due to a timeout during grading. Since we don't care about frontend
      // grading (we already do grading in RetailStudents), this error is not important.
      // It is "Alert Only" which means that it won't be seen by users.
      return
    }
    const learnosityUiErrorCodes = [10001, 10002, 10003, 10005, 10007, 10010, 10016]
    if (error && learnosityUiErrorCodes.includes(error.code)) {
      // Retry initializing Learnosity once if any of the UI error codes
      // are seen. This means the question is uninteractable. Wait 5 seconds
      // to allow properly initialized instances to render so this error is
      // not propagated to those instances as is the default behavior. This also
      // gives time between requests to the authentication service.
      // The codes are found here:
      // https://docs.learnosity.com/assessment/questions/troubleshooting
      setTimeout(() => {
        if (status === 'LOADING') {
          console.error('Error initializing Learnosity', {
            error,
            options,
            internalIssueId: 'CE-3466',
          })
          setStatus('ERROR')
        }
      }, 5000)

      return
    }

    console.error('Error initializing Learnosity', {
      error,
      options,
      internalIssueId: 'CE-3466',
    })
  }

  /**
   * Called when LearnosityApp.init() succeeds.
   */
  const learnosityReady = async (): Promise<void> => {
    if (!learnosity) {
      // we don't know why this can happen.
      console.error('Learnosity ready handler fired, but learnosity does not exist', {
        internalIssueId: 'CE-2238',
      })
      setStatus('ERROR')
      return
    }

    // Validate that Learnosity widget is loaded and not just the loading spinner.
    const spinnerRemoved = await waitForLearnosityWidget()
    if (!spinnerRemoved) {
      console.error('Learnosity still loading after 10s', {
        internalIssueId: 'CE-3466',
      })
      setStatus('ERROR')
      return
    }
    setStatus('READY')
  }

  /**
   * Pause execution for millis milliseconds
   */
  const _delay = (millis: number) => {
    return new Promise(resolve => setTimeout(() => resolve(true), millis))
  }

  /**
   * Returns a promise that resolves when the Learnosity spinner has removed itself
   * from the page and been replaced by the Learnosity widget.
   *
   * We do this because sometimes Learnosity reports itself as ready but is still showing its
   * loading spinner. Every 50 ms we check when the spinner has been removed so we do
   * not report this component as ready until the Learnosity is interactable.
   *
   * If the Learnosity spinner is still present after 10s, we resolve the Promise as false.
   * @returns {Promise<boolean>}
   */
  const waitForLearnosityWidget = async (): Promise<boolean> => {
    // check if spinner has removed itself once every 50ms for 10 seconds
    for (let numIterations = 0; numIterations < 200; numIterations++) {
      if (
        !document.querySelector(`.learnosity-${componentId} .lrn_widget`) &&
        document.querySelector(`.learnosity-${componentId} .lrn_spinner`)
      ) {
        await _delay(50)
      } else {
        return true
      }
    }
    return false
  }

  const mountLearnosity = () => {
    // This could be undefined if the learnosity script failed to load
    if (!window.LearnosityApp) {
      console.error('Learnosity did not load properly', {
        internalIssueId: 'CE-2474',
      })
      setStatus('ERROR')
      return
    }
    const options = {
      ...securityPacket,
      ...initOptions(),
    }

    learnosity = window.LearnosityApp.init(options, {
      readyListener: () =>
        // uncaught exceptions thrown here won't actually force the user to re-render the page
        // so we catch them explicitly and render our error state.
        learnosityReady()
          .then(() => {
            setStatus('READY')
          })
          .catch(err => {
            console.error('Unhandled exception in learnosityReady', {
              internalIssueId: 'CE-3466',
              err,
            })
            setStatus('ERROR')
          }),
      errorListener: error => learnosityError(error, options),
    })
  }

  const initOptions = (): ILearnosityOptions => {
    const data = JSON.parse(atom.data?.['widgetState'])
    const content = {
      ...data,
      feature_id: componentId,
    }

    return {
      features: [content],
    }
  }

  const errorElement = (
    <div className={styles.error}>
      {t('error_1')}
      <span
        className={styles.link}
        onClick={() => {
          location.reload()
        }}
      >
        {t('error_2')}
      </span>
      {t('error_3')}
    </div>
  )

  if (status === 'ERROR') {
    return errorElement
  }

  const tearDown = () => {
    if (learnosity && learnosity.reset) {
      learnosity.reset()
    }
  }

  return (
    <div className={classNames(styles.learnosity, `learnosity-${componentId}`)}>
      <div>
        {status === 'LOADING' && (
          <div aria-label={t('loading')} data-test="alchemie-vsper-atom-loading">
            {placeholder}
          </div>
        )}
        {/* The learnosity widget will render before it calls our learnosityReady callback.
          To prevent this, we wrap (and hide) the widget while our component is loading. */}
        <div
          className={classNames({
            [styles.ghost]: status === 'LOADING',
          })}
        >
          <div
            className={`learnosity-feature feature-${componentId}`}
            data-test="alchemie-vsper-atom-wrapper"
          />
        </div>
      </div>
    </div>
  )
}

AlchemieVSEPRrAtom.displayName = 'AlchemieLearnosity'

export default AlchemieVSEPRrAtom
