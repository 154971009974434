import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { SESSION_EXPIRED_MODAL } from 'app/frontend/pages/material/session-expired-modal'
import { Action } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, cancelled, put } from 'redux-saga/effects'
import { CSRF_MISMATCH_MODAL } from 'app/frontend/components/modals/csrf-mismtach-modal/csrf-mismatch-modal'

const REQ_PREFIX = '@@kn.api'
const REQ_INIT_PREFIX = `${REQ_PREFIX}.init`
const REQ_SUCCESS_PREFIX = `${REQ_PREFIX}.success`
const REQ_ERROR_PREFIX = `${REQ_PREFIX}.error`
const REQ_CANCEL_PREFIX = `${REQ_PREFIX}.cancel`

// Based on https://github.com/yelouafi/redux-saga/issues/178
export function* apiCall(
  fn: (...args: any[]) => any,
  apiActions: IApiActions,
  ...args
): SagaIterator {
  const {
    initActionCreator,
    successActionCreator,
    errorActionCreator,
    cancelActionCreator,
  } = apiActions
  try {
    const requestStart = new Date().getTime()
    yield put(initActionCreator(args))
    const response = yield (call as any)(fn, ...args)
    const responseEnd = new Date().getTime()
    yield put(successActionCreator(response, args, { requestStart, responseEnd }))
    return response
  } catch (error) {
    yield put(errorActionCreator(error, args))
    if (error.showSessionExpiredModal) {
      yield put(showModal(SESSION_EXPIRED_MODAL))
    } else if (error.csrfMismatch) {
      yield put(showModal(CSRF_MISMATCH_MODAL))
    } else {
      throw error
    }
  } finally {
    if (yield cancelled()) {
      yield put(cancelActionCreator(args))
    }
  }
}

// Action creators, these are private as a user should never create these actions
export type ApiInitAction = {
  args: any
} & Action

function apiInitActionCreator(baseAction: string, args: any): ApiInitAction {
  return {
    type: apiInitAction(baseAction),
    args,
  }
}

type AjaxMetric = {
  requestStart: number
  responseEnd: number
}

export type ApiSuccessAction = {
  response: any
  args: any
  ajaxMetric: AjaxMetric
} & Action

function apiSuccessActionCreator(
  baseAction: string,
  response: Response,
  args: any,
  ajaxMetric: AjaxMetric
): ApiSuccessAction {
  return {
    type: apiSuccessAction(baseAction),
    response,
    args,
    ajaxMetric,
  }
}

export type ApiErrorAction = {
  error: Error
  args: any
} & Action

function apiErrorActionCreator(baseAction: string, error: Error, args: any): ApiErrorAction {
  return {
    type: apiErrorAction(baseAction),
    error,
    args,
  }
}

export type ApiCancelAction = {
  args: any
} & Action

function apiCancelActionCreator(baseAction: string, args: any): ApiCancelAction {
  return {
    type: apiCancelAction(baseAction),
    args,
  }
}

/**
 * Note that the return types on the action creators here are total
 * hack which allow reducers to correctly discriminate the union type.
 *
 * Since discriminated union types only work with static strings this casts
 * the type to a static string type (even though the actual return types are
 * dynamic with static string prefixes).
 */

// Action names
function apiInitAction(baseAction: string): 'init' {
  return (`${REQ_INIT_PREFIX}/${baseAction}` as any) as 'init'
}
function apiSuccessAction(baseAction: string): 'success' {
  return (`${REQ_SUCCESS_PREFIX}/${baseAction}` as any) as 'success'
}
function apiErrorAction(baseAction: string): 'error' {
  return (`${REQ_ERROR_PREFIX}/${baseAction}` as any) as 'error'
}
function apiCancelAction(baseAction: string): 'cancel' {
  return (`${REQ_CANCEL_PREFIX}/${baseAction}` as any) as 'cancel'
}

export type InitAction = {
  type: 'init'
  args: any
}

export type SuccessAction<T> = {
  type: 'success'
  response: T
  args: any
}

export type ErrorAction = {
  type: 'error'
  error: Error
  args: any
}

export type CancelAction = {
  type: 'cancel'
  args: any
}

export type ApiActions<T> = InitAction | SuccessAction<T> | ErrorAction | CancelAction

// Use this to create actions for an API call
interface IApiActions {
  INIT: 'init'
  SUCCESS: 'success'
  ERROR: 'error'
  CANCEL: 'cancel'
  initActionCreator: (...args: any[]) => ApiInitAction
  successActionCreator: (...args: any[]) => ApiSuccessAction
  errorActionCreator: (...args: any[]) => ApiErrorAction
  cancelActionCreator: (...args: any[]) => ApiCancelAction
}
export function makeApiActions(baseAction: string): IApiActions {
  return {
    INIT: apiInitAction(baseAction),
    SUCCESS: apiSuccessAction(baseAction),
    ERROR: apiErrorAction(baseAction),
    CANCEL: apiCancelAction(baseAction),
    initActionCreator: apiInitActionCreator.bind(null, baseAction),
    successActionCreator: apiSuccessActionCreator.bind(null, baseAction),
    errorActionCreator: apiErrorActionCreator.bind(null, baseAction),
    cancelActionCreator: apiCancelActionCreator.bind(null, baseAction),
  }
}
