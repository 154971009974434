/* tslint:disable:max-file-line-count */
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'

import { videoPlay, videoStop } from './video-actions'
import VideoHelper from 'app/frontend/helpers/video-helper'
import VideoSlider from 'app/frontend/components/video/video-slider'
import { isMobile } from 'app/frontend/helpers/device'
import * as DateTime from 'app/frontend/helpers/datetime'
import * as keycode from 'keycode'
import { tns } from 'app/frontend/helpers/translations/i18n'
import VideoError from './video-error'
import { YouTubeLink } from './video-link'
import { getPlayingClipId } from './video-reducer'

import * as videoControlsStyles from './video-controls.css'
import * as styles from './video-player-skin.css'

const t = tns('video_player')

interface OwnProps {
  videoId: string // YouTube specific id for video
  clipId: string // Created from video ids and timestamps
  clipDuration?: number // Length of clip (s)
  atomId?: string // Atom id
  start?: number // Start of clip (s)
  end?: number // End of clip (s)
  autoplay?: boolean // Autoplay the video
  controls?: string // Indicates to use own controls or provider's controls
  onReady?: (arg: object) => void // onReady callback
  contentManager: ContentManagerActionsType
  ref: React.RefObject<YoutubeVideo>
}

interface State {
  playerState: 'LOADING' | 'LOADED' | 'TIMEOUT' | 'ERROR'
  firstPlay: boolean
  endSec: number // End of clip (s)
  timeElapsedSec: number // Time spent playing (s)
  timeRemainingSec: number // Time left (s)
  muted: boolean
  captionsOptions: any[] // Captions options from YouTube
  currentCaption: string // Language code for captions
  captionsEnabled: boolean
  qualityLevels: string[]
  currentLevel?: string // Playback quality
  controls: any
  showSettingsPopup: boolean
  showCaptionsPopup: boolean
  controlsFocused: boolean
  volumeFocused: boolean
  video: any
}

interface StateProps {
  playing: boolean
}

interface DispatchProps {
  videoPlay?: () => void // Dispatch videoPlay action
  videoStop?: () => void // Dispatch videoStop action
}

type Props = OwnProps & StateProps & DispatchProps

export class YoutubeVideo extends React.Component<Props, State> {
  reportAutoPlay: boolean

  youtubeHelperAlertTimer: number

  player: any

  wasPlayingBeforeSeekTo: boolean

  debounceCallback: (val: any) => void

  playbackInterval: number

  playerContainer: HTMLDivElement

  scrubber: VideoSlider

  volume: VideoSlider

  static readonly TIMEOUT_MS = 15000 // 15 seconds

  constructor(props: Props) {
    super(props)

    /**
     * Mixpanel reporting helper variables.
     */
    this.reportAutoPlay = true

    this.state = this.getInitialState()
  }

  /**
   * Returns the initial state of this component.
   */
  getInitialState(): State {
    return {
      playerState: 'LOADING',
      firstPlay: true,
      endSec: this.props.end,
      timeElapsedSec: 0,
      timeRemainingSec: 0,
      muted: false,
      captionsOptions: [],
      currentCaption: null,
      captionsEnabled: false,
      qualityLevels: [],
      currentLevel: null,
      controls: isMobile() ? 'youtube' : this.props.controls,
      showSettingsPopup: false,
      showCaptionsPopup: false,
      controlsFocused: false,
      volumeFocused: false,
      video: null,
    }
  }

  /**
   * Component was mounted.
   */
  componentDidMount(): void {
    this.youtubeHelperAlertTimer = window.setTimeout(() => {
      this.reportError('YouTube component timed out.', {
        playerState: this.state.playerState,
        doesPlayerExist: !!this.player,
      })
      this.handleFatalError('TIMEOUT')
    }, YoutubeVideo.TIMEOUT_MS)
    VideoHelper.load(this.loadPlayer)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Pause video if it was playing and now another one is playing
    if (prevProps.playing && !this.props.playing) {
      this.pauseVideo()
    }
    if (prevState.playerState === 'LOADING' && this.state.playerState === 'LOADED') {
      this.props.contentManager.success()
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.youtubeHelperAlertTimer)
    if (this.playbackInterval) {
      clearInterval(this.playbackInterval)
    }
    this.props.videoStop()
    this.destroyPlayer()
  }

  /**
   * Handles setting this component to a fatal ERROR or TIMEOUT state.
   * Destroys the YouTube iFrame and then sets the component to the proper state.
   *
   * NOTE: The YouTube player must be destroyed before changing state, as React does not
   * have permission to tear down the iframe itself.
   */
  handleFatalError(playerState: 'TIMEOUT' | 'ERROR') {
    this.destroyPlayer()
    this.setState({ playerState })
  }

  /**
   * Called to destroy the iframe created by YouTube. Required when we render an error
   * or timeout component as React does not have permission to unmount the iFrame on
   * rerender.
   */
  destroyPlayer(): void {
    if (this.player) {
      this.player.destroy()
      this.player = null
    }
  }

  /**
   * Called when a user moves the volume slider.
   *
   * @param pct 0-100
   */
  onVolumeChange = (pct: any) => {
    if (typeof pct !== 'number' || _.isNaN(pct)) {
      return
    }

    this.player.setVolume(VideoHelper.formatVolume(pct))
    window.localStorage.setItem('videoVolume', pct.toString())
  }

  /**
   * Called by the youtube player when it is ready.
   */
  onPlayerReady = (): void => {
    clearTimeout(this.youtubeHelperAlertTimer)

    // update state
    this.setState(
      {
        // if the video doesn't have an end time set, set it to the duration of the video
        endSec: this.props.end || this.player.getDuration(),
        playerState: 'LOADED',
      },
      () => {
        const localVolume = parseInt(window.localStorage.getItem('videoVolume'), null)
        const localMute = window.localStorage.getItem('videoMuted')

        // when player is ready, call the callback
        if (this.props.onReady) {
          this.props.onReady({
            duration: this.player.getDuration(),
          })
        }

        // update volume slider with saved value or player's default
        if (this.volume) {
          if (this.state.controls !== 'youtube' && 0 <= localVolume && localVolume <= 100) {
            this.volume.setValue(localVolume)
            this.player.setVolume(VideoHelper.formatVolume(localVolume))
          } else {
            this.volume.setValue(this.player.getVolume())
          }
        }

        // update volume mute setting with saved value
        if (this.state.controls !== 'youtube' && localMute === 'true') {
          this.player.mute()

          this.setState({
            muted: true,
          })
        }

        // autoplay the video if desired
        if (this.props.autoplay) {
          this.autoplayVideo()
        }
      }
    )
  }

  onScrubberSlide = (event, value): void => {
    if (event === null) {
      // do nothing
    }
    this.updateTimestamps(this.player.getCurrentTime())
    this.debounceSlideTo(value)
  }

  /**
   * Seeks the video to value after the user has been inactive for 0.33 seconds.
   * Pauses the videos and resumes it to avoid slowness and weird audio noises.
   */
  debounceSlideTo = (value: number): void => {
    if (!this.debounceCallback) {
      this.debounceCallback = _.debounce(val => {
        // seek video
        this.player.seekTo(val)

        if (this.wasPlayingBeforeSeekTo) {
          // play video again if we paused it earlier
          this.player.playVideo()
        }
        this.wasPlayingBeforeSeekTo = undefined
      }, 333)
    }

    // pause video while uses slider
    if (this.wasPlayingBeforeSeekTo === undefined) {
      this.wasPlayingBeforeSeekTo = this.props.playing
      this.player.pauseVideo()
    }

    this.debounceCallback(value)
  }

  /**
   * Returns true if fullscreen is supported, false otherwise.
   */
  hasFullscreenSupport = (): boolean => {
    const docEle: any = document.documentElement
    return (
      docEle.requestFullscreen ||
      docEle.webkitRequestFullscreen ||
      docEle.mozRequestFullScreen ||
      docEle.msRequestFullscreen
    )
  }

  /**
   * Report a warning to our backend - the video player has loaded but is encountering
   * errors as the user interacts with it.
   */
  reportWarning = (message: string, error: any): void => {
    console.error(message, {
      error,
      videoId: this.props.videoId,
      internalIssueId: 'CE-2297',
    })
    this.props.contentManager.addWarning({ message })
  }

  /**
   * Report an error to our backend - the video player has encountered a fatal error.
   * Remounting the video may solve the problem.
   */
  reportError = (message: string, error: any): void => {
    console.error(message, {
      error,
      videoId: this.props.videoId,
      internalIssueId: 'CE-2297',
    })
    this.props.contentManager.addError({ message, isRetryable: true })
  }

  /**
   * Called when an error happens after the player is initialized.
   * (e.g. click play and then video doesn't exist)
   *
   * All of these errors result in the destruction of the video player.
   */
  onPlayerError = (error): void => {
    const code = error ? error.data : -1
    // HTML5 video error
    if (code === 5) {
      this.reportError(
        'YouTube error: The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred',
        code
      )
    } else if (code === 100) {
      this.reportWarning('YouTube error: The video requested was not found', code)
    } else if (code === -1) {
      this.reportError('YouTube error: There was an unknown YouTube video error', code)
    } else if (code === 101 || code === 150) {
      this.reportWarning(
        'YouTube error: The owner of the requested video does not allow it to be played in embedded players',
        code
      )
    } else if (code === 2) {
      this.reportWarning('YouTube error: The request contains an invalid parameter value', code)
    } else {
      this.reportError('an error occurred while playing the video', code)
    }
    this.handleFatalError('ERROR')
  }

  /**
   * Loads the YouTube player into our component.
   */
  loadPlayer = (): void => {
    const container = this.playerContainer
    const { videoId, start, end } = this.props
    const player = new window.YT.Player(container, {
      videoId: videoId,
      width: '100%',
      height: '100%',
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
        onError: this.onPlayerError,
      },
      playerVars: {
        start: start,
        end: end,
        cc_load_policy: 1,
        iv_load_policy: 3,
        rel: 0,
        showinfo: 0,
        controls: this.state.controls === 'youtube' ? 1 : 0,
        wmode: 'transparent',
      },
    })

    // save player
    this.player = player
  }

  /**
   * Skips the video.
   */
  skipVideo = (): void => {
    this.pauseVideo()
  }

  /**
   * Pauses the video.
   */
  pauseVideo = (): void => {
    try {
      this.player.pauseVideo()
      this.props.videoStop()
    } catch (e) {
      this.reportWarning('error pausing video', e)
    }
  }

  /**
   * Plays the video.
   */
  autoplayVideo = (): void => {
    try {
      this.player.playVideo()
    } catch (e) {
      this.reportWarning('error auto-playing video', e)
    }
  }

  /**
   * Called when the video has finished playing.
   */
  onPlayerEnded = (): void => {
    this.updateTimestamps(this.player.getCurrentTime())
    this.props.videoStop()
  }

  /**
   * Called on first play of video to fetch
   * available captions
   */
  getCaptionOptions = () => {
    let availableCaptions = []
    try {
      availableCaptions = this.player.getOption('captions', 'tracklist')
    } catch (e) {
      this.reportWarning('error loading captions', e)
    }
    const localCaption = window.localStorage.getItem('videoCaptions')
    const matchedCaption = _.find(
      availableCaptions,
      caption => caption.languageCode === localCaption
    )
    const statePayload = { captionsOptions: availableCaptions }

    // enable captions with saved settings or disable them by default
    if (matchedCaption) {
      this.player.setOption('captions', 'track', {
        languageCode: localCaption,
      })

      Object.assign(statePayload, {
        captionsEnabled: true,
        currentCaption: localCaption,
      })
    } else if (!_.isEmpty(availableCaptions)) {
      this.player.setOption('captions', 'track', {})
    }

    return statePayload
  }

  /**
   * Called on first play of video to fetch
   * available quality levels
   */
  getQualityLevels = (): object => {
    return {
      qualityLevels: this.player.getAvailableQualityLevels(),
      currentLevel: this.player.getPlaybackQuality(),
    }
  }

  /**
   * Called when the video is starting playing.
   */
  onPlayerPlay = (): void => {
    // update state
    const newState = {}

    // get advanced settings for controls
    if (this.state.controls !== 'youtube' && this.state.firstPlay) {
      Object.assign(
        newState,
        { firstPlay: false },
        this.getCaptionOptions(),
        this.getQualityLevels()
      )
    }

    this.setState(newState)

    // poll to fetch current time
    this.playbackInterval = window.setInterval(
      function () {
        const time = this.player.getCurrentTime(),
          props = this.props,
          startTime = props.start,
          endTime = this.state.end

        if (time < startTime) {
          this.player.seekTo(startTime)
        } else if (time > endTime) {
          this.player.seekTo(startTime)
          this.player.pauseVideo()
        }

        if (this.state.controls !== 'youtube') {
          this.scrubber.setValue(time)
        }

        this.updateTimestamps(time)
      }.bind(this),
      500
    )

    this.props.videoPlay()
    this.reportAutoPlay = false
  }

  /**
   * Called when the video is paused.
   */
  onPlayerPause = (): void => {
    this.props.videoStop()
  }

  /**
   * Called when the video is buffering.
   */
  onPlayerBuffer = (): void => {
    this.props.videoPlay()
  }

  /**
   * Update the times elapsed and remaining times.
   */
  updateTimestamps = (currentTime: number): void => {
    const props = this.props,
      startTime = props.start,
      endTime = this.state.endSec

    this.setState({
      timeElapsedSec: Math.max(Math.floor(currentTime) - startTime, 0),
      timeRemainingSec: Math.max(endTime - Math.floor(currentTime), 0),
    })
  }

  /**
   * Called when the state of the player changes.
   */
  onPlayerStateChange = (event: any): void => {
    if (this.playbackInterval) {
      clearInterval(this.playbackInterval)
    }

    switch (event.data) {
      case 0:
        this.onPlayerEnded()
        break
      case 1:
        this.onPlayerPlay()
        break
      case 2: // paused
        this.onPlayerPause()
        break
      case 3: // buffering
        this.onPlayerBuffer()
        break
    }
  }

  /**
   * Returns the full screen element if supported.
   */
  renderFullScreenButton = (): JSX.Element => {
    if (this.hasFullscreenSupport()) {
      return (
        <button
          className="full-screen"
          type="button"
          onClick={this.onClickFullscreenButton}
          aria-label={t('fullscreen')}
          onFocus={this.onControlFocus}
          onBlur={this.onControlBlur}
        />
      )
    }
  }

  /**
   * Toggle between fullscreen and not.
   */
  toggleFullScreen = (node: Element): void => {
    // is the player fullscreen?
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !(document as Commons.VideoDocument).webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // make player fullscreen
      if (document.documentElement.requestFullscreen) {
        node.requestFullscreen()
      } else if (document.documentElement.msRequestFullscreen) {
        node.msRequestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        node.mozRequestFullScreen()
      } else if ((document.documentElement as Commons.VideoHtmlElement).webkitRequestFullscreen) {
        ;(node as Commons.VideoElement).webkitRequestFullscreen()
      }
    } else {
      // exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if ((document as Commons.VideoDocument).webkitExitFullscreen) {
        ;(document as Commons.VideoDocument).webkitExitFullscreen()
      }
    }
  }

  /**
   * Called when the quality button is clicked.
   */
  onClickQualityLevel = (level: string): void => {
    // change the video level (might not work)
    const currLevel = this.player.getPlaybackQuality()
    if (level !== currLevel) {
      this.player.setPlaybackQuality(level)
    }

    // update current level
    this.setState({
      currentLevel: level,
    })
  }

  /**
   * Called when the user clicks the fullscreen button.
   */
  onClickFullscreenButton = (): void => {
    const node = ReactDOM.findDOMNode(this) as Element
    this.toggleFullScreen(node)
  }

  /**
   * Called when the user clicks on the player/pause button.
   */
  onClickPlayButton = (): void => {
    const player = this.player,
      state = player.getPlayerState()
    if (state === 1) {
      player.pauseVideo()
    } else {
      player.playVideo()
    }
  }

  /**
   * Called when the user clicks on the volume button.
   */
  onVolumeButtonClick = (): void => {
    const player = this.player
    if (player.isMuted()) {
      player.unMute()
      window.localStorage.removeItem('videoMuted')
    } else {
      player.mute()
      window.localStorage.setItem('videoMuted', 'true')
    }

    // update state
    this.setState({
      muted: !player.isMuted(),
    })
  }

  /**
   * Called when the user clicks on the CC button
   */
  onCaptionsButtonClick = (): void => {
    this.setState({
      showCaptionsPopup: !this.state.showCaptionsPopup,
    })

    if (_.isEmpty(this.state.captionsOptions)) {
      return
    }

    if (this.state.captionsEnabled) {
      this.player.setOption('captions', 'track', {})
      window.localStorage.removeItem('videoCaptions')
    } else {
      const languageCode = this.state.currentCaption || 'en'

      this.player.setOption('captions', 'track', {
        languageCode: languageCode,
      })
      window.localStorage.setItem('videoCaptions', languageCode)
    }

    const captionsEnabled = !this.state.captionsEnabled
    this.setState({
      captionsEnabled: captionsEnabled,
      currentCaption: this.state.currentCaption || 'en',
    })
  }

  /**
   * Called when user clicks on a language
   * option under captions
   */
  onClickCaptionsOption = (languageCode: string): void => {
    this.player.setOption('captions', 'track', { languageCode: languageCode })
    this.setState({
      captionsEnabled: true,
      currentCaption: languageCode,
    })
    window.localStorage.setItem('videoCaptions', languageCode)
  }

  onCaptionsKeyDown = (e: React.KeyboardEvent<any>): void => {
    const { captionsOptions, currentCaption } = this.state
    if (_.isEmpty(this.state.captionsOptions)) {
      return
    }
    switch (keycode(e.keyCode)) {
      case 'up':
      case 'right':
        e.preventDefault()
        this.onClickCaptionsOption(
          captionsOptions[
            Math.min(
              captionsOptions.findIndex(caption => caption.languageCode === currentCaption) + 1,
              captionsOptions.length - 1
            )
          ]
        )
        return
      case 'left':
      case 'down':
        e.preventDefault()
        this.onClickCaptionsOption(
          captionsOptions[
            Math.max(
              captionsOptions.findIndex(caption => caption.languageCode === currentCaption) - 1,
              0
            )
          ]
        )
        return
      default:
        return
    }
  }

  /**
   * Returns the options available for captions,
   * or a friendly error message if there are no
   * captions available.
   * @returns {XML}
   */
  renderCaptionsOptions = (): JSX.Element => {
    if (!_.isEmpty(this.state.captionsOptions)) {
      return (
        <div className="options">
          {this.state.captionsOptions.map(function (option) {
            const checked =
              option.languageCode === this.state.currentCaption && this.state.captionsEnabled
            return (
              <label
                key={option.languageCode}
                onClick={this.onClickCaptionsOption.bind(this, option.languageCode)}
              >
                <input
                  type="radio"
                  name="captionOption"
                  value={option.languageCode}
                  checked={checked}
                  aria-checked={checked}
                  readOnly
                  onFocus={this.onControlFocus}
                />
                <span>{option.displayName}</span>
              </label>
            )
          }, this)}
        </div>
      )
    } else {
      return (
        <div className="options no-options">
          The owner of this video has not provided captions for this video.{' '}
          <a
            href={
              `https://www.youtube.com/watch?v=${this.props.videoId}` + `&t=${this.props.start}s`
            }
            target="_blank"
            rel="noopener noreferrer"
            onFocus={this.onControlFocus}
            data-bi="youtube-link"
          >
            Visit this link
          </a>{' '}
          to take advantage of automatic captioning and follow along from
          {' ' + DateTime.formatTime(this.props.start)} to
          {' ' + DateTime.formatTime(this.state.endSec)}.
        </div>
      )
    }
  }

  onQualityKeyDown = (e: React.KeyboardEvent<any>) => {
    const { qualityLevels, currentLevel } = this.state
    switch (keycode(e.keyCode)) {
      case 'up':
      case 'right':
        e.preventDefault()
        this.onClickQualityLevel(
          qualityLevels[Math.max(qualityLevels.findIndex(level => level === currentLevel) - 1, 0)]
        )
        return
      case 'down':
      case 'left':
        e.preventDefault()
        this.onClickQualityLevel(
          qualityLevels[
            Math.min(
              qualityLevels.findIndex(level => level === currentLevel) + 1,
              qualityLevels.length - 1
            )
          ]
        )
        return
      default:
        return
    }
  }

  /**
   * Returns the elements to show in the quality menu.
   */
  renderQualityLevels = (): JSX.Element => {
    return (
      <fieldset>
        {this.state.qualityLevels.map(function (level) {
          const checked = level.toLowerCase() === this.state.currentLevel.toLowerCase()
          return (
            <label key={level} htmlFor={level}>
              <input
                type="radio"
                tabIndex={-1}
                name={`qualityLevel`}
                value={level}
                id={level}
                onChange={this.onClickQualityLevel.bind(this, level)}
                checked={checked}
                aria-checked={checked}
              />
              <span>{level}</span>
            </label>
          )
        }, this)}
      </fieldset>
    )
  }

  /**
   * Renders another toggle for captions under
   * quality settings.
   */
  renderCaptionsButtonSecondary = (): JSX.Element => {
    if (!_.isEmpty(this.state.captionsOptions)) {
      return (
        <label className="captions-button-secondary">
          <input
            type="checkbox"
            onClick={this.onCaptionsButtonClick}
            checked={this.state.captionsEnabled}
            aria-checked={this.state.captionsEnabled}
            readOnly
            onFocus={this.onControlFocus}
          />
          <span>Captions</span>
        </label>
      )
    }
  }

  /**
   * Returns the className for the play button.
   */
  playButtonClass = (): string => {
    const ret = ['play']
    if (this.props.playing) {
      ret.push('playing')
    }
    return ret.join(' ')
  }

  playButtonLabel = (): string => {
    if (this.props.playing) {
      return t('pause')
    } else {
      return t('play')
    }
  }

  volumeButtonLabel = (): string => {
    if (this.state.muted) {
      return t('unmute')
    } else {
      return t('mute')
    }
  }

  /**
   * Returns the className for the captions button
   */
  captionsButtonClass = (): string => {
    if (this.state.captionsEnabled) {
      return 'enabled'
    }

    if (!this.state.firstPlay && _.isEmpty(this.state.captionsOptions)) {
      return 'unavailable'
    }
  }

  /**
   * Returns the className for the volume button.
   */
  volumeButtonClass = (): string => {
    if (this.state.muted) {
      return 'muted'
    }
  }

  /**
   * Renders the elapsed time.
   */
  renderTimeElapsed = (): string => {
    if (this.state.timeElapsedSec) {
      return DateTime.formatTime(this.state.timeElapsedSec)
    } else {
      return '0:00'
    }
  }

  /**
   * Renders the duration (or remaining time if video started).
   */
  renderTimeRemaining = (): JSX.Element => {
    let timeRemainingSec = 0
    if (this.state.timeRemainingSec) {
      timeRemainingSec = this.state.timeRemainingSec
    } else if (this.state.endSec > 0) {
      timeRemainingSec = this.state.endSec - (this.props.start || 0)
    }

    return (
      <span aria-label={DateTime.humanizeTime(timeRemainingSec)}>
        {DateTime.formatTime(timeRemainingSec)}
      </span>
    )
  }

  /**
   * Returns the className for the root element of this component.
   */
  controlsClassName = (): string => {
    const ret = ['video-controls']
    if (!this.state.firstPlay) {
      ret.push('with-captions-button')
    }
    if (this.state.qualityLevels.length > 0) {
      ret.push('with-quality-button')
    }
    if (this.state.controlsFocused) {
      ret.push('visible')
    }
    return ret.join(' ')
  }

  renderSettingsPopup = (): JSX.Element => {
    const className = 'options' + (this.state.showSettingsPopup ? ' enabled' : '')
    const id = `video-options-${this.props.videoId}`
    return (
      <div className={className} id={id}>
        {this.renderQualityLevels()}
        {this.renderCaptionsButtonSecondary()}
      </div>
    )
  }

  toggleSettingsPopup = (): void => {
    this.setState({
      showSettingsPopup: !this.state.showSettingsPopup,
    })
  }

  onControlFocus = (): void => {
    this.setState({ controlsFocused: true })
  }

  onControlBlur = (): void => {
    this.setState({ controlsFocused: false })
  }

  onVolumeFocus = (): void => {
    this.setState({ volumeFocused: true, controlsFocused: true })
  }

  onVolumeBlur = (): void => {
    this.setState({ volumeFocused: false, controlsFocused: false })
  }

  /**
   * Returns the controls for the video player.
   */
  renderControls = (): JSX.Element => {
    const props = this.props
    if (this.state.controls !== 'youtube' && this.state.playerState === 'LOADED') {
      return (
        <div className={this.controlsClassName()}>
          <div className="left-side">
            <button
              className={this.playButtonClass()}
              type="button"
              onFocus={this.onControlFocus}
              onBlur={this.onControlBlur}
              onClick={this.onClickPlayButton}
              aria-label={this.playButtonLabel()}
            />
            <div className="time elapsed" aria-label={t('time_elapsed')}>
              {this.renderTimeElapsed()}
            </div>
          </div>
          <div className="video-scrubber" aria-label={t('seek_slider')}>
            <VideoSlider
              ref={ref => (this.scrubber = ref)}
              className={videoControlsStyles.trackSlider}
              min={props.start}
              max={this.state.endSec}
              onSlide={this.onScrubberSlide}
              onFocus={this.onControlFocus}
              onBlur={this.onControlBlur}
            />
          </div>
          <div className="right-side">
            <div className="time duration" aria-label={t('time_remaining')}>
              {this.renderTimeRemaining()}
            </div>
            <div className="captions">
              <button
                type="button"
                className={this.captionsButtonClass()}
                onClick={this.onCaptionsButtonClick}
                aria-label={t('toggle_captions')}
                onKeyDown={this.onCaptionsKeyDown}
                onFocus={this.onControlFocus}
                onBlur={this.onControlBlur}
              >
                <span className="captions-button-text">CC</span>
              </button>
              {this.state.showCaptionsPopup && this.renderCaptionsOptions()}
            </div>
            <div className="volume">
              <button
                type="button"
                className={this.volumeButtonClass()}
                onClick={this.onVolumeButtonClick}
                aria-label={this.volumeButtonLabel()}
                onFocus={this.onVolumeFocus}
                onBlur={this.onVolumeBlur}
              />
              <div className={`slider-wrapper ${this.state.volumeFocused ? 'focused' : ''}`}>
                <VideoSlider
                  className={videoControlsStyles.volumeSlider}
                  vertical={true}
                  onChange={this.onVolumeChange}
                  ref={ref => (this.volume = ref)}
                  onFocus={this.onVolumeFocus}
                  onBlur={this.onVolumeBlur}
                  min={0}
                  max={100}
                />
              </div>
            </div>
            <div className="quality">
              <button
                type="button"
                aria-label={t('video_settings')}
                onClick={this.toggleSettingsPopup}
                className={this.state.showSettingsPopup ? 'enabled' : ''}
                aria-haspopup="true"
                aria-controls={`video-options-${this.props.videoId}`}
                aria-expanded={this.state.showSettingsPopup ? 'true' : 'false'}
                onKeyDown={this.onQualityKeyDown}
                onFocus={this.onControlFocus}
                onBlur={this.onControlBlur}
              />
              {this.state.showSettingsPopup && this.renderSettingsPopup()}
            </div>
            {this.renderFullScreenButton()}
          </div>
        </div>
      )
    }
  }

  /**
   * Renders a link to this video on YouTube
   */
  renderExternalLink = (text: string): JSX.Element => {
    return (
      <YouTubeLink
        videoId={this.props.videoId}
        clipStartAtSeconds={this.props.start}
        clipEndAtSeconds={this.props.end}
      >
        {text}
      </YouTubeLink>
    )
  }

  /**
   * Renders the component.
   */
  render(): JSX.Element {
    if (this.state.playerState === 'ERROR' || this.state.playerState === 'TIMEOUT') {
      return <VideoError classNames={styles.videoAtom} videoLinkFn={this.renderExternalLink} />
    }

    // TODO CE-3816: We need a loading component

    return (
      <div className={styles.videoAtom} aria-busy={this.state.playerState === 'LOADING'}>
        <div className={styles.scale} />
        <div
          className={styles.loading}
          data-visible={this.state.playerState === 'LOADING'}
          aria-hidden={this.state.playerState !== 'LOADING'}
          aria-label={t('loading')}
        />
        <div ref={ref => (this.playerContainer = ref)} />
        /* It's simpler to not show controls until loaded */
        {this.state.playerState === 'LOADED' && this.renderControls()}
      </div>
    )
  }
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const { clipId } = ownProps
  const playingClipId = getPlayingClipId(state)
  const playing = playingClipId === clipId
  return {
    playing,
  }
}

function mapDispatchToProps(dispatch, ownProps: OwnProps): DispatchProps {
  return {
    videoPlay: () => dispatch(videoPlay(ownProps.clipId)),
    videoStop: () => dispatch(videoStop(ownProps.clipId)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(YoutubeVideo)
