import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Tag } from 'app/frontend/components/tag'
import { Heading } from 'app/frontend/components/material/heading'
import * as styles from './assessment-questions-title-bar.css'

type Props = {
  title: string
  tagLabel?: string
  tagTooltip?: string
  unassignedTag?: string
  unassignedTooltip?: string
  weight?: 'semibold' | 'regular'
  size?: SizeType
}

export type SizeType = 'small' | 'medium' | 'large'
type Sizes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type HeadingSizesType = {
  [headingSize in SizeType]: {
    tag: Sizes
    size: Sizes
  }
}

export const AssessmentQuestionsTitleBar: React.FunctionComponent<Props> = ({
  title,
  tagLabel,
  tagTooltip,
  unassignedTag,
  unassignedTooltip,
  children,
  weight = 'regular',
  size = 'large',
}) => {
  const headingSizes: HeadingSizesType = {
    small: {
      tag: 'h6',
      size: 'h6',
    },
    medium: {
      tag: 'h4',
      size: 'h4',
    },
    large: {
      tag: 'h2',
      size: 'h4',
    },
  }

  return (
    <Box direction="row" full="page" alignItems="center" justify="between" responsive={false}>
      <Heading
        tag={headingSizes[size].tag}
        size={headingSizes[size].size}
        weight={weight}
        className={styles.title}
        margin={{ vertical: 'small' }}
      >
        {title}
      </Heading>

      <Box direction="row" alignItems="center" responsive={false} className={styles.options}>
        {unassignedTag && (
          <Tag
            label={unassignedTag}
            theme="warning"
            tooltip={unassignedTooltip}
            iconName="icon-warning"
            data-test="unassigned-tag"
          />
        )}
        {tagLabel && (
          <Tag label={tagLabel} theme="secondary" tooltip={tagTooltip} data-test="sequence-tag" />
        )}
        {children}
      </Box>
    </Box>
  )
}

AssessmentQuestionsTitleBar.displayName = 'AssessmentQuestionsTitleBar'
