import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import * as HAS_STUDENT_STARTED_ASSIGNMENT_QUERY from './has-student-started-assignment.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseHasStudentStartedAssignmentResults = RenderProps<
  GQL.HasStudentStartedAssignment.Query
>

export const useHasStudentStartedAssignment = (
  assignmentId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
): UseHasStudentStartedAssignmentResults => {
  const { loading, error, data } = useQuery<
    GQL.HasStudentStartedAssignment.Query,
    GQL.HasStudentStartedAssignment.Variables
  >(HAS_STUDENT_STARTED_ASSIGNMENT_QUERY, {
    variables: {
      assignmentId,
    },
    fetchPolicy,
    nextFetchPolicy: 'cache-first', // TODO ALPACA-759
  })

  return {
    loading,
    error,
    ...data,
  }
}
