import * as React from 'react'
import { QuestionViewer } from 'app/frontend/compositions/data/question-viewer/question-viewer'
import { tns } from 'app/frontend/helpers/translations/i18n'
import {
  AssessmentSequenceWithOverride,
  isQuestionRemovedFromAssessment,
  calculateAssessmentSequenceWeight,
} from 'app/frontend/helpers/assignment'
import { QuestionHeadingWithEditablePoints } from 'app/frontend/compositions/ux/question-heading-with-points'
import { useTotalAssessmentPoints } from 'app/frontend/compositions/connected/get-assessment-sequences'
import { QuestionViewPreferencesOptionsViewDensity } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'
import { getSequenceVariationCount } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-helper'
import { ParentEntityType } from 'app/typings/commons'
import { CustomAssessmentQuestionActions } from './custom-assessment-question-actions'
import { AssessmentQuestionsTitleBar } from './assessment-questions-title-bar'

const t = tns('teach:assessment_questions')
export const CUSTOM_QUESTIONS_ENTITY_LIMIT = 100

type OwnProps = {
  customSequences: AssessmentSequenceWithOverride[]
  assessment: GQL.GetAssignment.Assignment
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  displayDensity?: QuestionViewPreferencesOptionsViewDensity
  hasEditPermission: boolean
  questionNumber?: number
  setCustomItemVariationLength?: (e: number) => void
}

type Props = OwnProps

export const CustomAssessmentQuestions: React.FunctionComponent<Props> = ({
  assessment,
  customSequences,
  isCourseAssignmentOnSection,
  hasStudentStartedAssignment,
  displayDensity,
  hasEditPermission,
  questionNumber,
  setCustomItemVariationLength,
}) => {
  const count = customSequences.length
  const { totalAssessmentPoints, assessmentSequences } = useTotalAssessmentPoints(assessment.id)
  const minVariationCountForSequences = getSequenceVariationCount(assessment, customSequences)
  const parentEntityType = assessment.sectionId ? ParentEntityType.Section : ParentEntityType.Course
  return (
    <>
      <AssessmentQuestionsTitleBar
        title={t('custom_questions')}
        tagLabel={count.toString()}
        tagTooltip={
          count < CUSTOM_QUESTIONS_ENTITY_LIMIT
            ? t('num_questions', { count })
            : t('custom_questions_max', { count })
        }
      />
      {customSequences.map((seq, index) => {
        const minVariationCount = minVariationCountForSequences[seq.sequenceId]
        const { points, weight } = calculateAssessmentSequenceWeight(seq, totalAssessmentPoints)
        const readOnly =
          hasStudentStartedAssignment ||
          isCourseAssignmentOnSection ||
          !hasEditPermission ||
          isQuestionRemovedFromAssessment(seq)
        return (
          <QuestionViewer
            lazyLoad={true}
            customHeading={
              <QuestionHeadingWithEditablePoints
                assessmentId={assessment.id}
                sequenceId={seq.sequenceId}
                parentEntityType={parentEntityType}
                points={points}
                weight={weight}
                readOnly={readOnly}
                assessmentSequences={assessmentSequences}
                isPoolQuestion={false}
              />
            }
            key={seq.pathSequenceVariationId}
            assessmentId={assessment.id}
            parentEntityType={parentEntityType}
            minVariationCount={minVariationCount}
            isAssessmentOffline={assessment.quizConfiguration.printable}
            sequenceId={seq.sequenceId}
            pathSequenceVariationId={seq.pathSequenceVariationId}
            includeExplanationAndAnswers={true}
            actionBar={CustomAssessmentQuestionActions}
            override={seq.resultOverride}
            overrideValue={seq.overrideValue}
            displayDensity={displayDensity}
            canUpdateDisplayDensity={true}
            questionNumber={questionNumber + (index + 1)}
            setCustomItemVariationLength={setCustomItemVariationLength}
          />
        )
      })}
    </>
  )
}

CustomAssessmentQuestions.displayName = 'CustomAssessmentQuestions'
