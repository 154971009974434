import * as _ from 'lodash'

/**
 * Return the string 'learn', 'teach' or 'admin'
 * to reflect what section of the app the user is in
 */
export function sectionInApp(): string {
  const url = window.context ? window.context.request.url : ''
  if (_.startsWith(url, '/learn')) {
    return 'learn'
  } else if (_.startsWith(url, '/admin')) {
    return 'admin'
  } else if (_.startsWith(url, '/teach')) {
    return 'teach'
  } else if (_.startsWith(url, '/orders')) {
    return 'orders'
  }
}

export function inTeachSection(): boolean {
  return sectionInApp() === 'teach'
}

/**
 * Slugify a string: whitespaces are replaced with dashes, and invalid characters are removed.
 */
export function slugify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

function getNextUrlPart(prefixPath: string): string {
  const matches = window.location.pathname.match(new RegExp(`/${prefixPath}/(.*?)(/|$)`))
  if (matches && matches.length > 1) {
    return matches[1]
  }
  return null
}

export function getCourseId(): string {
  return getNextUrlPart('course')
}

export function getSectionId(): string {
  return getNextUrlPart('section')
}

export function getSectionIdForLearn(): string {
  return getCourseId() || getSectionId()
}

export function getAssignmentId(): string {
  return getNextUrlPart('assignment')
}

export function getQuizId(): string {
  return getNextUrlPart('quiz')
}

export function getEmailAddressDelimiter(): string {
  return navigator.platform.indexOf('Win') > -1 ? ';' : ','
}
