import * as React from 'react'
import * as classnames from 'classnames'
import { CardMaterial } from 'app/frontend/components/material/card/card'
import { ButtonMaterial, ButtonTheme } from 'app/frontend/components/material/button/button'
import { Icon, ShadowedIcon } from 'app/frontend/components/material/icon'
import * as styles from './card-link.css'

type InnerContentsProps = {
  heading: string
  body?: string
  iconComponent?: React.ReactNode
}

export const InnerContents: React.FunctionComponent<InnerContentsProps> = ({
  heading,
  body,
  iconComponent,
}) => (
  <>
    {iconComponent}
    <div className={styles.copy}>
      <h2 className={styles.heading}>{heading}</h2>
      {body && <p className={styles.description}>{body}</p>}
    </div>
  </>
)

InnerContents.displayName = 'InnerContents'

type Props = {
  iconName?: string
  heading: string
  body?: string
  cta?: string
  buttonTheme?: ButtonTheme
  buttonDataBi?: string
  onClick?: () => void
  fullWidth?: boolean
  iconComponent?: React.ReactNode
  cardTheme?: string
  ariaLabel?: string
}

export const CardLink: React.FunctionComponent<Props> = ({
  iconName,
  heading,
  body,
  cta,
  buttonTheme,
  buttonDataBi,
  onClick,
  fullWidth,
  iconComponent = iconName ? <ShadowedIcon name={iconName} className={styles.icon} /> : null,
  cardTheme,
  ariaLabel,
}) => (
  <>
    <CardMaterial className={styles.cardDesktop} raised={false} theme={{ card: cardTheme }}>
      <InnerContents heading={heading} body={body} iconComponent={iconComponent} />
      {onClick && (
        <ButtonMaterial
          className={styles.cta}
          label={cta}
          onClick={onClick}
          theme={buttonTheme || 'primary'}
          data-bi={buttonDataBi}
          fullWidth={fullWidth}
          ariaLabel={ariaLabel}
        />
      )}
    </CardMaterial>

    <CardMaterial className={styles.cardMobile} raised={false} theme={{ card: cardTheme }}>
      <button onClick={onClick ? onClick : () => null} className={styles.buttonWrapper}>
        <InnerContents heading={heading} body={body} iconComponent={iconComponent} />
        <Icon name="icon-arrow-chevron-right" />
      </button>
    </CardMaterial>
  </>
)

CardLink.displayName = 'CardLink'

type CardLinkGroupProps = {
  className?: string
}

export const CardLinkGroup: React.FunctionComponent<CardLinkGroupProps> = ({
  className,
  children,
}) => <div className={classnames(styles.cardLinkGroup, className)}>{children}</div>

CardLinkGroup.displayName = 'CardLinkGroup'
