import * as React from 'react'
import Snackbar from 'app/frontend/components/material/snackbar/snackbar-container'
import ScreenreaderAlert from 'app/frontend/components/material/screenreader-alert'
import RegradeSnackbarContainer from 'app/frontend/components/material/regrade-snackbar/regrade-snackbar-container'
import GoalUpdateSnackbarContainer from 'app/frontend/components/material/goal-update-snackbar/goal-update-snackbar-container'
import GradeUpdateSnackbarContainer from 'app/frontend/components/material/grade-update-snackbar/grade-update-snackbar-actions-container'

export const LearnGlobals: React.FunctionComponent<{}> = () => {
  return (
    <div>
      <Snackbar />
      <RegradeSnackbarContainer />
      <GoalUpdateSnackbarContainer />
      <GradeUpdateSnackbarContainer />
      <ScreenreaderAlert />
    </div>
  )
}

export default LearnGlobals
