import * as React from 'react'
import * as classnames from 'classnames'
import { Checkbox } from '@mui/material'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Box } from 'app/frontend/components/material/box'
import { Icon } from 'app/frontend/components/material/icon'
import { Tag } from 'app/frontend/components/tag'
import * as styles from './table-of-contents.css'
import { ALL_CONCEPTS } from 'app/frontend/compositions/data/lo-concept-selector/lo-concept-selector'
import {
  TopicLoErrorType,
  TopicLoStatus,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import { TooltipIcon } from 'app/frontend/components/tooltip'

export type TOCLearningObjectiveError = {
  type: TopicLoStatus.Error | TopicLoErrorType
  message?: string
}

type Props = {
  objective: GQL.LearningObjectiveFields.Fragment
  topicId: string
  numSequences?: number
  toggleObjective?: (loId: string, topicId: string, checked: boolean) => void
  onClick?: (topicId: string, lo: GQL.LearningObjectiveFields.Fragment, conceptId?: string) => void
  checked?: boolean
  isSaving?: boolean
  disabled?: boolean
  isExpanded?: boolean
  warningText?: string
  error?: TOCLearningObjectiveError
}

type State = {
  isExpanded: boolean
}

export class TableOfContentsLearningObjective extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isExpanded: props.isExpanded,
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isExpanded !== this.props.isExpanded) {
      this.setState({ isExpanded: this.props.isExpanded })
    }
  }

  triggerClick = () => {
    const { objective, topicId, onClick, disabled, children } = this.props

    if (disabled) {
      return
    }

    if (children) {
      this.setState(state => ({ isExpanded: !state.isExpanded }))
    } else if (!!onClick) {
      onClick(topicId, objective, ALL_CONCEPTS)
    }
  }
  proceedKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.triggerClick()
    }
  }
  toggleCheckbox = (checked: boolean) => {
    const { toggleObjective, objective, topicId } = this.props
    toggleObjective(objective.id, topicId, checked)
  }

  getIconClassname = () => {
    const {
      onClick,
      disabled,
      objective: { hasLoConcepts },
    } = this.props

    const enableClick = !!onClick && !disabled
    if (hasLoConcepts) {
      return this.state.isExpanded ? styles.upArrow : styles.downArrow
    } else {
      return enableClick ? styles.rightArrow : styles.rightArrowAlwaysInvisible
    }
  }

  getIconName = () => {
    const {
      objective: { hasLoConcepts },
    } = this.props

    if (hasLoConcepts) {
      return this.state.isExpanded ? 'icon-expand-less' : 'icon-expand-more'
    } else {
      return 'icon-arrow-chevron-right'
    }
  }

  render() {
    const {
      objective,
      numSequences,
      toggleObjective,
      onClick,
      isSaving,
      disabled,
      checked,
      warningText,
      error,
      children,
    } = this.props
    const { isExpanded } = this.state
    const enableClick = !!onClick && !disabled
    const loTagLabel = numSequences !== undefined ? numSequences.toString() : undefined
    const showArrows = children && !disabled
    const showUpArrow = showArrows && isExpanded
    const showDownArrow = showArrows && !isExpanded

    return (
      <Box full="page" data-test={`learning-objective-${objective.id}`} data-lo-id={objective.id}>
        <Box
          direction="row"
          full="page"
          alignItems="center"
          pad={{ horizontal: 'large' }}
          className={styles.loRow}
        >
          {toggleObjective && (
            <Checkbox
              className={styles.checkbox}
              checked={checked}
              onChange={e => this.toggleCheckbox(e.target.checked)}
              disabled={disabled}
              data-test="toggle-lo-checkbox"
              inputProps={{
                'aria-label': objective.description,
              }}
            />
          )}
          <Box
            direction="row"
            alignItems="center"
            className={classnames({
              [styles.loDescription]: true,
              [styles.pointerCursor]: enableClick || showArrows,
              [styles.showRightArrow]: enableClick,
              [styles.showUpArrow]: showUpArrow,
              [styles.showDownArrow]: showDownArrow,
            })}
            onClick={this.triggerClick}
            onKeyDown={this.proceedKeyDown}
            data-bi="lo-item-body"
            tabIndex={0}
          >
            <Paragraph
              data-test="lo-description"
              className={classnames({ [styles.disabled]: disabled }, styles.loDescription)}
            >
              {objective.description}
            </Paragraph>
            {error && (
              <TooltipIcon
                disabled={!error.message}
                tooltip={{
                  title: error.message,
                  placement: 'top',
                }}
                icon={{
                  name: 'icon-error-outline',
                  size: 'large',
                  className: styles.errorIcon,
                }}
                data-bi="error-text-tooltip"
              />
            )}
            {warningText && (
              <TooltipIcon
                tooltip={{
                  title: warningText,
                  placement: 'top',
                }}
                icon={{
                  name: 'icon-error-outline',
                  size: 'large',
                  className: styles.errorIcon,
                }}
                data-bi="warning-text-tooltip"
              />
            )}
            {(isSaving || loTagLabel) && (
              <Tag
                label={loTagLabel}
                isLoading={isSaving}
                theme="secondary"
                margin={{ left: 'medium' }}
                data-test="lo-num-sequences-tag"
              />
            )}
            <Icon name={this.getIconName()} className={this.getIconClassname()} size="large" />
          </Box>
        </Box>
        {isExpanded && children}
      </Box>
    )
  }
}
