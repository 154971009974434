import { isEmpty } from 'lodash'
import { MaterialControllerState } from './material-controller'

/**
 * Determine if the contents of the material layout root should be hidden from ths screen reader.
 * @return true if any modal is showing, false otherwise
 */
export function getIsRootPageHiddenFromScreenReader(state: MaterialControllerState): boolean {
  return state.global.ui.thirdPartyModal.isOpen || !isEmpty(state.global.ui.modal)
}
