import * as React from 'react'
import * as classnames from 'classnames'
import * as _ from 'lodash'
import * as styles from './dropdown.css'

export type DropdownChoice = {
  ariaSelected?: boolean
  value: string
  label: string
  disabled?: boolean
}

export const Choice: React.FunctionComponent<DropdownChoice> = ({
  ariaSelected,
  value,
  label,
  disabled,
}) => (
  <option key={value} value={value} disabled={disabled} aria-selected={ariaSelected}>
    {label}
  </option>
)

Choice.displayName = 'Choice'

export interface IDropdownProps {
  onChange?: (args: any) => void
  onClick?: () => void
  choices?: DropdownChoice[]
  label?: string
  value: any
  className?: string
  dropdownMenuClassname?: string
  name?: string
  disabled?: boolean
  labelledBy?: string
  required?: boolean
  description?: string
  containsPii?: boolean
  dataBi: string
  id?: string
}

class Dropdown extends React.Component<IDropdownProps, {}> {
  private selectorId: string

  UNSAFE_componentWillMount(): void {
    if (!this.props.id) {
      this.selectorId = _.uniqueId('dropdown')
    }
  }

  render(): JSX.Element {
    const {
      onChange,
      onClick,
      choices,
      value,
      label,
      className,
      dropdownMenuClassname,
      name,
      disabled,
      labelledBy,
      required,
      description,
      containsPii,
      children,
      dataBi,
      id,
    } = this.props
    return (
      <div className={classnames(styles.group, className)} data-bi={dataBi}>
        {label && (
          <label htmlFor={id || this.selectorId} className={styles.label}>
            {label}
            {required ? '*' : ''}
          </label>
        )}
        <select
          id={id || this.selectorId}
          data-bi={`${dataBi}-select`}
          name={name}
          onChange={onChange}
          onClick={onClick}
          value={value}
          className={classnames(styles.dropdownMenu, dropdownMenuClassname)}
          disabled={disabled}
          aria-labelledby={labelledBy}
          required={required}
          data-pii={containsPii || undefined}
        >
          {children
            ? children
            : choices.map((choice, idx) => (
                <Choice
                  key={idx}
                  ariaSelected={choice.value === value}
                  value={choice.value}
                  label={choice.label}
                  disabled={!!choice.disabled}
                />
              ))}
        </select>
        {description && <div className={styles.description}>{description}</div>}
      </div>
    )
  }
}

export default Dropdown
