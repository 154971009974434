import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

/**
 * Renders the given component in <div id="react-page"> using {window.kn} as props.
 */
export const render = Component => {
  const controller = React.createElement(Component, window.kn)
  const wrappedController = React.createElement(AppContainer, null, controller)
  ReactDOM.render(wrappedController, document.getElementById('react-page'))
}

export const entry = (entryModule, factory) => {
  if (entryModule.hot) {
    // When running locally, listen to changes to the JavaScript and re-render the page
    // entryModule.id is something like "./signed-out-entry.tsx"
    const controllerName = entryModule.id.replace(/-entry\.tsx?$/, '-controller.tsx')
    entryModule.hot.accept(controllerName, () => render(factory().default))
  }

  return () => render(factory().default)
}
