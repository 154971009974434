import * as React from 'react'
import * as classnames from 'classnames/bind'
import { BoxContextProps, withBoxContext } from 'app/frontend/components/material/box/box'

import * as styles from './paragraph.css'

const c = classnames.bind(styles)

export interface Props extends React.HTMLAttributes<HTMLParagraphElement>, BoxContextProps {
  /** Text alignment. Defaults to inherit. */
  align?: 'start' | 'center' | 'end' | 'inherit'

  /** Vertical margin. */
  margin?: 'none' | 'small' | 'medium' | 'large'

  /** The size of the Paragraph text. Defaults to medium. */
  size?: 'small' | 'medium' | 'large'

  /** The size of the Paragraph text. Defaults to regular. */
  weight?: 'regular' | 'semibold'

  /** The type Paragraph text. Defaults to regular. */
  type?: 'regular' | 'monospace'

  /** Uppercase if true */
  uppercase?: boolean
}

/**
 * A paragraph of text
 */
class _Paragraph extends React.Component<Props, {}> {
  /**
   * Returns a css class name of the pattern `prefix-value`.  If an object is passed into
   * the value, the pattern is then `prefix-value.key-value.value`.
   */
  private classes(prefix: string, value: boolean | string | object): string[] {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [`${prefix}-${value}`]
    } else if (typeof value === 'object') {
      return Object.entries(value).map(([key, val]) => `${prefix}-${key}-${val}`)
    } else {
      return []
    }
  }

  render(): JSX.Element {
    const {
      children,
      align = 'inherit',
      margin = 'medium',
      weight = 'regular',
      type = 'regular',
      size = 'medium',
      uppercase,
      className,
      background,
      ...otherProps
    } = this.props

    return (
      <p
        className={classnames(
          c(
            `default`,
            this.classes('align', align),
            this.classes('margin', margin),
            this.classes('size', size),
            this.classes('weight', weight),
            this.classes('type', type),
            this.classes('uppercase', uppercase),
            this.classes('context', { background })
          ),
          className
        )}
        {...otherProps}
      >
        {children}
      </p>
    )
  }
}

export const Paragraph = withBoxContext<_Paragraph, Props>(_Paragraph)
