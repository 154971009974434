import ContentFeedbackModal from './feedback-modal'

/**
 * A modal used to report content feedback.
 */
export default ContentFeedbackModal

/**
 * Dispatch an action to open the modal.
 */
export { showContentFeedbackModal } from './feedback-modal-actions'

/**
 * Register this saga in order to listen for modal actions.
 */
export { default as contentFeedbackModalSaga } from './feedback-modal-saga'
