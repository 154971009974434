/**
 * Subset of alta permissions used in Brackend. Source of truth lives in Permissions service
 * (/permissions-core/src/main/java/com/knewton/permissions/core/model/StoredPermission.java).
 */
export enum Permission {
  // Knerd Tools
  KNERD_TOOLS_VIEW = 'KnerdTools.view',
  KNERD_TOOLS_VIEW_USERS = 'KnerdTools.viewUsers',
  KNERD_TOOLS_VIEW_COURSES = 'KnerdTools.viewCourses',
  KNERD_TOOLS_VIEW_COURSEPACKS = 'KnerdTools.viewCoursepacks',
  KNERD_TOOLS_VIEW_INSTITUTIONS = 'KnerdTools.viewInstitutions',
  KNERD_TOOLS_VIEW_ORDERS = 'KnerdTools.viewOrders',
  KNERD_TOOLS_VIEW_LTI_CREDENTIALS = 'KnerdTools.viewLTICredentials',
  KNERD_TOOLS_VIEW_ROLES = 'KnerdTools.viewRoles',
  KNERD_TOOLS_VIEW_GRAPHQL = 'KnerdTools.viewGraphQL',
  KNERD_TOOLS_VIEW_FEATURES = 'KnerdTools.viewFeatures',

  // Teach/Learn
  TEACH_LEARN_VIEW = 'TeachLearn.view',
  TEACH_LEARN_DEBUG = 'TeachLearn.debug',
  TEACH_LEARN_CHEAT = 'TeachLearn.cheat',
  TEACH_LEARN_VIEW_SEQUENCE = 'TeachLearn.viewSequence',
  TEACH_LEARN_USE_SHORTCUTS = 'TeachLearn.useShortcuts',

  // Users
  USER_LOGIN_AS = 'User.loginAs',
  USER_READ = 'User.read',
  USER_WRITE = 'User.write',
  USER_CREATE_ACCOUNT_BY_ADMIN = 'User.createAccountByAdmin',
  USER_ROLE_WRITE = 'UserRole.write',

  // Institutions
  INSTITUTION_READ = 'Institution.read',
  INSTITUTION_WRITE = 'Institution.write',

  // Coursepacks
  COURSEPACK_READ = 'Coursepack.read',
  COURSEPACK_WRITE = 'Coursepack.write',

  // Courses
  COURSE_READ = 'Course.read',
  COURSE_WRITE = 'Course.write',

  // Roles
  ROLE_READ = 'Role.read',
  ROLE_WRITE = 'Role.write',
  INSTRUCTOR_ROLE_GRANT = 'InstructorRole.grant',
  CONTRACTOR_ROLE_GRANT = 'ContentContractorRole.grant',

  // Permissions
  PERMISSION_READ = 'Permission.read',

  // LTI Credentials
  LTI_CREDENTIAL_READ = 'LTICredential.read',
  LTI_CREDENTIAL_WRITE = 'LTICredential.write',

  // Atoms
  ATOM_READ_ANSWER = 'Atom.readAnswer',

  // Orders
  DISTRIBUTOR_DOMAIN_WHITELIST_READ = 'DistributorDomainWhitelist.read',
  DISTRIBUTOR_READ = 'Distributor.read',
  DISTRIBUTOR_DOMAIN_WHITELIST_WRITE = 'DistributorDomainWhitelist.write',
  DISTRIBUTOR_WRITE = 'Distributor.write',
  FREE_PILOT_ORDER_SUBMIT = 'FreePilotOrder.submit',
  PAID_ORDER_SUBMIT = 'PaidOrder.submit',

  // Assignments
  ASSIGNMENT_PREVIEW = 'Assignment.preview',

  // Course Supplements
  SUPPLEMENT_READ = 'Supplement.read',

  // Influitive
  INFLUITIVE_ACCESS = 'Influitive.access',
}

export default Permission
