module.exports = {
  'preferred-font': 'var(--font)',
  '--font': '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  '--font-monospace': '"Monaco", "Consolas", "Lucida Console", monospace',
  '--font-weight-regular': '400',
  '--font-weight-semibold': '600',
  '--font-weight-bold': '700',
  '--max-width': '960px',
  '--half-max-width': 'calc(var(--max-width) * 0.5)',
  '--max-modal-width': '794px',
  '--primary-dark': 'var(--blue-1000)',
  '--primary-light': 'var(--color-white)',
  '--focus-color': '#5599d8',
  '--btn-focus-color': '#064560',
  '--text-focus-color': '#5296D5',
  '--appbar-height': '4rem',
  '--breakpoint-large': '768px',
  '--breakpoint-med': '480px',
  '--breakpoint-small': '320px',
  '--blue-50': '#E7F6F8',
  '--blue-100': '#D0EEF1',
  '--blue-200': '#A2DDE4',
  '--blue-300': '#73CDD7',
  '--blue-400': '#45BCCA',
  '--blue-500': '#00A4B7',
  '--blue-600': '#008392',
  '--blue-700': '#006975',
  '--blue-800': '#004b54',
  '--blue-900': '#002d32',
  '--blue-1000': '#21243C',
  '--grey-7': 'rgba(255, 255, 255, 0.7)',
  '--grey-50': '#F8F8F8',
  '--grey-75': '#F0F0F0',
  '--grey-100': '#DDDDDD',
  '--grey-200': '#CCCCCC',
  '--grey-300': '#ABABAB',
  '--grey-400': '#727272',
  '--grey-500': '#585858',
  '--grey-600': '#484848',
  '--grey-700': '#3b3b3b',
  '--grey-800': '#2e2e2e',
  '--grey-900': '#212121',
  '--green-50': '#E7F7F3',
  '--green-100': '#B9E7DB',
  '--green-200': '#8BD7C3',
  '--green-300': '#5CC7AC',
  '--green-400': '#2EB794',
  '--green-500': '#00A87D',
  '--green-600': '#008765',
  '--green-700': '#006b50',
  '--green-800': '#004d39',
  '--green-900': '#002e23',
  '--orange-50': '#FFF9F2',
  '--orange-100': '#FFE6CB',
  '--orange-200': '#FFD2A4',
  '--orange-300': '#FFBE7A',
  '--orange-400': '#F6AB5D',
  '--orange-500': '#F49A3C',
  '--orange-600': '#F28411',
  '--orange-700': '#D66700',
  '--orange-800': '#C15100',
  '--orange-900': '#973500',
  '--purple-50': '#F5F5FF',
  '--purple-100': '#DADCF5',
  '--purple-200': '#C1C6EF',
  '--purple-300': '#A9AFE8',
  '--purple-400': '#9098E2',
  '--purple-500': '#7882DC',
  '--purple-600': '#636BB5',
  '--purple-700': '#4D538C',
  '--purple-800': '#373C64',
  '--purple-900': '#21243C',
  '--red-50': '#FDF0F1',
  '--red-100': '#FBD4D6',
  '--red-200': '#F9B8BA',
  '--red-300': '#F79C9F',
  '--red-400': '#F58084',
  '--red-500': '#F36469',
  '--red-600': '#C84E50',
  '--red-700': '#9B4043',
  '--red-800': '#6F2E30',
  '--red-900': '#431C1D',
  '--yellow-50': '#FEFAF0',
  '--yellow-100': '#FCF0D2',
  '--yellow-200': '#FAE7B4',
  '--yellow-300': '#F8DD97',
  '--yellow-400': '#F6D479',
  '--yellow-500': '#F5CB5C',
  '--yellow-600': '#C9A74C',
  '--yellow-700': '#9c823b',
  '--yellow-800': '#705d2a',
  '--yellow-900': '#43381a',
  '--color-white': '#FFFFFF',
  '--color-black': '#000000',
  '--login-gradient-start': '#636BB5',
  '--login-gradient-end': '#73CDD7',
  '--login-gradient-angle': '135deg',
  '--error-link-blue': '#0000EE',
  '--z-index-sub-modal': '1000', // sticky page components (e.g. header, sidebar)
  '--z-index-modal': '2000', // sticky common components (e.g. modal, popover)
  '--z-index-super-modal': '3000', // troublesome shared components (.e.g. learnosity, time picker)
}
