/**
 * This wrapper around window.location.href
 * exists only as a way to test what
 * values are passed to window.location.href
 */

export function redirect(path: string): void {
  window.location.href = path
}

export default redirect
