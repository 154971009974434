import * as React from 'react'
import * as styles from 'app/frontend/content/atoms/external-atom/external-button.css'
import * as classNames from 'classnames'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Icon } from 'app/frontend/components/material/icon'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { buildUrl } from './external-atom-util'

const t = tns('external_url')

/**
 * This button accompanies an embedded iframe for cases when a user might like to open
 * the embedded URL in a new tab (e.g. if they are on mobile and the iframe is too small)
 */
export const NewTabButton: React.FunctionComponent<{
  url: string
}> = ({ url }) => {
  const realUrl = buildUrl(window?.location?.origin, url)
  return (
    <ButtonMaterial
      icon={<Icon name="icon-preview" className={styles.icons} />}
      label={t('external_url_new_tab_button_label')}
      theme="bordered"
      className={styles.previewBtn}
      data-bi="preview-btn"
      href={realUrl}
      target="_blank"
    />
  )
}

/**
 * This launcher shows a more prominent centered button for when we aren't rendering an iframe
 * and just want the user to open the external URL in a new tab
 */
export const NewTabUrlLauncher: React.FunctionComponent<{
  url: string
  title: string
}> = ({ url, title }) => {
  const realUrl = buildUrl(window?.location?.origin, url)
  return (
    <div className={styles.launcherContainer} data-test="atom-external-launcher">
      <ButtonMaterial
        icon={<Icon name="icon-preview" className={classNames(styles.icons, styles.newTabIcons)} />}
        label={t('external_url_new_tab_launch_label')}
        theme="bordered"
        className={classNames(styles.previewBtn, styles.prominent)}
        data-bi="preview-btn"
        href={realUrl}
        target="_blank"
      />
      <div data-test="atom-external-title">{title}</div>
    </div>
  )
}
