import { combineReducers } from 'redux'
import credentialsReducerByInstitutionId, {
  CredentialByInstitutionIdState,
} from './credentials-reducer-by-institution-id'

export type CredentialsReducerState = {
  byInstitutionId: CredentialByInstitutionIdState
}

export default combineReducers<CredentialsReducerState>({
  byInstitutionId: credentialsReducerByInstitutionId,
})
