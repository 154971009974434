import * as React from 'react'
import * as moment from 'moment'
import * as classnames from 'classnames'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { DateTimeFormats } from 'app/helpers/timezone'
import { DateProps } from '../datepicker'
import { TimeProps } from '../timepicker'
import { ComboCalendar } from './combo-calendar'
import { useDateTimePicker } from './use-datetimepicker'
import { useStyles } from './styles'

export type Props = DateProps &
  TimeProps & { timePickerLabel: string; className?: string; beforeNow?: boolean }

export const ButtonCombopicker: React.FunctionComponent<Props> = ({
  value,
  label,
  dataBi,
  minDate = moment().add(-2, 'years'),
  maxDate = moment().add(2, 'years'),
  className,
  dateFormat = DateTimeFormats.MONTH_DATE,
  timeFormat = DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE,
  timeFormatRegex,
  onChange,
  allowUndefined = false,
  acceptAnyFormat,
  timePickerLabel,
  timepickerId,
  disabled,
  beforeNow,
}) => {
  const styles = useStyles()
  const dateTimeFormat = `${dateFormat}, ${timeFormat}`
  const container = React.useRef<HTMLDivElement>()

  const { isPickerOpen, showPicker, hidePicker, setText } = useDateTimePicker({
    dateFormat,
    value,
    minDate,
    maxDate,
    onChange,
    allowUndefined,
    acceptAnyFormat,
    timeFormat,
    container,
    timepickerId,
  })

  const handleChangeInCombo = (val: moment.Moment) => {
    setText(val ? val.format(dateTimeFormat) : '')
  }

  const invalidValue = (minDate && value?.isBefore(minDate)) || (maxDate && value?.isAfter(maxDate))

  return (
    <div ref={container} className={classnames(styles.static)} data-bi={dataBi}>
      <ButtonMaterial
        label={label}
        onClick={showPicker}
        className={classnames(className, isPickerOpen && styles.toggle)}
        disabled={disabled}
      />
      <ComboCalendar
        pickerOpen={isPickerOpen}
        hidePicker={hidePicker}
        onChange={handleChangeInCombo}
        setChange={onChange}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        timeFormat={timeFormat}
        timeFormatRegex={timeFormatRegex}
        timePickerLabel={timePickerLabel}
        timepickerId={timepickerId}
        pickerStyle={styles.rightAligned}
        disabled={invalidValue}
        beforeNow={beforeNow}
      />
    </div>
  )
}
