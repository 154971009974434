export function isEnabled(featureName: Features.FeatureName): boolean {
  if (window && window.context && window.context.features) {
    return !!window.context.features[featureName]
  }
  return false
}

export function featureFlagsReducer(state = {}) {
  return state
}
