import * as React from 'react'
import { isEmpty } from 'lodash'
import StickyElement from 'app/frontend/components/sticky-element/sticky-element'
import AtomSpinner from 'app/frontend/components/material/loading/spinner-atom'
import { EmptyState } from 'app/frontend/pages/material/teach/assessment-builder/empty-state'
import { ParentEntityType } from 'app/typings/commons'
import { LoadingAndError } from 'app/frontend/helpers/apollo/adopt'
import { useTableOfContentsForCoursepack } from 'app/frontend/compositions/connected/get-table-of-contents-for-coursepack'
import { useAssessmentSequencesWithSequenceOverrides } from 'app/frontend/compositions/connected/get-assessment-sequences'
import { useAssessedSequences } from 'app/frontend/pages/material/teach/compositions/connected/get-assessed-sequences'
import { useAssessmentBuilderPermissionState } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-builder-permission-state/use-assessment-builder-permission-state'
import { useHasStudentStartedAssignment } from 'app/frontend/pages/material/teach/compositions/connected/has-student-started-assignment'
import { AssessmentQuestionsWrapper } from './assessment-questions/assessment-questions-wrapper'
import { AssessmentHeader } from './assessment-header'
import * as styles from './assessment-body.css'

interface Props {
  assessment: GQL.GetAssignment.Assignment
  isLmsIntegrated: boolean
  section: GQL.GetSection.Section
  course: GQL.GetCourse.Course
}

export const AssessmentBody: React.FunctionComponent<Props> = ({
  assessment,
  isLmsIntegrated,
  section,
  course,
}) => {
  const parentEntityId = assessment.sectionId || assessment.courseId
  const parentEntityType = assessment.sectionId ? ParentEntityType.Section : ParentEntityType.Course
  const assessmentId = assessment.id

  const { titles, ...le1 } = useTableOfContentsForCoursepack(course.coursepackId)
  const { assessmentSequencesWithOverrides, ...le2 } = useAssessmentSequencesWithSequenceOverrides(
    assessmentId
  )

  /**
   * Calling the useHasStudentStartedAssignment here with cache-and-network fetch-policy
   * because, we need to get the student started assessment state when every time this renders.
   * otherwise student started assessment state will not be changed until the page
   * refreshed.
   */
  const { hasStudentStartedAssignment, ...le3 } = useHasStudentStartedAssignment(
    assessmentId,
    'cache-and-network'
  )
  const {
    isCourseAssignmentOnSection,
    hasEditPermission,
    ...le4
  } = useAssessmentBuilderPermissionState()
  const { sequenceIdToAssessmentsMap, ...le5 } = useAssessedSequences(
    parentEntityId,
    parentEntityType
  )

  const loadingAndErrorResults: LoadingAndError[] = [le1, le2, le3, le4, le5]
  const loading = loadingAndErrorResults.some(r => r.loading)
  const error = loadingAndErrorResults.map(r => r.error).find(err => !!err)

  if (loading || error) {
    return (
      <div className={styles.assessmentBodyLoader}>
        <AtomSpinner />
      </div>
    )
  }

  // This renders an empty state only if there are no LOs selected and no questions (specifically custom ones)
  if (isEmpty(assessment.pathLearningObjectives) && isEmpty(assessmentSequencesWithOverrides)) {
    return (
      <EmptyState
        assessmentId={assessment.id}
        coursepackId={course.coursepackId}
        parentEntityType={parentEntityType}
        parentEntityId={parentEntityId}
        isCourseAssignmentOnSection={isCourseAssignmentOnSection}
        hasEditPermission={hasEditPermission}
      />
    )
  }

  return (
    <>
      <StickyElement>
        <AssessmentHeader
          parentEntityType={parentEntityType}
          parentEntityId={parentEntityId}
          assessment={assessment}
          course={course}
          section={section}
          isCourseAssignmentOnSection={isCourseAssignmentOnSection}
          hasStudentStartedAssignment={hasStudentStartedAssignment}
          hasEditPermission={hasEditPermission}
        />
      </StickyElement>
      <AssessmentQuestionsWrapper
        assessment={assessment}
        assessmentSequences={assessmentSequencesWithOverrides}
        titles={titles}
        isCourseAssignmentOnSection={isCourseAssignmentOnSection}
        hasStudentStartedAssignment={hasStudentStartedAssignment}
        hasEditPermission={hasEditPermission}
        isLmsIntegrated={isLmsIntegrated}
        allAssessedSequences={sequenceIdToAssessmentsMap}
      />
    </>
  )
}

AssessmentBody.displayName = 'AssessmentBody'
