import { makeApiActions } from 'app/frontend/api/api-redux'
import { Action } from 'redux'

export enum InstitutionLtiCredentialsAction {
  GENERATE_LTI_CREDENTIALS = 'GENERATE_LTI_CREDENTIALS',
}
export const API_GENERATE_LTI_CREDENTIALS = makeApiActions('API_GENERATE_LTI_CREDENTIALS')
export const API_GET_LTI_CREDENTIALS = makeApiActions('API_GET_LTI_CREDENTIALS')

export interface GenerateLtiCredentialsAction extends Action {
  type: InstitutionLtiCredentialsAction.GENERATE_LTI_CREDENTIALS
  institutionId: Commons.InstitutionId
}

export function generateLtiCredentials(
  institutionId: Commons.InstitutionId
): GenerateLtiCredentialsAction {
  return {
    type: InstitutionLtiCredentialsAction.GENERATE_LTI_CREDENTIALS,
    institutionId,
  }
}
