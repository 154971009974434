import { predictTimeRegex } from '../helpers'

const getOptionsArray = () => {
  const options = []
  for (let i = 0; i < 24; i++) {
    const hours = i % 12 || 12
    const ampm = i < 12 ? 'AM' : 'PM'
    options.push(`${hours}:00 ${ampm}`)
    options.push(`${hours}:15 ${ampm}`)
    options.push(`${hours}:30 ${ampm}`)
    options.push(`${hours}:45 ${ampm}`)
  }
  return options
}

export const optionsArray = getOptionsArray()

// Dynamically get the index to focus on based on the input.
export const getFocusIndex = (val: string, optionArray): number => {
  if (val === '') {
    return 0
  }
  if (val === 'p' || val === 'P') {
    return optionArray.indexOf('12:00 PM')
  }
  const match = val.match(predictTimeRegex)
  if (match) {
    const hh = parseInt(match[1], 10)
    const mm = match[2]
      ? match[2].length === 1
        ? parseInt(match[2], 10) * 10
        : parseInt(match[2], 10)
      : 0
    const ampm = hh > 12 || val.includes('P') || val.includes('p') ? 'PM' : 'AM'
    const roundedMM = mm ? Math.floor(mm / 15) * 15 : '00'
    return optionArray.indexOf(`${hh % 12 || '12'}:${roundedMM || '00'} ${ampm}`)
  } else {
    return -1
  }
}
