import { sumBy } from 'lodash'

/**
 * Converts a float to a percentage with two decimals.
 * 0.123456 => 12.34
 */
export const convertToPercent2Decimals = (value: number) => {
  return value ? (value * 100).toFixed(2) : '0'
}

/**
 * Converts a map of numbers into a map of percentages (between 0 and 100). It scales the values
 * of the input map so that the sum of values in the output is 100. a "minPercentage" can be
 * provided if non zero small values need to have a minimum percentage.
 *
 * { a: 4, b: 2, c: 2 } => { a: 50, b: 25, c: 25 }
 */
export const toPercentages = <T extends { [key: string]: number }>(
  stats: T,
  minPercentage = 5
): T => {
  const entries = stats ? Object.entries(stats) : []
  const sum = sumBy(entries, ([_key, value]) => value)
  const minValue = (sum * minPercentage) / 100
  const numAdjustments = sumBy(entries, ([_key, value]) => (value > 0 && value < minValue ? 1 : 0))
  const sumWithoutAdjustedValues = sumBy(entries, ([_key, value]) => (value > minValue ? value : 0))
  const pctLeftWithoutAdjustedValues = 100 - numAdjustments * minPercentage

  const ret = {}
  entries.forEach(([key, value]) => {
    if (value > 0 && value < minValue) {
      ret[key] = minPercentage
    } else {
      ret[key] = (value * pctLeftWithoutAdjustedValues) / sumWithoutAdjustedValues
    }
  })
  return ret as T
}
