import { combineReducers } from 'redux'
import activeCourseReducer, { ActiveCourseState } from './active-course-reducer'
import activeChapterCardIdsReducer from './active-chapter-card-ids-reducer'
import isFetchingReducer from './is-fetching-reducer'
import errorReducer, { ErrorState } from './error-reducer'
import commonCartridgeReducer, { CommonCartridgeReducerState } from './common-cartridge-reducer'
import courseworkReducer, { CourseworkUIState } from './coursework'

export type CourseReducerState = {
  activeCourse: ActiveCourseState
  activeChapterCardIds: string[]
  isFetching: boolean
  error: ErrorState
  commonCartridge: CommonCartridgeReducerState
  coursework: CourseworkUIState
}

export default combineReducers<CourseReducerState>({
  activeCourse: activeCourseReducer,
  activeChapterCardIds: activeChapterCardIdsReducer,
  isFetching: isFetchingReducer,
  error: errorReducer,
  commonCartridge: commonCartridgeReducer,
  coursework: courseworkReducer,
})
