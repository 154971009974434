import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { combineReducers } from 'redux'
import coursesReducerById, { CoursesReducer } from './courses-reducer-by-id'
import { isAssignmentAdaptive } from 'app/frontend/pages/material/learn/entities/assignments/assignments-reducer'

export const getCourses = (state: LearnControllerState) => state.entities.courses
export const getCoursesAsMap = (state: LearnControllerState) => getCourses(state).byId
export const getCoursesAsList = (state: LearnControllerState) =>
  Object.values(getCoursesAsMap(state))
export const getCourseById = (state: LearnControllerState, courseId: string) =>
  getCoursesAsMap(state)[courseId]
export const getLateSubmissionEnabled = (
  state: LearnControllerState,
  courseId: string,
  assignmentId: string
) => {
  const course = getCoursesAsMap(state)[courseId]
  const isAdaptive = isAssignmentAdaptive(state, assignmentId)
  return course
    ? isAdaptive
      ? course.assignmentLateSubmissionEnabled
      : course.quizLateSubmissionEnabled
    : false
}
export type CoursesState = {
  byId: CoursesReducer
}

export default combineReducers<CoursesState>({
  byId: coursesReducerById,
})
