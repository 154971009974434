'use strict'

import * as Clipboard from 'clipboard'

/***
 * Helper function that copies any text to the clipboard.
 * @param text: the content to be copied
 * @returns {Promise<Event>} a clipboard copy event will be resolved/rejected
 */
export function copyToClipboard(text) {
  return new Promise<Event>((resolve, reject) => {
    // Create a button and put it in the DOM.
    // If the element in not in the DOM, IE11 will not copy the text.
    const button = document.createElement('button')
    button.setAttribute('style', 'display: none')
    button.classList.add('copy-to-clipboard')
    document.body.appendChild(button)

    const clipboard = new Clipboard(button, {
      text() {
        return text
      },
    })

    clipboard.on('success', event => {
      event.clearSelection()
      resolve(event)
      document.body.removeChild(button)
      clipboard.destroy()
    })

    clipboard.on('error', event => {
      reject(event)
      document.body.removeChild(button)
      clipboard.destroy()
    })

    button.click()
  })
}

export default copyToClipboard
