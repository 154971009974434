export type SHOW_MODAL = 'SHOW_MODAL'
export const SHOW_MODAL: SHOW_MODAL = 'SHOW_MODAL'
export type HIDE_MODAL = 'HIDE_MODAL'
export const HIDE_MODAL: HIDE_MODAL = 'HIDE_MODAL'
export type CLEAR_REFOCUS = 'CLEAR_REFOCUS'
export const CLEAR_REFOCUS: CLEAR_REFOCUS = 'CLEAR_REFOCUS'
export type REFOCUS_MODAL = 'REFOCUS_MODAL'
export const REFOCUS_MODAL: REFOCUS_MODAL = 'REFOCUS_MODAL'
export type UPDATE_MODAL = 'UPDATE_MODAL'
export const UPDATE_MODAL: UPDATE_MODAL = 'UPDATE_MODAL'

export type ShowModalAction = {
  type: SHOW_MODAL
  name: string
  options: { [prop: string]: any }
}
export const showModal = (name: string, options: { [prop: string]: any } = {}): ShowModalAction => {
  return {
    type: SHOW_MODAL,
    name,
    options,
  }
}

export type HideModalAction = {
  type: HIDE_MODAL
  name: string
  options: { [prop: string]: any }
}
export const hideModal = (name: string, options?: { [prop: string]: any }): HideModalAction => {
  return {
    type: HIDE_MODAL,
    name,
    options,
  }
}

type UpdateModalAction = {
  type: UPDATE_MODAL
  name: string
  options: { [prop: string]: any }
}
export const updateModal = (name: string, options?: { [prop: string]: any }): UpdateModalAction => {
  return {
    type: UPDATE_MODAL,
    name,
    options,
  }
}

export type ClearRefoucsAction = {
  type: CLEAR_REFOCUS
  name: string
}
export const clearRefocus = (name: string): ClearRefoucsAction => {
  return {
    type: CLEAR_REFOCUS,
    name,
  }
}

export type RefocusModalAction = {
  type: REFOCUS_MODAL
  name: string
}
export const refocusModal = (name: string): RefocusModalAction => {
  return {
    type: REFOCUS_MODAL,
    name,
  }
}

export type ModalActions =
  | ShowModalAction
  | HideModalAction
  | UpdateModalAction
  | ClearRefoucsAction
  | RefocusModalAction
