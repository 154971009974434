import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import * as classnames from 'classnames'
import AppBar from 'accessible-react-toolbox/lib/app_bar'
import Navigation from 'accessible-react-toolbox/lib/navigation'
import Link from 'accessible-react-toolbox/lib/link'
import NavActions from './nav-actions'
import { getStaticUrl } from 'app/frontend/helpers/assets'
import NavAppMenu from './nav-app-menu'
import * as CurrentUser from 'app/frontend/helpers/current-user'
import { paymentInitSiteName } from 'app/frontend/pages/material/learn/modals/payments/actions'
import { Icon } from 'app/frontend/components/material/icon'
import { ExitLdbButton } from 'app/frontend/pages/material/learn/quiz-flow/exit-ldb'
import * as LogoAltaColorSvg from 'app/public/img/logo-knewton-alta-color-2.svg'
import * as LogoAltaWhiteSvg from 'app/public/img/logo-knewton-alta-white-2.svg'
import * as styles from './nav-app-bar.css'
import { Browser } from 'app/frontend/helpers/browser'

export type NavAppBarTheme = 'dark' | 'light'

interface INavAppBarProps {
  theme?: NavAppBarTheme
  showMenu?: boolean
  clickableLogo?: boolean
  title: string
  useGlobalNavConfig?: boolean
  navLinks?: {
    label: string
    path: string
    dataBi: string
  }[]
  onToggleDrawer: () => void
  onToggleMenu: () => void
}

export const NavAppBar: React.FunctionComponent<INavAppBarProps> = props => {
  const {
    title,
    onToggleDrawer,
    onToggleMenu,
    showMenu,
    theme,
    useGlobalNavConfig,
    navLinks,
    clickableLogo = true,
  } = props
  const secureBrowser = Browser.isSecureBrowser()
  const component = (
    <img
      src={getStaticUrl(theme === 'light' ? LogoAltaColorSvg : LogoAltaWhiteSvg)}
      alt="Knewton Alta logo, link to home page"
      className={title && title.length > 0 ? styles.logoBorder : styles.logo}
    />
  )
  return (
    <AppBar
      theme={{
        appBar: styles[`appBar-${theme || 'dark'}`],
        title: styles.title,
      }}
      flat={true}
    >
      {showMenu && !secureBrowser && (
        <button
          className={classnames(styles.button, {
            [styles.globalNavHamburger]: useGlobalNavConfig,
          })}
          onClick={onToggleDrawer}
          aria-label="Course and Help Navigation links"
        >
          <Icon
            name="icon-menu"
            size="large"
            className={classnames({
              [styles.menuIconLight]: theme !== 'light',
              [styles.menuIconDark]: theme === 'light',
            })}
          />
        </button>
      )}
      {secureBrowser || !clickableLogo ? (
        component
      ) : (
        <a href="/home" data-bi="app-bar-logo-link">
          {component}
        </a>
      )}
      {navLinks ? (
        <Navigation type="horizontal" theme={{ horizontal: styles.horizontal }}>
          {navLinks.map((navLink, i) => (
            <Link
              key={i}
              href={navLink.path}
              data-bi={navLink.dataBi}
              theme={{ link: styles.navLink }}
            >
              {navLink.label}
            </Link>
          ))}
        </Navigation>
      ) : (
        <div className={styles.horizontal} />
      )}
      {secureBrowser ? (
        <ExitLdbButton />
      ) : (
        <NavAppMenu
          onClick={onToggleMenu}
          navAppMenuTheme={theme}
          useGlobalNavConfig={useGlobalNavConfig}
        />
      )}
    </AppBar>
  )
}

NavAppBar.displayName = 'NavAppBar'

function mapStateToProps(state: any): Partial<INavAppBarProps> {
  const title = _.get(state, 'global.ui.nav.pageTitle')
  return {
    title,
  }
}

export function mapDispatchToProps(dispatch: any): Partial<INavAppBarProps> {
  return {
    onToggleDrawer(): void {
      dispatch(NavActions.toggleNavDrawer())
    },
    onToggleMenu(): void {
      dispatch(NavActions.toggleNavMenu())
      if (CurrentUser.isLearner()) {
        dispatch(paymentInitSiteName())
      }
    },
  }
}

export default connect<
  Partial<INavAppBarProps>,
  Partial<INavAppBarProps>,
  Partial<INavAppBarProps>
>(
  mapStateToProps,
  mapDispatchToProps
)(NavAppBar)
