import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './question-count-warnings.css'
import * as classNames from 'classnames'
import { ParentEntityType } from 'app/typings/commons'

const t = tns('teach:assessment_builder')

export type UsedQuestions = {
  questionCount: number
  assessmentType: ParentEntityType
}

interface OwnProps {
  usedQuestionsCount?: UsedQuestions
  printIncompatibleCount?: number
  ldbIncompatibleCount?: number
  staticQuestionCount?: number
  poolQuestionCount?: number
  multipleAttemptsCount?: number
  printableVersionsCount?: number
  messageStyle?: string
}

type Props = OwnProps

export const QuestionCountWarnings: React.FunctionComponent<Props> = ({
  usedQuestionsCount,
  printIncompatibleCount,
  ldbIncompatibleCount,
  staticQuestionCount,
  poolQuestionCount,
  multipleAttemptsCount,
  printableVersionsCount,
  messageStyle = styles.warningMessageListItem,
}) => {
  const messages: string[] = [
    ...(usedQuestionsCount?.questionCount > 0
      ? [
          usedQuestionsCount.assessmentType === ParentEntityType.Section
            ? t('used_in_other_section_level_assessments_warning', {
                count: usedQuestionsCount.questionCount,
              })
            : t('used_in_other_course_level_assessments_warning', {
                count: usedQuestionsCount.questionCount,
              }),
        ]
      : []),
    ...(printIncompatibleCount
      ? [t('print_incompatible_warning', { count: printIncompatibleCount })]
      : []),
    ...(ldbIncompatibleCount
      ? [t('ldb_incompatible_warning', { count: ldbIncompatibleCount })]
      : []),
    ...(staticQuestionCount > 0 && poolQuestionCount === 0 && multipleAttemptsCount > 1
      ? [
          t('static_questions_attempts_warning', {
            staticCount: staticQuestionCount,
            attemptCount: multipleAttemptsCount,
          }),
        ]
      : []),
    ...(staticQuestionCount === 0 && poolQuestionCount > 0 && multipleAttemptsCount > 1
      ? [
          t('pool_questions_attempts_warning', {
            poolCount: poolQuestionCount,
            attemptCount: multipleAttemptsCount,
          }),
        ]
      : []),
    ...(staticQuestionCount > 0 && poolQuestionCount > 0 && multipleAttemptsCount > 1
      ? [
          t('static_pool_questions_attempts_warning', {
            staticCount: staticQuestionCount,
            poolCount: poolQuestionCount,
            attemptCount: multipleAttemptsCount,
          }),
        ]
      : []),
    ...(staticQuestionCount > 0 && poolQuestionCount === 0 && printableVersionsCount > 1
      ? [
          t('static_questions_versions_warning', {
            staticCount: staticQuestionCount,
            versionCount: printableVersionsCount,
          }),
        ]
      : []),
    ...(staticQuestionCount === 0 && poolQuestionCount > 0 && printableVersionsCount > 1
      ? [
          t('pool_questions_versions_warning', {
            poolCount: poolQuestionCount,
            versionCount: printableVersionsCount,
          }),
        ]
      : []),
    ...(staticQuestionCount > 0 && poolQuestionCount > 0 && printableVersionsCount > 1
      ? [
          t('static_pool_questions_versions_warning', {
            staticCount: staticQuestionCount,
            poolCount: poolQuestionCount,
            versionCount: printableVersionsCount,
          }),
        ]
      : []),
  ]

  // if only one warning message found in the list, show the text, without bullet styles
  if (messages.length === 1) {
    return <span className={messageStyle}>{messages[0]}</span>
  }

  return (
    <ul className={styles.warningMessageList}>
      {messages.map((message, idx) => (
        <li key={`msg-${idx}`} className={classNames(styles.warningListItemMargin, messageStyle)}>
          <span className={styles.warningMessage}>{message}</span>
        </li>
      ))}
    </ul>
  )
}

QuestionCountWarnings.displayName = 'QuestionCountWarnings'
