export type LearningObjectiveId = string
export type TopicId = string
export type ConceptId = string

export enum SequenceStatus {
  Saving = 'SAVING',
  Error = 'ERROR',
  None = 'NONE',
}

export enum TopicLoStatus {
  Saving = 'SAVING',
  Error = 'ERROR',
  None = 'NONE',
}

export enum TopicLoErrorType {
  TooManyObjectives = 'TOO_MANY_LEARNING_OBJECTIVES',
}

export enum ConceptStatus {
  Saving = 'SAVING',
  Error = 'ERROR',
  None = 'NONE',
}

export type SequenceStatusMap = {
  [sequenceId: string]: SequenceStatus
}
export type LoStatus = {
  status: TopicLoStatus
  type?: TopicLoErrorType
}
export type LoStatusMap = { [loId: string]: LoStatus }
export type TopicLoStatusMap = { [topicId: string]: LoStatusMap }

export type ConceptsByLoMap = { [loId: string]: ConceptId[] }
export type ConceptStatusesByLoMap = { [loId: string]: ConceptStatuses }
export type ConceptStatuses = { [conceptId: string]: ConceptStatus }

export type TopicLo = {
  topicId: TopicId
  loId: LearningObjectiveId
}
export type TopicLoMap = { [topicId: string]: Set<LearningObjectiveId> }

export type LoConceptMap = { [loId: string]: Set<ConceptId> }
