import * as React from 'react'
import { connect } from 'react-redux'
import { groupBy } from 'lodash'
import { PageBody } from 'app/frontend/components/material/page'
import {
  CUSTOM_QUESTIONS_ENTITY_LIMIT,
  CustomAssessmentQuestions,
} from './custom-assessment-questions'
import { AssessmentSequenceWithOverride } from 'app/frontend/helpers/assignment'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { isAnyModalOpen } from 'app/frontend/components/material/modal/modal-reducer'
import {
  QuestionViewPreferencesOptionsView,
  QuestionViewPreferencesOptionsViewDensity,
  QuestionViewPreferencesStatus,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'
import { SequenceIdToAssessmentsMapType } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-helper'
import { AssessmentBuilderWarningAlert } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-builder-warning-alert'
import { AssessmentQuestionOrderViewWrapper } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-question-order-view-wrapper'
import { AddCustomQuestionButton } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/add-custom-question-button'
import { ParentEntityType } from 'app/typings/commons'
import { AssessmentQuestions } from './assessment-questions'
import * as styles from './assessment-questions.css'

export interface AssessmentSequenceByLoMapType {
  [id: string]: AssessmentSequenceWithOverride[]
}

export interface OwnProps {
  assessment: GQL.GetAssignment.Assignment
  assessmentSequences: AssessmentSequenceWithOverride[]
  titles: GQL.TitleTaxonFields.Fragment[]
  isCourseAssignmentOnSection: boolean
  isLmsIntegrated: boolean
  hasStudentStartedAssignment: boolean
  allAssessedSequences: SequenceIdToAssessmentsMapType
  hasEditPermission: boolean
}
type StateProps = {
  isModalOpen: boolean
  viewBy: QuestionViewPreferencesOptionsView
  displayDensity: QuestionViewPreferencesOptionsViewDensity
  preferencesStatus: QuestionViewPreferencesStatus
}
interface ownState {
  customItemVariationLength: number
}

type Props = OwnProps & StateProps

export class _AssessmentQuestionsWrapper extends React.Component<Props, ownState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      customItemVariationLength: 0,
    }
  }
  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    // Prevent rerenders while there is an open modal
    return !nextProps.isModalOpen
  }

  setCustomItemVariationLength = (length: number) => {
    this.setState({ customItemVariationLength: length })
  }

  render() {
    const { customItemVariationLength } = this.state
    const {
      assessmentSequences,
      assessment,
      titles,
      isCourseAssignmentOnSection,
      isLmsIntegrated,
      hasStudentStartedAssignment,
      viewBy,
      displayDensity,
      allAssessedSequences,
      hasEditPermission,
    } = this.props
    const groupedAssessmentSequencesByLo = groupBy(
      assessmentSequences,
      as => as.learningObjectiveId || 'CUSTOM'
    )
    const hasAltaSequences = Object.keys(groupedAssessmentSequencesByLo).some(id => id !== 'CUSTOM')
    const customSequences = groupedAssessmentSequencesByLo.CUSTOM
    const canAddCustomQuestions =
      !isCourseAssignmentOnSection &&
      !hasStudentStartedAssignment &&
      hasEditPermission &&
      (!customSequences || customSequences.length < CUSTOM_QUESTIONS_ENTITY_LIMIT)

    return (
      <PageBody>
        <>
          <AssessmentBuilderWarningAlert
            assessmentId={assessment.id}
            parentEntityId={assessment.sectionId || assessment.courseId}
            parentEntityType={
              assessment.sectionId ? ParentEntityType.Section : ParentEntityType.Course
            }
            customItemVariationLength={customItemVariationLength}
          />

          {viewBy === QuestionViewPreferencesOptionsView.BY_OBJECTIVE && (
            <>
              <AssessmentQuestions
                assessment={assessment}
                assessmentSequences={groupedAssessmentSequencesByLo}
                titles={titles}
                isLmsIntegrated={isLmsIntegrated}
                isCourseAssignmentOnSection={isCourseAssignmentOnSection}
                hasStudentStartedAssignment={hasStudentStartedAssignment}
                displayDensity={displayDensity}
                allAssessedSequences={allAssessedSequences}
                hasEditPermission={hasEditPermission}
              />
              {hasAltaSequences && (customSequences || canAddCustomQuestions) && (
                <hr className={styles.customQuestionsDivider} />
              )}
              {customSequences && (
                <CustomAssessmentQuestions
                  customSequences={customSequences}
                  assessment={assessment}
                  isCourseAssignmentOnSection={isCourseAssignmentOnSection}
                  hasStudentStartedAssignment={hasStudentStartedAssignment}
                  displayDensity={displayDensity}
                  hasEditPermission={hasEditPermission}
                  questionNumber={assessmentSequences.length - customSequences.length}
                  setCustomItemVariationLength={this.setCustomItemVariationLength}
                />
              )}
            </>
          )}

          {viewBy === QuestionViewPreferencesOptionsView.BY_ORDER && (
            <>
              <AssessmentQuestionOrderViewWrapper
                assessment={assessment}
                isCourseAssignmentOnSection={isCourseAssignmentOnSection}
                hasStudentStartedAssignment={hasStudentStartedAssignment}
                titles={titles}
                assessmentSequences={assessmentSequences}
                isLmsIntegrated={isLmsIntegrated}
                allAssessedSequences={allAssessedSequences}
                hasEditPermission={hasEditPermission}
              />
            </>
          )}

          {canAddCustomQuestions && <AddCustomQuestionButton assessment={assessment} />}
        </>
      </PageBody>
    )
  }
}

const mapStatetoProps = (state: TeachControllerState): StateProps => {
  const { viewBy, displayDensity, status } = state.ui.assessmentBuilder.questionViewPreferences
  return {
    isModalOpen: isAnyModalOpen(state),
    viewBy: viewBy,
    displayDensity: displayDensity,
    preferencesStatus: status,
  }
}

export const AssessmentQuestionsWrapper = connect<StateProps, {}, OwnProps>(mapStatetoProps)(
  _AssessmentQuestionsWrapper
)
