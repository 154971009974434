export const TOGGLE_CHAPTER_CARD = 'TOGGLE_CHAPTER_CARD'
export function toggleChapterCard(cardId, isExpanded) {
  return {
    type: TOGGLE_CHAPTER_CARD,
    cardId,
    isExpanded,
  }
}

export const SELECT_TOPIC_NAME = 'SELECT_TOPIC_NAME'
export function selectTopicName(topicId) {
  return {
    type: SELECT_TOPIC_NAME,
    topicId,
  }
}

export const SELECT_TOPIC_FILTER = 'SELECT_TOPIC_FILTER'
export function selectTopicFilter(courseId, filterByActive) {
  return {
    type: SELECT_TOPIC_FILTER,
    courseId,
    filterByActive,
  }
}
