import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { useTypesetEffect, Priority } from 'app/frontend/content/mathjax'
import DatasetAtom from 'app/frontend/components/atoms/dataset-atom'
import ImageAtom from 'app/frontend/components/atoms/image-atom'
import VideoAtom from 'app/frontend/content/atoms/video-atom/video-atom'
import DesmosAtom from 'app/frontend/content/atoms/desmos-atom/desmos-atom'
import AlchemieVSEPRrAtom from 'app/frontend/content/atoms/alchemie-vsper-atom/alchemie-vsper-atom'
import { ExternalAtom, NewTabUrlLauncher } from 'app/frontend/content/atoms/external-atom'
import { ContentManagerActions, useContentManagerActions } from './manager'

interface Props {
  node: Element
  media?: GQL.AtomMedia[]
  canAutoplay?: boolean
}

/**
 * The ContentLoader will inject content from the CMS into the DOM. It parses the provided DOM
 * element for image, video, and dataset placeholders, replacing them with connected components:
 *
 *     <div class="atomic-content" data-id="<uuid>">
 *
 * The Media array provides supplemental information on how to interpret these various template
 * injection points, using the data-id field.
 */

const ContentLoader: React.FunctionComponent<Props> = props => {
  const manager = useContentManagerActions({ type: 'MATHJAX' })

  useTypesetEffect(
    props.node,
    Priority.DEFAULT,
    () => manager.addError({ message: 'Could not typeset LaTex.', isRetryable: true }),
    manager.success,
    // don't re-typeset on priority change.
    [props.node, manager]
  )
  return <>{Array.from(processDOM(props.node, props.media ?? [], !!props.canAutoplay))}</>
}

/**
 * We process a DOM node, emitting Portals of media types to render in the DOM.
 *
 * (JC): I'm unsure how react reconciliation will work for createPortal. To help react make
 * a good decision, we're providing keys to our emitted Portals, but I suspect these
 * keys should instead be added to the portal themselves which is currently unsupported.
 */
function* processDOM(
  node: Element,
  media: GQL.AtomMedia[],
  canAutoplay: boolean
): IterableIterator<React.ReactPortal> {
  let compoundIsAutoplaying

  const elements = node.querySelectorAll('.atomic-content')
  let index = -1
  // using entries() resulted in some weird behavior in unit tests, so avoiding it.
  for (const element of elements) {
    index += 1
    const id = element.getAttribute('data-id')
    const mediaAtom = media.find(ele => ele.id === id)
    const key = `${index}-${id}`

    if (!mediaAtom) {
      // This isn't fatal, but signifies a bug somewhere in the stack or a problem
      // with the content.
      console.error('Could not find media atom', {
        dataId: id,
        internalIssueId: 'CE-3444 ',
      })
      continue
    }

    if (mediaAtom.dataType === 'IMAGE_LOCATION') {
      const customWidth = element.getAttribute('custom-width')
      yield ReactDOM.createPortal(
        <ContentManagerActions initialDetails={{ type: 'IMAGE', mediaAtomId: mediaAtom.id }}>
          {actions => (
            <ImageAtom
              atom={mediaAtom}
              customWidth={customWidth}
              key={key}
              contentManager={actions}
            />
          )}
        </ContentManagerActions>,
        element
      )
    } else if (mediaAtom.dataType === 'VIDEO_LOCATION') {
      // enable autoplay if the compound is not already autoplaying a video
      const autoplay = canAutoplay && !compoundIsAutoplaying

      if (autoplay) {
        compoundIsAutoplaying = true
      }
      yield ReactDOM.createPortal(
        <ContentManagerActions
          initialDetails={{
            type:
              (mediaAtom.data as GQL.AtomVideoData).provider === 'YOUTUBE' ? 'YOUTUBE' : 'VIMEO',
            mediaAtomId: mediaAtom.id,
          }}
        >
          {actions => (
            <VideoAtom autoplay={autoplay} atom={mediaAtom} key={key} contentManager={actions} />
          )}
        </ContentManagerActions>,
        element
      )
    } else if (mediaAtom.dataType === 'DATASET') {
      yield ReactDOM.createPortal(
        <ContentManagerActions initialDetails={{ type: 'DATASET', mediaAtomId: mediaAtom.id }}>
          {actions => (
            <DatasetAtom
              atom={mediaAtom as Content.DatasetMediaAtom}
              key={key}
              contentManager={actions}
            />
          )}
        </ContentManagerActions>,
        element
      )
    } else if (mediaAtom.dataType === 'DESMOS_GRAPH') {
      yield ReactDOM.createPortal(
        <ContentManagerActions initialDetails={{ type: 'DESMOS', mediaAtomId: mediaAtom.id }}>
          {actions => <DesmosAtom atom={mediaAtom} key={key} contentManager={actions} />}
        </ContentManagerActions>,
        element
      )
    } else if (mediaAtom.dataType === 'ALCHEMIE') {
      yield ReactDOM.createPortal(
        <ContentManagerActions initialDetails={{ type: 'ALCHEMIE', mediaAtomId: mediaAtom.id }}>
          {actions => <AlchemieVSEPRrAtom atom={mediaAtom} key={key} contentManager={actions} />}
        </ContentManagerActions>,
        element
      )
    } else if (mediaAtom.dataType === 'EXTERNAL_URL') {
      const heightString = element.getAttribute('data-height')
      const widthString = element.getAttribute('data-width')
      const height = heightString === 'None' ? null : heightString
      const width = widthString === 'None' ? null : widthString
      const toNewTab = element.getAttribute('data-to_new_tab') === 'True'
      const title = element.getAttribute('data-title')
      const url = element.getAttribute('data-href')

      if (toNewTab) {
        yield ReactDOM.createPortal(<NewTabUrlLauncher url={url} title={title} />, element)
      } else {
        yield ReactDOM.createPortal(
          <ContentManagerActions
            initialDetails={{ type: 'EXTERNAL_URL', mediaAtomId: mediaAtom.id }}
          >
            {actions => (
              <ExternalAtom
                atom={mediaAtom}
                key={key}
                height={height}
                width={width}
                contentManager={actions}
              />
            )}
          </ContentManagerActions>,
          element
        )
      }
    } else {
      console.error('Unknown media atom data type', {
        mediaAtom: mediaAtom,
        internalIssueId: 'CE-3443',
      })
    }
  }
}

/**
 * This component is expensive to re-render, thus we memoize it to avoid expensive re-renders.
 * It is up to the parent node to ensure the contents of the provided node and media array are
 * immutable -- as we will not update or re-render on changes to these containers.
 */
export default React.memo(ContentLoader)
