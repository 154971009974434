import * as React from 'react'
import { connect } from 'react-redux'
import * as classnames from 'classnames'
import { MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as CurrentUser from 'app/frontend/helpers/current-user'
import { ButtonMenu, ButtonPosition, IconMenu } from 'app/frontend/components/menu'
import { Icon } from 'app/frontend/components/material/icon'
import { FeedbackType } from 'app/helpers/common/content-types'
import { showContentFeedbackModal } from 'app/frontend/components/modals/content-feedback'
import { sendEvent } from 'app/frontend/helpers/mixpanel'
import { FEEDBACK_MENU_ITEM_CLICK, FEEDBACK_BUTTON_CLICK } from 'app/frontend/data/mixpanel-events'
import * as EmojiSprite from 'app/public/img/feedback/feedback-emoji-sprite.png'
import { getStaticUrl } from 'app/frontend/helpers/assets'
import { Heading } from 'app/frontend/components/material/heading'

const t = tns('feedback')
const styles = require('./feedback-menu.css')

export type FeedBackModalOptions = {
  learn: boolean
  isQuiz?: boolean
  sequenceId: string
  compoundInstance?: Commons.ICompoundInstance
  enrollment?: Commons.IEnrollment
  retailClass?: Commons.IRetailClassLean
  atom: Content.IAtom
}

export interface FeedbackMenuOwnProps {
  learn: boolean
  atom: Content.IAtom
  compoundInstance?: Commons.ICompoundInstance
  isQuiz: boolean
  enrollment?: Commons.IEnrollment
  retailClass?: Commons.IRetailClassLean
  sequenceId?: string
  questionsCount?: number
  position?: ButtonPosition
  buttonMenuClassName?: string
  location?: string // Location name for mixpanel event
  /**
   * Set if we want the feedback menu button to automatically
   * change its form based on screensize or location or lock it to
   * icon/button form.
   */
  responsiveMode?: 'icon-only' | 'button-only' | 'auto'
}

interface FeedbackMenuDispatchProps {
  showFeedbackModal: (action: FeedbackType, options: FeedBackModalOptions) => void
}

export type Props = FeedbackMenuOwnProps & FeedbackMenuDispatchProps

export const FEEDBACK_ITEMS = [
  FeedbackType.NotRight,
  FeedbackType.LooksBroken,
  FeedbackType.CannotAnswer,
  FeedbackType.HaveIdea,
]

const useMenuItemStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'normal',
  },
  emoji: {
    backgroundImage: `url(${getStaticUrl(EmojiSprite)})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginRight: '0.6rem',
    width: '1.5rem',
    height: '1.5rem',
  },
  not_right: {
    backgroundPosition: '0 0',
  },
  looks_broken: {
    backgroundPosition: '-1.5rem 0',
  },
  cannot_answer: {
    backgroundPosition: '-3rem 0',
  },
  have_idea: {
    backgroundPosition: '-4.5rem 0',
  },
  text: {
    marginTop: '0.3rem',
    flex: 1,
  },
  headText: {
    color: theme.palette?.grey[600],
    fontFamily: theme.typography?.fontFamily,
    fontWeight: theme.typography?.fontWeightMedium,
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  subText: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    marginTop: '0.25rem',
    marginBottom: '0',
    paddingBottom: '0',
  },
}))

const useMenuStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette?.common.white,
  },
  list: {
    maxWidth: '18rem',
  },
}))

export const FeedbackMenu: React.FunctionComponent<Props> = ({
  learn,
  isQuiz,
  compoundInstance,
  sequenceId,
  enrollment,
  retailClass,
  showFeedbackModal,
  questionsCount,
  atom,
  position,
  buttonMenuClassName,
  location,
  responsiveMode = 'auto',
}) => {
  const options = {
    learn,
    isQuiz,
    sequenceId: compoundInstance ? compoundInstance.parentSequenceId : sequenceId,
    compoundInstance,
    enrollment,
    retailClass,
    questionsCount,
    atom,
  }

  const menuItemStyles = useMenuItemStyles()
  const menuStyles = useMenuStyles()

  // never show the feedback menu for custom items
  if (!atom || atom.isCustomAssessmentItem) {
    return null
  }

  const menuItems = FEEDBACK_ITEMS.map(label => {
    return (
      <MenuItem
        data-html2canvas-ignore
        data-test={`feedback-menu-button-${label}`}
        key={label}
        classes={{ root: menuItemStyles.root }}
        onClick={() => {
          showFeedbackModal(label, options)
          if (location) {
            sendEvent(FEEDBACK_MENU_ITEM_CLICK, { location, label })
          }
        }}
      >
        <>
          <span
            className={classnames(menuItemStyles.emoji, menuItemStyles[label])}
            aria-hidden="true"
          />
          <div className={menuItemStyles.text}>
            <Heading tag="h3" margin="none" className={menuItemStyles.headText}>
              {t(label)}
            </Heading>
            <p className={menuItemStyles.subText}>{t(`${label}_sub`)}</p>
          </div>
        </>
      </MenuItem>
    )
  })

  // The overflow icon button should render on all teach pages
  // as well as for mobile screen sizes.
  // To make the menu tab navigatable, we had to duplicate the code here.
  return (
    <>
      {(responsiveMode === 'icon-only' || responsiveMode === 'auto') && (
        <div
          className={classnames(
            responsiveMode === 'auto' && styles.overflowIconDisplay,
            'feedbackMenu'
          )}
          data-html2canvas-ignore
          data-test="feedback-menu-icon"
        >
          <IconMenu
            dataBi="feedback-menu"
            icon={<Icon name="icon-dots" size="medlarge" />}
            position={position || 'auto'}
            iconAriaLabel={t('content_feedback_button_aria')}
            isDarkIconTheme={true}
            shouldCloseOnClick={true}
            menuTheme={menuStyles}
          >
            {menuItems}
          </IconMenu>
        </div>
      )}

      {(responsiveMode === 'button-only' || responsiveMode === 'auto') && (
        <ButtonMenu
          dataBi="feedback-button-menu"
          icon={<Icon name="icon-feedback" size="medium" className={styles.feedbackIcon} />}
          label={t('content_feedback')}
          position={position || 'bottomRight'}
          menuTheme={menuStyles}
          buttonClassName={classnames(
            buttonMenuClassName,
            responsiveMode === 'auto' && styles.desktopButtonDisplay,
            'feedbackMenu'
          )}
          shouldCloseOnClick={true}
          onClick={() => {
            if (location) {
              sendEvent(FEEDBACK_BUTTON_CLICK, { location })
            }
          }}
          data-html2canvas-ignore
        >
          {menuItems}
        </ButtonMenu>
      )}
    </>
  )
}

FeedbackMenu.displayName = 'FeedbackMenu'

const mapDispatchToProps = (dispatch): FeedbackMenuDispatchProps => {
  return {
    showFeedbackModal: (action: FeedbackType, options: FeedBackModalOptions): void => {
      dispatch(
        showContentFeedbackModal({
          category: action,
          user: CurrentUser.get(),
          atom: options.atom,
          retailClass: options.retailClass,
          enrollment: options.enrollment,
          compoundInstance: options.compoundInstance,
          isQuiz: !!options.isQuiz,
        })
      )
    },
  }
}

export default connect<{}, FeedbackMenuDispatchProps, FeedbackMenuOwnProps>(
  null,
  mapDispatchToProps
)(FeedbackMenu)
