import { combineReducers } from 'redux'
import assignmentUIReducer, { AssignmentUIState } from './assignment-ui-reducer'
import studentAssignmentUIReducer, {
  StudentAssignmentUIState,
} from './student-assignment-ui-reducer'

export interface CourseworkUIState {
  assignment: AssignmentUIState
  studentAssignment: StudentAssignmentUIState
}

export default combineReducers<CourseworkUIState>({
  assignment: assignmentUIReducer,
  studentAssignment: studentAssignmentUIReducer,
})
