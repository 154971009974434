import { PAYMENTS_MODAL_DISPLAY_STATE } from './reducer'
import { makeApiActions } from 'app/frontend/api/api-redux'
import SubscriptionCourseDetails = Payments.SubscriptionCourseDetails

export type SET_PAYMENT_MODAL_DISPLAY_STATE = 'SET_PAYMENT_MODAL_DISPLAY_STATE'
export const SET_PAYMENT_MODAL_DISPLAY_STATE: SET_PAYMENT_MODAL_DISPLAY_STATE =
  'SET_PAYMENT_MODAL_DISPLAY_STATE'
export type SetPaymentModalDisplayStateAction = {
  type: SET_PAYMENT_MODAL_DISPLAY_STATE
  state: PAYMENTS_MODAL_DISPLAY_STATE
}

export const setPaymentsModalDisplayState = (state: PAYMENTS_MODAL_DISPLAY_STATE) => ({
  type: SET_PAYMENT_MODAL_DISPLAY_STATE,
  state,
})

export type START_COURTESY_ACCESS = 'START_COURTESY_ACCESS'
export const START_COURTESY_ACCESS: START_COURTESY_ACCESS = 'START_COURTESY_ACCESS'
export const API_START_COURTESY_ACCESS = makeApiActions(START_COURTESY_ACCESS)
export type StartCourtesyAccessAction = {
  type: START_COURTESY_ACCESS
  coursepackId: string
  courseDetails: SubscriptionCourseDetails
}

export const startCourtesyAccess = (
  coursepackId: string,
  courseDetails: SubscriptionCourseDetails
): StartCourtesyAccessAction => ({
  type: START_COURTESY_ACCESS,
  coursepackId,
  courseDetails,
})

export type PAYMENT_MODAL_VERIFY_PAID = 'PAYMENT_MODAL_VERIFY_PAID'
export const PAYMENT_MODAL_VERIFY_PAID: PAYMENT_MODAL_VERIFY_PAID = 'PAYMENT_MODAL_VERIFY_PAID'
export const API_VERIFY_PAID = makeApiActions(PAYMENT_MODAL_VERIFY_PAID)

export type PaymentModalVerifyPaid = {
  type: PAYMENT_MODAL_VERIFY_PAID
  hostedPageId: string
  course: Commons.IRetailClass
}

export const paymentModalVerifyPaid = (
  hostedPageId: string,
  course: Commons.IRetailClass
): PaymentModalVerifyPaid => {
  return {
    type: PAYMENT_MODAL_VERIFY_PAID,
    hostedPageId,
    course,
  }
}

export type PAYMENT_MODAL_REFRESH_SUBSCRIPTIONS = 'PAYMENT_MODAL_REFRESH_SUBSCRIPTIONS'
export const PAYMENT_MODAL_REFRESH_SUBSCRIPTIONS: PAYMENT_MODAL_REFRESH_SUBSCRIPTIONS =
  'PAYMENT_MODAL_REFRESH_SUBSCRIPTIONS'
export type PaymentModalRefreshSubscriptions = {
  type: PAYMENT_MODAL_REFRESH_SUBSCRIPTIONS
}

export const paymentModalRefreshSubscriptions = (): PaymentModalRefreshSubscriptions => {
  return {
    type: PAYMENT_MODAL_REFRESH_SUBSCRIPTIONS,
  }
}

export type PAYMENT_MODAL_ACCESS_CODE_SUBMITTED = 'PAYMENT_MODAL_ACCESS_CODE_SUBMITTED'
export const PAYMENT_MODAL_ACCESS_CODE_SUBMITTED: PAYMENT_MODAL_ACCESS_CODE_SUBMITTED =
  'PAYMENT_MODAL_ACCESS_CODE_SUBMITTED'
export const API_PAYMENT_SUBMIT_ACCESS_CODE = makeApiActions(PAYMENT_MODAL_ACCESS_CODE_SUBMITTED)

export type PaymentModalAccessCodeSubmitted = {
  type: PAYMENT_MODAL_ACCESS_CODE_SUBMITTED
  accessCode: string
  coursepackId: string
  courseDetails: SubscriptionCourseDetails
}

export const paymentModalAccessCodeSubmitted = (
  accessCode: string,
  coursepackId: string,
  courseDetails: SubscriptionCourseDetails
): PaymentModalAccessCodeSubmitted => {
  return {
    type: PAYMENT_MODAL_ACCESS_CODE_SUBMITTED,
    accessCode,
    coursepackId,
    courseDetails,
  }
}

export type PAYMENT_MODAL_BILLING_PORTAL_OPENED = 'PAYMENT_MODAL_BILLING_PORTAL_OPENED'
export const PAYMENT_MODAL_BILLING_PORTAL_OPENED: PAYMENT_MODAL_BILLING_PORTAL_OPENED =
  'PAYMENT_MODAL_BILLING_PORTAL_OPENED'

export type PaymentModalBillingPortalOpened = {
  type: PAYMENT_MODAL_BILLING_PORTAL_OPENED
}

export const paymentBillingPortalOpen = (): PaymentModalBillingPortalOpened => {
  return {
    type: PAYMENT_MODAL_BILLING_PORTAL_OPENED,
  }
}

export type SHOW_INVALID_BILLING_SNACKBAR = 'SHOW_INVALID_BILLING_SNACKBAR'
export const SHOW_INVALID_BILLING_SNACKBAR: SHOW_INVALID_BILLING_SNACKBAR =
  'SHOW_INVALID_BILLING_SNACKBAR'

export type ShowInvalidBillingSnackbar = {
  type: SHOW_INVALID_BILLING_SNACKBAR
}

export const showInvalidBillingSnackbar = (): ShowInvalidBillingSnackbar => {
  return {
    type: SHOW_INVALID_BILLING_SNACKBAR,
  }
}

export type PAYMENT_INIT_SITE_NAME = 'PAYMENT_INIT_SITE_NAME'
export const PAYMENT_INIT_SITE_NAME: PAYMENT_INIT_SITE_NAME = 'PAYMENT_INIT_SITE_NAME'
export const API_PAYMENT_INIT_SITE_NAME = makeApiActions(PAYMENT_INIT_SITE_NAME)

type InitSiteArgs = {
  openBillingPortal: boolean
}

export type PaymentInitSiteName = {
  type: PAYMENT_INIT_SITE_NAME
  args: InitSiteArgs
}

export const paymentInitSiteName = (args = { openBillingPortal: false }): PaymentInitSiteName => {
  return {
    type: PAYMENT_INIT_SITE_NAME,
    args,
  }
}
