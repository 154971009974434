import * as React from 'react'
import { useCourse } from 'app/frontend/compositions/connected/get-course'
import { useRawAssignment } from 'app/frontend/pages/material/learn/compositions/connected/get-raw-assignment'
import { useSection } from 'app/frontend/compositions/connected/get-section'
import { mapLoadingAndErrorToProps } from 'app/frontend/helpers/apollo/adopt'
import { updatePendo } from 'app/frontend/layout/material/pendo/pendo-helpers'

interface Props {
  pathId?: string
  sectionId?: string
  courseId?: string
}

export const PendoContextInner: React.FunctionComponent<Props> = ({
  pathId,
  courseId,
  sectionId,
  children,
}) => {
  const assignmentData = useRawAssignment(pathId, { skip: !pathId })
  const sectionData = useSection(sectionId ?? assignmentData.assignment?.sectionId, {
    skip: !(sectionId ?? assignmentData.assignment?.sectionId),
  })
  const courseData = useCourse(
    courseId ?? assignmentData.assignment?.courseId ?? sectionData.section?.courseId,
    {
      skip: !(courseId ?? assignmentData.assignment?.courseId ?? sectionData.section?.courseId),
    }
  )

  const {
    courseData: { course },
    assignmentData: { assignment },
    loading,
    error,
  } = mapLoadingAndErrorToProps({
    assignmentData,
    sectionData,
    courseData,
  })

  if (!loading && !error) {
    // Once everything is loaded, update the Pendo visitor information
    updatePendo(course)
  }

  // Return an element with some extra attributes that Pendo will capture when users interact
  // with the page. Pendo will capture anything starting with "data-".
  return (
    <div
      data-course-id={course?.id}
      data-lms-type={course?.lmsType}
      data-billing={course?.billingModel}
      data-path-type={assignment?.type}
      data-path-label={assignment?.label}
    >
      {children}
    </div>
  )
}
