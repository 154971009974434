import * as React from 'react'
import * as classnames from 'classnames'
import * as styles from './page-padding.css'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  className?: string
  fullWidth?: boolean
}

/**
 * Adds 1rem horizontal padding when viewport < 960px
 */
export const PagePadding: React.FunctionComponent<Props> = ({
  children,
  className,
  fullWidth,
  ...otherProps
}) => (
  <div
    className={classnames(styles.wrapper, className, {
      [styles.fullWidth]: fullWidth,
    })}
    {...otherProps}
  >
    {children}
  </div>
)

PagePadding.displayName = 'PagePadding'
