import { combineReducers } from 'redux'
import historyReducer, { ILearnFlowHistoryReducer } from './history-reducer'
import modeReducer, { ModeReducerState } from './mode-reducer'
import loadingReducer, { LearnFlowLoadingState } from './loading-reducer'
import sequencePreviewReducer, { SequencePreviewReducerState } from './sequence-preview-reducer'
import { displayAnalyticsReducer } from './display-analytics-reducer'

export type LearnFlowUIState = {
  history: ILearnFlowHistoryReducer
  mode: ModeReducerState
  loading: LearnFlowLoadingState
  sequencePreview: SequencePreviewReducerState
  displayAnalytics: boolean
}

export default combineReducers({
  history: historyReducer,
  mode: modeReducer,
  loading: loadingReducer,
  sequencePreview: sequencePreviewReducer,
  displayAnalytics: displayAnalyticsReducer,
})
