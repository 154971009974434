import {
  SetQuestionViewPreferencesAction,
  SetQuestionDisplayDensityAction,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/question-view-preferences-action'
import {
  QuestionViewPreferencesStatus,
  QuestionViewPreferencesOptionsViewDensity,
  QuestionViewPreferencesOptionsView,
} from './helper'
import { QuestionViewPreferencesAction } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/question-view-preferences-action'

export interface QuestionViewPreferencesState {
  viewBy: QuestionViewPreferencesOptionsView
  displayDensity: QuestionViewPreferencesOptionsViewDensity
  status: QuestionViewPreferencesStatus
  customDisplayDensity: Map<string, QuestionViewPreferencesOptionsViewDensity>
}

type RelevantActionType = SetQuestionViewPreferencesAction | SetQuestionDisplayDensityAction

const defaultState = {
  viewBy: QuestionViewPreferencesOptionsView.BY_OBJECTIVE,
  displayDensity: QuestionViewPreferencesOptionsViewDensity.EXPANDED,
  status: QuestionViewPreferencesStatus.NOT_APPLIED,
  customDisplayDensity: new Map(),
}

/**
 * Reducer for keeping track of question preference state in assessment builder
 * @param state - State that holds question preferences information (type/numQuestionPerLO)
 * @param action - Action that required to call for update question preferences state
 */
export function QuestionViewPreferencesReducer(
  state: QuestionViewPreferencesState = defaultState,
  action: RelevantActionType
): QuestionViewPreferencesState {
  switch (action.type) {
    case QuestionViewPreferencesAction.SetQuestionViewPreferences:
      return {
        viewBy: action.viewBy,
        displayDensity: action.displayDensity,
        status: QuestionViewPreferencesStatus.APPLIED,
        customDisplayDensity: new Map(),
      }
    case QuestionViewPreferencesAction.SetQuestionDisplayDensity:
      const customDisplayDensityState = new Map(state.customDisplayDensity)
      customDisplayDensityState.set(action.questionId, action.displayDensity)
      return {
        ...state,
        customDisplayDensity: customDisplayDensityState,
      }
  }

  return state
}
