import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import MaterialSnackbar from 'accessible-react-toolbox/lib/snackbar'
import { SEARCH_TASK_STATUSES_QUERY } from 'app/frontend/pages/material/teach/compositions/connected/search-task-statuses'
import { hideGoalUpdateSnackbarAction } from 'app/frontend/components/material/goal-update-snackbar/goal-update-snackbar-actions'
import {
  getGoalUpdateSnackbarState,
  GoalUpdateSnackbarReducerState,
} from 'app/frontend/components/material/goal-update-snackbar/goal-update-snackbar-reducer'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as LogReporter from 'app/frontend/helpers/log-reporter'
import * as styles from './goal-update-snackbar.css'

const t = tns('teach:goal_update_snackbar')

const POLL_INTERVAL_MS = 5000

const snackbarTheme = {
  snackbar: styles.snackbar,
  label: styles.label,
  button: styles.button,
  active: styles.active,
}

export enum GoalUpdateState {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export const GoalUpdateSnackbar: React.FunctionComponent = () => {
  const [active, setActive] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [updateGoalState, setUpdateGoalState] = useState(GoalUpdateState.IN_PROGRESS)
  const [pendingTasks, setPendingTasks] = useState(0)
  const client = useApolloClient()

  const {
    userId,
    updatedAfter,
    lastShownAt,
    courseId,
  } = useSelector((state: GoalUpdateSnackbarReducerState) => getGoalUpdateSnackbarState(state))

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (updateGoalState === GoalUpdateState.IN_PROGRESS) {
      setActive(true)
      const intervalId = setInterval(getPendingAsyncGoalUpdateTasks, POLL_INTERVAL_MS)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [lastShownAt, updateGoalState, pendingTasks])

  const hide = () => {
    setActive(false)
    setTimeout(() => {
      dispatch(hideGoalUpdateSnackbarAction())
    }, 750)
  }

  const onClick = () => {
    if (!clicked) {
      setClicked(true)
      hide()
      window.location.reload()
    }
  }

  const createSearchRequest = (
    initiatorId: string,
    count: number,
    taskStatus?: GQL.TaskStatusStatus,
    taskCreatedAfter?: number
  ): GQL.TaskStatusSearchInput => {
    const filters = {
      type: 'GOAL_SYNC' as GQL.TaskType,
      initiatorId,
      status: taskStatus,
      createdAfter: taskCreatedAfter,
      courseId,
    }
    return {
      filters: filters,
      pagination: {
        count: count,
      },
    }
  }

  const getTaskStatusResult = async (searchTaskRequest: GQL.TaskStatusSearchInput) => {
    const searchTaskStatusesResult = (await client.query({
      query: SEARCH_TASK_STATUSES_QUERY,
      variables: { request: searchTaskRequest },
      fetchPolicy: 'network-only',
    })) as ApolloQueryResult<GQL.SearchTaskStatuses.Query>

    if (searchTaskStatusesResult.errors) {
      LogReporter.error(t('error_searching'), searchTaskStatusesResult.errors)
      return 0
    }
    return searchTaskStatusesResult.data.searchForTaskStatuses.results.length
  }

  const getPendingAsyncGoalUpdateTasks = async () => {
    const pendingTaskRequest = createSearchRequest(
      userId,
      100,
      'PENDING' as GQL.TaskStatusStatus,
      updatedAfter
    )

    const pendingTasksCount = await getTaskStatusResult(pendingTaskRequest)
    if (pendingTasksCount > 0) {
      setPendingTasks(pendingTasksCount)
    } else {
      const inprogressTaskRequest = createSearchRequest(
        userId,
        100,
        'IN_PROGRESS' as GQL.TaskStatusStatus,
        updatedAfter
      )
      const inprogressTasksCount = await getTaskStatusResult(inprogressTaskRequest)
      if (inprogressTasksCount > 0) {
        setPendingTasks(inprogressTasksCount)
      } else {
        const failedTaskRequest = createSearchRequest(
          userId,
          100,
          'FAILED' as GQL.TaskStatusStatus,
          updatedAfter
        )
        const failedTasksCount = await getTaskStatusResult(failedTaskRequest)
        setUpdateGoalState(failedTasksCount > 0 ? GoalUpdateState.FAILED : GoalUpdateState.DONE)
        setPendingTasks(failedTasksCount)
      }
    }
  }

  const getPendingTasksMessage = (pendingFailedTasks: number): JSX.Element => {
    const labelForPendingTask = t('goal_update_failed')
    const pendingTasksText = t('pending_goal_update_tasks', {
      pendingTasks: pendingFailedTasks < 100 ? `${pendingFailedTasks}` : '100+',
    })
    return (
      <>
        <div className={styles.label}>{labelForPendingTask}</div>
        <div className={styles.progressText}>{pendingTasksText}</div>
      </>
    )
  }

  const label =
    updateGoalState === GoalUpdateState.IN_PROGRESS
      ? t('updating_goals')
      : updateGoalState === GoalUpdateState.DONE
      ? t('goal_updated')
      : getPendingTasksMessage(pendingTasks)

  const action = updateGoalState === GoalUpdateState.DONE ? t('refresh') : null
  return (
    <MaterialSnackbar
      label={label}
      action={action}
      onClick={onClick}
      active={active}
      timeout={null}
      theme={snackbarTheme}
    />
  )
}
