import * as React from 'react'
import { useDispatch } from 'react-redux'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Icon } from 'app/frontend/components/material/icon'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import * as DELETE_ASSESSMENT_SEQUENCE_VARIATION from 'app/frontend/pages/material/teach/compositions/connected/delete-assessment-sequence-variation/delete-assessment-sequence-variation.gql'
import * as styles from './remove-assessment-sequence.css'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import {
  TEACH_COURSE_ASSESSMENT_REMOVE_QUESTION,
  TEACH_SECTION_ASSESSMENT_REMOVE_QUESTION,
} from 'app/frontend/data/mixpanel-events'
import { ParentEntityType } from 'app/typings/commons'
import { useMutation } from '@apollo/client'

const t = tns('teach:remove_assessment_sequence')

export interface Props {
  assessmentId: string
  sequenceId: string
  pathSequenceVariationId: string
  parentEntityType?: Commons.ParentEntityType
  onRemove?: () => void
  onRemoveComplete?: () => void
  onRemoveError?: () => void
}

export const RemoveAssessmentSequence: React.FunctionComponent<Props> = ({
  assessmentId,
  sequenceId,
  pathSequenceVariationId,
  parentEntityType,
  onRemove,
  onRemoveComplete,
  onRemoveError,
}) => {
  const dispatch = useDispatch()
  const showMessage = (msg: string, iconName: string) =>
    dispatch(showSnackbar({ message: msg, iconName }))

  const [deleteAssessmentSequence, { loading }] = useMutation(
    DELETE_ASSESSMENT_SEQUENCE_VARIATION,
    {
      context: { silenceErrors: true },
      onCompleted: () => {
        onRemoveComplete()
        showMessage(t('question_removed'), 'icon-progress-circle-complete')
      },
      onError: onRemoveError,
    }
  )

  const onClick = async () => {
    onRemove()
    // send remove assessment question mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_REMOVE_QUESTION
        : TEACH_SECTION_ASSESSMENT_REMOVE_QUESTION,
      assessmentId
    )

    await deleteAssessmentSequence({
      variables: {
        assessmentId,
        sequenceId,
        pathSequenceVariationId,
      },
    })
  }

  return (
    <ButtonMaterial
      icon={<Icon name="icon-delete" className={styles.removeIcon} />}
      label={t('remove_label')}
      theme="bordered"
      disabled={loading}
      onClick={onClick}
      data-bi="remove-btn"
    />
  )
}
