import { Action } from 'redux'
import {
  AnalyticsCategory,
  CompletionType,
  AllCourseworkSortColumns,
  IAnalyticsSortOrder,
} from 'app/frontend/data/analytics-data'

export enum AllCourseworkActions {
  SET_COURSEWORK_TYPE_FILTER = 'SET_COURSEWORK_TYPE_FILTER',
  SET_ASSIGNMENT_COMPLETION_TYPE = 'SET_ASSIGNMENT_COMPLETION_TYPE',
  SORT_COURSEWORK_ANALYTICS = 'SORT_COURSEWORK_ANALYTICS',
}

export type SetCourseworkTypeFilter = {
  courseworkTypeFilter: AnalyticsCategory
} & Action
export function setCourseworkTypeFilter(
  courseworkTypeFilter: AnalyticsCategory
): SetCourseworkTypeFilter {
  return {
    type: AllCourseworkActions.SET_COURSEWORK_TYPE_FILTER,
    courseworkTypeFilter,
  }
}

export type setAssignmentCompletionFilter = { completionFilter: CompletionType } & Action
export function setAssignmentCompletionFilter(
  completionFilter: CompletionType
): setAssignmentCompletionFilter {
  return {
    type: AllCourseworkActions.SET_ASSIGNMENT_COMPLETION_TYPE,
    completionFilter,
  }
}

export type SortCourseworkAnalytics = {
  type: AllCourseworkActions.SORT_COURSEWORK_ANALYTICS
  sortOrder: IAnalyticsSortOrder
  sortColumn: AllCourseworkSortColumns
}
export function sortCourseworkAnalytics(
  sortOrder: IAnalyticsSortOrder,
  sortColumn: AllCourseworkSortColumns
): SortCourseworkAnalytics {
  return {
    type: AllCourseworkActions.SORT_COURSEWORK_ANALYTICS,
    sortOrder,
    sortColumn,
  }
}
