import * as React from 'react'

// This component adds event listeners to the window to determine
// what input the user is currently using. A data tag is added
// to the root element which is used in component css to add
// or remove the focus outline to help navigte when using the
// keyboard.
export class WhatInput extends React.Component<{}, {}> {
  constructor(props) {
    super(props)
  }

  updateTimer: number
  recentInput: 'mouse' | 'keyboard'

  // this list of events comes from the what-input library
  listeners = ['pointerdown', 'MSPointerDown', 'touchStart', 'mousedown', 'keydown']

  mouseListener = (): void => {
    this.recentInput = 'mouse'
  }

  keyboardListener = (): void => {
    this.recentInput = 'keyboard'
  }

  componentDidMount() {
    for (const listener of this.listeners) {
      if (listener === 'keydown') {
        window.addEventListener(listener, this.keyboardListener)
      } else {
        window.addEventListener(listener, this.mouseListener)
      }
    }

    this.updateTimer = window.setInterval(() => {
      document.documentElement.setAttribute('data-whatintent', this.recentInput || 'mouse')
    }, 2000)
  }

  componentWillUnmount() {
    if (this.updateTimer) {
      window.clearInterval(this.updateTimer)
    }

    for (const listener of this.listeners) {
      if (listener === 'keydown') {
        window.removeEventListener(listener, this.keyboardListener)
      } else {
        window.removeEventListener(listener, this.mouseListener)
      }
    }
  }

  render(): JSX.Element {
    return <>{this.props.children}</>
  }
}

export default WhatInput
