/**
 * The library react-data-grid currently does not support SSR, it claims to fix the issue
 * at its next release. We temporarily handle this issue by checking the global environment ,
 * and we will remove the workaround once the library is updated.
 */

import * as React from 'react'

interface IReactDataGridProps {
  columns: any[]
  rowsCount: number
  rowGetter: (i: any) => void
  headerRowHeight: number
  minHeight: number
  ref: React.Ref<Element>
  enableCellAutoFocus: boolean
}

let ReactDataGrid

if ((global as any).self !== undefined) {
  ReactDataGrid = require('react-data-grid')
} else {
  // we cannot attach ref to a stateless component so it has to be a class
  ReactDataGrid = class extends React.Component<IReactDataGridProps> {
    render = () => <div />
  }
}

export default ReactDataGrid
