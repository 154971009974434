import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  customMenu: {
    marginLeft: '1.5rem',
    marginTop: '0.5rem',
    boxShadows: '0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.24), 0rem 0rem 0.125rem rgba(0, 0, 0, 0.12)',
    borderRadius: '0.25rem',
  },
}))
