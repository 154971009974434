import * as React from 'react'
import * as _ from 'lodash'
import * as titlecase from 'titlecase'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { LicenseInfo, LICENSE_DATA } from 'app/frontend/data/license-data'
import * as styles from './atom-attribution.css'

const t = tns('content_attribution')

/**
 * We have a bunch of old content with old sourceUrls from our beta days.
 */
const urlTranslations = { 'beta.knewton.com': 'https://www.knewton.com' }

export const SourceName: React.FunctionComponent<{ name: string; url?: string }> = ({
  name,
  url,
}) => {
  return isUrlOkForLinking(url) ? (
    <a
      href={urlTranslations[url] || url}
      target="_blank"
      data-bi="source-name-link"
      className={styles.link}
    >
      {name}
    </a>
  ) : (
    <span>{name}</span>
  )
}

export const ByAuthor: React.FunctionComponent<{ name?: string }> = ({ name }) => {
  return <>{t('by', { authorName: name ? titlecase(name) : 'Knewton' })}</>
}

export const OpenStaxDownloadLinkOrNot: React.FunctionComponent<{
  author: string
  sourceUrl?: string
}> = ({ author, sourceUrl }) => {
  if (isUrlOkForLinking(sourceUrl) && author && author.match(/open\s?stax/i)) {
    return (
      <>
        {t('download')}{' '}
        <a href={sourceUrl} target="_blank" data-bi="openstax-download-link">
          here
        </a>
      </>
    )
  }
  return null
}

/**
 * A simple hook that transforms a license string to a LicenseInfo object that contains
 * supplemental license information. Will try to apply smart default if license is null or
 * undefined.
 */
export const useLicenseInfoOrDefault = (license: string | undefined | null): LicenseInfo => {
  const memoizedLicense = React.useMemo<LicenseInfo>(() => {
    if (!license) {
      return LICENSE_DATA['THIRD_PARTY']
    } else {
      return (
        LICENSE_DATA[license] || {
          text: license,
        }
      )
    }
  }, [license])

  return memoizedLicense
}

export const LicensedUnder: React.FunctionComponent<{
  license: LicenseInfo
}> = ({ license }) => {
  return (
    <>
      {t('license')}{' '}
      {isUrlOkForLinking(license.url) ? (
        <a href={license.url} target="_blank" data-bi="license-link" className={styles.link}>
          {license.text}
        </a>
      ) : (
        license.text
      )}
    </>
  )
}

/**
 * Returns True if the url is valid and we're not currently in the lock down browser
 * @param url the url to examine
 */
const isUrlOkForLinking = (url: string | null | undefined) => {
  return url && url.match(/^(http|\/)/i) && !window.context.secureBrowser
}
