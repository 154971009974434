import * as React from 'react'
import Slider from 'rc-slider'

import * as styles from './video-slider.css'

interface IVideoSliderProps {
  className?: string
  min?: number
  max?: number
  defaultValue?: number
  tabIndex?: number
  vertical?: boolean
  value?: number
  onChange?: (value: number) => void
  onFocus?: (value: number) => void
  onBlur?: (value: number) => void
  onSlide?: (event: any, value: number) => void
}

interface IVideoSliderState {
  value: number
}

export default class VideoSlider extends React.Component<IVideoSliderProps, IVideoSliderState> {
  public static defaultProps = {
    min: 0,
    max: 100,
    defaultValue: 0,
    tabIndex: 0,
    vertical: false,
    onFocus: () => null,
    onBlur: () => null,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
  }

  /**
   * Called when the value of the slider has changed.
   */
  onValueChanged(value) {
    const { onChange, onSlide } = this.props
    if (onChange) {
      onChange(value)
    }
    if (onSlide) {
      onSlide(null, value)
    }
    this.setState({ value })
  }

  /**
   * Gets the value(s) of the slider.
   */
  getValue() {
    return this.state.value
  }

  /**
   * Set the value(s) of the slider
   */
  setValue(value) {
    this.setState({ value })
  }

  /**
   * Returns the className for the root element.
   */
  rootClassName() {
    const ret = [styles.videoSlider]
    if (this.props.className) {
      ret.push(this.props.className)
    }
    return ret.join(' ')
  }

  /**
   * Renders the component.
   */
  render() {
    return (
      <Slider
        className={this.rootClassName()}
        vertical={this.props.vertical}
        min={this.props.min}
        max={this.props.max}
        aria-valuemin={this.props.min}
        aria-valuemax={this.props.max}
        aria-valuenow={this.getValue()}
        defaultValue={this.props.defaultValue}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        tabIndex={this.props.tabIndex}
        value={this.state.value}
        onChange={this.onValueChanged.bind(this)}
      />
    )
  }
}
