import { Reducer } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import { MODES, UPDATE_MODE, UpdateModeAction } from './mode-actions'
import { API_GET_SEQUENCE_PREVIEW } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export type ModeReducerState = MODES
type ModeAction = ApiSuccessAction | UpdateModeAction

export const getMode = (state: LearnControllerState): MODES => {
  return state.ui.learnFlow.mode
}

export const reducer = (
  state: ModeReducerState = 'active',
  action: ModeAction
): ModeReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case UPDATE_MODE:
      return (action as UpdateModeAction).mode
    case API_GET_SEQUENCE_PREVIEW.SUCCESS:
      return 'custom'
    default:
      return state
  }
}

export default reducer as Reducer<ModeReducerState>
