import * as React from 'react'
import {
  ByAuthor,
  SourceName,
  LicensedUnder,
  OpenStaxDownloadLinkOrNot,
  useLicenseInfoOrDefault,
} from './components'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('content_attribution')

interface Props {
  atom: GQL.Atom | Content.IAtom
}

const AtomAttribution: React.FunctionComponent<Props> = ({ atom }) => {
  if (!atom.attribution) {
    return null // just abort
  }

  const licenseInfo = useLicenseInfoOrDefault(atom.attribution.license)
  if (licenseInfo.customAttributionComponent) {
    return <licenseInfo.customAttributionComponent atom={atom} />
  }

  return (
    <>
      <SourceName name={atom.name || t('content')} url={atom.attribution.sourceUrl} />{' '}
      <ByAuthor name={atom.attribution.author} /> <LicensedUnder license={licenseInfo} />
      {'. '}
      <OpenStaxDownloadLinkOrNot
        author={atom.attribution.author}
        sourceUrl={atom.attribution.sourceUrl}
      />
    </>
  )
}

export default AtomAttribution
