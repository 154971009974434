import { dataURItoBlob } from 'app/frontend/helpers/screenshot'

export type uploadScreenshotResult = {
  url: string
}
export type uploadScreenshotType = (
  screenshot: string,
  preSignedData: GQL.ScreenshotUrl
) => Promise<uploadScreenshotResult>

export const sendScreenshotToS3 = async (formData: FormData, preSignedData: GQL.ScreenshotUrl) => {
  // Pulled this out to make it easy to mock.
  await fetch(preSignedData.url, {
    method: 'POST',
    body: formData,
    mode: 'no-cors',
  })
}

export const uploadScreenshot: uploadScreenshotType = async (screenshot, preSignedData) => {
  // We're using no-cors mode on upload which means we won't get status from fetch() result.
  // this means we might not get some errors.
  const formData = new FormData()
  const blob = dataURItoBlob(screenshot)
  const presignedfields = ['key', 'policy', 'signature', 'AWSAccessKeyId', 'xAmzSecurityToken']
  const filename = 'screenshot.png'

  formData.append('Content-Type', 'image/png')
  presignedfields.forEach(k => {
    if (preSignedData.fields[k]) {
      formData.append(k, preSignedData.fields[k])
    }
  })
  formData.append('file', blob, filename)

  await sendScreenshotToS3(formData, preSignedData)

  return { url: preSignedData.fields.key.replace('${filename}', filename) }
}
