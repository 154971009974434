import * as React from 'react'
import * as moment from 'moment-timezone'
import { Icon } from 'app/frontend/components/material/icon'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './hero-dates.css'
const t = tns('teach:assessment_builder')

type OwnProps = {
  assessment: GQL.GetAssignment.Assignment
}

type Props = OwnProps

export class HeroDates extends React.Component<Props> {
  renderDate = (label: string, date: number) => {
    return (
      <>
        {label} <span className={styles.bold}>{moment(date).format('MMM D[,] YYYY')}</span>{' '}
        {t('at')}{' '}
        <span className={styles.bold}>
          {moment(date).format('h:mma')} {moment.tz.zone(moment.tz.guess()).abbr(date)}
        </span>
      </>
    )
  }

  render() {
    const { assessment } = this.props

    return (
      <div className={styles.dates} data-test="assessment-builder-hero-dates">
        <div>
          <Icon name="icon-date-range" className={styles.dateIcon} />
        </div>
        <div>
          <div className={styles.startDate} data-test="starts-on">
            {this.renderDate(t('starts_on'), assessment.startsAt)}
          </div>
          <div data-test="ends-on">{this.renderDate(t('ends_on'), assessment.endsAt)}</div>
        </div>
      </div>
    )
  }
}

export default HeroDates
