export type UPDATE_UNANSWERED_COMPOUNDS = 'UPDATE_UNANSWERED_COMPOUNDS'
export const UPDATE_UNANSWERED_COMPOUNDS: UPDATE_UNANSWERED_COMPOUNDS =
  'UPDATE_UNANSWERED_COMPOUNDS'
import { UnansweredCompoundsState } from './unanswered-compounds-reducer'

export type UpdateUnansweredCompoundsAction = {
  type: UPDATE_UNANSWERED_COMPOUNDS
  unansweredCompounds: UnansweredCompoundsState
}
export const updateUnansweredCompounds = (unansweredCompounds: UnansweredCompoundsState) => {
  return {
    type: UPDATE_UNANSWERED_COMPOUNDS,
    unansweredCompounds,
  }
}
