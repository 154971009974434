import * as React from 'react'
import { Fields, change } from 'redux-form'
import { useDispatch } from 'react-redux'
import * as moment from 'moment'
import { makeStyles } from '@mui/styles'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'
import { Datepicker, Timepicker } from 'app/frontend/components/pickers'
import {
  getEndDateLimit,
  getStartDateLimit,
} from 'app/frontend/pages/material/teach/helpers/date-defaults-moment'

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    minWidth: '22.75rem',
  },
  pickerContainer: {
    height: '5.75rem',
    width: '100%',
    minWidth: '8rem',
  },
  pickersContainer: {
    display: 'flex',
  },
  spacer: {
    padding: '.75rem',
  },
}))

interface FieldProps extends Props {
  setTimeError: (text: string) => void
  setDateError: (text: string) => void
  timeError: string
  dateError: string
  names: string[]
}

interface Props {
  /**
   * The Field names
   */
  onChange?: (val: moment.Moment) => void
  dateName: string
  timeName: string
  dataBiPrefix: string
  dateSelectorLabel: string
  timeSelectorLabel: string
  description?: string
  minDate?: moment.Moment
  maxDate?: moment.Moment
  disabled?: boolean
  iconButtonAriaLabel?: string
}

const renderDateAndTime = ({
  names,
  dateSelectorLabel,
  dataBiPrefix,
  minDate,
  maxDate,
  setDateError,
  setTimeError,
  description,
  dateError,
  timeError,
  timeSelectorLabel,
  disabled,
  iconButtonAriaLabel,
  ...otherProps
}: WrappedFieldPropsStub & FieldProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { input, meta: dateMeta } = otherProps[names[0]] as WrappedFieldPropsStub
  const { meta: timeMeta } = otherProps[names[1]] as WrappedFieldPropsStub
  const momentValue = moment(input.value)

  // Bit of a hack but it works
  // This allows the validation function to rerun so that the field error and the
  // underlying error can be in sync
  // Using moment() so that the value will change (and trigger the revalidation)
  React.useEffect(() => {
    if (dateError || timeError || dateMeta.error || timeMeta.error) {
      dispatch(change(dateMeta.form, names[1], momentValue))
    }
  }, [dateError, timeError, dateMeta.error, timeMeta.error])

  return (
    <div className={styles.root}>
      <div className={styles.pickersContainer}>
        <div className={styles.pickerContainer}>
          <Datepicker
            value={momentValue}
            label={dateSelectorLabel}
            dataBi={dataBiPrefix + 'date'}
            minDate={getStartDateLimit(minDate?.valueOf())}
            maxDate={getEndDateLimit(maxDate?.valueOf())}
            onChange={input.onChange}
            error={dateMeta.error}
            setError={setDateError}
            disabled={disabled}
            iconButtonAriaLabel={iconButtonAriaLabel}
          />
        </div>
        <div className={styles.spacer} />
        <div className={styles.pickerContainer}>
          <Timepicker
            timepickerId={dataBiPrefix + 'time'}
            value={momentValue}
            label={timeSelectorLabel}
            onChange={input.onChange}
            error={timeMeta.error}
            setError={setTimeError}
            disabled={disabled}
          />
        </div>
      </div>
      <Box direction="row">
        <Paragraph size="small" margin="none">
          {description}
        </Paragraph>
      </Box>
    </div>
  )
}

export const DatetimeSelectorFields: React.FunctionComponent<Props> = ({
  dateName,
  timeName,
  onChange,
  ...otherProps
}) => {
  const [dateError, setDateError] = React.useState('')
  const [timeError, setTimeError] = React.useState('')
  const isDateError = React.useCallback(() => dateError || undefined, [dateError])
  const isTimeError = React.useCallback(() => timeError || undefined, [timeError])

  return (
    <Fields
      names={[dateName, timeName]}
      component={renderDateAndTime}
      {...otherProps}
      validate={{ [dateName]: isDateError, [timeName]: isTimeError }}
      dateError={dateError}
      timeError={timeError}
      setDateError={setDateError}
      setTimeError={setTimeError}
    />
  )
}
