import * as React from 'react'
import type { ApolloError } from '@apollo/client'
import { TwoRowLabelCount } from 'app/frontend/components/two-row-label-count'
import { Box } from 'app/frontend/components/material/box'
import { PlaceholderText } from 'app/frontend/components/material/loading'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { QuestionCountWarnings } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/warning-messages'

const t = tns('teach:assessment_builder')

export interface OwnProps {
  /**
   * True to display warnings about LO/sequence selections that may be
   * a mistake based on assessment settings and other content in the
   * course/section
   */
  showWarnings?: boolean
  learningObjectiveCount?: number
  questionCount?: number
  notAssignedLoCount?: number
  totalAssessmentPoints?: number
  usedQuestionsCount?: number
  notPrintFriendlyCount?: number
  notLdbFriendlyCount?: number
  repeatablePoolQuestionCount?: number
  repeatableStaticQuestionCount?: number
  maxNumAttempts?: number
  printableVariantsCount?: number
  usedQuestionCount?: number
  parentEntityType: Commons.ParentEntityType
  loading?: boolean
  error?: ApolloError
}

type Props = OwnProps

export const AssessmentQuestionLoCounts: React.FunctionComponent<Props> = ({
  loading,
  error,
  learningObjectiveCount,
  questionCount,
  notAssignedLoCount,
  totalAssessmentPoints,
  showWarnings,
  usedQuestionsCount,
  notPrintFriendlyCount,
  notLdbFriendlyCount,
  repeatablePoolQuestionCount,
  repeatableStaticQuestionCount,
  maxNumAttempts,
  printableVariantsCount,
  parentEntityType,
}) => {
  if (loading || error) {
    return (
      <>
        <PlaceholderText width="5rem" height="2rem" />
        <PlaceholderText width="5rem" height="2rem" />
      </>
    )
  }

  // check for multiple attempts & versions warning messages are available or not
  const multipleAttemptsWarning =
    (repeatablePoolQuestionCount || repeatableStaticQuestionCount) &&
    (maxNumAttempts > 1 || printableVariantsCount > 1)
  // check for question counts warning message(s)
  const showCountWarning =
    usedQuestionsCount || notPrintFriendlyCount || notLdbFriendlyCount || multipleAttemptsWarning
  const questionCountWarningMessage =
    showWarnings && showCountWarning ? (
      <QuestionCountWarnings
        usedQuestionsCount={
          usedQuestionsCount > 0
            ? {
                assessmentType: parentEntityType,
                questionCount: usedQuestionsCount,
              }
            : null
        }
        printIncompatibleCount={notPrintFriendlyCount}
        ldbIncompatibleCount={notLdbFriendlyCount}
        poolQuestionCount={repeatablePoolQuestionCount}
        staticQuestionCount={repeatableStaticQuestionCount}
        multipleAttemptsCount={maxNumAttempts}
        printableVersionsCount={printableVariantsCount}
      />
    ) : null

  return (
    <Box pad={{ between: 'medium' }} direction="row">
      <TwoRowLabelCount
        label={t('label_objectives_count')}
        count={learningObjectiveCount}
        dataTestCounter="los-count"
        warning={
          showWarnings && notAssignedLoCount && notAssignedLoCount > 0
            ? t('los_warning', { count: notAssignedLoCount })
            : null
        }
      />
      <TwoRowLabelCount
        label={t('label_questions_count')}
        count={questionCount}
        dataTestCounter="questions-count"
        tooltipWidth="auto"
        warning={questionCountWarningMessage}
      />
      <TwoRowLabelCount
        label={t('label_total_points_count')}
        count={totalAssessmentPoints}
        dataTestCounter="point-count"
      />
    </Box>
  )
}

AssessmentQuestionLoCounts.displayName = 'AssessmentQuestionLoCounts'
