export type RESCUE_EMAIL_INSTRUCTOR = 'RESCUE_EMAIL_INSTRUCTOR'
export const RESCUE_EMAIL_INSTRUCTOR: RESCUE_EMAIL_INSTRUCTOR = 'RESCUE_EMAIL_INSTRUCTOR'
export type RescueEmailInstructorAction = {
  type: RESCUE_EMAIL_INSTRUCTOR
}
export const rescueEmailInstructor = (): RescueEmailInstructorAction => ({
  type: RESCUE_EMAIL_INSTRUCTOR,
})

export type ADD_DISPLAYED_RESCUE_MODAL = 'ADD_DISPLAYED_RESCUE_MODAL'
export const ADD_DISPLAYED_RESCUE_MODAL: ADD_DISPLAYED_RESCUE_MODAL = 'ADD_DISPLAYED_RESCUE_MODAL'
export type AddDisplayedRescueModalAction = {
  type: ADD_DISPLAYED_RESCUE_MODAL
  modalType: Commons.DisplayedModalType
  compoundInstance: Commons.ICompoundInstance
}
export const addDisplayedRescueModal = (
  compoundInstance,
  modalType: Commons.DisplayedModalType
): AddDisplayedRescueModalAction => ({
  compoundInstance,
  modalType,
  type: ADD_DISPLAYED_RESCUE_MODAL,
})

export type OPEN_BOOSTER_ASSIGNMENT = 'OPEN_BOOSTER_ASSIGNMENT'
export const OPEN_BOOSTER_ASSIGNMENT: OPEN_BOOSTER_ASSIGNMENT = 'OPEN_BOOSTER_ASSIGNMENT'
export type OpenBoosterAssignmentAction = {
  type: OPEN_BOOSTER_ASSIGNMENT
  pathId: string
}
export const openBoosterAssignment = (pathId: string): OpenBoosterAssignmentAction => ({
  type: OPEN_BOOSTER_ASSIGNMENT,
  pathId,
})

export type RESCUE_CLOSE_MODAL = 'RESCUE_CLOSE_MODAL'
export const RESCUE_CLOSE_MODAL: RESCUE_CLOSE_MODAL = 'RESCUE_CLOSE_MODAL'
export type RescueCloseModal = {
  type: RESCUE_CLOSE_MODAL
  modalType: 'WORKING_TOO_LONG'
}
export const closeRescueModal = (modalType): RescueCloseModal => ({
  type: RESCUE_CLOSE_MODAL,
  modalType,
})

export type RescueModalActions = RescueEmailInstructorAction | AddDisplayedRescueModalAction
