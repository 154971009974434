import * as React from 'react'
import Content from 'app/frontend/components/content'

import * as atomStyles from 'app/frontend/components/atom/atom.css'

export interface Props {
  atom: GQL.Atom
}

export const InstructionalContent: React.FunctionComponent<Props> = ({ atom }) => {
  const data = atom.data as GQL.AtomInstructionBlock
  return (
    <Content
      data-test="instructional"
      className={atomStyles.userContent}
      html={data.instruction}
      media={atom.media}
    />
  )
}

InstructionalContent.displayName = 'InstructionalContent'
