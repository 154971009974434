import * as React from 'react'
import { TooltipProps } from '@mui/material'
import { Icon, Props as IconProps } from 'app/frontend/components/material/icon/icon'
import { Tooltip, TooltipWidth } from './tooltip'

export interface Props {
  icon: IconProps
  tooltip: Pick<TooltipProps, 'title' | 'placement' | 'id'>
  width?: TooltipWidth
  disabled?: boolean
}

export const TooltipIcon: React.FunctionComponent<Props> = ({ tooltip, icon, width, disabled }) => {
  return (
    <Tooltip width={width} disabled={disabled} {...tooltip}>
      <Icon {...icon} />
    </Tooltip>
  )
}
