import * as _ from 'lodash'
const PADDING = 40
const ANIMATE_TIMEOUT_MS = 10

export type ScrollOptions = {
  duration?: number
  padding?: number
}

export type SelectorOrElement = HTMLElement | string

/**
 * Scrolling helper functions
 */
export class ScrollHelper {
  static computeEase(startTime, curTime, endTime, start, end) {
    let pct = (curTime - startTime) / (endTime - startTime)
    pct = Math.min(1, pct)
    // swing effect
    pct = 0.5 - Math.cos(pct * Math.PI) / 2
    return pct * (end - start) + start
  }

  static animate(duration, start, end, callback) {
    const startTime = Date.now(),
      endTime = startTime + duration,
      doAnimate = () => {
        const curTime = Date.now()
        callback(ScrollHelper.computeEase(startTime, curTime, endTime, start, end))
        if (curTime < endTime) {
          setTimeout(doAnimate, ANIMATE_TIMEOUT_MS)
        }
      }

    setTimeout(doAnimate, ANIMATE_TIMEOUT_MS)
  }

  static jumpToTop() {
    window.scrollTo(0, 0)
  }

  static scrollTo(
    selectorOrElement: SelectorOrElement,
    options: ScrollOptions = {}
  ): HTMLElement | undefined {
    try {
      const ele: HTMLElement =
        typeof selectorOrElement === 'string'
          ? document.querySelector(selectorOrElement)
          : selectorOrElement

      if (!ele) {
        return
      }

      // add default options
      options = _.assign(
        {
          duration: 500,
          padding: PADDING,
        },
        options
      )

      // set focus on element
      ele.focus()

      // animate scroll to element
      const eleTop = window.scrollY + ele.getBoundingClientRect().top

      // animate scrolling
      this.animate(options.duration, window.scrollY, eleTop - options.padding, top => {
        window.scrollTo(0, top)
      })
      return ele
    } catch (e) {
      console.error('ScrollHelper error', e)
    }
  }
}

export default ScrollHelper
