import * as React from 'react'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'

import * as styles from './third-party-cookie-modal.css'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('third_party_cookie_modal')

export const THIRD_PARTY_COOKIE_MODAL = 'THIRD_PARTY_COOKIE_MODAL'

/**
 * For all the browsers, we are giving the link to our knowledge base article on enabling third-party cookies.
 * This article provides detailed instructions for enabling third-party cookies in all major browsers.
 */
export const browserEnableCookieLink = (): string => {
  return 'https://support.knewton.com/s/article/Enabling-Third-Party-Cookies-in-Browsers-for-Knewton-Alta'
}

export const ThirdPartyCookieModal: React.FunctionComponent<{}> = () => {
  return (
    <Modal name={THIRD_PARTY_COOKIE_MODAL} dataBi="third-party-cookie-modal" preventClose={true}>
      <ModalBody>
        <h1 className={styles.modalTitle}>{t('modal_title')}</h1>
        <p>
          {t('modal_body_first')}{' '}
          <a
            href={browserEnableCookieLink()}
            target="_blank"
            className={styles.helpLink}
            data-bi="third-party-cookie-link"
          >
            {t('modal_body_second_link')}
          </a>
          {t('modal_body_third')}
        </p>
      </ModalBody>
    </Modal>
  )
}

export default ThirdPartyCookieModal
