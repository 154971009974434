import { SagaIterator } from 'redux-saga'
import { all, call } from 'redux-saga/effects'
import { apiCall } from 'app/frontend/api/api-redux'
import { batchActions } from 'app/frontend/pages/metrics/metrics-saga'
import { API_REPORT_ATOM_RENDER, reportAtomRenders } from 'app/frontend/api/metrics'
import { AtomRenderReportAction, REPORT_ATOM_RENDER_RESULT } from './actions'

export function* sendAtomRenderEvents(actions: AtomRenderReportAction[]) {
  const atomRenderReports: Content.AtomRenderReport[] = actions.map(action => action.data)
  yield call(apiCall, reportAtomRenders, API_REPORT_ATOM_RENDER, atomRenderReports)
}

/**
 * All REPORT_ATOM_RENDER_RESULT actions will be added to a buffer and sent in batches to the server
 * every 5 s.
 *
 * If more than 300 reports are accumulated in those 10 s, older metrics will
 * start being dropped.
 *
 * A REPORT_ATOM_RENDER_RESULT action is sent each time an Atom is rendered in Alta.
 */
export default function* contentMetricsSaga(): SagaIterator {
  yield all([batchActions(5000, 300, REPORT_ATOM_RENDER_RESULT, sendAtomRenderEvents)])
}
