import * as _ from 'lodash'
import { Reducer } from 'redux'
import { API_SEND_LEARNING_EVENTS } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  AnswerQuizAction,
  QuizFlowActionType,
  SaveQuizAttemptNumberAction,
} from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export type ICompoundEventQueueState = {
  [compoundId: string]: Learn.IEvent
}

export type EventQueueState = {
  [quizId: string]: {
    attemptNumber: number
    events: ICompoundEventQueueState
  }
}

export const getEventQueue = (state: LearnControllerState) => state.entities.quizFlow.eventQueue

export const getEventQueueForQuizAsList = (
  state: LearnControllerState,
  quizId: string
): Learn.IEvent[] => {
  const eventQueueById = getEventQueue(state)[quizId]
  return eventQueueById.events ? Object.values(eventQueueById.events) : []
}

export const getAttemptNumberForQuiz = (state: LearnControllerState, quizId: string): number => {
  const eventQueueById = getEventQueue(state)[quizId]
  return eventQueueById && eventQueueById.attemptNumber
}

export function eventQueueReducer(
  state: EventQueueState = {},
  action: AnswerQuizAction & ApiSuccessAction & SaveQuizAttemptNumberAction
) {
  let quizId

  if (!action) {
    return state
  }

  switch (action.type) {
    case QuizFlowActionType.AnswerQuizQuestion:
      quizId = action.quizId
      return {
        ...state,
        [quizId]: {
          ...state[quizId],
          events: {
            ...state[quizId].events,
            [action.answerEvent.compoundInstanceId]: action.answerEvent,
          },
        },
      }
    case API_SEND_LEARNING_EVENTS.INIT:
      quizId = action.args[0]
      const sentCompoundIds = action.args[1].map(c => c.compoundInstanceId)
      return {
        ...state,
        [quizId]: {
          ...state[quizId],
          events: _.omit(state[quizId].events, sentCompoundIds),
        },
      }
    case API_SEND_LEARNING_EVENTS.ERROR:
      quizId = action.args[0]
      const compoundsToRetry = _.keyBy(action.args[1], 'compoundInstanceId')
      return {
        ...state,
        [quizId]: {
          ...state[quizId],
          events: Object.assign({}, { ...state[quizId].events }, compoundsToRetry),
        },
      }
    case QuizFlowActionType.ClearEventQueue:
      quizId = action.quizId
      return {
        ...state,
        [quizId]: {},
      }
    case QuizFlowActionType.SaveQuizAttemptNumber:
      quizId = action.quizId
      return {
        ...state,
        [quizId]: {
          ...state[quizId],
          attemptNumber: action.attemptNumber,
        },
      }
    default:
      return state
  }
}

export default eventQueueReducer as Reducer<EventQueueState>
