import { BreakpointsOptions } from '@mui/material/styles'

/**
 * Our UX team is too small to give comprehensive designs for each breakpoint -- we'll generally be given a
 * single "mobile" design that needs to be evaluated for xs devices down to 320px (a 10 yr old iphone).
 * *
 * [0, 600) - xs
 * Phones and any weirdos that use the minimum browser window size.
 *
 * [600, 960) - sm
 * Large phones and small tablets (in portrait view). Generally, we'll render the desktop
 * experience in this case, but there may be cases where we want to render a mobile experience,
 * instead.
 *
 * [960, 1280) - md
 * Tablets in landscape mode and laptops.
 *
 * [1280, 1920) - lg
 * Laptops and Desktops with smaller screens.
 *
 * [1920, ∞) - xl
 * Anything bigger.
 *
 * When designing for breakpoints, target the *minimum* value in the device range.
 * The only exception is xs, in which we target 320px.
 *
 * We shouldn't abstract these values into new keys like "phone" or "tablet" until v5 because we
 * will lose the convenience bp props in <Hidden> and <Grid>
 * https://github.com/mui-org/material-ui/issues/21589
 *
 * Our pre-material-ui code is written to target a smaller set of breakpoints (in theme.js)
 *   --breakpoint-small   [320, 480) <-- generally refers to mobile
 *   --breakpoint-medium  [480, 768)
 *   --breakpoint-large   [768, ∞)
 * Take care when writing new code.
 */
export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}
