/**
 * Material UI theme overrides for Alta
 */
import { createTheme, ThemeOptions } from '@mui/material'
import { typography } from './typography'
import { palette } from './palette'
import { breakpoints } from './breakpoints'
import { overrides } from './overrides'
import { zIndex } from 'app/frontend/theme/zIndex'

export const theme: ThemeOptions = createTheme({
  breakpoints,
  typography,
  palette,
  components: overrides,
  spacing: factor => `${0.25 * factor}rem`,
  page: {
    maxWidth: '960px',
  },
  modal: {
    maxWidth: '794px',
  },
  zIndex,
})
