module.exports    = {
	"accessibility": "Accessibility",
	"billing": "Billing",
	"catalog": "Catalog",
	"courses": "Courses",
	"help": "Help",
	"instructor": "Instructor",
	"knerd": "Knerd",
	"my_account": "My Account",
	"my_institution": "My Institution",
	"opens_a_dialog": "Opens a dialog",
	"privacy": "Privacy",
	"sign_out": "Sign Out",
	"sign_out_as": "Sign Out As",
	"status": "Status",
	"student": "Student",
	"supplements": "Supplements",
	"terms": "Terms"
}