import CopyrightElsevier from 'app/frontend/components/atom/attribution/copyright-elsevier'

/**
 * An object describing a license.
 * Exactly one of text or customAttributionComponent must be set.
 */
export interface LicenseInfo {
  text?: string
  url?: string
  img?: string
  customAttributionComponent?: React.FunctionComponent<{ atom: GQL.Atom }>
}

/**
 * License Data.
 */
export const LICENSE_DATA: { [name: string]: LicenseInfo } = {
  STANDARD_KNEWTON: {
    text: 'Standard Knewton License',
    url: 'https://www.knewton.com/terms-of-service',
  },
  THIRD_PARTY: {
    text: 'Knewton Third Party License',
    url: 'http://www.knewton.com/third-party-licenses',
  },
  ALL_RIGHTS_RESERVED: {
    text: 'All Rights Reserved',
  },
  UNKNOWN: {
    text: 'Unknown',
  },
  CC_BY: {
    text: 'Creative Commons Attribution 4.0 International License',
    url: 'https://creativecommons.org/licenses/by/4.0/legalcode',
    img: 'https://i.creativecommons.org/l/by/4.0/80x15.png',
  },
  CC_BY_3: {
    text: 'Creative Commons Attribution 3.0 Unported License',
    url: 'https://creativecommons.org/licenses/by/3.0/legalcode',
    img: 'https://i.creativecommons.org/l/by/3.0/80x15.png',
  },
  CC_BY_SA: {
    text: 'Creative Commons Attribution-ShareAlike 4.0 International License',
    url: 'https://creativecommons.org/licenses/by-sa/4.0/legalcode',
    img: 'https://i.creativecommons.org/l/by-sa/4.0/80x15.png',
  },
  CC_BY_NC: {
    text: 'Creative Commons Attribution-NonCommercial 4.0 International License',
    url: 'https://creativecommons.org/licenses/by-nc/4.0/legalcode',
    img: 'https://i.creativecommons.org/l/by-nc/4.0/80x15.png',
  },
  CC_BY_NC_SA: {
    text: 'CC Attribution-NonCommercial-ShareAlike 4.0 International License',
    url: 'https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode',
    img: 'https://i.creativecommons.org/l/by-nc-sa/4.0/80x15.png',
  },
  CC_BY_ND: {
    text: 'Creative Commons Attribution-NoDerivatives 4.0 International License',
    url: 'https://creativecommons.org/licenses/by-nd/4.0/legalcode',
    img: 'https://i.creativecommons.org/l/by-nd/4.0/80x15.png',
  },
  CC_BY_NC_ND: {
    text: 'CC Attribution-NonCommercial-NoDerivatives 4.0 International License',
    url: 'https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode',
    img: 'https://i.creativecommons.org/l/by-nc-nd/4.0/80x15.png',
  },
  CCO: {
    text: 'CC0 1.0 Universal License',
    url: 'https://creativecommons.org/publicdomain/zero/1.0/legalcode',
    img: 'https://i.creativecommons.org/p/mark/1.0/80x15.png',
  },
  COPYRIGHT_ELSEVIER: {
    text: '',
    customAttributionComponent: CopyrightElsevier,
  },
}

export default LICENSE_DATA
