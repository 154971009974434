export const REPORT_ATOM_RENDER_RESULT = 'REPORT_ATOM_RENDER_RESULT'

export type AtomRenderReportAction = {
  type: string
  data: Content.AtomRenderReport
}

export const reportAtomRenderFailure = (
  contentDetails: Content.ContentDetails,
  atom: Content.IAtom,
  latencyMs: number,
  renderLocation: Content.AtomRenderLocation
): AtomRenderReportAction => ({
  type: REPORT_ATOM_RENDER_RESULT,
  data: {
    metricType: 'ATOM RENDER REPORT',
    result: 'ERROR',
    atomMetadata: {
      atomId: atom.id.atomId,
      variationId: atom.id.variationId,
      sequenceId: atom.id.sequenceId,
      atomType: _getAtomType(atom),
      renderLocation,
    },
    latencyMs,
    lastContent: contentDetails,
  },
})

export const reportAtomRenderSuccess = (
  contentDetails: Content.ContentDetails,
  atom: Content.IAtom,
  latencyMs: number,
  renderLocation: Content.AtomRenderLocation
): AtomRenderReportAction => ({
  type: REPORT_ATOM_RENDER_RESULT,
  data: {
    metricType: 'ATOM RENDER REPORT',
    result: 'SUCCESS',
    atomMetadata: {
      atomId: atom.id.atomId,
      variationId: atom.id.variationId,
      sequenceId: atom.id.sequenceId,
      atomType: _getAtomType(atom),
      renderLocation,
    },
    latencyMs,
    lastContent: contentDetails,
  },
})

// This is a Custom enum used to disambiguate the LEARNOSITY_GENERIC_QUESTION type into
// its sub-types.
type AtomType =
  | Exclude<GQL.AtomDataType, 'LEARNOSITY_GENERIC_QUESTION'>
  | 'DESMOS_QUESTION'
  | 'LEARNOSITY_MATH_CLOZE_QUESTION'
  | 'CUSTOM_LEARNOSITY_MC_QUESTION'
  | 'CUSTOM_LEARNOSITY_MATH_QUESTION'

/**
 *  Custom test items, math formula cloze questions, and Desmos questions all use a layout type of
 *  LEARNOSITY_GENERIC_QUESTION. To better identify which type of Atom we are reporting on, split
 *  them up when reporting an Atom render.
 */
const _getAtomType = (atom: Content.IAtom): AtomType => {
  if (atom.dataType === 'LEARNOSITY_GENERIC_QUESTION') {
    const learnosityType = (atom.data as GQL.AtomLearnosityBlock).content.type
    if (learnosityType === 'mcq') {
      return 'CUSTOM_LEARNOSITY_MC_QUESTION'
    } else if (learnosityType === 'formulaV2') {
      return 'CUSTOM_LEARNOSITY_MATH_QUESTION'
    } else if (learnosityType === 'clozeformula') {
      return 'LEARNOSITY_MATH_CLOZE_QUESTION'
    } else {
      return 'DESMOS_QUESTION'
    }
  } else {
    // Typescript doesn't realize we checked for LEARNOSITY_GENERIC_QUESTION thus it thinks
    // the types are incompatible.
    return atom.dataType as AtomType
  }
}
