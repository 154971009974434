import { isEmpty } from 'lodash'
import {
  assessmentLoSelectionReducer,
  LoSelectionState,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-lo-selection-reducer'
import {
  QuestionPreferencesReducer,
  QuestionPreferencesState,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences-reducer'
import {
  QuestionViewPreferencesState,
  QuestionViewPreferencesReducer,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/question-view-preferences-reducer'
import { combineReducers } from 'redux'
import {
  assessmentSequenceSelectionReducer,
  SequenceSelectionState,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-sequence-selection-reducer'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import {
  SequenceStatus,
  SequenceStatusMap,
  TopicLoStatus,
  TopicLoStatusMap,
  ConceptStatus,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import {
  assessmentConceptSelectionReducer,
  ConceptSelectionState,
  ConceptStatusesByTopic,
} from './assessment-lo-selection-modal/assessment-concept-selection-reducer'
import { AssessmentBuilderModalType } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import { QuestionViewPreferencesOptionsViewDensity } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'

export type AssessmentBuilderState = {
  sequenceState: SequenceSelectionState
  topicLoState: LoSelectionState
  loConceptState: ConceptSelectionState
  questionPreferences: QuestionPreferencesState
  questionViewPreferences: QuestionViewPreferencesState
}

export const getAssessmentBuilderState = (state: TeachControllerState): AssessmentBuilderState =>
  state && state.ui && state.ui.assessmentBuilder

export const getSequenceStatusMap = (state: TeachControllerState): SequenceStatusMap => {
  const assessmentBuilderState = getAssessmentBuilderState(state)
  return assessmentBuilderState.sequenceState.sequenceStatuses
}

export const isAnySequenceSaving = (state: TeachControllerState): boolean => {
  const sequenceStatusMap = getSequenceStatusMap(state)
  return (
    (sequenceStatusMap && Object.values(sequenceStatusMap).includes(SequenceStatus.Saving)) || false
  )
}

export const hasAnySequenceError = (state: TeachControllerState): boolean => {
  const sequenceStatusMap = getSequenceStatusMap(state)
  return (
    (sequenceStatusMap && Object.values(sequenceStatusMap).includes(SequenceStatus.Error)) || false
  )
}

export const getSequenceStatus = (
  state: TeachControllerState,
  sequenceId: string
): SequenceStatus => {
  const sequenceStatusMap = getSequenceStatusMap(state)
  return (sequenceStatusMap && sequenceStatusMap[sequenceId]) || SequenceStatus.None
}

export const isSequenceSaving = (state: TeachControllerState, sequenceId: string): boolean =>
  getSequenceStatus(state, sequenceId) === SequenceStatus.Saving

export const hasSequenceError = (state: TeachControllerState, sequenceId: string): boolean =>
  getSequenceStatus(state, sequenceId) === SequenceStatus.Error

const EMPTY_STATUS_MAP = {}
export const getTopicLoStatusMap = (
  state: TeachControllerState,
  assessmentId: string
): TopicLoStatusMap => {
  const assessmentBuilderState = getAssessmentBuilderState(state)
  const topicLoState = assessmentBuilderState && assessmentBuilderState.topicLoState[assessmentId]
  return (topicLoState && topicLoState.topicLoStatuses) || EMPTY_STATUS_MAP
}

export const isAnyTopicLoSaving = (state: TeachControllerState, assessmentId: string): boolean => {
  const topicLoStatusMap = getTopicLoStatusMap(state, assessmentId)
  return (
    (topicLoStatusMap &&
      Object.values(topicLoStatusMap).some(loStatusMap =>
        Object.values(loStatusMap)
          .map(({ status }) => status)
          .includes(TopicLoStatus.Saving)
      )) ||
    false
  )
}

export const isAnyConceptSaving = (state: TeachControllerState, assessmentId: string): boolean => {
  const conceptStatuses = getConceptStatusesByTopic(state, assessmentId)
  return (
    conceptStatuses &&
    Object.values(conceptStatuses).some(conceptStatusesByLoMap =>
      Object.values(conceptStatusesByLoMap).some(loConceptStatuses =>
        Object.values(loConceptStatuses).includes(ConceptStatus.Saving)
      )
    )
  )
}

/**
 * Get the concepts status by topic ID
 * @param state - TeachControllerState
 * @param assessmentId - id of the assessment
 */
export const getConceptStatusesByTopic = (
  state: TeachControllerState,
  assessmentId: string
): ConceptStatusesByTopic => {
  const assessmentBuilderState = getAssessmentBuilderState(state)
  return assessmentBuilderState?.loConceptState?.[assessmentId]?.conceptStatuses ?? EMPTY_STATUS_MAP
}

/**
 * Get the redux ViewDensity value.
 * If ViewDensity value exist for given sequence ID it will
 * return relevant  ViewDensity value otherwise default ViewDensity value
 * @param state - TeachControllerState
 * @param sequenceId - id of sequence
 */
export const getDisplayDensity = (state: TeachControllerState, sequenceId: string) => {
  let displayDensity
  const {
    customDisplayDensity,
    displayDensity: displayDensityState,
  } = state.ui.assessmentBuilder.questionViewPreferences

  // Check whether QuestionSelection modal is open.
  // if QuestionSelection is open set displayDensity value to EXPANDED mode
  // otherwise check relevant question displayDensity if exist then apply it.
  // else default displayDensity value exist on redux layer.
  if (!isEmpty(state.modal) && state.modal[AssessmentBuilderModalType.QuestionSelection]) {
    displayDensity = QuestionViewPreferencesOptionsViewDensity.EXPANDED
  } else {
    displayDensity = customDisplayDensity.get(sequenceId) ?? displayDensityState
  }

  return displayDensity
}

export const assessmentBuilderReducer = combineReducers<AssessmentBuilderState>({
  sequenceState: assessmentSequenceSelectionReducer,
  topicLoState: assessmentLoSelectionReducer,
  loConceptState: assessmentConceptSelectionReducer,
  questionPreferences: QuestionPreferencesReducer,
  questionViewPreferences: QuestionViewPreferencesReducer,
})
