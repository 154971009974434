/**
 * Valid event strings that can be tracked with mixpanel on the client
 */
export const LOGIN_ATTEMPT = 'Login Attempt'
export const REGISTER_ATTEMPT = 'Register Attempt'
export const REGISTER_OAUTH_ATTEMPT = 'Register Oauth Attempt'
export const ACCOUNT_EDIT_ATTEMPT = 'Account Edit Attempt'

export const RELOAD_PROMPT_SHOW = 'global | snackbars | reload prompt | show'
export const RELOAD_PROMPT_CLICK = 'global | snackbars | reload prompt | click'

export const TEACH_STUDENT_CLICK_MARK_AS_DONE = 'teach | student | click | mark as done'
export const TEACH_STUDENT_CLICK_DELETE_LAST_ATTEMPT =
  'teach | student | click | delete last attempt'

// Reset password flow
export const RESET_PASSWORD_VIEW = 'login | reset | view'
export const RESET_PASSWORD_CLICK = 'login | reset | click | reset'

// My Courses view
export const TEACH_COURSES_VIEW = 'teach | courses | view'
export const TEACH_COURSES_COPY_SHOW = 'teach | courses | copy | show'
export const TEACH_COURSES_CLICK_COURSE = 'teach | courses | click | course'
export const TEACH_CREATE_COPY_COURSE = 'teach | courses | copy | click | create'

// Course Builder Funnel
export const TEACH_CREATE_COURSE_CLICK_OPEN_MODAL = 'teach | create course | click | open modal'
export const TEACH_CREATE_COURSE_CLICK_CREATE = 'teach | create course | click | create'
export const TEACH_CREATE_ASSIGNMENT_CLICK_OPEN_MODAL =
  'teach | create assignment | click | open modal'

// Coursework Builder
export const TEACH_COURSE_COURSEWORK_MANAGE_ADD = 'teach | course | manage | click | add'
export const TEACH_SECTION_COURSEWORK_MANAGE_ADD = 'teach | section | manage | click | add'
export const TEACH_COURSE_COURSEWORK_ADD_ASSIGNMENT = 'teach | course | add | click | assignment'
export const TEACH_SECTION_COURSEWORK_ADD_ASSIGNMENT = 'teach | section | add | click | assignment'
export const TEACH_COURSE_COURSEWORK_ADD_ASSESSMENT = 'teach | course | add | click | assessment'
export const TEACH_SECTION_COURSEWORK_ADD_ASSESSMENT = 'teach | section | add | click | assessment'

// Assignment Builder
export const TEACH_COURSE_ADD_ASSIGNMENT_EXIT = 'teach | course | add | assignment | click | exit'
export const TEACH_SECTION_ADD_ASSIGNMENT_EXIT = 'teach | section | add | assignment | click | exit'
export const TEACH_COURSE_ADD_ASSIGNMENT_CREATE =
  'teach | course | add | assignment | click | create'
export const TEACH_SECTION_ADD_ASSIGNMENT_CREATE =
  'teach | section | add | assignment | click | create'
export const TEACH_COURSE_ADD_ASSIGNMENT_SELECT_LOS =
  'teach | course | add | assignment | click | LOs'
export const TEACH_SECTION_ADD_ASSIGNMENT_SELECT_LOS =
  'teach | section | add | assignment | click | LOs'
export const TEACH_COURSE_ADD_ASSIGNMENT_NEXT_LOS =
  'teach | course | add | assignment | click | next'
export const TEACH_SECTION_ADD_ASSIGNMENT_NEXT_LOS =
  'teach | section | add | assignment | click | next'
export const TEACH_COURSE_ADD_ASSIGNMENT_SAVE_LOS =
  'teach | course | add | assignment | click | save changes'
export const TEACH_SECTION_ADD_ASSIGNMENT_SAVE_LOS =
  'teach | section | add | assignment | click | save changes'
export const TEACH_COURSE_ADD_ASSIGNMENT_FILTERS =
  'teach | course | add | assignment | click | filters'
export const TEACH_SECTION_ADD_ASSIGNMENT_FILTERS =
  'teach | section | add | assignment | click | filters'

// Assessment Builder
export const TEACH_SECTION_ASSESSMENT_ADD_ALTA_QUESTION =
  'teach | section | assessment | add alta question'
export const TEACH_COURSE_ASSESSMENT_ADD_ALTA_QUESTION =
  'teach | course | assessment | add alta question'
export const TEACH_SECTION_ASSESSMENT_EDIT = 'teach | section | assessment | edit'
export const TEACH_COURSE_ASSESSMENT_EDIT = 'teach | course | assessment | edit'
export const TEACH_SECTION_ASSESSMENT_EDIT_QUESTIONS =
  'teach | section | assessment | edit | questions'
export const TEACH_COURSE_ASSESSMENT_EDIT_QUESTIONS =
  'teach | course | assessment | edit | questions'
export const TEACH_SECTION_ASSESSMENT_EDIT_QUESTIONS_ADD_OR_REMOVE =
  'teach | section | assessment | edit | questions | addremove'
export const TEACH_COURSE_ASSESSMENT_EDIT_QUESTIONS_ADD_OR_REMOVE =
  'teach | course | assessment | edit | questions | addremove'
export const TEACH_SECTION_ASSESSMENT_ADD_CUSTOM = 'teach | section | assessment | add custom'
export const TEACH_COURSE_ASSESSMENT_ADD_CUSTOM = 'teach | course | assessment | add custom'
export const TEACH_SECTION_ASSESSMENT_PREVIEW = 'teach | section | assessment | preview'
export const TEACH_COURSE_ASSESSMENT_PREVIEW = 'teach | course | assessment | preview'
export const TEACH_SECTION_ASSESSMENT_SETTINGS = 'teach | section | assessment | settings'
export const TEACH_COURSE_ASSESSMENT_SETTINGS = 'teach | course | assessment | settings'
export const TEACH_SECTION_ASSESSMENT_CHANGE_VIEW = 'teach | section | assessment | change view'
export const TEACH_COURSE_ASSESSMENT_CHANGE_VIEW = 'teach | course | assessment | change view'
export const TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_APPLY =
  'teach | section | assessment | change view | apply'
export const TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_APPLY =
  'teach | course | assessment | change view | apply'
export const TEACH_SECTION_ASSESSMENT_MOVE_QUESTION = 'teach | section | assessment | move question'
export const TEACH_COURSE_ASSESSMENT_MOVE_QUESTION = 'teach | course | assessment | move question'
export const TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_COLLAPSE_APPLY =
  'teach | section | assessment | change view | collapse | apply'
export const TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_COLLAPSE_APPLY =
  'teach | course | assessment | change view | collapse | apply'
export const TEACH_SECTION_ASSESSMENT_KEBOB_EDIT_QUESTIONS =
  'teach | section | assessment | edit questions'
export const TEACH_COURSE_ASSESSMENT_KEBOB_EDIT_QUESTIONS =
  'teach | course | assessment | edit questions'
export const TEACH_SECTION_ASSESSMENT_KEBOB_EDIT_QUESTIONS_ADD_OR_REMOVE =
  'teach | section | assessment | edit questions | addremove'
export const TEACH_COURSE_ASSESSMENT_KEBOB_EDIT_QUESTIONS_ADD_OR_REMOVE =
  'teach | course | assessment | edit questions | addremove'
export const TEACH_COURSE_ASSESSMENT_CHANGE_QUESTION =
  'teach | course | assessment | change question'
export const TEACH_SECTION_ASSESSMENT_CHANGE_QUESTION =
  'teach | section | assessment | change question'
export const TEACH_COURSE_ASSESSMENT_POINTS_SAVE = 'teach | course | assessment | points | save'
export const TEACH_SECTION_ASSESSMENT_POINTS_SAVE = 'teach | section | assessment | points | save'
export const TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_BY_ORDER_APPLY =
  'teach | section | assessment | change view | order | apply'
export const TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_BY_ORDER_APPLY =
  'teach | course | assessment | change view | order | apply'
export const TEACH_SECTION_ASSESSMENT_REMOVE_QUESTION =
  'teach | section | assessment | remove question'
export const TEACH_COURSE_ASSESSMENT_REMOVE_QUESTION =
  'teach | course | assessment | remove question'
export const TEACH_SECTION_MANAGE_EDIT = 'teach | section | manage | edit'
export const TEACH_COURSE_MANAGE_EDIT = 'teach | course | manage | edit'

export const TEACH_COURSES_COURSEWORK_MANAGE = 'teach | course | coursework | click | manage'
export const TEACH_COURSE_EDIT_ASSIGNMENT_MANAGE =
  'teach | course | manage | click | edit assignment'
export const TEACH_SECTION_EDIT_ASSIGNMENT_MANAGE =
  'teach | section | manage | click | edit assignment'
export const TEACH_COURSE_PREVIEW_ASSIGNMENT =
  'teach | course | manage | click | preview assignment'
export const TEACH_COURSE_EDIT_ASSIGNMENT_CONTENT_VIEW =
  'teach | course | manage | assignment | click | view content'
export const TEACH_SECTION_EDIT_ASSIGNMENT_IND_ACT_DASH =
  'teach | section | individual activity | click | edit'
export const TEACH_COURSE_EDIT_ASSIGNMENT_EXIT = 'teach | course | edit | assignment | click | exit'
export const TEACH_SECTION_EDIT_ASSIGNMENT_EXIT =
  'teach | section | edit | assignment | click | exit'
export const TEACH_COURSE_EDIT_ASSIGNMENT_SAVE = 'teach | course | edit | assignment | click | save'
export const TEACH_SECTION_EDIT_ASSIGNMENT_SAVE =
  'teach | section | edit | assignment | click | save'
export const TEACH_COURSE_EDIT_ASSIGNMENT_SELECT_LOS =
  'teach | course | edit | assignment | click | edit'
export const TEACH_SECTION_EDIT_ASSIGNMENT_SELECT_LOS =
  'teach | section | edit | assignment | click | edit'
export const TEACH_COURSE_EDIT_ASSIGNMENT_SAVE_LOS =
  'teach | course | edit | assignment | click | save changes'
export const TEACH_SECTION_EDIT_ASSIGNMENT_SAVE_LOS =
  'teach | section | edit | assignment | click | save changes'
export const TEACH_COURSE_EDIT_ASSIGNMENT_FILTERS =
  'teach | course | edit | assignment | click | filters'
export const TEACH_SECTION_EDIT_ASSIGNMENT_FILTERS =
  'teach | section | edit | assignment | click | filters'

export const LEARN_PROGRESS_MODAL_DISPLAY = 'learn | modals | progress | show'

export const LEARN_BOOSTER_DISPLAY = 'learn | modals | get help booster | show'
export const LEARN_BOOSTER_BOOST_ME = 'learn | modals | get help booster | boost me'
export const LEARN_BOOSTER_EMAIL = 'learn | modals | get help booster | email'
export const LEARN_BOOSTER_REVIEW = 'learn | modals | get help booster | review'
export const LEARN_BOOSTER_CLOSE = 'learn | modals | get help booster | close'

export const LEARN_GET_HELP_DISPLAY = 'learn | modals | get help | show'
export const LEARN_GET_HELP_EMAIL = 'learn | modals | get help | email'
export const LEARN_GET_HELP_CONTINUE = 'learn | modals | get help | continue'
export const LEARN_GET_HELP_CLOSE = 'learn | modals | get help | close'

export const LEARN_TAKE_BREAK_DISPLAY = 'learn | modals | take a break | show'
export const LEARN_TAKE_BREAK_EMAIL = 'learn | modals | take a break | email'
export const LEARN_TAKE_BREAK_REVIEW = 'learn | modals | take a break| review'
export const LEARN_TAKE_BREAK_CLOSE = 'learn | modals | take a break | close'

export const LEARN_CELEBRATE_ASSIGNMENT_DISPLAY = 'learn | modals | celebrate | show'
export const LEARN_CELEBRATE_ASSIGNMENT_CLICK_RATING = 'learn | modals | celebrate | click | rating'
export const LEARN_CELEBRATE_ASSIGNMENT_CLICK_FEEDBACK =
  'learn | modals | celebrate | click | feedback'
export const LEARN_CELEBRATE_ASSIGNMENT_CLICK_SUBMIT = 'learn | modals | celebrate | click | submit'
export const LEARN_CELEBRATE_ASSIGNMENT_CLICK_TOGGLE_CONFETTI =
  'learn | modals | celebrate | click | toggle confetti'
export const LEARN_CELEBRATE_TOPIC_DISPLAY = 'learn | learn flow | celebrate | topic | show'

export const LEARN_QUIZ_TIME_UP_DISPLAY = 'learn | modals | quiz time up | show'
export const LEARN_QUIZ_TIME_UP_BACK_TO_OVERVIEW =
  'learn | modals | quiz time up | back to quiz overview'

export const LEARN_TEST_TIME_UP_BACK_TO_OVERVIEW =
  'learn | modals | quiz time up | back to test overview'

export const LEARN_ASSIGNMENT_TIME_UP_DISPLAY = 'learn | modals | assignment time up | show'
export const LEARN_ASSIGNMENT_TIME_UP_KEEP_GOING =
  'learn | modals | assignment time up | keep going'
export const LEARN_ASSIGNMENT_TIME_UP_BACK_TO_ASSIGNMENT =
  'learn | modals | assignment time up | back to assignment'

export const LEARN_FLOW_CLICK_MORE_INSTRUCTION = 'learn | learn flow | click | More Instruction'

// Assessment flow
export const LEARN_ASSESSMENT_MISSING_ANSWERS_DISPLAY =
  'learn | test flow | submit | skipped questions modal | show'
export const LEARN_ASSESSMENT_MISSING_ANSWERS_CLOSE =
  'learn | test flow | submit | skipped questions modal | close'
export const LEARN_ASSESSMENT_MISSING_ANSWERS_VIEW_UNANSWERED =
  'learn | test flow | submit | skipped questions modal | view unanswered'
export const LEARN_ASSESSMENT_MISSING_ANSWERS_SUBMIT =
  'learn | test flow | submit | skipped questions modal | submit'

export const KNERDTOOLS_CLICK_CREATE_INSTRUCTOR = 'knerd tools | users | click | create account'
export const KNERDTOOLS_CLICK_CREATE_ACCOUNT =
  'knerd tools | users | create account modal | click | create'
export const KNERDTOOLS_CLICK_CREATE_PASSWORD_RESET_TOKEN =
  'knerd tools | users | reset password token | click | create'

/* Teach Coursework Dashboard */
export const TEACH_ALL_COURSEWORK_VIEW = 'teach | course | coursework | view'
export const TEACH_COURSEWORK_ASSIGNMENTS_TAB = 'teach | course | coursework | tab | Assignments'
export const TEACH_COURSEWORK_QUIZZES_TAB = 'teach | course | coursework | tab | Quizzes'
export const TEACH_COURSEWORK_TESTS_TAB = 'teach | course | coursework | tab | Tests'
export const TEACH_COURSEWORK_HOMEWORK_TAB = 'teach | course | coursework | tab | Homework'
export const TEACH_COURSEWORK_DUE_OR_ALL = 'teach | course | coursework | due or all'
export const TEACH_COURSEWORK_EXPORT_DATA = 'teach | course | coursework | click | Data Export'
export const TEACH_COURSEWORK_IND_COURSEWORK = 'teach | course | coursework | Individual coursework'
export const TEACH_QUIZ_IND_COURSEWORK = 'teach | section | ind assessment'
export const TEACH_COURSEWORK_ASSIGNMENT_VIEW = 'teach | course | coursework | assignment | view'

/* All Students Dashboard */
export const TEACH_ALL_STUDENTS_VIEW = 'teach | course | all students | view'
export const TEACH_ALL_STUDENTS_CLICK_MASTERY_DONUT =
  'teach | course | all students | click | Student Mastery donut'
export const TEACH_ALL_STUDENTS_CLICK_ACTIVITY_DONUT =
  'teach | course | all students | click | Student Activity donut'
export const TEACH_ALL_STUDENTS_CLICK_COLUMN_NAME =
  'teach | course | all students | click | Column name'
export const TEACH_ALL_STUDENTS_CLICK_STUDENT_NAME =
  'teach | course | all students | click | Student name'
export const TEACH_ALL_STUDENTS_CLICK_EMAIL_STUDENT =
  'teach | course | all students | click | E-mail Student'
export const TEACH_INVD_STUDENT_CLICK_EMAIL_HEADER =
  'teach | course | ind activity | click | student email'

/* Individual Student Dashboard */
export const TEACH_IND_STUDENT_VIEW = 'teach | course | ind student | view'
export const TEACH_IND_STUDENT_CLICK_TAB_ASSIGNMENTS =
  'teach | course | ind student | tab | Assignments'
export const TEACH_IND_STUDENT_CLICK_TAB_QUIZZES = 'teach | course | ind student | tab | Quizzes'
export const TEACH_IND_STUDENT_CLICK_TAB_HOMEWORK = 'teach | course | ind student | tab | Homework'
export const TEACH_IND_STUDENT_CLICK_TAB_TESTS = 'teach | course | ind student | tab | Tests'
export const TEACH_IND_STUDENT_CLICK_FILTERS = 'teach | course | ind student | filters'
export const TEACH_IND_STUDENT_CLICK_DUE_OR_ALL = 'teach | course | ind student | due or all'

/* Individual Activity */
export const TEACH_OVERALL_STATUS_FILTER = 'teach | course | activity | overall status filter'
export const TEACH_OVERALL_STATUS_SORT_MASTERY_OR_SCORE =
  'teach | course | activity | sort mastery/score'
export const TEACH_OVERALL_STATUS_EMAIL = 'teach | course | activity | email student'
export const TEACH_OVERALL_STATUS_TAB = 'teach | course | activity | assn | tab | overall status'
export const TEACH_LO_STATUS_EMAIL =
  'teach | course | activity | assn | tab | LO status | email student'
export const TEACH_LO_STATUS_TAB = 'teach | course | activity | assn | tab | LO status'
export const TEACH_LO_STATUS_FILTER =
  'teach | course | activity | assn | tab | LO status | complete/in progress/struggling/not started'
export const TEACH_COURSEWORK_SORT_NAME = 'teach | course | activity | sort | student name'
export const TEACH_COURSEWORK_CLICK_STUDENT_NAME =
  'teach | course | activity | click | student name'
export const TEACH_QUIZ_CLICK_STUDENT_NAME = 'teach | course | quiz | click | student name'

export const TEACH_COURSEWORK_ACTIVITY_VIEW = 'teach | course | activity | view'

/* Ind student ind activity */
export const TEACH_INVD_FILTER = 'teach | course | ind activity | click | filters'
export const TEACH_INVD_QUIZ_FILTER = 'teach | course | ind quiz | click | filters'
export const TEACH_INVD_CONTENT = 'teach | course | ind activity | click | content'
export const TEACH_INVD_QUIZ_CONTENT = 'teach | course | ind quiz | click | content'
export const TEACH_INVD_MARK_DONE = 'teach | course | ind activity | click | mark done'
export const TEACH_INVD_DELETE_LAST_ATTEMPT = 'teach | course | ind activity | delete last attempt'
export const TEACH_COURSEWORK_IND_ACTIVITY_VIEW = 'teach | course | ind activity | view'
export const TEACH_INVD_VIEW_ACTIVITY = 'teach | course | ind activity | click | view activity'

/* Ind student ind activity page */

export const TEACH_INVD_ACTIVITY_FILTER_CHECKBOX =
  'teach | course | ind activity | click | filter checkbox'
export const TEACH_INVD_ACTIVITY_FILTER_APPLY =
  'teach | course | ind activity | filters | click | apply'
export const TEACH_INVD_ACTIVITY_FILTER_POPOVER =
  'teach | course | ind activity | click | filter popover'
export const TEACH_INVD_ACTIVITY_CORRECTNESS = 'teach | course | ind activity | click | correctness'
export const TEACH_INVD_ACTIVITY_OBJECTIVE_FILTER =
  'teach | course | ind activity | activity details | click | dropdown filter'
export const TEACH_INVD_ACTIVITY_CLICK_CONTENT =
  'teach | course | ind activity | activity details | click | content'
export const TEACH_INVD_ACTIVITY_CLICK_OBJECTIVE_LIST_ITEM =
  'teach | course | ind activity | click | learning objective filter'

/* Browse Catalog */
export const TEACH_CATALOG_VIEW = 'teach | catalog | view'
export const TEACH_CATALOG_FILTER = 'teach | catalog | filter'
export const TEACH_CATALOG_OFFERING_CARD = 'teach | catalog | offering card'
export const TEACH_OFFERING_COVER_TOC = 'teach | offering cover | TOC'
export const TEACH_OFFERING_COVER_CREATE_COURSE = 'teach | offering cover | click | Create course'
export const TEACH_OFFERING_COVER_PREVIEW = 'teach | offering cover | click | Preview'
export const TEACH_OFFERING_COVER_PRINT = 'teach | offering cover | click | Print'
export const TEACH_OFFERING_COVER_CLICK_LO = 'teach | offering cover | click | LO'

/* Result Overrides */
export const TEACH_SEQUENCE_OVERRIDE_COURSE = 'teach | course | assessment | result override'
export const TEACH_SEQUENCE_OVERRIDE_SECTION = 'teach | section | assessment | result override'
export const TEACH_COMPOUND_INSTANCE_OVERRIDE = 'teach | section | enrollment | result override'

/* Test dashboards M1 */
export const TEACH_ASSESSMENT_TAB = 'teach | section | test | click | tab'
export const TEACH_ASSESSMENT_DASH_RESULTS_TAB_CLICK_CONTENT =
  'teach | section | test | results tab | click | content'
export const TEACH_ASSESSMENT_DASH_RESULTS_TAB_CHANGE_ATTEMPT_FILTER =
  'teach | section | test | results tab | attempts filter'
export const TEACH_ASSESSMENT_RESULTS_TAB_CONTENT_CLOSE_MODAL =
  'teach | section | test | results tab | click | content | close modal'
export const TEACH_ASSESSMENT_STUDENTS_TAB_TEST_GRADE_FILTER =
  'teach | section | test | student tab | click | test grade filter'
export const TEACH_ASSESSMENT_STUDENTS_TAB_CLICK_STUDENT_NAME =
  'teach | section | test | student tab | click | student name'
export const TEACH_ASSESSMENT_DASH_CLICK_REVIEW_CENTER_LINK =
  'teach | course | test | student tab | click | review center link'

/* Test dashboard question stats modal */
export const TEACH_QUESTION_STATS_MODAL_CLICK_POOL_REFRESH =
  'teach | section | test | results tab | click | content | refresh'
export const TEACH_QUESTION_STATS_MODAL_CLICK_HIDE_SHOW_ANS =
  'teach | section | test | results tab | content | click | hide show answer'
export const TEACH_QUESTION_STATS_MODAL_CLICK_PREV =
  'teach | section | test | results tab | content | click | prev'
export const TEACH_QUESTION_STATS_MODAL_CLICK_NEXT =
  'teach | section | test | results tab | content | click | next'

/* Duplicate Assessment */
export const DUPLICATED_ASSESSMENT_TOKEN_COURSE = 'teach | course | test | copy test token'
export const DUPLICATED_ASSESSMENT_TOKEN_SECTION = 'teach | section | test | copy test token'
export const DUPLICATED_ASSESSMENT_COURSE = 'teach | course | coursework | click | duplicate test'
export const DUPLICATED_ASSESSMENT_SECTION = 'teach | section | manage | duplicate test'
export const DUPLICATED_ASSESSMENT_USING_TOKEN_SECTION =
  'teach | course | section | add | copied test'
export const DUPLICATED_ASSESSMENT_USING_TOKEN_COURSE =
  'teach | course | coursework | add | copied test'
export const NEW_ASSESSMENT_COURSE = 'teach | course | coursework | add | new test'
export const NEW_ASSESSMENT_SECTION = 'teach | course | section | add | new test'

/* LEARN COURSE COVER */
export const LEARN_COURSE_COVER_VIEW = 'learn | course | view'
export const LEARN_COURSE_INSTRUCTORS_NOTES_VIEW = 'learn | course | click | instructors notes'
export const LEARN_COURSE_EMAIL_INSTRUCTOR = 'learn | course | click | email instructor'
export const LEARN_COURSE_START_COURSEWORK = 'learn | course | click | coursework'
export const LEARN_COURSE_REVIEW_CENTER = 'learn | course | click | review center'
export const LEARN_COURSE_INSTRUCTORS_NOTES_BACK_TO_SCHEDULE =
  'learn | assignment cover page | Instructor notes | click | schedule'

/* LEARN ASSIGNMENT COVER */
export const LEARN_ASSIGNMENT_COVER_VIEW = 'learn | assignment cover | view'
export const LEARN_ASSIGNMENT_COVER_OVERVIEW = 'learn | assignment cover | click | overview tab'
export const LEARN_ASSIGNMENT_COVER_ACTIVITY = 'learn | assignment cover | click | activity tab'
export const LEARN_ASSIGNMENT_COVER_BOOSTER = 'learn | assignment cover | click | booster tab'
export const LEARN_ASSIGNMENT_COVER_GRADE_EXPLANATION =
  'learn | assignment cover | click | grade explanation'
export const LEARN_ASSIGNMENT_COVER_NEXT_ASSIGNMENT =
  'learn | assignment cover | click | next assignment'
export const LEARN_ASSIGNMENT_COVER_BACK_TO_COURSE =
  'learn | assignment cover | click | back to course'
export const LEARN_ASSIGNMENT_CLICK_VIEW_RELATED_INSTRUCTION =
  'learn | assignment cover page | click | View Related Instruction'
export const LEARN_ASSIGNMENT_COVER_VIEW_ACTIVITY =
  'learn | assignment cover | click | view activity'

/* LEARN ASSIGNMENT COVER ACTIVITY */
export const LEARN_ASSIGNMENT_COVER_ACTIVITY_VIEW = 'learn | assignment cover | activity tab | view'
export const LEARN_ASSIGNMENT_COVER_ACTIVITY_CORRECTNESS_FILTER =
  'learn | assignment cover | activity tab | correctness filter'
export const LEARN_ASSIGNMENT_COVER_ACTIVITY_CONTENT =
  'learn | assignment cover | activity tab | click | content'
export const LEARN_ASSIGNMENT_CLICK_OBJECTIVE_LIST_ITEM =
  'learn | assignment cover | click | learning objective'

/* LEARN ASSIGNMENT ACTIVITY */
export const LEARN_ASSIGNMENT_ACTIVITY_FILTER_CHECKBOX =
  'learn | activity details | click | filter checkbox'
export const LEARN_ASSIGNMENT_ACTIVITY_FILTER_POPOVER =
  'learn | activity details | click | filter popover'
export const LEARN_ASSIGNMENT_ACTIVITY_CORRECTNESS =
  'learn | activity details | click | correctness'
export const LEARN_ASSIGNMENT_ACTIVITY_FILTER_APPLY = 'learn | activity details | click | apply'
export const LEARN_ASSIGNMENT_ACTIVITY_OBJECTIVE_FILTER =
  'learn | activity details | learning objective filter'
export const LEARN_ASSIGNMENT_ACTIVITY_CLICK_CONTENT = 'learn | activity details | click | content'
export const LEARN_ASSIGNMENT_ACTIVITY_CLICK_CONTENT_MODAL_CLOSE =
  'learn | activity details | content modal | click | close'

/* LEARN ASSIGNMENT COVER BOOSTER */
export const LEARN_ASSIGNMENT_COVER_BOOSTER_VIEW = 'learn | assignment cover | boosters tab | view'
export const LEARN_ASSIGNMENT_COVER_START_BOOSTER =
  'learn | assignment cover | boosters tab | click | enter booster'

/* LEARN REVIEW CENTER */
export const LEARN_REVIEW_CENTER_SORT = 'learn | review center | click | sorting options'
export const LEARN_REVIEW_CENTER_VIEW = 'learn | review center | view'
export const LEARN_REVIEW_CENTER_ENTER = 'learn | review center | click | enter'

/* LEARN FLOW */
export const LEARN_FLOW_VIEW_ANSWER = 'learn | learn flow | 2nd attempt | click | view answers'
export const LEARN_FLOW_BACK_TO_ASSIGNMENT =
  'learn | learn flow | click | back to assignment overview'
export const LEARN_FLOW_SHOW_ANALYTICS = 'learn | learn flow | header | analytics | show'
export const LEARN_FLOW_HIDE_ANALYTICS = 'learn | learn flow | header | analytics | hide'

/* LEARN FLOW MODALS */
export const LEARN_FLOW_MODAL_MORE_INSTRUCTION_VIEW =
  'learn | modals | More Instruction | click | view'
export const LEARN_FLOW_MODAL_MORE_INSTRUCTION_BACK =
  'learn | modals | More Instruction | click | go back'

/* QUIZ/TEST RESULTS */
export const LEARN_TEST_RESULTS_VIEW = 'learn | test results | view'
export const LEARN_TEST_RESULTS_BACK = 'learn | test results | back to test overview'
export const LEARN_TEST_RESULTS_CLICK_FILTER_QUESTIONS =
  'learn | test results | click | correctness filter'

/* QUIZ/TEST FORFEIT RESULTS MODAL */
export const LEARN_TEST_COVER_FORFEIT_ATTEMPT_SHOW =
  'learn | test cover | forfeit attempts modal | show'
export const LEARN_TEST_COVER_FORFEIT_ATTEMPT_FORFEIT =
  'learn | test cover | forfeit attempts modal | click | forfeit'

/* QUIZ/TEST COVER PAGE */
export const LEARN_TEST_COVER_ENTER = 'learn | test cover | click | enter'
export const LEARN_TEST_COVER_VIEW_ANSWERS = 'learn | test cover | click | view answers'
export const LEARN_TEST_COVER_VIEW_RESULTS = 'learn | test cover | click | view results'

/* QUIZ/TEST FLOW */
export const LEARN_TEST_FLOW_SUBMIT = 'learn | test flow | click | submit'
export const LEARN_TEST_FLOW_FILTER = 'learn | test flow | click | filter'

export const LEARN_TEST_FLOW_SUBMIT_MODAL_VIEW = 'learn | test flow | submit modal | show'
export const LEARN_TEST_FLOW_SUBMIT_MODAL_SUBMIT =
  'learn | test flow | submit modal | click | submit'
export const LEARN_TEST_FLOW_SUBMIT_MODAL_CANCEL =
  'learn | test flow | submit modal | click | cancel'
export const LEARN_TEST_FLOW_EXIT_LDB = 'learn | test flow | click | exit LBD'

/* LEARN ACTIVITY MODAL - ACTIVITY NAVIGATOR */
export const LEARN_ACTIVITY_MODAL_CLICK_PREV =
  'learn | activity details | content modal | click | prev'
export const LEARN_ACTIVITY_MODAL_CLICK_NEXT =
  'learn | activity details | content modal | click | next'

/* LOADING SCREEN MODAL EVENTS ON LEARN FLOW */
export const LEARN_FLOW_LOADING_SCREEN_VIEW = 'learn | learn flow | loading modal | view'
export const LEARN_FLOW_LOADING_SCREEN_CLICK_DISMISS =
  'learn | learn flow | loading modal | click | dismiss'

/* ASSESSMENT DASHBOARD */
// Lo Readiness Tab
export const TEACH_ASSESSMENT_DASH_OBJECTIVES_TAB_CLICK_LO =
  'teach | section | test | objective tab | click | learning objective'
// LO Readiness Page
export const TEACH_LO_READINESS_CLICK_LO_FILTER =
  'teach | section | test | LO readiness | objective filter'
export const TEACH_LO_READINESS_LO_FILTER_CLICK_LO =
  'teach | section | test | LO readiness | objective filter | click LO'
export const TEACH_LO_READINESS_CLICK_CONTENT =
  'teach | section | test | LO readiness | click | content'
// LO Readiness Modal
export const TEACH_LO_READINESS_CONTENT_CLICK_HIDE_SHOW_ANS =
  'teach | section | test | LO readiness | content | click | hide show answer'
export const TEACH_LO_READINESS_CONTENT_CLICK_PREV =
  'teach | section | test | LO readiness | content | click | prev'
export const TEACH_LO_READINESS_CONTENT_CLICK_NEXT =
  'teach | section | test | LO readiness | content | click | next'
export const TEACH_LO_READNIESS_CONTENT_CLICK_CLOSE_MODAL =
  'teach | section | test | LO readiness | content | click | close modal'

/** Feedback events */
export const FEEDBACK_BUTTON_CLICK = 'FEEDBACK_BUTTON_CLICK'
export const FEEDBACK_MENU_ITEM_CLICK = 'FEEDBACK_MENU_ITEM_CLICK'
