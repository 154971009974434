export type SET_DISPLAY_ANALYTICS = 'SET_DISPLAY_ANALYTICS'
export const SET_DISPLAY_ANALYTICS = 'SET_DISPLAY_ANALYTICS'

export type SetDisplayAnalyticsAction = {
  type: SET_DISPLAY_ANALYTICS
  displayAnalytics: boolean
}
export const setDisplayAnalytics = (displayAnalytics: boolean): SetDisplayAnalyticsAction => ({
  type: SET_DISPLAY_ANALYTICS,
  displayAnalytics,
})
