import * as React from 'react'
import * as _ from 'lodash'
import Dialog, { DialogProps } from 'accessible-react-toolbox/lib/dialog'
import { clearRefocus, hideModal as hideModalAction } from './modal-actions'
import { connect, ReactReduxContext } from 'react-redux'
import { ReduxFormContext } from 'redux-form'
import { ButtonIconMaterial } from 'app/frontend/components/material/button/button-icon'
import * as classnames from 'classnames'
import { ApolloProvider } from '@apollo/client'
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc' // TODO ALPACA-757
import { __RouterContext } from 'react-router'
import { I18nextProvider } from 'react-i18next'
import { i18n } from 'app/frontend/helpers/translations/i18n'
import * as styles from './modal.css'
import { compose } from 'app/frontend/helpers/compose'
import { altaTheme } from 'app/frontend/theme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'

interface IModalProps extends DialogProps {
  wrapperClass?: string
  name: string
  fullScreen?: boolean
  isVisible?: boolean
  hideModal?: () => void
  leftAction?: React.ReactNode
  rightAction?: React.ReactNode
  belowDialog?: React.ReactNode
  openingBtnId?: string
  preventClose?: boolean
  shouldRefocus?: boolean
  clearRefocus: (name: string) => void
  initialFocus?: () => HTMLElement // if provided will set initial modal focus this element
  dataBi: string
}

export class _ModalLegacy extends React.Component<WithApolloClient<IModalProps>> {
  modalRef = undefined

  componentDidMount() {
    if (this.props.isVisible) {
      setTimeout(() => this.giveFocusToModal())
      // need to add event listener in componentDidMount because some modal components become visible
      // only upon mounting and do not update
      this.addHandleTrapListener()
    }
  }

  componentDidUpdate(prevProps: IModalProps) {
    if (this.props.isVisible && !prevProps.isVisible) {
      setTimeout(() => this.giveFocusToModal())
    }

    if (this.props.isVisible) {
      // in the possibility that it already had the event listener from componentDidMount
      // remove it before adding event listener
      this.removeHandleTrapListener()
      this.addHandleTrapListener()
    } else if (!this.props.isVisible && prevProps.isVisible) {
      this.removeHandleTrapListener()
      this.giveFocusToOpeningBtn()
    }

    if (this.props.shouldRefocus) {
      // In the event that a modal has a DISPLAY_STATE, rather than
      //  changing the actual page, trigger a refocus of the modal.
      if (this.modalRef) {
        this.modalRef.focus()
      }

      // Clear the refocus so we don't enter an endless refocus loop
      this.props.clearRefocus(this.props.name)
    }
  }

  componentWillUnmount() {
    this.removeHandleTrapListener()
    this.giveFocusToOpeningBtn()
  }

  addHandleTrapListener = () => {
    document.addEventListener('keydown', this.handleTrap)
  }

  removeHandleTrapListener = () => {
    document.removeEventListener('keydown', this.handleTrap)
  }

  setRef = elem => {
    this.modalRef = elem
  }

  giveFocusToModal = () => {
    const initialFocus = this.props.initialFocus && this.props.initialFocus()
    if (initialFocus) {
      initialFocus.focus()
    } else if (this.modalRef) {
      this.modalRef.focus()
    } else {
      // if we dont have initial focus or modalRef to focus on
      // we will just focus on the first focusable element

      // We need the setTimeout so that we wait for the modal to
      // exist on the page
      setTimeout(() => {
        const elems = [
          ...document.body
            .querySelector('[id=modal]')
            .querySelectorAll(
              'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), ' +
                'textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
            ),
        ]
        const firstElement = elems[0] as any
        firstElement?.focus()
      }, 0)
    }
  }

  giveFocusToOpeningBtn = () => {
    if (this.props.openingBtnId && document.getElementById(this.props.openingBtnId)) {
      document.getElementById(this.props.openingBtnId).focus()
    }
  }

  handleTrap = event => {
    // get focusable elements
    const elems = [
      ...document.body
        .querySelector('[id=modal]')
        .querySelectorAll(
          'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), ' +
            'textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
        ),
    ]
    const current = event.target
    const firstElement = elems[0] as any
    const lastElement = elems[elems.length - 1] as any
    if (current === elems[elems.length - 1] && !event.shiftKey && event.key === 'Tab') {
      event.preventDefault()
      firstElement.focus()
    } else if (current === elems[0] && event.shiftKey && event.key === 'Tab') {
      event.preventDefault()
      lastElement.focus()
    }
  }

  render() {
    const {
      isVisible,
      children,
      hideModal,
      fullScreen,
      leftAction,
      rightAction,
      preventClose,
      belowDialog,
      wrapperClass,
      client,
      dataBi,
    } = this.props

    let dialogTheme = styles.dialogNonFullscreen
    if (fullScreen) {
      dialogTheme = classnames(styles.dialogFullscreen, { [styles.stickyPadding]: !!belowDialog })
    }

    // TODO -- Follow up
    // Something broke in react-apollo@2.5.3 which caused the ApolloProvider to not properly provide
    // the apollo client to the children of <Dialog/>. As a result, we have to wrap the belowDialog
    // prop and the Dialog children in <ApolloProvider/> in order for it to be accessible by withApollo
    // and <ApolloConsumer/>
    return (
      <ReactReduxContext.Consumer>
        {store => (
          <__RouterContext.Consumer>
            {router => (
              <ReduxFormContext.Consumer>
                {reduxForm => (
                  <Dialog
                    onEscKeyDown={!preventClose ? hideModal : null}
                    onOverlayClick={!preventClose ? hideModal : null}
                    active={isVisible || false}
                    {..._.omit(this.props, ['isVisible', 'hideModal', 'fullscreen'])}
                    theme={{
                      wrapper: classnames(styles.wrapper, { [wrapperClass]: !!wrapperClass }),
                      dialog: dialogTheme,
                      body:
                        (fullScreen && styles.dialogBodyFullscreen) ||
                        styles.dialogBodyNonFullscreen,
                    }}
                    ariaLabelledby="modalTitle"
                    belowDialog={
                      belowDialog && (
                        <ReactReduxContext.Provider value={store}>
                          <ApolloProvider client={client}>{belowDialog}</ApolloProvider>
                        </ReactReduxContext.Provider>
                      )
                    }
                  >
                    <ReactReduxContext.Provider value={store}>
                      <__RouterContext.Provider value={router}>
                        <ReduxFormContext.Provider value={reduxForm}>
                          <ApolloProvider client={client}>
                            <I18nextProvider i18n={i18n}>
                              <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={altaTheme}>
                                  <div id="modal" data-bi={dataBi}>
                                    <div className={styles.actionsContainer}>
                                      <div className={styles.leftActionContainer}>{leftAction}</div>
                                      <div className={styles.rightActionContainer}>
                                        {!rightAction && !preventClose && (
                                          <div>
                                            <ButtonIconMaterial
                                              onClick={hideModal}
                                              icon={
                                                <svg>
                                                  <use xlinkHref="#icon-clear" />
                                                  <title>Close modal</title>
                                                </svg>
                                              }
                                              data-test="close-modal"
                                              ariaLabel="Close modal"
                                              setRef={this.setRef}
                                              dark={true}
                                            />
                                          </div>
                                        )}
                                        {rightAction}
                                      </div>
                                    </div>
                                    <div>{children}</div>
                                  </div>
                                </ThemeProvider>
                              </StyledEngineProvider>
                            </I18nextProvider>
                          </ApolloProvider>
                        </ReduxFormContext.Provider>
                      </__RouterContext.Provider>
                    </ReactReduxContext.Provider>
                  </Dialog>
                )}
              </ReduxFormContext.Consumer>
            )}
          </__RouterContext.Consumer>
        )}
      </ReactReduxContext.Consumer>
    )
  }
}

const mapStateToProps = (state, props): Partial<IModalProps> => ({
  isVisible: !!state.global.ui.modal[props.name],
  shouldRefocus: state.global.ui.modal[props.name]
    ? state.global.ui.modal[props.name].shouldRefocus
    : null,
})

const mapDispatchToProps = (dispatch, props): Partial<IModalProps> => ({
  hideModal: () => dispatch(hideModalAction(props.name)),
  clearRefocus: () => dispatch(clearRefocus(props.name)),
})

// @ts-ignore
export const ModalLegacy = compose<
  React.ComponentType<Partial<IModalProps>>,
  React.ComponentType<Partial<IModalProps>>,
  React.ComponentType<Partial<IModalProps>>
>(
  connect<{}, Partial<IModalProps>, Partial<IModalProps>>(mapStateToProps, mapDispatchToProps),
  withApollo
)(_ModalLegacy)
