import { combineReducers } from 'redux'
import compoundsReducer, { CompoundsReducerState } from './learn-flow-compounds-reducer'
import domainReducer, { DomainReducerState } from './learn-flow-domain-reducer'
import historyReducer, { HistoryReducerState } from './history-reducer'
import boostersReducer, { BoostersReducerState } from './boosters-reducer'
import cheatReducer, { CheatReducerState } from './cheat-reducer'

export type LearnFlowEntitiesState = {
  compounds: CompoundsReducerState
  domain: DomainReducerState
  history: HistoryReducerState
  boosters: BoostersReducerState
  cheat: CheatReducerState
}

export default combineReducers<LearnFlowEntitiesState>({
  compounds: compoundsReducer,
  domain: domainReducer,
  history: historyReducer,
  boosters: boostersReducer,
  cheat: cheatReducer,
})
