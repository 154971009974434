import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as classNames from 'classnames'
import { copyToClipboardAsTsv } from './dataset-atom-helper'
import * as buttonStyles from 'app/frontend/components/material/button/button.css'
import * as styles from './dataset-atom.css'
import { Icon } from 'app/frontend/components/material/icon'

const t = tns('dataset')

type Props = {
  parsedRows: string[][]
  isCopied: boolean
  onClick: () => void
  onError: (error) => void
}

export default class DatasetCopyButton extends React.Component<Props> {
  onClick = () => {
    this.props.onClick()
    copyToClipboardAsTsv(this.props.parsedRows, this.props.onError)
  }

  render(): JSX.Element {
    const isCopied = this.props.isCopied
    return (
      <button
        data-test="dataset-copy-button"
        className={classNames(buttonStyles.nakedButton, styles.copyButton)}
        onClick={this.onClick}
      >
        <Icon name={isCopied ? 'icon-check' : 'icon-copy'} className={styles.iconDatasetButton} />
        {isCopied ? t('copied') : t('copy_to_clipboard')}
      </button>
    )
  }
}
