import * as React from 'react'
import * as lottie from 'lottie-web'
import { getStaticUrl } from 'app/frontend/helpers/assets'

const lottiePlayer = (lottie as any) as typeof lottie.default

interface LottieProps {
  path: string
  loop?: boolean
  autoplay?: boolean
  renderer?: 'canvas' | 'html' | 'svg'
  nodeProps: React.HTMLAttributes<HTMLDivElement> & { 'data-test'?: string }
  playSegment?: [number, number]
}

export const Lottie: React.FunctionComponent<LottieProps> = ({
  path,
  loop,
  autoplay,
  renderer,
  nodeProps,
  playSegment,
}) => {
  const [node, setNode] = React.useState<HTMLDivElement>()

  React.useEffect(() => {
    const playSegments = () => {
      animation.playSegments(playSegment, true)
    }
    let animation
    if (node) {
      animation = lottiePlayer?.loadAnimation({
        container: node,
        renderer: renderer ?? 'svg',
        loop: loop ?? true,
        autoplay: autoplay ?? true,
        path: getStaticUrl(path),
      })
      if (playSegment) {
        animation?.addEventListener('DOMLoaded', playSegments)
      }
    }
    return () => {
      if (playSegment) {
        animation?.removeEventListener('DOMLoaded', playSegments)
      }
      animation?.destroy()
    }
  }, [node, path, renderer, loop, autoplay, playSegment?.[0], playSegment?.[1]])

  return <div ref={setNode} {...nodeProps} />
}

export default Lottie
