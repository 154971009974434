import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Heading } from 'app/frontend/components/material/heading'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './table-of-contents.css'

const t = tns('teach:coursework_builder')

type OwnProps = {
  title: GQL.TitleTaxonFields.Fragment
  showTitle: boolean
  disabled?: boolean
  children: JSX.Element | JSX.Element[]
}

type Props = OwnProps

export class TableOfContentsTitle extends React.PureComponent<Props> {
  render() {
    const { title, showTitle, disabled, children } = this.props

    return (
      <Box alignSelf="stretch" pad={{ top: 'large' }}>
        {showTitle && (
          <>
            <Heading
              tag="h2"
              size="h2"
              weight="regular"
              margin={{ bottom: disabled ? 'small' : 'large' }}
              data-test={`title-name-${title.id}`}
            >
              {title.name}
            </Heading>
            {disabled && (
              <Heading
                tag="h3"
                size="h4"
                weight="regular"
                margin={{ bottom: 'large' }}
                data-test="title-disabled-notice"
                className={styles.titleDisabledNotice}
              >
                {t('assignment_toc_title_disabled')}
              </Heading>
            )}
          </>
        )}

        <Box alignSelf="stretch" direction="column">
          {children}
        </Box>
      </Box>
    )
  }
}
