import * as React from 'react'
import * as classnames from 'classnames/bind'
import { BoxContextProps, withBoxContext } from 'app/frontend/components/material/box/box'
import * as styles from './icon.css'

const c = classnames.bind(styles)

export type IconSize = 'small' | 'medium' | 'medlarge' | 'large' | 'xlarge' | 'xxlarge' | 'huge'
export interface Props extends React.HTMLAttributes<SVGElement>, BoxContextProps {
  /** The name of the icon (ex. icon-outline) this should correspond to the icon name in knewcons */
  name: string

  /** The size icon. Default size is medium (1rem). */
  size?: IconSize
  /** Sets the icon svg title, if provided */
  title?: string
  /** Sets the aria role, if provided */
  role?: string
  /** If true, hidden from accessibility tools (default : true) */
  ariaHidden?: boolean
  /** If true, this element is focusable */
  focusable?: boolean
  /** If true, applies disabled icon styling */
  disabled?: boolean
  /** If true, remove the role attribute (default : true) */
  roleHidden?: boolean
}

export class _Icon extends React.Component<Props, {}> {
  /**
   * Returns a css class name of the pattern `prefix-value`.  If an object is passed into
   * the value, the pattern is then `prefix-value.key-value.value`.
   */
  private classes(prefix: string, value: boolean | string | object): string[] {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [`${prefix}-${value}`]
    } else if (typeof value === 'object') {
      return Object.entries(value).map(([key, val]) => `${prefix}-${key}-${val}`)
    } else {
      return []
    }
  }

  render(): JSX.Element {
    const {
      name,
      size = 'medium',
      className,
      ariaHidden = true,
      focusable,
      disabled,
      title,
      role,
      background,
      roleHidden = true,
      ...otherProps
    } = this.props

    return (
      <svg
        role={role}
        className={classnames(
          c(
            `default`,
            this.classes('size', size),
            this.classes('disabled', disabled),
            this.classes('context', { background })
          ),
          className
        )}
        data-name={name}
        {...(!roleHidden && { role: typeof role !== 'undefined' ? role : 'presentation' })}
        aria-hidden={
          (typeof ariaHidden !== 'undefined' ? String(ariaHidden) : 'false') as 'true' | 'false'
        }
        focusable={
          (typeof focusable !== 'undefined' ? '' + focusable : 'false') as 'true' | 'false'
        }
        {...otherProps}
      >
        <use xlinkHref={`#${name}`} />
        {typeof title !== 'undefined' && <title>{title}</title>}
      </svg>
    )
  }
}

export const Icon = withBoxContext<_Icon, Props>(_Icon)
