import * as React from 'react'
import { toNumber } from 'lodash'
import { Box } from 'app/frontend/components/material/box'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { TextInputMaterial } from 'app/frontend/components/material/textinput/textinput'
import * as styles from './edit-question-points.css'

const t = tns('edit_question_points')

export type EditQuestionInputType = number | ''
interface Props {
  points: EditQuestionInputType
  weight: string
  validationError: string | undefined
  onChange: (input: EditQuestionInputType) => void
}

export const EditQuestionPointsInput: React.FunctionComponent<Props> = ({
  points,
  weight,
  onChange,
  validationError,
}) => {
  return (
    <Box direction="row" full="page" margin={{ bottom: 'medium' }}>
      <Box full="page">
        <TextInputMaterial
          type="number"
          data-test="edit-points-input"
          value={points}
          error={validationError}
          onChange={(input: string) => {
            onChange(convertToNumber(input))
          }}
          theme={{
            error: styles.validationError,
            input: styles.editQuestionsInput,
            errored: styles.editQuestionsInputErrored,
            inputElement: styles.editQuestionsInputElement,
          }}
        />
      </Box>
      <Box data-test="edit-points-weight" alignSelf="center" className={styles.inputWeight}>
        {t('points_with_weight', { weight })}
      </Box>
    </Box>
  )
}

const convertToNumber = (input: string): EditQuestionInputType => {
  return input === '' ? input : toNumber(input)
}

EditQuestionPointsInput.displayName = 'EditQuestionPointsInput'
