export type SHOW_GRADE_UPDATE_SNACKBAR = 'SHOW_GRADE_UPDATE_SNACKBAR'
export const SHOW_GRADE_UPDATE_SNACKBAR: SHOW_GRADE_UPDATE_SNACKBAR = 'SHOW_GRADE_UPDATE_SNACKBAR'
export type HIDE_GRADE_UPDATE_SNACKBAR = 'HIDE_GRADE_UPDATE_SNACKBAR'
export const HIDE_GRADE_UPDATE_SNACKBAR: HIDE_GRADE_UPDATE_SNACKBAR = 'HIDE_GRADE_UPDATE_SNACKBAR'
export type UPDATE_GRADE_UPDATE_SNACKBAR_STATUS = 'UPDATE_GRADE_UPDATE_SNACKBAR_STATUS'
export const UPDATE_GRADE_UPDATE_SNACKBAR_STATUS: UPDATE_GRADE_UPDATE_SNACKBAR_STATUS =
  'UPDATE_GRADE_UPDATE_SNACKBAR_STATUS'

export type ShowGradeUpdateSnackbarAction = {
  type: SHOW_GRADE_UPDATE_SNACKBAR
  payload: any
}

export const showGradeUpdateSnackbarAction = (sectionId: string): ShowGradeUpdateSnackbarAction => {
  return {
    type: SHOW_GRADE_UPDATE_SNACKBAR,
    payload: {
      sectionId,
    },
  }
}

export type HideGradeUpdateSnackbarAction = {
  type: HIDE_GRADE_UPDATE_SNACKBAR
}

export const hideGradeUpdateSnackbarAction = (): HideGradeUpdateSnackbarAction => {
  return {
    type: HIDE_GRADE_UPDATE_SNACKBAR,
  }
}

export type UpdateGradeUpdateSnackbarStatusAction = {
  type: UPDATE_GRADE_UPDATE_SNACKBAR_STATUS
  payload: any
}

export const updateGradeUpdateSnackbarStatusAction = (
  sectionId: string
): UpdateGradeUpdateSnackbarStatusAction => {
  return {
    type: UPDATE_GRADE_UPDATE_SNACKBAR_STATUS,
    payload: {
      sectionId,
    },
  }
}

export type GradeUpdateSnackbarActions =
  | ShowGradeUpdateSnackbarAction
  | HideGradeUpdateSnackbarAction
  | UpdateGradeUpdateSnackbarStatusAction
