import { API_CREATE_COURSE } from 'app/frontend/pages/teach/entities/courses/course-actions'

export const getError = state => state.ui.course.error

const defaultState = {}

export type ErrorState = {
  isCreateCourseError?: boolean
  isCreateAssignmentError?: boolean
}

function error(state: ErrorState = {}, action) {
  switch (action.type) {
    case API_CREATE_COURSE.INIT:
    case API_CREATE_COURSE.SUCCESS:
    case API_CREATE_COURSE.CANCEL:
      return defaultState
    case API_CREATE_COURSE.ERROR:
      return {
        isCreateCourseError: true,
      }
    default:
      return state
  }
}

export default error
