import * as React from 'react'
import * as moment from 'moment'
import { DateTimeFormats } from 'app/helpers/timezone'
import { optionsArray } from './helpers'
import { timePickerReducer } from './reducer'
import { defaultTimeFormatRegex } from '../helpers'

type UseTimePickerProps = {
  value: moment.Moment
  onChange: (val: moment.Moment) => void
  timeFormat?: DateTimeFormats
  timeFormatRegex?: RegExp
  defaultTime?: string
  accept24h?: boolean
  isPopupOpen: boolean
  setError?: (text: string) => void
}

type TimePickerState = {
  optionsArray: string[]
  focusIndex: number
  inputTime: string
  setTime: (value: string) => void
  error: string
  handleBlur: () => void
  handleFocus: () => void
  handleChange: (value: string) => void
}

export const useTimePicker = ({
  value,
  onChange,
  timeFormat = DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE,
  timeFormatRegex = defaultTimeFormatRegex,
  defaultTime,
  accept24h,
  setError,
}: UseTimePickerProps): TimePickerState => {
  const dateTimeFormat = `${DateTimeFormats.MONTH_DATE_YEAR} ${timeFormat}`
  const date = React.useMemo(() => value && value.format(DateTimeFormats.MONTH_DATE_YEAR), [value])

  const [
    { time, inputTime, error, focusIndex },
    { setTime, handleBlur, handleFocus, handleChange },
  ] = timePickerReducer({
    value,
    defaultTime,
    timeFormatRegex,
    timeFormat,
    accept24h,
  })

  // if value changes, set the time
  React.useEffect(() => {
    if (!value) {
      return setTime('')
    }
    // If time is initially unset, we want to set the value when it changes. We use handleChange, because
    // the change is coming down from the parent component.
    if (!time) {
      handleChange(value.format(timeFormat))
    } else if (time.format(timeFormat) !== value.format(timeFormat)) {
      return setTime(value.format(timeFormat))
    }
  }, [value])

  // change time but not date
  React.useEffect(() => {
    if (time && time.format(timeFormat) !== value.format(timeFormat)) {
      onChange(moment(`${date} ${time.format(timeFormat)}`, dateTimeFormat))
    }
  }, [time])

  React.useEffect(() => {
    if (setError) {
      setError(error)
    }
  }, [error])

  return {
    optionsArray,
    inputTime,
    focusIndex,
    setTime,
    error,
    handleBlur,
    handleFocus,
    handleChange,
  }
}
