import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
const DUPLICATE_ASSESSMENT_TOKEN_QUERY = require('./duplicate-assessment-token.gql')

interface ChildrenProps {
  duplicateToken?: any
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryGetDuplicateAssessmentTokenArgs & {
  children: (props: ChildrenProps) => JSX.Element
}

export const GetDuplicateAssessmentToken: React.FunctionComponent<Props> = ({
  assignmentId,
  children,
}) => (
  <Query<GQL.GetDuplicateAssessmentToken.Query, GQL.GetDuplicateAssessmentToken.Variables>
    query={DUPLICATE_ASSESSMENT_TOKEN_QUERY}
    variables={{ assignmentId }}
  >
    {({ data, loading, error }) =>
      children({
        duplicateToken: isEmpty(data) ? null : data.getDuplicateAssessmentToken,
        loading,
        error,
      })
    }
  </Query>
)

GetDuplicateAssessmentToken.displayName = 'GetDuplicateAssessmentToken'
