import { combineReducers } from 'redux'

import sampleSequencesReducerById, { ISampleSequencesById } from './sample-sequences-reducer-by-id'

export type SampleSequencesReducerState = {
  byId: ISampleSequencesById
}

export default combineReducers<SampleSequencesReducerState>({
  byId: sampleSequencesReducerById,
})
