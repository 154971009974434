import { QueryHookOptions, useQuery } from '@apollo/client'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import * as GET_MAX_ENROLLMENT_EXTENSION_FOR_PATH from './get-enrollment-max-extension.gql'

type UseMaxExtensionDateForPathResults = RenderProps<{
  extensionDate: number
}>

export const useMaxExtensionDateForPath = (
  pathId: string,
  opts?: QueryHookOptions<
    GQL.GetMaxExtensionDateForPath.Query,
    GQL.GetMaxExtensionDateForPath.Variables
  >
): UseMaxExtensionDateForPathResults => {
  const { loading, error, data } = useQuery<
    GQL.GetMaxExtensionDateForPath.Query,
    GQL.GetMaxExtensionDateForPath.Variables
  >(GET_MAX_ENROLLMENT_EXTENSION_FOR_PATH, {
    ...opts,
    variables: { pathId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first', // TODO ALPACA-759
  })

  return {
    loading,
    error,
    extensionDate: data?.maxExtensionDateForPath.extensionDate,
  }
}
