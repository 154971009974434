import { API_CREATE_ASSIGNMENT_PREVIEW } from './preview-actions'

export interface PreviewState {
  isFetching: boolean
}

const defaultState = {
  isFetching: false,
}
export function previewReducer(state: PreviewState = defaultState, action): PreviewState {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_CREATE_ASSIGNMENT_PREVIEW.INIT:
      return {
        ...state,
        isFetching: true,
      }
    case API_CREATE_ASSIGNMENT_PREVIEW.ERROR:
    case API_CREATE_ASSIGNMENT_PREVIEW.SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}

export const isAssignmentPreviewFetching = state => state.entities.assignments.preview.isFetching

export default previewReducer
