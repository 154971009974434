import * as moment from 'moment'
import { DropdownValues } from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/form-fields/view-results-setting/helper'

export const ASSESSMENT_SETTINGS_FORM = 'ASSESSMENT_SETTINGS_FORM'
export enum FormNames {
  NAME = 'name',
  LABEL = 'label',
  STARTS_AT = 'startsAt',
  ENDS_AT = 'endsAt',
  STARTS_AT_TIME = 'startsAtTime',
  ENDS_AT_TIME = 'endsAtTime',
  PASSWORD_TYPE = 'passwordType',
  PASSWORD = 'password',
  COURSE_PASSWORD = 'coursePassword',
  PASSWORD_ENABLED = 'passwordEnabled',
  MAX_DURATION = 'maxDuration',
  USE_LDB = 'useLdb',
  SHUFFLE_QUESTIONS = 'shuffleQuestions',
  REVIEW_CENTER_ENABLED = 'reviewCenterEnabled',
  MAX_NUM_ATTEMPTS = 'maxNumAttempts',
  GRADE_ATTEMPT_AS = 'gradeAttemptsAs',
  PRINTABLE = 'printable',
  PRINTABLE_VARIATIONS_COUNT = 'printableVariantsCount',
  VIEW_RESULTS_CONFIG = 'viewResultsConfig',
  VIEW_RESULTS_GRADE_SETTING = 'viewResultsGrade',
  VIEW_RESULTS_ANSWER_SETTING = 'viewResultsAnswer',
  VIEW_RESULTS_EXPLANATION_SETTING = 'viewResultsExplanation',
  VIEW_RESULTS_COMMENT = 'viewResultsComment',
  TIMER = 'timer',
  STARTS_OR_ENDS_AT_OVERRIDDEN = 'startsOrEndsAtOverridden',
  PASSWORD_OVERRIDDEN = 'passwordOverridden',
  LDB_OVERRIDDEN = 'ldbOverridden',
}

export interface FormValues {
  name: string
  label: GQL.AssessmentLabel
  startsAt: moment.Moment
  endsAt: moment.Moment
  password: PasswordSettingType
  coursePassword: PasswordSettingType
  passwordType: PasswordType
  passwordEnabled: boolean
  maxDuration: MaxDurationSettingType
  useLdb: boolean
  shuffleQuestions: boolean
  reviewCenterEnabled: boolean
  maxNumAttempts: number
  printable: boolean
  printableVariantsCount: number | null
  viewResultsConfig: GQL.ViewResultsConfig
  startsOrEndsAtOverridden: boolean
  passwordOverridden: boolean
  ldbOverridden: boolean
  viewResultsGrade: DropdownValues
  viewResultsAnswer: DropdownValues
  viewResultsExplanation: DropdownValues
  viewResultsComment: DropdownValues
}

export enum PasswordType {
  COURSE = 'COURSE',
  SECTION = 'SECTION',
}

export type MaxDurationSettingType = moment.Moment | null
export type PasswordSettingType = string | null

export const convertMomentToDuration = (momentObject: moment.Moment): number => {
  if (!momentObject) {
    return null
  }
  return moment
    .duration({
      hours: momentObject.hours(),
      minutes: momentObject.minutes(),
    })
    .asMilliseconds()
}

export const convertDurationToMoment = (duration: number): moment.Moment => {
  return duration ? moment({ seconds: 0 }).milliseconds(duration) : null
}
