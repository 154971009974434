import { useState, useEffect } from 'react'

/**
 * React hook to persist a value to localStorage
 *
 * Use a sensible storageKey to prevent collisions.
 *
 * @param defaultValue default value to set if the key doesn't exist in the local storage
 * @param storageKey key under which the value will be stored in localStorage
 */
export function useLocallyPersistedState(defaultValue: any, storageKey: string) {
  // If the value exists, we'll use that as our initial value.
  // Otherwise, we'll use the default value passed to the hook
  const [persistedValue, setPersistedValue] = useState(() => {
    try {
      const storedValue = window.localStorage.getItem(storageKey)
      return storedValue !== null ? JSON.parse(storedValue) : defaultValue
    } catch (error) {
      console.warn('Error setting the state in useLocallyPersistedState hook: ', error)
      return defaultValue
    }
  })

  // Update localStorage whenever the state value changes
  useEffect(() => {
    try {
      window.localStorage.setItem(storageKey, JSON.stringify(persistedValue))
    } catch (error) {
      console.warn(
        'Error setting the value to local storage in useLocallyPersistedState hook: ',
        error
      )
    }
  }, [storageKey, persistedValue])

  return [persistedValue, setPersistedValue]
}
