import * as React from 'react'
import { Divider, DividerProps } from '@mui/material'

import { makeStyles } from '@mui/styles'

const useMenuDividerStyle = makeStyles(theme => ({
  root: {
    margin: 0,
    backgroundColor: theme.palette?.grey[100],
  },
}))

export const MenuDivider: React.FunctionComponent<DividerProps> = (props): JSX.Element => {
  return <Divider classes={props.classes || useMenuDividerStyle()} {...props} />
}
