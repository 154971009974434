import * as React from 'react'
import type { ApolloError } from '@apollo/client'
import { Atom, AtomWrapper } from 'app/frontend/components/atom'
import InlineAtomAttributions from 'app/frontend/components/atom/inline-atom-attributions'
import AtomSpinner from 'app/frontend/components/material/loading/spinner-atom'

interface OwnProps {
  atom?: GQL.Atom
  loading?: boolean
  error?: ApolloError
  includeAttribution?: boolean
  hideCorrectAnswers?: boolean
  hideChoiceExplanation?: boolean
  hideExplanation?: boolean
  isQuiz: boolean
  loader?: JSX.Element
  sequenceId?: string
  isVariationContentChanged?: boolean
  isFromPreview?: boolean
}

type Props = OwnProps

export const AtomQueryResults: React.FunctionComponent<Props> = props => {
  const {
    loading,
    error,
    atom,
    hideCorrectAnswers,
    hideChoiceExplanation,
    hideExplanation,
    includeAttribution,
    isQuiz,
    loader,
    sequenceId,
    isVariationContentChanged,
    isFromPreview,
  } = props

  // TODO talk to ux about how to handle atom error
  if (error) {
    return <div data-test="atom-fetch-error" />
  }
  if (loading) {
    if (loader) {
      return loader
    } else {
      return <AtomSpinner />
    }
  }

  // Our GQL component is only used for teach.
  const renderLocation: Content.AtomRenderLocation = isQuiz
    ? 'TEACH_ASSESSMENT_BUILDER'
    : 'BROWSE_SEQUENCE'

  return (
    <div data-sequence-id={sequenceId} data-test="atom-wrapper">
      <AtomWrapper
        atom={atom}
        renderLocation={renderLocation}
        key={`${atom.id.sequenceId}+${atom.id.atomId}+${atom.id.variationId}`}
      >
        <Atom
          atom={atom as Content.IAtom} // TODO: Update the dataType field on the graphQL schema to be an enum
          hideCorrectAnswers={hideCorrectAnswers}
          hideExplanation={hideExplanation}
          hideChoiceExplanation={hideChoiceExplanation}
          isVariationContentChanged={isVariationContentChanged}
          isFromPreview={isFromPreview}
        />
        {includeAttribution && <InlineAtomAttributions atom={atom} />}
      </AtomWrapper>
    </div>
  )
}
AtomQueryResults.displayName = 'AtomQueryResults'
