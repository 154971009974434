import * as React from 'react'
import * as moment from 'moment'
import * as keycode from 'keycode'
import { DateTimeFormats } from 'app/helpers/timezone'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { useCalendarStyles } from './styles'
import { Timepicker } from '../timepicker'
import { Calendar } from '../datepicker/calendar'
import { handleFocusTrap } from '../helpers'

const t = tns('combo_calendar')

export type Props = {
  pickerOpen: boolean
  hidePicker: () => void
  onChange: (value: moment.Moment) => void
  setChange: (value: moment.Moment) => void
  value: moment.Moment | undefined
  maxDate?: moment.Moment
  minDate?: moment.Moment
  disabled?: boolean
  dateFormat?: DateTimeFormats
  timeFormat?: DateTimeFormats
  defaultTime?: string
  timeFormatRegex?: RegExp
  timePickerLabel: string
  timepickerId: string
  pickerStyle?: string
  beforeNow?: boolean
}

export const ComboCalendar: React.FunctionComponent<Props> = ({
  hidePicker,
  onChange,
  setChange,
  value,
  maxDate,
  minDate,
  disabled,
  pickerOpen,
  timePickerLabel,
  dateFormat = DateTimeFormats.MONTH_DATE_YEAR,
  timeFormat,
  defaultTime = '12:00pm',
  timeFormatRegex,
  timepickerId,
  pickerStyle,
  beforeNow,
}) => {
  const styles = useCalendarStyles()
  const [comboValue, setComboValue] = React.useState<moment.Moment>(value)
  const [timeError, setTimeError] = React.useState('')
  const calendarRef = React.useRef<HTMLDivElement>()
  const leftIconRef = React.useRef<HTMLDivElement>()
  const rightIconRef = React.useRef<HTMLDivElement>()
  const cancelButtonRef = React.useRef<HTMLOrSVGElement>()
  const updateButtonRef = React.useRef<HTMLOrSVGElement>()
  const timePickerRef = React.useRef<HTMLDivElement>()
  const refsInOrder = [
    calendarRef,
    timePickerRef,
    cancelButtonRef,
    updateButtonRef,
    leftIconRef,
    rightIconRef,
  ]

  const handleChange = (val: moment.Moment) => {
    setComboValue(val)
    onChange(val)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (keycode(e.keyCode) === 'esc') {
      hidePicker()
    }
    if (keycode(e.keyCode) === 'tab') {
      e.preventDefault()
      handleFocusTrap(e, refsInOrder)
    }
  }

  React.useEffect(() => {
    if (!pickerOpen) {
      handleChange(value)
    }
  }, [pickerOpen, value])

  let error = null
  if (comboValue.isAfter(maxDate)) {
    error = t('before_error', { time: maxDate.format(DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE) })
  } else if (!beforeNow && moment().isAfter(comboValue)) {
    error = t('after_error', { time: moment().format(DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE) })
  } else if (comboValue.isBefore(minDate)) {
    error = t('after_error', { time: minDate.format(DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE) })
  }

  return (
    <>
      {pickerOpen && (
        <div onKeyDown={handleKeyDown} className={pickerStyle}>
          <Calendar
            pickerOpen={pickerOpen}
            hidePicker={() => null}
            onChange={handleChange}
            value={comboValue}
            minDate={moment(minDate).startOf('day')}
            maxDate={moment(maxDate).endOf('day')}
            dateFormat={dateFormat}
            disabled={disabled}
            setRefs={{ calendar: calendarRef, leftIcon: leftIconRef, rightIcon: rightIconRef }}
            calendarStyles={styles.calendar}
          />
          <div className={styles.comboContainer}>
            <div className={styles.timeContainer}>
              <Timepicker
                timepickerId={timepickerId}
                value={comboValue}
                onChange={handleChange}
                timeFormat={timeFormat}
                timeFormatRegex={timeFormatRegex}
                label={timePickerLabel}
                defaultTime={defaultTime}
                disabled={disabled}
                inputStyle={styles.input}
                inputRef={timePickerRef}
                error={error}
                setError={setTimeError}
              />
            </div>
            <div className={styles.buttonContainer}>
              <ButtonMaterial
                setButtonRef={ref => (cancelButtonRef.current = ref)}
                label={t('cancel')}
                theme={'secondary'}
                className={styles.comboButton}
                data-test="calender-popup-cancel"
                onClick={() => {
                  hidePicker()
                  handleChange(value)
                }}
              />
              <ButtonMaterial
                setButtonRef={ref => (updateButtonRef.current = ref)}
                label={t('update')}
                className={styles.comboButton}
                data-test="calender-popup-update"
                onClick={() => {
                  hidePicker()
                  setChange(comboValue)
                }}
                disabled={disabled || !!error || !!timeError}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
