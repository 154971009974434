import * as React from 'react'
import { Route } from 'react-router-dom'
import MaterialController from 'app/frontend/layout/material/material-controller'
import SignedOutSaga from './signed-out-saga'
import Login from './login/login'
import Register from './register/register'
import ForgotPassword from './forgot-password/forgot-password'
import ResetPassword from './forgot-password/reset-password'
import ChildUserModal from './modals/child-user'

import * as styles from './login/login.css'

interface ILoginControllerProps {
  role?: 'LEARNER' | 'TEACHER'
  redir?: string
  googleInfo?: object
  email?: string
  token?: string
  valid?: ConstrainBoolean
}

const SignedOutController: React.FunctionComponent<ILoginControllerProps> = ({
  redir,
  googleInfo,
  role,
  email,
  token,
  valid,
}) => {
  const RedirectedLogin = () => <Login redir={redir} google={googleInfo} />

  const WrappedRegister = () => <Register role={role} redir={redir} google={googleInfo} />

  const WrappedForgot = () => <ForgotPassword />
  const WrappedReset = () => <ResetPassword email={email} token={token} isValid={valid} />

  return (
    <div className={styles.loginPage}>
      <MaterialController
        className="signed-out-page"
        saga={SignedOutSaga}
        showNavBar={false}
        reducer="signed-out/signed-out"
      >
        <ChildUserModal />
        <Route path="/login" render={RedirectedLogin} />
        <Route path="/account/register" component={WrappedRegister} />
        <Route path="/forgot" render={WrappedForgot} />
        <Route path="/reset" render={WrappedReset} />
      </MaterialController>
    </div>
  )
}

export default SignedOutController
