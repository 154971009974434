import * as React from 'react'
import type { ApolloError } from '@apollo/client'
import { isEmpty } from 'lodash'
import * as CHECK_INSTRUCTOR_ACCESS_FOR_COURSE from './check-instructor-access-for-course.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { QueryHookOptions, useQuery } from '@apollo/client'

interface ChildrenProps {
  hasAccess?: boolean
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryCheckInstructorAccessForCourseArgs & {
  children: (props: ChildrenProps) => JSX.Element
  skip?: boolean
}
export type UseCheckInstructorAccessForCourseResults = RenderProps<{
  hasAccess?: boolean
}>

export const CheckInstructorAccessForCourseQuery: React.FunctionComponent<Props> = ({
  courseId,
  children,
  skip = false,
}) => {
  const { loading, error, hasAccess } = useCheckInstructorAccessForCourse(courseId, { skip })
  return children({ loading, error, hasAccess })
}

export const useCheckInstructorAccessForCourse = (
  courseId: string,
  opts?: QueryHookOptions<
    GQL.CheckInstructorAccessForCourse.Query,
    GQL.CheckInstructorAccessForCourse.Variables
  >
): UseCheckInstructorAccessForCourseResults => {
  const { loading, error, data } = useQuery<
    GQL.CheckInstructorAccessForCourse.Query,
    GQL.CheckInstructorAccessForCourse.Variables
  >(CHECK_INSTRUCTOR_ACCESS_FOR_COURSE, {
    variables: {
      courseId,
    },
    ...opts,
  })

  return {
    loading,
    error,
    hasAccess: isEmpty(data) ? null : data.checkInstructorAccessForCourse.privileged,
  }
}

CheckInstructorAccessForCourseQuery.displayName = 'CheckInstructorAccessForCourseQuery'
