import { Reducer } from 'redux'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import {
  ReviewCenterActionTypes,
  SortBy,
} from 'app/frontend/pages/material/learn/review-center/review-center-actions'
import { API_GET_REVIEW_ASSIGNMENTS } from 'app/frontend/pages/material/learn/review-center/review-center-actions'

// selectors
export const isReviewAssignmentsLoadedForAssessment = (state: LearnControllerState, assessmentId) =>
  state.ui.reviewCenter.isReviewAssignmentsLoaded[assessmentId]
/**
 * Get the selected review center sort, or the default
 */
export const getReviewCenterSort = (state: LearnControllerState): SortBy =>
  state.ui.reviewCenter.sortBy

// reducer
export type ReviewCenterReducerState = {
  sortBy: SortBy
  isReviewAssignmentsLoaded: {
    [quizId: string]: boolean
  }
}

const defaultState = {
  sortBy: SortBy.getDefault(),
  isReviewAssignmentsLoaded: {},
}

export const reviewCenterUIReducer = (
  state: ReviewCenterReducerState = defaultState,
  action
): ReviewCenterReducerState => {
  let assessmentId
  switch (action.type) {
    case ReviewCenterActionTypes.SortReviewAssignments:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case API_GET_REVIEW_ASSIGNMENTS.INIT:
    case API_GET_REVIEW_ASSIGNMENTS.ERROR:
      assessmentId = action.args[0]
      return {
        ...state,
        isReviewAssignmentsLoaded: {
          ...state.isReviewAssignmentsLoaded,
          [assessmentId]: false,
        },
      }
    case API_GET_REVIEW_ASSIGNMENTS.SUCCESS:
      assessmentId = action.args[0]
      return {
        ...state,
        isReviewAssignmentsLoaded: {
          ...state.isReviewAssignmentsLoaded,
          [assessmentId]: true,
        },
      }
    default:
      return state
  }
}

export default reviewCenterUIReducer as Reducer<ReviewCenterReducerState>
