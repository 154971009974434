import { combineReducers } from 'redux'
import activeInstitutionReducer from './institution/active-institution-reducer'

export type UIState = {
  activeInstitution: Commons.InstitutionId
}

export default combineReducers({
  activeInstitution: activeInstitutionReducer,
})
