import { FilterState } from './filter-reducer'

export enum FilterActionType {
  ApplyFilter = 'APPLY_FILTER',
  Clicked = 'FILTER_CLICKED',
}
export type ApplyFilterAction = {
  type: FilterActionType.ApplyFilter
  filter: FilterState
}
export const applyFilter = (filter: FilterState): ApplyFilterAction => {
  return {
    type: FilterActionType.ApplyFilter,
    filter,
  }
}

export type FilterClickedAction = {
  type: FilterActionType.Clicked
}
export const filterClicked = (): FilterClickedAction => ({
  type: FilterActionType.Clicked,
})
