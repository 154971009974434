import { Action } from 'redux'
import { SagaIterator, buffers, delay, Buffer } from 'redux-saga'
import { call, takeEvery, all, fork, actionChannel, take, put } from 'redux-saga/effects'
import { apiCall } from 'app/frontend/api/api-redux'
import {
  SEND_TIMING,
  API_SEND_TIMING,
  DURATION,
  sendTiming,
} from 'app/frontend/pages/metrics/metrics-actions'
import * as MetricApi from 'app/frontend/api/metrics'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import * as GoogleAnalytics from 'react-ga'

/**
 * Send timing metrics to Grafana
 * @param actions [{metrics: Metrics.TimingMetricsType[]}]
 */
export function* sendTimings(actions): SagaIterator {
  if (actions.length > 0) {
    const allMetrics = []
    for (const action of actions) {
      allMetrics.push(...action.metrics)
    }
    try {
      yield call(apiCall, MetricApi.sendTiming, API_SEND_TIMING, allMetrics)
    } catch (err) {
      // no-op
    }
  }
}

export function* getAsyncCallMetrics(action): SagaIterator {
  const whiteList = new Set([
    '@@kn.api.success/API_CREATE_ASSIGNMENT',
    '@@kn.api.success/API_GET_ASSIGNMENT_ANALYTICS',
    '@@kn.api.success/API_SEND_LEARN_EVENTS',
  ])

  if (!whiteList.has(action.type)) {
    return
  }

  const actionName = action.type.split('/')[1].toLowerCase()

  const metrics = [
    {
      name: `browser.event.${actionName}`,
      start: action.ajaxMetric.requestStart,
      end: action.ajaxMetric.responseEnd,
      metricType: DURATION,
    },
  ]
  yield put(sendTiming(metrics))
}

export const trackPage = (page: string, options = {}) => {
  try {
    const pageTemplate = page
      .split(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
      .join('<uuid>')
    GoogleAnalytics.set({
      pageTemplate,
      ...options,
    })
    GoogleAnalytics.pageview(pageTemplate)
  } catch (e) {
    console.warn('Error sending analytics', e)
  }
}

export function* onLocationChange(action: LocationChangeAction): SagaIterator {
  trackPage(action.payload.location.pathname)
}

/**
 * Batch the actions of the given `pattern` for `ms` milliseconds and process all accumulated
 * actions at once with given `task`.
 *
 * @param ms number of milliseconds to wait for actions to accumulate
 * @param max max number of actions allowed to be accumulated
 * @param pattern pattern of the actions
 * @param task the function that can process a collection of accumulated actions all at once
 */
export const batchActions = (ms, max, pattern, task) =>
  fork(function* () {
    const batchBuffer: Buffer<Action<any>> = buffers.sliding(max)
    const batchChannel = yield actionChannel(pattern, batchBuffer)

    while (true) {
      yield call(delay, ms)
      const actions = []
      for (let i = 0; i < max; i++) {
        const action = yield take(batchChannel)
        actions.push(action)
        if (batchBuffer.isEmpty()) {
          break
        }
      }
      yield fork(task, actions)
    }
  })

/**
 * All SEND_TIMING actions will be added to a buffer and sent in batches to grafana every 10 s.
 * Unless more than 300 metrics are accumulated in those 5 s, in which case older metrics will
 * start being dropped.
 *
 * All LOCATION_CHANGE actions will send a metric to Google Analytics.
 *
 * All *_API_SUCCESS actions will be filtered and converted to SEND_TIMING.
 */
export default function* metricsSaga(): SagaIterator {
  yield all([
    batchActions(5000, 300, SEND_TIMING, sendTimings),
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeEvery(action => /@@kn\.api\.success/.test(action.type), getAsyncCallMetrics),
  ])
}
