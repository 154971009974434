import * as React from 'react'
import {
  Card,
  CardMedia,
  CardTitle,
  CardText,
  CardActions,
} from 'accessible-react-toolbox/lib/card'
import * as _ from 'lodash'
import * as classnames from 'classnames'
const styles = require('./card.css')

interface ICardProps {
  children?: any
  className?: string
  raised?: boolean
  [key: string]: any
}

interface ICardMediaProps {
  aspectRatio?: 'wide' | 'square'
  children?: any
  className?: string
  color?: string
  contentOverlay?: boolean
  image?: React.ReactNode
  [key: string]: any
}

interface ICardTitleProps {
  avatar?: React.ReactNode
  children?: React.ReactNode
  className?: string
  subtitle?: string
  title?: string
  theme?: object
  [key: string]: any
}

interface ICardTextProps {
  children?: React.ReactNode
  className?: string
  [key: string]: any
}

interface ICardActionsProps {
  children?: React.ReactNode
  className?: string
  [key: string]: any
}

export const CardMaterial: React.FunctionComponent<ICardProps> = (props): JSX.Element => {
  return (
    <Card
      {...props}
      theme={{
        card: classnames(styles.card, props.theme ? props.theme.card : ''),
      }}
    />
  )
}

CardMaterial.displayName = 'CardMaterial'

export const CardMediaMaterial: React.FunctionComponent<ICardMediaProps> = (props): JSX.Element => {
  return <CardMedia {...props} theme={{ cardMedia: styles.cardMedia }} />
}

CardMediaMaterial.displayName = 'CardMediaMaterial'

export const CardTitleMaterial: React.FunctionComponent<ICardTitleProps> = (props): JSX.Element => {
  return (
    <CardTitle
      {...props}
      theme={{
        cardTitle: classnames(styles.cardTitle, _.get(props, 'theme.cardTitle')),
        large: classnames(styles.large, _.get(props, 'theme.large')),
        title: classnames(styles.title, _.get(props, 'theme.title')),
        subtitle: classnames(styles.subtitle, _.get(props, 'theme.subtitle')),
      }}
    />
  )
}

CardTitleMaterial.displayName = 'CardTitleMaterial'

export const CardTextMaterial: React.FunctionComponent<ICardTextProps> = (props): JSX.Element => {
  return <CardText {...props} theme={{ cardText: styles.cardText }} />
}

CardTextMaterial.displayName = 'CardTextMaterial'

export const CardActionsMaterial: React.FunctionComponent<ICardActionsProps> = (
  props
): JSX.Element => {
  return <CardActions {...props} theme={{ cardActions: styles.cardActions }} />
}

CardActionsMaterial.displayName = 'CardActionsMaterial'
