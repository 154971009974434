import { makeApiActions } from 'app/frontend/api/api-redux'
import { Action } from 'redux'

export enum LearnAnalyticsActions {
  GetCourseAnalytics = 'GET_LEARN_COURSE_ANALYTICS',
  ApiGetCourseAnalytics = 'API_GET_LEARN_COURSE_ANALYTICS',
}

export const API_GET_LEARN_COURSE_ANALYTICS = makeApiActions(
  LearnAnalyticsActions.ApiGetCourseAnalytics
)

export interface GetLearnCourseAnalyticsAction extends Action {
  type: LearnAnalyticsActions.GetCourseAnalytics
  courseId: string
}

export function getLearnCourseAnalytics(courseId): GetLearnCourseAnalyticsAction {
  return {
    type: LearnAnalyticsActions.GetCourseAnalytics,
    courseId,
  }
}
