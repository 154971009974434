import { makeApiActions } from 'app/frontend/api/api-redux'

export type SUBMIT_JOIN_COURSE_TOKEN = 'SUBMIT_JOIN_COURSE_TOKEN'
export const SUBMIT_JOIN_COURSE_TOKEN: SUBMIT_JOIN_COURSE_TOKEN = 'SUBMIT_JOIN_COURSE_TOKEN'

export const API_SUBMIT_JOIN_COURSE_TOKEN = makeApiActions('API_SUBMIT_JOIN_COURSE_TOKEN')
export type SubmitJoinCourseTokenAction = {
  type: SUBMIT_JOIN_COURSE_TOKEN
  token: string
}

export const submitJoinCourseToken = (token: string): SubmitJoinCourseTokenAction => {
  return {
    type: SUBMIT_JOIN_COURSE_TOKEN,
    token,
  }
}
