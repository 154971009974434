import * as React from 'react'
import { connect } from 'react-redux'
import * as classnames from 'classnames'
import { Box } from 'app/frontend/components/material/box'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './assessment-header.css'
import { quizFlowPreviewRoute } from 'app/frontend/pages/material/learn/routes'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { tns } from 'app/frontend/helpers/translations/i18n'
import {
  AssessmentBuilderModalType,
  AssessmentLoSelectionModalOptions,
  AssessmentSettingsModalOption,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import { AssessmentQuestionLoCountsLoader } from 'app/frontend/pages/material/teach/assessment-builder/assessment-question-lo-counts'
import { QuestionViewPreferences } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences'
import * as Feature from 'app/frontend/helpers/feature'
import { NoticeBox } from 'app/frontend/components/material/notice-box/notice-box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_EDIT,
  TEACH_COURSE_ASSESSMENT_PREVIEW,
  TEACH_COURSE_ASSESSMENT_SETTINGS,
  TEACH_SECTION_ASSESSMENT_EDIT,
  TEACH_SECTION_ASSESSMENT_PREVIEW,
  TEACH_SECTION_ASSESSMENT_SETTINGS,
} from 'app/frontend/data/mixpanel-events'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import { useAssignmentOverride } from 'app/frontend/pages/material/teach/compositions/connected/get-assignment-override'

const t = tns('teach:assessment_header')

interface OwnProps {
  parentEntityType: Commons.ParentEntityType
  parentEntityId: string
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  section: GQL.GetSection.Section
  course: GQL.GetCourse.Course
  assessment: GQL.GetAssignment.Assignment
  hasEditPermission: boolean
}

interface StateProps {}

interface DispatchProps {
  showLoSelectionModal: (options: AssessmentLoSelectionModalOptions) => void
  openSettingsModal: (options: AssessmentSettingsModalOption) => void
}

type Props = OwnProps & StateProps & DispatchProps

export const _AssessmentHeader: React.FunctionComponent<Props> = ({
  parentEntityType,
  parentEntityId,
  showLoSelectionModal,
  isCourseAssignmentOnSection,
  hasStudentStartedAssignment,
  openSettingsModal,
  section,
  course,
  assessment,
  hasEditPermission,
}) => {
  const assessmentId = assessment.id
  const coursepackId = course.coursepackId
  const isShuffled = assessment.quizConfiguration.shuffleQuestions

  let overriddenProperties

  if (section) {
    // fetch assignment section override property details
    const { assignmentOverride } = useAssignmentOverride(
      assessment.id,
      section.id,
      false,
      'cache-and-network'
    )
    overriddenProperties = assignmentOverride
  }

  const onLoEdit = () => {
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_EDIT
        : TEACH_SECTION_ASSESSMENT_EDIT,
      assessmentId
    )
    // show LO selection modal
    showLoSelectionModal({
      assessmentId,
      coursepackId,
      parentEntityType,
      parentEntityId,
    })
  }

  const onPreview = () => {
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_PREVIEW
        : TEACH_SECTION_ASSESSMENT_PREVIEW,
      assessmentId
    )
  }

  const onChangeSettings = () => {
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_SETTINGS
        : TEACH_SECTION_ASSESSMENT_SETTINGS,
      assessmentId
    )

    // open settings modal
    openSettingsModal({
      assessment,
      course,
      section,
      isCourseAssignmentOnSection,
      hasStudentStartedAssignment,
      hasEditPermission,
      overriddenProperties,
    })
  }

  return (
    <div>
      <Box
        direction="row"
        background="light"
        pad={{ vertical: 'small' }}
        separator={['top', 'bottom']}
        justify="center"
        alignItems="center"
      >
        <Box direction="row" full="page" justify="between" alignItems="center">
          <AssessmentQuestionLoCountsLoader
            assessmentId={assessmentId}
            parentEntityId={parentEntityId}
            parentEntityType={parentEntityType}
            showWarnings={true}
          />
          <Box direction="row" pad={{ between: 'medium' }}>
            {Feature.isEnabled('questionOrderingFeatureFlag') && (
              <QuestionViewPreferences
                assessmentId={assessmentId}
                parentEntityType={parentEntityType}
              />
            )}

            <ButtonMaterial
              icon={<Icon name="icon-preview" className={styles.icons} />}
              label={t('label_preview')}
              theme="bordered"
              data-bi="preview-btn"
              href={quizFlowPreviewRoute(assessmentId)}
              target="_blank"
              onClick={onPreview}
            />

            {!hasStudentStartedAssignment && !isCourseAssignmentOnSection && hasEditPermission && (
              <ButtonMaterial
                icon={
                  <Icon name="icon-edit" className={classnames(styles.icons, styles.iconEdit)} />
                }
                label={t('label_edit')}
                onClick={onLoEdit}
                data-bi="edit-btn"
              />
            )}
          </Box>
        </Box>
      </Box>

      {hasStudentStartedAssignment && (
        <NoticeBox iconName="icon-lock">
          <Paragraph className={styles.message} data-test="notice-box-isStudentStartedAssignment">
            {t('students_have_started_warning')}
          </Paragraph>
        </NoticeBox>
      )}

      {(isCourseAssignmentOnSection || !hasEditPermission) && (
        <NoticeBox iconName="icon-lock">
          <Paragraph className={styles.message} data-test="notice-box-uneditable">
            {isCourseAssignmentOnSection
              ? t('edit_course_outline_warning')
              : t('edit_section_level_instructor_warning')}
          </Paragraph>
        </NoticeBox>
      )}

      {Feature.isEnabled('questionOrderingFeatureFlag') && isShuffled && (
        <NoticeBox iconName="icon-lock">
          <Box direction="row" pad={{ between: 'small' }}>
            <Paragraph className={styles.message} data-test="notice-box-isShuffled">
              {t('shuffle_on')}
            </Paragraph>
            <Paragraph
              onClick={onChangeSettings}
              className={classnames(styles.message, styles.clickableMessage)}
              data-test="clickable-text"
            >
              {t('change_settings')}
            </Paragraph>
          </Box>
        </NoticeBox>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  showLoSelectionModal: (options: AssessmentLoSelectionModalOptions) =>
    dispatch(showModal(AssessmentBuilderModalType.LoSelection, options)),
  openSettingsModal: (options: AssessmentSettingsModalOption) =>
    dispatch(showModal(AssessmentBuilderModalType.AssessmentSettings, options)),
})

export const AssessmentHeader = connect<StateProps, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(_AssessmentHeader)
AssessmentHeader.displayName = 'AssessmentHeader'
