/**
 * Youtube Helper.
 */
export const VideoHelper = {
  /**
   * List of callbacks queue while the player is getting loaded.
   */
  callbacks: [],

  /**
   * State of the library (NOT_LOADED, LOADING, LOADED).
   */
  state: 'NOT_LOADED',

  /**
   * Loads the youtube player and calls the callback when is it loaded.
   */
  load: function (callback) {
    // set callback
    if (!window.onYouTubeIframeAPIReady) {
      window.onYouTubeIframeAPIReady = this.onLoaded.bind(this)
    }

    if (this.state === 'NOT_LOADED') {
      // add script to page
      const tag = document.createElement('script')
      const head = document.getElementsByTagName('head')[0]
      tag.src = 'https://www.youtube.com/iframe_api'
      tag.onerror = this.onError.bind(this)
      head.appendChild(tag)

      // update state
      this.state = 'LOADING'
    }

    if (this.state === 'LOADED') {
      callback()
    } else {
      this.callbacks.push(callback)
    }
  },

  /**
   * Called the the script failed to be loaded.
   */
  onError: function () {
    this.state = 'NOT_LOADED'
    console.error('failed to load the youtube script', {
      internalIssueId: 'CE-2297',
    })
  },

  /**
   * Called by the youtube player when loaded.
   */
  onLoaded: function () {
    // update state
    this.state = 'LOADED'

    // call callbacks
    this.callbacks.forEach(callback => callback())
    this.callbacks = []
  },

  /**
   * Gate against bad values suspected to
   * be caused by jQuery UI slider
   *
   * @param {number} percent 0-100
   */
  formatVolume(percent) {
    if (percent > 100) {
      return 100
    } else if (percent < 0) {
      return 0
    }

    return parseInt(percent, 10)
  },

  /**
   * Vimeo setVolume() needs a value of 0-1
   *
   * @param {number} percent 0-100
   */
  formatVolumeForVimeo(percent) {
    return this.formatVolume(percent) / 100
  },
}

export default VideoHelper
