import * as React from 'react'
import { range } from 'lodash'
import * as moment from 'moment'
import * as classnames from 'classnames'
import { MenuItem, Typography } from '@mui/material'
import { FieldSelect } from 'app/frontend/compositions/data/redux-form-fields'
import { TextInputMaterial } from 'app/frontend/components/material/textinput/textinput'
import { Icon } from 'app/frontend/components/material/icon'
import RecaptchaWidget from 'app/frontend/components/material/login/recaptcha-widget'
import { shouldRenderReduxFormError } from 'app/frontend/helpers/validation'
import { t } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { RadioGroup } from 'app/frontend/components/radio-group'
import * as styles from './register.css'

export const Recaptcha = field => <RecaptchaWidget recaptcha={field} />

export const FirstName = field => {
  return (
    <TextInputMaterial
      className={styles.textInput}
      name="firstName"
      label={field.label}
      error={shouldRenderReduxFormError(field.meta)}
      data-pii="true"
      autoComplete="given-name"
      {...field.input}
    />
  )
}

export const LastName = field => {
  return (
    <TextInputMaterial
      className={styles.textInput}
      name="lastName"
      label={field.label}
      error={shouldRenderReduxFormError(field.meta)}
      data-pii="true"
      autoComplete="family-name"
      {...field.input}
    />
  )
}

export const EmailName = field => {
  return (
    <TextInputMaterial
      className={styles.textInput}
      name="email"
      label={field.label}
      error={shouldRenderReduxFormError(field.meta)}
      data-pii="true"
      autoComplete="email"
      {...field.input}
    />
  )
}

export const DisabledEmail = field => {
  return (
    <TextInputMaterial
      className={styles.textInput}
      name="email"
      label={field.label}
      disabled={true}
      error={shouldRenderReduxFormError(field.meta)}
      data-pii="true"
      {...field.input}
    />
  )
}

export const Password = field => {
  return (
    <div>
      <div className={styles.togglePasswordGroup}>
        <TextInputMaterial
          className={styles.textInput}
          name="password"
          label={field.label}
          {...{ ...(field.describedBy && { describedBy: field.describedBy }) }}
          type={field.showPassword ? 'text' : 'password'}
          error={shouldRenderReduxFormError(field.meta)}
          autoComplete="new-password"
          {...field.input}
        />
        <button
          type="button"
          onClick={field.togglePasswordVisibility}
          className={styles.togglePassword}
          aria-label={field.toggleText}
          aria-pressed={field.showPassword}
        >
          <Icon
            name={field.showPassword ? 'icon-visibility_on' : 'icon-visibility_off'}
            ariaHidden
            focusable={false}
            className={classnames(styles.togglePasswordIcon, {
              [styles.togglePasswordActive]: field.showPassword,
            })}
          />
        </button>
      </div>
      {field.meta.active && field.requirementsText && (
        <p id={'passwordRequirements'} tabIndex={-1} className={styles.passwordReqs}>
          {field.requirementsText}
        </p>
      )}
    </div>
  )
}

export const Month: React.FunctionComponent<{
  name: string
  label: string
}> = React.memo(({ name, label }) => (
  <FieldSelect
    MenuProps={{ MenuListProps: { dense: true } }}
    fullWidth
    name={name}
    label={label}
    data-pii={true}
    outerPb={0}
    className={styles.dropdown}
    data-bi="birthday-month"
  >
    {range(1, 13)
      .map(month => month.toString())
      .map(month => (
        <MenuItem key={month} value={month}>
          {month}
        </MenuItem>
      ))}
  </FieldSelect>
))

export const Day: React.FunctionComponent<{
  name: string
  label: string
}> = React.memo(({ name, label }) => (
  <FieldSelect
    MenuProps={{ MenuListProps: { dense: true } }}
    fullWidth
    name={name}
    label={label}
    data-pii={true}
    outerPb={0}
    className={styles.dropdown}
    data-bi="birthday-day"
  >
    {range(1, 32)
      .map(day => day.toString())
      .map(day => (
        <MenuItem key={day} value={day}>
          {day}
        </MenuItem>
      ))}
  </FieldSelect>
))

/**
 * Return birthyears, user must be over 13
 */
export const Year: React.FunctionComponent<{
  name: string
  label: string
}> = React.memo(({ name, label }) => (
  <FieldSelect
    MenuProps={{ MenuListProps: { dense: true } }}
    fullWidth
    name={name}
    label={label}
    data-pii={true}
    outerPb={0}
    className={styles.dropdown}
    data-bi="birthday-year"
  >
    {range(moment().year() - 13, moment().year() - 110)
      .map(year => year.toString())
      .map(year => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
  </FieldSelect>
))

export const Role = field => {
  return (
    <RadioGroup
      name="primary-role"
      label={
        <Typography
          variant="h6"
          component={Paragraph}
          sx={{ color: theme => `${theme.palette?.grey[400]} !important` }}
        >
          {t('registration_page:primary_role')}
        </Typography>
      }
      radioGroupProps={{
        onChange: field.input.onChange,
        value: field.input.value,
      }}
      radioProps={[
        { label: field.studentLabel, value: 'LEARNER' },
        { label: field.instructorLabel, value: 'TEACHER' },
      ]}
      // @ts-ignore
      classes={{ labelRoot: styles.roleRadioBtn }}
    />
  )
}

/** OAuth related hidden form fields */
export const HiddenEmail = field => <input type="hidden" name="email" value={field.input.value} />

export const HiddenGoogleId = field => (
  <input type="hidden" name="googleId" value={field.input.value} />
)

export const HiddenSignature = field => (
  <input type="hidden" name="signature" value={field.input.value} />
)

export const Redirect = field => <input type="hidden" name="redir" value={field.input.value} />
