import * as React from 'react'
import * as classnames from 'classnames'
import { Radio as RadioCore, RadioProps as RadioCoreProps } from '@mui/material'
import { makeStyles, WithStyles } from '@mui/styles'
import { radioStyles } from './styles'

interface OwnProps extends RadioCoreProps {
  name?: string
  label?: string
  value?: string // The value of the radio component
  isSelected?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const useStyles = makeStyles(radioStyles)
type RadioStyles = Partial<WithStyles<typeof radioStyles>>
export type Props = OwnProps & RadioStyles

export const Radio: React.FunctionComponent<Props> = ({
  name,
  value,
  isSelected,
  onChange,
  classes = {},
  ...rest
}) => {
  const styles = useStyles()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event, event.target.checked)
  }

  return (
    <RadioCore
      name={name}
      checked={isSelected}
      value={value}
      onChange={handleChange}
      {...rest}
      classes={{
        root: classnames(styles.root, classes?.root),
        checked: classnames(styles.optionChecked, classes?.optionChecked),
        disabled: classnames(styles.optionDisabled, classes?.optionDisabled),
      }}
      focusVisibleClassName={isSelected ? styles.root : styles.radioVisible}
      disableTouchRipple={!isSelected}
    />
  )
}

Radio.displayName = 'Radio'
