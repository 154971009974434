import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { FilterGroupWrapper } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/content-filters/filter-group-wrapper'
import {
  AssessmentUsageFilter,
  AssignmentUsageFilter,
  FilterGroups,
  SelectionFilter,
  FilterGroupsStateType,
} from 'app/typings/commons'

const t = tns('teach:assessment_lo_selection_modal')

export interface Props {
  appliedFilters: FilterGroupsStateType
  onSelectFilters: (
    selectedFilter: AssignmentUsageFilter | AssessmentUsageFilter | SelectionFilter,
    selectedFilterGroup: FilterGroups
  ) => void
}

export const ContentFilterGroups: React.FunctionComponent<Props> = ({
  appliedFilters,
  onSelectFilters,
}) => (
  <>
    <FilterGroupWrapper
      dataBi="assignment-usage-filter"
      filterGroup={FilterGroups.AssignmentUsage}
      selectedFilter={appliedFilters[FilterGroups.AssignmentUsage]}
      onApply={(value: AssignmentUsageFilter) =>
        onSelectFilters(value, FilterGroups.AssignmentUsage)
      }
      filterOptions={[
        {
          value: AssignmentUsageFilter.All_Assignment,
          label: t('filter_all'),
        },
        {
          value: AssignmentUsageFilter.Assigned,
          label: t('filter_assigned'),
        },
        {
          value: AssignmentUsageFilter.Unassigned,
          label: t('filter_unassigned'),
        },
      ]}
    />

    <FilterGroupWrapper
      dataBi="assessment-usage-filter"
      filterGroup={FilterGroups.AssessmentUsage}
      selectedFilter={appliedFilters[FilterGroups.AssessmentUsage]}
      onApply={(value: AssessmentUsageFilter) =>
        onSelectFilters(value, FilterGroups.AssessmentUsage)
      }
      filterOptions={[
        {
          value: AssessmentUsageFilter.All_Assessment,
          label: t('filter_all'),
        },
        {
          value: AssessmentUsageFilter.Tested,
          label: t('filter_tested'),
        },
        {
          value: AssessmentUsageFilter.Untested,
          label: t('filter_untested'),
        },
      ]}
    />

    <FilterGroupWrapper
      dataBi="selection-filter"
      filterGroup={FilterGroups.Selection}
      selectedFilter={appliedFilters[FilterGroups.Selection]}
      onApply={(value: SelectionFilter) => onSelectFilters(value, FilterGroups.Selection)}
      filterOptions={[
        {
          value: SelectionFilter.All_Selection,
          label: t('filter_all'),
        },
        {
          value: SelectionFilter.Selected,
          label: t('filter_selected'),
        },
        {
          value: SelectionFilter.NotSelected,
          label: t('filter_not_selected'),
        },
      ]}
    />
  </>
)

ContentFilterGroups.displayName = 'ContentFilterGroups'
