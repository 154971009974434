import { Action } from 'redux'
import {
  IStudentAssignmentAnalyticsSortColumns,
  IAnalyticsSortOrder,
  IStudentAssessmentAnalyticsSortColumns,
} from 'app/frontend/data/analytics-data'
import { CompletionType } from 'app/frontend/data/analytics-data'

export enum StudentActions {
  SetAssessmentStatusFilter = 'SET_ASSESSMENT_STATUS_FILTER',
  SetAssessmentAnalyticsSort = 'SET_ASSESSMENT_ANALYTICS_SORT',
  SetAssignmentAnalyticsSort = 'SET_ASSIGNMENT_ANALYTICS_SORT',
  SetCompletionType = 'SET_COMPLETION_TYPE',
}

export type SetAssignmentAnalyticsSort = {
  sortColumn: IStudentAssignmentAnalyticsSortColumns
  sortOrder: IAnalyticsSortOrder
} & Action
export function setAssignmentAnalyticsSort(
  sortColumn: IStudentAssignmentAnalyticsSortColumns,
  sortOrder: IAnalyticsSortOrder
): SetAssignmentAnalyticsSort {
  return {
    type: StudentActions.SetAssignmentAnalyticsSort,
    sortColumn: sortColumn,
    sortOrder: sortOrder,
  }
}

export type SetCompletionType = { completionType: CompletionType | null } & Action
export function setCompletionType(completionType: CompletionType | null): SetCompletionType {
  return {
    type: StudentActions.SetCompletionType,
    completionType,
  }
}

export type SetAssessmentStatusFilter = { filter: string } & Action
export function setAssessmentStatusFilter(filter: string): SetAssessmentStatusFilter {
  return {
    type: StudentActions.SetAssessmentStatusFilter,
    filter: filter,
  }
}

export type SetAssessmentAnalyticsSort = {
  sortColumn: IStudentAssessmentAnalyticsSortColumns
  sortOrder: IAnalyticsSortOrder
} & Action
export function setAssessmentAnalyticsSort(
  sortColumn: IStudentAssessmentAnalyticsSortColumns,
  sortOrder: IAnalyticsSortOrder
): SetAssessmentAnalyticsSort {
  return {
    type: StudentActions.SetAssessmentAnalyticsSort,
    sortColumn: sortColumn,
    sortOrder: sortOrder,
  }
}
