import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { Heading } from 'app/frontend/components/material/heading'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { useForm } from 'app/frontend/compositions/connected/redux-form'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'

import type { useSubmitContentFeedbackParams } from 'compositions/connected/content-feedback'
import { useSubmitContentFeedback } from 'app/frontend/compositions/connected/content-feedback'
import type { ModalOptions } from './types'
import FeedbackEmoji from './feedback-emoji'
import type { FormValues } from './feedback-form'
import FeedbackForm, { CONTENT_FEEDBACK_FORM } from './feedback-form'
import { FeedbackType } from 'app/helpers/common/content-types'
import { hasGqlError } from 'app/frontend/helpers/apollo/error'

const t = tns('feedback')
export const CONTENT_FEEDBACK_MODAL = 'CONTENT_FEEDBACK_MODAL'

interface Props extends ModalOptions {
  hideModal: () => void
}

const FeedbackModalInner: React.FunctionComponent<Props> = ({ hideModal, ...options }) => {
  const { isPristine, isValid, isSubmitting, submit } = useForm(CONTENT_FEEDBACK_FORM)
  const contentFeedbackCb = useSubmitContentFeedback()

  const handleOnSubmitFail = React.useCallback((_errors, dispatch: any, submitError: any) => {
    if (hasGqlError(submitError, { statusCode: 429 })) {
      dispatch(showSnackbar({ message: t('submit_error_rate_limit'), timeout: 2000 }))
    } else {
      dispatch(showSnackbar({ message: t('submit_error_server'), timeout: 2000 }))
    }
  }, [])

  const handleOnSubmitSuccess = React.useCallback(
    (_result, dispatch: any) => {
      hideModal()
      dispatch(showSnackbar({ message: t('submit_success') }))
    },
    [hideModal]
  )

  const handleSubmit = React.useCallback(
    async (data: FormValues) => contentFeedbackCb(modalDataToUseSubmitArgs(options, data)),
    [contentFeedbackCb, options]
  )

  return (
    <>
      <ModalBody>
        <Heading tag="h1" size="h2">
          <FeedbackEmoji category={options.category} />
          <span>{t(options.category)}</span>
        </Heading>
        <Heading tag="h2" size="h5" weight="regular">
          {t(`${options.category}_sub`)}
        </Heading>
        <FeedbackForm
          onSubmit={handleSubmit}
          onSubmitFail={handleOnSubmitFail}
          onSubmitSuccess={handleOnSubmitSuccess}
        />
        <Paragraph data-test="report-warning" id={'descriptionScreenshot'} tabIndex={-1}>
          <strong>{t('please_note')}</strong> {t('report_warning')}
        </Paragraph>
        <Paragraph>{t('realtime_support_info')}</Paragraph>
      </ModalBody>
      <ModalFooter>
        <ButtonMaterial
          onClick={hideModal}
          theme="bordered"
          label={t('cancel')}
          disabled={isSubmitting}
          data-bi="cancel-feedback"
        />
        <ButtonMaterial
          onClick={submit}
          label={t('submit')}
          disabled={isPristine || isSubmitting || !isValid}
          data-bi="submit-feedback"
        />
      </ModalFooter>
    </>
  )
}

export const modalDataToUseSubmitArgs = (
  options: ModalOptions,
  formData: FormValues
): useSubmitContentFeedbackParams => {
  const { atom, compoundInstance, retailClass, enrollment, isQuiz, category, user } = options

  // Sadly, this is not type safe. Once we migrate over to the new service, we should replace
  // the FeedbackType enum with one with matching keys and values.
  let gqlCategory = 'Other' as GQL.ContentFeedbackCategory
  switch (category) {
    case FeedbackType.CannotAnswer:
      gqlCategory = 'CannotAnswer' as GQL.ContentFeedbackCategory
      break
    case FeedbackType.HaveIdea:
      gqlCategory = 'HaveIdea' as GQL.ContentFeedbackCategory
      break
    case FeedbackType.LooksBroken:
      gqlCategory = 'LooksBroken' as GQL.ContentFeedbackCategory
      break
    case FeedbackType.NotRight:
      gqlCategory = 'NotRight' as GQL.ContentFeedbackCategory
      break
  }

  let answer = 'Unanswered'
  if (compoundInstance?.data) {
    answer = `${compoundInstance.data.type}: ${JSON.stringify(
      compoundInstance.data?.answer ??
        compoundInstance.data?.mcAnswer ??
        compoundInstance.data?.mcAnswers
    )}`
  }

  return {
    category: gqlCategory,
    // from the atom
    sequenceId: atom.id.sequenceId,
    atomId: atom.id.atomId,
    variationId: atom.id.variationId,
    isQuizItem: isQuiz,
    isCustomItem: atom.isCustomAssessmentItem,
    learningObjectiveId: atom.learningObjectiveId,
    conceptIds: atom.conceptIds?.join(',') ?? '',
    // from the compound instance
    compoundInstanceId: compoundInstance?.id,
    answer,
    // from the course
    coursepackId: retailClass?.coursepackId,
    courseName: retailClass?.name ?? '',
    // from the enrollment/path
    enrollmentId: enrollment?.id,
    assignmentId: enrollment?.pathId,
    assignmentName: enrollment?.path?.name ?? '',
    goalId: enrollment?.apiGoalId,
    // from the user
    userId: user.id,
    persona: user.roleForUi as GQL.RoleForUiType,
    isTest: user.testing,
    userAgent: navigator.userAgent,
    // from the form/ux
    screenShotDataUri: options.screenShot,
    description: formData.description,
  }
}

export default FeedbackModalInner
