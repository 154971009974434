import * as React from 'react'
import { useState, useEffect } from 'react'
import * as classnames from 'classnames'
import * as styles from './sticky-element.css'
interface Props {
  children: JSX.Element
  stickyCustomStyle?: string
}

const StickyElement: React.FunctionComponent<Props> = ({ children, stickyCustomStyle }) => {
  const [isSticky, setIsSticky] = useState(false)
  const [stickyElOffsetTop, setStickyElOffsetTop] = useState<number | undefined>()
  const [stickyElOffsetHeight, setStickyElOffsetHeight] = useState<number | undefined | null>()

  useEffect(() => {
    const stickyEl = document.getElementById('sticky')
    if (stickyElOffsetTop === undefined) {
      setStickyElOffsetTop(stickyEl.offsetTop)
    }
  })

  useEffect(() => {
    const handleScroll = () => {
      const stickyEl = document.getElementById('sticky')
      if (!isSticky && window.pageYOffset > stickyElOffsetTop) {
        setIsSticky(true)
        setStickyElOffsetHeight(stickyEl.offsetHeight)
      }

      if (isSticky && window.pageYOffset <= stickyElOffsetTop) {
        setIsSticky(false)
        setStickyElOffsetHeight(null)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isSticky, stickyElOffsetTop])

  return (
    <>
      <div
        id="sticky"
        className={classnames(
          {
            [styles.sticky]: isSticky,
          },
          isSticky && stickyCustomStyle
        )}
      >
        {children}
      </div>
      {/* Filler element that takes up the same space when the element was static. */}
      {/* This prevents the "jumpiness" during the sticky transition. */}
      {isSticky && <div style={{ height: stickyElOffsetHeight }} />}
    </>
  )
}
StickyElement.displayName = 'StickyElement'

export default StickyElement
