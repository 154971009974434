import * as React from 'react'
import { Field } from 'redux-form'
import { MenuItem } from '@mui/material'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { FormNames } from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/form-fields/helper'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'
import { isEnabled } from 'app/frontend/helpers/feature'
import { DropdownValues, isGreaterThan, Props, SettingName } from './helper'
import { useStyles } from './styles'
import { SettingFieldWithSelect } from './setting-field-with-select'

const t = tns('teach:assessment_builder')

export const renderCommentSetting = (field: WrappedFieldPropsStub & Props) => {
  const styles = useStyles()
  const { isReadOnly, input, selectedSettingOptions } = field
  const commentsSettingDropdownOptions: React.ReactNode[] = [
    <MenuItem
      key={`${SettingName.COMMENT}-${DropdownValues.IMMEDIATELY}`}
      disabled={isGreaterThan(
        selectedSettingOptions[SettingName.RESULTS],
        DropdownValues.AFTER_SUBMISSION
      )}
      value={DropdownValues.IMMEDIATELY}
      classes={{ root: styles.menuItemRoot }}
      data-bi={DropdownValues.IMMEDIATELY}
    >
      {t('setting_dropdown_immediately')}
    </MenuItem>,
    <MenuItem
      key={`${SettingName.COMMENT}-${DropdownValues.AFTER_DUE_DATE}`}
      disabled={isGreaterThan(
        selectedSettingOptions[SettingName.RESULTS],
        DropdownValues.AFTER_DUE_DATE
      )}
      value={DropdownValues.AFTER_DUE_DATE}
      classes={{ root: styles.menuItemRoot }}
      data-bi={DropdownValues.AFTER_DUE_DATE}
    >
      {t('setting_dropdown_on_due_date')}
    </MenuItem>,
    // TODO: FF cleanup under YALA-1322
    ...(isEnabled('upgradeAssessmentSettingM2Flag') && [
      <MenuItem
        key={`${SettingName.COMMENT}-${DropdownValues.MANUALLY}`}
        disabled={isGreaterThan(
          selectedSettingOptions[SettingName.RESULTS],
          DropdownValues.MANUALLY
        )}
        value={DropdownValues.MANUALLY}
        classes={{ root: styles.menuItemRoot }}
        data-bi={DropdownValues.MANUALLY}
      >
        {t('setting_dropdown_manually')}
      </MenuItem>,
    ]),
  ]

  if (input.value === DropdownValues.NEVER) {
    commentsSettingDropdownOptions.push(
      <MenuItem
        key={`${SettingName.COMMENT}-${DropdownValues.NEVER}`}
        disabled={true}
        value={DropdownValues.NEVER}
        classes={{ root: styles.menuItemRoot }}
        data-bi={DropdownValues.NEVER}
      >
        {t('setting_dropdown_never')}
      </MenuItem>
    )
  }
  const isNeverOptionSelected = selectedSettingOptions[SettingName.RESULTS] === DropdownValues.NEVER
  return (
    <>
      <SettingFieldWithSelect
        header={t('setting_results_comments')}
        description={t('setting_results_comments_description')}
        isReadOnly={isReadOnly || isNeverOptionSelected}
        settingName={SettingName.COMMENT}
        input={input}
        menuItems={commentsSettingDropdownOptions}
      />
      {isNeverOptionSelected && (
        <label className={styles.helperText} data-test="comments-setting-helper-text">
          {t('helperText')}
        </label>
      )}
    </>
  )
}

export const CommentSettingField: React.FunctionComponent<Props> = ({
  isReadOnly,
  selectedSettingOptions,
}) => (
  <Field
    isReadOnly={isReadOnly}
    selectedSettingOptions={selectedSettingOptions}
    name={FormNames.VIEW_RESULTS_COMMENT}
    component={renderCommentSetting}
  />
)
