import { QueryHookOptions, useQuery } from '@apollo/client'
import * as GET_COURSE from './get-course.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseCourseResults = RenderProps<GQL.GetCourse.Query>

export const useCourse = (
  courseId?: string,
  opts?: QueryHookOptions<GQL.GetCourse.Query, GQL.GetCourse.Variables>
): UseCourseResults => {
  const { loading, error, data } = useQuery<GQL.GetCourse.Query, GQL.GetCourse.Variables>(
    GET_COURSE,
    {
      ...opts,
      skip: !courseId || opts?.skip,
      variables: { id: courseId },
    }
  )

  return {
    loading,
    error,
    ...data,
  }
}
