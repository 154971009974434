import ScrollHelper from 'app/frontend/helpers/scroll'
import * as CommonsTypes from 'app/typings/commons'

export const containsVideo = (compound: Learn.IStateAndContent): boolean => {
  const mediaArray = compound.atom.media
  return mediaArray.some(media => media.dataType === 'VIDEO_LOCATION')
}

export const isInstructional = (compound: Learn.IStateAndContent): boolean => {
  const dataType = compound.atom.dataType
  return dataType === 'INSTRUCTIONAL_ATOM'
}

export const getFirstCompoundWithVideo = (compounds: Learn.IStateAndContent[]): string => {
  const compoundState = compounds.find(compound => {
    return compound.atom.media.some(atom => {
      return atom.dataType === 'VIDEO_LOCATION'
    })
  })

  if (compoundState) {
    return compoundState.compoundInstance.id
  }

  return null
}

/**
 * Scroll to the first compound in the learn flow
 * that is not DONE or SKIPPED (or ANSWERED)
 *
 * If the resulting compound is the first on the page
 * (or if no result is found), then scroll to the top
 * of the page
 *
 * Possible states:
 * RECOMMENDED, SHOWN, ANSWERED, DONE, SKIPPED
 */
export type scrollOptions = {
  excludeAnswered?: boolean
  timeout?: number
  jumpToTopByDefault?: boolean
}

export type activeCompoundMetadata = {
  activeIndex: number
  compoundInstanceId: string
}

/**
 * Scroll animate to a compound in the learn flow
 */
export function scrollToCompound(compoundInstanceId: string): void {
  ScrollHelper.scrollTo(`.compound[data-compound-id="${compoundInstanceId}"]`, {
    duration: 500,
  })
}

export function scrollToActiveCompound(
  allCompounds: Learn.IStateAndContent[],
  customOptions: scrollOptions
): void {
  const opts = Object.assign(
    {
      excludeAnswered: false, // do not include answered compounds
      timeout: 0, // how long to wait to scroll
      jumpToTopByDefault: true, // scroll to top if no match
    },
    customOptions
  )
  const { compoundInstanceId, activeIndex } = getActiveCompound(allCompounds, opts)
  if (activeIndex > 0) {
    if (opts.timeout) {
      setTimeout(() => {
        scrollToCompound(compoundInstanceId)
      }, opts.timeout)
    } else {
      scrollToCompound(compoundInstanceId)
    }
  } else if (activeIndex === 0 || opts.jumpToTopByDefault) {
    // If the first active compound is also the first compound overall,
    // then go to top of the page instead.
    ScrollHelper.jumpToTop()
  }
}

export function getActiveCompound(
  allCompounds: Learn.IStateAndContent[],
  opts: Partial<scrollOptions>
): activeCompoundMetadata {
  const blacklistCompounds = opts.excludeAnswered
    ? ['DONE', 'SKIPPED', 'ANSWERED']
    : ['DONE', 'SKIPPED']
  const activeIndex = allCompounds.findIndex(compound => {
    const { state } = compound.compoundInstance
    return !blacklistCompounds.includes(state)
  })
  if (activeIndex >= 0) {
    const compoundInstanceId = allCompounds[activeIndex].compoundInstance.id
    return { compoundInstanceId, activeIndex }
  } else {
    return { compoundInstanceId: 'NOT FOUND', activeIndex: -1 }
  }
}

export enum AssignmentType {
  ADAPTIVE = 'ASSIGNMENT',
  REVIEW = 'REVIEW',
  TEST = 'TEST',
  QUIZ = 'QUIZ',
}

export const getAssessmentType = (path: Commons.IPath): AssignmentType => {
  switch (path.type) {
    case CommonsTypes.AssignmentType.ADAPTIVE:
      return AssignmentType.ADAPTIVE
    case CommonsTypes.AssignmentType.REVIEW:
      return AssignmentType.REVIEW
    case CommonsTypes.AssignmentType.QUIZ:
      switch (path.label) {
        case CommonsTypes.AssessmentLabel.TEST:
          return AssignmentType.TEST
        case CommonsTypes.AssessmentLabel.QUIZ:
          return AssignmentType.QUIZ
      }
  }
}

export const humanizeAssessmentType = (assessmentType: AssignmentType): string => {
  return {
    [AssignmentType.ADAPTIVE]: 'assignment',
    [AssignmentType.REVIEW]: 'review',
    [AssignmentType.TEST]: 'test',
    [AssignmentType.QUIZ]: 'quiz',
  }[assessmentType]
}
