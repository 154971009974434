import { API_RESEND_VERIFICATION_EMAIL } from './email-verification-actions'
import { ApiActions } from 'app/frontend/api/api-redux'

export type EmailVerificationState = {
  isFetching: boolean
  success: boolean
  email: string
  redirection: string
}

const defaultState = {
  isFetching: false,
  success: false,
  // These are set by initial state, do not reset
  email: null,
  redirection: null,
}

export default (
  state: EmailVerificationState = defaultState,
  action: ApiActions<{}>
): EmailVerificationState => {
  switch (action.type) {
    case API_RESEND_VERIFICATION_EMAIL.INIT:
      return {
        ...state,
        isFetching: true,
      }
    case API_RESEND_VERIFICATION_EMAIL.SUCCESS:
      return {
        ...state,
        success: true,
        isFetching: false,
      }
    case API_RESEND_VERIFICATION_EMAIL.ERROR:
    case API_RESEND_VERIFICATION_EMAIL.CANCEL:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}
