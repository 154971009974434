import 'isomorphic-fetch'
import { LogLevel } from 'app/helpers/common/logs'
import { TokenBucket } from 'limiter'
import { fetch } from 'app/frontend/helpers/fetch'

/**
 * Construct a TokenBucket for rate limiting our logs
 */
const makeLogLimiter = (): TokenBucket => {
  const bucket = new TokenBucket(100, 10, 'minute')
  // Token bucket defaults to empty, so we force it to be full a not exposed API.
  // @ts-ignore: Property 'content' does not exist on type TokenBucket
  bucket.content = 100
  return bucket
}

/**
 * rate-limit on the client to prevent our app from uncontrollably sending megabytes of data
 * to the backend in case of an error, or some debug logging we've accidentally enabled.
 *
 * Using the TokenBucket allows us to ingest a burst of 100 log messages from the user in a given
 * minute. After a significant burst, the bucket will replenish at 10 messages a minute.
 * These numbers are fairly arbitrary.
 *
 * Why are we limited the number of messages instead of the number of bytes? It's easier.
 */
let LOG_LIMITER = makeLogLimiter()

/**
 * List of logs queued to report.
 * Avoids spamming the API by efficiently sending logs in a batch, while maintaining order.
 */
let LOG_QUEUE: Logs.Log[] = []

/**
 * Total number of logs processed by the page.
 * Each log is sequentially numbered to make it obvious when logs are dropped due to infra bugs or
 * rate-limiting.
 */
let NUM_LOGS = 0

/**
 * Timeout that will report logs.
 */
let REPORT_TIMEOUT = null

export const debug = (source: string, content: any) => {
  report(LogLevel.Debug, source, content)
}

export const info = (source: string, content: any) => {
  report(LogLevel.Info, source, content)
}

export const warn = (source: string, content: any) => {
  report(LogLevel.Warn, source, content)
}

export const error = (source: string, content: any) => {
  report(LogLevel.Error, source, content)
}

/**
 * Called to report any log
 */
const report = (level: LogLevel, source: string, content: any) => {
  NUM_LOGS++
  try {
    if (LOG_LIMITER.tryRemoveTokens(1)) {
      LOG_QUEUE.push({
        level,
        source,
        content: normalizeContent(content),
        lognum: NUM_LOGS,
      })
      reportEventually()
    }
  } catch (e) {
    // warn on failure to report log.
    console.warn('failed to report log', e)
  }
}

/**
 * Some JavaScript objects cannot be serialized to JSON. This function converts those objects
 * into JSON.stringify friendly object.
 */
export const normalizeContent = (content: any): any => {
  if (content instanceof Error) {
    return normalizeError(content)
  } else if (Array.isArray(content)) {
    return content.map(item => {
      return item instanceof Error ? normalizeError(item) : item
    })
  } else {
    return content
  }
}

/**
 * Converts an error object into a serializable object.
 */
const normalizeError = (err: Error): any => {
  return {
    message: err.message,
    stack: err.stack,
  }
}

/**
 * Creates the thread that will report the log in 1 second if it wasn't already created.
 */
export const reportEventually = () => {
  if (!REPORT_TIMEOUT) {
    REPORT_TIMEOUT = setTimeout(reportTimeout, 1000)
  }
}

export const getLogQueue = () => {
  return LOG_QUEUE
}

export const reportTimeout = () => {
  try {
    // Clear the reference to the thread so that any new call will create a new thread.
    REPORT_TIMEOUT = null

    // Get all the info logs and clear the queue.
    const payload: Logs.LogRequest = {
      version: window.context.buildNum,
      logs: LOG_QUEUE,
    }
    LOG_QUEUE = []

    // send data
    fetch('/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
  } catch (e) {
    // Warn failed to send logs
    console.warn('Failed to send logs', e)
  }
}

/**
 * Reset this module to the initial state.
 * Use for testing only.
 */
export const reset = () => {
  LOG_QUEUE = []
  NUM_LOGS = 0
  LOG_LIMITER = makeLogLimiter()
}
