import { Reducer } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  GetEnrollmentGradeAction,
  API_GET_ENROLLMENT_GRADE,
} from 'app/frontend/pages/material/learn/learn-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export const getGradeByEnrollmentId = (
  state: LearnControllerState,
  enrollmentId: string
): Partial<Commons.IEnrollmentGrade> => {
  return state.entities.grades.byEnrollmentId[enrollmentId]
    ? state.entities.grades.byEnrollmentId[enrollmentId]
    : {}
}

export interface GradesState {
  byEnrollmentId: {
    [enrollmentId: string]: Commons.IEnrollmentGrade
  }
}

export const reducer = (
  state: Partial<GradesState> = { byEnrollmentId: {} },
  action: GetEnrollmentGradeAction & ApiSuccessAction
): Partial<GradesState> => {
  if (!action) {
    return state
  }
  switch (action.type) {
    case API_GET_ENROLLMENT_GRADE.SUCCESS:
      const enrollmentId = action.args[0]
      return {
        byEnrollmentId: {
          ...state.byEnrollmentId,
          [enrollmentId]: action.response,
        },
      }
    default:
      return state
  }
}

export default reducer as Reducer<GradesState>
