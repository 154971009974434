import * as React from 'react'
import * as moment from 'moment'
import * as keycode from 'keycode'
import * as classnames from 'classnames'
import { Autocomplete, TextField } from '@mui/material'
import { DateTimeFormats } from 'app/helpers/timezone'
import { getFocusIndex } from 'app/frontend/components/pickers/timepicker/helpers'
import { useTimePicker } from './use-timepicker'
import { useStyles } from './styles'
import { defaultTimeFormatRegex } from '../helpers'

export type Props = {
  value: moment.Moment | undefined
  timepickerId: string
  onChange?: (val: moment.Moment) => void
  label: string
  timeFormat?: DateTimeFormats
  timeFormatRegex?: RegExp
  disabled?: boolean
  defaultTime?: string
  inputStyle?: string
  accept24h?: boolean
  inputRef?: any
  error?: string
  setError?: (text: string) => void
  fullWidth?: boolean
  variant?: 'filled' | 'standard' | 'outlined'
  timeRestrictedValue?: string
}

export const Timepicker: React.FunctionComponent<Props> = ({
  label,
  value,
  timeFormat = DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE,
  timeFormatRegex = defaultTimeFormatRegex,
  onChange,
  disabled,
  defaultTime,
  inputStyle,
  accept24h = true,
  inputRef,
  timepickerId,
  error,
  setError,
  fullWidth = true,
  variant = 'standard',
  timeRestrictedValue,
}) => {
  const styles = useStyles()
  const [isPopupOpen, setPopupOpen] = React.useState(false)

  const {
    optionsArray,
    inputTime,
    setTime,
    handleBlur,
    error: timeError,
    handleFocus,
    focusIndex,
    handleChange,
  } = useTimePicker({
    value,
    timeFormat,
    timeFormatRegex,
    onChange,
    defaultTime,
    accept24h,
    isPopupOpen,
    setError,
  })

  const handleKeyDown = e => {
    if (keycode(e.keyCode) === 'esc') {
      setTime(value.format(timeFormat))
      return setPopupOpen(false)
    }
    if (keycode(e.keyCode) === 'enter') {
      handleBlur()
      return setPopupOpen(false)
    }
    if (!isPopupOpen) {
      setPopupOpen(true)
    }
  }

  const disableTimepicker = disabled || !value

  const timeRestrictedOptions =
    timeRestrictedValue &&
    optionsArray.filter(elem => moment(elem, 'h:mm A') >= moment(timeRestrictedValue, 'h:mm A'))

  let newFocusIndex =
    timeRestrictedValue && getFocusIndex(moment(value).format(timeFormat), timeRestrictedOptions)
  if (newFocusIndex === -1) {
    newFocusIndex = 0
  }
  // Change the order of the dropdown, so the top of the list is the closest to the user's input
  const timeOptions = timeRestrictedValue
    ? [
        ...timeRestrictedOptions.slice(newFocusIndex),
        ...timeRestrictedOptions.slice(0, newFocusIndex),
      ]
    : [...optionsArray.slice(focusIndex), ...optionsArray.slice(0, focusIndex)]

  return (
    <>
      <Autocomplete
        id={timepickerId}
        classes={{
          root: styles.root,
          popper: styles.popper,
          option: styles.option,
          paper: styles.paper,
          listbox: styles.listbox,
        }}
        defaultValue={defaultTime}
        disabled={disableTimepicker}
        value={inputTime}
        onFocus={() => !disableTimepicker && setPopupOpen(true)}
        onBlur={() => setPopupOpen(false)}
        open={!disableTimepicker && isPopupOpen}
        renderInput={params => (
          <>
            <TextField
              {...params}
              error={!!(error || timeError)}
              value={inputTime}
              disabled={disableTimepicker}
              onChange={e => setTime(e.target.value)}
              label={label}
              inputRef={inputRef}
              className={classnames(styles.input, inputStyle)}
              fullWidth={fullWidth}
              InputLabelProps={{
                ...params.InputLabelProps,
                classes: {
                  root: classnames(styles.label, variant !== 'standard' && styles.variantLabel),
                },
              }}
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: classnames(
                    styles.inputRoot,
                    variant && variant !== 'standard' && styles.variantInputRoot
                  ),
                  underline: variant === 'standard' && styles.underline,
                },
              }}
              onClick={() => !disableTimepicker && setPopupOpen(true)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              variant={variant as any}
            />
            {!!(error || timeError) && (
              <span
                className={classnames(styles.error, variant !== 'standard' && styles.variantError)}
                role="alert"
              >
                {error || timeError}
              </span>
            )}
          </>
        )}
        options={timeOptions}
        filterOptions={options => options}
        freeSolo={true}
        disableClearable={true}
        onChange={(_e: React.SyntheticEvent<HTMLOptionElement>, val) => {
          setPopupOpen(false)
          handleChange(val)
        }}
      />
    </>
  )
}
