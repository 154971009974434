import { makeApiActions } from 'app/frontend/api/api-redux'

export enum QuizFlowActionType {
  UnlockQuiz = 'UNLOCK_QUIZ',
  SaveChangedAnswers = 'SAVE_CHANGED_ANSWERS',
  StartQuiz = 'START_QUIZ',
  SubmitQuiz = 'SUBMIT_QUIZ',
  GetServerTime = 'GET_SERVER_TIME',
  AnswerQuizQuestion = 'ANSWER_QUIZ_QUESTION',
  LeaveQuiz = 'LEAVE_QUIZ',
  ForfeitAttempts = 'FORFEIT_ATTEMPTS',
  ViewUnansweredQuestions = 'VIEW_UNANSWERED_QUESTIONS',
  ClearEventQueue = 'CLEAR_EVENT_QUEUE',
  SaveQuizAttemptNumber = 'SAVE_QUIZ_ATTEMPT_NUMBER',
}

export const API_GET_QUIZ = makeApiActions('API_GET_QUIZ')
export const API_UNLOCK_QUIZ = makeApiActions('API_UNLOCK_QUIZ')
export const API_START_QUIZ = makeApiActions('API_START_QUIZ')
export const API_COMPLETE_QUIZ = makeApiActions('API_COMPLETE_QUIZ')
export const API_GET_SERVER_TIME = makeApiActions('API_GET_SERVER_TIME')
export const API_GET_QUIZ_PREVIEW = makeApiActions('API_GET_QUIZ_PREVIEW')
export const API_IS_BEFORE_DUE = makeApiActions('API_IS_BEFORE_DUE')
export const API_MARK_ENROLLMENT_AS_COMPLETE = makeApiActions('API_MARK_ENROLLMENT_AS_COMPLETE')

export type UnlockQuizAction = {
  type: QuizFlowActionType.UnlockQuiz
  quizId: string
  password: string
}
export const unlockQuiz = (quizId: string, password: string): UnlockQuizAction => {
  return {
    type: QuizFlowActionType.UnlockQuiz,
    quizId,
    password,
  }
}

export type SaveChangedAnswersAction = {
  type: QuizFlowActionType.SaveChangedAnswers
  quizId: string
}
export const saveChangedAnswers = (quizId: string): SaveChangedAnswersAction => {
  return {
    type: QuizFlowActionType.SaveChangedAnswers,
    quizId,
  }
}

export type StartQuizAction = {
  type: QuizFlowActionType.StartQuiz
  quizId: string
  password: string
}
export const startQuiz = (quizId: string, password: string): StartQuizAction => {
  return {
    type: QuizFlowActionType.StartQuiz,
    quizId,
    password,
  }
}

export type SubmitQuizAction = {
  type: QuizFlowActionType.SubmitQuiz
  quizId: string
  timeUp: boolean
}
export const submitQuiz = (quizId: string, timeUp?: boolean): SubmitQuizAction => {
  return {
    type: QuizFlowActionType.SubmitQuiz,
    quizId,
    timeUp: timeUp || false,
  }
}

export type GetServerTimeAction = {
  type: QuizFlowActionType.GetServerTime
}
export const getServerTime = (): GetServerTimeAction => {
  return {
    type: QuizFlowActionType.GetServerTime,
  }
}

export type AnswerQuizAction = {
  type: QuizFlowActionType.AnswerQuizQuestion
  quizId: string
  answerEvent: Learn.IEvent
}
export const answerQuizQuestion = (quizId: string, answerEvent: Learn.IEvent) => {
  return {
    type: QuizFlowActionType.AnswerQuizQuestion,
    quizId,
    answerEvent,
  }
}

export type LeaveQuiz = {
  type: QuizFlowActionType.LeaveQuiz
  assignmentId: string
}
export const leaveQuiz = (assignmentId: string) => {
  return {
    type: QuizFlowActionType.LeaveQuiz,
    assignmentId,
  }
}

export type ForfeitAttemptsAction = {
  type: QuizFlowActionType.ForfeitAttempts
  courseId: string
  assessmentId: string
  attemptNumberToView: number
}
export const forfeitAttempts = (
  courseId: string,
  assessmentId: string,
  attemptNumberToView: number
): ForfeitAttemptsAction => {
  return {
    type: QuizFlowActionType.ForfeitAttempts,
    courseId,
    assessmentId,
    attemptNumberToView,
  }
}

export type ViewUnansweredQuestionsAction = {
  type: QuizFlowActionType.ViewUnansweredQuestions
}
export const viewUnansweredQuestions = (): ViewUnansweredQuestionsAction => {
  return {
    type: QuizFlowActionType.ViewUnansweredQuestions,
  }
}

export type clearEventQueueAction = {
  type: QuizFlowActionType.ClearEventQueue
  quizId: string
}
export const clearEventQueue = (quizId: string): clearEventQueueAction => {
  return {
    type: QuizFlowActionType.ClearEventQueue,
    quizId,
  }
}

export type SaveQuizAttemptNumberAction = {
  type: QuizFlowActionType.SaveQuizAttemptNumber
  quizId: string
  attemptNumber: number
}
export const saveQuizAttemptNumber = (
  quizId: string,
  attemptNumber: number
): SaveQuizAttemptNumberAction => {
  return {
    type: QuizFlowActionType.SaveQuizAttemptNumber,
    quizId,
    attemptNumber,
  }
}
