import { Location } from 'history'
import { LearnRouteParams } from 'app/frontend/pages/material/learn/learn-routes'

export enum UiActionType {
  ComponentLoad = 'COMPONENT_LOAD',
  SetActiveAssignment = 'SET_ACTIVE_ASSIGNMENT',
}

export type ComponentLoadAction = {
  type: UiActionType.ComponentLoad
  name: string
  routerLocation?: Location
  routerParams?: LearnRouteParams
}

// To be triggered by a component's lifecycle method
export const componentLoad = (
  name: string,
  routerLocation: any,
  routerParams: any
): ComponentLoadAction => {
  return {
    type: UiActionType.ComponentLoad,
    name,
    routerLocation,
    routerParams,
  }
}

export type SetActiveAssignmentAction = {
  type: UiActionType.SetActiveAssignment
  activeAssignmentId: string | null
}

export const setActiveAssignment = (activeAssignmentId: string | null) => {
  return {
    type: UiActionType.SetActiveAssignment,
    activeAssignmentId,
  }
}

export enum SetIsNewWileyPlus {
  actionType = 'SET_IS_NEW_WILEY_PLUS',
}
export type SetIsNewWileyPlusAction = {
  type: SetIsNewWileyPlus.actionType
  isNewWileyPlus: boolean
}
export const setIsNewWileyPlus = (isNewWileyPlus: boolean): SetIsNewWileyPlusAction => ({
  type: SetIsNewWileyPlus.actionType,
  isNewWileyPlus,
})

export enum SetIsAnLms {
  actionType = 'SET_IS_LMS_AN_LMS',
}
export type SetIsAnLmsAction = {
  type: SetIsAnLms.actionType
  isAnLms: boolean
}
export const setIsAnLms = (isAnLms: boolean): SetIsAnLmsAction => ({
  type: SetIsAnLms.actionType,
  isAnLms,
})
