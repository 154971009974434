import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as _ from 'lodash'
import * as classnames from 'classnames'
import { IconButton, MenuItem, MenuList } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { MenuDividerMaterial } from 'app/frontend/components/material/menu'
import { PopperMenu } from 'app/frontend/components/popper-menu/popper-menu'
import NavActions from 'app/frontend/layout/material/nav-actions'
import { paymentBillingPortalOpen } from 'app/frontend/pages/material/learn/modals/payments/actions'
import * as CurrentUser from 'app/frontend/helpers/current-user'
import { redirect } from 'app/frontend/helpers/redirect'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { theme } from 'app/frontend/theme/theme'
import Permission from 'app/helpers/common/permissions'
import { makeStyles } from '@mui/styles'
import * as styles from './nav-app-menu.css'

const t = tns('nav')

type NavAppMenuTheme = 'dark' | 'light'

interface INavAppMenuProps {
  navAppMenuTheme?: NavAppMenuTheme
  useGlobalNavConfig?: boolean
  onClick: () => void
}

const useStyles = makeStyles(() => ({
  popper: {
    padding: '0rem',
    '&:focus': {
      outlineColor: '#064560 !important',
    },
  },
}))

const NavAppMenu: React.FunctionComponent<INavAppMenuProps> = props => {
  const { onClick, navAppMenuTheme, useGlobalNavConfig } = props

  const menuStyle = useStyles()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const isChargebeeInited = useSelector((state: any) =>
    _.get(state, 'ui.payments.chargebeeInit', false)
  )
  const inIFrame = useSelector((state: any) => _.get(state, 'global.ui.inIFrame', false))

  const dispatch = useDispatch()
  const onHide = () => dispatch(NavActions.toggleNavMenu('HIDE'))
  const onBillingClick = () => dispatch(paymentBillingPortalOpen())

  const openInNewTab = (url: string) => {
    window.open(url, '_blank')
  }

  const originalUser = CurrentUser.getLoginAsOriginalUser()
  const currentUser = CurrentUser.get()

  const handleButtonClick = () => {
    setOpen(prevOpen => !prevOpen)
    if (onClick) {
      onClick()
    }
  }

  const handleMenuHide = () => {
    setOpen(false)
    if (onHide) {
      onHide()
    }
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
      anchorRef.current?.focus()
    } else if (event.key === 'Escape') {
      setOpen(false)
      anchorRef.current?.focus()
    }
  }

  return (
    <div
      className={classnames({
        [styles.accountMenu]: !useGlobalNavConfig,
        [styles.accountMenuGlobalNav]: useGlobalNavConfig,
      })}
    >
      <IconButton
        data-bi="account-icon"
        ref={anchorRef}
        aria-label={'toggle user account menu'}
        aria-expanded={open}
        aria-haspopup="true"
        onClick={handleButtonClick}
        className={menuStyle.popper}
      >
        <AccountCircleIcon
          name="icon-account"
          className={styles[`menuIcon-${navAppMenuTheme || 'dark'}`]}
        />
      </IconButton>
      <PopperMenu
        open={open}
        anchorRef={anchorRef}
        handleClose={handleMenuHide}
        placement="bottom-end"
        styles={{
          backgroundColor: theme.palette?.common.white,
        }}
      >
        <MenuList role="presentation" autoFocusItem={true} onKeyDown={handleListKeyDown}>
          {useGlobalNavConfig &&
            (CurrentUser.canViewKnerdTools() || CurrentUser.canViewTeachAndLearn()) && [
              <MenuItem
                key={'student'}
                onClick={() => redirect('/learn')}
                data-bi="student-menu-item"
                tabIndex={0}
              >
                {t('student')}
              </MenuItem>,
              <MenuItem
                key={'instructor'}
                onClick={() => redirect('/teach')}
                data-bi="instructor-menu-item"
                tabIndex={0}
              >
                {t('instructor')}
              </MenuItem>,
              CurrentUser.canViewKnerdTools() && (
                <MenuItem
                  key={'knerdtools'}
                  onClick={() => redirect('/knerdtools')}
                  data-bi="knerdtools-menu-item"
                  tabIndex={0}
                >
                  {t('knerd')}
                </MenuItem>
              ),
              <MenuDividerMaterial key={'divider'} />,
            ]}
          {CurrentUser.isInstitutionAdmin() && (
            <MenuItem onClick={() => redirect('/institution')} data-bi="institution-menu-item">
              {t('my_institution')}
            </MenuItem>
          )}
          <MenuItem onClick={() => redirect('/account')} data-bi="account-menu-item">
            {t('my_account')}
          </MenuItem>
          <MenuItem
            onClick={onBillingClick}
            data-bi="billing-menu-item"
            disabled={!isChargebeeInited}
            className="billing-option"
          >
            {t('billing')}
            <span className={styles.billingLinkDetails}>- {t('opens_a_dialog')}</span>
          </MenuItem>
          <MenuDividerMaterial />
          <MenuItem
            onClick={() => openInNewTab('https://support.knewton.com/')}
            data-bi="help-menu-item"
          >
            {t('help')}
          </MenuItem>
          <MenuItem
            onClick={() => openInNewTab('https://status.knewton.com/')}
            data-bi="status-menu-item"
          >
            {t('status')}
          </MenuItem>
          {(CurrentUser.hasAnyPermission([Permission.SUPPLEMENT_READ]) ||
            CurrentUser.canViewKnerdTools()) && (
            <MenuItem
              data-bi="supplements-menu-item"
              onClick={() => openInNewTab('https://bit.ly/KnewtonSupplements')}
            >
              {t('supplements')}
            </MenuItem>
          )}
          <MenuItem onClick={() => openInNewTab('/privacy-policy/')} data-bi="privacy-menu-item">
            {t('privacy')}
          </MenuItem>
          <MenuItem onClick={() => openInNewTab('/terms-of-service/')} data-bi="terms-menu-item">
            {t('terms')}
          </MenuItem>
          <MenuItem
            onClick={() => openInNewTab('https://www.knewton.com/accessibility')}
            data-bi="accessibility-menu-item"
          >
            {t('accessibility')}
          </MenuItem>
          {!inIFrame &&
            currentUser && [
              <MenuDividerMaterial key={'divider'} />,
              <MenuItem
                key={'sign-out'}
                onClick={() =>
                  CurrentUser.isDistributor()
                    ? redirect('/logout?userType=distributor')
                    : redirect('/logout')
                }
                data-bi="sign-out-menu-item"
                tabIndex={0}
              >
                {t('sign_out')}
              </MenuItem>,
            ]}
          {originalUser && [
            <MenuDividerMaterial key={'divider'} />,
            <MenuItem
              key={'sign-out-as'}
              data-bi="sign-out-as-menu-item"
              onClick={() => redirect('/login_as/disconnect')}
            >
              {t('sign_out_as')}
            </MenuItem>,
          ]}
        </MenuList>
      </PopperMenu>
    </div>
  )
}

export default NavAppMenu
