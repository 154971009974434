import * as React from 'react'
import { RenderProps, withLoadingAndError } from 'app/frontend/helpers/apollo/adopt'
import { countBy, memoize } from 'lodash'
import { TopicLoMap } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import {
  useAssignment,
  UseAssignmentResults,
} from 'app/frontend/pages/material/teach/compositions/connected/get-assignment'
import {
  useTableOfContentsForCoursepack,
  UseTableOfContentsForCoursepackResults,
} from 'app/frontend/compositions/connected/get-table-of-contents-for-coursepack/use-table-of-contents-for-coursepack'
import {
  useHasStudentStartedAssignment,
  UseHasStudentStartedAssignmentResults,
} from 'app/frontend/pages/material/teach/compositions/connected/has-student-started-assignment'
import {
  useAssignedLearningObjectives,
  UseAssignedLearningObjectivesResults,
} from 'app/frontend/pages/material/teach/compositions/connected/get-assigned-learning-objectives/use-assigned-learning-objectives'
import {
  useAssessmentSequences,
  UseAssessmentSequencesResults,
} from 'app/frontend/compositions/connected/get-assessment-sequences'
import {
  useAssessmentQuestionsByConceptByLo,
  AssessmentQuestionsByConceptByLo,
} from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-questions-by-concept-by-lo'
import { LearningObjectivesToAssignmentsMap } from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id.ts'

export type ChildrenProps = RenderProps<{
  titles: GQL.TitleTaxonFields.Fragment[]
  assignmentsByLoId: LearningObjectivesToAssignmentsMap
  assessmentId: string
  persistedSelectedLosByTopic: TopicLoMap
  numSequencesByLo: { [loId: string]: number }
  hasStudentStartedAssessment: boolean
  assessmentQuestionsByConceptByLo: AssessmentQuestionsByConceptByLo
  parentEntityType: Commons.ParentEntityType
}>

export type Props = {
  assessmentId: string
  coursepackId: string
  parentEntityType: Commons.ParentEntityType
  parentEntityId: string
  children: (props: ChildrenProps) => JSX.Element
}

const getTopicLoMapFromTopicLos = memoize(
  (topicLos: GQL.AssessmentFields.PathLearningObjectives[]) => {
    return topicLos.reduce((soFar, lo) => {
      const losForTopicSoFar = soFar[lo.topicId] || new Set()
      losForTopicSoFar.add(lo.learningObjectiveId)
      soFar[lo.topicId] = losForTopicSoFar
      return soFar
    }, {} as TopicLoMap)
  }
)

export type QueryResults = {
  tableOfContentsResults?: UseTableOfContentsForCoursepackResults
  assignedLoResults?: UseAssignedLearningObjectivesResults
  assessmentResults?: UseAssignmentResults
  assessmentSequencesResults?: UseAssessmentSequencesResults
  hasStudentStartedAssessmentResults?: UseHasStudentStartedAssignmentResults
  assessmentQuestionsByConceptByLoResults?: AssessmentQuestionsByConceptByLo
}

export const mapProps = ({
  tableOfContentsResults,
  assignedLoResults,
  assessmentResults,
  assessmentSequencesResults,
  hasStudentStartedAssessmentResults,
  assessmentQuestionsByConceptByLoResults,
  loading,
  error,
}: RenderProps<QueryResults>) => {
  const assessment = assessmentResults && assessmentResults.assignment
  return {
    titles: tableOfContentsResults && tableOfContentsResults.titles,
    assignmentsByLoId: assignedLoResults && assignedLoResults.assignmentsByLearningObjectiveId,
    persistedSelectedLosByTopic:
      assessment &&
      assessment.pathLearningObjectives &&
      getTopicLoMapFromTopicLos(assessment.pathLearningObjectives),
    numSequencesByLo:
      assessmentSequencesResults &&
      assessmentSequencesResults.assessmentSequences &&
      countBy(assessmentSequencesResults.assessmentSequences, s => s.learningObjectiveId),
    hasStudentStartedAssessment:
      hasStudentStartedAssessmentResults &&
      hasStudentStartedAssessmentResults.hasStudentStartedAssignment,
    assessmentQuestionsByConceptByLo: assessmentQuestionsByConceptByLoResults || {},
    loading,
    error,
  }
}

/**
 * Query composition to retrieve the data needed for the assessment table of contents LO selection component
 */
export const GetAssessmentTableOfContentsData: React.FunctionComponent<Props> = props => {
  const { assessmentId, coursepackId, parentEntityType, parentEntityId, children } = props
  const resultProps = withLoadingAndError(mapProps)({
    tableOfContentsResults: useTableOfContentsForCoursepack(coursepackId),
    assignedLoResults: useAssignedLearningObjectives(
      parentEntityId,
      parentEntityType,
      'cache-and-network'
    ),
    assessmentResults: useAssignment(assessmentId),
    assessmentSequencesResults: useAssessmentSequences(assessmentId),
    hasStudentStartedAssessmentResults: useHasStudentStartedAssignment(assessmentId),
    // We dont want the loading and error state because that will cause the AssessmentTableOfContentsLoader
    // to go into a loading state any time a question is added or removed
    assessmentQuestionsByConceptByLoResults: useAssessmentQuestionsByConceptByLo(assessmentId)
      .results,
  })

  return <>{children({ ...props, ...resultProps })}</>
}

GetAssessmentTableOfContentsData.displayName = 'GetAssessmentTableOfContentsData'
