import * as React from 'react'
import { InjectedFormProps } from 'redux-form'
import PasswordField from 'app/frontend/components/material/password-field'
import * as styles from './reset-fields.css'
import { t } from 'i18next'

export const Password: React.FunctionComponent<InjectedFormProps> = field => (
  <div>
    <PasswordField field={field} />
    {field.meta.active && field.displayRequirements && (
      <p id={'passwordRequirements'} tabIndex={-1} className={styles.passwordReqs}>
        {t('account_edit_page:password_requirements')}
      </p>
    )}
  </div>
)

export const HiddenField: React.FunctionComponent<InjectedFormProps> = field => (
  <input type="hidden" value={field.input.value} />
)
