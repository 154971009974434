import * as React from 'react'
import Content from 'app/frontend/components/content'
import * as classNames from 'classnames'
import { MultiChoiceAnswers } from './multi-choice-answers'

const atomStyles = require('app/frontend/components/atom/atom.css')
const styles = require('./multi-choice.css')

export interface Props {
  atom: GQL.Atom
  hideChoiceExplanation?: boolean
  hideGeneralExplanation?: boolean
  hideCorrectAnswers?: boolean
}

export const MultiChoiceContent: React.FunctionComponent<Props> = ({
  atom,
  hideChoiceExplanation,
  hideGeneralExplanation,
  hideCorrectAnswers,
}) => {
  const { media } = atom,
    data = atom.data as GQL.AtomMultiChoiceBlock

  return (
    <div data-test="multiple-choice">
      <Content
        className={classNames(atomStyles.question, atomStyles.userContent)}
        html={data.question}
        media={media}
      />
      {data.answers && (
        <MultiChoiceAnswers
          answers={data.answers}
          media={media}
          isMultiAnswer={data.isMultiAnswer}
          hideExplanation={hideChoiceExplanation}
          hideCorrectAnswers={hideCorrectAnswers}
        />
      )}
      {data.generalExplanation && !hideGeneralExplanation && (
        <div
          data-test="multi-choice-general-explanation"
          className={atomStyles['general-explanation-wrapper']}
        >
          <h5>General explanation</h5>
          <Content
            className={classNames(styles.generalExplanation, atomStyles.userContent)}
            html={data.generalExplanation}
            media={media}
          />
        </div>
      )}
    </div>
  )
}
MultiChoiceContent.displayName = 'MultiChoiceContent'
