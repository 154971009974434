import * as React from 'react'
import { find } from 'lodash'
import { ContentMetrics } from './metrics'
import type { State, Action } from './context'
import { StatusDispatchContext, StatusStateContext } from './context'

const reducer = (state: State, action: Action): State => {
  const current = find(state.status, status => status._id === action.id) ?? {
    loading: true,
    errors: [],
    warnings: [],
    _id: action.id,
    debug: {
      type: 'OTHER',
    },
  }

  switch (action.type) {
    case 'ADD_WARNING':
      return {
        ...state,
        status: [
          {
            ...current,
            warnings: [...current.warnings, action.value],
          },
          ...state.status.filter(status => status._id !== action.id),
        ],
      }
    case 'ADD_ERROR':
      return {
        ...state,
        status: [
          {
            ...current,
            loading: false,
            errors: [...current.errors, action.value],
          },
          ...state.status.filter(status => status._id !== action.id),
        ],
      }
    case 'INITIALIZE':
      // Does *not* move a piece of content back to loading
      return {
        ...state,
        status: [
          {
            ...current,
            debug: action.details,
          },
          ...state.status.filter(status => status._id !== action.id),
        ],
      }
    case 'SUCCESS':
      return {
        ...state,
        status: [
          {
            ...current,
            loading: false,
          },
          ...state.status.filter(status => status._id !== action.id),
        ],
      }
    default:
      return state
  }
}

export interface Props {
  atom: Content.IAtom
  renderLocation: Content.AtomRenderLocation
}

/**
 * Acts as an aggregator of the status of any descendant content loading. All descendant components
 * that asynchronously load should consume the StatusDispatchContext to propogate status changes
 * back up the tree.
 *
 * Any descendant components that wish to render said statuses should consume from
 * StatusStateContext.
 *
 * This component should be wrapped around a single atom and will report out metrics as the
 * atom loads.
 *
 * An Error Boundary *must* be used to catch any errors thrown by descendant components to prevent
 * full page crashes.
 */
const ContentManagerProvider: React.FunctionComponent<Props> = ({
  atom,
  renderLocation,
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    status: [],
    atom,
    renderLocation,
  })
  return (
    <StatusDispatchContext.Provider value={dispatch}>
      <StatusStateContext.Provider value={state}>
        <ContentMetrics />
        {children}
      </StatusStateContext.Provider>
    </StatusDispatchContext.Provider>
  )
}

export default ContentManagerProvider
