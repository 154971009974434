import * as React from 'react'
import { useApolloClient } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { sortBy, find } from 'lodash'
import {
  AssessmentSequenceWithOverride,
  calculateAssessmentSequenceWeight,
  getSequenceCounts,
  isQuestionRemovedFromAssessment,
} from 'app/frontend/helpers/assignment'
import { QuestionViewer } from 'app/frontend/compositions/data/question-viewer/question-viewer'
import { QuestionHeadingWithEditablePoints } from 'app/frontend/compositions/ux/question-heading-with-points'
import { AssessmentQuestionActions } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-question-actions'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import * as UPDATE_ASSESSMENT_QUESTION_POSITION from 'app/frontend/compositions/connected/update-assessment-question-position/update-assessment-question-position.gql'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { swapList } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'
import { calculateTotalAssessmentPoints } from 'app/frontend/helpers/assignment'
import {
  hideSnackbar,
  showSnackbar,
} from 'app/frontend/components/material/snackbar/snackbar-actions'
import { LmsResyncWarning } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/lms-resync-warning.tsx'
import {
  getSequenceVariationCount,
  isSequenceUsed,
  SequenceIdToAssessmentsMapType,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-helper'
import { CustomAssessmentQuestionActions } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/custom-assessment-question-actions'
import { hasGqlError } from 'app/frontend/helpers/apollo/error'
import {
  getChapterAndTopicForTopicId,
  getObjectiveForObjectiveId,
} from 'app/frontend/helpers/taxonomy'
import { LearningObjectivesToAssignmentsMap } from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_MOVE_QUESTION,
  TEACH_SECTION_ASSESSMENT_MOVE_QUESTION,
} from 'app/frontend/data/mixpanel-events'

const t = tns('teach:assessment_questions')

interface OwnProps {
  assessment: GQL.GetAssignment.Assignment
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  titles: GQL.TitleTaxonFields.Fragment[]
  assessmentSequences: AssessmentSequenceWithOverride[]
  isLmsIntegrated: boolean
  allAssessedSequences: SequenceIdToAssessmentsMapType
  assignmentsByLearningObjectiveId: LearningObjectivesToAssignmentsMap
  hasEditPermission: boolean
}
export const QuestionViewPreferencesOrder: React.FunctionComponent<OwnProps> = ({
  hasStudentStartedAssignment,
  isCourseAssignmentOnSection,
  assessment,
  assessmentSequences,
  isLmsIntegrated,
  allAssessedSequences,
  titles,
  assignmentsByLearningObjectiveId,
  hasEditPermission,
}) => {
  const client = useApolloClient()
  const dispatch = useDispatch()

  const sortedAssessmentSequences: AssessmentSequenceWithOverride[] = sortBy(
    assessmentSequences,
    'sortKey'
  )

  const [sequenceList, setSequenceList] = useState(sortedAssessmentSequences)

  /**
   * use to update setSequenceList with updated props
   * work-flow
   * User first set all questions to view by order and then add a new custom question or Alta question.
   * The User will not perform any action other than adding questions.(setSequenceList not executed)At this point,
   * sequenceList (state value) will be an old assessmentSequence list.
   */
  useEffect(() => {
    setSequenceList(sortBy(assessmentSequences, 'sortKey'))
  }, [assessmentSequences])

  /**
   * Get the correct destination index from the
   * drop result object.
   * @param result: DropResult
   * Return : destination index for a given DropResult object
   */
  const getDestinationIndex = (result: DropResult) => {
    return result.source.index > result.destination.index
      ? result.destination.index - 1
      : result.destination.index
  }

  /**
   * Function that execute when question drag is end.
   * Get required parameter values from the sorted assessment list,
   * selected question ID - questionId
   * and previous question ID - previousQuestionIdAtDestination.
   * Call updateQuestionPosition function
   * @param assessmentSequencesList - sorted assessment list
   */
  const onQuestionDragEnd = (assessmentSequencesList: AssessmentSequenceWithOverride[]) => {
    return (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }
      const { id } = assessment
      // send mixpanel event
      sendEventTeachAssessment(
        parentEntityType === ParentEntityType.Course
          ? TEACH_COURSE_ASSESSMENT_MOVE_QUESTION
          : TEACH_SECTION_ASSESSMENT_MOVE_QUESTION,
        id
      )
      const destinationIndex = result.destination.index === 0 ? null : getDestinationIndex(result)
      // Stores question id at the source index. This is the question that is dragged.
      const sourceQuestionId = assessmentSequencesList[result.source.index].pathSequenceVariationId
      // Stores questionId at the destination index
      const destinationQuestionId =
        destinationIndex === null
          ? null
          : assessmentSequencesList[destinationIndex].pathSequenceVariationId

      updateQuestionPosition(
        sourceQuestionId,
        destinationQuestionId,
        assessmentSequencesList,
        result
      )
    }
  }

  /**
   * API call-  update the question position
   * @param questionId - Current question Id
   * @param previousQuestionId - Question Id where the current question is dropped
   */
  const updateQuestionPosition = async (
    questionId: string,
    previousQuestionId: string | null,
    originalSequenceList: AssessmentSequenceWithOverride[],
    result: DropResult
  ): Promise<void> => {
    const assessmentId = assessment.id

    // Apply question order changes
    setSequenceList(swapList(originalSequenceList, result.source.index, result.destination.index))

    try {
      const { data } = await client.mutate({
        mutation: UPDATE_ASSESSMENT_QUESTION_POSITION,
        variables: { assessmentId, questionId, previousQuestionId },
        context: { silenceErrors: true },
      })
      if (data) {
        dispatch(hideSnackbar()) // Hide any existing success snackbars before rendering new one
        dispatch(
          showSnackbar({ message: t('save_success'), iconName: 'icon-progress-circle-complete' })
        )
      }
    } catch (e) {
      dispatch(hideSnackbar()) // Hide any existing error snackbars before rendering new one
      setSequenceList(originalSequenceList) // Reset the order of sequenceList when error occurred
      if (hasGqlError(e, { errorType: 'ASSESSMENT_ALREADY_STARTED' })) {
        dispatch(showSnackbar({ message: t('assessment_started') }))
      } else {
        dispatch(showSnackbar({ message: t('save_error') }))
      }
    }
  }

  const totalAssessmentPoints = calculateTotalAssessmentPoints(sequenceList)
  const sequenceCounts = getSequenceCounts(sequenceList)
  const isDraggable = !(
    assessment.quizConfiguration.shuffleQuestions ||
    hasStudentStartedAssignment ||
    isCourseAssignmentOnSection ||
    !hasEditPermission
  )
  const minVariationCountForSequences = getSequenceVariationCount(assessment, assessmentSequences)
  const parentEntityType = assessment.sectionId ? ParentEntityType.Section : ParentEntityType.Course

  return (
    <div>
      {isLmsIntegrated && <LmsResyncWarning assessment={assessment} />}
      <DragDropContext onDragEnd={onQuestionDragEnd(sequenceList)}>
        <Droppable droppableId="droppable-questions">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef} data-test="question-holder">
              {sequenceList.map((sequence, index) => {
                const minVariationCount = minVariationCountForSequences[sequence.sequenceId]
                const { points, weight } = calculateAssessmentSequenceWeight(
                  sequence,
                  totalAssessmentPoints
                )
                const readOnly =
                  hasStudentStartedAssignment ||
                  isCourseAssignmentOnSection ||
                  !hasEditPermission ||
                  isQuestionRemovedFromAssessment(sequence)
                const plo = find(assessment.pathLearningObjectives, {
                  learningObjectiveId: sequence.learningObjectiveId,
                })
                const { chapter, topic } = getChapterAndTopicForTopicId(
                  titles,
                  plo ? plo.topicId : null
                )
                const chapterName = chapter ? chapter.displayName : null
                const topicName = topic ? topic.displayName : null
                const learningObjective = getObjectiveForObjectiveId(
                  topic,
                  sequence.learningObjectiveId
                )
                return (
                  <Draggable
                    key={sequence.pathSequenceVariationId}
                    draggableId={sequence.pathSequenceVariationId}
                    index={index}
                    isDragDisabled={!isDraggable}
                  >
                    {providedSequences => (
                      <div
                        ref={providedSequences.innerRef}
                        {...providedSequences.draggableProps}
                        {...providedSequences.dragHandleProps}
                        data-test="question-viewer"
                        data-variation-id={sequence.pathSequenceVariationId}
                      >
                        <QuestionViewer
                          customHeading={
                            <QuestionHeadingWithEditablePoints
                              assessmentId={assessment.id}
                              sequenceId={sequence.sequenceId}
                              parentEntityType={parentEntityType}
                              points={points}
                              weight={weight}
                              readOnly={readOnly}
                              assessmentSequences={sortedAssessmentSequences}
                              isPoolQuestion={sequenceCounts[sequence.sequenceId] > 1}
                              questionIndex={index + 1}
                            />
                          }
                          sequenceId={sequence.sequenceId}
                          parentEntityType={parentEntityType}
                          pathSequenceVariationId={sequence.pathSequenceVariationId}
                          includeExplanationAndAnswers={true}
                          actionBar={
                            !sequence.learningObjectiveId
                              ? CustomAssessmentQuestionActions
                              : AssessmentQuestionActions
                          }
                          assessmentId={assessment.id}
                          minVariationCount={minVariationCount}
                          isAssessmentOffline={assessment.quizConfiguration.printable}
                          isAssessmentLdb={assessment.quizConfiguration.useLdb}
                          override={sequence.resultOverride}
                          overrideValue={sequence.overrideValue}
                          displayLearningObjectiveBar={true}
                          isDraggable={isDraggable}
                          canUpdateDisplayDensity={true}
                          isSequenceUsed={isSequenceUsed(
                            sequence.sequenceId,
                            assessment.id,
                            allAssessedSequences
                          )}
                          learningObjectives={assessment.pathLearningObjectives}
                          learningObjective={learningObjective}
                          chapterName={chapterName}
                          topicName={topicName}
                          assignmentsByLearningObjectiveId={assignmentsByLearningObjectiveId}
                          questionNumber={index + 1}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

QuestionViewPreferencesOrder.displayName = 'QuestionViewPreferencesOrder'
