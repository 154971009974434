import * as React from 'react'
import * as classnames from 'classnames/bind'
import * as color from 'color'
import * as theme from '../theme'

import * as styles from './box.css'

const c = classnames.bind(styles)

export interface Props extends React.HTMLAttributes<HTMLDivElement>, BoxContextProps {
  /** How to alignItems the contents along the cross axis. */
  alignItems?: 'start' | 'center' | 'end' | 'baseline' | 'stretch'

  /** How to alignItems within its container along the cross axis. */
  alignSelf?: 'start' | 'center' | 'end' | 'stretch'

  /** Data-bi property allows product to attach Pendo guides to a box easily */
  dataBi?: string

  /** The orientation to layout the child components in. Defaults to column. */
  direction?: 'row' | 'column'

  /** Whether flex-grow and/or flex-shrink is true. */
  flex?: boolean | 'grow' | 'shrink' | 'expand'

  /** Whether the width and/or height should take the full viewport size.
   * Alternatively, 'page' will restrict contents to the globaly defined --max-width.
   */
  full?: boolean | 'horizontal' | 'vertical' | 'page'

  /** How to alignItems the contents along the main axis. */
  justify?: 'start' | 'center' | 'between' | 'around' | 'end'

  /** The amount of margin around the box. An object can be specified to distinguish horizontal
   * margin, vertical margin, and margin on a particular side of the box:
   * {horizontal: none|small|medium|large|xlarge|huge, vertical: none|small|medium|large|xlarge|huge,
   * top|left|right|bottom: none|small|medium|large|xlarge|huge}. Defaults to none.
   */
  margin?:
    | 'none'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'huge'
    | {
        horizontal?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        vertical?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
      }
    | {
        top?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge' | 'auto'
        left?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge' | 'auto'
        right?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge' | 'auto'
        bottom?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge' | 'auto'
      }
  /**
   * The amount of padding to put around the contents. An object can be specified to distinguish
   * horizontal padding, vertical padding, and padding between child components:
   * {horizontal: none|small|medium|large, vertical: none|small|medium|large,
   * between: none|small|medium|large}. Defaults to none. Padding set using between only
   * affects components based on the direction set (adds horizontal padding between components
   * for row, or vertical padding between components for column).
   */
  pad?:
    | 'none'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'huge'
    | {
        horizontal?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        vertical?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        between?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
      }
    | {
        top?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        left?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        right?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        bottom?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        between?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
      }

  // Whether to reverse the order of the child components.
  reverse?: boolean

  // Whether children laid out in a row direction should be switched to a column layout when the
  // display area narrows. Defaults to true.
  responsive?: boolean

  // Set text-alignItems for the Box contents.
  textAlign?: 'left' | 'center' | 'right'

  // Whether children can wrap if they can't all fit. Defaults to false.
  wrap?: boolean

  // Add a separator
  separator?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'horizontal'
    | 'vertical'
    | 'all'
    | 'between'
    | 'none'
    | string[]

  // boxOwnBackground is assigned from the explicitly passed prop "background" and it is only
  // used by the component _Box for its inline style.
  boxOwnBackground?: 'none' | 'dark' | 'light' | string

  // sets a 0.25rem border radius around the box
  roundedBorder?: boolean
}

export type BoxBackground = 'none' | 'dark' | 'light' | string

export interface BoxContextProps {
  // Sets the background between dark a light colors, and sets the context, may choose between
  // "none" (no background), "dark" (primary dark color color background), "light" (primary light
  // color background), or specify a color from the theme
  background?: BoxBackground

  ref?: React.Ref<any>
}

const BoxContext = React.createContext<BoxContextProps>({ background: 'none' })

export const withBoxContext = <T, P extends BoxContextProps>(InnerComponent) => {
  return React.forwardRef<T, P>((props, ref) => {
    return (
      <BoxContext.Consumer>
        {context =>
          InnerComponent === _Box ? (
            <_Box
              ref={ref}
              {...props}
              // boxOwnBackground is used to set the box inline style,
              // background is for the context.
              boxOwnBackground={props.background}
              background={props.background ? props.background : context.background}
            />
          ) : (
            <InnerComponent
              {...props}
              background={props.background ? props.background : context.background}
            />
          )
        }
      </BoxContext.Consumer>
    )
  })
}

/**
 * General purpose flexible box layout. This supports many, but not all, of the flexbox capabilities.
 * Based off of http://grommet.io/docs/box/.  Box also supports child component theming by specifying
 * the background property.
 */
class _Box extends React.Component<Props, {}> {
  ref: React.RefObject<HTMLDivElement>

  constructor(props) {
    super(props)

    this.ref = React.createRef<HTMLDivElement>()
  }

  /**
   * Computes whether col is a "light" or "dark" color, from its R, G, and B color channels.
   */
  getColorBrightness(col: string): 'dark' | 'light' {
    const accentColor = color(col)
    const isDark = 5 * accentColor.green() + 2 * accentColor.red() + accentColor.blue() <= 8 * 128
    return isDark ? 'dark' : 'light'
  }

  /**
   * Given a context with the background property, computes a color value from it.
   * A valid background string can be "light", "dark", or any theme color.
   */
  getBgColorFromContext(context: BoxContextProps): string {
    const { background = this.props.background || 'none' } = context
    switch (background) {
      case 'none':
        return undefined
      case 'light':
        return theme['--color-white']
      case 'dark':
        return theme['--blue-1000']
      default:
        return theme[`--${background}`] || background
    }
  }

  getContext(): BoxContextProps {
    const contextColor = this.getBgColorFromContext(this.props)
    return {
      background: contextColor ? this.getColorBrightness(contextColor) : this.props.background,
    }
  }

  /**
   * Returns a css class name of the pattern `prefix-value`.  If an object is passed into
   * the value, the pattern is then `prefix-value.key-value.value`.
   */
  private classes(prefix: string, value: boolean | string | object): string[] {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [`${prefix}-${value}`]
    } else if (typeof value === 'object') {
      return Object.entries(value).map(([key, val]) => `${prefix}-${key}-${val}`)
    } else {
      return []
    }
  }

  render(): JSX.Element {
    const {
      alignItems = 'start',
      direction = 'column',
      reverse = false,
      responsive = true,
      flex = 'shrink',
      full = false,
      justify = 'start',
      margin = 'none',
      pad = 'none',
      textAlign = 'left',
      wrap = false,
      alignSelf,
      separator,
      className,
      children,
      background,
      boxOwnBackground,
      roundedBorder = false,
      dataBi,
      ...otherProps
    } = this.props

    return (
      <div
        ref={this.ref}
        data-bi={dataBi}
        style={{
          /* sets the background color only if the prop has been set explictly -- if it has been
                 passed through the context this does not set a new background, and instead inherits it
              */
          backgroundColor:
            boxOwnBackground && this.getBgColorFromContext({ background: boxOwnBackground }),
        }}
        className={classnames(
          c(
            `default`,
            this.classes('alignItems', alignItems),
            this.classes('align-self', alignSelf),
            this.classes('direction', direction),
            this.classes('flex', flex),
            this.classes('full', full),
            this.classes('justify', justify),
            this.classes('margin', margin),
            this.classes('pad', pad),
            this.classes('reverse', reverse),
            this.classes('responsive', responsive),
            this.classes('text-align', textAlign),
            this.classes('wrap', wrap),
            this.classes('rounded-border', roundedBorder),
            separator instanceof Array
              ? separator.map(s => this.classes('separator', s))
              : this.classes('separator', separator)
          ),
          className
        )}
        {...otherProps}
      >
        {background && (
          <BoxContext.Provider value={this.getContext()}>{children}</BoxContext.Provider>
        )}
      </div>
    )
  }
}

export const Box = withBoxContext<_Box, Props>(_Box)
