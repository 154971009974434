import * as React from 'react'

/**
 * This can be use to call a function when a componentDidUpdate happens. React ref is used to
 * control the function invoke to stop from calling when componentDidMount
 *
 * @param effect the function which need to call only when componentDidUpdate
 * @param deps dependencies for the useEffect
 */
export const useDidUpdateEffect = (effect, deps) => {
  const ref = React.useRef<boolean>(false)
  React.useEffect(() => {
    if (ref.current) {
      effect()
    } else {
      ref.current = true
    }
  }, [...deps, ref])
}
