import * as React from 'react'
import { map, isEmpty } from 'lodash'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { QuestionViewer } from 'app/frontend/compositions/data/question-viewer/question-viewer'
import { getChapterAndTopicForTopicId } from 'app/frontend/helpers/taxonomy'
import { useAssignedLearningObjectives } from 'app/frontend/pages/material/teach/compositions/connected/get-assigned-learning-objectives/use-assigned-learning-objectives'
import { ParentEntityType } from 'app/typings/commons'
import { QuestionHeadingWithEditablePoints } from 'app/frontend/compositions/ux/question-heading-with-points'
import {
  AssessmentSequenceWithOverride,
  isQuestionRemovedFromAssessment,
  calculateAssessmentSequenceWeight,
  getSequenceCounts,
} from 'app/frontend/helpers/assignment'
import { useTotalAssessmentPoints } from 'app/frontend/compositions/connected/get-assessment-sequences'
import { sortPathLearningObjectivesByToc } from 'app/frontend/helpers/table-of-contents'
import { QuestionViewPreferencesOptionsViewDensity } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'
import {
  getSequenceVariationCount,
  isSequenceUsed,
  SequenceIdToAssessmentsMapType,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-helper'
import { LearningObjectiveByIdQuery } from 'app/frontend/compositions/connected/learning-objective-by-id'
import { LearningObjectiveBar } from './learning-objective-bar/learning-objective-bar'
import { AssessmentQuestionActions } from './assessment-question-actions'
import * as styles from './assessment-questions.css'
import { LmsResyncWarning } from './lms-resync-warning'
import { AssessmentSequenceByLoMapType } from './assessment-questions-wrapper'

const t = tns('teach:assessment_questions')

interface Props {
  assessment: GQL.GetAssignment.Assignment
  assessmentSequences: AssessmentSequenceByLoMapType
  titles: GQL.TitleTaxonFields.Fragment[]
  isLmsIntegrated: boolean
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  displayDensity?: QuestionViewPreferencesOptionsViewDensity
  allAssessedSequences: SequenceIdToAssessmentsMapType
  hasEditPermission: boolean
}

export const AssessmentQuestions: React.FunctionComponent<Props> = ({
  assessment,
  assessmentSequences,
  titles,
  isLmsIntegrated,
  isCourseAssignmentOnSection,
  hasStudentStartedAssignment,
  displayDensity,
  allAssessedSequences,
  hasEditPermission,
}) => {
  const sortedPathLearningObjectives = sortPathLearningObjectivesByToc(
    titles,
    assessment.pathLearningObjectives
  )
  const parentEntityId = assessment.sectionId || assessment.courseId
  const parentEntityType = assessment.sectionId ? ParentEntityType.Section : ParentEntityType.Course

  const { assignmentsByLearningObjectiveId } = useAssignedLearningObjectives(
    parentEntityId,
    parentEntityType
  )

  const {
    totalAssessmentPoints,
    assessmentSequences: allAssessmentSequences,
  } = useTotalAssessmentPoints(assessment.id)

  const countMap = React.useMemo(() => {
    const baseLoMap = new Map<string, number>()
    let counter = 1
    sortedPathLearningObjectives.forEach(learningObjective => {
      baseLoMap.set(learningObjective.learningObjectiveId, counter)
      counter += assessmentSequences[learningObjective.learningObjectiveId]?.length ?? 0
    })
    return baseLoMap
  }, [sortedPathLearningObjectives, assessmentSequences])

  const renderQuestionsViewer = (
    sequences: AssessmentSequenceWithOverride[],
    learningObjective: Partial<Content.ILearningObjective>,
    chapterName: string,
    topicName: string,
    startIndex: number
  ): JSX.Element => {
    const sequenceCounts = getSequenceCounts(sequences)
    const minVariationCountForSequences = getSequenceVariationCount(assessment, sequences)
    return (
      <>
        {sequences.map((seq, index) => {
          const minVariationCount = minVariationCountForSequences[seq.sequenceId]
          const { points, weight } = calculateAssessmentSequenceWeight(seq, totalAssessmentPoints)
          const readOnly =
            hasStudentStartedAssignment ||
            isCourseAssignmentOnSection ||
            !hasEditPermission ||
            isQuestionRemovedFromAssessment(seq)
          return (
            <div
              data-test={`question-viewer-${seq.pathSequenceVariationId}`}
              data-sequence-id={seq.sequenceId}
              key={`${seq.pathSequenceVariationId}`}
            >
              <QuestionViewer
                lazyLoad={true}
                customHeading={
                  <QuestionHeadingWithEditablePoints
                    assessmentId={assessment.id}
                    sequenceId={seq.sequenceId}
                    parentEntityType={parentEntityType}
                    points={points}
                    weight={weight}
                    readOnly={readOnly}
                    assessmentSequences={allAssessmentSequences}
                    isPoolQuestion={sequenceCounts[seq.sequenceId] > 1}
                  />
                }
                parentEntityType={parentEntityType}
                sequenceId={seq.sequenceId}
                pathSequenceVariationId={seq.pathSequenceVariationId}
                includeExplanationAndAnswers={true}
                actionBar={AssessmentQuestionActions}
                assessmentId={assessment.id}
                isAssessmentOffline={assessment.quizConfiguration.printable}
                isAssessmentLdb={assessment.quizConfiguration.useLdb}
                minVariationCount={minVariationCount}
                override={seq.resultOverride}
                overrideValue={seq.overrideValue}
                displayDensity={displayDensity}
                canUpdateDisplayDensity={true}
                isSequenceUsed={isSequenceUsed(seq.sequenceId, assessment.id, allAssessedSequences)}
                learningObjective={learningObjective}
                topicName={topicName}
                chapterName={chapterName}
                questionNumber={startIndex + index}
              />
            </div>
          )
        })}
      </>
    )
  }

  const renderEmptyObjective = (): JSX.Element => {
    return (
      <Box
        separator="all"
        margin={{ vertical: 'large' }}
        alignItems="center"
        justify="center"
        className={styles.emptyObjective}
      >
        <Paragraph data-test="empty-objective-text">{t('empty_objective_text')}</Paragraph>
      </Box>
    )
  }

  return (
    <>
      {isLmsIntegrated && <LmsResyncWarning assessment={assessment} />}
      {map(sortedPathLearningObjectives, plo => {
        const loId = plo.learningObjectiveId
        const sequences = assessmentSequences[loId] ? assessmentSequences[loId] : []
        const { chapter, topic } = getChapterAndTopicForTopicId(titles, plo.topicId)
        const chapterName = chapter ? chapter.displayName : null
        const topicName = topic ? topic.displayName : null

        return (
          <LearningObjectiveByIdQuery id={loId} key={loId}>
            {({ lo: learningObjective }) => (
              <div className={styles.container} data-test="assessment-questions">
                <LearningObjectiveBar
                  key={loId}
                  assessmentId={assessment.id}
                  learningObjectiveId={plo.learningObjectiveId}
                  learningObjective={learningObjective}
                  numAssessmentSequences={sequences.length.toString()}
                  chapterName={chapterName}
                  topicName={topicName}
                  pathLearningObjectives={assessment.pathLearningObjectives}
                  assignmentsByLearningObjectiveId={assignmentsByLearningObjectiveId}
                />
                {isEmpty(sequences)
                  ? renderEmptyObjective()
                  : renderQuestionsViewer(
                      sequences,
                      learningObjective,
                      chapterName,
                      topicName,
                      countMap.get(loId)
                    )}
              </div>
            )}
          </LearningObjectiveByIdQuery>
        )
      })}
    </>
  )
}

AssessmentQuestions.displayName = 'AssessmentQuestions'
