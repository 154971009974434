import * as React from 'react'
import * as classnames from 'classnames'
import ErrorBoundary from 'app/frontend/components/error/error-boundary'
const styles = require('./page.css')

export type HeaderTheme = 'dark' | 'light'

interface Props {
  header: JSX.Element
  /**
   * dark or light theme. Defaults to dark.
   */
  headerTheme?: HeaderTheme
}

interface State {
  index: number
}

export default class Page extends React.Component<Props, State> {
  constructor(props, context) {
    super(props, context)
    this.state = {
      index: 0,
    }
  }

  render(): JSX.Element {
    const header = this.props.header
    const headerTheme = this.props.headerTheme || 'dark'
    const children = this.props.children as any
    return (
      <div>
        {header && (
          <div className={classnames(headerTheme, styles[`headerContainer-${headerTheme}`])}>
            <div className={styles.wrapper}>
              <div className={styles.header}>{header}</div>
            </div>
          </div>
        )}
        {!children || children.length === 0 ? (
          <div className={styles.spacer} />
        ) : (
          <div className={styles.child}>
            <ErrorBoundary source={'page'}>{children}</ErrorBoundary>
          </div>
        )}
      </div>
    )
  }
}
