import * as React from 'react'
import { Box, BoxProps } from 'app/frontend/components/material/box'
import { Heading } from 'app/frontend/components/material/heading'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './collapsible-card.css'

type Props = {
  active?: boolean
  defaultExpanded?: boolean
  title: string
  children: JSX.Element
  wrapperProps?: BoxProps
  keyChange?: string // if this changes, isOpen will reset to the value of defaultExpanded
  tag?: JSX.Element
}

export const CollapsibleCard: React.FunctionComponent<Props> = ({
  wrapperProps,
  tag,
  active,
  keyChange,
  title,
  defaultExpanded,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(!!defaultExpanded)

  React.useEffect(() => {
    setIsOpen(!!defaultExpanded)
  }, [defaultExpanded, keyChange])

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      e.preventDefault()
      toggleOpen()
    }
  }

  const isVisible = !!(isOpen || active)

  const icon = isVisible ? 'icon-expand-more' : 'icon-arrow-chevron-right'

  return (
    <Box alignSelf="stretch" margin={{ vertical: 'medium' }} {...wrapperProps}>
      <Box
        data-test="collapsible-heading"
        role="button"
        tabIndex={0}
        aria-expanded={isVisible}
        direction="row"
        alignItems="center"
        onClick={toggleOpen}
        onKeyDown={onKeyDown}
        className={styles.heading}
        alignSelf="stretch"
        justify="between"
      >
        <Box alignSelf="stretch" direction="row" alignItems="center">
          <Icon name={icon} size="large" />
          <Heading tag="h3" pad={{ left: 'small' }} data-test="card-heading" margin="none">
            {title}
          </Heading>
        </Box>
        {tag}
      </Box>
      {isVisible && children}
    </Box>
  )
}
