import * as React from 'react'
import Circle from 'app/frontend/components/material/circle/circle'
import { getStaticUrl } from 'app/frontend/helpers/assets'
import * as HeroRegisterSvg from 'app/public/img/hero-register.svg'
import * as styles from './register.css'

const IntroMessaging: React.FunctionComponent<{ t: any }> = ({ t }) => {
  return (
    <div>
      <Circle style={{ margin: 'auto' }}>
        <img
          className={styles.heroImg}
          src={getStaticUrl(HeroRegisterSvg)}
          alt=""
          role="presentation"
        />
      </Circle>
      <div className={styles.marketingText}>{t('registration_page:marketing_text')}</div>
    </div>
  )
}

IntroMessaging.displayName = 'IntroMessaging'
export default IntroMessaging
