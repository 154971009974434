import * as React from 'react'
import { Heading } from 'app/frontend/components/material/heading'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { useLoConceptByIds } from 'app/frontend/pages/material/teach/compositions/connected/get-lo-concept-by-ids'
import { PlaceholderText } from 'app/frontend/components/material/loading'
import { Select, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
const t = tns('teach:concept_preview')

interface Props {
  selectedConceptId: string
  selectedObjectiveId: Commons.LearningObjectiveId
  conceptIds: string[]
  hideHeader?: boolean
  onSelectConcept: (conceptId: string) => void
}

export const ALL_CONCEPTS = 'ALL_CONCEPTS'

const useSelectStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    color: theme.palette?.grey[600],
  },
}))

const useMenuItemStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    opacity: 1,
    color: theme.palette?.grey['600'],
    '&.Mui-selected': {
      backgroundColor: '#b2d4ff',
    },
  },
}))

type LoConceptOptions = {
  label: string
  value: string
}

export const LoConceptSelector: React.FunctionComponent<Props> = ({
  selectedConceptId,
  conceptIds,
  onSelectConcept,
  hideHeader,
}) => {
  const { loading, error, loConcepts } = useLoConceptByIds(conceptIds)

  const selectClasses = useSelectStyles()
  const menuItemClasses = useMenuItemStyles()

  const handleOnChange = event => {
    onSelectConcept(event.target.value)
  }

  if (loading || error) {
    return <PlaceholderText width="50rem" height="2.5rem" />
  }

  let loConceptOptions: LoConceptOptions[] = []

  if (loConcepts.length > 1) {
    loConceptOptions = loConcepts.map((loConcept: GQL.LoConcept) => ({
      label: loConcept.name,
      value: loConcept.id,
    }))
  }
  loConceptOptions.push({
    label: t('all', { count: loConcepts.length }),
    value: ALL_CONCEPTS,
  })

  return (
    <>
      {!hideHeader && (
        <Heading tag="h5" size="h6" margin={{ top: 'medium' }}>
          {t('concepts')}
        </Heading>
      )}
      <Select
        name="concept-selector"
        variant="standard"
        placeholder={t('select_concepts')}
        value={selectedConceptId}
        onChange={handleOnChange}
        classes={selectClasses}
        displayEmpty={false}
      >
        {loConceptOptions.map(lo => (
          <MenuItem key={lo.value} classes={menuItemClasses} value={lo.value}>
            {lo.label}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

LoConceptSelector.displayName = 'LoConceptSelector'
