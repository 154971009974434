import MediaAttribution from './media-attribution'
import AtomAttribution from './atom-attribution'

export {
  /**
   * MediaAttribution is used to abstract some of the nastiness around formatting a Media Atom's
   * attribution metadata. Because this data is as old as time, it has a lot of nuance to it.
   */
  MediaAttribution,
  /**
   * AtomAttribution is used to abstract some of the nastiness around formatting an Atom's
   * attribution. Because this data is as old as time, it has a lot of nuance to it.
   */
  AtomAttribution,
}
