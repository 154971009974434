import { Action } from 'redux'
import {
  ThirdPartyModalActionType,
  SetThirdPartyModalOpenAction,
} from 'app/frontend/layout/material/third-party-modal-actions'

export interface ThirdPartyModalReducerState {
  /**
   * Is any third party modal open
   */
  isOpen: boolean
}

const defaultState = {
  isOpen: false,
}

export function thirdPartyModalReducer(
  state: ThirdPartyModalReducerState = defaultState,
  action: Action
) {
  if (action.type === ThirdPartyModalActionType.SetThirdPartyModalOpen) {
    const setThirdPartyModalOpenAction = action as SetThirdPartyModalOpenAction
    return {
      isOpen: setThirdPartyModalOpenAction.isOpen,
    }
  } else {
    return state
  }
}
