import * as _ from 'lodash'
import ErrorHandler from './error-handler'

/* These are internal -- no need to expose them */
type TypesetCommand = ['Typeset', any, Element, () => {}]
type CallbackCommand = [() => {}]
type MathJaxCommand = TypesetCommand | CallbackCommand

/**
 * Submit to the Queue with a custom priority.
 * ON_DEMAND will be processed before DEFAULT
 */
export enum Priority {
  DEFAULT = 1,
  ON_DEMAND = 2,
}

/**
 * Additions to the Queue will be given a unique ID
 */
export type CommandId = string

export interface ConfigureOptions {
  onBegin?: () => void /* The Queue has started typesetting */
  onEnd?: () => void /* The Queue has finished typesetting */
}

export interface TypesetOptions {
  priority?: Priority /* A higher priority allows preference */
  onSuccess?: (id: CommandId) => void /* Called when a Typeset has finished */
  onError?: (id: CommandId) => void /* Called when a Typeset has errored */
}

/**
 * Our Singleton MathJax Queue.
 *
 * This queue acts as a wrapper around the MathJax.Hub.Queue and provides the following
 * additional functionality:
 *
 * (1) Allows requests to cut in line and be processed next with the ON_DEMAND priority.
 * (2) Allows requests (and callbacks) to be cancelled which is important for React lifecycles
 * (3) Will call the provided callbacks:
 *   (3a) Per typeset request
 *   (3b) When the queue starts typesetting
 *   (3c) When the queue stops typesetting and is out of work
 * (4) Calls success or error callbacks after typeset is handled by MathJax
 *
 * This queue will only attempt to process commands (e.g. typeset) once its start() method
 * has been called. This signifies MathJax has been configured and is loaded on the page.
 */
const queue = {
  /**
   * Allows us to look-up from CommandID to Command, so we can leverage fast look-ups.
   *
   * NOTE: We store a callback for generating the command(s) rather than the command(s) itself, as
   * some commands may have a reference to MathJax.Hub which may not yet exist while being
   * queued.
   *
   * Each command callback may create multiple MathJaxCommands in order to fully execute
   * the desired behavior. Because we submit these in batch to MathJax.Hub.Queue,
   * they are guaranteed to be called synchronously, in order, in succession.
   */
  commands: new Map<CommandId, () => MathJaxCommand[]>() as Map<CommandId, () => MathJaxCommand[]>,

  /**
   * The commands to process, in a FIFOish based order.
   */
  toProcess: [] as CommandId[],

  /**
   * The command currently being processed.
   */
  inProcess: null as CommandId,

  /**
   * The queue has been started
   */
  started: false,

  errorHandler: new ErrorHandler(),

  /**
   * Clears our Queue of work.
   *
   * This is primarily used for testing. You probably shouldn't call it unless you know what you
   * are doing, as it will stop submitting work to MathJax and abandon all callbacks.
   */
  clear(): void {
    this.commands = new Map<CommandId, () => MathJaxCommand[]>()
    this.toProcess = [] as CommandId[]
    this.inProcess = null as CommandId
    this.started = false
    this.errorHandler.unsubscribe()
    this.errorHandler = new ErrorHandler()
  },

  /**
   * Called when the Queue begins processing
   */
  onBegin: () => undefined,

  /**
   * Called when the Queue ends processing (and has no more work)
   */
  onEnd: () => undefined,

  /**
   * Configures the Queue.
   */
  configure(options: ConfigureOptions): void {
    if (options.onBegin) {
      this.onBegin = options.onBegin
    }
    if (options.onEnd) {
      this.onEnd = options.onEnd
    }
  },

  /**
   * Starts the Queue.
   *
   * The Queue will not run until this method is called. Any queued up commands will be submitted
   * to the MathJax Hub. Once the Queue is started, it cannot be stopped and will run until
   * exhaustion, listening and processing additions.
   *
   * This method is idempotent and may be called multiple times.
   */
  start(): void {
    if (!this.started) {
      this.errorHandler.subscribe()
      this.started = true
      this.safeStart()
    }
  },

  /**
   * Request to Typeset an Element.
   * @param element The element to be processed and typeset
   * @param options options for handling this request
   * @returns {string} ID of the request which can be used to cancel said request.
   */
  typeset: function (element: Element | string, options: TypesetOptions = {}): CommandId {
    const { priority, onSuccess, onError } = options
    const id = _.uniqueId()

    this.commands.set(
      id,
      this._typeset.bind(
        this,
        id,
        element,
        onSuccess ? onSuccess : () => undefined,
        onError ? onError : () => undefined
      )
    )
    if (priority === Priority.ON_DEMAND) {
      // O(N) tsk tsk
      this.toProcess.unshift(id)
    } else {
      this.toProcess.push(id)
    }

    // whenever we get a request to typeset, we always try to kick off the Queue if it isn't
    // already consuming work.
    this.safeStart()
    return id
  },

  /**
   * Returns an array of MathJaxCommand.
   *
   * See http://docs.mathjax.org/en/latest/api/hub.html#Typeset for details.
   * TL;DR is that this command takes a single Element (or Element ID) to process for LaTex
   * and a callbacks to call after the typesetting is complete.
   *
   * Side-effects include:
   *   - Clearing our queue's errors (and possibly filling it with new ones)
   *   - Calling the success or error callback if this action hasn't yet been cancelled
   *   - Removing this command from our internal structures (as it is complete)
   * @private
   */
  _typeset: function (
    id: CommandId,
    element: Element | string,
    success: (id: CommandId) => void,
    error: (id: CommandId) => void
  ): MathJaxCommand[] {
    return [
      [() => this.errorHandler.reset()] as CallbackCommand,
      [
        'Typeset',
        MathJax.Hub,
        element,
        () => {
          // Guard against any requests what were cancelled while MathJax was typesetting
          if (this.commands.has(id)) {
            this.commands.delete(id)
            try {
              this.errorHandler.numErrors === 0 ? success(id) : error(id)
            } catch (e) {
              console.error('failed to execute task callback', {
                error: e,
                id: id,
                internalIssueId: 'CE-3476',
              })
            }
          }
        },
      ] as TypesetCommand,
    ]
  },

  /**
   * Cancel a request.
   *
   * If the request has not been processed by MathJax, then it will be removed. If the request
   * is already in-flight, then we cannot stop the MathJax engine from processing it, but we
   * will ignore the provided callbacks.
   *
   * @param id the unique identifier
   * @returns {boolean} true if the item was deleted
   */
  cancel: function (id: CommandId): boolean {
    if (_.isNil(id)) {
      return false
    }
    this.toProcess = this.toProcess.filter(value => value !== id)
    return this.commands.delete(id)
  },

  /**
   * Try to start feeding work to the MathJax.Hub.Queue.
   *
   * If the Queue is already doing work, then this is a noop.
   */
  safeStart: function (): void {
    if (this.started && this.inProcess === null && this.toProcess.length > 0) {
      this.onBegin()
      this.next()
    }
  },

  /**
   * Submit the next Command to the MathJax.Hub.Queue
   *
   * This function will consume the first command in the queue. It will also ensure this function
   * is called by the MathJax engine after the task has been completed so we may continue
   * processing automatically.
   */
  next: function (): void {
    if (this.toProcess.length > 0) {
      const id = this.toProcess.shift()
      this.inProcess = id
      const buildCommand = this.commands.get(id)
      MathJax.Hub.Queue(...buildCommand(), [
        () => {
          this.next()
        },
      ] as CallbackCommand)
    } else {
      this.inProcess = null
      this.onEnd()
    }
  },
}

export default queue
