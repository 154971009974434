import * as React from 'react'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import Autocomplete from 'accessible-react-toolbox/lib/autocomplete'
import { FFlipActions } from './fflip-actions'
import { connect } from 'react-redux'
import { ModalFooter } from 'app/frontend/components/material/modal/modal-footer'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { hideModal } from 'app/frontend/components/material/modal/modal-actions'
import { features } from 'app/middleware/data/features'
import * as Feature from 'app/frontend/helpers/feature'

export const FFLIP_MODAL = 'FFLIP_MODAL'

namespace FFlipModal {
  export interface DispatchProps {
    enableFeature: (featureName: string) => void
    disableFeature: (featureName: string) => void
    hideModal: () => void
  }

  export interface StateProps {}

  export interface OwnProps {}

  export type Props = DispatchProps & StateProps & OwnProps

  export interface State {
    selectedFeature: Features.FeatureName
    featuresSource: { [id: string]: string }
  }
}

class FFlipModal extends React.Component<FFlipModal.Props, FFlipModal.State> {
  private featureSelectElementName = FFLIP_MODAL + '_input'

  constructor(props, context) {
    super(props, context)
    this.state = {
      selectedFeature: undefined,
      featuresSource: Object.entries(features).reduce((map, [key, val]) => {
        map[key] = val.name
        return map
      }, {}),
    }
  }

  handleFeatureSelect = (feature: Features.FeatureName): void => {
    this.setState({
      selectedFeature: feature,
    })
  }

  handleToggleFeature = (e: React.FormEvent): void => {
    e.preventDefault()
    const { selectedFeature } = this.state
    if (Feature.isEnabled(selectedFeature)) {
      this.props.disableFeature(selectedFeature)
    } else {
      this.props.enableFeature(selectedFeature)
    }
  }

  render(): JSX.Element {
    const { selectedFeature, featuresSource } = this.state
    return (
      <Modal
        name={FFLIP_MODAL}
        dataBi="fflip-modal"
        initialFocus={() => document.getElementsByName(this.featureSelectElementName)[0]}
        fullScreen={false}
      >
        <form onSubmit={this.handleToggleFeature}>
          <ModalBody>
            <p>
              WARNING: Temporary feature toggles here may be overridden by features which have been
              enabled or disabled permanently.
            </p>
            <p>
              Please be sure you know what you're doing. Do not use this tool in front of an
              external audience or for demo purposes.
            </p>
            <Autocomplete
              direction="down"
              name={this.featureSelectElementName}
              multiple={false}
              suggestionMatch="anywhere"
              label="Input feature to toggle"
              onChange={this.handleFeatureSelect}
              value={selectedFeature}
              source={featuresSource}
            />
            {selectedFeature && <p>{features[selectedFeature].description}</p>}
            <p className="caption-small">* Hint trigger this modal via Alt+F</p>
          </ModalBody>
          <ModalFooter>
            <ButtonMaterial
              disabled={!selectedFeature}
              type="submit"
              label={Feature.isEnabled(selectedFeature) ? 'Disable' : 'Enable'}
              data-bi="enable-feature-flag"
            />
            <ButtonMaterial
              theme="secondary"
              label="Cancel"
              data-bi="cancel-button"
              onClick={this.props.hideModal}
            />
          </ModalFooter>
        </form>
      </Modal>
    )
  }
}

const mapDispatchToProps = (dispatch): FFlipModal.DispatchProps => ({
  enableFeature: featureName => dispatch(FFlipActions.enableFeature(featureName)),
  disableFeature: featureName => dispatch(FFlipActions.disableFeature(featureName)),
  hideModal: () => dispatch(hideModal(FFLIP_MODAL)),
})

export default connect<FFlipModal.StateProps, FFlipModal.DispatchProps, FFlipModal.OwnProps>(
  null,
  mapDispatchToProps
)(FFlipModal)
