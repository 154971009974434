export const OPEN_POPOVER = 'OPEN_POPOVER'
export function openPopover(id) {
  return {
    type: OPEN_POPOVER,
    id,
  }
}

export const CLOSE_POPOVER = 'CLOSE_POPOVER'
export function closePopover() {
  return {
    type: CLOSE_POPOVER,
  }
}
