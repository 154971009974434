import { combineReducers } from 'redux'
import ldbLaunchUrlsReducer, { LdbLaunchUrlState } from './ldb-launch-urls-reducer'
import filterReducer, { FilterState } from './filter-reducer'
import authReducer, { AuthState } from './auth-reducer'
import statusReducer, { StatusState } from './status-reducer'
import timeDeltaReducer from './time-delta-reducer'
import unansweredCompoundsReducer, {
  UnansweredCompoundsState,
} from './unanswered-compounds-reducer'
import { LoadingState } from 'app/frontend/pages/material/learn/ui/loading-state'
import loadingReducer from './loading-reducer'

export type QuizFlowUIState = {
  auth: AuthState
  ldbLaunchUrls: LdbLaunchUrlState
  filter: FilterState
  status: StatusState
  timeDelta: number
  unansweredCompounds: UnansweredCompoundsState
  loading: LoadingState
}

export default combineReducers({
  auth: authReducer,
  ldbLaunchUrls: ldbLaunchUrlsReducer,
  filter: filterReducer,
  status: statusReducer,
  timeDelta: timeDeltaReducer,
  unansweredCompounds: unansweredCompoundsReducer,
  loading: loadingReducer,
})
