export type AssignedLearningObjectivesMap = {
  // This list contains assignment ids that have assigned the learning objective
  [learningObjectiveId: string]: string[]
}

export type TestedLearningObjectivesMap = {
  // This list contains assessment ids that have assessed the learning objective
  [learningObjectiveId: string]: string[]
}

export type AssignedObjectivesWithBlacklistMap = {
  // This list contains blacklisted concept ids for the learning objective
  [learningObjectiveId: string]: string[]
}

export type AssessedConceptsLoMap = {
  [learningObjectiveId: string]: {
    // This list contains assessment ids that assess the concept
    [conceptId: string]: string[]
  }
}

export type LearningObjectivesToAssignmentsMap = {
  assignedLearningObjectives: AssignedLearningObjectivesMap
  testedLearningObjectives: TestedLearningObjectivesMap
  assignedObjectivesWithBlacklist: AssignedObjectivesWithBlacklistMap
}

export const buildLosToAssignmentMap = (
  data:
    | GQL.GetLearningObjectivesForSection.LearningObjectivesForSection
    | GQL.GetLearningObjectivesForCourse.LearningObjectivesForCourse
): LearningObjectivesToAssignmentsMap => ({
  assignedLearningObjectives: data.assignedLearningObjectives.reduce((res, curr) => {
    res[curr.learningObjectiveId] = curr.assignmentIds
    return res
  }, {}),
  testedLearningObjectives: data.testedLearningObjectives.reduce((res, curr) => {
    res[curr.learningObjectiveId] = curr.assignmentIds
    return res
  }, {}),
  assignedObjectivesWithBlacklist: data.assignedObjectivesWithBlacklist.reduce((res, curr) => {
    res[curr.learningObjectiveId] = curr.conceptBlacklist
    return res
  }, {}),
})
