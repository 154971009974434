import { InstitutionsComponentActions } from './actions'

export interface InstitutionsComponentState {
  query: string
  debouncedQuery: string
  sortBy: string
  sortDirection: string
  includeTestInstitutions: boolean
}

const defaultState = {
  query: '',
  debouncedQuery: '',
  sortBy: 'NAME',
  sortDirection: 'ASC',
  includeTestInstitutions: false,
}

export function reducer(
  state: InstitutionsComponentState = defaultState,
  action: InstitutionsComponentActions.AllActions
): InstitutionsComponentState {
  switch (action.type) {
    case InstitutionsComponentActions.Actions.UPDATE_INSTITUTIONS_QUERY:
      return {
        ...state,
        query: action.query,
      }
    case InstitutionsComponentActions.Actions.UPDATE_INSTITUTIONS_DEBOUNCED_QUERY:
      return {
        ...state,
        debouncedQuery: action.query,
      }
    case InstitutionsComponentActions.Actions.UPDATE_INSTITUTIONS_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case InstitutionsComponentActions.Actions.UPDATE_INSTITUTIONS_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: action.sortDirection,
      }
    case InstitutionsComponentActions.Actions.TOGGLE_INCLUDE_TEST_INSTITUTIONS:
      return {
        ...state,
        includeTestInstitutions: !state.includeTestInstitutions,
      }
    default:
      return state
  }
}
