import { Action } from 'redux'

export type ActiveInstitution = { institutionId: Commons.InstitutionId } & Action
export const SET_ACTIVE_INSTITUTION = 'SET_ACTIVE_INSTITUTION'
export function setActiveInstitution(institutionId: Commons.InstitutionId): ActiveInstitution {
  return {
    type: SET_ACTIVE_INSTITUTION,
    institutionId,
  }
}
