import { all, takeEvery, select, call, put } from 'redux-saga/effects'
import { keyBy, mapValues } from 'lodash'
import { SagaIterator } from 'redux-saga'
import { getOrCreateClient as getApolloClient } from 'app/frontend/helpers/apollo'
import {
  ADD_RANDOM_ASSESSMENT_QUESTIONS,
  DELETE_ASSESSMENT_QUESTIONS,
} from 'app/frontend/pages/material/teach/compositions/connected/assessment-questions'
import { GET_ASSESSMENT_SEQUENCES } from 'app/frontend/compositions/connected/get-assessment-sequences'
import * as GET_SEQUENCE from 'app/frontend/compositions/connected/get-sequence/get-sequence.gql'
import { queryCollection } from 'app/frontend/helpers/apollo/query'
import {
  AssessmentQuestionAction,
  AddRandomAssessmentQuestionsAction,
  editConceptsSucceeded,
  editConceptsFailed,
  editConceptsStaged,
  DeleteAssessmentQuestionsAction,
} from './assessment-lo-selection-actions'
import { getQuestionPreferencesState } from './question-preferences/question-preferences-reducer'
import { GET_ASSIGNMENT } from '../../compositions/connected/get-assignment'

export function* handleAddRandomAssessmentQuestions({
  assessmentId,
  topicId,
  loConcepts,
}: AddRandomAssessmentQuestionsAction) {
  const affectedConcepts = mapValues(
    keyBy(loConcepts, loConcept => loConcept.learningObjectiveId),
    loConcept => loConcept.conceptIds
  )
  try {
    // Put all affected concepts in a Saving state
    yield put(editConceptsStaged(assessmentId, topicId, affectedConcepts))

    // Add questions and refetch necessary data
    yield call(addRandomQuestions, assessmentId, topicId, loConcepts)

    // Remove saving state from affected concepts
    yield put(editConceptsSucceeded(assessmentId, topicId, affectedConcepts))
  } catch (e) {
    // Put all affected concepts in error state
    yield put(editConceptsFailed(assessmentId, topicId, affectedConcepts))
  }
}

/**
 * Makes a graphql mutation to add random questions to an assessment. After the mutation completes, graphql queries
 * are made to refetch:
 *  1. The assessment to retreive any newly created PathLearningObjectives
 *  2. Sequences from the CMS of all questions on the assessment
 *  3. Updated AssesmentSequences on the assessment
 *
 * @param assessmentId  AssessmentId to add random questions too
 * @param topicId       TopicId that the questions being added belong to
 * @param loConcepts    List of objects that map a set of concept ids to a learning objective id
 */
export function* addRandomQuestions(
  assessmentId: string,
  topicId: string,
  loConcepts: Commons.LoConcept[]
) {
  const client = getApolloClient()
  const questionPreferenceState = yield select(getQuestionPreferencesState)
  const variables: GQL.MutationAddRandomAssessmentQuestionsArgs = {
    request: {
      assessmentId,
      topicId,
      loConcepts,
      numQuestionsPerLo: questionPreferenceState.count,
      questionType: questionPreferenceState.questionType as GQL.QuestionType,
    },
  }

  const refetchSequencesVariables: GQL.GetAssessmentSequences.Variables = {
    assessmentId,
  }

  const { data } = yield call(client.mutate, {
    mutation: ADD_RANDOM_ASSESSMENT_QUESTIONS,
    variables,
    context: { silenceErrors: true },
  })

  const sequenceIds =
    data?.addRandomAssessmentQuestions?.questions?.map(question => ({
      sequenceId: question.sequenceId,
    })) || []

  yield call(queryCollection, client, GET_SEQUENCE, sequenceIds)

  yield call(client.query, {
    query: GET_ASSIGNMENT,
    variables: { id: assessmentId },
    fetchPolicy: 'network-only',
    context: { silenceErrors: true },
  })

  yield call(client.query, {
    query: GET_ASSESSMENT_SEQUENCES,
    variables: refetchSequencesVariables,
    fetchPolicy: 'network-only',
    context: { silenceErrors: true },
  })
}

export function* handleDeleteAssessmentQuestions({
  assessmentId,
  topicId,
  loConcepts,
  questionIds,
}: DeleteAssessmentQuestionsAction) {
  const affectedConcepts = mapValues(
    keyBy(loConcepts, loConcept => loConcept.learningObjectiveId),
    loConcept => loConcept.conceptIds
  )

  try {
    // Put all affected concepts in a Saving state
    yield put(editConceptsStaged(assessmentId, topicId, affectedConcepts))

    // Delete questions and refetch necessary data
    yield call(deleteAssessmentQuestions, assessmentId, questionIds)

    // Remove saving state from affected concepts
    yield put(editConceptsSucceeded(assessmentId, topicId, affectedConcepts))
  } catch (e) {
    // Put all affected concepts in error state
    yield put(editConceptsFailed(assessmentId, topicId, affectedConcepts))
  }
}

/**
 * Make graphql mutation to delete questions from an assessment. After the mutation completes, a graphql query is made
 * to refetch the assessment sequences on the given assessment
 *
 * @param assessmentId  AssessmentId to add random questions too
 * @param questionIds   Ids of question to delete
 */
export function* deleteAssessmentQuestions(assessmentId: string, questionIds: string[]) {
  const client = getApolloClient()

  yield call(client.mutate, {
    mutation: DELETE_ASSESSMENT_QUESTIONS,
    variables: {
      assessmentId,
      questionIds,
    },
    context: { silenceErrors: true },
  })

  // Doing this here instead of as a refetchQueries on the mutation because we want to
  // maintain the loading state until it is complete
  yield call(client.query, {
    query: GET_ASSESSMENT_SEQUENCES,
    variables: { assessmentId },
    fetchPolicy: 'network-only',
    context: { silenceErrors: true },
  })

  yield call(client.query, {
    query: GET_ASSIGNMENT,
    variables: { id: assessmentId },
    fetchPolicy: 'network-only',
    context: { silenceErrors: true },
  })
}

export function* processAssessmentConceptsSelection(): SagaIterator {
  yield all([
    takeEvery<AddRandomAssessmentQuestionsAction>(
      AssessmentQuestionAction.AddRandomQuestions,
      handleAddRandomAssessmentQuestions
    ),
    takeEvery<DeleteAssessmentQuestionsAction>(
      AssessmentQuestionAction.DeleteQuestions,
      handleDeleteAssessmentQuestions
    ),
  ])
}
