import { post } from 'app/frontend/api/index'
import { makeApiActions } from 'app/frontend/api/api-redux'

export const sendTiming = (metrics: Metrics.TimingMetricsType[]): Promise<Response> => {
  return post('/metrics/timing', { metrics })
}

export const API_REPORT_ATOM_RENDER = makeApiActions('API_REPORT_ATOM_RENDER')

export const reportAtomRenders = (
  atomRenderReports: Content.AtomRenderReport[]
): Promise<Response> => {
  return post('/metrics/atom', atomRenderReports)
}
