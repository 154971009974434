import {
  LearnFlowLoadAction,
  API_GET_ENROLLMENT_CONTENT,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import { Reducer } from 'redux'

export interface HistoryReducerState extends Commons.IHistory {}

export const reducer = (
  state: HistoryReducerState = { sequences: [] },
  action: LearnFlowLoadAction & ApiSuccessAction
) => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_ENROLLMENT_CONTENT.SUCCESS:
      return action.response.history || state
    default:
      return state
  }
}

export default reducer as Reducer<HistoryReducerState>
