import * as React from 'react'
import { IconButton, IconButtonProps } from 'accessible-react-toolbox/lib/button'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import * as styles from './button.css'
import { isModifiedEvent } from 'app/frontend/components/material/button/button'
import { Tooltip } from 'app/frontend/components/tooltip'

interface ButtonIconMaterialProps {
  setRef?: (elem: any) => void
  dark?: boolean
  path?: string
  tooltip?: string
}

interface DispatchProps {
  goToPath: (path: string) => void
}

type OwnProps = IconButtonProps & ButtonIconMaterialProps
type Props = DispatchProps & OwnProps

export const _ButtonIconMaterial: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { dark, path, goToPath, tooltip, ...rest } = props

  const onClick = (e: MouseEvent) => {
    if (props.onClick) {
      props.onClick(e)
    }
    if (path && !isModifiedEvent(e)) {
      e.preventDefault()
      goToPath(path)
    }
  }

  const href = path ?? props.href

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          {...rest}
          href={href}
          onClick={onClick}
          theme={{ button: dark ? styles.darkButton : styles.lightButton }}
        />
      </Tooltip>
    )
  }

  return (
    <IconButton
      {...rest}
      href={href}
      onClick={onClick}
      theme={{ button: dark ? styles.darkButton : styles.lightButton }}
    />
  )
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    goToPath: (path: string) => dispatch(push(path)),
  }
}

export const ButtonIconMaterial = connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(_ButtonIconMaterial)

ButtonIconMaterial.displayName = 'ButtonIconMaterial'
