import * as React from 'react'
import * as _ from 'lodash'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'
import YoutubeVideo from 'app/frontend/components/video/youtube-video'
import type { YoutubeVideo as YoutubeVideoType } from 'app/frontend/components/video/youtube-video'
import VimeoVideo from 'app/frontend/components/video/vimeo-video'
import type { VimeoVideo as VimeoVideoType } from 'app/frontend/components/video/vimeo-video'
import Transcript from './video-transcript'

/**
 * Renders a video player for a given atom.
 */
interface IVideoAtomProps {
  atom: GQL.AtomMedia
  autoplay: boolean
  className?: string
  contentManager: ContentManagerActionsType
}

export default class VideoAtom extends React.Component<IVideoAtomProps, {}> {
  private video: React.RefObject<YoutubeVideoType | VimeoVideoType>

  constructor(props) {
    super(props)

    this.video = React.createRef<YoutubeVideoType | VimeoVideoType>()
  }

  renderVideo() {
    const atomData = this.props.atom.data as GQL.AtomVideoData
    const provider = atomData.provider
    const videoProps = {
      clipId:
        this.props.atom.id +
        '-' +
        String(atomData.providersId) +
        '-' +
        String(atomData.start) +
        '-' +
        String(atomData.end),
      videoId: atomData.providersId,
      clipDuration: atomData.clipDuration,
      atomId: this.props.atom.id,
      autoplay: this.props.autoplay,
      providerId: atomData.provider,
      end: atomData.end,
      start: atomData.start,
      contentManager: this.props.contentManager,
    }

    if (provider === 'YOUTUBE') {
      return <YoutubeVideo ref={this.video as React.RefObject<YoutubeVideoType>} {...videoProps} />
    } else if (provider === 'VIMEO') {
      return <VimeoVideo ref={this.video as React.RefObject<VimeoVideoType>} {...videoProps} />
    }
  }

  render(): JSX.Element {
    const { transcriptHtml } = this.props.atom.data as GQL.AtomVideoData
    const { className } = this.props

    return (
      <div className={className}>
        {this.renderVideo()}
        {!_.isEmpty(transcriptHtml) && <Transcript transcriptHtml={transcriptHtml} />}
      </div>
    )
  }
}
