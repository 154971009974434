import { Reducer } from 'redux'
import * as _ from 'lodash'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_GET_ENROLLMENT_CONTENT,
  LearnFlowLoadAction,
  UpdateContentAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { getLearnFlowHistory } from '../../helpers/reducer-helper'
import {
  API_SEND_LEARN_EVENTS,
  SendEventsAction,
} from 'app/frontend/pages/material/learn/learn-flow/events/learn-flow-events-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { isInstructional } from 'app/frontend/pages/material/learn/learn-flow/helpers'

export interface ILearnFlowHistoryReducer {
  // Number of questions a user has seen so far.
  questionsCompleted: number
  // Number of instructions a user has seen so far.
  instructionsCompleted: number
  // Number of questions has answered so far (correct and incorrect).
  questionsAnswered: number
}

const defaultState = {
  questionsCompleted: 0,
  instructionsCompleted: 0,
  questionsAnswered: 0,
}

export const getLearnFlowUIHistory = (state: LearnControllerState): ILearnFlowHistoryReducer => {
  return state.ui.learnFlow.history
}

type HistoryAction =
  | (LearnFlowLoadAction & ApiSuccessAction)
  | UpdateContentAction
  | (SendEventsAction & ApiSuccessAction)

export const reducer = (
  state: ILearnFlowHistoryReducer = defaultState,
  action: HistoryAction
): ILearnFlowHistoryReducer => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_ENROLLMENT_CONTENT.SUCCESS:
      return getLearnFlowHistory(
        (action as LearnFlowLoadAction & ApiSuccessAction).response.history
      )
    case API_SEND_LEARN_EVENTS.SUCCESS:
      const newState = { ...state }
      const events = (action as ApiSuccessAction).args[1] as Learn.IEvent[]
      events.forEach(event => {
        if (event.type === 'SUBMIT_COMPOUND') {
          const learnStates = (action as ApiSuccessAction).response
            .states as Learn.IStateAndContent[]
          const matchingLearnState = _.find(
            learnStates,
            learnState => learnState.compoundInstance.id === event.compoundInstanceId
          )
          if (matchingLearnState) {
            if (isInstructional(matchingLearnState)) {
              newState.instructionsCompleted += 1
            } else {
              newState.questionsCompleted += 1
              newState.questionsAnswered += 1
            }
          }
        }
      })
      return newState
    default:
      return state
  }
}

export default reducer as Reducer<ILearnFlowHistoryReducer>
