import * as React from 'react'
import { ClickAwayListener, Grow, Paper } from '@mui/material'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import { makeStyles } from '@mui/styles'

export type Props = {
  open: boolean
  anchorRef: React.RefObject<HTMLElement>
  handleClose: (event: Event | React.SyntheticEvent) => void
  placement: PopperPlacementType
  styles: React.CSSProperties
  children: React.ReactNode
}

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 200,
    boxShadow: '0rem 0.125rem 1.25rem 0rem rgba(0, 0, 0, 0.02)',
  },
}))

export const PopperMenu: React.FunctionComponent<Props> = props => {
  const { open, anchorRef, handleClose, children, placement, styles } = props
  const menuStyle = useStyles()

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      placement={placement}
      transition
      disablePortal={true}
      className={menuStyle.popper}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={styles}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <div>{children}</div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}
