import * as React from 'react'
import * as moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { change, formValueSelector, getFormValues, reduxForm } from 'redux-form'
import { tns } from 'app/frontend/helpers/translations/i18n'
import SelectLabelInput from 'app/frontend/compositions/data/assessment-select-label-input/select-label-input'
import { FieldTextInput } from 'app/frontend/compositions/data/redux-form-fields'
import { Heading } from 'app/frontend/components/material/heading'
import { Box } from 'app/frontend/components/material/box'
import {
  isPopulated,
  shouldErrorOnNewEndsAt,
  shouldWarnOnNewEndsAt,
  validateAssessmentTime,
  validateMomentDateRange,
} from 'app/frontend/helpers/validation'
import { DatetimeSelectorFields } from 'app/frontend/pages/material/teach/compositions/connected/coursework-modal/coursework-customize-adaptive/datetime-selector-field'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { AssessmentSettingResetWarning } from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/assessment-setting-reset-warning/assessment-setting-reset-warning'
import * as Feature from 'app/frontend/helpers/feature'
import { StudentResultsSetting } from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/form-fields/view-results-setting/student-results-setting'
import * as boxStyles from './option-boxes.css'
import {
  CopyAssessmentToken,
  LockDownBrowserSetting,
  LtiLinkSetting,
  MultipleAttemptsSetting,
  OfflineAssessmentSetting,
  PasswordSetting,
  ReviewCenterSetting,
  ShuffleQuestionOrderSetting,
  TimedAssessmentSetting,
  ViewResultsSetting,
} from './form-fields'
import { ASSESSMENT_SETTINGS_FORM, FormNames, FormValues } from './form-fields/helper'

const t = tns('teach:assessment_builder')

interface OwnProps {
  assessment: GQL.GetAssignment.Assignment
  initialValues: Partial<FormValues>
  resetValues: { startsAt: number; endsAt: number }
  course: GQL.GetCourse.Course
  section: GQL.GetSection.Section
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  onSubmit: (formValues: Partial<FormValues>) => void
  hasEditPermission: boolean
  onFieldReset: (resetFields: Set<FormNames>) => void
}

type StateProps = {
  isOfflineAssessment: boolean
  formValues: Partial<FormValues>
}

type ReduxFormProps = {
  handleSubmit: () => React.EventHandler<React.FormEvent<HTMLFormElement>>
}

type FormValidator = {
  [key: string]: string | null
}

type Props = OwnProps & ReduxFormProps & StateProps

export const _SettingsModalForm: React.FunctionComponent<Props> = ({
  isCourseAssignmentOnSection,
  hasStudentStartedAssignment,
  assessment,
  course,
  section,
  handleSubmit,
  hasEditPermission,
  onFieldReset,
  resetValues,
}) => {
  const isUpgradeSettingEnabled = Feature.isEnabled('upgradeAssessmentSettingFlag')
  const dispatch = useDispatch()
  const stateProps: StateProps = useSelector<TeachControllerState, StateProps>(
    (state: TeachControllerState) => {
      const isOfflineAssessment = isOfflineAssessmentSelector(state)
      const formValues = useSelector(getFormValues(ASSESSMENT_SETTINGS_FORM)) as FormValues
      return {
        isOfflineAssessment,
        formValues,
      }
    }
  )
  const isLmsIntegrated = course && course.lmsType !== 'NONE'
  const isReadOnly = isCourseAssignmentOnSection || !hasEditPermission
  const isDisabled = !isCourseAssignmentOnSection && !hasEditPermission

  const onPasswordOverrideReset = () => {
    onFieldReset(new Set([FormNames.PASSWORD]))
  }
  const onDatesOverrideReset = () => {
    onFieldReset(new Set([FormNames.STARTS_AT]))
  }
  const onLDBOverrideReset = () => {
    onFieldReset(new Set([FormNames.USE_LDB]))
  }
  const showDateResetLink =
    isCourseAssignmentOnSection && stateProps?.formValues?.startsOrEndsAtOverridden
  const showPasswordResetLink =
    isCourseAssignmentOnSection && stateProps?.formValues?.passwordOverridden
  const showLDBResetLink = isCourseAssignmentOnSection && stateProps?.formValues?.ldbOverridden

  React.useEffect(() => {
    if (
      resetValues &&
      isCourseAssignmentOnSection &&
      (stateProps.formValues?.startsAt.valueOf() !== resetValues.startsAt ||
        stateProps.formValues?.endsAt.valueOf() !== resetValues.endsAt)
    ) {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.STARTS_OR_ENDS_AT_OVERRIDDEN, true))
    }
  }, [
    resetValues?.startsAt,
    resetValues?.endsAt,
    stateProps.formValues?.endsAt,
    stateProps.formValues?.startsAt,
  ])

  return (
    <form name={ASSESSMENT_SETTINGS_FORM} onSubmit={handleSubmit}>
      <FieldTextInput
        data-test="settings-modal-field-name"
        label={t('assessment_name')}
        name={FormNames.NAME}
        disabled={isReadOnly}
      />
      <Box
        direction="row"
        flex="grow"
        justify="between"
        responsive={true}
        wrap={true}
        pad={{ between: 'small' }}
      >
        <DatetimeSelectorFields
          dateName={FormNames.STARTS_AT}
          timeName={FormNames.STARTS_AT_TIME}
          dataBiPrefix="starts-at-"
          dateSelectorLabel={t('begin_date')}
          timeSelectorLabel={t('begin_time')}
          minDate={moment(section ? section.startsAt : course.startsAt)}
          maxDate={moment(section ? section.endsAt : course.endsAt)}
          disabled={isDisabled}
          iconButtonAriaLabel={t('start_date_label_description')}
        />
        <DatetimeSelectorFields
          dateName={FormNames.ENDS_AT}
          timeName={FormNames.ENDS_AT_TIME}
          dataBiPrefix="ends-at-"
          dateSelectorLabel={t('finish_date')}
          timeSelectorLabel={t('finish_time')}
          minDate={moment(section ? section.startsAt : course.startsAt)}
          maxDate={moment(section ? section.endsAt : course.endsAt)}
          disabled={isDisabled}
          iconButtonAriaLabel={t('due_date_label_description')}
        />
      </Box>
      {showDateResetLink && (
        <AssessmentSettingResetWarning
          warningMessage={t('section_override_due_date_reset_warning')}
          onReset={onDatesOverrideReset}
          dataBi="date-reset-warning"
        />
      )}
      <Box pad={{ top: 'medium', bottom: 'small' }}>
        <SelectLabelInput disabled={isReadOnly} />
      </Box>
      <Box data-test="features" pad={{ top: 'medium', bottom: 'small' }}>
        <Heading tag="h4"> {t('settings_features')}</Heading>
        <Box full="page" className={boxStyles.assessmentOptions}>
          <ReviewCenterSetting isReadOnly={isReadOnly} course={course} section={section} />
          <OfflineAssessmentSetting isReadOnly={isReadOnly || hasStudentStartedAssignment} />
          <ShuffleQuestionOrderSetting isReadOnly={isReadOnly} />
          {!stateProps?.isOfflineAssessment && (
            <>
              <TimedAssessmentSetting isReadOnly={isReadOnly || hasStudentStartedAssignment} />
              <MultipleAttemptsSetting isReadOnly={isReadOnly} assessmentId={assessment.id} />
              {/*TODO FF cleanup under YALA-1191*/}
              {!isUpgradeSettingEnabled && <ViewResultsSetting isReadOnly={isReadOnly} />}
            </>
          )}
        </Box>
      </Box>
      {/*TODO FF cleanup under YALA-1191*/}
      {isUpgradeSettingEnabled && (
        <Box data-test="student_results" pad={{ top: 'medium', bottom: 'small' }}>
          <Heading tag="h4" data-test="question-results-settings">
            {t('setting_results')}
          </Heading>
          <Box full="page" className={boxStyles.assessmentOptions}>
            <StudentResultsSetting
              isReadOnly={isReadOnly}
              assessmentId={assessment.id}
              isLateSubmissionEnabled={course.quizLateSubmissionEnabled}
              assessmentEndsAt={assessment.endsAt}
            />
          </Box>
        </Box>
      )}

      {!stateProps?.isOfflineAssessment && (
        <>
          <Box data-test="security-settings" pad={{ top: 'medium', bottom: 'small' }}>
            <Heading tag="h4"> {t('settings_security')}</Heading>
            <Box full="page" className={boxStyles.assessmentOptions}>
              <PasswordSetting
                isReadOnly={isDisabled}
                isCourseAssignmentOnSection={isCourseAssignmentOnSection}
                hasCoursePassword={assessment.quizConfiguration.isPasswordProtected}
                onReset={onPasswordOverrideReset}
              />
              {showPasswordResetLink && (
                <Box full="page" pad="medium">
                  <AssessmentSettingResetWarning
                    warningMessage={t('section_override_password_reset_warning')}
                    onReset={onPasswordOverrideReset}
                    dataBi="password-reset-warning"
                  />
                </Box>
              )}
              <LockDownBrowserSetting
                isReadOnly={isDisabled}
                isCourseAssignmentOnSection={isCourseAssignmentOnSection}
              />
              {showLDBResetLink && (
                <Box full="page" pad="medium">
                  <AssessmentSettingResetWarning
                    warningMessage={t('section_override_ldb_reset_warning')}
                    onReset={onLDBOverrideReset}
                    dataBi="ldb-reset-warning"
                  />
                </Box>
              )}
            </Box>
          </Box>
          {isLmsIntegrated && (
            <Box data-test="lms-settings" pad={{ top: 'medium', bottom: 'small' }}>
              <Heading tag="h4"> {t('settings_lms')}</Heading>
              <Box full="page" className={boxStyles.assessmentOptions}>
                <LtiLinkSetting courseworkId={assessment.lmsCourseworkId} />
              </Box>
            </Box>
          )}
        </>
      )}
      <Box data-test="copy-settings" pad={{ top: 'medium', bottom: 'small' }}>
        <Heading tag="h4"> {t('settings_copy')}</Heading>
        <Box full="page" className={boxStyles.assessmentOptions}>
          <CopyAssessmentToken
            assessmentId={assessment.id}
            sectionId={section && section.id}
            courseId={course.id}
          />
        </Box>
      </Box>
    </form>
  )
}

_SettingsModalForm.displayName = 'SettingsModalForm'

const isOfflineAssessmentSelector = (state: TeachControllerState): boolean => {
  const selector = formValueSelector(ASSESSMENT_SETTINGS_FORM)
  return selector(state, FormNames.PRINTABLE)
}

export const warnDueDateChange = (
  values: Partial<FormValues>,
  props: Partial<OwnProps>
): FormValidator => {
  const newEndsAt = values.endsAt
  const initialEndsAt = props.assessment.endsAt
  return {
    [FormNames.ENDS_AT]: shouldWarnOnNewEndsAt(initialEndsAt, newEndsAt?.valueOf())
      ? t('settings_student_grades_update_warning')
      : null,
  }
}

export const validate = (values: Partial<FormValues>, props: Partial<OwnProps>): FormValidator => {
  const { startsAt, endsAt, password, passwordEnabled, maxDuration } = values
  const initialEndsAt = props.initialValues[FormNames.ENDS_AT]

  const dueDateChangeErrorMessage = shouldErrorOnNewEndsAt(
    initialEndsAt.valueOf(),
    endsAt?.valueOf()
  )
    ? t('settings_due_date_in_past_error')
    : null

  return {
    [FormNames.ENDS_AT]:
      validateMomentDateRange(startsAt, endsAt) || dueDateChangeErrorMessage || null,
    [FormNames.PASSWORD]:
      passwordEnabled && !isPopulated(password) ? t('settings_password_error') : null,
    [FormNames.TIMER]:
      maxDuration && validateAssessmentTime(maxDuration) ? t('invalid_time') : null,
  }
}

export const SettingsModalForm = reduxForm({
  form: ASSESSMENT_SETTINGS_FORM,
  enableReinitialize: true,
  touchOnChange: true,
  validate,
  warn: warnDueDateChange,
})(_SettingsModalForm)
