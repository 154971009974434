import { DocumentNode as GraphqlDocumentNode } from 'graphql'
import { DocumentNode as ApolloDocumentNode, QueryOptions, ApolloClient } from '@apollo/client'

export type DocumentNode = GraphqlDocumentNode & ApolloDocumentNode

/**
 * This function will make individual graphql queries for each variables object in queryVariables.
 * By doing this, we can take advantage of Apollo's cache to only make network requests for queries
 * and entities not yet cached.
 *
 * @param client          ApolloClient to call query from
 * @param query           Graphql query to be called with variables object from queryVariables
 * @param queryVariables  An array of arguments to query individually for
 * @param queryOptions    Query options to provide to each graphql query executed. Every query will
 *                        use the same query parameters
 */
export async function queryCollection<T, V>(
  client: ApolloClient<any>,
  query: DocumentNode,
  queryVariables: V[],
  queryOptions: Partial<QueryOptions> = {}
): Promise<T[]> {
  if (!queryVariables.length) {
    return []
  }

  return Promise.all<T>(
    queryVariables.map(async (variables: V) => {
      const queryData = await client.query<T, V>({
        ...queryOptions,
        query,
        variables,
      })

      return queryData?.data
    })
  )
}
