import { post } from './index'

export const forgotPassword = (data: any): Promise<Response> => {
  return post('/forgot', data)
}

export const resetPassword = (data: any): Promise<Response> => {
  return post('/reset', data)
}

export const sendVerificationEmail = (redirection: string): Promise<Response> => {
  return post('/account/email-verification', { r: redirection })
}

export const editAccount = (data: any): Promise<Response> => {
  return post('/account/update', data)
}

export const deleteAccount = (): Promise<Response> => {
  return post('/account/delete')
}
