import * as React from 'react'
import { Checkbox } from '@mui/material'
import { TextInputMaterial } from 'app/frontend/components/material/textinput/textinput'
import { FormControlLabelCheckbox } from 'app/frontend/components/material/form-control-label'
import { Icon } from 'app/frontend/components/material/icon'
import * as classnames from 'classnames'
import { LoginAlert } from 'app/frontend/components/material/login-alert'
import RecaptchaWidget from 'app/frontend/components/material/login/recaptcha-widget'
import * as styles from './login.css'

export const PasswordError = field =>
  field.meta.error ? <LoginAlert error={field.meta.error} /> : null

export const Redirect = field =>
  field.input.value ? <input type="hidden" {...field.input} /> : null

export const Recaptcha = field => <RecaptchaWidget recaptcha={field} />

export const Username = field => (
  <TextInputMaterial
    label={field.label}
    className={styles.inputField}
    error={field.meta.dirty && !field.meta.active && field.meta.error}
    data-pii="true"
    autoComplete="email"
    {...field.input}
  />
)

export const Password = field => {
  const iconClass = classnames(styles.togglePasswordIcon, {
    [styles.togglePasswordActive]: field.showPassword,
  })

  return (
    <div className={styles.togglePasswordGroup}>
      <TextInputMaterial
        label={field.label}
        type={field.showPassword ? 'text' : 'password'}
        className={styles.inputField}
        autoComplete="current-password"
        {...field.input}
      />
      <button
        type="button"
        onClick={field.togglePasswordVisibility}
        className={styles.togglePassword}
        aria-label={field.passwordText}
        aria-pressed={field.showPassword}
      >
        <Icon
          name={field.showPassword ? 'icon-visibility_on' : 'icon-visibility_off'}
          className={iconClass}
          ariaHidden
          focusable={false}
        />
      </button>
    </div>
  )
}

export const Remember = field => (
  <div className={styles.rememberMeContainer}>
    <FormControlLabelCheckbox
      control={<Checkbox checked={field.input.value} {...field.input} />}
      className={styles.rememberMe}
      label={field.label}
      checked={field.input.value}
    />
  </div>
)
