import { PAYMENTS_MODAL } from '../'
import { SHOW_MODAL } from 'app/frontend/components/material/modal/modal-actions'
import { API_PAYMENT_SUBMIT_ACCESS_CODE, SET_PAYMENT_MODAL_DISPLAY_STATE } from './actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
export type PAYMENTS_MODAL_DISPLAY_STATE =
  | 'PLAN_PICKER'
  | 'ACCESS_CODE'
  | 'COURTESY_ACCESS'
  | 'PAYMENT_COMPLETE'

export type PaymentsModalState = {
  loading: boolean
  section: Commons.IRetailClass
  displayState: PAYMENTS_MODAL_DISPLAY_STATE
}

export const getPaymentsModalState = (state: LearnControllerState): Partial<PaymentsModalState> =>
  state.ui.modal.payments

export const reducer = (
  state: Partial<PaymentsModalState> = {},
  action
): Partial<PaymentsModalState> => {
  switch (action.type) {
    case SHOW_MODAL:
      if (action.name === PAYMENTS_MODAL) {
        return {
          displayState: 'PLAN_PICKER',
          section: action.options.section,
          loading: false,
        }
      }
      return state
    case SET_PAYMENT_MODAL_DISPLAY_STATE:
      return {
        ...state,
        displayState: action.state,
      }
    case API_PAYMENT_SUBMIT_ACCESS_CODE.INIT:
      return {
        ...state,
        loading: true,
      }
    case API_PAYMENT_SUBMIT_ACCESS_CODE.CANCEL:
    case API_PAYMENT_SUBMIT_ACCESS_CODE.ERROR:
    case API_PAYMENT_SUBMIT_ACCESS_CODE.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
