import * as React from 'react'
import { connect } from 'react-redux'
import { updateModal } from 'app/frontend/components/material/modal/modal-actions'
import { Heading } from 'app/frontend/components/material/heading'
import { CardLinkGroup, CardLink } from 'app/frontend/compositions/data/card-link'
import {
  CUSTOM_QUESTIONS_MODAL,
  CustomQuestionModalOptions,
  View,
} from './custom-questions-modal-types'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './custom-questions-modal.css'

const t = tns('teach:custom_questions_modal')

type DispatchProps = {
  updateModalOptions: (options: Partial<CustomQuestionModalOptions>) => void
}

type Props = DispatchProps

export class _CustomQuestionsTypePicker extends React.Component<Props, {}> {
  onClickMC = (): void => this.props.updateModalOptions({ view: View.MultipleChoice })
  onClickFR = (): void => this.props.updateModalOptions({ view: View.FreeResponse })

  render(): JSX.Element {
    return (
      <>
        <Heading size="h3" align="center">
          {t('select_custom_question_type_heading')}
        </Heading>
        <CardLinkGroup className={styles.cardLinkGroup}>
          <CardLink
            iconName="icon-multiple-choice"
            heading={t('select_custom_question_type_mc_heading')}
            cta={t('select_custom_question_type_mc_cta')}
            onClick={this.onClickMC}
            buttonDataBi="select-multi-choice-button"
          />
          <CardLink
            iconName="icon-free-response"
            heading={t('select_custom_question_type_fr_heading')}
            cta={t('select_custom_question_type_fr_cta')}
            onClick={this.onClickFR}
            buttonDataBi="select-free-response-button"
          />
        </CardLinkGroup>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  updateModalOptions: (options: Partial<CustomQuestionModalOptions>) =>
    dispatch(updateModal(CUSTOM_QUESTIONS_MODAL, options)),
})

export const CustomQuestionsTypePicker = connect<{}, DispatchProps, {}>(
  null,
  mapDispatchToProps
)(_CustomQuestionsTypePicker)
