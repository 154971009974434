import * as React from 'react'
import * as classnames from 'classnames'
import { t } from 'app/frontend/helpers/translations/i18n'
import * as styles from './legal-stuff.css'

interface ILegalStuffProps {
  forDistributor?: boolean
  className?: string
}

const LegalStuff: React.FunctionComponent<ILegalStuffProps> = ({
  forDistributor,
  className,
}): JSX.Element => {
  const termsOfServiceLink = forDistributor
    ? 'https://www.knewton.com/resources/terms-of-service-order-form/'
    : 'https://www.knewton.com/terms-of-service/'
  return (
    <div className={classnames(styles.bottomMatter, className)}>
      <p className={styles.copyright}>{t('copyright')}</p>
      <div>
        <a
          href="https://www.knewton.com/privacy-policy/"
          className={styles.bottomMatterLink}
          data-bi="privacy-link"
          aria-label={t('privacy_policy')}
        >
          {t('privacy')}
        </a>
        <a
          href={termsOfServiceLink}
          className={styles.bottomMatterLink}
          data-bi="terms-link"
          aria-label={t('terms_of_service')}
        >
          {t('terms')}
        </a>
        <a
          href="https://www.knewton.com/accessibility"
          className={styles.bottomMatterLink}
          data-bi="accessibility-link"
          aria-label={t('commitment_to_accessibility')}
        >
          {t('accessibility')}
        </a>
      </div>
    </div>
  )
}

LegalStuff.displayName = 'LegalStuff'

export default LegalStuff
