import * as React from 'react'
import * as moment from 'moment'

type DateTimePickerReducerProps = {
  dateTimeFormat: string
  value: moment.Moment
}

type DateTimePickerReducerState = [
  {
    inputValue: moment.Moment | false
    formattedInputValue: string
    isPickerOpen: boolean
    error: string
  },
  {
    showPicker: () => void
    hidePicker: () => void
    setText: (val: string) => void
    setError: (val: string) => void
    handleChange: (val: moment.Moment) => void
  }
]

type DateTimeActions =
  | {
      type: 'KEYBOARD_INPUT'
      value: string
    }
  | {
      type: 'CHANGE'
      value: moment.Moment
    }
  | {
      type: 'SET_ERROR'
      value: string
    }
  | {
      type: 'SHOW_PICKER'
    }
  | {
      type: 'HIDE_PICKER'
    }

type DateTimeState = {
  inputValue: moment.Moment | false
  formattedInputValue: string
  isPickerOpen: boolean
  error: string
}

export const dateTimePickerReducer = ({
  dateTimeFormat,
  value,
}: DateTimePickerReducerProps): DateTimePickerReducerState => {
  const [{ inputValue, formattedInputValue, isPickerOpen, error }, dispatch] = React.useReducer(
    (state: DateTimeState, action: DateTimeActions) => {
      switch (action.type) {
        case 'CHANGE':
          return {
            ...state,
            error: '',
            formattedInputValue: action.value ? action.value.format(dateTimeFormat) : '',
            inputValue: action.value,
          }
        case 'KEYBOARD_INPUT':
          return {
            ...state,
            error: '',
            formattedInputValue: action.value,
          }
        case 'SET_ERROR':
          return {
            ...state,
            error: action.value,
          }
        case 'SHOW_PICKER':
          return { ...state, error: '', isPickerOpen: true }
        case 'HIDE_PICKER':
          if (!state.isPickerOpen) {
            return state
          }
          return {
            ...state,
            inputValue: value,
            formattedInputValue: value ? value.format(dateTimeFormat) : '',
            isPickerOpen: false,
          }
        default:
          return state
      }
    },
    {
      isPickerOpen: false,
      inputValue: value,
      formattedInputValue: value ? value.format(dateTimeFormat) : '',
      error: '',
    }
  )

  // Actions
  const setText = (val: string) => dispatch({ type: 'KEYBOARD_INPUT', value: val })
  const setError = (val: string) => dispatch({ type: 'SET_ERROR', value: val })
  const handleChange = (val: moment.Moment) => dispatch({ type: 'CHANGE', value: val })
  const showPicker = () => dispatch({ type: 'SHOW_PICKER' })
  const hidePicker = () => dispatch({ type: 'HIDE_PICKER' })

  return [
    { inputValue, formattedInputValue, isPickerOpen, error },
    { showPicker, hidePicker, setText, setError, handleChange },
  ]
}
