import * as React from 'react'
import { connect } from 'react-redux'
import { tns } from 'app/frontend/helpers/translations/i18n'
import MaterialSnackbar from 'accessible-react-toolbox/lib/snackbar'
import { hideRegradeSnackbarAction } from './regrade-snackbar-actions'
import { getRegradeSnackbarState } from './regrade-snackbar-reducer'
import * as CurrentUser from 'app/frontend/helpers/current-user'
import { SEARCH_TASK_STATUSES_QUERY } from 'app/frontend/pages/material/teach/compositions/connected/search-task-statuses'
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc' // TODO ALPACA-757
import { ApolloQueryResult } from '@apollo/client'
import * as LogReporter from 'app/frontend/helpers/log-reporter'

const styles = require('./regrade-snackbar.css')

const t = tns('teach:regrade_snackbar')

const POLL_INTERVAL_MS = 5000
const TIMEOUT_PERIOD = 10000

const snackbarTheme = {
  snackbar: styles.snackbar,
  label: styles.label,
  button: styles.button,
  active: styles.active,
}

export enum RegradeState {
  STARTING = 'STARTING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export interface StateProps {
  userId: string
  updatedAfter: number
  startedAt: number
}

export interface DispatchProps {
  hideRegradeSnackbar: () => void
}

interface IRegradeSnackbarState {
  active: boolean
  clicked: boolean
  regradeState: RegradeState
  pendingTasks: number
}

type Props = StateProps & DispatchProps & WithApolloClient<{}>

export class RegradeSnackbar extends React.Component<Props, IRegradeSnackbarState> {
  private pollRegradeHandle

  constructor(props) {
    super(props)
    this.state = {
      active: false,
      clicked: false,
      regradeState: RegradeState.STARTING,
      pendingTasks: 0,
    }
  }

  componentDidMount(): void {
    this.setState({ active: true })
    this.pollRegradeHandle = setInterval(this.pollRegradeTask, POLL_INTERVAL_MS)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>): void {
    if (nextProps.startedAt !== this.props.startedAt) {
      clearInterval(this.pollRegradeHandle)
      this.pollRegradeHandle = setInterval(this.pollRegradeTask, POLL_INTERVAL_MS)

      this.setState({
        regradeState: RegradeState.STARTING,
        pendingTasks: 0,
      })
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.pollRegradeHandle)
  }

  private hide = (): void => {
    this.setState({ active: false })
    // delay needed for slide out animation
    if (this.props.hideRegradeSnackbar) {
      setTimeout(() => {
        this.props.hideRegradeSnackbar()
      }, 750)
    }
  }

  private onClick = (): void => {
    if (!this.state.clicked) {
      this.setState({ clicked: true })
      this.hide()
      window.location.reload()
    }
  }

  private createSearchRequest = (
    userId: string,
    createdAfter: number,
    count = 100,
    taskStatus?: GQL.TaskStatusStatus
  ): GQL.TaskStatusSearchInput => {
    const filters = {
      type: 'ASSIGNMENT_REGRADE' as GQL.TaskType,
      initiatorId: userId,
      status: taskStatus,
      createdAfter: createdAfter,
    }
    return {
      filters: filters,
      pagination: {
        count: count,
      },
    }
  }

  private checkRegradeStarted = async () => {
    const { userId, updatedAfter, client } = this.props

    if (this.state.regradeState === RegradeState.STARTING) {
      const taskCreatedRequest = this.createSearchRequest(userId, updatedAfter, 1)

      const searchTaskStatusesResult = (await client.query({
        query: SEARCH_TASK_STATUSES_QUERY,
        variables: { request: taskCreatedRequest },
        fetchPolicy: 'network-only',
      })) as ApolloQueryResult<GQL.SearchTaskStatuses.Query>
      if (searchTaskStatusesResult.errors) {
        LogReporter.error(t('error_searching'), searchTaskStatusesResult.errors)
        return false
      }
      return !!searchTaskStatusesResult.data.searchForTaskStatuses.results.length
    } else {
      return true
    }
  }

  private pollRegradeTask = async () => {
    const { userId, updatedAfter, startedAt, client } = this.props

    const started = await this.checkRegradeStarted()

    if (started) {
      const pendingTaskRequest = this.createSearchRequest(
        userId,
        updatedAfter,
        100,
        'PENDING' as GQL.TaskStatusStatus
      )

      const searchTaskStatusesResult = (await client.query({
        query: SEARCH_TASK_STATUSES_QUERY,
        variables: { request: pendingTaskRequest },
        fetchPolicy: 'network-only',
      })) as ApolloQueryResult<GQL.SearchTaskStatuses.Query>

      if (searchTaskStatusesResult.errors) {
        LogReporter.error(t('error_searching'), searchTaskStatusesResult.errors)
      } else {
        const pendingTasks = searchTaskStatusesResult.data.searchForTaskStatuses.results.length

        const regradeState = pendingTasks === 0 ? RegradeState.DONE : RegradeState.IN_PROGRESS
        this.setState({
          regradeState: regradeState,
          pendingTasks: pendingTasks,
        })
      }
    } else if (Date.now() - startedAt < TIMEOUT_PERIOD) {
      this.setState({
        regradeState: RegradeState.STARTING,
        pendingTasks: 0,
      })
    } else {
      this.setState({
        regradeState: RegradeState.DONE,
        pendingTasks: 0,
      })
    }

    if (this.state.regradeState === RegradeState.DONE) {
      clearInterval(this.pollRegradeHandle)
    }
  }

  private getPendingTasksMessage = (pendingTasks: number): JSX.Element => {
    const label = t('updating_grades')
    const pendingTasksText = t('pending_regrade_tasks', {
      pendingTasks: pendingTasks < 100 ? `${pendingTasks}` : '100+',
    })
    return (
      <>
        <div className={styles.label}>{label}</div>
        <div className={styles.progressText}>{pendingTasksText}</div>
      </>
    )
  }

  render(): JSX.Element {
    const { regradeState, pendingTasks } = this.state

    if (CurrentUser.isTestUser()) {
      return null
    }

    const label =
      regradeState === RegradeState.STARTING
        ? t('starting_regrade')
        : regradeState === RegradeState.IN_PROGRESS
        ? this.getPendingTasksMessage(pendingTasks)
        : t('grades_updated')

    const action = regradeState === RegradeState.DONE ? t('refresh') : null
    return (
      <MaterialSnackbar
        label={label}
        action={action}
        onClick={this.onClick}
        active={this.state.active}
        timeout={null}
        theme={snackbarTheme}
      />
    )
  }
}

const mapStateToProps = (state: StateProps) => {
  const { userId, updatedAfter, lastShownAt } = getRegradeSnackbarState(state)
  return {
    userId: userId,
    updatedAfter: updatedAfter,
    startedAt: lastShownAt,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideRegradeSnackbar: () => dispatch(hideRegradeSnackbarAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(RegradeSnackbar))
