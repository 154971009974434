
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuizConfigurationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"QuizConfiguration"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maxDuration"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"useLdb"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"shuffleQuestions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isPasswordProtected"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"reviewCenterEnabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"maxNumAttempts"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"totalQuestions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"printable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"printableVariantsCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewResultsConfig"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"gradeAttemptsAs"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewResultsSettings"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gradeSetting"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"resultsSetting"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"answersSetting"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"commentSetting"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":355}};
    doc.loc.source = {"body":"fragment QuizConfigurationFields on QuizConfiguration {\n  maxDuration\n  useLdb\n  shuffleQuestions\n  isPasswordProtected\n  reviewCenterEnabled\n  maxNumAttempts\n  totalQuestions\n  printable\n  printableVariantsCount\n  viewResultsConfig\n  gradeAttemptsAs\n  viewResultsSettings {\n    gradeSetting\n    resultsSetting\n    answersSetting\n    commentSetting\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
