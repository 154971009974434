import { makeApiActions } from 'app/frontend/api/api-redux'
export const API_FORGOT_PASSWORD = makeApiActions('API_FORGOT_PASSWORD')
export const SUBMIT_FORGOT_PASSWORD_FORM = 'SUBMIT_FORGOT_PASSWORD_FORM'
export type SUBMIT_FORGOT_PASSWORD_FORM = 'SUBMIT_FORGOT_PASSWORD_FORM'

export type SubmitForgotPasswordFormAction = {
  type: SUBMIT_FORGOT_PASSWORD_FORM
  data: {
    email: string
  }
}

export const submitForgotPasswordForm = (data): SubmitForgotPasswordFormAction => ({
  type: SUBMIT_FORGOT_PASSWORD_FORM,
  data,
})

export const SHOW_SENT_EMAIL = 'SHOW_SENT_EMAIL'
export type SHOW_SENT_EMAIL = 'SHOW_SENT_EMAIL'
export const showSentEmail = () => ({
  type: SHOW_SENT_EMAIL,
})

export const API_RESET_PASSWORD = makeApiActions('API_RESET_PASSWORD')
export const SUBMIT_RESET_PASSWORD_FORM = 'SUBMIT_RESET_PASSWORD_FORM'
export type SUBMIT_RESET_PASSWORD_FORM = 'SUBMIT_RESET_PASSWORD_FORM'
export const submitResetPasswordForm = data => ({
  type: SUBMIT_RESET_PASSWORD_FORM,
  data,
})
