import * as React from 'react'
import { Query as ApolloQuery, QueryComponentOptions } from '@apollo/client/react/components' // TODO ALPACA-758

export type ErrorProps = {
  silenceErrors?: boolean
  errorMessage?: string
}

type Props<TData, TVariables> = QueryComponentOptions<TData, TVariables> & ErrorProps

export class Query<TData, TVariables> extends React.Component<Props<TData, TVariables>> {
  render() {
    const { silenceErrors, errorMessage, children, ...queryProps } = this.props
    // This should be the declaration: <ApolloQuery<TData, TVariables> {...queryProps}>
    // See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32588#issuecomment-459060799
    return (
      // @ts-ignore
      <ApolloQuery<TData, TVariables>
        context={{ silenceErrors, errorMessage }}
        {...(queryProps as JSX.LibraryManagedAttributes<typeof ApolloQuery, typeof queryProps>)}
      >
        {renderProps => <>{children(renderProps)}</>}
      </ApolloQuery>
    )
  }
}
