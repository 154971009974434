export namespace LtiCredentialsSearchActions {
  export enum ActionTypes {
    UpdateLtiCredentialsQuery = 'UPDATE_LTI_CREDENTIALS_QUERY',
    UpdateLtiCredentialsDebouncedQuery = 'UPDATE_LTI_CREDENTIALS_DEBOUNCED_QUERY',
    UpdateLtiCredentialsSortBy = 'UPDATE_LTI_CREDENTIALS_SORT_BY',
    UpdateLtiCredentialsSortOrder = 'UPDATE_LTI_CREDENTIALS_SORT_ORDER',
  }

  export function updateQuery(query: string) {
    return {
      type: ActionTypes.UpdateLtiCredentialsQuery,
      query,
    }
  }

  export function updateDebouncedQuery(query: string) {
    return {
      type: ActionTypes.UpdateLtiCredentialsDebouncedQuery,
      query,
    }
  }

  export function updateSortBy(sortBy: string) {
    return {
      type: ActionTypes.UpdateLtiCredentialsSortBy,
      sortBy,
    }
  }

  export function updateSortOrder(sortOrder: string) {
    return {
      type: ActionTypes.UpdateLtiCredentialsSortOrder,
      sortOrder,
    }
  }
}
