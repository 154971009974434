import { StudentActions } from './student-actions'
import {
  SortOrder,
  OUTLINE_SORT,
  IStudentAssignmentAnalyticsSortColumns,
  IStudentAssessmentAnalyticsSortColumns,
  IAnalyticsSortOrder,
  CompletionType,
} from 'app/frontend/data/analytics-data'

export const getStudentAnalytics = (state): StudentReducerState => state.ui.analytics.student

export const getAssignmentSortColumn = (state): IStudentAssignmentAnalyticsSortColumns =>
  getStudentAnalytics(state).assignmentSortColumn

export const getAssignmentSortOrder = (state): IAnalyticsSortOrder =>
  getStudentAnalytics(state).assignmentSortOrder

export const getAssessmentSortColumn = (state): IStudentAssessmentAnalyticsSortColumns =>
  getStudentAnalytics(state).assessmentSortColumn

export const getAssessmentSortOrder = (state): IAnalyticsSortOrder =>
  getStudentAnalytics(state).assessmentSortOrder

const defaultState = {
  assignmentSortColumn: OUTLINE_SORT,
  assignmentSortOrder: SortOrder.ASC,
  assessmentSortColumn: OUTLINE_SORT,
  assessmentSortOrder: SortOrder.ASC,
  assessmentStatusFilter: 'all',
  activeStudentId: null,
}

export type StudentReducerState = {
  assignmentSortColumn: IStudentAssignmentAnalyticsSortColumns
  assignmentSortOrder: IAnalyticsSortOrder
  // completionType should be deleted after legacy components using it are removed
  completionType?: CompletionType
  assessmentSortColumn: IStudentAssessmentAnalyticsSortColumns
  assessmentSortOrder: IAnalyticsSortOrder
  assessmentStatusFilter: string
  activeStudentId: string
}

export default (state: StudentReducerState = defaultState, action) => {
  switch (action.type) {
    case StudentActions.SetAssignmentAnalyticsSort:
      return {
        ...state,
        assignmentSortColumn: action.sortColumn,
        assignmentSortOrder:
          state.assignmentSortColumn === action.sortColumn ? action.sortOrder : SortOrder.ASC,
      }
    case StudentActions.SetCompletionType:
      return {
        ...state,
        completionType: action.completionType,
      }
    case StudentActions.SetAssessmentAnalyticsSort:
      return {
        ...state,
        assessmentSortColumn: action.sortColumn,
        assessmentSortOrder:
          state.assessmentSortColumn === action.sortColumn ? action.sortOrder : SortOrder.ASC,
      }
    case StudentActions.SetAssessmentStatusFilter:
      return {
        ...state,
        assessmentStatusFilter: action.filter,
      }
    default:
      return state
  }
}
