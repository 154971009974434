export type SHOW_REGRADE_SNACKBAR = 'SHOW_REGRADE_SNACKBAR'
export const SHOW_REGRADE_SNACKBAR: SHOW_REGRADE_SNACKBAR = 'SHOW_REGRADE_SNACKBAR'
export type HIDE_REGRADE_SNACKBAR = 'HIDE_REGRADE_SNACKBAR'
export const HIDE_REGRADE_SNACKBAR: HIDE_REGRADE_SNACKBAR = 'HIDE_REGRADE_SNACKBAR'

export type ShowRegradeSnackbarAction = {
  type: SHOW_REGRADE_SNACKBAR
}

export const showRegradeSnackbarAction = (): ShowRegradeSnackbarAction => {
  return {
    type: SHOW_REGRADE_SNACKBAR,
  }
}

export type HideRegradeSnackbarAction = {
  type: HIDE_REGRADE_SNACKBAR
}

export const hideRegradeSnackbarAction = (): HideRegradeSnackbarAction => {
  return {
    type: HIDE_REGRADE_SNACKBAR,
  }
}

export type RegradeSnackbarActions = ShowRegradeSnackbarAction | HideRegradeSnackbarAction
