import * as React from 'react'
import { InstructionalContent } from 'app/frontend/components/atom/instructional'
import { MultiChoiceContent } from 'app/frontend/components/atom/multi-choice'
import { LearnosityContent } from 'app/frontend/components/atom/learnosity'

export interface Props {
  atom: Content.IAtom
  hideCorrectAnswers?: boolean
  hideChoiceExplanation?: boolean
  hideExplanation?: boolean
  isVariationContentChanged?: boolean
  isFromPreview?: boolean
}

export const RawAtom: React.FunctionComponent<Props> = ({
  atom,
  hideCorrectAnswers,
  hideChoiceExplanation,
  hideExplanation,
  isVariationContentChanged,
  isFromPreview,
}) => {
  switch (atom.dataType) {
    case 'INSTRUCTIONAL_ATOM':
      return <InstructionalContent atom={atom} />
    case 'MULTIPLE_CHOICE_QUESTION':
      return (
        <MultiChoiceContent
          atom={atom}
          hideCorrectAnswers={hideCorrectAnswers}
          hideChoiceExplanation={hideChoiceExplanation || hideExplanation}
          hideGeneralExplanation={hideExplanation}
        />
      )
    case 'GRAPHING_QUESTION':
    case 'LEARNOSITY_MATH_QUESTION':
    case 'LEARNOSITY_GENERIC_QUESTION':
    case 'CHEMISTRY_FORMULA_QUESTION':
    case 'CHEMISTRY_WITH_IMAGE_QUESTION':
      return (
        <LearnosityContent
          atom={atom}
          hideCorrectAnswers={hideCorrectAnswers}
          hideExplanation={hideExplanation}
          isVariationContentChanged={isVariationContentChanged}
          isFromPreview={isFromPreview}
        />
      )
    default:
      console.error('Cannot find atom data type!', {
        ...atom.id,
        dataType: atom.dataType,
        internalIssueId: 'CE-3467',
      })
      return null
  }
}

RawAtom.displayName = 'RawAtom'
