import * as React from 'react'
import * as classnames from 'classnames'
import { Box, Collapse, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles, WithStyles } from '@mui/styles'

/**
 * Renders its children as banners across the entire screen.
 */
export const MaterialBanners: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {React.Children.map(children, child => (
        <Box>{child}</Box>
      ))}
    </Box>
  )
}

const bannerStyles = ({ page }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      justifyContent: 'center',
    },
    contents: {
      maxWidth: page.maxWidth,
      width: '100%',
    },
  })

const useStyles = makeStyles(bannerStyles)

export type MaterialBannerStyles = WithStyles<typeof bannerStyles>

export interface MaterialBannerProps extends MaterialBannerStyles {
  children: React.ReactNode
  collapseTimeout?: number
}

/**
 * A customizable banner component that slides in from the top.
 */
export const MaterialBanner: React.FunctionComponent<MaterialBannerProps> = ({
  children,
  classes,
  collapseTimeout = 1000,
}) => {
  const styles = useStyles()
  const [isIn, setIsIn] = React.useState(false)

  // Move from out -> in, immediately to trigger animation
  React.useEffect(() => {
    setIsIn(true)
  }, [])

  return (
    <Collapse in={isIn} timeout={collapseTimeout}>
      <Grid container className={classnames(styles.root, classes.root)}>
        <Box className={classnames(styles.contents, classes.contents)}>{children}</Box>
      </Grid>
    </Collapse>
  )
}
