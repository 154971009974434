import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './empty-state.css'
import { Icon } from 'app/frontend/components/material/icon'
import { connect } from 'react-redux'
import { CUSTOM_QUESTIONS_MODAL, CustomQuestionsView } from './custom-questions-modal'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { CustomQuestionModalOptions } from './custom-questions-modal/custom-questions-modal-types'
import { isEnabled } from 'app/frontend/helpers/feature'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import {
  AssessmentBuilderModalType,
  AssessmentLoSelectionModalOptions,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { NoticeBox } from 'app/frontend/components/material/notice-box/notice-box'
import {
  TEACH_COURSE_ASSESSMENT_ADD_ALTA_QUESTION,
  TEACH_COURSE_ASSESSMENT_ADD_CUSTOM,
  TEACH_SECTION_ASSESSMENT_ADD_ALTA_QUESTION,
  TEACH_SECTION_ASSESSMENT_ADD_CUSTOM,
} from 'app/frontend/data/mixpanel-events'
import { ParentEntityType } from 'app/typings/commons'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'

const t = tns('teach:assessment_builder')

type DispatchProps = {
  showCustomQuestionsModal: () => void
  showLoSelectionModal: () => void
}

type OwnProps = {
  assessmentId: string
  coursepackId: string
  parentEntityType: Commons.ParentEntityType
  parentEntityId: string
  isCourseAssignmentOnSection: boolean
  hasEditPermission: boolean
}

type Props = DispatchProps & OwnProps

export const _EmptyState: React.FunctionComponent<Props> = ({
  showCustomQuestionsModal,
  showLoSelectionModal,
  isCourseAssignmentOnSection,
  hasEditPermission,
  parentEntityType,
  assessmentId,
}) => {
  const onAddAltaQuestion = () => {
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_ADD_ALTA_QUESTION
        : TEACH_SECTION_ASSESSMENT_ADD_ALTA_QUESTION,
      assessmentId
    )
    // show lo-selection modal
    showLoSelectionModal()
  }

  const onAddCustomQuestion = () => {
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_ADD_CUSTOM
        : TEACH_SECTION_ASSESSMENT_ADD_CUSTOM,
      assessmentId
    )
    // show custom question modal
    showCustomQuestionsModal()
  }

  return (
    <>
      {!isCourseAssignmentOnSection && !hasEditPermission && (
        <NoticeBox iconName="icon-lock">
          <Paragraph className={styles.message} data-test="notice-box-uneditable">
            {t('edit_section_level_instructor_warning')}
          </Paragraph>
        </NoticeBox>
      )}
      <div className={styles.emptyState} data-test="assessment-builder-empty-state">
        <Icon name="icon-assessment-builder-empty-state" className={styles.emptyStateIcon} />
        <div className={styles.header}>
          {isCourseAssignmentOnSection
            ? t('empty_state_header_course_on_section')
            : t('empty_state_header')}
        </div>
        <div className={styles.description}>
          {isCourseAssignmentOnSection
            ? t('empty_state_description_course_on_section')
            : t('empty_state_description')}
        </div>
        {!isCourseAssignmentOnSection && hasEditPermission && (
          <Box
            direction="row"
            alignItems="center"
            responsive={true}
            pad={{ between: 'large' }}
            margin="medium"
          >
            <ButtonMaterial
              icon={<Icon name="icon-add-mat" size="medium" className={styles.plusIcon} />}
              theme="primary"
              label={t('add_alta_question')}
              ariaLabel={t('add_alta_question')}
              onClick={onAddAltaQuestion}
              data-bi="add-alta-question-button"
            />

            <ButtonMaterial
              icon={<Icon name="icon-add-mat" size="medium" />}
              label={t('add_custom_question')}
              ariaLabel={t('add_custom_question')}
              theme="bordered"
              onClick={onAddCustomQuestion}
              data-bi="add-custom-question-button"
            />
          </Box>
        )}
      </div>
    </>
  )
}

_EmptyState.displayName = 'EmptyState'

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps => {
  const { assessmentId, coursepackId, parentEntityType, parentEntityId } = ownProps
  const customQuestionModalOptions: CustomQuestionModalOptions = {
    assessmentId,
    view: isEnabled('customFreeResponseQuestions')
      ? CustomQuestionsView.Select
      : CustomQuestionsView.MultipleChoice,
  }
  const loSelectionModalOptions: AssessmentLoSelectionModalOptions = {
    assessmentId,
    coursepackId,
    parentEntityType,
    parentEntityId,
  }

  return {
    showCustomQuestionsModal: () =>
      dispatch(showModal(CUSTOM_QUESTIONS_MODAL, customQuestionModalOptions)),
    showLoSelectionModal: () =>
      dispatch(showModal(AssessmentBuilderModalType.LoSelection, loSelectionModalOptions)),
  }
}

export const EmptyState = connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(_EmptyState)
