import { sendEvent } from './mixpanel'
import { getAssignmentId, getQuizId, getSectionIdForLearn } from 'app/frontend/helpers/url'
import * as GET_LEARN_FLOW_TRACKING_QUERY from './learn-flow-props.gql'
import { ApolloQueryResult } from '@apollo/client'

export function sendEventLearnSection(
  name: string,
  section: Commons.IRetailClass | GQL.SectionFields.Fragment,
  additionalProps: object = {}
): void {
  sendEvent(name, { course_id: section.courseId, section_id: section.id, ...additionalProps })
}

export function sendEventLearnAssignment(
  name: string,
  section: Commons.IRetailClass | GQL.SectionFields.Fragment,
  assignment: Commons.IPath | GQL.AssignmentFields.Fragment,
  additionalProps: object = {}
): void {
  sendEventLearnSection(name, section, {
    path_id: assignment.id,
    assignment_type: assignment.type,
    ...additionalProps,
  })
}

export function sendEventLearnCompound(
  name: string,
  section: Commons.IRetailClass | GQL.SectionFields.Fragment,
  assignment: Commons.IPath | GQL.AssignmentFields.Fragment,
  compound: Learn.IStateAndContent,
  additionalProps: object = {}
): void {
  sendEventLearnAssignment(name, section, assignment, {
    coumpound_instance_id: compound.compoundInstance.id,
    ...additionalProps,
  })
}

export function sendEventLearnAssessment(
  name: string,
  section: Commons.IRetailClass | GQL.SectionFields.Fragment,
  assessment: Commons.IPath,
  enrollment: Commons.IEnrollment,
  additionalProps: object = {}
): void {
  const props = { quiz_id: assessment.id, enrollment_id: enrollment.id, ...additionalProps }
  sendEventLearnSection(name, section, props)
}

export async function sendEventSimple(
  name: string,
  data: GQL.GetLearnFlowTrackingInfo.Query | GQL.GetLearnQuizTrackingInfo.Query | undefined,
  sectionId: string,
  assignmentId: string,
  additionalProps: object = {}
) {
  try {
    sendEvent(name, {
      course_id: data?.section.courseId,
      section_id: sectionId,
      path_id: assignmentId,
      enrollment_id: data?.enrollment.id,
      ...additionalProps,
    })
  } catch (e) {
    console.error(`Could not track event ${name} in mixpanel: `, e)
  }
}

export async function sendEventLearnFlow(name: string, client: any, additionalProps: object = {}) {
  try {
    const sectionId = getSectionIdForLearn()
    const assignmentId = getAssignmentId()
    if (!sectionId || !assignmentId) {
      return
    }

    const results = (await client.query({
      query: GET_LEARN_FLOW_TRACKING_QUERY,
      variables: { assignmentId, sectionId },
      context: { silenceErrors: true },
    })) as ApolloQueryResult<GQL.GetLearnFlowTrackingInfo.Query>

    const { data } = results

    sendEvent(name, {
      course_id: data.section.courseId,
      section_id: sectionId,
      path_id: assignmentId,
      assignment_type: data.assignmentForStudent.type,
      enrollment_id: data.enrollment.id,
      ...additionalProps,
    })
  } catch (e) {
    console.error('Could not track learn flow event in mixpanel: ', e)
  }
}

function getContextByUrl() {
  if (window.location.href.endsWith('/cover')) {
    return 'assignment_cover_page'
  } else if (getQuizId()) {
    return 'quiz_flow'
  } else if (getAssignmentId()) {
    return 'learn_flow'
  } else if (getSectionIdForLearn()) {
    return 'course_cover_page'
  } else {
    return 'unknown'
  }
}

export function sendEventLearnGeneric(name: string, additionalProps: object = {}) {
  try {
    const sectionId = getSectionIdForLearn()
    const assignmentId = getAssignmentId() || getQuizId()

    sendEvent(name, {
      section_id: sectionId,
      path_id: assignmentId,
      url: window.location.href,
      context: getContextByUrl(),
      ...additionalProps,
    })
  } catch (e) {
    console.error('Could not track learn generic event in mixpanel: ', e)
  }
}
