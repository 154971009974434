import * as React from 'react'
import * as classnames from 'classnames'
import * as styles from './text-separator.css'

interface ITextSeparatorProps {
  className?: string
}

export const TextSeparator: React.FunctionComponent<ITextSeparatorProps> = ({
  className,
  children,
}) => {
  return children ? (
    <div className={classnames(styles.container, className)}>
      <div className={styles.line} />
      <div className={styles.text}>{children}</div>
      <div className={styles.line} />
    </div>
  ) : (
    <div className={classnames(styles.container, className)}>
      <div className={styles.longLine} />
    </div>
  )
}

TextSeparator.displayName = 'TextSeparator'
