import * as React from 'react'
import { uniqueId } from 'lodash'
import { Field } from 'redux-form'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps as SelectPropsCore,
} from '@mui/material'
import { FieldProps, WrappedFieldPropsStub } from './fields'

/**
 * We omit anything that conflicts with Field's props, any
 * native-only props (we don't need them), and labelId / label since we
 * implement that ourselves.
 */
type _SelectProps<T> = Omit<
  SelectPropsCore<T>,
  'name' | 'input' | 'defaultValue' | 'native' | 'labelId' | 'label'
>

interface SelectProps extends _SelectProps<string> {
  /** Don't forget to label and describe your form field appropriately */
  label?: string
  description?: string | ((value: string) => React.ReactNode)
  required?: boolean
  outerPb?: number
  ariaLabelledBy?: string
}

/**
 * Renders a <Select> and its choices.
 * See https://mui.com/components/selects/
 * If you want to render hundreds of options, you should use <NativeSelect />
 */
export const renderSelect = (field: WrappedFieldPropsStub & SelectProps) => {
  const {
    input,
    meta,
    label,
    description,
    required,
    children,
    variant = 'standard',
    fullWidth,
    outerPb = 4,
    ariaLabelledBy,
    ...rest
  } = field

  const { current: labelId } = React.useRef(uniqueId(`${input.name}-label-`))
  const { current: descriptionId } = React.useRef(uniqueId(`${input.name}-description-`))

  const disabled = !!(rest.disabled || meta?.submitting)

  return (
    <FormControl required={required} variant={variant} fullWidth={fullWidth} sx={{ pb: outerPb }}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select<string>
        {...(label && { label, labelId })}
        aria-describedby={description && descriptionId}
        inputProps={input}
        data-bi={`${input.name}-dropdown`}
        {...rest}
        disabled={disabled}
        SelectDisplayProps={{
          style: { outlineOffset: '0.25rem', paddingTop: '0.125rem', marginLeft: '0.125rem' },
          ...(ariaLabelledBy && { 'aria-labelledby': ariaLabelledBy }),
        }}
      >
        {children}
      </Select>
      {description && (
        <FormHelperText
          id={descriptionId}
          component={typeof description === 'function' ? 'div' : 'p'}
        >
          {typeof description === 'function' ? description(input.value) : description}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export interface FieldSelectProps
  extends Omit<FieldProps, 'forwardRef' | 'value'>,
    Omit<SelectProps, 'name' | 'input'> {}

/**
 * Renders a Mui Select field connected to Redux-Forms.
 * @param props
 */
export const FieldSelect = (props: FieldSelectProps) => (
  <Field {...props} component={renderSelect} />
)
FieldSelect.displayName = 'FieldSelect'
