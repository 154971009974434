import { LOCATION_CHANGE } from 'connected-react-router'
import { StudentsAnalyticsActions } from './students-actions'

export const getStudentsAnalytics = (state): StudentsReducerState => state.ui.analytics.students
export const getSelectedStudents = (state): string[] => getStudentsAnalytics(state).selectedStudents

const defaultState = {
  selectedStudents: [],
}

export type StudentsReducerState = {
  selectedStudents: string[]
}

export default (state: StudentsReducerState = defaultState, action) => {
  switch (action.type) {
    case StudentsAnalyticsActions.UPDATE_SELECTED_STUDENTS:
      return {
        ...state,
        selectedStudents: action.selectedStudents,
      }
    /**
     * Filters are applied via routing in the query string. When a new filter is
     * applied, the simplest thing to do is to deselect all of the students.
     * This also means when navigating away from the dashboard, selected students
     * will be discarded. This is good when actions performed on subsequent pages
     * can add or remove students from the section being shown.
     */
    case LOCATION_CHANGE:
      return {
        ...state,
        selectedStudents: [],
      }
    default:
      return state
  }
}
