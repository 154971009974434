/* tslint:disable:max-file-line-count */
import { filter, find, flatten, isEmpty, omit } from 'lodash'
import {
  AssignedLearningObjectivesMap,
  AssignedObjectivesWithBlacklistMap,
  AssessedConceptsLoMap,
} from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import { TopicLoMap } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import { memoize } from 'lodash'

/**
 * For a topic to be checked, we need to make sure that all the los under that
 * topic are selected and each of those los have an empty conceptBlacklist.
 * You should always use this method along with getLosPerTopic
 * @param topic
 * @param selectedTopicLos
 */
export const isTopicChecked = (
  topic: GQL.TopicTaxonFields.Fragment,
  selectedTopicLos: GQL.TopicLearningObjective[]
): boolean => {
  const filteredSelectedTopicLos = selectedTopicLos.filter(topicLo => topicLo.topicId === topic.id)

  return (
    topic.learningObjectives.length === filteredSelectedTopicLos.length &&
    filteredSelectedTopicLos.every(topicLo => isEmpty(topicLo.conceptBlacklist))
  )
}

export const isLoChecked = (
  loId: string,
  topicId: string,
  selectedTopicLos: GQL.TopicLearningObjective[]
): boolean => {
  const selectedLo = find(
    selectedTopicLos,
    topicLo =>
      topicLo.learningObjectiveId === loId &&
      topicLo.topicId === topicId &&
      isEmpty(topicLo.conceptBlacklist)
  )

  return !!selectedLo
}

export const isLoConceptChecked = (
  loConceptId: string,
  conceptBlacklistForLo: string[]
): boolean => {
  return conceptBlacklistForLo ? !conceptBlacklistForLo.includes(loConceptId) : false
}

export const getLosPerTopic = (
  topic: GQL.TopicTaxonFields.Fragment,
  selectedTopicLos: GQL.TopicLearningObjective[]
): number => {
  const topicLos = topic && topic.learningObjectives

  if (isEmpty(selectedTopicLos) || isEmpty(topicLos)) {
    return 0
  }

  const selectedLosForTopicCount = selectedTopicLos.reduce(
    (count, topicLo) => (topicLo.topicId === topic.id ? count + 1 : count),
    0
  )
  return selectedLosForTopicCount
}

export const doesChapterContainSelectedLo = (
  chapter: GQL.ChapterTaxonFields.Fragment,
  selectedLosByTopicId: TopicLoMap
): boolean => {
  const sectionTopics = flatten(chapter.sections.map(s => s.topics))
  const chapterTopicsContainSelected = chapter.topics.some(t => !!selectedLosByTopicId[t.id])
  const sectionTopicsContainSelected = sectionTopics.some(t => !!selectedLosByTopicId[t.id])

  return chapterTopicsContainSelected || sectionTopicsContainSelected
}

/**
 * Generate a map from topic ID to title ID for every topic in the given titles. Memoized with the
 * assumption that the contents of a title does not change. **Consequently you SHOULD NOT pass a filtered
 * title into this function**
 */
export const getTopicIdToTitleId = memoize(
  (titles: GQL.TitleTaxonFields.Fragment[]): { [topicId: string]: string } => {
    const topicIdToTitleId = {}

    for (const title of titles) {
      for (const c of title.chapters) {
        for (const topic of c.topics || []) {
          topicIdToTitleId[topic.id] = title.id
        }
        for (const s of c.sections || []) {
          for (const topic of s.topics) {
            topicIdToTitleId[topic.id] = title.id
          }
        }
      }
    }

    return topicIdToTitleId
  },
  (titles): string => titles.map(t => t.id).join('')
)

export const searchTableOfContents = (
  titles: GQL.TitleTaxonFields.Fragment[],
  query: string
): GQL.TitleTaxonFields.Fragment[] => {
  const matchedTitles = []

  titles.forEach(title => {
    const chaptTitle = title.name.toLowerCase()

    // if the match is on the title level, include the entire title
    if (chaptTitle.includes(query.toLowerCase())) {
      matchedTitles.push(title)
    } else {
      const matchedChapters = searchChapters(title.chapters, query)

      // if there is a match on the topic or lo level, only include those topics/los
      if (!isEmpty(matchedChapters)) {
        const titleResult = { ...title, chapters: matchedChapters }
        matchedTitles.push(titleResult)
      }
    }
  })

  return matchedTitles
}

export const searchChapters = (
  chapters: GQL.ChapterTaxonFields.Fragment[],
  query: string
): GQL.ChapterTaxonFields.Fragment[] => {
  const matchedChapters = []

  chapters.forEach(chapter => {
    const chaptTitle = chapter.name.toLowerCase()

    // if the match is on the chapter level, include the entire chapter
    if (chaptTitle.includes(query.toLowerCase())) {
      matchedChapters.push(chapter)
    } else {
      const matchedTopics = searchTopics(chapter.topics, query)
      const matchedSections = searchSections(chapter.sections, query)

      // if there is a match on the topic or lo level, only include those topics/los
      if (!isEmpty(matchedTopics) || !isEmpty(matchedSections)) {
        const chapterResult = { ...chapter, topics: matchedTopics, sections: matchedSections }
        matchedChapters.push(chapterResult)
      }
    }
  })

  return matchedChapters
}

export const searchSections = (
  sections: GQL.SectionTaxonFields.Fragment[],
  query: string
): GQL.SectionTaxonFields.Fragment[] => {
  const matchedSections = []

  sections.forEach(section => {
    const chaptTitle = section.name.toLowerCase()

    // if the match is on the section level, include the entire section
    if (chaptTitle.includes(query.toLowerCase())) {
      matchedSections.push(section)
    } else {
      const matchedTopics = searchTopics(section.topics, query)

      // if there is a match on the topic or lo level, only include those topics/los
      if (!isEmpty(matchedTopics)) {
        const sectionResult = { ...section, topics: matchedTopics }
        matchedSections.push(sectionResult)
      }
    }
  })

  return matchedSections
}

export const searchTopics = (
  topics: GQL.TopicTaxonFields.Fragment[],
  query: string
): GQL.TopicTaxonFields.Fragment[] => {
  const matchedTopics = []

  topics.forEach(topic => {
    const topicTitle = topic.name.toLowerCase()

    // if the match is on the topic level, include the entire topic
    if (topicTitle.includes(query.toLowerCase())) {
      matchedTopics.push(topic)
    } else {
      const matchedLos = searchLos(topic.learningObjectives, query)

      // if there is a match on lo level, only include those los in the topic
      if (!isEmpty(matchedLos)) {
        const topicResult = { ...omit(topic, 'learningObjectives'), learningObjectives: matchedLos }
        matchedTopics.push(topicResult)
      }
    }
  })

  return matchedTopics
}

export const searchLos = (
  objectives: GQL.LearningObjectiveFields.Fragment[],
  query: string
): GQL.LearningObjectiveFields.Fragment[] => {
  const matchedLos = []

  objectives.forEach(objective => {
    const loTitle = objective.description.toLowerCase()

    // if there is a match on the lo description, add it to the list
    if (loTitle.includes(query.toLowerCase())) {
      matchedLos.push(objective)
    }
  })

  return matchedLos
}

/**
 * Filter a list of titles based on a predicate
 * evaluating a topic LO
 */
export const filterToc = (
  titles: GQL.TitleTaxonFields.Fragment[],
  filterLoConceptsFn: (
    lo: GQL.LearningObjectiveFields.Fragment,
    topicId?: string,
    conceptId?: string
  ) => boolean
): GQL.TitleTaxonFields.Fragment[] => {
  const filteredTitles = []

  titles.forEach(title => {
    const filteredChapters = filterChapters(title.chapters, filterLoConceptsFn)

    // if there there are los that are unassigned, only include those in the chapter
    if (!isEmpty(filteredChapters)) {
      const titleResult = { ...title, chapters: filteredChapters }
      filteredTitles.push(titleResult)
    }
  })

  return filteredTitles
}

/**
 * Filter a list of chapters based on a predicate
 * evaluating a topic LO
 */
export const filterChapters = (
  chapters: GQL.ChapterTaxonFields.Fragment[],
  filterLoConceptsFn: (
    lo: GQL.LearningObjectiveFields.Fragment,
    topicId?: string,
    conceptId?: string
  ) => boolean
): GQL.ChapterTaxonFields.Fragment[] => {
  const filteredChapters = []
  chapters.forEach(chapter => {
    const filteredTopics = filterTopics(chapter.topics, filterLoConceptsFn)
    const filteredSections = filterSections(chapter.sections, filterLoConceptsFn)

    // if there there are los that are unassigned, only include those in the chapter
    if (!isEmpty(filteredTopics) || !isEmpty(filteredSections)) {
      const chapterResult = { ...chapter, topics: filteredTopics, sections: filteredSections }
      filteredChapters.push(chapterResult)
    }
  })

  return filteredChapters
}

/**
 * Filter a list of sections based on a predicate
 * evaluating a topic LO
 */
export const filterSections = (
  sections: GQL.SectionTaxonFields.Fragment[],
  filterLoConceptsFn: (
    lo: GQL.LearningObjectiveFields.Fragment,
    topicId?: string,
    conceptId?: string
  ) => boolean
): GQL.SectionTaxonFields.Fragment[] => {
  const filteredSections = []

  sections.forEach(section => {
    const filteredTopics = filterTopics(section.topics, filterLoConceptsFn)

    // if there are unassigned LOs, include only those in the topic
    if (!isEmpty(filteredTopics)) {
      const sectionResult = { ...section, topics: filteredTopics }
      filteredSections.push(sectionResult)
    }
  })

  return filteredSections
}

/**
 * Filter a list of topics based on a predicate
 * evaluating a topic LO
 */
export const filterTopics = (
  topics: GQL.TopicTaxonFields.Fragment[],
  filterLoConceptsFn: (
    lo: GQL.LearningObjectiveFields.Fragment,
    topicId?: string,
    conceptId?: string
  ) => boolean
): GQL.TopicTaxonFields.Fragment[] => {
  const filteredTopics = []

  topics.forEach(topic => {
    const filteredLos = filterObjectives(topic.learningObjectives, topic.id, filterLoConceptsFn)

    // if there are unassigned LOs, include only those in the topic
    if (!isEmpty(filteredLos)) {
      const topicResults = { ...topic, learningObjectives: filteredLos }
      filteredTopics.push(topicResults)
    }
  })

  return filteredTopics
}

export const filterObjectives = (
  los: GQL.LearningObjectiveFields.Fragment[],
  topicId: string,
  filterLoConceptsFn: (
    lo: GQL.LearningObjectiveFields.Fragment,
    topicId?: string,
    conceptId?: string
  ) => boolean
): GQL.LearningObjectiveFields.Fragment[] => {
  const filteredLos = []

  los.forEach(lo => {
    const filteredConcepts: GQL.ConceptCoverageType[] = filter(lo.conceptCoverage, cc =>
      filterLoConceptsFn(lo, topicId, cc.id)
    )

    if (!isEmpty(filteredConcepts)) {
      const loResults = { ...lo, conceptCoverage: filteredConcepts }
      filteredLos.push(loResults)
    }
  })

  return filteredLos
}

/**
 * Given a map of assignment IDs to learning objective ID,
 * creates a predicate to check if an LO is unassigned
 */
export const filterByUnassignedLearningObjectives = (
  assignedLoMap: AssignedLearningObjectivesMap
) => {
  return (lo: GQL.LearningObjectiveFields.Fragment) =>
    !assignedLoMap[lo.id] || assignedLoMap[lo.id].length === 0
}

/**
 * Given a map of assignment IDs to learning objective ID,
 * creates a predicate to check if a concept is assigned.
 * A concept is assigned if it is NOT blacklisted in any
 * one path learning objectives in a course/section.
 */
export const filterByAssignedConcepts = (
  assignedObjectivesWithBlacklist: AssignedObjectivesWithBlacklistMap
) => {
  return (lo: GQL.LearningObjectiveFields.Fragment, _topicId: string, conceptId: string): boolean =>
    !!assignedObjectivesWithBlacklist[lo.id] &&
    !assignedObjectivesWithBlacklist[lo.id].includes(conceptId)
}

/**
 * Given a map of assignment IDs to learning objective ID,
 * creates a predicate to check if a concept is assigned.
 * A concept is unassigned if it is blacklisted in any
 * one path learning objectives in a course/section.
 */
export const filterByUnassignedConcepts = (
  assignedObjectivesWithBlacklist: AssignedObjectivesWithBlacklistMap
): ((lo: GQL.LearningObjectiveFields.Fragment, _topicId: string, conceptId: string) => boolean) => {
  return (lo: GQL.LearningObjectiveFields.Fragment, _topicId: string, conceptId: string): boolean =>
    !assignedObjectivesWithBlacklist[lo.id] ||
    assignedObjectivesWithBlacklist[lo.id].includes(conceptId)
}

/**
 * Given a map of assignment IDs to conceptIds keyed by objective id,
 * creates a predicate to check if a concept is assessed in other
 * assessments
 */
export const filterByAssessedConcepts = (
  assessedConceptsLoMap: AssessedConceptsLoMap,
  assessmentId: string
): ((lo: GQL.LearningObjectiveFields.Fragment, _topicId: string, conceptId: string) => boolean) => {
  return (lo: GQL.LearningObjectiveFields.Fragment, _topicId: string, conceptId: string): boolean =>
    // It's assessed if there are assessment IDs mapped to the concept and
    // it is not mapped to the target assessment ID
    assessedConceptsLoMap?.[lo.id]?.[conceptId]?.some(id => id !== assessmentId) ?? false
}

/**
 * Given a map of assignment IDs to conceptIds keyed by objective id,
 * creates a predicate to check if a concept is unassessed in other
 * assessments
 */
export const filterByUnassessedConcepts = (
  assessedConceptsLoMap: AssessedConceptsLoMap,
  assessmentId: string
): ((lo: GQL.LearningObjectiveFields.Fragment, _topicId: string, conceptId: string) => boolean) => {
  return (lo: GQL.LearningObjectiveFields.Fragment, _topicId: string, conceptId: string): boolean =>
    // it's unassessed if there are no assessment IDs mapped to the concept, or
    // if the only mapped assessment ID is the target one
    !assessedConceptsLoMap?.[lo.id]?.[conceptId]?.some(id => id !== assessmentId)
}

/**
 * Given a map of learning objectives IDs to topic ID,
 * creates a predicate to check if an LO is selected.
 *
 * Topic ID is needed as an LO can exist in multiple topics.
 */
export const filterByTopicWithAnySelectedLearningObjective = (
  selectedLosByTopicMap: TopicLoMap
) => (topicId: string) =>
  !!selectedLosByTopicMap[topicId] && selectedLosByTopicMap[topicId].size > 0

/**
 * Given a map of learning objectives IDs to topic ID,
 * creates a predicate to check if an LO is not selected.
 *
 * Topic ID is needed as an LO can exist in multiple topics.
 */

export const filterByTopicWithAnyUnSelectedLearningObjective = (
  selectedLosByTopicMap: TopicLoMap
) => (topicId: string, loId: string) =>
  !selectedLosByTopicMap[topicId] || !selectedLosByTopicMap[topicId].has(loId)

export const flattenTopicLoMapValues = (selectedLosByTopicMap: TopicLoMap): string[] =>
  Object.values(selectedLosByTopicMap).reduce((acc, curr) => [...acc, ...curr], [])

export const getLoConceptsByLoId = memoize(
  (loId: string, titles: GQL.TitleTaxonFields.Fragment[]): string[] => {
    let loConceptIds: string[] = []
    for (const title of titles) {
      for (const c of title.chapters) {
        for (const topic of c.topics || []) {
          for (const objective of topic.learningObjectives) {
            if (objective.id === loId) {
              loConceptIds = objective.conceptCoverage.map(concept => concept.id)
            }
          }
        }
        for (const s of c.sections || []) {
          for (const topic of s.topics) {
            for (const objective of topic.learningObjectives) {
              if (objective.id === loId) {
                loConceptIds = objective.conceptCoverage.map(concept => concept.id)
              }
            }
          }
        }
      }
    }
    return loConceptIds
  },
  (loId): string => loId
)

export const getConceptBlacklistForLo = (
  loId: string,
  selectedTopicLos: GQL.TopicLearningObjective[]
): string[] => {
  const selectedTopicLo = selectedTopicLos.find(topicLo => topicLo.learningObjectiveId === loId)
  return selectedTopicLo && selectedTopicLo.conceptBlacklist
}

export const getSelectedLoConceptsForLo = (
  lo: GQL.LearningObjectiveFields.Fragment,
  conceptBlacklistForLo: string[]
): GQL.ConceptCoverageType[] => {
  if (!conceptBlacklistForLo) {
    return
  }
  return lo.conceptCoverage.filter(concept => !conceptBlacklistForLo.includes(concept.id))
}
