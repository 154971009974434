import * as _ from 'lodash'
import { Reducer, Action } from 'redux'
import { API_GET_COURSE } from 'app/frontend/pages/material/learn/learn-actions'
import { API_GET_ASSIGNMENT } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { API_GET_COURSEPACK_AND_DOMAIN_TAXONS } from 'app/frontend/pages/teach/browse/browse-actions'
import { API_GET_TAXONOMY_SUBTREE } from 'app/frontend/helpers/taxonomy/taxonomy-actions'
import { API_GET_REVIEW_ASSIGNMENTS } from 'app/frontend/pages/material/learn/review-center/review-center-actions'

export const getTaxonById = (state, taxonId: Taxonomy.TaxonId): Taxonomy.ITaxon => {
  return state.entities.taxonomy.byId[taxonId]
}

export type taxonomyReducerState = {} | ({ [id: string]: Taxonomy.ITaxon } & { toJSON: () => any })

export const taxonomyReducer: Reducer<taxonomyReducerState> = (
  state = {},
  action: Action & any
) => {
  if (!action) {
    return state
  }
  switch (action.type) {
    case API_GET_COURSE.SUCCESS:
    case API_GET_ASSIGNMENT.SUCCESS:
    case API_GET_COURSEPACK_AND_DOMAIN_TAXONS.SUCCESS:
    case API_GET_REVIEW_ASSIGNMENTS.SUCCESS:
      return Object.assign({}, state, action.response.taxonsById)
    case API_GET_COURSEPACK_AND_DOMAIN_TAXONS.SUCCESS:
      return Object.assign({}, state, action.response.taxonsById)
    case API_GET_TAXONOMY_SUBTREE.SUCCESS:
      return Object.assign({}, state, action.response)
    default:
      return state
  }
}

/**
 * Return an array of TaxonIds of all the leaf taxons under the given taxonId.
 * If the taxon passed is a leaf, then the array contains only this taxon.
 *
 * This method performs a depth-first traversal of the taxonomy to ensure that
 * the leaf taxons are ordered according to their displayPosition value and their
 * position in the taxonomy tree.
 * @param state The global state
 * @param taxonId TaxonId the id of a taxon
 */
export function getLeafTaxonIds(state, taxonId: Taxonomy.TaxonId): Taxonomy.TaxonId[] {
  const leafTaxons: Taxonomy.TaxonId[] = []
  const toVisit: Taxonomy.ITaxon[] = [getTaxonById(state, taxonId)]
  let taxon

  while (toVisit.length > 0) {
    // Shift removes the first element of an array (pop is last)
    taxon = toVisit.shift()
    if (!taxon) {
      continue
    }

    if (!_.isEmpty(taxon.children)) {
      const children: Taxonomy.ITaxon[] = taxon.children.map((childTaxonId: Taxonomy.TaxonId) => {
        return getTaxonById(state, childTaxonId)
      })

      // Add to the beginning of `toVisit` to ensure depth-first traversing and maintain the ordering
      toVisit.unshift.apply(toVisit, _.sortBy(children, 'displayPosition'))
    } else {
      // A taxon is a leaf taxon if it does not have children
      leafTaxons.push(taxon.id)
    }
  }

  return leafTaxons
}
