import { UsersComponentActions } from './actions'

export interface UsersComponentState {
  query: string
  debouncedQuery: string
  sortBy: string
  sortDirection: string
  includeTestUsers: boolean
  institutionId: string | undefined
}

const defaultState = {
  query: '',
  debouncedQuery: '',
  sortBy: 'EMAIL',
  sortDirection: 'ASC',
  includeTestUsers: false,
  institutionId: undefined,
}

export function reducer(
  state: UsersComponentState = defaultState,
  action: UsersComponentActions.AllActions
): UsersComponentState {
  switch (action.type) {
    case UsersComponentActions.Actions.UPDATE_USERS_QUERY:
      return {
        ...state,
        query: action.query,
      }
    case UsersComponentActions.Actions.UPDATE_USERS_DEBOUNCED_QUERY:
      return {
        ...state,
        debouncedQuery: action.query,
      }
    case UsersComponentActions.Actions.UPDATE_USERS_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case UsersComponentActions.Actions.UPDATE_USERS_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: action.sortDirection,
      }
    case UsersComponentActions.Actions.TOGGLE_INCLUDE_TEST_USERS:
      return {
        ...state,
        includeTestUsers: !state.includeTestUsers,
      }
    case UsersComponentActions.Actions.UPDATE_USERS_INSTITUTION_ID:
      return {
        ...state,
        institutionId: action.institutionId,
      }
    default:
      return state
  }
}
