
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChapterTaxonFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ChapterTaxon"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayPosition"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"topics"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TopicTaxonFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"sections"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SectionTaxonFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":246}};
    doc.loc.source = {"body":"#import \"./section-taxon-fields.gql\"\n#import \"./topic-taxon-fields.gql\"\nfragment ChapterTaxonFields on ChapterTaxon {\n  id\n  name\n  displayName\n  displayPosition\n  topics {\n    ...TopicTaxonFields\n  }\n  sections {\n    ...SectionTaxonFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./section-taxon-fields.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./topic-taxon-fields.gql").definitions));


      module.exports = doc;
    
