import { makeApiActions } from 'app/frontend/api/api-redux'

export const FETCH_LEARNOSITY_CREDENTIALS = 'FETCH_LEARNOSITY_CREDENTIALS'
export const SET_LEARNOSITY_IS_VALID = 'SET_LEARNOSITY_IS_VALID'
export const SET_LEARNOSITY_DATA = 'SET_LEARNOSITY_DATA'
export const PUT_LEARNOSITY_IS_READY = 'PUT_LEARNOSITY_IS_READY'
export const SET_LEARNOSITY_CREDENTIALS_FAIL = 'SET_LEARNOSITY_CREDENTIALS_FAIL'

export const fetchLearnosityCredentials = () => ({ type: FETCH_LEARNOSITY_CREDENTIALS })

export const setLearnosityIsValid = isValid => ({ type: SET_LEARNOSITY_IS_VALID, isValid })

export const putLearnosityIsReady = ready => ({ type: PUT_LEARNOSITY_IS_READY, ready })

export const setLearnosityData = (sequenceId, learnosityData) => ({
  type: SET_LEARNOSITY_DATA,
  sequenceId,
  learnosityData,
})

export const API_LEARNOSITY_CREDENTIALS = makeApiActions('API_LEARNOSITY_CREDENTIALS')
