import * as React from 'react'
import MathJaxQueue, { CommandId, Priority } from './queue'
import { MathJaxPriorityContext } from './mathjax-priority-context'

/**
 * Typeset an Element.
 */
export const useTypesetEffect = (
  node: Element,
  priority: Priority,
  onError: () => void,
  onSuccess: () => void,
  deps: React.DependencyList
) => {
  const priorityContext = React.useContext(MathJaxPriorityContext)
  const typesetId = React.useRef<CommandId>(undefined)

  React.useLayoutEffect(() => {
    typesetId.current = MathJaxQueue.typeset(node, {
      priority: [priority, priorityContext].includes(Priority.ON_DEMAND)
        ? Priority.ON_DEMAND
        : Priority.DEFAULT,
      onError,
      onSuccess,
    })
    return () => {
      if (typesetId.current) {
        MathJaxQueue.cancel(typesetId.current)
        typesetId.current = undefined
      }
    }
  }, deps)
}
