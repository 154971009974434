import { getLoginAsOriginalUser, get as getCurrentUser } from 'app/frontend/helpers/current-user'

// Stores whether or not pendo.initialize was called (it should only be called once).
export let initialized = false
// Stores the current course id. Everytime this changes, we send the new course info to Pendo.
export let currentCourseId = null

/**
 * Updates Pendo's visitor and account information.
 * We only update Pendo if the information has changed.
 */
export const updatePendo = (course: GQL.CourseFieldsFragment) => {
  // If we have never initialized Pendo or the course id has changed
  if (!initialized || currentCourseId !== course?.id) {
    try {
      const user = getLoginAsOriginalUser() || getCurrentUser()
      const options: any = { excludeAllText: true }
      if (user) {
        const isKnerd = !!user.permissions.find(p => p.name.match(/KnerdTools/))
        options.visitor = {
          id: `alta:${user.id}`,
          role: isKnerd ? 'ADMIN' : user.roleForUi,
          testing: user.testing,
          institutionId: user.institutions[0]?.id,
          signUpDate: new Date(user.createdAt).toISOString(),
        }
        options.account = course
          ? {
              id: course.id,
              lmsType: course.lmsType,
              billing: course.billingModel,
              institutionId: course.institutionId,
              coursepackId: course.coursepackId,
            }
          : {
              // The placeholder "pre-course" was suggested by Pendo
              id: 'pre-course',
            }
      }

      window.pendo?.[initialized ? 'identify' : 'initialize'](options)
      initialized = true
      currentCourseId = course?.id
    } catch (e) {
      console.error('Failed to initialize Pendo', e)
    }
  }
}
