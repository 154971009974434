import * as React from 'react'
import * as classnames from 'classnames'
import { Checkbox } from '@mui/material'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Box } from 'app/frontend/components/material/box'
import { Icon } from 'app/frontend/components/material/icon'
import { useLoConceptById } from 'app/frontend/pages/material/teach/compositions/connected/get-lo-concept-by-id'
import { Tag } from 'app/frontend/components/tag'
import * as styles from './table-of-contents.css'
import { PlaceholderText } from 'app/frontend/components/material/loading'
import { sample } from 'lodash'

type Props = {
  topicId: string
  objective: GQL.LearningObjectiveFields.Fragment
  loConceptId: string
  loConceptChecked: boolean
  disabled?: boolean
  toggleLoConcept?: (loConceptId: string, loId: string, topicId: string, checked: boolean) => void
  triggerPreviewModal?: (
    topicId: string,
    lo: GQL.LearningObjectiveFields.Fragment,
    conceptId: string
  ) => void
  numQuestions?: number
  isSaving?: boolean
  isError?: boolean
}

export const TableOfContentsLoConcept: React.FunctionComponent<Props> = ({
  objective,
  topicId,
  loConceptId,
  loConceptChecked,
  disabled,
  toggleLoConcept,
  triggerPreviewModal,
  numQuestions,
  isError,
  isSaving,
}) => {
  const { loConcept, loading: conceptLoading } = useLoConceptById(loConceptId)

  if (conceptLoading || !loConcept) {
    return (
      <Box
        direction="row"
        full="page"
        pad={{ horizontal: 'xlarge' }}
        alignItems="center"
        className={styles.loRow}
        data-test={`lo-concept-loader-${loConceptId}`}
      >
        <Box
          alignSelf="stretch"
          direction="row"
          alignItems="center"
          data-test="lo-concept-item-body"
          margin={{ top: 'medium', bottom: 'medium' }}
          className={styles.loConceptDescription}
        >
          <PlaceholderText
            marginTop=".25rem"
            marginBottom=".25rem"
            width={sample(['5rem', '15rem', '25rem'])}
          />
        </Box>
      </Box>
    )
  }

  const toggleCheckbox = checked => {
    toggleLoConcept(loConceptId, objective.id, topicId, checked)
  }

  const onClickLoConcept = () => {
    if (!!triggerPreviewModal && !disabled) {
      triggerPreviewModal(topicId, objective, loConceptId)
    }
  }

  return (
    <Box
      direction="row"
      full="page"
      pad={{ horizontal: 'xlarge' }}
      alignItems="center"
      className={styles.loRow}
      data-test={`lo-concept-${loConceptId}`}
      data-concept-id={loConceptId}
    >
      {toggleLoConcept && (
        <Checkbox
          checked={loConceptChecked}
          className={styles.checkbox}
          disabled={disabled}
          data-test="toggle-lo-concept-checkbox"
          onChange={e => toggleCheckbox(e.target.checked)}
          inputProps={{
            'aria-label': loConcept.name,
          }}
        />
      )}
      <Box
        alignSelf="stretch"
        direction="row"
        alignItems="center"
        data-test="lo-concept-item-body"
        onClick={onClickLoConcept}
        className={classnames({
          [styles.loConceptDescription]: true,
          [styles.pointerCursor]: !!triggerPreviewModal,
        })}
      >
        <Paragraph
          margin="medium"
          className={classnames({ [styles.disabled]: disabled }, styles.loConceptDescription)}
          data-test="lo-concept-description"
        >
          {loConcept.name}
        </Paragraph>
        {isError && <Icon name="icon-error-outline" size="large" className={styles.errorIcon} />}
        {(isSaving || !!numQuestions) && (
          <Tag
            label={`${numQuestions}`}
            isLoading={isSaving}
            theme="secondary"
            margin={{ left: 'medium' }}
            data-test="concept-num-questions-tag"
          />
        )}
      </Box>
    </Box>
  )
}

TableOfContentsLoConcept.displayName = 'TableOfContentsLoConcept'
