import * as React from 'react'
import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { reduxForm, clearSubmitErrors } from 'redux-form'
import { CardMaterial, CardTitleMaterial } from 'app/frontend/components/material/card/card'
import { validatePassword, validatePasswordsEqual } from 'app/frontend/helpers/validation'
import LegalStuff from 'app/frontend/components/material/legal-stuff/legal-stuff'
import { MainMaterial } from 'app/frontend/layout/material/main'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { RESET_PASSWORD_VIEW } from 'app/frontend/data/mixpanel-events'
import { sendEvent } from 'app/frontend/helpers/mixpanel'
import { useQueryParam } from 'app/frontend/hooks/use-query-params'
import { ResetPasswordFormContents } from './reset-password-form-contents'
import { NewInstructorResetPassword } from './new-instructor-reset-password'
import * as styles from './forgot-password.css'
import { submitResetPasswordForm } from './forgot-password-actions'
import SignedOutLogo from '../signed-out-logo'

const t = tns('forgot_password')

interface ResetPasswordProps {
  email?: string
  token?: string
  isValid?: boolean
}

interface ResetPasswordFormProps {
  handleSubmit?: () => void
  valid: boolean
  submitting: boolean
}

type Props = ResetPasswordProps & ResetPasswordFormProps

const RESET_PASSWORD_FORM = 'reset-password'

export const ResetPassword: React.FunctionComponent<Props> = ({
  handleSubmit,
  email,
  token,
  valid,
  submitting,
}) => {
  const dispatch = useDispatch()
  const newInstructor = !!useQueryParam('newInstructor')

  // we want to send this mixpanel event when the user lands on the page but not for every
  // rerender (e.g. toggling the password viewabiliy) so we pass no dependencies
  useEffect(() => {
    sendEvent(RESET_PASSWORD_VIEW)
  }, [])

  return (
    <div className={styles.container}>
      <SignedOutLogo />
      <p className={styles.signInCorner} onClick={() => dispatch(push('/login'))}>
        {t('caps_sign_in')}
      </p>
      <MainMaterial>
        <CardMaterial className={styles.card}>
          {!newInstructor && (
            <CardTitleMaterial
              className={styles.cardTitle}
              theme={{ title: styles.cardTitleHeader }}
            >
              {t('reset_password')}
            </CardTitleMaterial>
          )}
          {email && token ? (
            <div>
              <form onSubmit={handleSubmit} data-lpignore={true}>
                {newInstructor ? (
                  <NewInstructorResetPassword
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    valid={valid}
                  />
                ) : (
                  <ResetPasswordFormContents
                    submitting={submitting}
                    valid={valid}
                    handleSubmit={handleSubmit}
                  />
                )}
              </form>
            </div>
          ) : (
            <p>{t('expired_link')}</p>
          )}
        </CardMaterial>
      </MainMaterial>
      <LegalStuff />
    </div>
  )
}

const validate = values => ({
  password: validatePassword(values.password),
  confirmPassword: values?.newInstructor
    ? undefined
    : validatePasswordsEqual(values.password, values.confirmPassword),
})

const mapStateToProps = (_state, props) => {
  return {
    initialValues: {
      email: props.email,
      token: props.token,
      valid: props.isValid,
    },
  }
}

let WrappedForm = reduxForm({
  form: RESET_PASSWORD_FORM,
  validate,
})(ResetPassword)
WrappedForm = connect(mapStateToProps, {
  onSubmit: submitResetPasswordForm,
  clearSubmitErrors,
})(WrappedForm)
const Wrapped = WrappedForm

export default Wrapped
