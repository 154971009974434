import { makeApiActions } from 'app/frontend/api/api-redux'

export type RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL'
export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL'

export type ResendEmailVerificationAction = {
  type: RESEND_VERIFICATION_EMAIL
  redirection: string
}

export const resendVerificationEmail = (redirection: string): ResendEmailVerificationAction => {
  return {
    type: RESEND_VERIFICATION_EMAIL,
    redirection,
  }
}

export const API_RESEND_VERIFICATION_EMAIL = makeApiActions('API_RESEND_VERIFICATION_EMAIL')
