import * as React from 'react'
import RecaptchaHelper from 'app/frontend/helpers/recaptcha'

interface Props {
  type?: string
  onChange?: (newValue: string) => void
  onExpired?: () => void
}

export default class GoogleRecaptchaWidget extends React.Component<Props, {}> {
  captchaEle: HTMLDivElement
  static displayName = 'GoogleRecaptchaWidget'

  componentDidMount() {
    RecaptchaHelper.load(this.renderCaptchaWidget)
  }

  reset() {
    grecaptcha.reset()
  }

  onResponse = response => {
    // change component value (response is a string hash that proves that the user proved
    // he's not a robot)
    if (this.props.onChange) {
      this.props.onChange(response)
    }
  }

  renderCaptchaWidget = () => {
    grecaptcha.render(this.captchaEle, {
      sitekey: window.context.recaptchaKey,
      type: this.props.type,
      callback: this.onResponse,
      'expired-callback': this.props.onExpired,
    })
  }

  render() {
    return <div className="captcha-widget" ref={ele => (this.captchaEle = ele)} />
  }
}
