import { useEffect, useReducer, Reducer } from 'react'
import { useApolloClient, ApolloError, QueryOptions } from '@apollo/client'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { queryCollection, DocumentNode } from 'app/frontend/helpers/apollo/query'

/**
 * This hook wraps queryCollection to provide error and loading states.
 *
 * queryCollection will make individual graphql queries for each variables object in queryVariables.
 * By doing this, we can take advantage of Apollo's cache to only make network requests for queries
 * and entities not yet cached.
 *
 * @param query           Graphql query to be called with variables object from queryVariables
 * @param queryVariables  An array of arguments to query individually for
 * @param triggerRefetchVariables An object with key-value pairs that trigger refetching based on variable changes.
 * @param queryOptions    Query options to provide to each graphql query executed. Every query will
 *                        use the same query parameters
 */
export function useQueryForCollectionWithIndividualQuery<T, V>(
  query: DocumentNode,
  queryVariables: V[],
  triggerRefetchVariables?: { [key: string]: any },
  queryOptions?: Partial<QueryOptions>
): RenderProps<{ data?: T[] }> {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(reducer, {
    loading: false,
    error: undefined,
    data: undefined,
  })
  const client = useApolloClient()
  useEffect(
    () => {
      const fetchData = async () => {
        dispatch({ type: ActionTypes.INIT })
        try {
          const data = await queryCollection<T, V>(client, query, queryVariables, queryOptions)
          return dispatch({ type: ActionTypes.SUCCESS, results: data })
        } catch (error) {
          return dispatch({ type: ActionTypes.ERROR, error })
        }
      }

      fetchData()
    },
    // Stringify has to be used because useEffect uses a shallow comparison
    // to trigger the callback. This ensures that the callback is triggered
    // only when any value within the object changes and not the object
    // itself.
    [JSON.stringify(queryVariables), JSON.stringify(triggerRefetchVariables)]
  )

  return { loading: state.loading, error: state.error, data: state.data }
}

type State<T> = { data?: T[] | null; loading: boolean; error?: ApolloError }
enum ActionTypes {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
type Action<T> =
  | { type: ActionTypes.INIT }
  | { type: ActionTypes.SUCCESS; results: T[] }
  | { type: ActionTypes.ERROR; error: ApolloError }

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case 'INIT':
      return {
        data: state.data,
        loading: true,
      }
    case 'SUCCESS':
      return {
        data: action.results,
        loading: false,
      }
    case 'ERROR':
      return {
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}
