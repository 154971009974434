import { QueryHookOptions, useQuery } from '@apollo/client'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import * as GET_ASSIGNMENT from './get-assignment.gql'

export type UseAssignmentResults = RenderProps<GQL.GetAssignment.Query>

export const useAssignment = (
  assignmentId: string,
  opts?: QueryHookOptions<GQL.GetAssignment.Query, GQL.GetAssignment.Variables>
): UseAssignmentResults => {
  const { loading, error, data } = useQuery<GQL.GetAssignment.Query, GQL.GetAssignment.Variables>(
    GET_ASSIGNMENT,
    {
      ...opts,
      variables: {
        id: assignmentId,
      },
    }
  )

  return {
    loading,
    error,
    ...data,
  }
}
