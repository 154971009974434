import * as React from 'react'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { SadAtom } from 'app/frontend/components/material/error'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('content_atom')

interface Props {
  onRetry: () => void
  errors: string[]
}

const RetryableAtomError: React.FunctionComponent<Props> = ({ onRetry, errors }) => {
  return (
    <Box alignItems="center" pad={{ top: 'large', bottom: 'large' }} justify="center">
      <SadAtom role="presentation" alt="" size="medium" />
      <Box textAlign="center" margin={{ top: 'medium', bottom: 'small' }}>
        <Paragraph margin={'none'}>
          {t('something_went_wrong')}
          <br />
          {t('tap_reload')}
          <br />
          {t('contact_support')}
        </Paragraph>
      </Box>
      <Box textAlign="center" margin={{ top: 'small', bottom: 'medium' }}>
        <Paragraph size={'small'} margin={'none'}>
          {errors.map((e, idx) => (
            <React.Fragment key={idx}>
              <span key={idx}>{e}</span>
              <br />
            </React.Fragment>
          ))}
        </Paragraph>
      </Box>
      <ButtonMaterial
        theme={'warn'}
        data-bi="reload-button"
        label={t('reload_atom_btn')}
        onClick={onRetry}
      />
    </Box>
  )
}

export default RetryableAtomError
