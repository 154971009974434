import { PaletteOptions, SimplePaletteColorOptions } from '@mui/material/styles/createPalette'

export const palette: PaletteOptions & { primary: SimplePaletteColorOptions } = {
  primary: {
    light: '#E7F6F8',
    main: '#008392',
    dark: '#21243C',
  },
  secondary: {
    light: '#F5F5FF',
    main: '#636BB5',
    dark: '#21243C',
  },
  error: {
    light: '#FDF0F1',
    main: '#C84E50',
    dark: '#431C1D',
  },
  warning: {
    light: '#FFF9F2',
    main: '#F28411',
    dark: '#973500',
    contrastText: '#000',
  },
  success: {
    light: '#E7F7F3',
    main: '#008765',
    dark: '#002E23',
    contrastText: '#FFF',
  },
  blue: {
    50: '#E7F6F8',
    100: '#D0EEF1',
    200: '#A2DDE4',
    300: '#73CDD7',
    400: '#45BCCA',
    500: '#00A4B7',
    600: '#008392',
    700: '#006975',
    800: '#004b54',
    900: '#002d32',
    1000: '#21243C',
  },
  // This is used in a few legacy components, we probably shouldn't use it going forward
  custom_grey: {
    7: '#FFFFFFB3',
  },
  grey: {
    50: '#F8F8F8',
    100: '#DDDDDD',
    200: '#CCCCCC',
    300: '#ABABAB',
    400: '#727272',
    500: '#585858',
    600: '#484848',
    700: '#3b3b3b',
    800: '#2e2e2e',
    900: '#212121',
  },
  purple: {
    50: '#F5F5FF',
    100: '#DADCF5',
    200: '#C1C6EF',
    300: '#A9AFE8',
    400: '#9098E2',
    500: '#7882DC',
    600: '#636BB5',
    700: '#4D538C',
    800: '#373C64',
    900: '#21243C',
  },
  green: {
    50: '#E7F7F3',
    100: '#B9E7DB',
    200: '#8BD7C3',
    300: '#5CC7AC',
    400: '#2EB794',
    500: '#00A87D',
    600: '#008765',
    700: '#006b50',
    800: '#004d39',
    900: '#002e23',
  },
  yellow: {
    50: '#FEFAF0',
    100: '#FCF0D2',
    200: '#FAE7B4',
    300: '#F8DD97',
    400: '#F6D479',
    500: '#F5CB5C',
    600: '#C9A74C',
    700: '#9c823b',
    800: '#705d2a',
    900: '#43381a',
  },
  background: {
    paper: '#F8F8F8',
  },
  action: {
    focus: '#5599d8',
  },
}
