import { OPEN_POPOVER, CLOSE_POPOVER } from './popover-actions'

export type PopoverState = string | null

export function popover(state: PopoverState = null, action) {
  switch (action.type) {
    case OPEN_POPOVER:
      return action.id
    case CLOSE_POPOVER:
      return null
    default:
      return state
  }
}
export const getPopover = state => state.popover

export default popover
