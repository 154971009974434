import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '0rem 0.75rem',
    height: '2rem',
    width: '100%',
    alignItems: 'top',
    fontFamily: theme.typography?.fontFamily,
  },
  select: {
    '&:focus': {
      outline: '0.3125rem auto #5094D3',
    },
  },
  icon: {
    marginRight: '0.75rem',
  },
  menuContainer: {
    width: '100%',
    maxWidth: '20rem',
    height: '3.5rem',
    backgroundColor: theme.palette?.grey[50],
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    borderBottom: `0.063rem solid ${theme.palette?.grey[400]}`,
    paddingTop: '0.625rem',
  },
  dropdownLabel: {
    alignItems: 'center',
    height: '0.625rem',
    color: theme.palette?.grey[600],
    fontSize: '0.75rem',
    lineHeight: '1rem',
    display: 'flex',
    paddingLeft: '0.75rem',
  },
  container: {
    padding: '1.25rem 1rem 1.25rem 1rem',
  },
  wrapper: {
    width: '100%',
    paddingTop: '1.5rem',
  },
  firstElementWrapper: {
    width: '100%',
    paddingTop: '1rem',
  },
  helperText: {
    fontSize: '0.75rem',
    color: theme.palette?.grey[400],
    paddingLeft: '0.75rem',
    paddingTop: '0.5rem',
  },
  menuItemRoot: {
    height: '3rem',
    minWidth: '20rem',
  },
  supportLink: {
    color: theme.palette?.blue?.[600],
    marginLeft: '0.25rem',
    fontWeight: 600,
    textDecoration: 'none',
  },
  resetLink: {
    color: `${theme.palette?.blue?.[600]} !important`,
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '0.875rem',
    minHeight: '1.5rem',
    height: '1.5rem',
  },
  warningBanner: {
    backgroundColor: theme.palette?.yellow?.[50],
    border: `solid 0.0625rem ${theme.palette?.yellow?.[600]}`,
    borderRadius: '0.25rem',
  },
  warningBannerIcon: {
    fill: theme.palette?.yellow?.[700],
    width: '1.375rem',
    height: '1.25rem',
    marginRight: '1.125rem',
  },
  /* added important here because parent modal is overriding the properties of the paragraph */
  warningBannerText: {
    color: `${theme.palette?.yellow?.[800]} !important`,
    fontSize: '0.875rem !important',
  },
}))
