import * as React from 'react'
import { Input, Select, Typography } from '@mui/material'
import { Box } from 'app/frontend/components/material/box'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { InputField, SettingName } from './helper'
import { useStyles } from './styles'

const t = tns('teach:assessment_builder')

interface Props {
  header: string
  description: string
  isReadOnly: boolean
  settingName: SettingName
  menuItems: React.ReactNode[]
  input: InputField
}

export const SettingFieldWithSelect: React.FunctionComponent<Props> = ({
  header,
  description,
  isReadOnly,
  settingName,
  menuItems,
  input,
}) => {
  const styles = useStyles()
  return (
    <Box
      className={settingName === SettingName.GRADE ? styles.firstElementWrapper : styles.wrapper}
    >
      <Typography
        id={`${settingName}-results-setting-dropdown`}
        variant="h2"
        color="textPrimary"
        paragraph={true}
        style={{ paddingBottom: 0, fontWeight: 600 }}
      >
        {header}
      </Typography>
      <Box pad={{ top: 'small', bottom: 'medium' }}>
        <Typography
          id={`${settingName}-results-setting-description`}
          variant="body2"
          color="textPrimary"
          paragraph={true}
          style={{ paddingBottom: 0, fontSize: '0.75rem' }}
        >
          {description}
        </Typography>
      </Box>
      <Box className={styles.menuContainer} responsive={true}>
        <label className={styles.dropdownLabel}>{t('setting_dropdown_label')}</label>
        <Select
          labelId={`${settingName}-results-setting-dropdown`}
          input={<Input classes={{ root: styles.root }} disableUnderline={true} />}
          onChange={input.onChange}
          value={input.value}
          classes={{ icon: styles.icon }}
          data-bi={`${settingName}-results-setting`}
          disabled={isReadOnly}
          aria-describedby={`${settingName}-results-setting-description`}
          SelectDisplayProps={{
            className: styles.select,
          }}
        >
          {menuItems}
        </Select>
      </Box>
    </Box>
  )
}
