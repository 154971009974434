import { Action } from 'redux'
import { AssignmentUITab } from './assignment-ui-reducer'
import {
  LearningObjectiveTableColumns,
  OverallStatusTableColumns,
  IAnalyticsSortOrder,
  SortOrder,
} from 'app/frontend/data/analytics-data'

export enum CourseworkActions {
  SelectAssignmentUITab = 'SELECT_ASSIGNMENT_UI_TAB',
  OpenCourseworkAssignment = 'OPEN_COURSEWORK_ASSIGNMENT',
  SetActiveAssignment = 'SET_ACTIVE_ASSIGNMENT',
  SelectLearningObjectiveStatus = 'SELECT_LEARNING_OBJECTIVE_STATUS',
  SortLearningObjectiveTable = 'SORT_LEARNING_OBJECTIVE_TABLE',
  UpdateSelectedStudents = 'UPDATE_ASSIGNMENT_SELECTED_STUDENTS',
  SortStudentAssignmentMastery = 'SORT_STUDENT_ASSIGNMENT_MASTERY',
  SelectAssignmentStatus = 'SELECT_ASSIGNMENT_STATUS',
  SortOverallStatusTable = 'SORT_OVERALL_STATUS_TABLE',
}

export interface SelectAssignmentUITabAction extends Action {
  type: CourseworkActions.SelectAssignmentUITab
  tab: AssignmentUITab
  doNotUpdateUrl?: boolean
}
export function selectAssignmentUITab(
  tab: AssignmentUITab,
  doNotUpdateUrl = false
): SelectAssignmentUITabAction {
  return {
    type: CourseworkActions.SelectAssignmentUITab,
    tab,
    doNotUpdateUrl,
  }
}

export interface OpenCourseworkAssignmentAction extends Action {
  type: CourseworkActions.OpenCourseworkAssignment
  courseId: string
  assignmentId: string
}
export function openCourseworkAssignment(
  courseId: string,
  assignmentId: string
): OpenCourseworkAssignmentAction {
  return {
    type: CourseworkActions.OpenCourseworkAssignment,
    courseId,
    assignmentId,
  }
}

export type SetActiveAssignment = {
  assignmentId: string
} & Action
export function setActiveAssignment(assignmentId: string): SetActiveAssignment {
  return {
    type: CourseworkActions.SetActiveAssignment,
    assignmentId,
  }
}

export type SelectLearningObjectiveStatus = {
  learningObjectiveId: string
  progressStatus: string
  doNotUpdateUrl?: boolean
} & Action
export function selectLearningObjectiveStatus(
  learningObjectiveId: string,
  progressStatus: string,
  doNotUpdateUrl = false
): SelectLearningObjectiveStatus {
  return {
    type: CourseworkActions.SelectLearningObjectiveStatus,
    learningObjectiveId,
    progressStatus,
    doNotUpdateUrl,
  }
}

export type SortLearningObjectiveTable = {
  column: LearningObjectiveTableColumns
  sortOrder: IAnalyticsSortOrder
} & Action
export function sortLearningObjectiveTable(
  column: LearningObjectiveTableColumns,
  sortOrder: IAnalyticsSortOrder
): SortLearningObjectiveTable {
  return {
    type: CourseworkActions.SortLearningObjectiveTable,
    column,
    sortOrder,
  }
}
export type SortOverallStatusTable = {
  column: OverallStatusTableColumns
  sortOrder: SortOrder
} & Action
export function sortOverallStatusTable(
  column: OverallStatusTableColumns,
  sortOrder: SortOrder
): SortOverallStatusTable {
  return {
    type: CourseworkActions.SortOverallStatusTable,
    column,
    sortOrder,
  }
}

interface UpdateSelectedStudentsAction extends Action {
  type: CourseworkActions.UpdateSelectedStudents
  selectedStudents: string[]
}

export function updateSelectedStudents(selectedStudents: string[]): UpdateSelectedStudentsAction {
  return {
    type: CourseworkActions.UpdateSelectedStudents,
    selectedStudents,
  }
}

export interface SortStudentAssignmentMasteryAction extends Action {
  type: CourseworkActions.SortStudentAssignmentMastery
  sortOrder: IAnalyticsSortOrder
}
export function sortStudentAssignmentMastery(
  sortOrder: IAnalyticsSortOrder
): SortStudentAssignmentMasteryAction {
  return {
    type: CourseworkActions.SortStudentAssignmentMastery,
    sortOrder,
  }
}

export type SelectAssignmentStatus = {
  assignmentStatus: string
  doNotUpdateUrl?: boolean
} & Action
export function selectAssignmentStatus(
  assignmentStatus: Analytics.AssignmentStatus,
  doNotUpdateUrl = false
): SelectAssignmentStatus {
  return {
    type: CourseworkActions.SelectAssignmentStatus,
    assignmentStatus,
    doNotUpdateUrl,
  }
}
