import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { sendEventLearnGeneric } from 'app/frontend/helpers/mixpanel/learn'
import { LEARN_TEST_FLOW_EXIT_LDB } from 'app/frontend/data/mixpanel-events'
import * as styles from './exit-ldb-button.css'

const t = tns('quiz_flow')
export const exitLdbHref = '/?rldbxb=1'

export const ExitLdbButton: React.FunctionComponent<{}> = () => {
  const onClick = () => {
    sendEventLearnGeneric(LEARN_TEST_FLOW_EXIT_LDB)
  }

  return (
    <ButtonMaterial
      onClick={onClick}
      href={exitLdbHref}
      label={t('exit')}
      className={styles.exitButton}
      theme="bordered-light"
      data-bi="exit-ldb-button"
    />
  )
}
