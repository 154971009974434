import {
  AssessmentLabel,
  AssignmentType,
  CompoundInstanceSource,
  ResultOverrideType,
} from 'app/typings/commons'
import { countBy, Dictionary, difference, groupBy, isEmpty, keyBy, omit } from 'lodash'
import { getParentSequenceVariationId } from './compound'

export type SequenceOverridesMap = {
  [sequenceId: string]: GQL.GetSequenceOverrides.GetSequenceOverrides
}

type Assignment =
  | Commons.IPath
  | GQL.Assignment
  | GQL.GetAssignment.Assignment
  | GQL.GetAssignmentsForCourse.Results

export type AssessmentSequenceWithOverride = Commons.IPathSequence & {
  resultOverride?: ResultOverrideType
  overrideValue?: number
}

export type ObjectiveProgress = {
  topicId?: string
  learningObjectiveId: string
  progress: number
}

export type ObjectiveProgressByTopicId = {
  [topicId: string]: ObjectiveProgress[]
}

export type SequenceVariationCount = {
  [sequenceId: string]: number
}
export const isAdaptive = (assignment: Assignment): boolean =>
  assignment.type === AssignmentType.ADAPTIVE
export const isAdaptiveReview = (assignment: Assignment): boolean =>
  assignment.type === AssignmentType.REVIEW

/**
 * Checks if the provided compound instance is recommended because of
 * instruct-first config.
 */
export const isInstructFirst = (compound: Learn.IStateAndContent): boolean =>
  compound.compoundInstance.source === CompoundInstanceSource.INTRODUCTION

export const isQuiz = (assignment: Assignment): boolean =>
  isAssessment(assignment) && assignment.label === AssessmentLabel.QUIZ

export const isTest = (assignment: Assignment): boolean =>
  isAssessment(assignment) && assignment.label === AssessmentLabel.TEST

export const isPrintable = (assignment: Assignment): boolean =>
  isAssessment(assignment) && !!assignment.quizConfiguration.printable

export const isAssessment = (assessment: Partial<Assignment>): boolean =>
  assessment.type === AssignmentType.QUIZ

export const isMultiTopic = (
  assignment: Commons.IPath | GQL.Assignment | GQL.GetAssignment.Assignment
): boolean => {
  if (!assignment || isEmpty(assignment.pathLearningObjectives)) {
    return false
  }
  const { topicId } = assignment.pathLearningObjectives[0]
  return assignment.pathLearningObjectives.some(plo => plo.topicId !== topicId)
}

export const getStartsAt = (
  assignment: Assignment,
  override: GQL.GetAssignmentOverride.AssignmentOverride
): number => (override && override.startsAt) || (assignment && assignment.startsAt)

export const getEndsAt = (
  assignment: Assignment,
  override: GQL.GetAssignmentOverride.AssignmentOverride
): number => (override && override.endsAt) || (assignment && assignment.endsAt)

export const mergeAssignmentOverride = (
  assignment: GQL.GetAssignment.Assignment,
  override: GQL.GetAssignmentOverride.AssignmentOverride
): GQL.GetAssignment.Assignment => ({
  ...assignment,
  startsAt: getStartsAt(assignment, override),
  endsAt: getEndsAt(assignment, override),
})

export const getFormattedUserAssignment = (userName: string, assignmentName: string): string =>
  `${userName} - ${assignmentName}`

export const isQuestionRemovedFromAssessment = (
  assessmentSequence: AssessmentSequenceWithOverride
) => {
  return assessmentSequence.resultOverride === ResultOverrideType.REMOVED_FROM_ASSESSMENT
}

export const calculateAssessmentSequenceValue = (
  assessmentSequence: AssessmentSequenceWithOverride
): number => {
  if (!assessmentSequence || isQuestionRemovedFromAssessment(assessmentSequence)) {
    return 0
  }
  return assessmentSequence.points
}

export const calculateAssessmentSequenceWeight = (
  assessmentSequence: AssessmentSequenceWithOverride,
  totalAssessmentPoints: number
): {
  points: number
  weight: number
} => {
  if (
    !assessmentSequence ||
    isQuestionRemovedFromAssessment(assessmentSequence) ||
    !totalAssessmentPoints
  ) {
    return {
      points: 0,
      weight: 0,
    }
  }

  const weight = assessmentSequence.points / totalAssessmentPoints

  return {
    points: assessmentSequence.points,
    weight,
  }
}

export const calculateTotalAssessmentPoints = (
  assessmentSequences: AssessmentSequenceWithOverride[]
): number => {
  return assessmentSequences.reduce((totalPoints, assessmentSequence) => {
    totalPoints += calculateAssessmentSequenceValue(assessmentSequence)
    return totalPoints
  }, 0)
}

export const calculateTotalAssessmentPointsFromCompoundInstances = (
  assessmentSequences: AssessmentSequenceWithOverride[],
  compoundInstances: Commons.ICompoundInstance[]
): number => {
  const assessmentSequencesAsDict = assessmentSequences
    ? getAssessmentSequencesBySequenceVariationId(assessmentSequences)
    : {}
  return compoundInstances.reduce((totalPoints, compoundInstance) => {
    const parentSequenceVariationId = getParentSequenceVariationId(compoundInstance)
    const assessmentSequence = assessmentSequencesAsDict[parentSequenceVariationId]
    totalPoints += assessmentSequence ? calculateAssessmentSequenceValue(assessmentSequence) : 1
    return totalPoints
  }, 0)
}

export const getAssessmentSequencesBySequenceVariationId = (
  assessmentSequences: AssessmentSequenceWithOverride[]
): Dictionary<AssessmentSequenceWithOverride> => {
  return keyBy<AssessmentSequenceWithOverride>(assessmentSequences, 'pathSequenceVariationId')
}

export const countAssessmentSequences = (
  assessmentSequences: AssessmentSequenceWithOverride[]
): number => {
  return assessmentSequences.reduce((questionCount, assessmentSequence) => {
    return (questionCount += isQuestionRemovedFromAssessment(assessmentSequence) ? 0 : 1)
  }, 0)
}

export const getAssessmentSequencesWithOverrides = (
  assessmentSequences: Commons.IPathSequence[],
  sequenceOverridesMap: SequenceOverridesMap
): AssessmentSequenceWithOverride[] => {
  return assessmentSequences.map(assessmentSequence => {
    const override = sequenceOverridesMap && sequenceOverridesMap[assessmentSequence.sequenceId]
    return {
      ...assessmentSequence,
      resultOverride: override ? override.resultOverride : null,
      overrideValue: override ? override.overrideValue : null,
    }
  })
}

export const getSequenceCounts = (assessmentSequences: Commons.IPathSequence[]) => {
  return countBy(assessmentSequences, (assessmentSequence: Commons.IPathSequence) => {
    return assessmentSequence.sequenceId
  })
}

export const getConceptIdsForPathLearningObjective = (
  learningObjective: Partial<Content.ILearningObjective>,
  pathLearningObjective: Commons.PathLearningObjective
): string[] => {
  const loConceptIds = learningObjective.conceptCoverage.map(concept => concept.id)
  const blackListedConceptIds = pathLearningObjective.conceptBlacklist
  return difference(loConceptIds, blackListedConceptIds)
}

export const getObjectivesProgressByTopicId = (
  pathLearningObjectives: Commons.PathLearningObjective[],
  loProgress: Analytics.IStatusAndProgressTarget[]
): ObjectiveProgressByTopicId => {
  const loProgressById = keyBy(loProgress, 'target_id')

  const objectivesProgress = pathLearningObjectives.map(plo => {
    return {
      ...omit(plo, 'conceptBlacklist'),
      progress: loProgressById[`lref-${plo.learningObjectiveId}`]
        ? loProgressById[`lref-${plo.learningObjectiveId}`].progress
        : 0,
    }
  })

  return groupBy(objectivesProgress, 'topicId')
}
