import { Reducer } from 'redux'
import {
  API_GET_ENROLLMENT_CONTENT,
  API_GET_NEXT_ENROLLMENT_CONTENT,
  API_GET_INSTRUCTION_BY_CONCEPT,
  API_GET_HISTORY_CONTENT,
  API_GET_SEQUENCE_PREVIEW,
  API_GET_LEARN_FLOW,
  API_GET_ASSIGNMENT,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { UiActionType, ComponentLoadAction } from 'app/frontend/pages/material/learn/ui/ui-actions'
import { API_SEND_LEARN_EVENTS } from 'app/frontend/pages/material/learn/learn-flow/events/learn-flow-events-actions'
import { UPDATE_CONTENT } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { LoadingState } from 'app/frontend/pages/material/learn/ui/loading-state'
import STATUS_CODES from 'app/brackend/status-codes'
import CODES from 'app/frontend/api/codes'
import { API_GET_COURSE } from '../../learn-actions'

export interface LearnFlowLoadingState {
  initialContent: LoadingState
  moreContent: LoadingState
  event: LoadingState
}

export const getEventLoadingState = (state: LearnControllerState): LoadingState =>
  state.ui.learnFlow.loading.event

export const getMoreContentLoadingState = (state: LearnControllerState): LoadingState =>
  state.ui.learnFlow.loading.moreContent

export const getInitialContentLoadingState = (state: LearnControllerState): LoadingState =>
  state.ui.learnFlow.loading.initialContent

const defaultState = {
  initialContent: LoadingState.None,
  moreContent: LoadingState.None,
  event: LoadingState.None,
}

export const reducer = (
  state: LearnFlowLoadingState = defaultState,
  action: any
): LearnFlowLoadingState => {
  let notFound, initialContent

  if (!action) {
    return state
  }

  switch (action.type) {
    // Initial Content loading
    case API_GET_ENROLLMENT_CONTENT.INIT:
    case API_GET_ASSIGNMENT.INIT:
    case API_GET_LEARN_FLOW.INIT:
    case API_GET_SEQUENCE_PREVIEW.INIT:
      return {
        ...state,
        initialContent: LoadingState.Loading,
      }

    case API_GET_ENROLLMENT_CONTENT.SUCCESS:
    case API_GET_SEQUENCE_PREVIEW.SUCCESS:
      return {
        ...state,
        initialContent: LoadingState.Success,
      }

    case API_GET_ENROLLMENT_CONTENT.ERROR:
    case API_GET_ASSIGNMENT.ERROR:
    case API_GET_LEARN_FLOW.ERROR:
    case API_GET_SEQUENCE_PREVIEW.ERROR:
    case API_GET_COURSE.ERROR:
      notFound =
        (action.error.json && action.error.json.error.code === CODES.NOT_FOUND) ||
        (action.error.response && action.error.response.status === STATUS_CODES.NOT_FOUND) ||
        (action.error.json && action.error.json.error.statusCode === STATUS_CODES.NOT_FOUND)

      initialContent = notFound ? LoadingState.NotFound : LoadingState.Error

      return {
        ...state,
        initialContent,
      }

    // More Content loading
    case API_GET_NEXT_ENROLLMENT_CONTENT.INIT:
    case API_GET_INSTRUCTION_BY_CONCEPT.INIT:
    case API_GET_HISTORY_CONTENT.INIT:
      return {
        ...state,
        moreContent: LoadingState.Loading,
      }
    case UiActionType.ComponentLoad:
      if ((action as ComponentLoadAction).name === 'ASSIGNMENT_COVER') {
        return {
          ...state,
          initialContent: LoadingState.None,
          moreContent: LoadingState.None,
        }
      } else {
        return state
      }
    case UPDATE_CONTENT:
    case API_GET_NEXT_ENROLLMENT_CONTENT.SUCCESS:
      return {
        ...state,
        moreContent: LoadingState.Success,
      }

    case API_GET_NEXT_ENROLLMENT_CONTENT.ERROR:
    case API_GET_INSTRUCTION_BY_CONCEPT.ERROR:
    case API_GET_HISTORY_CONTENT.ERROR:
      return {
        ...state,
        moreContent: LoadingState.Error,
      }

    // Send Event loading
    // Disable submission buttons
    case API_SEND_LEARN_EVENTS.INIT:
      return {
        ...state,
        event: LoadingState.Loading,
      }

    case API_SEND_LEARN_EVENTS.SUCCESS:
      return {
        ...state,
        event: LoadingState.Success,
      }

    case API_SEND_LEARN_EVENTS.ERROR:
      notFound =
        (action.error.json && action.error.json.error.code === CODES.NOT_FOUND) ||
        (action.error.response && action.error.response.status === STATUS_CODES.NOT_FOUND) ||
        (action.error.json && action.error.json.error.statusCode === STATUS_CODES.NOT_FOUND)

      initialContent = notFound ? LoadingState.NotFound : LoadingState.Error

      return {
        ...state,
        initialContent,
        event: LoadingState.Error,
      }

    default:
      return state
  }
}

export default reducer as Reducer<LearnFlowLoadingState>
