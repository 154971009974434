import * as React from 'react'
import {
  IconMenu,
  IconMenuProps,
  Menu,
  MenuDivider,
  MenuDividerProps,
  MenuTheme,
} from 'accessible-react-toolbox/lib/menu'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { ButtonTheme } from 'app/frontend/components/material/button/button'
import * as styles from './index.css'

interface IconMenuOwnProps {
  darkIcon?: boolean
}

interface ButtonMenuProps {
  theme?: ButtonTheme
  menuTheme?: MenuTheme
  className?: string
  label: string
  position: 'static' | 'auto' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  onClick?: () => void
  icon?: JSX.Element
  dataBi: string
}

interface ButtonMenuState {
  active: boolean
  /**
   * Indicates how the menu was opened.
   */
  activatedBy: 'mouse' | 'keyboard'
}

export const MenuMaterial: React.FunctionComponent<IconMenuProps & IconMenuOwnProps> = (
  props
): JSX.Element => {
  const { darkIcon, ...rest } = props

  return (
    <IconMenu
      {...rest}
      theme={{ iconMenu: darkIcon ? styles.darkMenu : styles.lightMenu, ...props.theme }}
    />
  )
}

MenuMaterial.displayName = 'MenuMaterial'

export class ButtonMenu extends React.Component<ButtonMenuProps, ButtonMenuState> {
  private buttonRef: HTMLOrSVGElement

  constructor(props) {
    super(props)
    this.state = {
      active: false,
      activatedBy: 'mouse',
    }
  }

  static defaultProps = {
    theme: 'bordered',
  }

  handleButtonClick = (e: UIEvent) => {
    // event.detail = 0 means keyboard.
    // https://stackoverflow.com/questions/19244666/how-to-detect-if-click-event-has-been-triggered-by-enter
    const activatedBy = e.detail === 0 ? 'keyboard' : 'mouse'
    this.setState({ active: !this.state.active, activatedBy })
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  handleMenuHide = () => this.setState({ active: false })

  componentDidUpdate(_prevProps: ButtonMenuProps, prevState: ButtonMenuState) {
    // When the menu is closed and keyboard was used to open it,
    // we need to bring back the focus to the button.
    if (prevState.active && !this.state.active) {
      if (this.state.activatedBy === 'keyboard') {
        this.buttonRef.focus()
      }
    }
  }

  render() {
    return (
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <ButtonMaterial
          icon={this.props.icon}
          aria-expanded={this.state.active}
          aria-haspopup="true"
          onClick={this.state.active ? null : this.handleButtonClick}
          label={this.props.label}
          theme={this.props.theme}
          className={this.props.className}
          data-bi={this.props.dataBi}
          setButtonRef={r => (this.buttonRef = r)}
        />
        <Menu
          position={this.props.position}
          active={this.state.active}
          onHide={this.handleMenuHide}
          theme={this.props.menuTheme}
        >
          {this.props.children}
        </Menu>
      </div>
    )
  }
}

export const MenuDividerMaterial: React.FunctionComponent<MenuDividerProps> = (
  props
): JSX.Element => {
  const { theme, ...rest } = props
  return (
    <MenuDivider
      {...rest}
      theme={{
        menuDivider: styles.menuDivider,
        ...theme,
      }}
    />
  )
}
