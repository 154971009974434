import * as React from 'react'
import ErrorBoundary from './error-boundary'

export interface FallbackProps {
  /** Can be called to remount children. */
  remount: () => void

  /** How many attempts we've made at remounting. */
  numberOfMounts: number

  /** The error thrown and caught. */
  error: Error
}

interface Props {
  /** Emitted during error logging as the source of the error.
   * Defaults to 'content-error-boundary'
   */
  source: string

  /** Renders this component when an error occurs. */
  fallback: (props: FallbackProps) => React.ReactNode

  children: React.ReactNode
}

const RetryableErrorBoundary: React.FunctionComponent<Props> = ({ source, children, fallback }) => {
  const [error, setError] = React.useState<Error>()
  const [numberOfMounts, setNumberOfMounts] = React.useState(0)

  const handleRemount = React.useCallback(() => setNumberOfMounts(prev => prev + 1), [])

  React.useEffect(() => {
    setError(undefined)
  }, [numberOfMounts])

  if (error) {
    return <>{fallback({ remount: handleRemount, error, numberOfMounts })}</>
  }

  // We leverage the fact that changing "key" signals to React that it should unmount/mount the
  // entire subtree, thus the entire branch of the tree will be recreated if the error is cleared
  // and numberOfMounts has changed.
  return (
    <ErrorBoundary key={numberOfMounts} source={source} onError={e => setError(e)}>
      {children}
    </ErrorBoundary>
  )
}

export default RetryableErrorBoundary
