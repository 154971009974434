import * as React from 'react'
import * as _ from 'lodash'
import * as classNames from 'classnames'
import * as styles from './atom-attribution.css'
import { AtomAttribution, MediaAttribution } from './attribution'

interface Props {
  atom: GQL.Atom
}

/**
 * Renders the attributions for a given atom and all of the media (images, etc) contained
 * within.
 */
const InlineAtomAttributions: React.FunctionComponent<Props> = ({ atom }) => {
  const [showLicense, setShowLicense] = React.useState(false)

  return (
    <div
      className={classNames(styles.compoundAttribution, {
        [styles.hiddenAttribution]: !showLicense,
      })}
      onClick={() => setShowLicense(!showLicense)}
    >
      <AtomAttribution atom={atom} />
      {atom.media
        .filter(media => media.metadata)
        .map(media => (
          <React.Fragment key={media.id}>
            {' | '}
            <MediaAttribution media={media} />
          </React.Fragment>
        ))}
    </div>
  )
}

export default InlineAtomAttributions
