import {
  useAssessedSequences,
  UseAssessedSequencesResults,
} from 'app/frontend/pages/material/teach/compositions/connected/get-assessed-sequences'
import {
  useCachedSequencesWithBatchQuery,
  UseSequencesResults,
} from 'app/frontend/compositions/connected/get-sequences'
import { mapLoadingAndErrorToProps, RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { AssessedConceptsLoMap } from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'

export type UseAssessedConceptsResults = RenderProps<{
  assessedConceptsByLo: AssessedConceptsLoMap
}>

/**
 * Returns all assessed concepts for a given course or section
 * @param parentEntityId
 * @param parentEntityType
 */
export const useAssessedConcepts = (
  parentEntityId: string,
  parentEntityType: Commons.ParentEntityType
): UseAssessedConceptsResults => {
  const assessedSequencesData: UseAssessedSequencesResults = useAssessedSequences(
    parentEntityId,
    parentEntityType
  )
  const sequenceIds: string[] = assessedSequencesData?.sequenceIdToAssessmentsMap
    ? Object.keys(assessedSequencesData.sequenceIdToAssessmentsMap)
    : []
  const sequenceData: UseSequencesResults = useCachedSequencesWithBatchQuery(sequenceIds)
  const {
    loading,
    error,
    assessedSequencesData: { sequenceIdToAssessmentsMap },
    sequenceData: { sequences },
  } = mapLoadingAndErrorToProps({ assessedSequencesData, sequenceData })

  if (!sequenceIdToAssessmentsMap || !(sequences?.length > 0)) {
    return {
      loading,
      error,
      assessedConceptsByLo: {},
    }
  }

  const assessedConceptsByLoMap: AssessedConceptsLoMap = {}
  const assessedConceptsMap: { [conceptId: string]: Set<string> } = {}

  for (const sequence of sequences) {
    // a quiz sequence will only have exactly 1 atom
    const conceptIds = sequence.atoms[0].conceptIds
    const loId = sequence.atoms[0].learningObjectiveId
    const sequenceId = sequence.id
    const sequenceAssessments = sequenceIdToAssessmentsMap[sequenceId]

    // Multi alligned sequences will have > 1 concepts, but most have only 1
    for (const conceptId of conceptIds) {
      // If the concept id doesnt exist in assessedConceptsMap, add the key to assessedConceptsMap with all of
      // the assessments
      if (!assessedConceptsMap[conceptId]) {
        assessedConceptsMap[conceptId] = new Set(sequenceAssessments)
      }
      // Otherwise, add all of the assessment ids from sequenceAssessments to the concept id set
      else {
        sequenceAssessments.forEach(
          assessedConceptsMap[conceptId].add,
          assessedConceptsMap[conceptId]
        )
      }

      // Key assessedConceptsMap by loId
      assessedConceptsByLoMap[loId] = {
        ...assessedConceptsByLoMap[loId],
        [conceptId]: Array.from(assessedConceptsMap[conceptId]),
      }
    }
  }

  return {
    loading,
    error,
    assessedConceptsByLo: assessedConceptsByLoMap,
  }
}
