import * as React from 'react'
import { adopt, ChildrenFn } from 'react-adopt'
import { withLoadingAndError, RenderProps } from 'app/frontend/helpers/apollo/adopt'
import {
  GetSectionQuery,
  GetSectionRenderProps,
} from 'app/frontend/compositions/connected/get-section'
import {
  GetAssignmentOverrideRenderProps,
  GetAssignmentOverrideQuery,
} from 'app/frontend/pages/material/teach/compositions/connected/get-assignment-override'
import GetAssignmentQuery, {
  GetAssignmentRenderProps,
} from 'app/frontend/pages/material/teach/compositions/connected/get-assignment/get-assignment-query'

export type ResultProps = {
  sectionResults?: GetSectionRenderProps
  assignmentResults?: GetAssignmentRenderProps
  overrideResults?: GetAssignmentOverrideRenderProps
}

export type AdoptProps = {
  sectionId: string
  assignmentId: string
}

type AdoptInnerProps = AdoptProps & ResultProps & { render: ChildrenFn<RenderProps<ResultProps>> }

export type AdoptRenderProps = RenderProps<{
  section: GQL.GetSection.Section
  assignment: GQL.GetAssignment.Assignment
  override: GQL.GetAssignmentOverride.AssignmentOverride
}>

export type GetSectionWithCourseRenderProps = AdoptProps & {
  children: (props: AdoptRenderProps) => JSX.Element
}

export const mapProps = ({
  sectionResults,
  assignmentResults,
  overrideResults,
  loading,
  error,
}: RenderProps<ResultProps>): AdoptRenderProps => ({
  section: sectionResults.section,
  assignment: assignmentResults.assignment,
  override: overrideResults.override,
  loading,
  error,
})

export const GetAssignmentWithSectionQuery: React.FunctionComponent<GetSectionWithCourseRenderProps> = adopt<
  AdoptRenderProps,
  AdoptProps
>(
  {
    sectionResults: ({ sectionId, render }: AdoptInnerProps) => (
      <GetSectionQuery id={sectionId} children={render} />
    ),
    assignmentResults: ({ assignmentId, render }: AdoptInnerProps) => (
      <GetAssignmentQuery id={assignmentId} children={render} />
    ),
    overrideResults: ({ sectionId, assignmentId, render }: AdoptInnerProps) => (
      <GetAssignmentOverrideQuery
        assignmentId={assignmentId}
        sectionId={sectionId}
        children={render}
      />
    ),
  },
  withLoadingAndError(mapProps)
)

GetAssignmentWithSectionQuery.displayName = 'GetAssignmentWithSectionQuery'
