type STATUS_CODES = {
  [label: string]: number
}

// This module is only used in a few places, and they are mostly in the frontend.
// TODO: consider deleting this, and using app/frontend/api/codes instead.
const STATUS_CODES: STATUS_CODES = {}
STATUS_CODES.FORBIDDEN = 403
STATUS_CODES.NOT_FOUND = 404

export default STATUS_CODES
