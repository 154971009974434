import { combineReducers } from 'redux'
import { reducer as courseFormReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import entitiesReducer, { EntitiesState } from './entities/entities-reducer'
import uiReducer, { TeachUiReducerState } from './ui/ui-reducer'

import allCourses from './courses/all-courses-reducer'

import assignmentCoverReducer from './assignment-cover/assignment-cover-reducer'

import { featureFlagsReducer } from 'app/frontend/helpers/feature'
import popover, { PopoverState } from 'app/frontend/components/popover/popover-reducer'
import videoReducer, { VideoState } from 'app/frontend/components/video/video-reducer'
import paymentsReducer, {
  PaymentsModalState,
} from 'app/frontend/pages/material/learn/modals/payments/reducer'
import modalReducer, {
  IModalReducerState,
} from 'app/frontend/components/material/modal/modal-reducer'

export function shareUrl(state = null) {
  return state
}

export type TeachControllerState = {
  entities: EntitiesState
  ui: TeachUiReducerState
  modal: IModalReducerState
  form: any
  shareUrl: string
  payments: Partial<PaymentsModalState>
  video: VideoState
  // TODO: add types to all the fields below
  allCourses: any
  popover: PopoverState
  toastr: any
  featureFlags: any
  assignmentCover: any
  router?: any
}

export default combineReducers<TeachControllerState>({
  entities: entitiesReducer,
  ui: uiReducer,
  allCourses,
  assignmentCover: assignmentCoverReducer,
  featureFlags: featureFlagsReducer,
  form: courseFormReducer,
  popover,
  shareUrl,
  video: videoReducer,
  payments: paymentsReducer,
  // @TODO TEACH-2049, TEACH-2048 remove modal, header, toastr once teach is tuxofied
  modal: modalReducer,
  toastr: toastrReducer,
})
