import { createModal, PartSupplier, RenderProps } from 'app/frontend/components/material/modal'

export enum AssessmentBuilderModalType {
  QuestionSelection = 'ASSESSMENT_QUESTIONS_MODAL',
  LoSelection = 'ASSESSMENT_LO_SELECTION_MODAL',
  AssessmentSettings = 'ASSESSMENT_SETTINGS_MODAL',
}

export interface AssessmentLoSelectionModalOptions {
  assessmentId: string
  coursepackId: string
  parentEntityType: Commons.ParentEntityType
  parentEntityId: string
}

export interface AssessmentQuestionsModalOptions {
  assessmentId: string
  learningObjectiveId: string
  fromObjectivesModal: boolean
  chapterName: string
  topicName: string
  loDescription: string
  conceptId?: string
}

export interface AssessmentSettingsModalOption {
  assessment: GQL.GetAssignment.Assignment
  course: GQL.GetCourse.Course
  section: GQL.GetSection.Section
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  hasEditPermission: boolean
  overriddenProperties: GQL.GetAssignmentOverride.AssignmentOverride
}

export type AssessmentLoSelectionModalRenderProps = RenderProps<AssessmentLoSelectionModalOptions>
export type AssessmentLoSelectionModalPartSupplier = PartSupplier<AssessmentLoSelectionModalOptions>
export const ModalComponent = createModal<AssessmentLoSelectionModalOptions>()
