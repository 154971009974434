import { ISnackbarProps } from './snackbar'

export type SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const SHOW_SNACKBAR: SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export type HIDE_SNACKBAR = 'HIDE_SNACKBAR'
export const HIDE_SNACKBAR: HIDE_SNACKBAR = 'HIDE_SNACKBAR'
const defaultMessage = 'Sorry, something went wrong. Please try refreshing your browser.'

export type ShowSnackbarAction = {
  type: SHOW_SNACKBAR
  options: ISnackbarProps
}

export const showSnackbar = (options: ISnackbarProps): ShowSnackbarAction => {
  return {
    type: SHOW_SNACKBAR,
    options,
  }
}

export type HideSnackbarAction = {
  type: HIDE_SNACKBAR
}

export const hideSnackbar = (): HideSnackbarAction => {
  return {
    type: HIDE_SNACKBAR,
  }
}

export const errorOptions = {
  message: defaultMessage,
  timeout: null,
  actionLabel: 'Dismiss',
  handleClick: hideSnackbar,
}

export const defaultErrorSnackbar = (): ShowSnackbarAction => {
  return {
    type: SHOW_SNACKBAR,
    options: errorOptions,
  }
}

export type SnackbarActions = ShowSnackbarAction | HideSnackbarAction
