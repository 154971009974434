import { combineReducers } from 'redux'
import sequencesReducerById, { SequencesReducerByIdState } from './sequences-reducer-by-id'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'

export const getSequences = (state: TeachControllerState): SequencesReducerState =>
  state.entities.sequences
export const getSequencesById = (state: TeachControllerState): SequencesReducerByIdState =>
  getSequences(state).byId

export type SequencesReducerState = {
  byId: SequencesReducerByIdState
}

export default combineReducers<SequencesReducerState>({
  byId: sequencesReducerById,
})
