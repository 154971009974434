import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  icon: {
    position: 'absolute',
    top: '.75rem',
    right: '0',
    fill: theme.palette?.grey[400],
    outlineColor: '#064560 !important',
  },
  static: {
    position: 'relative',
    '& .MuiCalendarPicker-root': {
      backgroundColor: '#FFFFFF',
    },
  },
  textInput: {
    width: '12.5rem',
  },
  input: {
    borderTopLeftRadius: '.25rem',
    borderTopRightRadius: '.25rem',
    width: '8.125rem',
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette?.grey[400]} !important`,
    },
  },
  inputRoot: {
    width: '12rem',
    height: '2.5rem',
    color: theme.palette?.grey[600],
    fontFamily: theme.typography?.fontFamily,
    '& input': {
      width: '80%',
    },'& input:focus':{
      outlineColor:'#064560',
    }
  },
  label: {
    paddingTop: '.25rem',
    color: theme.palette?.grey[400],
    fontFamily: theme.typography?.fontFamily,
  },
  error: {
    position: 'absolute',
    fontSize: '.75rem',
    color: theme.palette?.error.main,
    display: 'block',
    width: '100%',
    paddingTop: '.1rem',
  },
  rightAligned: {
    position: 'absolute',
    right: '19.4rem',
  },
  // had to set optional get for the theme palette since unit tests are failing due to `blue`
  // color unavailability in default theme
  // related references :
  // https://stackoverflow.com/q/61087315,
  // https://github.com/mui-org/material-ui/issues/15404
  toggle: {
    background: theme.palette?.blue?.[800],
    '&:hover': {
      background: theme.palette?.blue?.[800],
    },
  },
}))

export const useCalendarStyles = makeStyles(() => ({
  calendar: {
    height: 455,
  },
  comboContainer: {
    position: 'absolute',
    zIndex: 1,
  },
  timeContainer: {
    position: 'absolute',
    top: '20rem',
    left: '1rem',
  },
  buttonContainer: {
    position: 'absolute',
    top: '25.125rem',
    left: '6rem',
    width: '19.375rem',
  },
  comboButton: {
    margin: '.5rem',
  },
  input: {
    width: '17.25rem',
  },
}))
