import { apiCall } from 'app/frontend/api/api-redux'
import { SagaIterator } from 'redux-saga'
import { takeEvery, all } from 'redux-saga/effects'
import { FFlipActions } from './fflip-actions'
import * as FFlipApi from 'app/frontend/api/fflip'

export function* onEnableFeature(action: FFlipActions.EnableFeatureAction): SagaIterator {
  yield* apiCall(FFlipApi.enableFeature, FFlipActions.API_ENABLE_FEATURE, action.featureName)
  location.reload()
}

export function* onDisableFeature(action: FFlipActions.DisableFeatureAction): SagaIterator {
  yield* apiCall(FFlipApi.disableFeature, FFlipActions.API_DISABLE_FEATURE, action.featureName)
  location.reload()
}

export default function* saga(): SagaIterator {
  yield all([
    takeEvery(FFlipActions.Actions.ENABLE_FEATURE, onEnableFeature),
    takeEvery(FFlipActions.Actions.DISABLE_FEATURE, onDisableFeature),
  ])
}
