import { Reducer } from 'redux'
import { SET_DISPLAY_ANALYTICS, SetDisplayAnalyticsAction } from './display-analytics-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import {
  API_GET_NEXT_ENROLLMENT_CONTENT,
  LEARN_FLOW_LOAD,
  LearnFlowLoadAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { SuccessAction } from 'app/frontend/api/api-redux'

export const getDisplayAnalytics = (state: LearnControllerState): boolean =>
  state.ui.learnFlow.displayAnalytics

export const _displayAnalyticsReducer = (
  state = false,
  action: SetDisplayAnalyticsAction | LearnFlowLoadAction | SuccessAction<any>
): boolean => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case SET_DISPLAY_ANALYTICS:
      return action.displayAnalytics
    case API_GET_NEXT_ENROLLMENT_CONTENT.SUCCESS:
    case LEARN_FLOW_LOAD:
      return false
    default:
      return state
  }
}

export const displayAnalyticsReducer = _displayAnalyticsReducer as Reducer<boolean>
