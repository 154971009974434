import { Action, combineReducers } from 'redux'
import sampleSequencesReducer, {
  SampleSequencesReducerState,
} from './sample-sequences/sample-sequences-reducer'
import { API_GET_TAXONOMY_SUBTREE } from 'app/frontend/helpers/taxonomy/taxonomy-actions'
import { getLearningObjectivesMapFromTaxonomy } from 'app/frontend/pages/material/teach/helpers/teach-helpers'

export interface ILearningObjectivesByIdState {
  [K: string]: Content.ILearningObjective
}

export function learningObjectivesByIdReducer(
  state = {},
  action: Action & any
): ILearningObjectivesByIdState {
  switch (action.type) {
    case API_GET_TAXONOMY_SUBTREE.SUCCESS:
      return Object.assign({}, state, getLearningObjectivesMapFromTaxonomy(action.response))
    default:
      return state
  }
}

export type LearningObjectiveReducerState = {
  byId: ILearningObjectivesByIdState
  sampleSequences: SampleSequencesReducerState
}

export const getLearningObjectives = state => state.entities.learningObjectives
export const getLearningObjectivesById = (state): ILearningObjectivesByIdState =>
  getLearningObjectives(state).byId
export const getLearningObjectiveById = (state, id): Content.ILearningObjective =>
  getLearningObjectivesById(state)[id]

export default combineReducers<LearningObjectiveReducerState>({
  byId: learningObjectivesByIdReducer,
  sampleSequences: sampleSequencesReducer,
})
