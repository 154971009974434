import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { FieldCheckboxLabel } from 'app/frontend/compositions/data/redux-form-fields'
import { FormNames } from './helper'
import * as styles from '../settings-modal.css'

const t = tns('teach:assessment_builder')

interface Props {
  isReadOnly: boolean
}

export class ShuffleQuestionOrderSetting extends React.Component<Props> {
  render() {
    return (
      <Box full="page" separator="top" pad="medium" data-test="shuffle-setting">
        <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
          <FieldCheckboxLabel
            name={FormNames.SHUFFLE_QUESTIONS}
            label={
              <Paragraph margin="none" weight="semibold">
                {t('settings_shuffle_question_header')}
              </Paragraph>
            }
            disabled={this.props.isReadOnly}
            className={styles.fieldCheckbox}
            labelPlacement={'start'}
            aria-describedby="settings-shuffle-question-description"
          />
        </Box>
        <Paragraph size="small" margin="small" id="settings-shuffle-question-description">
          {t('settings_shuffle_question_description')}
        </Paragraph>
      </Box>
    )
  }
}
