import { apiCall } from 'app/frontend/api/api-redux'
import * as LearnosityApi from 'app/frontend/api/learnosity-api'
import { API_LEARNOSITY_CREDENTIALS } from './learnosity-actions'
import { SagaIterator, delay } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { defaultErrorSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'

// Amount of time to wait before refreshing credentials
const FETCH_CREDENTIALS_INTERVAL_MS = 1800 * 1000 // 0.5 hour

// Amount of time to wait before retrying a failed request
const RETRY_FETCH_MS = 1 * 1000 // 1 second

/**
 * Fetch credentials every FETCH_CREDENTIALS_INTERVAL_MS.
 * Credentials are valid for about 3 hours. We preemptively fetch them to avoid learnosity
 * reporting errors.
 */
export default function* fetchCredentialsInterval(): SagaIterator {
  let errCount = 0
  while (errCount < 3) {
    try {
      yield call(apiCall, LearnosityApi.getCredentials, API_LEARNOSITY_CREDENTIALS)
      // we don't want errors to accumulate over time if at least one try is successful
      errCount = 0
      yield call(delay, FETCH_CREDENTIALS_INTERVAL_MS)
    } catch (e) {
      console.warn(`Failed to get learnosity credentials, retrying...`, e)
      errCount++
      // only wait 1s to retry
      yield call(delay, RETRY_FETCH_MS)
    }
  }
  // prompt the user to refresh the page if the fetching credentials call fails 3 times
  yield put(defaultErrorSnackbar())
}
