import { LOCATION_CHANGE } from 'connected-react-router'

let prevPathInfo: PrevPathInfo = {
  pathname: '',
  search: '',
}

export type PrevPathInfo = {
  pathname: string
  search: string
}

export const getPrevPathInfo = (state: any): PrevPathInfo => {
  return state.ui.prevPathInfo
}

/*
 * This reducer has no logic to go backwards in history. It can only go forwards.
 * It stores the previous route/pathname in state, even if the new route is a result
 * of the user hitting the browser back button.
 */
export function prevPathInfoReducer(
  state: PrevPathInfo = { pathname: '', search: '' },
  action: any
): any {
  if (!action || action.type !== LOCATION_CHANGE) {
    return state
  }

  const newState = prevPathInfo
  prevPathInfo = action.payload

  return newState
}
