import * as React from 'react'
import { isEmpty } from 'lodash'
import * as classnames from 'classnames'
import { Box } from 'app/frontend/components/material/box'

const styles = require('./page.css')

export type PageBodyTheme = 'dark' | 'light'

interface State {
  index: number
}

interface Props {
  theme?: PageBodyTheme
  className?: string
  tabIndex?: number // override Tabs logic to set the active tab
  noPadding?: boolean
}

/**
 * Page component for displaying the main content. Depending on the
 * type of `children`, will return empty space, or the main content. Complements PageHero.
 */
export class PageBody extends React.Component<Props, State> {
  constructor(props, context) {
    super(props, context)
    this.state = {
      index: 0,
    }
  }

  render(): JSX.Element {
    const { children, className, noPadding } = this.props

    // Emptiness
    if (isEmpty(children)) {
      return <Box margin="medium" />
    }

    return (
      <div
        className={classnames(styles.child, className, {
          [styles.noPadding]: noPadding,
        })}
      >
        {children}
      </div>
    )
  }
}

export default PageBody
