import * as React from 'react'
import PopoverButton from 'app/frontend/components/material/button/popover-button'
import { QuestionPreferencesOption } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences-option'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences.css'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { setQuestionPreferences } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences-action'
import { QuestionPreferencesStatus } from 'app/frontend/data/question-preference-enum'
import { connect } from 'react-redux'

const t = tns('teach:assessment_lo_selection_modal')

export interface OwnProps {
  assessmentId: string
}

interface DispatchProps {
  persistQuestionPreferences: (questionType: string, numOfQuestionPerLo: number) => void
}

interface StateProps {
  questionType: string
  numQuestion: number
  preferencesStatus: string
}

type Props = StateProps & OwnProps & DispatchProps

export const _QuestionPreferences: React.FunctionComponent<Props> = ({
  assessmentId,
  questionType,
  numQuestion,
  persistQuestionPreferences,
  preferencesStatus,
}) => {
  return (
    <PopoverButton
      id={assessmentId}
      label={t('question_preferences')}
      theme={preferencesStatus === QuestionPreferencesStatus.APPLIED ? 'primary' : 'bordered'}
      customPopoverCls={styles.customPopoverCls}
      dataBi="preference-popover"
    >
      <QuestionPreferencesOption
        numQuestionsPerLo={numQuestion}
        questionType={questionType}
        updateState={persistQuestionPreferences}
      />
    </PopoverButton>
  )
}

/**
 * Dispatching setQuestionPreferences action to the store
 * @param dispatch
 */
function mapDispatchToProps(dispatch): DispatchProps {
  return {
    persistQuestionPreferences: (questionType: string, numOfQuestionPerLo: number) =>
      dispatch(setQuestionPreferences(questionType, numOfQuestionPerLo)),
  }
}

function mapStateToProps(state: any): StateProps {
  const { questionPreferences } = state.ui.assessmentBuilder

  return {
    questionType: questionPreferences.questionType,
    numQuestion: questionPreferences.count,
    preferencesStatus: questionPreferences.status,
  }
}

/**
 * Connect QuestionPreferences component to redux store
 * with props and actions
 */
export const QuestionPreferences = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(_QuestionPreferences)
