import * as React from 'react'
import { AssessmentQuestionLoCounts } from 'app/frontend/pages/material/teach/assessment-builder/assessment-question-lo-counts/assessment-question-lo-counts'
import { useAssessmentSequencesWithSequenceOverrides } from 'app/frontend/compositions/connected/get-assessment-sequences'
import {
  calculateTotalAssessmentPoints,
  countAssessmentSequences,
} from 'app/frontend/helpers/assignment'
import { useAssessmentQuestionsMetadata } from 'app/frontend/pages/material/teach/assessment-builder/assessment-question-lo-counts/use-assessment-questions-metadata'
import { LoadingAndError } from 'app/frontend/helpers/apollo/adopt'

export interface OwnProps {
  assessmentId: string
  parentEntityType: Commons.ParentEntityType
  parentEntityId: string
  /**
   * True to display warnings about LO/sequence selections that may be
   * a mistake based on assessment settings and other content in the
   * course/section
   */
  showWarnings: boolean
}

type Props = OwnProps

export const AssessmentQuestionLoCountsLoader: React.FunctionComponent<Props> = ({
  assessmentId,
  parentEntityType,
  parentEntityId,
  showWarnings,
}) => {
  const { assessmentQuestionMetadata, ...le1 } = useAssessmentQuestionsMetadata(
    assessmentId,
    parentEntityId,
    parentEntityType
  )
  const { assessmentSequencesWithOverrides, ...le2 } = useAssessmentSequencesWithSequenceOverrides(
    assessmentId
  )

  // check for loading & error state
  const loadingAndErrorResults: LoadingAndError[] = [le1, le2]
  const loading = loadingAndErrorResults.some(r => r.loading)
  const error = loadingAndErrorResults.map(r => r.error).find(err => !!err)

  let totalAssessmentPoints
  let questionCount
  if (!loading && !error && assessmentSequencesWithOverrides) {
    totalAssessmentPoints = calculateTotalAssessmentPoints(assessmentSequencesWithOverrides)
    questionCount = countAssessmentSequences(assessmentSequencesWithOverrides)
  }

  return (
    <AssessmentQuestionLoCounts
      {...assessmentQuestionMetadata}
      parentEntityType={parentEntityType}
      questionCount={questionCount}
      totalAssessmentPoints={totalAssessmentPoints}
      loading={loading}
      error={error}
      showWarnings={showWarnings}
    />
  )
}

AssessmentQuestionLoCountsLoader.displayName = 'AssessmentQuestionLoCountsLoader'
