import { isEmpty } from 'lodash'
import { useQuery } from '@apollo/client'
import * as GET_LEARNING_OBJECTIVES_FOR_COURSE from './get-learning-objectives-for-course.gql'

import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import {
  buildLosToAssignmentMap,
  LearningObjectivesToAssignmentsMap,
} from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'

export type UseStudentProfileForSectionResults = RenderProps<{
  assignmentsByLearningObjectiveId?: LearningObjectivesToAssignmentsMap
}>

export const useLearningObjectiveForCourse = (
  courseId: string
): UseStudentProfileForSectionResults => {
  const { loading, error, data } = useQuery<
    GQL.GetLearningObjectivesForCourse.Query,
    GQL.GetLearningObjectivesForCourse.Variables
  >(GET_LEARNING_OBJECTIVES_FOR_COURSE, {
    variables: {
      courseId,
    },
  })

  return {
    loading,
    error,
    assignmentsByLearningObjectiveId: isEmpty(data)
      ? null
      : buildLosToAssignmentMap(data.learningObjectivesForCourse),
  }
}
