import * as React from 'react'
import * as moment from 'moment'
import { DateTimeFormats } from 'app/helpers/timezone'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { getFocusIndex, optionsArray } from './helpers'
import { anyTimeFormatRegex } from '../helpers'

const t = tns('timepicker')

type TimePickerReducerProps = {
  value: moment.Moment
  timeFormat: DateTimeFormats
  timeFormatRegex: RegExp
  defaultTime: string
  accept24h: boolean
}

type TimePickerReducerState = [
  { time: moment.Moment; inputTime: string; error: string; focusIndex: number },
  {
    setTime: (newTime: string) => void
    handleBlur: () => void
    handleFocus: () => void
    handleChange: (newTime: string) => void
  }
]

type TIME_ACTIONS =
  | {
      type: 'CHANGE'
      time: string
    }
  | {
      type: 'KEYBOARD_INPUT'
      time: string
    }
  | {
      type: 'FOCUS'
    }
  | {
      type: 'BLUR'
    }

type TIME_STATE = {
  focusIndex: number
  inputTime: string
  time: moment.Moment
  error: string
}

export const timePickerReducer = ({
  value,
  defaultTime,
  timeFormat,
  timeFormatRegex,
  accept24h,
}: TimePickerReducerProps): TimePickerReducerState => {
  const [{ time, inputTime, error, focusIndex }, dispatch] = React.useReducer(
    (state: TIME_STATE, action: TIME_ACTIONS): TIME_STATE => {
      switch (action.type) {
        case 'CHANGE':
        case 'KEYBOARD_INPUT':
          const nextFocusIndex = getFocusIndex(action.time, optionsArray)
          return {
            time: action.type === 'CHANGE' ? moment(action.time, timeFormat) : state.time,
            focusIndex: nextFocusIndex === -1 ? state.focusIndex : nextFocusIndex,
            error: '',
            inputTime: action.time,
          }
        case 'FOCUS':
          return {
            ...state,
            focusIndex: getFocusIndex(state.inputTime, optionsArray),
            error: '',
          }
        case 'BLUR':
          if (state.inputTime === '') {
            if (!value) {
              return {
                focusIndex: 0,
                time: value,
                inputTime: '',
                error: '',
              }
            } else if (defaultTime) {
              return {
                focusIndex: 0,
                time: moment(defaultTime, timeFormat),
                inputTime: defaultTime,
                error: '',
              }
            }
            return {
              focusIndex: 0,
              time: value,
              inputTime: value.format(timeFormat),
              error: '',
            }
          }
          const isValid = state.inputTime.match(timeFormatRegex)
          if (!isValid) {
            // if its not valid, check to see if it could be 24h time
            if (accept24h && state.inputTime.match(anyTimeFormatRegex)) {
              const time24h = moment(state.inputTime, 'h:mm')
              return {
                ...state,
                inputTime: time24h.format(timeFormat),
                time: time24h,
                error: '',
              }
            } else {
              return {
                ...state,
                error: t('time_error'),
              }
            }
          }
          return {
            ...state,
            inputTime: moment(state.inputTime, timeFormat).format(timeFormat),
            time: moment(state.inputTime, timeFormat),
            error: '',
          }
        default:
          return state
      }
    },
    {
      time: value,
      inputTime: value ? value.format(timeFormat) : '',
      focusIndex: 0,
      error: '',
    }
  )

  // Actions
  const handleChange = (newTime: string) => dispatch({ type: 'CHANGE', time: newTime })
  const setTime = (newTime: string) => dispatch({ type: 'KEYBOARD_INPUT', time: newTime })
  const handleBlur = () => dispatch({ type: 'BLUR' })
  const handleFocus = () => dispatch({ type: 'FOCUS' })

  return [
    { time, inputTime, error, focusIndex },
    { setTime, handleBlur, handleFocus, handleChange },
  ]
}
