import * as React from 'react'
import 'wicg-inert'

interface InertProps {
  disabled?: boolean
}

/**
 * Removes keyboard interactivity from the DOM tree starting at this node.
 * This is useful to ensure that components which have been visually removed from the screen
 * are not still reachable to keyboard users.
 *
 * This is a little flaky when the DOM subtree is changed after the inert attribute has been
 * set, sometimes the inertness isn't reflected in the new tree. Rather than try to hack
 * around it here and risk woeful performance, we'll just live with it (for now).
 */
export const Inert: React.FunctionComponent<InertProps> = ({ disabled, children }) => {
  const handleRef = React.useCallback(
    node => {
      if (disabled) {
        node?.removeAttribute('inert')
      } else {
        node?.setAttribute('inert', '')
      }
    },
    [disabled]
  )

  return <div ref={handleRef}>{children}</div>
}
