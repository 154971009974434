import * as React from 'react'
import { Field, Form, reduxForm, InjectedFormProps } from 'redux-form'
import { tns } from 'app/frontend/helpers/translations/i18n'
import FormTextInputMaterial from 'app/frontend/components/material/textinput/formtextinput'

const t = tns('feedback')

interface OwnProps {
  /**
   * The action to perform on form submission
   */
  onSubmit: (values: FormValues) => void
}

export interface FormValues {
  description: string
}

type Props = OwnProps & InjectedFormProps<FormValues, OwnProps>

export const CONTENT_FEEDBACK_FORM = 'CONTENT_FEEDBACK_FORM'

const FeedbackForm: React.FunctionComponent<Props> = ({ handleSubmit, onSubmit }) => {
  return (
    <Form name={CONTENT_FEEDBACK_FORM} onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="description"
        type="text"
        component={renderDescription}
        validate={validateDescription}
        label={t('feedback_label')}
      />
    </Form>
  )
}

export default reduxForm({
  form: CONTENT_FEEDBACK_FORM,
})(FeedbackForm)

/**
 * FIELDS
 */

/**
 * Description
 */

const DESCRIPTION_MINIMUM_CHARS = 6

export const validateDescription = (value: string | undefined): string | undefined => {
  if (!value || value.length < DESCRIPTION_MINIMUM_CHARS) {
    return t('description_length')
  }
}

export const renderDescription = (field: Field) => {
  return (
    <FormTextInputMaterial
      required={true}
      field={field}
      label={field.label}
      multiline={true}
      maxLength={1000}
      name={field.input.name}
      describedBy={'descriptionScreenshot'}
    />
  )
}
