import { API_CREATE_COURSE } from 'app/frontend/pages/teach/entities/courses/course-actions'

function isFetchingReducer(state = false, action) {
  switch (action.type) {
    case API_CREATE_COURSE.INIT:
      return true
    case API_CREATE_COURSE.SUCCESS:
    case API_CREATE_COURSE.ERROR:
    case API_CREATE_COURSE.CANCEL:
      return false
    default:
      return state
  }
}

export default isFetchingReducer
