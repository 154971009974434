import {
  API_GET_SAMPLE_SEQUENCES,
  UPDATE_SAMPLE_SEQUENCES,
  UpdateSampleSequencesAction,
} from 'app/frontend/pages/teach/browse/browse-actions'
import { Action } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'

export type SequencesReducerByIdState = {
  [K: string]: Content.ISequence
}

export const sequencesReducerById = (
  state = {},
  action: Action & ApiSuccessAction & UpdateSampleSequencesAction
): SequencesReducerByIdState => {
  let newState, sequences

  switch (action.type) {
    case API_GET_SAMPLE_SEQUENCES.SUCCESS:
      newState = {}
      sequences = action.response.content.sequences || []

      sequences.forEach(sequence => {
        const seqId = sequence.id

        if (!newState[seqId]) {
          newState[seqId] = sequence
        }
      })

      return {
        ...state,
        ...newState,
      }
    case UPDATE_SAMPLE_SEQUENCES:
      newState = {}
      sequences = action.content.sequences || []

      sequences.forEach(sequence => {
        const seqId = sequence.id

        if (!newState[seqId]) {
          newState[seqId] = sequence
        }
      })

      return {
        ...state,
        ...newState,
      }
    default:
      return state
  }
}

export default sequencesReducerById
