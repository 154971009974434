import { Reducer } from 'redux'
import { API_SEND_LEARNING_EVENTS } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { API_COMPLETE_QUIZ } from '../../quiz-flow/quiz-flow-actions'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export interface StatusState {
  error: boolean
  isSaving: boolean
  isSubmitting: boolean
  lastSaved: Date
}

export const getSavingStatus = (state: LearnControllerState): boolean => {
  return state.ui.quizFlow.status.isSaving
}

export const getSubmittingStatus = (state: LearnControllerState): boolean => {
  return state.ui.quizFlow.status.isSubmitting
}

const defaultState = {
  error: false,
  isSaving: false,
  isSubmitting: false,
  lastSaved: null,
}
export function status(state: StatusState = defaultState, action: ApiSuccessAction) {
  if (!action) {
    return state
  }
  switch (action.type) {
    case API_SEND_LEARNING_EVENTS.INIT:
      return {
        ...state,
        error: false,
        isSaving: true,
      }
    case API_SEND_LEARNING_EVENTS.SUCCESS:
      return {
        ...state,
        error: false,
        isSaving: false,
        lastSaved: new Date(),
      }
    case API_SEND_LEARNING_EVENTS.ERROR:
      return {
        ...state,
        error: true,
        isSaving: false,
      }
    case API_COMPLETE_QUIZ.INIT:
      return {
        ...state,
        isSubmitting: true,
      }
    case API_COMPLETE_QUIZ.SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      }
    case API_COMPLETE_QUIZ.ERROR:
      return {
        ...state,
        isSubmitting: false,
      }
    default:
      return state
  }
}

export default status as Reducer<StatusState>
