import * as React from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup as RadioGroupCore,
  RadioGroupProps as RadioGroupCoreProps,
} from '@mui/material'
import { WithStyles, makeStyles } from '@mui/styles'
import { map, omit } from 'lodash'
import { radioGroupStyles } from './styles'
import { Radio, Props as RadioProps } from './radio'
import { FormControlLabelRadioGroup } from 'app/frontend/components/material/form-control-label'

export interface RadioGroupProps extends Partial<RadioGroupCoreProps> {
  value: string // Value of the selected radio button.
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  row?: boolean
}

export interface RadioPropsForGroup extends RadioProps {
  children?: React.ReactNode
}
interface Props extends RadioGroupStyles {
  name: string
  radioGroupProps: RadioGroupProps
  radioProps: RadioPropsForGroup[]
  label?: string | React.ReactNode // For better screen reader assitance, provide label
  disabled?: boolean // Disables all the options. Disable prop on RadioPropsForGroup disables only the specified option
  dataBi?: string
}

const useStyles = makeStyles(radioGroupStyles)
export type RadioGroupStyles = Partial<WithStyles<typeof radioGroupStyles>>

export const RadioGroup: React.FunctionComponent<Props> = ({
  name,
  radioGroupProps,
  radioProps,
  label,
  disabled = false,
  classes = {},
  dataBi,
}) => {
  const styles = useStyles()
  const { row = false, value: selectedValue, onChange } = radioGroupProps

  const radioOptions = map(radioProps, prop => {
    const radioProp = { ...omit(prop, ['children']) }
    return (
      <React.Fragment key={radioProp.key || radioProp.value}>
        <FormControlLabelRadioGroup
          value={radioProp.value}
          label={radioProp.label}
          disabled={disabled || radioProp.disabled}
          classes={{
            root: classes?.labelRoot,
            label: classes?.label,
          }}
          control={
            <Radio
              value={radioProp.value}
              isSelected={selectedValue === radioProp.value}
              data-bi={`${name}_${radioProp.value}`}
              onChange={event => onChange(event, event.target.value)}
              {...radioProp}
            />
          }
        />
        {prop.children}
      </React.Fragment>
    )
  })

  return (
    <FormControl component="fieldset" data-bi={dataBi} className={classes?.formControl}>
      {label && (
        <FormLabel component="legend" className={styles.label}>
          {label}
        </FormLabel>
      )}
      <RadioGroupCore row={!!row} value={selectedValue} name={name} {...radioGroupProps}>
        {radioOptions}
      </RadioGroupCore>
    </FormControl>
  )
}
