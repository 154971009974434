import * as React from 'react'
import * as moment from 'moment'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('content_attribution')

interface Props {
  atom: GQL.Atom
}

export const CopyrightElsevier: React.FunctionComponent<Props> = ({ atom }) => {
  return (
    <span>
      {t('elsevier_copyright', {
        atomName: atom.name,
        year: moment().year(),
      })}
    </span>
  )
}

export default CopyrightElsevier
