import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { calculateTotalAssessmentPointsFromCompoundInstances } from 'app/frontend/helpers/assignment'
import { useAssessmentSequences } from './use-assessment-sequences'

export type UseTotalAssessmentPointsResults = RenderProps<{
  totalAssessmentPoints: number
  assessmentSequences: Commons.IPathSequence[]
}>

export const useTotalAssessmentPointsFromCompoundInstances = (
  assessmentId: string,
  compounds: Learn.IStateAndContent[]
): UseTotalAssessmentPointsResults => {
  const { loading, error, assessmentSequences } = useAssessmentSequences(assessmentId)
  const compoundInstances = compounds.map(compound => compound.compoundInstance)
  let totalAssessmentPoints = 0
  if (assessmentSequences) {
    totalAssessmentPoints = calculateTotalAssessmentPointsFromCompoundInstances(
      assessmentSequences,
      compoundInstances
    )
  }

  return {
    loading,
    error,
    totalAssessmentPoints,
    assessmentSequences,
  }
}
