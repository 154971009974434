import { Reducer } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import { API_GET_SEQUENCE_PREVIEW } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'

export interface SequencePreviewReducerState {
  pathId: string
}

const defaultState = {
  pathId: null,
}

export const reducer = (
  state: SequencePreviewReducerState = defaultState,
  action: ApiSuccessAction
): SequencePreviewReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_SEQUENCE_PREVIEW.SUCCESS:
      return {
        pathId: action.response.pathId,
      }
    default:
      return state
  }
}

export default reducer as Reducer<SequencePreviewReducerState>
