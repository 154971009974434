import * as React from 'react'
import { connect } from 'react-redux'
import DatasetAtom, {
  ActionProps,
  OwnProps as DatasetAtomOwnProps,
} from 'app/frontend/content/atoms/dataset-atom/dataset-atom'
import { DATASET_HELP_MODAL } from 'app/frontend/pages/material/learn/modals'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import * as textWidth from 'text-width'
import { defaultErrorSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'

interface StateProps {
  platform: string
  textWidth: (text: string, font: any) => number
}

type OwnProps = Commons.Omit<DatasetAtomOwnProps, keyof StateProps>

type DispatchProps = ActionProps

type Props = OwnProps & StateProps & DispatchProps

// A wrapper providing the flexibility to update the content component
export const DatasetAtomWrapper: React.FunctionComponent<Props> = props => (
  <DatasetAtom {...props} />
)

DatasetAtomWrapper.displayName = 'DatasetAtomWrapper'

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    onHelpButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      dispatch(showModal(DATASET_HELP_MODAL))
    },
    onCopyError: error => {
      console.error('Error copying csv dataset', {
        error,
        internalIssueId: 'CE-3468',
      })
      dispatch(defaultErrorSnackbar())
    },
  }
}
const mapStateToProps = (): StateProps => ({
  platform: window.navigator && window.navigator.platform,
  textWidth,
})

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(DatasetAtomWrapper)
