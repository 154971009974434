import * as React from 'react'
import * as classnames from 'classnames'
import {
  TextInputMaterial,
  ITextInputProps,
} from 'app/frontend/components/material/textinput/textinput'
import { Icon } from 'app/frontend/components/material/icon'
import { t } from 'app/frontend/helpers/translations/i18n'
import * as styles from './index.css'
import { useToggle } from 'app/frontend/components/material/helpers'

interface Props extends ITextInputProps {
  value: string
  label?: string
  hideLabel?: boolean
  error?: string
  passwordText?: string
  onChange: (e: any) => void
}

export const Password: React.FunctionComponent<Props> = props => {
  const [visible, toggleVisibility] = useToggle(false)

  const {
    label,
    hideLabel = false,
    error,
    value,
    onChange,
    disabled,
    passwordText = t('show_password'),
    ...otherProps
  } = props

  const iconClass = classnames(styles.togglePasswordIcon, {
    [styles.togglePasswordActive]: visible,
  })
  const buttonClass = classnames(styles.togglePassword, {
    [styles.togglePasswordWithoutLabelButton]: hideLabel,
  })
  const textInputClass = classnames({
    [styles.togglePasswordWithoutLabel]: hideLabel,
    [styles.togglePasswordInactiveWithoutLabel]: hideLabel && disabled,
  })

  return (
    <div className={styles.togglePasswordGroup}>
      <TextInputMaterial
        data-test="password-value"
        label={!hideLabel && (label || t('password'))}
        type={visible ? 'text' : 'password'}
        error={error}
        value={value}
        disabled={disabled}
        onChange={!disabled ? onChange : null}
        className={textInputClass}
        {...otherProps}
      />
      <button
        title={passwordText}
        type="button"
        onClick={toggleVisibility}
        className={buttonClass}
        data-test="password-toggle-visibility"
        aria-pressed={visible}
      >
        <Icon
          name={visible ? 'icon-visibility_on' : 'icon-visibility_off'}
          className={iconClass}
          focusable={false}
        />
      </button>
    </div>
  )
}
Password.displayName = 'Password'
/**
 * Password field that contains a button to
 *  toggle the visibility of the password.
 */
type FieldProps = {
  field: any
}
// TODO This password filed should be refactored so that it can be used in all the places where
//  password field has defined as separate function under YALA-638 task
export const PasswordField: React.FunctionComponent<FieldProps> = ({ field }) => {
  return (
    <Password
      label={field.label}
      error={field.meta.dirty && !field.meta.active && field.meta.error}
      {...{ ...(field.describedBy && { describedBy: field.describedBy }) }}
      {...field.input}
      passwordText={field.passwordText}
      {...(field.autoComplete && { autoComplete: field.autoComplete })}
    />
  )
}

export default PasswordField
