import { Action } from 'redux'

export enum ThirdPartyModalActionType {
  SetThirdPartyModalOpen = 'SET_THIRD_PARTY_MODAL_OPEN',
}

export interface SetThirdPartyModalOpenAction extends Action {
  type: ThirdPartyModalActionType.SetThirdPartyModalOpen
  isOpen: boolean
}

export const setThirdPartyModalOpen = (isOpen: boolean): SetThirdPartyModalOpenAction => {
  return {
    type: ThirdPartyModalActionType.SetThirdPartyModalOpen,
    isOpen,
  }
}
