import { makeApiActions } from 'app/frontend/api/api-redux'
import { Action } from 'redux'

export enum TeachCoursesAction {
  CREATE_COURSE = 'CREATE_COURSE',
  OPEN_COURSE = 'OPEN_COURSE',
  DELETE_COURSE = 'DELETE_COURSE',
  API_GET_COURSE_DATA = 'API_GET_COURSE_DATA',
  API_GET_ALL_COURSES = 'API_GET_ALL_COURSES',
}

export interface OpenCourseAction extends Action {
  type: TeachCoursesAction.OPEN_COURSE
  course: Commons.IRetailClass
}

export function openCourse(course: Commons.IRetailClass): OpenCourseAction {
  return {
    type: TeachCoursesAction.OPEN_COURSE,
    course,
  }
}

export interface DeleteCourseAction extends Action {
  type: TeachCoursesAction.DELETE_COURSE
  courseId
  redirectUrl
}
export function deleteCourse(courseId, redirectUrl = null): DeleteCourseAction {
  return {
    type: TeachCoursesAction.DELETE_COURSE,
    courseId,
    redirectUrl,
  }
}

export interface CreateCourseAction extends Action {
  type: TeachCoursesAction.CREATE_COURSE
  course: any
}
export function createCourse(course): CreateCourseAction {
  return {
    type: TeachCoursesAction.CREATE_COURSE,
    course,
  }
}
export const API_CREATE_COURSE = makeApiActions('API_CREATE_COURSE')
export const API_GET_COURSE = makeApiActions('API_GET_COURSE')
export const API_DELETE_COURSE = makeApiActions('API_DELETE_COURSE')

export const API_GET_ALL_COURSES = makeApiActions(TeachCoursesAction.API_GET_ALL_COURSES)
export const API_GET_COURSE_DATA = makeApiActions(TeachCoursesAction.API_GET_COURSE_DATA)
