import * as React from 'react'
import * as classnames from 'classnames/bind'
import { BoxContextProps, withBoxContext } from 'app/frontend/components/material/box/box'

import * as styles from './icon.css'
import { IconSize } from './icon'
const c = classnames.bind(styles)

interface Props extends React.HTMLAttributes<SVGElement>, BoxContextProps {
  /** The size icon. Default size is medium (1rem). */
  size?: IconSize
  /** Sets the icon svg title, if provided */
  title?: string
  /** Sets the aria role, if provided */
  role?: string
  /** If true, hidden from accessibility tools (default : true) */
  ariaHidden?: boolean
  /** If true, this element is focusable */
  focusable?: boolean
  /** If true, remove the role attribute (default : true) */
  roleHidden?: boolean
}

class _IconLoadingEllipsis extends React.Component<Props, {}> {
  /**
   * Returns a css class name of the pattern `prefix-value`.  If an object is passed into
   * the value, the pattern is then `prefix-value.key-value.value`.
   */
  private classes(prefix: string, value: boolean | string | object): string[] {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [`${prefix}-${value}`]
    } else if (typeof value === 'object') {
      return Object.entries(value).map(([key, val]) => `${prefix}-${key}-${val}`)
    } else {
      return []
    }
  }

  render(): JSX.Element {
    const {
      size = 'medium',
      className,
      ariaHidden = true,
      focusable,
      title,
      role,
      background,
      roleHidden = true,
      ...otherProps
    } = this.props

    return (
      <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ background: '0 0' }}
        id="icon-loading-ellipsis"
        width="100%"
        height="100%"
        className={classnames(
          c(`default`, this.classes('size', size), this.classes('context', { background })),
          className
        )}
        {...(!roleHidden && { role: typeof role !== 'undefined' ? role : 'presentation' })}
        aria-hidden={
          (typeof ariaHidden !== 'undefined' ? String(ariaHidden) : 'false') as 'true' | 'false'
        }
        focusable={
          (typeof focusable !== 'undefined' ? `${focusable}` : 'false') as 'true' | 'false'
        }
        {...otherProps}
      >
        {typeof title !== 'undefined' && <title>{title}</title>}
        <circle r="8" transform="matrix(.97124 0 0 .97124 25 50)">
          <animate
            attributeName="r"
            begin="-0.3333333333333333s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;8;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
            from="0%"
            to="100%"
          />
        </circle>
        <circle r="8" transform="matrix(.60805 0 0 .60805 50 50)">
          <animate
            attributeName="r"
            begin="-0.16666666666666666s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;8;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
            from="0%"
            to="100%"
          />
        </circle>
        <circle r="8" transform="matrix(.1547 0 0 .1547 75 50)">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;8;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
            from="0%"
            to="100%"
          />
        </circle>
      </svg>
    )
  }
}

export const IconLoadingEllipsis = withBoxContext<_IconLoadingEllipsis, Props>(_IconLoadingEllipsis)
