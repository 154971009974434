export const CUSTOM_QUESTIONS_MODAL = 'CUSTOM_QUESTIONS_MODAL'

export type CustomQuestionModalOptions = {
  assessmentId: string
  view: View
  pathSequenceVariationId?: string
  questionJson?: GQL.QuestionJson
  customItemVariationSequence?: GQL.CustomItemVariationSequence
  currentQuestionIndex?: number
  atom?: Content.IAtom
  editorMode?: EditorMode
  onVariationContentChange?: (isContentChanged: any) => void
  poolSize?: number
}

export enum View {
  Select = 'SELECT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  FreeResponse = 'FREE_RESPONSE',
}

/**
 * Enum representing various editor modes.
 * These modes are used to control the behavior of the editor component.
 * Can be used as context when translating header text using i18next.
 * For example, to translate the header text for the ADD_CUSTOM_QUESTION mode, use:
 * `t('header', { context: EditorMode.ADD_CUSTOM_QUESTION })`
 */
export enum EditorMode {
  ADD_CUSTOM_QUESTION = 'ADD_CUSTOM_QUESTION_HEADING',
  EDIT_CUSTOM_QUESTION = 'UPDATE_CUSTOM_QUESTION_HEADING',
  ADD_POOL_QUESTION = 'ADD_POOL_QUESTION',
  EDIT_POOL_QUESTION = 'UPDATE_POOL_QUESTION',
  FLAG_ENABLED_EDIT_CUSTOM_QUESTION = 'EDIT_CUSTOM_QUESTION',
}
