import * as React from 'react'
import * as classNames from 'classnames'
import Content from 'app/frontend/components/content'
import * as styles from 'app/frontend/components/compound/learnosity/learnosity.css'
import * as atomStyles from 'app/frontend/components/atom/atom.css'
import LearnosityComponent from './learnosity'
import LearnosityResponsePromptExplanation from './learnosity-response-prompt-explanation'

export interface Props {
  atom: Content.IAtom
  hideExplanation?: boolean
  hideQuestion?: boolean
  hideLearnosityComponent?: boolean
  hideCorrectAnswers?: boolean
  isVariationContentChanged?: boolean
  isFromPreview?: boolean
}

export const LearnosityContent: React.FunctionComponent<Props> = ({
  atom,
  hideQuestion,
  hideExplanation,
  hideLearnosityComponent,
  hideCorrectAnswers,
  isVariationContentChanged,
  isFromPreview,
}) => {
  const { media } = atom
  const data = atom.data as GQL.AtomLearnosityBlock

  return (
    <div className={styles.learnosity}>
      {!hideQuestion && (
        <Content
          className={classNames(atomStyles.question, atomStyles.userContent)}
          html={data.question}
          media={media}
          isVariationContentChanged={isVariationContentChanged}
        />
      )}
      {!hideLearnosityComponent && (
        <LearnosityComponent
          atom={atom}
          showCorrectAnswers={!hideCorrectAnswers}
          hideInputAnswers={!hideCorrectAnswers && data.content && data.content.type === 'custom'}
          isCustomQuestionChanged={isVariationContentChanged}
          isFromPreview={isFromPreview}
        />
      )}
      {!hideExplanation && (
        <LearnosityResponsePromptExplanation explanation={data.explanation} media={media} />
      )}
    </div>
  )
}

LearnosityContent.displayName = 'LearnosityContent'
