import { makeApiActions } from 'app/frontend/api/api-redux'

export type EVENT_TYPES = {
  [key: string]: Learn.EventType
}
export const EVENT_TYPES: EVENT_TYPES = {
  SUBMIT_COMPOUND: 'SUBMIT_COMPOUND',
  ANSWER_MULTI_CHOICE_MULTI_ANSWER_QUESTION: 'ANSWER_MULTI_CHOICE_MULTI_ANSWER_QUESTION',
  ANSWER_MULTI_CHOICE_QUESTION: 'ANSWER_MULTI_CHOICE_QUESTION',
  ANSWER_GRAPHING_QUESTION: 'ANSWER_GRAPHING_QUESTION',
  ANSWER_LEARNOSITY_MATH_QUESTION: 'ANSWER_LEARNOSITY_MATH_QUESTION',
  ANSWER_CHEMISTRY_FORMULA_QUESTION: 'ANSWER_CHEMISTRY_FORMULA_QUESTION',
  ANSWER_CHEMISTRY_WITH_IMAGE_QUESTION: 'ANSWER_CHEMISTRY_WITH_IMAGE_QUESTION',
  ANSWER_LEARNOSITY_GENERIC_QUESTION: 'ANSWER_LEARNOSITY_GENERIC_QUESTION',
  VIEW_ANSWER: 'VIEW_ANSWER',
}

export const ANSWER_EVENT_TYPES_LIST = [
  EVENT_TYPES.ANSWER_MULTI_CHOICE_MULTI_ANSWER_QUESTION,
  EVENT_TYPES.ANSWER_MULTI_CHOICE_QUESTION,
  EVENT_TYPES.ANSWER_GRAPHING_QUESTION,
  EVENT_TYPES.ANSWER_LEARNOSITY_MATH_QUESTION,
  EVENT_TYPES.ANSWER_CHEMISTRY_FORMULA_QUESTION,
  EVENT_TYPES.ANSWER_CHEMISTRY_WITH_IMAGE_QUESTION,
  EVENT_TYPES.ANSWER_LEARNOSITY_GENERIC_QUESTION,
]

export const API_SEND_LEARN_EVENTS = makeApiActions('API_SEND_LEARN_EVENTS')

export type AGENCY_REQUEST = 'AGENCY_REQUEST'
export const AGENCY_REQUEST: AGENCY_REQUEST = 'AGENCY_REQUEST'
export type CONTINUE_LEARNING = 'CONTINUE_LEARNING'
export const CONTINUE_LEARNING: CONTINUE_LEARNING = 'CONTINUE_LEARNING'
export type SEND_EVENTS = 'SEND_EVENTS'
export const SEND_EVENTS: SEND_EVENTS = 'SEND_EVENTS'
export type ANSWER_QUESTION = 'ANSWER_QUESTION'
export const ANSWER_QUESTION: ANSWER_QUESTION = 'ANSWER_QUESTION'
export type ANSWER_QUESTION_SUCCESS = 'ANSWER_QUESTION_SUCCESS'
export const ANSWER_QUESTION_SUCCESS: ANSWER_QUESTION_SUCCESS = 'ANSWER_QUESTION_SUCCESS'
export type SEQUENCE_COMPLETE = 'SEQUENCE_COMPLETE'
export const SEQUENCE_COMPLETE: SEQUENCE_COMPLETE = 'SEQUENCE_COMPLETE'
export type VIEW_ANSWER = 'VIEW_ANSWER'
export const VIEW_ANSWER: VIEW_ANSWER = 'VIEW_ANSWER'

export type AgencyType = 'SWITCH_TOPIC' | 'SWITCH_LO_IN_CURRENT_TOPIC' | 'ESCAPE_REMEDIATION'
export type AgencyRequestAction = {
  type: AGENCY_REQUEST
  /**
   * The current active CI when the agency is requested.
   * This is useful if agency is requested inside the context of
   * a CI like clicking remediation button inside a certain CI.
   *
   * If null, will infer based on the states of all CIs for the
   * current sequence.
   */
  activeCompoundInstanceId?: string
  agencyType: AgencyType
  learningObjectiveId?: string
  topicId?: string
}

type BaseAgencyRequestInput = {
  /**
   * The current active CI when the agency is requested.
   * This is useful if agency is requested inside the context of
   * a CI like clicking remediation button inside a certain CI.
   *
   * If null, will infer based on the states of all CIs for the
   * current sequence.
   */
  activeCompoundInstanceId?: string
}
type EscapeRemediationInput = BaseAgencyRequestInput & {
  agencyType: 'ESCAPE_REMEDIATION'
  learningObjectiveId: string
}
type SwitchLoInCurrentTopicType = BaseAgencyRequestInput & {
  agencyType: 'SWITCH_LO_IN_CURRENT_TOPIC'
  learningObjectiveId: string
}
type SwitchTopicType = BaseAgencyRequestInput & {
  agencyType: 'SWITCH_TOPIC'
  topicId: string
}
export type AgencyRequestInput =
  | EscapeRemediationInput
  | SwitchLoInCurrentTopicType
  | SwitchTopicType

export const agencyRequest = (request: AgencyRequestInput): AgencyRequestAction => {
  return {
    ...request,
    type: AGENCY_REQUEST,
  }
}

export type ContinueLearningAction = {
  type: CONTINUE_LEARNING
  compoundInstanceId: string
}
export const continueLearning = (compoundInstanceId: string): ContinueLearningAction => {
  return {
    type: CONTINUE_LEARNING,
    compoundInstanceId,
  }
}

export type SendEventsAction = {
  type: SEND_EVENTS
  events: Learn.IEvent[]
  assignmentId: string
}
export const sendEvents = (events: Learn.IEvent[], assignmentId: string): SendEventsAction => {
  return {
    type: SEND_EVENTS,
    events,
    assignmentId,
  }
}

export type Answer = number | number[] | Set<number> | JSX.Element | Commons.ILearnosityAnswer
export type AnswerQuestionAction = {
  type: ANSWER_QUESTION
  compound: Learn.IStateAndContent
  answer: Answer
}
export const answerQuestion = (
  compound: Learn.IStateAndContent,
  answer: Answer
): AnswerQuestionAction => {
  return {
    type: ANSWER_QUESTION,
    compound,
    answer,
  }
}

export type AnswerQuestionSuccessAction = {
  type: ANSWER_QUESTION_SUCCESS
  compound: Learn.IStateAndContent
}
export const answerQuestionSuccess = (
  compound: Learn.IStateAndContent
): AnswerQuestionSuccessAction => {
  return {
    type: ANSWER_QUESTION_SUCCESS,
    compound,
  }
}

export type SequenceCompleteAction = {
  type: SEQUENCE_COMPLETE
}
export const sequenceComplete = (): SequenceCompleteAction => {
  return {
    type: SEQUENCE_COMPLETE,
  }
}

export type ViewAnswerAction = {
  type: VIEW_ANSWER
  state: Learn.IStateAndContent
}
export function viewAnswer(state: Learn.IStateAndContent): ViewAnswerAction {
  return {
    type: VIEW_ANSWER,
    state,
  }
}
