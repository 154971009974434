export interface AtomVariationButtonActionsType {
  assessmentId?: string
  atom?: Content.IAtom
  sequenceId: string
  pathSequenceVariationId?: string
  atomId?: string
  variationId?: string
  sequenceVariationsUsed?: number
  maxNumberVariations?: number
  onRefreshAtomVariation?: () => void
  onQuestionNavigation?: (index: number) => void
  parentEntityType?: Commons.ParentEntityType
  fromObjectivesModal: boolean
  learningObjectiveId: string
  chapterName: string
  topicName: string
  loDescription: string
  questionVariationNumber?: number
  onVariationContentChange?: (isContentChanged: any) => void
  customSequenceVariationSize?: number
}

export const MIN_REQUIRED_ATOM_VARIATIONS = 3
export const isPoolQuestion = (atom: Content.IAtom): boolean => {
  return (
    atom &&
    (atom.poolSize >= MIN_REQUIRED_ATOM_VARIATIONS ||
      (atom.isCustomAssessmentItem && atom.poolSize > 1))
  )
}

export const isPoolSequenceAtom = (atom: Content.ISequenceAtom): boolean => {
  return atom && atom.variations.length >= MIN_REQUIRED_ATOM_VARIATIONS
}

export const getMaxNumberOfVariations = (sequenceAtomVariationIds: string[]): number =>
  Math.floor(sequenceAtomVariationIds.length / 2) || 1
