/**
 * Enum that represent the question types
 */
export enum QuestionPreference {
  STATIC = 'STATIC',
  POOL = 'POOL',
}

/**
 * Enum that represent whether selected preferences applied or not
 */
export enum QuestionPreferencesStatus {
  APPLIED = 'APPLIED',
  NOT_APPLIED = 'NOT_APPLIED',
}
