import * as React from 'react'
import * as classnames from 'classnames'

import { usePrevious } from 'app/frontend/hooks/use-previous'
import ContentLoader from './content-loader'
import * as tableStyles from 'app/frontend/components/compound/cms-table.css'

interface Props {
  html?: string
  media?: GQL.AtomMedia[]
  canAutoplay?: boolean
  className?: string
  isVariationContentChanged?: boolean
}

/**
 * This Content component will render HTML from the CMS.
 *
 * This component renders a <div> containing the Content's HTML. We render <ContentLoader>
 * alongside which will render Portals to inject any dynamic content into said div.
 *
 * If HTML changes then we remount the entire component tree because we need to re-inject
 * any dynamically loaded content.
 */
const Content: React.FunctionComponent<Props> = ({
  className,
  media,
  canAutoplay,
  html,
  isVariationContentChanged = false,
}) => {
  // This is called a ref callback
  const [div, setDiv] = React.useState<HTMLDivElement>()
  const refCb = React.useCallback((node: HTMLDivElement) => {
    setDiv(node)
  }, [])

  const previousHtml = usePrevious(html)

  if (html !== previousHtml && isVariationContentChanged === false) {
    console.error('Content HTML has changed', {
      previousHtml,
      nextHtml: html,
      internalIssueId: 'CE-3353',
    })
  }

  return (
    <React.Fragment key={html}>
      <div
        ref={refCb}
        className={classnames(className, tableStyles)}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      {!!html && !!div && <ContentLoader node={div} media={media} canAutoplay={canAutoplay} />}
    </React.Fragment>
  )
}

export default Content
