import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import { ApolloError, QueryHookOptions, useQuery } from '@apollo/client'
import * as COURSE from './get-course.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export interface GetCourseRenderProps {
  course?: GQL.GetCourse.Course
  loading?: boolean
  error?: ApolloError
}

type Props = GQL.QueryCourseArgs & {
  children: (props: GetCourseRenderProps) => JSX.Element
}

export const GetCourseQuery: React.FunctionComponent<Props> = ({ id, children }) => (
  <Query<GQL.GetCourse.Query, GQL.GetCourse.Variables> query={COURSE} skip={!id} variables={{ id }}>
    {({ data, loading, error }) =>
      children({
        course: isEmpty(data) ? null : data.course,
        loading,
        error,
      })
    }
  </Query>
)

export type UseGetCourseResults = RenderProps<{
  course: GQL.GetCourse.Course
}>

export const useGetCourse = (
  id: string,
  opts?: QueryHookOptions<GQL.GetCourse.Query, GQL.GetCourse.Variables>
): UseGetCourseResults => {
  const { loading, error, data } = useQuery(COURSE, { ...opts, variables: { id } })
  return {
    loading,
    error,
    course: isEmpty(data) ? null : data.course,
  }
}

GetCourseQuery.displayName = 'GetCourseQuery'

export default GetCourseQuery
