/**
 * Implements a Queue for submitting requests to typeset LaTex. MathJax only allows one DOM node
 * to be typeset at a time (because each typeset may result in an asynchronous file load), thus
 * we must coordinate typesetting across many page components.
 */
import queue, { CommandId, ConfigureOptions, TypesetOptions, Priority } from './queue'
import { whenReady } from './signals'

/**
 * Export a "simple" queue implementation as our default.
 */
export default {
  typeset: queue.typeset.bind(queue) as (element: Element, options?: TypesetOptions) => CommandId,
  cancel: queue.cancel.bind(queue) as (id: CommandId) => boolean,
  configure: queue.configure.bind(queue) as (options: ConfigureOptions) => void,
  start: queue.start.bind(queue) as () => void,
}

/**
 * Some non-queue MathJax functions
 */
export { whenReady }

/**
 * Export our main types. We'll probably want to change this if we ever move this into its
 * own library.
 */
export { CommandId, Priority }
