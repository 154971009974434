import { combineReducers } from 'redux'
import { taxonomyReducer } from 'app/frontend/helpers/taxonomy/taxonomy-reducer'
import entitiesReducer, { EntitiesState } from './entities/entities-reducer'
import uiReducer, { LearnUiReducerState } from './ui/ui-reducer'
import { reducer as formReducer } from 'redux-form'
import { SyncReduxRouterReducerState } from 'app/frontend/layout/material/sync-redux-router'
import { MaterialControllerState } from 'app/frontend/layout/material/material-controller'
import videoReducer, { VideoState } from 'app/frontend/components/video/video-reducer'
import popover, { PopoverState } from 'app/frontend/components/popover/popover-reducer'
import { LearnRouteParams } from './learn-routes'

export type LearnControllerState = {
  video: VideoState
  ui: LearnUiReducerState
  entities: EntitiesState
  taxonomy: { [id: string]: Taxonomy.ITaxon }
  router?: SyncReduxRouterReducerState<LearnRouteParams>
  form: any
  popover: PopoverState
}

export type BootstrappedLearnControllerState = LearnControllerState &
  MaterialControllerState<LearnRouteParams>

export default combineReducers<LearnControllerState>({
  video: videoReducer,
  ui: uiReducer,
  entities: entitiesReducer,
  taxonomy: taxonomyReducer,
  form: formReducer,
  popover,
})
