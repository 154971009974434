import * as React from 'react'
import { isEnabled } from 'app/frontend/helpers/feature'
import { ContentManagerProvider } from 'app/frontend/components/content/manager'
import { AtomVariationWithHeaderLoading } from 'app/frontend/components/loading/atom-variation-loading'
import { AtomErrorBoundary, RetryableAtomError, FatalAtomError } from './errors'
import AtomWrapperOverlay from './atom-wrapper-overlay'

interface Props {
  atom: Content.IAtom
  renderLocation: Content.AtomRenderLocation
  children: React.ReactNode
}

// At this point, we don't allow the error boundary to be retryable because we're not
// throwing custom exceptions that indicate a failure is retryable.
const MAX_BOUNDARY_RETRIES = 0

/**
 * Currently, this component provides three main mechanisms:
 *   1) Provides ContentManagerProvider contexts to sub-components.
 *   2) Publishes metrics at a per-atom granularity for monitoring rendering.
 *   3) Provides an Error Boundary that prevents errors from crashing the entire page.
 */
const AtomWrapper: React.FunctionComponent<Props> = ({ children, atom, renderLocation }) => {
  // used to remount the component tree via `key` prop
  const [retries, setRetries] = React.useState(0)

  return (
    <AtomErrorBoundary source={`Atom(${renderLocation})`} maxRetries={MAX_BOUNDARY_RETRIES}>
      <ContentManagerProvider key={`${retries}`} atom={atom} renderLocation={renderLocation}>
        {isEnabled('contentOverlayFlag') ? (
          <AtomWrapperOverlay
            renderLoading={() => <AtomVariationWithHeaderLoading />}
            renderErrors={errors =>
              errors.every(e => e.isRetryable) ? (
                <RetryableAtomError
                  errors={errors.map(e => e.message)}
                  onRetry={() => setRetries(r => r + 1)}
                />
              ) : (
                <FatalAtomError errors={errors.map(e => e.message)} />
              )
            }
          >
            {children}
          </AtomWrapperOverlay>
        ) : (
          children
        )}
      </ContentManagerProvider>
    </AtomErrorBoundary>
  )
}

export default AtomWrapper
