import { useLocation } from 'react-router'

/**
 * Returns the query param value
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
export function useQueryParam(param: string): string {
  const query = new URLSearchParams(useLocation().search)
  return query.has(param) ? query.get(param) : ''
}
