import * as React from 'react'
import { Field } from 'redux-form'
import { FormControl, FormLabel } from '@mui/material'
import { t } from 'app/frontend/helpers/translations/i18n'
import * as RegisterFields from './register-fields'
import * as styles from './register.css'

interface Props {
  googled: boolean
  forDistributor?: boolean
}

export const PersonalInfo: React.FunctionComponent<Props> = ({ googled, forDistributor }) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false)

  const togglePasswordVisibility = React.useCallback(
    e => {
      e.preventDefault()
      setShowPassword(!showPassword)
    },
    [showPassword]
  )

  const togglePasswordConfirmVisibility = React.useCallback(
    e => {
      e.preventDefault()
      setShowPasswordConfirm(!showPasswordConfirm)
    },
    [showPasswordConfirm]
  )

  return (
    <div>
      {googled && (
        <Field
          name="email"
          component={RegisterFields.DisabledEmail}
          label={t('registration_page:email')}
        />
      )}
      <Field
        name="firstName"
        component={RegisterFields.FirstName}
        label={t('registration_page:firstname')}
      />
      <Field
        name="lastName"
        component={RegisterFields.LastName}
        label={t('registration_page:lastname')}
      />
      {!googled && (
        <div>
          <Field
            name="email"
            component={RegisterFields.EmailName}
            label={
              forDistributor ? t('registration_page:work_email') : t('registration_page:email')
            }
          />
          <Field
            name="password"
            component={RegisterFields.Password}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            label={t('registration_page:password')}
            toggleText={t('show_password')}
            requirementsText={t('registration_page:password_requirements')}
            describedBy={'passwordRequirements'}
          />
          <Field
            name="passwordConfirm"
            component={RegisterFields.Password}
            showPassword={showPasswordConfirm}
            togglePasswordVisibility={togglePasswordConfirmVisibility}
            label={t('registration_page:password_confirm')}
            toggleText={t('show_confirm_password')}
          />
          {!forDistributor && (
            <FormControl component="fieldset" sx={{ width: '100%', marginTop: '1rem' }}>
              <FormLabel component="legend" className={styles.dobLabel}>
                {t('registration_page:date_of_birth')}
              </FormLabel>
              <div className={styles.birthday}>
                <RegisterFields.Month name="month" label={t('registration_page:month')} />
                <RegisterFields.Day name="day" label={t('registration_page:day')} />
                <RegisterFields.Year name="year" label={t('registration_page:year')} />
              </div>
            </FormControl>
          )}
        </div>
      )}
      {!forDistributor && (
        <Field
          name="role"
          component={RegisterFields.Role}
          studentLabel={t('registration_page:student')}
          instructorLabel={t('registration_page:instructor')}
        />
      )}
    </div>
  )
}

export default PersonalInfo
