// Any query that should be cached but not (solely) on an id property
// should be added to this list
export const typePolicies = {
  InstructorAccessForCourse: {
    keyFields: ['courseId'],
  },
  LearningObjectivesForAssignment: {
    keyFields: ['assignmentId'],
  },
  IsNowBeforeDueDate: {
    keyFields: ['assignmentId'],
  },
  AggregateEnrollmentMetricsForSectionByAssignmentType: {
    keyFields: ['sectionId'],
  },
  AssignmentsForSectionByAssignmentType: {
    keyFields: ['sectionId'],
  },
  AssignmentMetricsForSection: {
    keyFields: ['sectionId'],
  },
  InactivityCountsForSection: {
    keyFields: ['sectionId'],
  },
  MasteryCountsForSection: {
    keyFields: ['sectionId'],
  },
  StudentMetricsForSection: {
    keyFields: ['sectionId'],
  },
  LosMastereredForSectionForStudent: {
    keyFields: ['sectionId'],
  },
  SectionAssignment: {
    keyFields: ['path', ['id']],
  },
  BoostersForStudentForSection: {
    keyFields: ['sectionId', 'studentId'],
  },
  EnrollmentExtensionsForStudent: {
    keyFields: ['sectionId', 'studentId'],
  },
  StudentMetrics: {
    keyFields: ['sectionId', 'student', ['id']],
  },
  AssignmentOverride: {
    keyFields: ['sectionId', 'pathId'],
  },
  AssignmentMetrics: {
    keyFields: ['sectionId', 'assignmentId'],
  },
  EnrollmentExtensionsForAssignment: {
    keyFields: ['sectionId', 'assignmentId'],
  },
  EnrollmentMetricsForAssignmentByStudent: {
    keyFields: ['sectionId', 'assignmentId'],
  },
  StudentStatusBreakdownForAssignment: {
    keyFields: ['sectionId', 'assignmentId'],
  },
  StudentStatusBreakdownForAssignmentByLearningObjective: {
    keyFields: ['sectionId', 'assignmentId'],
  },
  EnrollmentMetrics: {
    keyFields: ['assignmentId', 'studentId'],
  },
  EnrollmentExtension: {
    keyFields: ['assignmentId', 'studentId'],
  },
  HistoryForAssignmentForStudent: {
    keyFields: ['assignmentId', 'studentId'],
  },
  LOMasteriesForAssignmentForStudent: {
    keyFields: ['assignmentId', 'studentId'],
  },
  HistoryForAssessmentForStudent: {
    keyFields: ['assignmentId', 'attempt', 'studentId'],
  },
  CoursepackPlan: {
    keyFields: ['planId'],
  },
  CoursepackTableOfContents: {
    keyFields: ['coursepackId'],
  },
  feature: {
    keyFields: ['name'],
  },
  FulfilledFreePilotOrder: {
    keyFields: ['accessCode'],
  },
  FulfilledPaidOrder: {
    keyFields: ['orderId'],
  },
  EnrollmentAttempt: {
    keyFields: ['enrollmentId', 'attemptNumber'],
  },
  EnrollmentGrade: {
    keyFields: ['enrollmentId'],
  },
  EnrollmentAttemptsForUserAndAssessment: {
    keyFields: ['assessmentId', 'userId'],
  },
  PostalAddress: {
    keyFields: ['streetAddressLine1', 'city'],
  },
  Atom: {
    keyFields: ['id', ['sequenceId', 'atomId', 'variationId']],
  },
  ApprovedCourseInstructor: {
    keyFields: object => {
      return `[CourseInstructor] ${object.courseId} ${object.instructorId}`
    },
  },
  CourseInstructor: {
    keyFields: object => {
      if (object.courseId && object.instructorId) {
        return `[CourseInstructor] ${object.courseId} ${object.instructorId}`
      }
      return null
    },
  },
  ApprovedSectionInstructor: {
    keyFields: object => {
      return `[SectionInstructor] ${object.sectionId} ${object.instructorId}`
    },
  },
  SectionInstructor: {
    keyFields: object => {
      return `[SectionInstructor] ${object.sectionId} ${object.instructorId}`
    },
  },
  DashboardStudentWithAttempts: {
    keyFields: ['userId', 'assessmentId'],
  },
  AssignmentLateSubmissionConfigForCourse: {
    keyFields: ['courseId'],
  },
  QuizLateSubmissionConfigForCourse: {
    keyFields: ['courseId'],
  },
  RemainingCreditAvailableForEnrollment: {
    keyFields: ['enrollmentId'],
  },
  LearningObjectivesForCourse: {
    keyFields: ['courseId'],
  },
  LearningObjectivesForSection: {
    keyFields: ['sectionId'],
  },
  CompoundInstanceOverride: {
    keyFields: ['assessmentId', 'compoundInstanceId'],
  },
  ResultOverrides: {
    keyFields: ['assessmentId', 'compoundInstanceId'],
  },
  SequenceOverride: {
    keyFields: ['assessmentId', 'sequenceId'],
  },
  AssessmentSequenceType: {
    keyFields: ['pathId', 'sequenceId', 'pathSequenceVariationId'],
  },
  AssessmentSequenceListType: {
    keyFields: ['assessmentId'],
  },
  AssessmentQuestionListType: {
    keyFields: ['assessmentId'],
  },
  CoursePlans: {
    keyFields: ['courseId'],
  },
  CourseSubscription: {
    keyFields: ['courseId'],
  },
  CustomSequence: {
    keyFields: ['sequenceId'],
  },
  CompletionEstimatesForAssignment: {
    keyFields: ['pathId'],
  },
  CompletionEstimatesForTopic: {
    keyFields: ['topicId'],
  },
  LtiEnrollment: {
    keyFields: ['enrollmentId'],
  },
  StudentCountsForCourse: {
    keyFields: ['courseId'],
  },
  SectionCountsForCourse: {
    keyFields: ['courseId'],
  },
  LoConcept: {
    keyFields: ['id'],
  },
  PathLearningObjectivesListType: {
    keyFields: object => {
      if (!object.assignmentId) {
        return null
      }
      return ['assignmentId']
    },
  },
  AtomSequence: {
    keyFields: ['atom', ['id', ['atomId']], 'pathSequence', ['pathSequenceVariationId']],
  },
  SectionStudentCount: {
    keyFields: ['sectionId'],
  },
  CommentsForAssessmentAttemptType: {
    keyFields: ['assessmentId', 'userId', 'attemptNumber'],
  },
  AgencyResponse: {
    keyFields: ['pathId'],
  },
  AssessmentAttempt: {
    keyFields: ['assessmentId', 'attemptNumber'],
  },
}
