import * as _ from 'lodash'
import { TOGGLE_CHAPTER_CARD } from 'app/frontend/pages/teach/ui/course/course-topics-actions'
import { TeachCoursesAction } from 'app/frontend/pages/teach/entities/courses/course-actions'

export const getActiveChapterCardIds = state => state.ui.course.activeChapterCardIds

function activeChapterCardIds(state: string[] = [], action) {
  switch (action.type) {
    case TOGGLE_CHAPTER_CARD:
      let newState
      const { cardId, isExpanded } = action
      if (isExpanded) {
        newState = [...state, cardId]
      } else {
        newState = _.filter(state, id => id !== cardId)
      }
      return newState
    case TeachCoursesAction.OPEN_COURSE:
      return []
    default:
      return state
  }
}

export default activeChapterCardIds
