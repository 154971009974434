import { useAssessmentSequences } from './use-assessment-sequences'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import {
  AssessmentSequenceWithOverride,
  getAssessmentSequencesWithOverrides,
} from 'app/frontend/helpers/assignment'
import { useSequenceOverrides } from 'app/frontend/compositions/connected/get-sequence-overrides'

export type UseAssessmentSequencesWithSequenceOverridesResults = RenderProps<{
  assessmentSequencesWithOverrides: AssessmentSequenceWithOverride[]
}>

export const useAssessmentSequencesWithSequenceOverrides = (
  assessmentId: string
): UseAssessmentSequencesWithSequenceOverridesResults => {
  const { loading: asLoading, error: asError, assessmentSequences } = useAssessmentSequences(
    assessmentId
  )
  const { loading: soLoading, error: soError, sequenceOverridesMap } = useSequenceOverrides(
    assessmentId
  )
  let assessmentSequencesWithOverrides = null
  if (assessmentSequences && sequenceOverridesMap) {
    assessmentSequencesWithOverrides = getAssessmentSequencesWithOverrides(
      assessmentSequences,
      sequenceOverridesMap
    )
  }
  return {
    loading: asLoading || soLoading,
    error: asError || soError,
    assessmentSequencesWithOverrides,
  }
}
