import * as LogReporter from 'app/frontend/helpers/log-reporter'
import { delay } from 'app/helpers/common/delay'

interface ServiceCloudAttribute {
  label: string
  transcript: string
}

/**
 * A map of attribute name to Service Cloud labels and transcript ids.
 */
const attributes: { [key: string]: ServiceCloudAttribute } = {
  domain: { label: 'Book / Journal Title', transcript: 'Book_Journal_Title__c' },
  courseName: { label: 'Course Name', transcript: 'Course_Name__c' },
  lastInstitutionName: {
    label: 'Institution / University',
    transcript: 'Institution_University__c',
  },
  assignmentId: { label: 'Assignment ID', transcript: 'Assignment_ID__c' },
  assignmentName: { label: 'Assignment Name', transcript: 'Assignment_Name__c' },
  journey: { label: 'Journey', transcript: 'Journey__c' },
}

/**
 * Adds or updates the given label in extraPrechatFormDetails.
 */
const setExtra = (attr: ServiceCloudAttribute, value: any) => {
  const extras = window.embedded_svc?.settings?.extraPrechatFormDetails
  if (extras) {
    // convert arrays or objects to string
    if (typeof value !== 'string') {
      value = JSON.stringify(value, null, 4)
    }

    const extra = extras.find(item => item.label === attr.label)
    if (extra) {
      // Update existing value
      extra.value = value
    } else {
      // Add new value
      extras.push({
        label: attr.label,
        transcriptFields: [attr.transcript],
        displayToAgent: true,
        value,
      })
    }
  }
}

/**
 * Add data to the payload that is sent to Service Cloud. Initial settigns is set in
 * base-scripts.ts.
 */
export function update(customInfo: object | undefined): void {
  if (customInfo) {
    for (const key in customInfo) {
      if (attributes[key]) {
        setExtra(attributes[key], customInfo[key])
      }
    }

    // Add all attributes to the Journey field because the Service Cloud team
    // does not want to add all the extra fields we collect.
    setExtra(attributes.journey, customInfo)
  }
}

/**
 * Open the chat window.
 */
export function openChat(): void {
  // Service Cloud does not have an API to open their widget.
  // Instead we pretend to click on it.
  document.getElementById('helpButtonSpan').click()
}

/**
 * The following code tries to instrument Service Cloud.
 * We are interested in tracking latencies to have a better understanding of the user experience.
 * This not something we want to keep forever. This is just to give us an idea of how slow the UX
 * is for our users. We should removes this after 2021-01-01.
 */
export async function instrument(): Promise<void> {
  const starTime = Date.now()
  let buttonEle = null
  let svc = null

  // wait ~30 seconds for Service Cloud to load
  while (starTime + 30 * 1000 > Date.now() && (!buttonEle || !svc)) {
    buttonEle = document.getElementById('helpButtonSpan')
    svc = window.embedded_svc as any
    await delay(1000)
  }

  if (!svc) {
    LogReporter.warn('service-cloud', 'embedded_svc not defined')
  } else if (!buttonEle) {
    LogReporter.warn('service-cloud', 'chat button not found')
  } else {
    // log the time it took to load
    LogReporter.info('service-cloud', {
      metric: 'button-load-ms',
      loadMs: Date.now() - starTime,
    })

    let helpButtonClickTime = null
    let startButtonClickTime = null

    // This event is triggered whe the service cloud button is clicked
    svc.addEventHandler('onHelpButtonClick', () => {
      startButtonClickTime = null
      helpButtonClickTime = Date.now()
    })

    // This event is triggered once after the button was clicked
    svc.addEventHandler('afterMaximize', async () => {
      try {
        if (helpButtonClickTime) {
          // Log the amount of time it took
          const loadMs = Date.now() - helpButtonClickTime
          LogReporter.info('service-cloud', {
            metric: 'pre-chat-load-ms',
            loadMs,
          })

          // Clear this so we don't log it twice
          helpButtonClickTime = null

          const starTime2 = Date.now()
          let startButtonEle = null
          // Wait ~30 seconds for "Start Chat" button to show up
          while (starTime2 + 30 * 1000 > Date.now() && !startButtonEle) {
            startButtonEle = document.querySelector('.slds-button.slds-button_brand')
            await delay(1000)
          }

          if (!startButtonEle) {
            LogReporter.warn('service-cloud', 'start button not found')
          } else {
            startButtonEle.addEventListener('click', () => {
              // This is not accurate because a user can click the button many times
              startButtonClickTime = Date.now()
            })
          }
        }
      } catch (e) {
        console.error('Failed to handle afterMaximize')
      }
    })

    // This event is triggered when the user is shown the "Queue" screen
    svc?.addEventHandler('onQueueUpdate', () => {
      try {
        if (startButtonClickTime) {
          // Log the time it took to start the chat
          const loadMs = Date.now() - startButtonClickTime
          LogReporter.info('service-cloud', {
            metric: 'start-chat-load-ms',
            loadMs,
          })

          // Clear this so we don't log it twice
          startButtonClickTime = null
        }
      } catch (e) {
        console.error('Failed to handle onQueueUpdate')
      }
    })
  }
}
