import { Reducer } from 'redux'
import {
  GetServerTimeAction,
  API_GET_SERVER_TIME,
} from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export const getTimeDelta = (state: LearnControllerState): number => {
  return state.ui.quizFlow.timeDelta
}

// This reducer stores the time difference between the local computer time and
// the time according to our servers. The delta is used to determine the end
// time of timed quizzes in local computer terms.
export const reducer = (state = null, action: GetServerTimeAction & ApiSuccessAction): number => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_SERVER_TIME.SUCCESS:
      return Date.now() - action.response.serverTime
    default:
      return state
  }
}

export default reducer as Reducer<number>
