import * as React from 'react'
import { Typography, Box, Link } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import type { Theme } from '@mui/material'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { useLocallyPersistedState } from 'app/frontend/hooks/use-locally-persisted-state'
import { getEulaStorageKey, isCurrentDateWithinRange } from './eula-data'
import { MaterialBanner } from 'app/frontend/layout/material/material-banners'

const t = tns('eula_update_banner')

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerRoot: {
      backgroundColor: theme.palette?.common.black,
      color: theme.palette?.common.white,
    },
    bannerContents: {
      margin: '1rem 0 1rem',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    text: {
      color: theme.palette?.common.white,
    },
    link: {
      color: theme.palette?.blue[200],
    },
  })
)

interface Props {
  description: string
  eulaUrl: string
  effectiveDate: number
}

export const EulaUpdateBanner: React.FunctionComponent<Props> = ({ description, eulaUrl }) => {
  const storageKey = getEulaStorageKey()
  const [persistedValue, setPersistedValue] = useLocallyPersistedState(false, storageKey)

  const styles = useStyles()

  // Do not show the banner again if the persisted value is set to true (the user clicked "Got it" btn)
  // (OR) if the current date is not within the range.
  if (persistedValue || !isCurrentDateWithinRange()) {
    return null
  }

  return (
    <MaterialBanner classes={{ root: styles.bannerRoot, contents: styles.bannerContents }}>
      <Box flexGrow={1}>
        <Typography variant="body2" className={styles.text}>
          {description}{' '}
          <Link
            href={eulaUrl}
            data-bi="eula-link"
            target="_blank"
            underline="always"
            className={styles.link}
          >
            {t('eula_link_text')}
          </Link>
        </Typography>
      </Box>
      <ButtonMaterial
        data-bi="eula-dismiss"
        label={t('eula_btn_text')}
        theme="bordered-light"
        onClick={() => setPersistedValue(true)}
      />
    </MaterialBanner>
  )
}
