import { isString, once } from 'lodash'

// Name of the key used to store the CSRF token in local storage
export const LOCAL_STORAGE_CSRF_KEY = 'csrfToken'
const syncErrorOnce = once(console.error)

/**
 * Parses the given CSRF token an extract the timestamp from it.
 * The timestamp if the time at which the token was created in milliseconds.
 * Returns -1 if the given token is invalid.
 */
export const getTokenTimestamp = (token: string): number => {
  if (token) {
    const parts = token.split('-')
    if (parts.length === 3 && parts[0] === '1') {
      return parseInt(parts[1], 10)
    }
  }
  return -1
}

/**
 * Saves the CSRF token in memory and localStorage.
 * If this token is older than the one in localStorage then it will be ignored
 * (syncCsrfToken will overwrite it).
 */
export const saveCsrfToken = (token: string): void => {
  if (token) {
    window.context.csrfToken = token
    syncCsrfToken()
  }
}

/**
 * Synchronizes the token in memory and localStorage. This makes sure that
 * the newest token is both in memory and localStorage. Other browser tabs
 * could have changed the token in localStorage.
 */
export const syncCsrfToken = (): void => {
  const memoryToken = window.context.csrfToken
  try {
    const storageToken = localStorage.getItem(LOCAL_STORAGE_CSRF_KEY)
    if (memoryToken !== storageToken) {
      if (getTokenTimestamp(storageToken) < getTokenTimestamp(memoryToken)) {
        localStorage.setItem(LOCAL_STORAGE_CSRF_KEY, memoryToken)
      } else {
        window.context.csrfToken = storageToken
      }
    }
  } catch (e) {
    // localStorage might be disabled (if 3rd party cookies are disabled) or full
    // even tho the documentation doesn't say it, any access to localStorage can result in an
    // exception.
    syncErrorOnce('Failed to sync CSRF token', e)
  }
}

/**
 * Gets latest CSRF token.
 * Will check both memory and localStorage.
 */
export const getCsrfToken = (): string => {
  // get token from memory
  syncCsrfToken()
  return window.context.csrfToken
}

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>

/**
 * This function wraps "fetch". The wrapper takes care of handling sending and
 * receiving CSRF tokens transparently.
 */
export const fetchWithCsrfToken = (fetch: Fetch): Fetch => {
  return (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    // Add token to request headers
    const token = getCsrfToken()
    if (token) {
      if (isString(input)) {
        // When fetch() is called with a url as first parameter
        init = init || {}
        init.headers = init.headers || {}
        init.headers['X-CSRF-TOKEN'] = token
      } else {
        // When fetch() is called with an object as first parameter
        input.headers.set('X-CSRF-TOKEN', token)
      }
    }

    return fetch(input, init).then(response => {
      const newToken = response.headers.get('X-CSRF-TOKEN')
      if (newToken) {
        // A new token was received.
        // Save it so we can send it later.
        saveCsrfToken(newToken)
      }
      return response
    })
  }
}
