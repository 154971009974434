import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  paper: {
    marginTop: '.5rem',
    background: 'white',
  },
  popper: {
    zIndex: 2000,
  },
  listbox: {
    maxHeight: '16rem',
  },
  option: {
    size: '1rem',
    color: theme.palette?.grey[600],
    paddingLeft: '1rem',
    paddingTop: '.75rem',
    height: '3rem',
    '&:hover': {
      background: theme.palette?.grey[100],
    },
  },
  input: {
    borderTopLeftRadius: '.25rem',
    borderTopRightRadius: '.25rem',
    minWidth: '8.125rem',
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette?.grey[400]} !important`,
    },
  },
  inputRoot: {
    height: '2.5rem',
    color: theme.palette?.grey[600],
    fontFamily: theme.typography?.fontFamily,
  },
  variantInputRoot: {
    height: 'unset',
  },
  label: {
    paddingTop: '.25rem',
    color: theme.palette?.grey[400],
    fontFamily: theme.typography?.fontFamily,
  },
  variantLabel: {
    paddingTop: 0,
  },
  error: {
    position: 'absolute',
    fontSize: '.75rem',
    color: theme.palette?.error.main,
    display: 'block',
    width: '15.75rem',
    paddingTop: '.2rem',
  },
  variantError: {
    paddingLeft: '.75rem',
  },
}))
