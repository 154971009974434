import { useQuery } from '@apollo/client'
import { isEmpty, keyBy } from 'lodash'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { SequenceOverridesMap } from 'app/frontend/helpers/assignment'
import * as GET_SEQUENCE_OVERRIDES from './get-sequence-overrides.gql'

export type UseSequenceOverridesResults = RenderProps<{
  sequenceOverrides?: GQL.GetSequenceOverrides.GetSequenceOverrides[]
  sequenceOverridesMap?: SequenceOverridesMap
}>

export const useSequenceOverrides = (
  assessmentId: string,
  skip = false
): UseSequenceOverridesResults => {
  const { loading, error, data } = useQuery<
    GQL.GetSequenceOverrides.Query,
    GQL.GetSequenceOverrides.Variables
  >(GET_SEQUENCE_OVERRIDES, {
    variables: {
      assessmentId,
    },
    skip,
  })

  return {
    loading,
    error,
    sequenceOverrides: isEmpty(data) ? null : data.getSequenceOverrides,
    sequenceOverridesMap: isEmpty(data) ? null : keyBy(data.getSequenceOverrides, 'sequenceId'),
  }
}
