import { useQuery, QueryHookOptions } from '@apollo/client'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import * as GET_ATOM_VARIATION from 'app/frontend/compositions/connected/atom-variation.gql'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('content_atom')

export type UseAtomVariationResults = RenderProps<{
  atom: GQL.Atom
}>

export const useAtomVariation = (
  sequenceId: string,
  atomId: string,
  variationId: string,
  opts?: QueryHookOptions<GQL.GetAtomVariation.Query, GQL.GetAtomVariation.Variables>
): UseAtomVariationResults => {
  const { loading, error, data } = useQuery<
    GQL.GetAtomVariation.Query,
    GQL.GetAtomVariation.Variables
  >(GET_ATOM_VARIATION, {
    ...opts,
    variables: { sequenceId, atomId, variationId },
    context: {
      errorMessage: t('generic_error_loading'),
    },
  })

  return {
    loading,
    error,
    atom: data?.atom ?? null,
  }
}
