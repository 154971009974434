import * as React from 'react'
import { uuid } from 'app/helpers/common/uuid4'
import type { ContentWarning, ContentError } from './context'
import { StatusDispatchContext } from './context'

export interface ContentManagerActionsType {
  addWarning: (value: ContentWarning) => void
  addError: (value: ContentError) => void
  success: () => void
}

/**
 * Registers the calling component as loading and provides it with callbacks for messaging
 * success, errors, and warnings.
 */
export const useContentManagerActions = (
  initialDetails: Content.ContentDetails
): ContentManagerActionsType => {
  const dispatch = React.useContext(StatusDispatchContext)
  const { current: id } = React.useRef(uuid())

  React.useLayoutEffect(() => {
    dispatch({ type: 'INITIALIZE', id, details: initialDetails })
  }, [])

  const { current: manager } = React.useRef({
    addWarning: (value: ContentWarning) => {
      dispatch({ type: 'ADD_WARNING', id, value })
    },
    addError: (value: ContentError) => {
      dispatch({ type: 'ADD_ERROR', id, value })
    },
    success: () => {
      dispatch({ type: 'SUCCESS', id })
    },
  })
  return manager
}

export interface Props {
  initialDetails: Content.ContentDetails
  children: (contentManagerActions: ContentManagerActionsType) => React.ReactNode
}

/**
 * Registers the child component as loading and provides it with callbacks for messaging
 * success, errors, and warnings.
 */
export const ContentManagerActions: React.FunctionComponent<Props> = ({
  initialDetails,
  children,
}) => {
  const contentManager = useContentManagerActions(initialDetails)
  return <>{children(contentManager)}</>
}
