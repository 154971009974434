import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { EditableQuestionPoints } from 'app/frontend/compositions/ux/question-points'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { AssessmentSequenceWithOverride } from 'helpers/assignment'
import * as styles from './question-heading.css'

const t = tns('atom_variation')
interface Props {
  assessmentId: string
  sequenceId: string
  points: number
  weight: number
  readOnly: boolean
  assessmentSequences: AssessmentSequenceWithOverride[]
  isPoolQuestion: boolean
  questionIndex?: number | null
  parentEntityType?: Commons.ParentEntityType
  questionVariationNumber?: number
}

export const QuestionHeadingWithEditablePoints: React.FunctionComponent<Props> = ({
  assessmentId,
  points,
  weight,
  readOnly,
  assessmentSequences,
  sequenceId,
  isPoolQuestion,
  questionIndex,
  parentEntityType,
  questionVariationNumber,
}) => {
  return (
    <Box direction="row">
      <Box className={styles.questionHeading}>{`${t('question')} ${questionIndex || ''}`}</Box>
      {isPoolQuestion && (
        <>
          <span className={styles.headingSeparator}>·</span>
          <Box data-test="pool-question-header" className={styles.questionHeading}>
            {t('pool_question_number', { number: questionVariationNumber })}
          </Box>
        </>
      )}

      <EditableQuestionPoints
        assessmentId={assessmentId}
        points={points}
        weight={weight}
        readOnly={readOnly}
        sequenceId={sequenceId}
        assessmentSequences={assessmentSequences}
        isPoolQuestion={isPoolQuestion}
        parentEntityType={parentEntityType}
      />
    </Box>
  )
}

QuestionHeadingWithEditablePoints.displayName = 'QuestionHeadingWithEditablePoints'
