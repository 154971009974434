import { Reducer } from 'redux'
import {
  UPDATE_UNANSWERED_COMPOUNDS,
  UpdateUnansweredCompoundsAction,
} from './unanswered-compounds-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export type UnansweredCompoundsState = {
  [compoundId: string]: true
}

export const getUnansweredCompounds = (state: LearnControllerState): UnansweredCompoundsState => {
  return state.ui.quizFlow.unansweredCompounds
}

export const reducer = (
  state: UnansweredCompoundsState = {},
  action: UpdateUnansweredCompoundsAction
): UnansweredCompoundsState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case UPDATE_UNANSWERED_COMPOUNDS:
      return action.unansweredCompounds
    default:
      return state
  }
}

export default reducer as Reducer<UnansweredCompoundsState>
