import { useQuery, ApolloError } from '@apollo/client'
import * as GET_LO_CONCEPT_BY_IDS from './get-lo-concept-by-ids.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseLoConceptByIdsResults = RenderProps<{
  loading?: boolean
  error?: ApolloError
  loConcepts: (GQL.LoConcept | null)[]
}>

export const useLoConceptByIds = (loConceptIds: string[]): UseLoConceptByIdsResults => {
  const { loading, error, data } = useQuery<
    GQL.GetLoConceptByIds.Query,
    GQL.GetLoConceptByIds.Variables
  >(GET_LO_CONCEPT_BY_IDS, {
    variables: {
      loConceptIds,
    },
  })

  return {
    loading,
    error,
    loConcepts: data ? data.loConceptByIds : null,
  }
}
