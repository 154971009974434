/*
This is for types and enums that are needed at runtime instead of just at compile time.
*/

export enum FeedbackType {
  NotRight = 'not_right',
  LooksBroken = 'looks_broken',
  CannotAnswer = 'cannot_answer',
  HaveIdea = 'have_idea',
  Other = 'other',
}

export interface FeedbackData {
  description: string
  sequenceId: string
  action: FeedbackType
  answer?: string
  goalId?: string
  enrollmentId?: string
  coursepackId?: string
  courseName?: string
  assignmentName?: string
  assignmentId?: string
  domain: string
  persona: Commons.RoleForUiType
  userId: string
  institutionNames: string
  timestamp: number
  datetime: string
  email: string
  isQuiz: boolean
  compoundInstanceId?: string
  isCustomItem: boolean
  atomId: string
  variationId: string
  image?: string
  conceptIds: string[]
  learningObjectiveId: string
}
