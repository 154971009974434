import * as React from 'react'
import * as _ from 'lodash'
import * as styles from './atom-variation-loading.css'
import { PlaceholderText } from 'app/frontend/components/material/loading'

export const AtomVariationWithHeaderLoading: React.FunctionComponent<{}> = () => {
  return (
    <>
      <PlaceholderText height="1.2rem" width="15rem" className={styles.headerText} />
      <AtomVariationLoading />
    </>
  )
}

export const AtomVariationLoading: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.atomVariationLoading}>
      <PlaceholderText height="0.5rem" width="58rem" className={styles.questionText} />
      <PlaceholderText height="0.5rem" width="55rem" className={styles.questionText} />
      <PlaceholderText height="0.5rem" width="52rem" className={styles.questionText} />
      <PlaceholderText height="5rem" width="58rem" className={styles.answerText} />
    </div>
  )
}
