import * as React from 'react'
import * as classnames from 'classnames'
import { Link } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import type { Theme, LinkProps } from '@mui/material'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

export interface StyleProps {
  color?: (theme: Theme) => React.CSSProperties['color']
  underlineColor?: (theme: Theme) => React.CSSProperties['backgroundColor']
  fontSize?: (theme: Theme) => React.CSSProperties['fontSize']
  fontWeight?: (theme: Theme) => React.CSSProperties['fontWeight']
  textTransform?: React.CSSProperties['textTransform']
  lineHeight?: React.CSSProperties['lineHeight']
  underlineWidth?: (theme: Theme) => React.CSSProperties['borderWidth']
}

export const styles = (theme: Theme) =>
  createStyles<'root', StyleProps>({
    root: {
      textDecoration: 'none',
      textTransform: props => props.textTransform,
      position: 'relative',
      display: 'inline-block',
      color: props => props.color?.(theme) ?? theme.palette?.primary.main,
      cursor: 'pointer',
      fontSize: props => props.fontSize?.(theme),
      lineHeight: props => props.lineHeight,
      fontWeight: props => props.fontWeight?.(theme),
      '&::after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: props => props.underlineWidth?.(theme) ?? '0.125rem',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        /* override this to match your color */
        background: props =>
          props.underlineColor?.(theme) ?? props.color?.(theme) ?? theme.palette?.primary.main,
      },
      '&:focus': {
        outlineOffset: '0.25rem',
      },
    },
  })

export const useStyles = makeStyles(styles)

export interface Props
  extends Commons.Omit<LinkProps, 'classes' | 'variant' | 'underline' | 'color' | 'styles'> {
  styleProps?: StyleProps
  router?: Pick<RouterLinkProps, 'to' | 'replace'>
}

export const UnderliningLink: React.FunctionComponent<Props> = ({
  styleProps,
  className,
  children,
  router,
  ...props
}) => {
  const s = useStyles(styleProps)
  const component = router ? RouterLink : undefined

  return (
    <Link
      className={classnames(s.root, className)}
      underline={'none'}
      {...props}
      component={component}
      {...router}
    >
      {children}
    </Link>
  )
}

export default UnderliningLink
