module.exports    = {
	"common": {
		"about_assessment": "About this assessment",
		"account": "account",
		"accessibility": "Accessibility",
		"commitment_to_accessibility": "Our Commitment to Accessibility",
		"activity": "Activity",
		"all": "All",
		"at": "at",
		"attempt": "Attempt",
		"answer_explanation": "Answer Explanation",
		"at_due_date": "at due date",
		"average_questions": "Average number of questions",
		"back_learn_flow": "Back to Assignment Overview",
		"back_review_learn_flow": "Back to Overview",
		"back_to_overview": "Back to Overview",
		"assignment_start": "Date available",
		"back_to_lms": "Back to My School",
		"back_to_top": "Back to Top",
		"booster": "Booster",
		"boosters": "Boosters",
		"cancel": "cancel",
		"click_to_email": "Click to email {{email_address}}",
		"Completed": "Completed on",
		"Completed_comma": "Completed, ",
		"continue": "Continue",
		"correct": "Correct",
		"correct_answer": "Great job! You answered correctly.",
		"course": "course",
		"Course": "Course",
		"course_plural": "courses",
		"copy_course_success": "Course {{course_name}} successfully created.",
		"copy_course_failure": "Failed to copy course. The provided link may have expired.",
		"copy_to_clipboard": "Copy to Clipboard",
		"error_copy_to_clipboard": "Error copying link to clipboard.",
		"knewton_alta_home_page": "Knewton Alta home page",
		"success_copy_to_clipboard": "Link copied to clipboard.",
		"copied": "Copied",
		"copyright": "Copyright © 2021 John Wiley & Sons, Inc.\n",
		"credit": "credit",
		"disabled": "Disabled",
		"download_csv": "Download CSV",
		"download_ldb_link": "https://support.knewton.com/s/article/Knewton-LockDown-Browser-powered-by-Respondus-Installation-Guide",
		"Due": "Due",
		"dueTooltip": "Due on {{date}} at {{time}} ({{zone}})",
		"due_date_credit_tooltip": "Your grade at due date won't be lost or decreased if you keep working.",
		"end_of_section": "the end of your section",
		"feedback_submitted": "Feedback submitted",
		"for_late_completion": "for late completion",
		"generic_error": "There was an error processing your request. Please try again.",
		"grade_colon_pct": "Grade: {{grade}}%",
		"pct_credit": "{{grade}}% credit",
		"no_credit": "No credit",
		"invalid_learnosity": "Question is not valid. Did you forget to set the correct answer?",
		"unanswered_learnosity": "Question is not valid. Did you forget to answer the question?",
		"allow_popup_error": "Please allow popups for this site.",
		"hello": "hello world",
		"help": "help",
		"incomplete_at_due_date": "Incomplete at due date",
		"incorrect_answer": "Bummer! Unfortunately, you answered incorrectly.",
		"incorrect": "Incorrect",
		"instructor": "Instructor",
		"instructor_notes": "instructor's notes",
		"join_course": "join $t(course)",
		"keep_going": "Keep Going",
		"knerd_tools": "Knerd Tools",
		"knewton_logo": "Knewton logo",
		"late": "Late",
		"late_completion_allowed": "Credit for late completion available",
		"late_completion_available_credit": "Complete by {{dueTimeAndDay}} to earn {{additionalCredit}}% additional credit.",
		"late_completion_no_available_credit": "This assignment is past its final due date. Further work won't improve the grade.",
		"late_completion_assessment_penalty": "Assessments completed by {{dueTimeAndDay}} will receive a {{latePenalty}}% late penalty.",
		"late_completion_assessment_full_penalty": "This assessment is late. If completed today, it will receive a 100% penalty.",
		"late_credit_tooltip_begin": "Earn more credit by completing this assignment! You can submit up to",
		"late_grading_no_credit_tooltip": "This assignment is past its final due date. Further work won't improve the grade.",
		"late_grading_tooltip": "This assignment was completed {{count}} day late with a {{latePenalty}}% penalty.",
		"late_grading_tooltip_plural": "This assignment was completed {{count}} days late with a {{latePenalty}}% penalty.",
		"late_penalty_included": "(inc. {{latePenalty}}% late penalty)",
		"ldb_warning": "It looks like you are already in a Lockdown Browser, so launching this assessment will not work. Please close the Lockdown Browser, open your course in a regular browser, and the Lockdown Browser will launch as you start this assessment.",
		"ldb_warning_more_info": "More information about using the Lockdown Browser.",
		"learn_cover_welcome": "Welcome Knerd! Join your first course",
		"learn_cover_welcome_detail": "Start where you are, we'll take you where you want to go.",
		"lms_integrated_cta": "Sorry, this assignment cannot be started from www.knewton.com. Please initiate all assignments, quizzes or tests for this course from your school's learning management system (LMS).",
		"marked_as_done": "Marked as done",
		"minimum_questions": "Minimum number of questions",
		"multiple_days_late": "{{days}} days late",
		"my_institution": "My Institution",
		"next_assignment": "Next Assignment",
		"error_assignments": "There was an error loading your assignments, please try again.",
		"no_course_assignments": "It's your lucky day!",
		"no_course_assignments_detail": "No coursework has been scheduled yet. Check back again later for updates from your instructor.",
		"no_course_description": "No Notes Yet",
		"no_course_description_detail": "Your instructor hasn't posted anything yet. Check back again later.",
		"no_grade": "No grade",
		"no_late_credit_tooltip": "This assignment was completed {{count}} day late. The result is lower than your previous grade, so your grade is unchanged.",
		"no_late_credit_tooltip_plural": "This assignment was completed {{count}} days late. The result is lower than your previous grade, so your grade is unchanged.",
		"no_partial_credit_tooltip": "Credit can only be earned upon completion.",
		"school": "school",
		"term": "Term",
		"today": "today",
		"tomorrow": "tomorrow",
		"course_does_not_exist": "This course does not exist, or you do not have permission to view it.",
		"quiz_does_not_exist": "This quiz does not exist!",
		"not_found_back": "Back to Home",
		"not_found_back_course": "Back to Course",
		"not_found_description": "We can't find that page.",
		"not_found_header": "Ooops!",
		"one_day_late": "one day late",
		"open": "Open",
		"opens_a_dialog": "- Opens a dialog",
		"Overdue": "Overdue",
		"Overdue_comma": "Overdue, ",
		"overview": "Overview",
		"partial_credit": "Partial Credit",
		"no_response": "No Response",
		"comments": "Comments",
		"password_quiz_screen_reader": "the password dialog for this secure test",
		"password_requirements": "Your password must contain 8 or more characters and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.",
		"payments": "billing",
		"payments_purchase_cta": "Purchase",
		"payments_purchase_screen_reader": "the purchase dialog",
		"payments_course_default_cta": "Welcome {{first_name}}! Please complete a purchase or enter an access code to access your Knewton course.",
		"payments_courtesy_cta": "Your Courtesy Access ends in {{num_days}} days. Please complete your purchase when possible to ensure continued access to your course.",
		"payments_courtesy_tomorrow_cta": "Your Courtesy Access ends tomorrow. Please complete your purchase when possible to ensure continued access to your course.",
		"payments_courtesy_today_cta": "Your Courtesy Access ends today. Please complete your purchase when possible to ensure continued access to your course.",
		"payments_courtesy_expired_cta": "Your Courtesy Access has expired. Please complete your purchase or enter an access code to continue access to your Knewton course.",
		"payments_courtesy_opt_out": "If you have opted out of inclusive access, you will need to subscribe when courtesy access expires.",
		"practice": "Practice",
		"privacy": "Privacy",
		"privacy_policy": "Privacy Policy",
		"question": "Question",
		"questions_to_show": "Questions to show:",
		"related_instruction": "Related Instruction",
		"results": "Results",
		"results_error": "There was an error loading your results, please try again.",
		"schedule": "schedule",
		"start_date_descriptor": "Begins",
		"end_date_descriptor": "Ends",
		"selectable": "selectable - press enter to navigate to",
		"related_instruction_screen_reader": "related instruction content for: {{objective}}",
		"activity_content_screen_reader": "activity content for: {{objective}}",
		"sign_out": "sign out",
		"sign_out_as": "sign out as",
		"strict_dates_course_cover": "Strict due dates for all coursework",
		"strict_dates_assignment_cover": "Strict due date",
		"strict_dates_assignment_tooltip": "Assignment grade will only be awarded for mastery earned before the due date",
		"strict_dates_quiz_tooltip": "Activity grade will only be awarded for work submitted before the due date",
		"show_password": "Show password",
		"show_current_password": "Show current password",
		"show_confirm_password": "Show confirm password",
		"show_new_password": "Show new password",
		"unanswered": "Unanswered",
		"start": "Start",
		"terms": "Terms",
		"terms_of_service": "Terms of Service",
		"unlock": "Unlock",
		"view_related_instruction": "Related instruction for this learning objective",
		"view_activity_content": "Your activity for this learning objective",
		"objectives": "Objectives",
		"whitelist": "Whitelist",
		"with_daily_penalty": "with a {{penalty}}% daily penalty.",
		"with_flat_penalty": "with a {{penalty}}% flat penalty.",
		"with_no_penalty": "with no penalty.",
		"work_remaining": "How much work it'll be",
		"dont_have_an_account": "First time here?",
		"create_an_account": "Create an account",
		"register": "Register",
		"remaining_questions": "Estimated number of questions remaining",
		"forgot_password": "Forgot password?",
		"password": "Password",
		"email": "Email address",
		"sign_in": "Sign in",
		"caps_sign_in": "SIGN IN",
		"sign_in_with_google": "SIGN IN WITH GOOGLE",
		"connected_account": "We've successfully connected with your Google account. Please verify your info below to finish creating your account.",
		"confirm_not_robot": "Please confirm that you are not a robot and submit again!",
		"invalid_password": "The email and/or password you entered is incorrect. Please try again or use another sign in option.",
		"no_matching_google_account": "Sorry, we couldn't find anyone matching that Google account. If you already have an account with us, sign in using the form provided. If you'd like to create an account, click below to sign up!",
		"google_error": "There was an issue connecting with your Google account. Please try again or sign in using the form provided.",
		"remember_me": "Remember me",
		"plan_cost_screen_reader_dollar": "{{dollars}} dollar",
		"plan_cost_screen_reader_dollar_plural": "{{dollars}} dollars",
		"plan_cost_screen_reader_cent": "{{cents}} cent",
		"plan_cost_screen_reader_cent_plural": "{{cents}} cents",
		"and": "and",
		"instruct_first_message": "This assignment will start with instructional material on the assigned objectives.",
		"back_to_course": "Back to Course",
		"back_to_review_assignments": "Back to Review List",
		"review_assignment_prefix": "Review",
		"human_calendar_same_else": "MMMM Do YYYY",
		"disabled_review_assignment": "This topic has been removed from the test.",
		"has_student_started_text": "Only name and dates can be changed because students have already started working.",
		"start_date": "Start date",
		"end_date": "End date",
		"start_review": "Start Review",
		"show_more": "show more",
		"show_less": "show less",
		"due_date": "Due Date",
		"date": "Date",
		"time": "Time",
		"time_limit": "Time Limit",
		"no_time_limit": "No Time Limit",
		"submit": "submit",
		"caps_ok": "OK",
		"caps_cancel": "CANCEL",
		"select": "Select",
		"on": "On",
		"off": "Off",
		"error_copy": "Error copying to clipboard.",
		"success_cop": "Copied to clipboard.",
		"generic_error_refresh_page": "Ooops, something went wrong, please refresh your page.",
		"attempt_dropdown_grade_label": "Attempt {{attemptNumber}}: {{grade}}%{{latePenaltyText}}, {{completedDate}} at {{completedTime}}",
		"attempt_dropdown_grade_label_weighted_questions": "Attempt {{attemptNumber}}: {{grade}}% ({{score}}/{{maxPoints}} points){{latePenaltyText}}, {{completedDate}} at {{completedTime}}",
		"default_search_promp": "Type to search",
		"required": "Required",
		"got_it": "Got it",
		"beta": "beta",
		"class_readiness_tooltip": "Readiness locks on the assessment’s due date so Grades and Readiness can be compared accurately.",
		"attempts": "Attempts"
	},
	"registration_page": {
		"already_have": "Already have an account?",
		"marketing_text": "Knewton personalizes instruction for every student to improve learning outcomes.",
		"create_your_account": "Create your account",
		"continue_with_google": "CONTINUE WITH GOOGLE",
		"or": "OR",
		"firstname": "First Name",
		"lastname": "Last Name",
		"work_email": "Work email",
		"email": "School Email",
		"password": "Password",
		"password_confirm": "Confirm Password",
		"month": "Month",
		"year": "Year",
		"day": "Day",
		"student": "Student",
		"instructor": "Instructor",
		"terms_of_service": " Terms of Service ",
		"privacy_policy": " Privacy Policy",
		"legal_text": "By clicking Continue with Google or Create Account, you are agreeing to Knewton's ",
		"and": " and ",
		"unique_email": "An account with this email address already exists.",
		"primary_role": "Primary Role",
		"date_of_birth": "Date of Birth",
		"page_title": "Register - Knewton",
		"verify_your_info": "Verify your Info"
	},
	"distributor_signin": {
		"distributor_register": "New Distributor Account",
		"distributor_sign_in": "Distributor Sign In",
		"legal_text": "By clicking Create Account, you are agreeing to Knewton's ",
		"terms_of_service_order_form": "Order Form Terms and Conditions, ",
		"page_title": "Register - Knewton"
	},
	"distributor_landing": {
		"page_title": "Distributor Portal - Knewton",
		"page_header": "Distributor Portal",
		"new_orders": "New Orders",
		"new_orders_detail": "Request access codes for Alta courseware to sell in your bookstore.",
		"start_new_order": "Start new order",
		"existing_orders": "Existing Orders",
		"existing_orders_detail_1": "Need help with an existing order?",
		"existing_orders_detail_2": "Contact us at knewtonsupport@wiley.com.",
		"contact": "Contact us"
	},
	"new_distributor_order": {
		"title": "New order",
		"description": "Fill out the details below to place an order for access codes.",
		"submit": "Place Order",
		"submitting": "Processing",
		"purchase_order": "Purchase order number",
		"term_start": "Academic Term Start Date",
		"month": "Month",
		"day": "Day",
		"year": "Year",
		"num_codes": "Number of codes",
		"institution": "Institution *",
		"choose_institution": "Choose institution",
		"product_offering": "Product Offering/ISBN *",
		"choose_product_offering": "Choose product offering",
		"enter_isbn": "Enter ISBN",
		"type_isbn": "Type ISBN to search",
		"institution_not_found_help": "If you don't see the institution you're looking for, contact knewtonsupport@wiley.com",
		"isbn_not_found": "No product offering found. Please verify that the ISBN you have entered is correct.",
		"purchase_order_error": "Please enter a purchase order number.",
		"sales_account_error": "Please select an institution from the search results dropdown.",
		"product_offering_error": "Please select a product offering/ISBN from the search results dropdown.",
		"product_offering_error_wrong_isbn": "We're sorry, this new Fall 2019 access code will not be available in the order portal until July 15, 2019.",
		"product_offering_error_use_standard_plans": "This product uses standard pricing. Please order Single-Term or AltaPass codes instead.",
		"payments_2019_warn": "On <b>July 15, 2019</b>, our ISBNs and pricing options will change. Please see our detailed <a href='https://drive.google.com/file/d/1dIDJbmrsrIxPDu5cwiftJMPGWG5kRzeV/view' target='_blank'>Distributor FAQ</a>.",
		"quantity_error": "Please enter a quantity between 1 and 999.",
		"success": "Successfully submitted a new order.",
		"submission_error": "Sorry, there was a problem processing your order! Please contact knewtonsupport@wiley.com for support.",
		"custom_coursepack_reminder": "Reminder: you can only use a custom ISBN for the institution it was created for."
	},
	"update_lti_registration": {
		"update_header": "Update Registration {{lti_registration_id}}",
		"required_fields": "Required Fields from LMS",
		"optional_fields": "Optional Fields from LMS",
		"lms_required_fields": "Data to give to LMS",
		"iss_label": "Iss / Issuer",
		"client_id_label": "Client ID",
		"platform_oidc_url_label": "Platform OIDC Authentication URL",
		"jwks_url_label": "JWKS URL",
		"jwks_alg_label": "JWKS Algorithm",
		"access_token_label": "Access Token URL",
		"lms_type_label": "LMS Type",
		"access_token_aud_label": "Access Token Audience",
		"name_label": "Name Given",
		"description_label": "Description Given",
		"login_init_url_label": "Alta Login Initiation URL",
		"tool_redir_label": "Alta Redirection URL",
		"tool_jwks_url": "Alta JWKS URL",
		"tool_domain": "Alta Domain",
		"tool_target_link": "Target Link URI",
		"submit_lti_update": "Update LTI Registration",
		"save_error": "Failed to update Lti Registration",
		"save_success": "Lti Registration successfully updated",
		"required_field_missing": "This field is required"
	},
	"create_lti_registration": {
		"lti_cover_header": "LTI 1.3 Registrations",
		"create_lti_button": "Create LTI Registration",
		"create_lti_header": "Create LTI 1.3 Registrations?",
		"create_lti_message": "This will create a new blank LTI 1.3 Registration. The form will show data that you could give to the LMS and fields that Alta needs to integrate with the LMS.",
		"search_lti": "Search LTI Registration",
		"search_result": "Results",
		"edit_lti_result": "Edit",
		"cancel": "Cancel",
		"continue": "Continue",
		"create_failure_message": "Failed to create"
	},
	"create_lti_deployment": {
		"lti_cover_header": "LTI 1.3 Deployments",
		"create_lti_button": "Create LTI Deployment",
		"search_lti": "Search LTI Deployments",
		"search_result": "Results",
		"save_success": "LTI Deployment successfully saved",
		"save_error": "Failed to save LTI Deployment",
		"create_lti_deployment_header": "Create Deployment",
		"create_lti_deployment_message": "Create a Deployment of Alta in an LMS section with Instructor/LMS admin provided deployment ID.",
		"form_deployment_id_label": "Deployment ID",
		"form_depoyment_description": "Deployment ID as provided by the LMS",
		"form_institution_label": "Select Institution for Deployment (Required)",
		"form_institution_note": "Type to search an Institution",
		"form_registration_label": "Select Registration for Deployment (Required)",
		"form_registration_note": "Type to search a LTI Registration",
		"edit_lti_deployment_result": "Edit"
	},
	"update_lti_deployment": {
		"edit_lti_deployment_title": "Update LTI Deployment",
		"save_success": "LTI Deployment successfully saved",
		"save_error": "Failed to save LTI Deployment",
		"form_deployment_id_label": "Deployment ID",
		"form_depoyment_description": "Deployment ID as provided by the LMS",
		"submit_lti_deployment_update": "Save"
	},
	"pick_plan": {
		"heading": "Pick a plan",
		"one_term_pass_heading": "Single-Term Access",
		"one_term_pass_body": "This pass allows students to access one course for one term.",
		"altapass_heading": "AltaPass",
		"altapass_body": "This pass allows students to join all courses in a specific discipline (Math+Stats, Econ or Chem) for two years.",
		"custom_body": "Pick this option if you have been given a custom ISBN you would like to order.",
		"custom_heading": "Custom ISBN",
		"select": "Select",
		"learn_more": "Learn more about our",
		"pricing_plans": "Pricing Plans"
	},
	"JOIN_COURSE_MODAL": {
		"header": "Join a $t(Course)",
		"text": "Enter the knerd link provided by your instructor.",
		"input_label": "Knerd link *",
		"input_caption": "Enter Knerd Link. Example: http://knerd.me/abc123",
		"error": "Link should be of the form http://knerd.me/abc123"
	},
	"PAYMENTS_MODAL": {
		"package_picker_button": "Select plan",
		"package_picker_code_button": "Enter code",
		"package_picker_header": "Buy Knewton Alta for your course",
		"package_picker_description": "To continue your access to {{- section_name}}, start by selecting one of the following:",
		"package_picker_single_caption": "One-Time Purchase",
		"package_picker_monthly_caption": "Monthly plan",
		"package_picker_monthly_feat_0": "Pay as you go",
		"package_picker_monthly_feat_1": "Cancel anytime",
		"package_picker_code_caption": "Redeem",
		"package_picker_code_subcaption": "Access Code",
		"package_picker_code_feat_0": "Enter access code from your campus store.",
		"package_picker_button_label": "Select Plan: {{plan}}",
		"redeem_access_code": "Redeem Access Code",
		"single_term_access": "Single-Term Access",
		"feature_one_term_access": "Access a single Alta course for a single term (e.g. semester, quarter)",
		"enter_access_code": "Enter the access code purchased from your campus store",
		"no_courtesy_access": "Not eligible for Courtesy Access",
		"package_picker_courtesy_access": "14-day Courtesy Access",
		"package_picker_courtesy_access_cta": "Get Courtesy Access",
		"package_picker_courtesy_access_label": "Get Courtesy Access: {{plan}}",
		"package_picker_alta_pass": "Access any Alta {{discipline}} course for two years",
		"access_code_header": "Enter your access code",
		"access_code_input_label": "Access code",
		"access_code_input_caption": "Example: xxxx-xxxx-xxxx",
		"access_code_error_used": "You are trying an access code that has already been used.",
		"access_code_error_not_applicable": "Sorry, this code does not apply to this course.",
		"access_code_error_invalid_address": "Your billing address is invalid. Please fix it and try again.",
		"access_code_error_invalid": "The access code you have entered is not valid.",
		"access_code_error_generic": "Something went wrong while verifying your access code. Please try again.",
		"already_has_active_subscription": "Oops! You already have a subscription for this course. Please refresh the page.",
		"invalid_billing_address": "Oops! Your billing address is invalid.",
		"something_went_wrong": "Something went wrong. Please try reloading the page.",
		"courtesy_access_header": "{{courtesy_access_period}}-Day Courtesy Access Option",
		"courtesy_access_description": "<p>Knewton’s {{courtesy_access_period}}-Day Courtesy Access option is available for students who are waiting on financial aid distribution or other economic support, but need immediate access in the meantime.</p>\n<p>This option will grant you full access to your Knewton course for {{courtesy_access_period}} days, after which you will need to purchase access via a one-time payment online or an access code from your campus store.</p>\n",
		"courtesy_access_warning_header": "The monthly payment option will no longer be available to you.",
		"courtesy_access_warning_description": "Once your {{courtesy_access_period}}-day courtesy access period ends, you will need to choose one of the following two options to retain access:",
		"courtesy_access_warning_description_with_monthly": "If you start Courtesy Access, you will no longer be able to choose the ${{price}} per month payment plan. You will need to choose one of the following options to retain access:",
		"courtesy_access_bullet_altapass": "Purchasing a ${{price}} AltaPass plan to access unlimited courses within a domain",
		"courtesy_access_bullet_one_time": "Purchasing a ${{price}} one-time payment plan",
		"courtesy_access_bullet_access_code": "Entering an access code purchased from your campus store",
		"courtesy_access_warning_acceptance": "I read and understand the above notice.",
		"courtesy_access_start": "Start now",
		"payment_complete_header": "Thank you!",
		"payment_complete_description": "You now have access to {{section_name}}.",
		"payment_complete_course": "View Course",
		"learn_more_about_plans": "Learn more about our",
		"pricing_plans": "Pricing Plans",
		"read_our": "Read our",
		"terms_of_service": "Terms of service",
		"error_getting_plans": "Failed to load plans. Please refresh this page.",
		"error_missing_plans": "Oops. We're sorry, this course is not currently available for purchase. If you believe this is an error, please",
		"contact_us": "contact us",
		"fix": "Fix",
		"and": "and",
		"privacy_policy": "Privacy Policy",
		"alta_pass": "Altapass"
	},
	"PROGRESS_MODAL": {
		"booster": "Booster",
		"completed": "Completed",
		"estimated_remaining": "Estimated remaining",
		"instructional_material": "{{count}} instructional material",
		"instructional_material_plural": "{{count}} instructional materials",
		"learning_objectives": "Learning Objectives",
		"mastery_breakdown": "Your Mastery Breakdown",
		"percent_mastered": "{{percent}}% mastered",
		"work": "Work",
		"questions_remaining": "{{count}} question remaining",
		"questions_remaining_plural": "{{count}} questions remaining",
		"questions_remaining_tbd": "To be determined",
		"questions_remaining_max": "30+ questions",
		"question": "{{count}} question",
		"question_plural": "{{count}} questions",
		"status": "status"
	},
	"assignment_progress": {
		"current_lo": "CURRENT OBJECTIVE ",
		"percent_mastered": "{{percent}}% mastered",
		"prereq_lo": "REFRESHER ",
		"tooltip": "Refresher materials are served after multiple incorrect answers on assigned learning objectives to cover knowledge gaps.",
		"lo_analytics_aria": "{{percent}}% mastered for learning objective {{name}}",
		"lo_analytics_current_aria": "{{percent}}% mastered for learning objective {{name}}. You are currently working on this learning objective.",
		"prereq_lo_analytics_aria": "Currently working on the refresher {{name}}",
		"header_analytics_aria": "Mastery per learning objective for the current assignment",
		"whats_this": "(What's this?)",
		"objective": "OBJECTIVE:",
		"prereq": "REFRESHER:"
	},
	"lo_analytics_modal": {
		"current_lo": "Current objective ",
		"percent_mastered": "{{percent}}% mastered",
		"prereq_lo": "Refresher",
		"tooltip": "Refresher materials are served after multiple incorrect answers on assigned learning objectives to cover knowledge gaps.",
		"prereq_lo_tooltip": "Refreshers help you fill knowledge gaps.",
		"lo_analytics_aria": "{{percent}}% mastered for learning objective {{name}}",
		"lo_analytics_current_aria": "{{percent}}% mastered for learning objective {{name}}. You are currently working on this learning objective.",
		"prereq_lo_analytics_aria": "Currently working on the refresher {{name}}",
		"header_analytics_aria": "Mastery per learning objective for the current assignment",
		"whats_this": "(What's this?)",
		"objective": "OBJECTIVE:",
		"prereq": "REFRESHER",
		"paused": "(PAUSED)"
	},
	"assignment_cover": {
		"unavailable_content": "No content is available."
	},
	"assignment_cover_header": {
		"completed": "Completed",
		"completed_late": "Completed late",
		"minimum": "minimum",
		"mastery": "mastery",
		"due_date": "Due Date",
		"not_started": "Not started",
		"on_avg": "on average",
		"status": "Status",
		"estimated_questions": "{{lowValue}} - {{highValue}} est. questions",
		"questions_remaining": "questions remaining",
		"work_estimate": "Work Estimate",
		"previous_mastery": "Previous Mastery",
		"previous_mastery_counts": "{{masteredCount}} / {{learningObjectiveCount }} learning objectives",
		"grade": "Grade",
		"review_instruction_btn": "Review Instruction",
		"review_instruction_aria": "Jump to instructional links for this assignment's learning objectives",
		"toggle_credit_aria": "toggle credit breakdown visibility",
		"last_grade_updated_time": "Last updated {{gradeUpdatedTime}}"
	},
	"assessment_cover_details": {
		"best_score": "Grade (Best Score)",
		"grade": "Grade",
		"attempts": "Attempts",
		"num_completed": "{{completed}} of {{max}} completed",
		"available": "Available {{date}} at {{time}} {{zone}}",
		"release_pending": "Pending instructor\n release"
	},
	"activity_summary": {
		"activity": "Activity",
		"on_target_activity": "Assigned Activity",
		"on_target_activity_description": "Material from the assigned learning objectives.",
		"on_target_activity_aria": "Assigned: Interacted with {{count}} assigned activity",
		"prerequisite_activity": "Refresher Activity",
		"prerequisite_activity_description": "Support Material from the assigned learning objectives.",
		"prerequisite_activity_aria": "Refresher: Interacted with {{count}} refresher activity",
		"practice_activity": "Practice Activity",
		"practice_activity_description": "Material worked on after completing the assignment.",
		"practice_activity_aria": "Practice: Interacted with {{count}} practice activity",
		"view_activity_details": "View Activity Details",
		"last_active": "Last active {{time}}"
	},
	"learn_flow": {
		"assignment_already_completed": "This assignment has already been completed.",
		"assignment_progress": "Assignment progress: {{percent}}%",
		"assignment_removed_error": "Your instructor has removed the assignment you were working on.",
		"content_has_changed_error": "Refresh this page! It seems like the content you are working on has changed. Refresh this page to see the updates!",
		"continue_learning": "Continue",
		"correct_answer": "Correct answer:",
		"correct_message_0": "Perfect. Your hard work is paying off 😀",
		"correct_message_1": "Yes that's right. Keep it up!",
		"correct_message_2": "Great work! That's correct.",
		"correct_message_3": "Well done! You got it right.",
		"correct_message_4": "Correct! You nailed it.",
		"free_response_prompt": "Provide your answer below:",
		"general_explanation": "General explanation",
		"incorrect_attempt_message": "Sorry, that's incorrect. Try again?",
		"incorrect_message_0": "That's not right - let's review the answer.",
		"incorrect_message_1": "Not quite right - check out the answer explanation.",
		"incorrect_message_2": "Keep trying - mistakes can help us grow.",
		"incorrect_message_3": "Not quite - review the answer explanation to help get the next one.",
		"incorrect_message_4": "That's incorrect - mistakes are part of learning. Keep trying!",
		"instruction_complete": "You have completed instruction for this learning objective. Answering the same questions correctly multiple times may have little or no effect on your progress. To continue working, click the \"Keep Going\" button.",
		"jump_to_content": "Return to content",
		"los_completed": "Way to go! You completed {{numLosFinished}} of {{numLosTotal}} objectives!",
		"multiple_answer_prompt": "Select all that apply:",
		"multiple_choice_prompt": "Select the correct answer below:",
		"no_instructional_content": "Oops, it looks like no instructional content exists!",
		"override_correct_message": "Marked correct",
		"override_ignored_message": "This question was marked ignored and does not affect grading.",
		"override_incorrect_message": "Marked incorrect",
		"override_partial_credit_message": "Partial credit",
		"question_cheat": "Instructor Cheat",
		"question_cheat_disabled_tooltip": "Cheat feature can only be used once. Refresh the page to use it again.",
		"question_cheat_tooltip": "Fill in the correct answer (available to Instructors only).",
		"question_desmos_cheat_disabled_tooltip": "Cheat feature not currently available for this question type.",
		"question_help": "More Instruction",
		"question_submit": "Submit",
		"question_try_again": "Try Again",
		"question_view_answer": "View Answer",
		"quiz_correct_message": "That is correct!",
		"quiz_incorrect_message": "That's not right.",
		"return_to_question": "Return to question",
		"review": "Review: ",
		"show_steps_button_label": "Show Steps",
		"show_steps_tooltip": "See a similar question broken down into steps to help you practice",
		"skipped_message": "This question was skipped.",
		"toggle_analytics_hide": "Hide progress breakdown",
		"toggle_analytics_show": "Show progress breakdown",
		"topic_struggling": "Let's switch it up. Moving on to the next topic.",
		"topics_completed": "Awesome, you completed {{numLosFinished}} of {{numLosTotal}} objectives! Moving on to the next topic!",
		"view": "View",
		"your_answer": "Your answer:",
		"mastery": "Mastery",
		"mastery_aria": "Mastery: {{mastery}}%",
		"toggle_analytics_aria": "Toggle learning objective analytics panel. When expanded, analytics will be above",
		"question": "Question",
		"assignment_mastery_percent": "Assignment Mastery: {{ percent }}%",
		"edit_score": "Edit Score",
		"instructor_comment": "Instructor Comment",
		"escape_remediation_available": "Nice work! You can try a few more Refreshers to build confidence, or resume your assigned objective.",
		"continue_current_remediation": "We recommend trying a few more Refreshers. Otherwise, click End Refresher to get back to the assigned objectives.",
		"got_it": "Got it",
		"end_refresher": "End Refresher",
		"start_topic": "Start",
		"switch_topic": "Switch"
	},
	"attempt_results_details": {
		"attempt_late_penalty": "(includes {{latePenalty}}% late penalty)",
		"attempts_result": "Attempt {{currentAttempt}}",
		"late_grading_tooltip": "This assignment was completed {{count}} day late with a {{latePenalty}}% penalty.",
		"late_grading_tooltip_plural": "This assignment was completed {{count}} days late with a {{latePenalty}}% penalty.",
		"no_results": "No results available to display yet.",
		"view_results": "View Results",
		"question_results": "Question Results",
		"question_results_tooltip": "Available on {{date}} at {{time}} {{timeZone}}",
		"comment": "{{ count }} comment",
		"comment_plural": "{{ count }} comments",
		"results_pending": "Question Results are pending release by your instructor"
	},
	"assessment_flow": {
		"countdown_day": "{{count}} day",
		"countdown_day_plural": "{{count}} days",
		"countdown_hour": "{{count}} hour",
		"countdown_hour_plural": "{{count}} hours",
		"countdown_minute": "{{count}} minute",
		"countdown_minute_plural": "{{count}} minutes",
		"countdown_second": "{{count}} second",
		"countdown_second_plural": "{{count}} seconds",
		"countdown_hour_minute": "$t(assessment_flow:countdown_hour, {\"count\": {{hours}} }), $t(assessment_flow:countdown_minute, {\"count\": {{minutes}} })",
		"countdown_hm": "{{hours}}h : {{minutes}}m",
		"countdown_s": "{{count}}",
		"countdown_label": "Time Left",
		"countdown_announce": "time is up in {{remaining}}",
		"countdown_announce_hour": "less than $t(assessment_flow:countdown_hour, {\"count\": {{count}} }) remaining",
		"countdown_announce_minute": "less than $t(assessment_flow:countdown_minute, {\"count\": {{count}} }) remaining",
		"countdown_announce_extended": "time extended, time is up in {{remaining}}"
	},
	"quiz_flow": {
		"attempts": "Attempts",
		"before_start_date": "This activity hasn't started yet.",
		"exit": "Exit",
		"download_ldb": "Download Respondus Lockdown Browser",
		"unsupported_ldb_device": "The LockDown Browser is not supported on this device. Please use a different device to access this assessment.",
		"ldb_launch_anyway": "or launch anyway",
		"score": "Score",
		"submit": "submit",
		"save_answers_error": "Something went wrong when saving your work. Please try again.",
		"save_answers_on_submit_error": "Something went wrong when saving your latest work. Earlier work will be graded.",
		"submit_error": "Something went wrong when submitting your assessment for grading. Please try again later.",
		"submit_success": "Assessment successfully submitted",
		"start_date": "Start date and time",
		"skipped_empty_state": "Questions you have not answered will appear here.",
		"end_date": "End date and time",
		"auto_save_note": "All answers will be saved automatically.",
		"not_started": "Ready to begin? Good luck!",
		"assessment_submitted": "Your {{assessment_type}} has been submitted.",
		"assessment_closed": "Sorry, you didn't submit this {{assessment_type}} and it is now closed.",
		"password_description": "A password is required to access this test. Your instructor will provide the password before the test begins.",
		"password_protected": "Password protection",
		"password_required": "Password required",
		"answers_will_be_posted": "Answers will be available after the due dates for all students have passed.",
		"whats_this": "What's this?",
		"unanswered_empty_state": "All questions have been answered.",
		"use_ldb": "Secure browser",
		"questions_answered": "Questions answered",
		"secure_browser": "Launch in Secure Browser",
		"secure_browser_description": "You'll need to install a browser with enhanced security to take this test. Download and install the Respondus Lockdown Browser.",
		"secure_browser_required": "Secure browser required",
		"submit_assessment": "Submit Assessment",
		"answered_questions": "{{numAnswered}} of {{totalQuestions}} questions answered",
		"time_left": "Time Left",
		"time_limit": "Time limit",
		"time_limit_description_1": "This is a timed test. You'll have {{time_limit}} to complete this test from the moment you start.",
		"time_limit_description_2": " If time runs out or the test closes before you complete it, your progress up to that point will be automatically submitted for you.",
		"time_limit_description_3": "Please note, the timer will continue to run even if you leave the test.",
		"time_taken": "Time taken",
		"timer_expired": "Time expired before saving your latest work. Earlier work will be graded.",
		"timer_started": "The timer for this test is running.",
		"view_results": "View Results",
		"saving_error": "Saving error",
		"content_left": "{{count}} left",
		"unlock": "Unlock",
		"start": "Start",
		"keep_going": "Keep Going",
		"try_again": "Try Again",
		"preview": "Preview of {{quizTitle}}",
		"saving": "saving",
		"multiple_attempts": "Multiple Attempts",
		"multiple_attempts_description": "Settings allow you to retake up to {{maxNumAttempts}} times, and the {{gradeAttemptsAs}} score of the {{maxNumAttempts}} will be used as your grade.",
		"multiple_attempts_total": "{{gradeAttemptsAs}} Score of {{maxNumAttempts}} Attempts",
		"no_questions_yet_test": "This test does not have any questions yet.",
		"no_questions_yet_quiz": "This quiz does not have any questions yet.",
		"results_page_error_generic": "Something went wrong while fetching your results. Please try again.",
		"show_answers": "Show Answers",
		"assessment_printable_indicator": "This test is being conducted offline. Please reach out to your instructor for more details.",
		"answered": "Answered",
		"max_points": "Max points",
		"past_due_assessment": "You didn't submit this assessment. It is now closed.",
		"answer_explanation_banner": "Answer explanations will be available on {{date}} at {{time}} {{zone}}.",
		"answer_explanation_banner_with_manual_setting": "Answer explanations are pending release by your instructor. Check back later.",
		"saving_text": "Saving...",
		"answer_saved": "Answer saved {{time}}",
		"need_info": "Need information about viewing your results?",
		"read_more": "Read more"
	},
	"password_modal": {
		"enter_password": "Enter the password provided by your instructor to unlock this test.",
		"password": "Password",
		"password_placeholder": "Enter password",
		"start_test": "Start test",
		"start_test_timed": "Start timer",
		"unlock_test": "Ready to start your test?"
	},
	"timed_test_modal": {
		"ok": "Start timer",
		"cancel": "Cancel",
		"heading": "Ready to start your timed test?"
	},
	"missing_answers_modal": {
		"modal_title": "{{numUnansweredQuestions}} Unanswered Questions",
		"modal_body": "You haven't answered all the questions in this test. Are you sure you want to submit?",
		"submit_anyway": "Submit Anyway",
		"back_to_test": "View Unanswered"
	},
	"quiz_time_up_modal": {
		"time_up_title": "Time's up!",
		"time_up_body": "Don't worry, we submitted your answers for you. Results will be posted after the testing period has ended.",
		"time_up_test_submit": "Back to Test Overview",
		"time_up_quiz_submit": "Back to Quiz Overview"
	},
	"assessment_time_up_modal": {
		"modal_title": "Time's up!",
		"modal_body": "We automatically submitted your answers for you. Results will be posted after the testing period has ended.",
		"close_btn": "close"
	},
	"quiz_timer_error_modal": {
		"timer_error_title": "Time's up?",
		"timer_error_body": "We tried to submit your exam, but it seems like you still have more time to work. Please verify that your computer's system clock is correct. If this problem persists, please reach out to our support team to take a look. You can still access your exam by closing this modal.",
		"timer_error_submit": "I've fixed my clock"
	},
	"assignment_time_up_modal": {
		"time_up_title": "Time's up!",
		"time_up_body": "Your progress has been submitted for a grade so you will no longer get further credit. Please note, you can always keep working on any assignment - even after completion.",
		"time_up_keep_going": "Keep going",
		"time_up_back": "Back to assignment overview"
	},
	"more_instruction_modal": {
		"header": "Having trouble?",
		"no_repeat_body": "We're here to help! We've got instructional material that may help improve your understanding of this learning objective:",
		"repeat_body": "Ok, you can review material you've already seen on this learning objective. Answering the same questions from \"More Instruction\" correctly multiple times may have little or no effect on your progress.",
		"continue": "View Instruction",
		"go_back": "Go Back",
		"no_instruction_exists": "Oops, it looks like there is no instructional content related to this question!"
	},
	"assignment_complete_modal": {
		"cta": "Continue",
		"feedback_prompt": "Your feedback",
		"feedback_success": "Thanks for your rating and feedback!",
		"heading": "You did it!",
		"heading_late": "Assignment completed!",
		"num_stars": "{{ count }} out of 5 stars",
		"rating_prompt": "Rate this assignment",
		"subheading": "Your hard work paid off.",
		"subheading_late": "Great job! Our data shows that completing this assignment means you're better prepared for future assignments and tests.",
		"stop_animation": "Stop Animation",
		"start_animation": "Start Animation"
	},
	"complete_modal": {
		"preview_complete": "Assignment Complete!",
		"custom_complete": "Sequence Preview Complete!",
		"preview_message": "You completed the assignment {{assignment_name}}. Please close this browser tab and return to your course.",
		"custom_message": "You completed the sequence preview. Please return to the previous page.",
		"header": "Mission Accomplished!",
		"completed_message": "You completed the booster {{name}}. You may now close this browser tab and return to your original assignment.",
		"close_tab": "Close Tab"
	},
	"preview_complete_modal": {
		"preview_complete": "Assignment Preview is Complete",
		"return_to_course": "Please close this tab to return to your course.",
		"close_tab": "Close Tab"
	},
	"learning_activity_tab": {
		"learning_activity": "Learning activity",
		"not_found": "No learning history found.",
		"all": "All content, {{count}} items total",
		"instructional": "Instructional content, {{count}} items total",
		"correct": "Correct content, {{count}} items total",
		"incorrect": "Incorrect content, {{count}} items total",
		"no_activity": "No activity",
		"no_activity_details": "Activity will be displayed here once the assignment is started.",
		"no_question_results": "Results will appear here once the student has completed the test.",
		"activity_summary": "Activity Summary",
		"work_time": "Work Time",
		"learning_objective": "Learning Objective",
		"30_plus_minutes": "30+ minutes",
		"30_plus_minutes_tooltip": "It looks like the work session was ended. This duration has not been included in the total work time for this assignment.",
		"no_activity_for_filters": "No activity is available for the selected filters."
	},
	"learning_activity_list_item": {
		"subject": "instructional material",
		"check": "correct answer",
		"close": "incorrect answer",
		"partial": "partial credit answer",
		"remove-circle-outline": "ignored answer",
		"completed_assignment": "You completed the assignment",
		"icon_prereq_tooltip": "Refresher",
		"icon_prereq_tooltip_with_lo": "Refresher of {{ name }}",
		"icon_practice_tooltip": "Extra practice after the assignment has been completed."
	},
	"forfeit_attempts_modal": {
		"view_results_forfeit_attempts_title": "Are you sure you want to view results?",
		"view_results_forfeit_attempts_body": "You still have more attempt(s) on this test. Choosing to view results will forfeit any remaining attempts.",
		"show_answers_forfeit_attempts_title": "Are you sure you want to see the answers?",
		"show_answers_forfeit_attempts_body": "You still have more attempt(s) on this test. Choosing to show answers will forfeit any remaining attempts.",
		"cancel": "Cancel",
		"proceed": "Proceed"
	},
	"activity_navigator": {
		"activity": "Activity",
		"previous_button_aria": "Show previous activity",
		"next_button_aria": "Show next activity"
	},
	"sticky_navigator": {
		"back_button_close_modal": "Close Modal"
	},
	"ACTIVITY_MODAL": {
		"answer_list": "List of the multiple choice options for this question"
	},
	"BREAK_MODAL": {
		"header": "Hey Fellow Knerd!",
		"message_intro": "You've done a lot of work on this assignment and it looks like you may still have ",
		"message_outro": " Consider sending a Knerd mail to your instructor to review your progress,\nto a friend for help, or just taking a break.",
		"message_questions_remaining": "{{count}} or more questions to go",
		"continue": "Continue Working",
		"email_instructor_button": "Knerd mail my instructor",
		"instructor_email_subject": "KNERD MAIL: Student help request",
		"instructor_email_body": "{{- student_name}} ({{student_email}}) would like your help on the assignment\n{{- assignment_name}}, due by {{assignment_due_date}}.\n{{- lo_names}}\n\nPlease see {{- student_first_name}}'s work so far here:\n\n{{- teacher_link}}\n\n[Please note, this link only functions for instructors of the course.  Instructors are able to see\nwhat materials the student has worked on and for how long.]\n\nPersonal note from {{- student_first_name}}:\n\n[Add a note for your teacher here]\n\nThank you,\nKnewton\n",
		"instructor_email_body_los": "{{- student_name}} is having a tough time with the following learning objective(s):\n{{- learning_objective_names}}\n"
	},
	"rescue_modal": {
		"help_title": "Pardon the Interruption",
		"struggle": "It looks like you're struggling with the learning objective",
		"crystal_ball": "Our crystal ball says you may still have",
		"more_questions": "{{count}} or more questions remaining.",
		"help_actions": "Consider reaching out to your instructor, or just taking a short break.",
		"continue": "Continue Working",
		"boost_me": "Boost Me",
		"boosters_title": "Need a boost?",
		"boosters_body": "Completing a Booster assignment may help you complete this assignment faster!",
		"boosters_footnote": "Note: Booster assignments are automatically added to your assignment list."
	},
	"dataset": {
		"dataset_help_aria": "Click here for instructions on how to use statistical software.",
		"dataset_help_link_aria": "This link will open in a new tab.",
		"dataset_help_text_1": "For instructions on how to use statistical software, see ",
		"dataset_help_text_2": " for reference.",
		"error": "Oops. Something went wrong with this dataset. Please send us feedback so we can fix the issue.",
		"help": "Help"
	},
	"external_url": {
		"external_url_new_tab_button_label": "open in new tab",
		"external_url_new_tab_launch_label": "view content in new tab"
	},
	"content_attribution": {
		"content": "Content",
		"by": "by {{authorName}}",
		"license": "is licensed under",
		"download": "Download for free",
		"media": "Media",
		"video": "Video",
		"dataset": "Dataset",
		"image": "Image",
		"desmos": "Desmos graph",
		"alchemie": "Alchemie",
		"external_url": "Embedded content",
		"content_attribution": "Content attribution",
		"attributions": "Licenses and Attributions",
		"elsevier_copyright": "{{atomName}} by Elsevier. Copyright© {{year}} Elsevier Inc. All Rights Reserved."
	},
	"compound": {
		"answer": "Answer"
	},
	"account_edit_page": {
		"email": "Email",
		"current_email": "Current email",
		"new_email": "New email",
		"firstName": "First name",
		"lastName": "Last name",
		"password": "Password",
		"current_password": "Current password",
		"change_distributor": "Change distributor",
		"newPassword": "New password",
		"newPasswordConfirm": "Confirm new password",
		"month": "Month",
		"day": "Day",
		"year": "Year",
		"primaryRole": "Role",
		"must_provide_password": "Please enter your current password above to change your password.",
		"check_confirm_password": "Passwords do not match.",
		"myAccount": "My Account",
		"signin_security": "Sign in & security",
		"must_enter_current_pwd": "You must enter your current password to change your password.",
		"password_cta": "Choose a new password to protect your account, or ",
		"reset_password_cta": "reset your password",
		"personal_info": "Personal info",
		"date_of_birth": "Date of Birth",
		"notifications": "Notifications",
		"course_digest_emails": "Course digest emails",
		"course_digest_emails_description": "Receive periodic email notifications for top learning objectives to review with your class.",
		"delete_account_label": "Delete your Knewton account",
		"delete_account_description": "Learn about your options and delete your Knewton account if you wish.",
		"account": "Account",
		"more_info": "MORE INFO",
		"course_digest_enabled": "Course digest emails are now enabled.",
		"course_digest_disabled": "Course digest emails are now disabled.",
		"accessibility": "Accessibility",
		"accessibility_description": "Enable accessibility settings such as MathML support on JAWS.",
		"accessibility_enabled": "Accessibility is now enabled.",
		"accessibility_disabled": "Accessibility is now disabled.",
		"accessibility_browser": "Browser support",
		"accessibility_browser_description": "We highly recommend using Microsoft Internet Explorer 11 with JAWS. For additional browser and screen-reader support, reach out to Knewton Support.",
		"accessibility_support": "Accessibility support documentation",
		"accessibility_support_description": "You can find support documentation",
		"accessibility_support_here": "here",
		"accessibility_support_link_label": "Open accessibility support page in a new tab",
		"account_deleted": "Your account has been deleted!",
		"student_role": "Student",
		"instructor_role": "Instructor",
		"successful_update": "Your information has been successfully updated!",
		"i_understand_error": "Please type I UNDERSTAND, try again.",
		"delete_success": "Your account has been deleted!",
		"save": "save",
		"email_error": "Email may not be the same as your old email."
	},
	"delete_account_modal": {
		"header": "We're sad to see you go!",
		"i_understand": "I UNDERSTAND",
		"cancel": "CANCEL",
		"delete_account": "DELETE ACCOUNT",
		"please_type": "Please type I UNDERSTAND to confirm",
		"delete_success": "Your account has been deleted!"
	},
	"knerd_delete_role_modal": {
		"delete_role_title": "Delete Role",
		"delete_role_message": "Are you sure you want to delete this role (there's no undoing this)?",
		"success_delete_role": "Role successfully deleted",
		"failed_delete_role": "Failed to delete role"
	},
	"SUBMIT_QUIZ_MODAL": {
		"title": "Submit answers",
		"message": "You will not be able to change your answers once they have been submitted for grading.",
		"submit_button": "submit",
		"cancel_button": "cancel"
	},
	"LDB_LAUNCH_MODAL": {
		"download_ldb": "download and install the Respondus Lockdown Browser. ",
		"title": "Secure browser launched",
		"message_1": "If a new window didn't launch, you'll need to ",
		"message_2": "Please make sure it's installed before trying again.",
		"got_it": "Got it"
	},
	"forgot_password": {
		"reset_password": "Reset password",
		"enter_email": "Enter the email address associated with your account and we'll send you a link to reset your password.",
		"email_field_label": "Email",
		"send_password_reset_link": "SEND PASSWORD RESET LINK",
		"email_header_confirm": "An email is on its way!",
		"didnt_get_email": "Didn't get an email?",
		"send_another_email": "Send another one",
		"email_sent": "We sent an email to {{email}}. Please follow instructions in the email to reset your password.",
		"email_sent2": "Email sent!",
		"type_new_password": "New Password",
		"retype_new_password": "Retype New Password",
		"expired_link": "The link you opened to reset your password has expired.",
		"update_password": "UPDATE PASSWORD",
		"password_updated": "Your password has been successfully updated!",
		"password_equals": "Passwords do not match."
	},
	"institution_admin": {
		"info": "Info",
		"institution_name": "Institution Name",
		"primary_lms_title": "Primary LMS",
		"primary_lms_content": "Check out our LMS Integration Documentation for detailed instructions on integrating Knewton into your LMS",
		"add_link": "Add Link",
		"generate_credentials": "Generate Credentials",
		"lti_credentials_title": "LTI Credentials",
		"lti_credentials_content": "Generate a set of LTI credentials to integrate Knewton into your LMS.",
		"lti_credentials_content_generated": "Use the following LTI credentials to integrate Knewton into your LMS",
		"lti_credentials_key": "Key",
		"lti_credentials_secret": "Secret",
		"lti_fetching_credentials": "LOADING...",
		"lti_generate_credentials": "Generate Credentials",
		"lti_generating_credentials": "GENERATING...",
		"lti_generate_credentials_error": "Oh no! Something went wrong with your request. Please try again.",
		"lti_refresh_browser": "Oh no! Something went wrong here. Please refresh your browser.",
		"lti_contact_support": "If this problem persists, please go to Knewton Support for further assistance",
		"lti_knewton_support": "Knewton Support",
		"lti_key": "LTI Key",
		"lti_secret": "LTI Secret",
		"has_been_copied": "has been copied to your clipboard",
		"created_on": "Created On",
		"lms_verification": "LTI Verification",
		"lms_verify_config_title": "To verify your configuration, please add the following LTI link to a test course in your LMS and launch it.",
		"lti_integration_heading": "Integrating Knewton Alta into your LMS",
		"lti_integration_error_list_heading": "To complete the Knewton Alta integration in your LMS, please verify the following:",
		"lti_consumer_key_missing_msg": "The LTI key is missing.",
		"lti_signature_invalid_msg": "The LTI key or secret appears to be incorrect. Verify the configuration in your LMS.",
		"lti_outcomes_url_missing_msg": "Ensure that you have enabled grading in the tool provider configuration.",
		"lti_user_data_missing_msg": "The LMS is currently not sending Alta the {{ userFields }} for the user. Please verify the tool provider configuration in the LMS and ensure that these fields are sent as part of the LTI launch.",
		"lti_config_success_msg": "Success! Everything looks good. Instructors may now be able to proceed with building out their courses with LTI links from Alta.",
		"first_name": "first name",
		"last_name": "last name",
		"email": "email address",
		"role": "role"
	},
	"test_banner": {
		"start_header": "Your assessment is ready to begin!",
		"review_header": "Review for your upcoming assessment",
		"upcoming": " is coming up in ",
		"upcoming_duration": "{{duration}}!",
		"cta": "We've put together a list of topics to help you prepare for your assessment.",
		"review_link": "GET STARTED",
		"started": " has started and is due {{timeDue}}.",
		"view_test_link": "view assessment {{name}}",
		"good_luck": "Good luck!"
	},
	"review_center": {
		"sort_by_label": "Sort by",
		"sort_by_previous_mastery": "Mastery on associated homework",
		"sort_by_current_mastery": "Mastery on review assignment",
		"sort_by_last_worked_on": "Last activity on associated homework",
		"sort_by_course_order": "Original order of assignments",
		"progress_accessible": "You previously mastered {{previousLos}} of {{numLos}}\nlearning objectives in this topic {{lastWorkedOnStr}}.\n"
	},
	"session_expired_modal": {
		"modal_title": "Session expired",
		"modal_body": "Your session expired. Please login again.",
		"go_to_login": "Go to login page"
	},
	"unified_review_center": {
		"review_center": "Review Center",
		"no_review_items": "No review items yet",
		"no_review_items_detail": "Your course doesn't have any review items available yet.\nIf you've got an upcoming test that you'd like to prepare for, you can ask your\ninstructor to enable the review center for it.\n"
	},
	"quiz_review_center": {
		"review_items": "Review List",
		"nwp_review_items": "Learning List",
		"no_review_items": "No items to review!",
		"review_for_quiz": "Review for {{quizName}}",
		"nwp_review_for_quiz": "Learning Center for {{quizName}}",
		"back_to_course": "Back to Course",
		"back_to_review_center": "Back to Review Center"
	},
	"course_cover_path_list_item": {
		"review": "Review",
		"optional": "Optional",
		"offline_assessment_label": "Offline {{ label }}",
		"assessment_label": "{{ label }}",
		"test_end_tooltip": "Ends on {{date}} at {{time}} ({{zone}}).",
		"test_start_tooltip": "Starts on {{date}} at {{time}} ({{zone}}).",
		"print_aria_label": "Offline test: {{assessmentType}} {{pathName}}",
		"course_cover_aria_label": "{{status}} {{assessmentType}}: {{pathName}}.{{overdue}} Start date: {{startDate}}. End date: {{endDate}}. {{percentMastery}}",
		"complete": "complete",
		"incomplete": "incomplete",
		"overdue_full_stop": "overdue.",
		"percent_mastery": "- {{progress}}% mastery",
		"comment": "{{count}} comment",
		"comment_plural": "{{count}} comments",
		"review_link_aria": "Review {{title}} (Optional) ({{progress}}% mastery)"
	},
	"analytics_dashboard": {
		"ALL": "ALL",
		"CURRENT": "CURRENT",
		"PAST": "PAST",
		"load_more": "LOAD MORE",
		"placeholder_course_name": "Untitled",
		"invalid_date_span": "invalid time span",
		"warn_inactive_student": "{{count}} inactive student",
		"warn_inactive_student_plural": "{{count}} inactive students",
		"warn_lo_review": "{{count}} learning objective needs review",
		"warn_lo_review_plural": "{{count}} learning objectives need review",
		"search_prompt": "Search by Course Name or Instructor",
		"search_clear": "Clear search",
		"no_courses_header": "No courses at your institution yet.",
		"no_courses_body": "Once courses are created, you can monitor their progress here.",
		"no_courses_tab": "No courses to show.",
		"struggling": "Struggling",
		"no_work": "Not Started",
		"in_progress": "In Progress",
		"complete": "Complete",
		"overall_status": "Overall Status",
		"learning_objective_status": "Individual Learning Objective Status",
		"firstname": "First Name",
		"lastname": "Last Name",
		"students": "Students",
		"questions": "Questions",
		"status": "Status",
		"work_time": "Work Time",
		"mastery": "Mastery",
		"grade": "Grade",
		"no_students_found": "No students selected - Clear Filters",
		"marked_done_on": "Marked Done on {{date}}",
		"completed_on": "Completed on {{date}}",
		"completed_late_on": "Completed late on {{date}}",
		"not_complete_by_due_date": "Not complete by due date",
		"percent_at_due_date": "{{percent}}% at Due Date",
		"error_getting_course_analytics": "Something went wrong while getting the course analytics. Please try again."
	},
	"student_donut": {
		"student": "{{count}} Student",
		"student_plural": "{{count}} Students",
		"excelling_percentage": "Above 90%",
		"on_track_percentage": "60 to 90%",
		"at_risk_percentage": "Below 60%"
	},
	"assignment_donut": {
		"assignment": "{{count}} Assignment",
		"assignment_plural": "{{count}} Assignments",
		"quiz": "{{count}} Quiz",
		"quiz_plural": "{{count}} Quizzes",
		"test": "{{count}} Test",
		"test_plural": "{{count}} Tests",
		"average_grade_quiz": "Average grade on quizzes due to date",
		"average_grade_test": "Average grade on tests due to date",
		"average_mastery": "Average mastery on assignments due to date"
	},
	"CHILD_USER_MODAL": {
		"header": "Thank You For Your Interest",
		"text": "Thank you for your interest in Knewton! Users must be a certain age to create an account to use our products. If you would like to use Knewton, please ask your parent or guardian to sign up and create an account for you.",
		"ok_button": "OK"
	},
	"set_username_modal": {
		"set_username_text": "Please add your first and last name to your account before proceeding by clicking ",
		"reload_text": ". You will then be able to author custom questions after refreshing this page.",
		"link_text": "here"
	},
	"incompatible_questions_modal": {
		"ldb_header": "Questions Incompatible with Lockdown Browser",
		"ldb_description": "At least one question in this test has downloadable content which is incompatible with the Respondus Lockdown Browser. Consider removing these questions and replacing them.",
		"print_header": "Questions Incompatible with Offline Tests",
		"print_description": "At least one question in this test has material which is incompatible with the offline tests. Consider removing these questions and replacing them.",
		"cancel": "Cancel",
		"review_questions": "Review Questions"
	},
	"reload_prompt": {
		"reload_prompt": "A new version of Alta is available",
		"refresh": "REFRESH"
	},
	"csrf_mismatch": {
		"modal_title": "Session Invalid",
		"modal_body": "Your session is invalid. Please refresh the page to continue.",
		"refresh": "Refresh"
	},
	"quiz_review_assignment_list_item": {
		"removed_from_test": "Removed from test"
	},
	"course_enrollment_url": {
		"copy_before": "COPY KNERD LINK",
		"copy_after": "COPIED",
		"contact_button": "Contact Knewton",
		"no_token_error": "We are unable to retrieve an invitation link. Please refresh your page."
	},
	"course_enrollment": {
		"approve_instructor": "Instructor has been approved.",
		"error_approve_instructor": "Something went wrong while approving this instructor. Please try again.",
		"remove_instructor": "Instructor removed.",
		"error_remove_instructor": "Something went wrong while removing this instructor. Please try again.",
		"remove_student": "Student removed.",
		"error_remove_student": "Something went wrong while removing this student. Please try again."
	},
	"course_share": {
		"share_header": "Copy this Course",
		"share_explanation": "Use this link to allow another instructor to copy this course to their account. The copy of the course will include everything on your schedule, but no student or co-instructor information will be shared.",
		"share_error": "An error has occurred generating your share link. Please contact Knewton Support.",
		"copy_before": "COPY LINK",
		"copy_after": "COPIED",
		"reset_button": "RESET"
	},
	"course_share_link_reset": {
		"confirmation_body": "Are you sure you want to reset your share link? Any previously shared links will become invalid and a new link will be generated.",
		"confirmation_button": "Reset"
	},
	"access_codes": {
		"978-1-63545-244-0": "Single-Term access",
		"978-1-63545-245-7": "altaPass",
		"access_codes_custom": "Custom"
	},
	"course_billing_models": {
		"course_evaluation_label": "Evaluation",
		"free_pilot_label": "Free Pilot",
		"free_secondary_label": "Secondary Corequisite Course",
		"marketing_campaign_equity_label": "Equity Campaign",
		"open_access_label": "Open Access",
		"student_paygate_label": "Student Paygate",
		"student_paygate_third_party_label": "Student Paygate via 3rd Party",
		"virtual_ta_poc_label": "Virtual TA",
		"help_more_information": "For more information visit our ",
		"help_support_center": "Support Center"
	},
	"institution_billing_models": {
		"student_paygate_helptext": "Students can purchase access directly on Alta or purchase access codes at the bookstore and redeem them in Alta.",
		"student_paygate_third_party_label_helptext": "Use only if this course has a special integration with a 3rd party such as Willo Labs that will provide student payment options. Note that Alta will NOT show a paygate to students in this course.",
		"open_access_helptext": "Students will be able to join this course without seeing the Alta paygate. Wiley will invoice the institution directly based on a census."
	},
	"course_assignment_completion_dropdown": {
		"allText": "All {{assignmentType}} ({{assignmentCount}})",
		"dueText": "{{assignmentType}} Due to Date ({{assignmentCount}})"
	},
	"coursework_student_assignment": {
		"learning_objectives": "Learning Objectives",
		"learning_objective": "Learning Objective",
		"mastery": "Mastery",
		"learning_objective_missing": "Missing Learning Objective"
	},
	"email_students": {
		"email_students_assignment_body": "Hello,\nI'd like to check-in regarding the activity {{assignmentName}} in the course {{courseName}}.\n<Please add further resources or information you'd like to share with your student(s).>\n\n{{instructorName}}\n",
		"email_students_assignment_subject": "Check-in on {{assignmentName}}",
		"email_students_course_subject": "Check-in on {{courseName}}",
		"email_students_course_body": "Hello,\nIt may be helpful to check-on on how you're doing with the course {{courseName}}.\n<Please add further resources or information you'd like to share with your student(s)>\nLet's set up a time to chat,\n\n{{instructorName}}\n",
		"email_students_title": "Send Knerd Mail to selected Students"
	},
	"assignment_details_modal": {
		"cancel": "Cancel",
		"create": "Create",
		"save": "Save",
		"available_date": "Available date",
		"due_date": "Due date"
	},
	"third_party_cookie_modal": {
		"modal_title": "Third-party cookies need to be enabled",
		"modal_body_first": "Knewton Alta uses cookies to provide a seamless experience with your learning management system. ",
		"modal_body_second_link": "View instructions on how to enable third party cookies in your browser",
		"modal_body_third": ". Once you've enabled third-party cookies, reload this page to try again."
	},
	"no_match_found": {
		"help_text": "No {{type}} match the criteria."
	},
	"course_schedule": {
		"review_center_enabled": "Review Center enabled",
		"error_assignment_order": "The assignment order could not be updated. Please try again.",
		"success_assignment_order": "The assignment order was successfully updated.",
		"success_common_cartridge": "Successfully downloaded common cartridge."
	},
	"course_copy_in_progress": {
		"course_copy_header": "Please wait while we copy your course.",
		"course_copy_subheader": "Do not navigate away from this page."
	},
	"orders_site": {
		"onboarding_blurb_intro": "Welcome to Knewton's distributor self-service portal. Sign in or register to order access codes for our digital courseware.",
		"onboarding_blurb_bullet1": "Manage your account online",
		"onboarding_blurb_bullet2": "Access 24/7",
		"onboarding_blurb_bullet3": "Get access codes instantly",
		"choose_distributor_header_line1": "Welcome, {{userDisplayName}}!",
		"choose_distributor_header_line2": "Get started by selecting your store.",
		"change_distributor_header_line2": "Change your store here.",
		"search_distributors_input_label": "Search for a store by address, zip code, or store number. e.g. \"12 university way\"",
		"select_distributor_cta": "Select",
		"select_distributor_confirmation_header": "Confirm store selection?",
		"select_distributor_info_label": "You've selected:",
		"select_distributor_cancel_cta": "Cancel",
		"select_distributor_confirm_cta": "Confirm",
		"add_store_line_1": "ADD STORE",
		"add_store_line_2": "Don't see your store? Add it here."
	},
	"order_management": {
		"heading": "Order Management",
		"new_pilot": "New Pilot",
		"distributors": "Distributors",
		"free_pilot_support_body1": "Hi there! We no longer support creating multi-use codes. Now your course can be tagged as a 'Free Pilot' directly in ",
		"free_pilot_support_body2": " and that will take down the student paygate for the course! Please note, only CSMs have this permission.",
		"free_pilot_support_body3": "For all the details, please check out the ",
		"release_notes": "release notes",
		"thank_you": "Thank you!"
	},
	"order_whitelist": {
		"add_domain": "Add a domain",
		"search_domain": "Search for a domain",
		"whitelisted_domains": "Whitelisted Domains"
	},
	"add_domain_whitelist_entry": {
		"add": "Add",
		"domain": "Domain",
		"title": "Add a domain",
		"description": "Enter a new domain to add:",
		"success_add_domain": "Successfully added a new domain!",
		"cancel": "Cancel"
	},
	"order_distributors": {
		"add_distributor": "Add a distributor",
		"search_distributor": "Search for a distributor",
		"distributors": "Distributors",
		"no_results": "No results",
		"no_stores_yet1": "Your organization hasn’t added any stores yet.",
		"no_stores_yet2": "Get started by adding your store below.",
		"edit": "Edit",
		"delete": "Delete",
		"load_more_search_results": "SHOW MORE",
		"error": "Sorry, there was a problem searching for distributors! Please try again, and contact knewtonsupport@wiley.com if the problem persists.",
		"delete_distributor_succeeded": "Distributor successfully deleted!",
		"delete_distributor_failed": "Failed to delete distributor. Check that no users are associated with this distributor and try again.",
		"edit_distributor_heading": "Edit distributor"
	},
	"distributor_form": {
		"add": "Add Store",
		"success_add_distributor": "Successfully added distributor",
		"success_update_distributor": "Successfully updated distributor",
		"error_update_distributor": "Failed to update distributor",
		"update": "Update",
		"state": "State",
		"store_name": "Store Name",
		"store_number": "Store identifier",
		"title": "Add store",
		"description": "Let us know what store you'll be placing an order for.",
		"billing_address": "BILLING ADDRESS",
		"address_1": "Address line 1",
		"address_2": "Address line 2",
		"email_domain": "Email domain",
		"same_billing": "Shipping address is same as billing address",
		"shipping_address": "SHIPPING ADDRESS",
		"phone_number": "Store phone number",
		"operator": "Operated by",
		"city": "City",
		"zip": "Zip code",
		"general_info_section_heading": "General Information",
		"billing_address_section_heading": "Billing Address",
		"shipping_address_section_heading": "Shipping Address",
		"unknown_add_distributor_error": "Sorry, we couldn't add this store! Please try again, and contact knewtonsupport@wiley.com if the issue persists.",
		"DISTRIBUTOR_NAME_CONFLICT": "A store with this name already exists. Please return to the search page to select it.",
		"DISTRIBUTOR_OPERATOR_AND_STORE_NUMBER_CONFLICT": "A store with this operator and store identifier already exists. Please return to the search page to select it.",
		"DISTRIBUTOR_BILLING_AND_SHIPPING_ADDRESS_CONFLICT": "A store with this billing and shipping address already exists. Please return to the search page to select it.",
		"DISTRIBUTOR_UNKNOWN_CONFLICT": "A matching store already exists. Please return to the search page to select it, or contact knewtonsupport@wiley.com if you are unable to find it.",
		"DISTRIBUTOR_DOMAIN_NOT_IN_WHITELIST": "The distributor domain name has not been whitelisted. Please add it using the 'Add a domain' form.",
		"DISTRIBUTOR_UNKOWN_FOREIGN_KEY": "The distributor is referencing an unknown entity."
	},
	"course_saga": {
		"success_edit_course": "Course {{courseName}} successfully updated.",
		"success_create_course": "The course {{courseName}} was successfully created.",
		"success_delete_course": "Course deleted.",
		"error_delete_course": "Course could not be deleted. Please try again.",
		"error_load_admin_data": "Cannot load the data for the administrator!",
		"error_load_institution_data": "Error loading institution data! Please try again."
	},
	"token_saga": {
		"error_resetting_copy_token": "Error resetting course copy token"
	},
	"template_saga": {
		"success_template_create": "Template successfully created.",
		"error_template_create": "Template could not be created. Please try again.",
		"success_template_delete": "Template deleted.",
		"error_template_delete": "Something when wrong while deleting the template. Please try again.",
		"success_template_cartridge_download": "Successfully downloaded template cartridge."
	},
	"new_pilot_modal": {
		"new_free_pilot": "New Free Pilot Program",
		"coursepack": "Coursepack/ISBN",
		"choose_coursepack": "Choose coursepack/ISBN",
		"description": "Create a free pilot program and generate a multi-use access code to share with an instructor.",
		"all_fields_required": "*All fields are required.",
		"instructor_name": "Instructor name",
		"instructor_email": "Instructor email",
		"institution": "Institution",
		"choose_institution": "Choose institution",
		"course_name": "Course name",
		"month": "Month",
		"day": "Day",
		"year": "Year",
		"course_start_date": "Course start date",
		"course_term": "Course term",
		"quantity": "Quantity",
		"create": "Create",
		"institution_not_found_help": "If you don't see the institution you're looking for, contact knewtonsupport@wiley.com",
		"custom_coursepack_reminder": "Reminder: you can only use a custom ISBN for the institution it was created for.",
		"submission_error": "Sorry, there was a problem processing your order! Please contact knewtonsupport@wiley.com for support."
	},
	"free_pilot_confirmation": {
		"title": "You're all set!",
		"message1": "Check your email to view Order Details and the Access Code for this Free Pilot Program.",
		"message2": "Feel free to close this modal and go back to Knerd tools.",
		"back_to_knerd_tools": "BACK TO KNERD TOOLS"
	},
	"knerd_edit_course": {
		"permission_denied": "You do not have permission to make this change.",
		"save_error": "Something went wrong saving your changes, please refresh the page to see if the changes persisted.",
		"save_cancel": "Discard changes",
		"save_confirm": "Save changes",
		"save_success": "Course updated",
		"course_evaluation_description": "Use for courses that are intended ONLY for instructors and their colleagues to evaluate Alta, and not for real students. Users will be able to jump between student and instructor views easily.",
		"free_pilot_description": "Use only in the case of an approved FREE pilot. Students can join the sections of this course without needing to pay.",
		"free_secondary_description": "Use this billing model to indicate a secondary corequisite course that students should not pay for because they are already paying for a separate, concurrent course.",
		"marketing_campaign_equity_description": "This course is offered for free to the students as part of the Equitability Initiative. Students will be able to join this course without seeing the Alta paygate.",
		"open_access_description": "Students will be able to join this course without seeing the Alta paygate. Wiley will invoice the institution directly based on a census.",
		"student_paygate_description": "Students can purchase access directly on Alta or purchase access codes at the bookstore and redeem them in Alta. Instructors can order the following ISBNs to provide course access to their students:",
		"student_paygate_thirdparty_description": "Use only if this course has a special integration with a 3rd party such as Willo Labs that will provide student payment options. Note that Alta will NOT show a paygate to students in this course.",
		"virtual_ta_poc_description": "Virtual TA proof of concept, only. Do not use this billing model for Alta courses.",
		"mailto_label": "Email Instructor",
		"mailto_subject": "Order your access codes for Knewton Alta",
		"demo_course_description": "By selecting this as Demo Course, you are preventing the ability to join a section in this course. Use for dashboard and analytics demos only",
		"billing_model_warning": "This billing model is different than the default for this institution. Please ensure this is correct before proceeding.",
		"mailto_body": "Hello there,\n\nYour Knewton Alta course is coming up!\nBelow is the information you need to order access codes for your students.\nFeel free to forward this email to your bookstore.\n\nYour Course details:\n{{courseName}}\n{{startsAt}} - {{endsAt}}\n\n{{isbns}}\n\nThe bookstore can order access codes directly from us at knewton.com/orders.\nIf you need more information on ordering Knewton Alta access codes, please visit:\nhttps://support.knewton.com/s/article/Campus-Bookstore-Ordering-Access-Codes-and-Creating-a-Distributor-Account-for-Knewton-Alta\n\n\nThank you,\nKnewton Alta Team\n",
		"inclusive_access": "Inclusive Access",
		"inclusive_access_header": "Remove opted-out students from roster",
		"inclusive_access_header_description": "For students who voluntarily opt-out. Reduces total number of inclusive access enrollments",
		"inclusive_access_students": "Students ({{studentCount}})",
		"first_name": "First Name",
		"last_name": "Last Name",
		"email": "Email",
		"inclusive_access_status": "Inclusive Access Status",
		"no_students": "No Students",
		"label_on": "On",
		"label_off": "Off"
	},
	"knerd_create_instructor": {
		"heading": "Create Instructor",
		"first_name_label": "First Name",
		"last_name_label": "Last Name",
		"email_label": "Email",
		"institution_label": "Institution",
		"institution_placeholder": "Choose an institution",
		"institution_test_label": "Include test institutions",
		"courses_header": "Add Courses to this account",
		"courses_href_label": "Course copy link",
		"add_course": "Add a course",
		"add_another_course": "Add another course",
		"remove_course": "Remove",
		"create_button_label": "Create",
		"open_course_spreadsheet": "Open course link spreadsheet",
		"invalid_email": "Enter a valid email",
		"invalid_course_link": "Courselink should look like https://knerd.me/c/copy/deadbeef-dead-beef-dead-deadbeefdead",
		"invalid_required": "Required",
		"permission_denied": "You do not have permission to make this change.",
		"save_error": "Something went wrong saving your changes, please refresh the page to see if the changes persisted.",
		"save_success": "User created",
		"email_already_in_use": "This email is already in use by another user"
	},
	"student_saga": {
		"error_retrieving_analytics": "Something went wrong while retrieving analytics. Please try again.",
		"error_mark_done": "Something went wrong while marking this assignment as done. Please try again."
	},
	"assignment_saga": {
		"success_delete_assignment": "{{assignmentType}} {{assignmentName}} was successfully deleted.",
		"error_delete_assignment": "{{assignmentType}} {{assignmentName}} could not be deleted. Please try again.",
		"success_update_assignment": "Your changes have been saved.",
		"error_conflict_update_assignment": "Your changes could not be saved because students have already started working.",
		"error_update_assignment": "{{assignmentType}} {{assignmentName}} could not be updated. Please try again.",
		"error_update_extension": "There was an error saving the extension. Please try again.",
		"some_extension_updates_failed": "Some extensions were not updated. Please try again.",
		"error_past_extension": "There was an error saving the extension -- extensions cannot be set before the current date and time.",
		"error_mark_done": "Something went wrong while marking this assignment as done. Please try again."
	},
	"course_student": {
		"error_getting_assignment_analytics": "Something went wrong while getting the assignment analytics. Please try again.",
		"error_getting_course_analytics": "Something went wrong while getting the course analytics. Please try again.",
		"error_getting_student_analytics": "Something went wrong while getting the student analytics. Please try again."
	},
	"error_boundary": {
		"title": "Ooops! Something went wrong.",
		"message": "Please try refreshing your browser. If the problem persists, feel free to contact us."
	},
	"video_player": {
		"play": "Play",
		"pause": "Pause",
		"mute": "Mute",
		"unmute": "Unmute",
		"time_elapsed": "Time elapsed",
		"seek_slider": "Seek slider",
		"time_remaining": "Time reamining",
		"toggle_captions": "Toggle captions",
		"video_settings": "Video settings",
		"loading": "Loading. Please wait...",
		"fullscreen": "Fullscreen",
		"provider_error": "It looks like we are having trouble connecting you to the video provider.",
		"refresh": "Please try refreshing this page",
		"open_in_tab": "or open the video in a new tab."
	},
	"extensions": {
		"set_extension": "Set a custom extension"
	},
	"feedback": {
		"feedback_label": "Description (required)",
		"incorrect_answer_key": "Incorrect answer key",
		"report": "Report",
		"typo": "Typo",
		"unclear_question": "Unclear question",
		"not_right": "Something's not right...",
		"not_right_sub": "There is an error in the instruction or question.",
		"other": "Other",
		"other_sub": "I have feedback on this question.",
		"looks_broken": "This looks broken...",
		"looks_broken_sub": "A graph/image/equation/video isn't working",
		"cannot_answer": "I cannot enter my answer.",
		"cannot_answer_sub": "A problem is preventing me from entering an answer to this question.",
		"have_idea": "I have an idea!",
		"have_idea_sub": "I have some feedback/suggestions.",
		"description_length": "Please include a comprehensive description.",
		"please_note": "Please Note:",
		"report_warning": "To help us diagnose the issue you’re reporting, a screenshot of this page will be taken and submitted with this feedback.",
		"realtime_support_info": "If you need realtime support, please click the chat icon at the bottom of your screen.",
		"content_feedback": "Feedback",
		"content_feedback_button_aria": "Content Feedback - enter to select issue type",
		"submit_success": "Thank you for your feedback",
		"submit_error_rate_limit": "You have submitted too many feedbacks. Please wait a few minutes and try again.",
		"submit_error_server": "Something went wrong while submitting your feedback. Please try again."
	},
	"content_atom": {
		"generic_error_loading": "Something went wrong while fetching your content. Please try again.",
		"see_another_variation": "See another variation",
		"not_print_friendly": "Not print-friendly",
		"mark_as_correct_for_all": "Mark as correct",
		"remove_tooltip": "Remove this question from the test",
		"undo_mark_as_correct": "Undo override",
		"something_went_wrong": "Something went wrong displaying this content.",
		"tap_reload": "Tap 'RELOAD' below or refresh this page.",
		"refresh": "Refresh this page.",
		"contact_support": "If the problem persists, please contact Knewton Support.",
		"reload_atom_btn": "reload content"
	},
	"connection_error_modal": {
		"title": "Check your connection...",
		"connection_problem": "Please check your network settings to ensure you have a stable\nconnection to Knewton Alta.\n",
		"fatal_solution": "Reload the page and if the problem persists contact Knewton Support.",
		"reload_button": "Reload"
	},
	"orders_verify_email": {
		"hero_text": "Welcome, {{name}}!",
		"body_header": "Verify your email address",
		"body_p0": "We sent an email to {{-email}}.",
		"body_p1": "Please follow instructions in the email to finish creating your account.",
		"body_p2": "Didn't get an email?",
		"body_p2_link": "Send another one"
	},
	"orders_contact": {
		"hero_text": "Welcome, {{name}}!",
		"body_header": "Contact us to finish your account setup",
		"body_p0": "We need a bit more info to get your account properly set up.",
		"body_p1": "Please email knewtonsupport@wiley.com to finish setting up your account.",
		"contact_button": "Contact Us",
		"email_subject": "Request for distributor whitelist",
		"email_body": "Hi, I'd like to request access for my distributor email address: {{email}}"
	},
	"order_confirmed": {
		"title": "Order successful!",
		"check_email_message": "Check your email to view your order details. The access codes you requested will be attached to your email in a CSV and PDF file.",
		"download_message": "You can also download your access codes right now.",
		"download_csv": "DOWNLOAD AS CSV",
		"download_pdf": "DOWNLOAD AS PDF"
	},
	"sequence_preview": {
		"deliver_this_variation": "Deliver this variation of the pooled question."
	},
	"image_atom": {
		"error_1": "Oops. Something went wrong. Please ",
		"error_2": "click here ",
		"error_3": "to refresh this image.",
		"error_for_manager": "Error loading image",
		"loading": "Loading image"
	},
	"external_atom": {
		"error_1": "Error when getting content from {{uri}}.",
		"error_no_url": "URL is improperly configured."
	},
	"learnosity_component": {
		"error_1": "Oops. Something went wrong. Please ",
		"error_2": "refresh ",
		"error_3": "this page. Send us feedback if this error persists.",
		"error_for_manager": "Error loading learnosity",
		"loading": "Loading, please wait"
	},
	"recaptcha_validation": {
		"recaptcha_confirm_not_robot": "To continue, please confirm that you are not a robot."
	},
	"request_access": {
		"success": "Thank you for submitting your request. We will get back to you shortly.",
		"error_request_access": "Something went wrong while submitting your request. Please try again."
	},
	"desmos_atom": {
		"error": "Something went wrong while displaying the Desmos graph. Please refresh the page.",
		"loading": "Loading Desmos graph"
	},
	"alchemie_atom": {
		"error": "Something went wrong while displaying the Alchemie Atom. Please refresh the page.",
		"loading": "Loading Alchemie Widget"
	},
	"lti_section_init": {
		"create_your_section": "Create your section",
		"creating_your_section": "Creating your section...",
		"create": "Create",
		"create_im_sure": "Yes I'm sure, Create",
		"logo_alt": "Knewton Alta logo",
		"create_instructions_part1": "You are about to create a section for your course ",
		"create_instructions_part2": " that runs from ",
		"create_instructions_part3": " to ",
		"old_course_warning_1": "WARNING: Are you sure you want to create a section in this course?",
		"old_course_warning_2": "{{courseName}} started more than eight weeks ago. Did you forget to reimport launch links in your LMS?",
		"new_semester_instructions": "Follow this link if you want to review instructions for setting up a course for a new semester: ",
		"new_semester_link": "Starting a new semester",
		"back_to_my_lms": "Back to my LMS"
	},
	"assessment_already_submitted_modal": {
		"header": "Your test has already been submitted",
		"body_single_attempt": "You cannot change your answers because this test has already been submitted for grading",
		"body_multiple_attempts": "You cannot change your answers because this test attempt has already been submitted for grading",
		"back": "Back to cover page"
	},
	"content_dev": {
		"learnosity_preview": "Learnosity Content Preview",
		"edit_disclaimer": "Note that any edits made to the source are not persisted.",
		"error_message": "Oops. Something went wrong. Please post in #content-team with the issue."
	},
	"datetime": {
		"day": "{{count}} day",
		"day_plural": "{{count}} days",
		"hour": "{{count}} hr",
		"hour_plural": "{{count}} hrs",
		"minute": "{{count}} min",
		"minute_plural": "{{count}} mins"
	},
	"atom_variation": {
		"question": "Question",
		"pool_question_number": "Pool Question {{number}}",
		"question_number": "Question {{number}}",
		"question_weight": "({{weight}}%)",
		"points": "{{count}} point",
		"points_plural": "{{count}} points",
		"score": "{{score}}/{{maxPoints}} points",
		"expand_question": "show question",
		"collapse_question": "collapse question"
	},
	"edit_question_points": {
		"set_points": "Set points",
		"save": "Save",
		"cancel": "Cancel",
		"saving": "Saving",
		"error": "Error, try again",
		"points_with_weight": "({{weight}}%)",
		"edit_question_tooltip": "Click here to edit the points available for this question",
		"invalid_input": "Please enter a whole number between 0-1000",
		"pool_question_warning": "Adjusting points here will also apply to other questions in this pool.",
		"assessment_started": "Students have started this assessment. Please refresh your browser before continuing."
	},
	"save_indicator_status": {
		"saving_changes": "Saving changes",
		"error_message": "Error saving, try again"
	},
	"topic_los": {
		"booster": "Booster"
	},
	"knerdtools": {
		"mark_as_instructor_warning": "This will provide this user free access to any course/section they are an instructor of.\n Are you sure you want to mark this user as an instructor?",
		"add_courses_to_account": "Add Courses to this account",
		"copy_course_link_text": "Course copy link (optional)",
		"invalid_url_error_validation": "Invalid URL format",
		"invalid_course_links_popup": "Error in Course Links"
	},
	"saml": {
		"title": "We're on our way to the Knerd Studio!",
		"details": "By clicking the button below, you consent to sharing your name and email address with Influitive solely for the purpose of account creation.",
		"got_it": "Ok, Got it!"
	},
	"dismissible_alert_box": {
		"dismiss": "dismiss"
	},
	"completion_estimates": {
		"estimated_questions": "Estimated {{low}} - {{high}} questions",
		"goal_completed": "Completed",
		"better_estimated_questions": "Estimated ~{{count}} questions"
	},
	"assignment_activity_details": {
		"activity_details": "Activity Details",
		"back_label": "back to assignment"
	},
	"learning_history_filters": {
		"all": "All ({{ count }})",
		"instruction": "Instruction ({{ count }})",
		"correct": "Correct ({{ count }})",
		"incorrect": "Incorrect ({{ count }})",
		"partial_credit": "Partial Credit ({{ count }})",
		"filter": "Filter",
		"time_spent": "TIME SPENT:",
		"actvity_filter_button_aria_label": "Activity type filter popover button",
		"toggle_filter_group_aria_label": "Correctness and content type filter"
	},
	"learning_history_empty_state": {
		"no_activity": "No activity yet"
	},
	"learning_history_category_filters": {
		"on_target": "Assigned",
		"prereq": "Refresher",
		"practice": "Practice",
		"cancel": "Cancel",
		"apply": "apply",
		"arial_label": "Activity type filter, dropdown menu"
	},
	"objectives_filter": {
		"learning_objective": "Learning Objective",
		"filter_by_objective": "Filter by objective",
		"remove_selection_aria_label": "Remove selection"
	},
	"new_instructor_password_page": {
		"almost_there": "Almost There!",
		"alt_image": "New Instructor Password Reset",
		"create_a_password": "Create a Password",
		"set_password_subheader": "Thanks for jumping in. Please create your password below to see all that Alta has to offer.",
		"set_password": "Set Password"
	},
	"eula_update_banner": {
		"eula_description": "We’re updating our Privacy Policy, effective {{ date }}.",
		"eula_link_text": "Learn more",
		"eula_btn_text": "GOT IT"
	},
	"prereq_banner": {
		"quick_refresher": "Quick Refresher",
		"refresher_text_1": "Looks like this objective is giving you trouble: ",
		"refresher_text_2": ". This Refresher will help you grasp some prerequisite material."
	},
	"instruct_first": {
		"lets_review_heading": "First up, let's review the assignment's learning objectives",
		"instruct_first_message": "Get familiar with this topic by reviewing instruction and answering a couple of questions."
	},
	"evaluation_banner": {
		"switch_to_instructor": "Switch to instructor view",
		"switch_to_student": "Switch to student view",
		"instructor_request_pending": "Instructor request pending",
		"cannot_switch_to_instructor_tooltip": "To join as an instructor, please request a join link",
		"instructor_request_pending_tooltip": "An instructor must approve your request",
		"banner_text": "This course is for evaluation purposes. Have questions?",
		"banner_link": "Visit our Support Center"
	},
	"learning_objective_summary": {
		"learning_objective_summary": "Learning Objective Summary",
		"learning_objective": "Learning Objective"
	},
	"readiness_breakdown": {
		"class_readiness": "Class Readiness",
		"excelling": "Excelling ({{ count }})",
		"on_track": "On Track ({{ count }})",
		"at_risk": "At Risk ({{ count }})",
		"unavailable": "Unavailable ({{ count }})",
		"excelling_text": "Students predicted to score 90 - 100% on the test",
		"on_track_text": "Students predicted to score 70 - 89% on the test",
		"at_risk_text": "Students predicted to score 0 - 69% on the test",
		"unavailable_text": "Not enough student activity to predict Readiness",
		"excelling_label": "Excelling",
		"on_track_label": "On Track",
		"at_risk_label": "At Risk",
		"unavailable_label": "Unavailable",
		"readiness_description": "Readiness is estimated based on your students' performance on related assignments and Review Centers. ",
		"learn_more": "Learn more",
		"empty": "Readiness is not available"
	},
	"class_results_breakdown_summary": {
		"class_results": "Class Results",
		"correct": "Correct ({{ count }})",
		"incorrect": "Incorrect ({{ count }})",
		"no_response": "No response ({{ count }})",
		"partial_credit": "Partial credit ({{ count }})"
	},
	"user_history_answer_modal": {
		"question": "Question"
	},
	"assignment_tag": {
		"offline_assessment_label": "OFFLINE {{ label }}"
	},
	"student_answer_modal": {
		"student": "Student",
		"attempt": "(Attempt {{ attempt }} of {{ fullAttempt }})",
		"no_activity": "No activity",
		"no_activity_details": "This student hasn't submitted any attempts"
	},
	"timepicker": {
		"time_error": "Enter a valid time",
		"past_error": "Can't be in the past",
		"after_error": "Must be after start time ({{ time }})",
		"before_error": "Must be before end time ({{ time }})"
	},
	"datepicker": {
		"date_invalid": "Try mm/dd/yy",
		"date_out_of_bounds": "Enter a date within the course timeframe",
		"past_error": "Can't be in the past",
		"after_course_error": "Can’t be after course end date ({{ date }})",
		"after_section_error": "Can’t be after section end date ({{ date }})",
		"before_course_error": "Can’t be before course start date ({{ date }})",
		"before_section_error": "Can’t be before section start date ({{ date }})",
		"after_error": "Can’t be after end date ({{ date }})",
		"before_error": "Can’t be before start date ({{ date }})"
	},
	"combo_calendar": {
		"after_error": "must be after start time ({{ time }})",
		"before_error": "must be before end time ({{ time }})",
		"update": "update",
		"cancel": "cancel"
	},
	"question_drawer": {
		"question_list": "Question List",
		"close_question_list": "Close Question List",
		"answered": "Answered ({{ count }})",
		"unanswered": "Unanswered ({{ count }})",
		"submit_assessment": "Submit Assessment"
	},
	"question_list": {
		"question_pt": "{{ count }} pt",
		"question_pt_plural": "{{ count }} pts"
	},
	"submit_assessment_modal": {
		"unanswered_title": "{{numUnansweredQuestions}} Unanswered Questions",
		"unanswered_body": "You haven't answered all the questions in this test. Are you sure you want to submit?",
		"unanswered_submit": "Submit Anyway",
		"unanswered_cancel": "View Unanswered",
		"answered_title": "Submit answers",
		"answered_body": "You will not be able to change your answers once they have been submitted for grading.",
		"answered_submit": "submit",
		"answered_cancel": "cancel"
	}
}