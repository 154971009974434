import * as React from 'react'
import { Priority } from './queue'

/**
 * The MathJaxPriorityContext is used to pass mathjax priority values down the component
 * trees in which we render content.
 *
 * This context is provided to the following components with ON_DEMAND:
 *
 *    UserHistoryAnswerModal (viewing one atom in the teach analytics dashboard)
 *    ActivityContentModal (viewing one atom in the learn student history)
 *
 * The DEFAULT is used in the following components:
 *
 *    RawAtom (viewing one atom in assessment builder or course catalog)
 *    Compound (viewing one atom in learn flow, quiz flow)
 *    QuizPrintQuestion (viewing one atom in a printable quiz)
 *
 * The context is consumed by the ContentLoader to determine which Mathjax
 * priority to use for rendering latex.
 */

export const MathJaxPriorityContext = React.createContext(Priority.DEFAULT)

/**
 * Overrides the MathJax Priority of descendant nodes in the tree.
 * ON_DEMAND will be rendered before any DEFAULT items.
 */
export const MathJaxOnDemandPriorityProvider: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <MathJaxPriorityContext.Provider value={Priority.ON_DEMAND}>
      {children}
    </MathJaxPriorityContext.Provider>
  )
}
