import { Reducer } from 'redux'
import { createSelector } from 'reselect'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_GET_ASSIGNMENT,
  LearnFlowLoadAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { API_GET_COURSE } from 'app/frontend/pages/material/learn/learn-actions'
import {
  API_GET_QUIZ_PREVIEW,
  API_IS_BEFORE_DUE,
} from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import * as moment from 'moment'
import * as _ from 'lodash'
import { EnrollmentsByAssignmentId } from 'app/frontend/pages/material/learn/entities/enrollments/enrollments-reducer'
import { REVIEW_VISIBLE_PERIOD } from 'app/frontend/data/quiz-configuration'
import { API_GET_REVIEW_ASSIGNMENTS } from 'app/frontend/pages/material/learn/review-center/review-center-actions'
import * as AssignmentHelper from 'app/frontend/helpers/assignment'
import { AssignmentType } from 'app/typings/commons'

export interface IAssignmentsReducer {
  byId: AssignmentsById
}

export interface DueDateInfo {
  isBeforeDueDateIsLoaded?: boolean
  isBeforeDueDate?: boolean
}

export type AssignmentState = Commons.IPath & DueDateInfo

export type AssignmentsById = {
  [pathId: string]: AssignmentState
}

export const getAssignmentById = (
  state: LearnControllerState,
  assignmentId: string
): Commons.IPath => {
  const assignments = getAllAssignmentsById(state)
  return assignmentId && assignments[assignmentId] ? assignments[assignmentId] : null
}

/**
 * Get every assignment in the store, NOT limited to the current course
 */
export const getAllAssignmentsById = (state: LearnControllerState): AssignmentsById => {
  return state.entities.assignments.byId
}

/**
 * Get all assignments, limited to the current course
 */
export const getAllAssignmentsForCourse = (
  state: LearnControllerState,
  assignmentIds: string[]
): AssignmentsById => getAssignmentsForCourse(state, assignmentIds)

const getAssignmentsForCourse = createSelector(
  getAllAssignmentsById,
  (_state: LearnControllerState, assignmentIds: string[]): string[] => assignmentIds,
  (assignmentsById: AssignmentsById, assignmentIds: string[]): AssignmentsById =>
    _.pickBy(assignmentsById, (a: Commons.IPath) => assignmentIds.includes(a.id))
)

export const isAssignmentAdaptive = (
  state: LearnControllerState,
  assignmentId?: string
): boolean => {
  const assignment = getAssignmentById(state, assignmentId)
  return (
    (assignment &&
      (assignment.type === AssignmentType.ADAPTIVE || assignment.type === AssignmentType.REVIEW)) ||
    false
  )
}

export const checkIfBooster = (
  activeSection: Commons.IRetailClass,
  assignmentId: string
): boolean => {
  return !!(
    activeSection &&
    activeSection.boosters &&
    activeSection.boosters.some(booster => booster.id === assignmentId)
  )
}

/**
 * Get a test that should be used in the course cover banner
 *
 * A test is visible if students should work on either the
 * review center or the actual test
 */
export function getNextVisibleTest(
  assignments: AssignmentsById,
  enrollments: EnrollmentsByAssignmentId,
  assignmentIds: string[]
): Commons.IPath | undefined {
  const cutoff = moment().add(REVIEW_VISIBLE_PERIOD)
  const now = moment()

  // Find the first occurring assessment that starts within 14 days and is not complete
  const tests = _.filter(assignments, asgn => {
    const isAssessment = AssignmentHelper.isAssessment(asgn)
    if (
      isAssessment &&
      assignmentIds.includes(asgn.id) &&
      // But we must not be passed the due date
      moment().isBefore(asgn.endsAt) &&
      // Must not be printable
      !asgn.quizConfiguration.printable
    ) {
      const afterCutoff = asgn.quizConfiguration.reviewCenterEnabled
        ? cutoff.isAfter(asgn.startsAt)
        : now.isAfter(asgn.startsAt)
      if (afterCutoff) {
        const enrollment = enrollments[asgn.id]
        return !enrollment || !(enrollment.completed || enrollment.markedDone)
      }
    }
    return false
  })

  return _.minBy(tests, test => test.startsAt) || undefined
}

const reducer = (
  state: Partial<IAssignmentsReducer> = { byId: {} },
  action: LearnFlowLoadAction & ApiSuccessAction
): Partial<IAssignmentsReducer> => {
  if (!action) {
    return state
  }

  let assignmentId

  switch (action.type) {
    case API_GET_COURSE.SUCCESS:
      const { assignmentsById } = action.response

      return {
        byId: {
          ...state.byId,
          ...assignmentsById,
        },
      }
    case API_GET_ASSIGNMENT.SUCCESS:
      const { assignment } = action.response

      return {
        byId: {
          ...state.byId,
          [assignment.id]: assignment,
        },
      }
    case API_GET_QUIZ_PREVIEW.SUCCESS:
      const { info } = action.response

      return {
        byId: {
          ...state.byId,
          [info.id]: info,
        },
      }
    case API_IS_BEFORE_DUE.INIT:
      assignmentId = action.args[0]
      return {
        byId: {
          ...state.byId,
          [assignmentId]: {
            ...state.byId[assignmentId],
            isBeforeDueDateIsLoaded: false,
          },
        },
      }
    case API_IS_BEFORE_DUE.SUCCESS:
      assignmentId = action.args[0]
      const isBeforeDueDate = action.response.withinDueDate

      return {
        byId: {
          ...state.byId,
          [assignmentId]: {
            ...state.byId[assignmentId],
            isBeforeDueDate,
            isBeforeDueDateIsLoaded: true,
          },
        },
      }
    case API_GET_REVIEW_ASSIGNMENTS.SUCCESS:
      const { reviewAssignments } = action.response

      return {
        byId: {
          ...state.byId,
          ..._.keyBy(reviewAssignments, 'id'),
        },
      }
    default:
      return state
  }
}

export default reducer as Reducer<IAssignmentsReducer>
