import { put, all, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as _ from 'lodash'
import { screenshot } from 'app/frontend/helpers/screenshot'
import { takeFirst } from 'app/frontend/helpers/effects'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import type { ModalOptions } from './types'
import { CONTENT_FEEDBACK_MODAL } from './feedback-modal'
import {
  ShowContentFeedbackModalAction,
  SHOW_CONTENT_FEEDBACK_MODAL,
} from './feedback-modal-actions'

/**
 * Opens the Content Feedback Modal
 *   - Takes a screenshot prior to opening.
 */
export function* takeScreenshotAndOpenModal(action: ShowContentFeedbackModalAction): SagaIterator {
  let image: string | undefined
  try {
    image = yield call(screenshot, document.body)
  } catch (error) {
    console.error('Failed to take content feedback screenshot', {
      error,
      internalIssueId: 'CE-3469',
    })
  }
  const options: ModalOptions = {
    ...action.options,
    screenShot: image,
  }
  yield put(showModal(CONTENT_FEEDBACK_MODAL, options))
}

export default function* contentFeedbackModalSaga(): SagaIterator {
  yield all([
    /**
     * If a user clicks a bunch of times, just process the first one so the modal doesn't just
     * keep getting reopened over itself.
     */
    takeFirst(SHOW_CONTENT_FEEDBACK_MODAL, takeScreenshotAndOpenModal),
  ])
}
