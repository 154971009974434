import { showModal, ShowModalAction } from 'app/frontend/components/material/modal/modal-actions'
import { CONNECTION_ERROR_MODAL, ConnectionErrorModalOptions } from './connection-error-modal'

/**
 * Thin wrapper around showModal(CONNECTION_ERROR_MODAL)
 */
export const showConnectionErrorModal = (
  options?: ConnectionErrorModalOptions
): ShowModalAction => {
  return showModal(CONNECTION_ERROR_MODAL, options || {})
}
