/**
 * Build the actual URL used on Iframes or
 * URL links on buttons. We convert the URL
 * to a special link to authenticate to Wiley
 * servers if its a Wiley link.
 *
 * @param hostUrl the Host URL for Knewton Brackend.
 * @param url the url we will wrap.
 */
export const buildUrl = (hostUrl: string, url: string) => {
  return hostUrl + '/content/external?url=' + encodeURIComponent(url)
}
