import type { ApolloError } from '@apollo/client'
import compose from 'app/frontend/helpers/compose'

export type LoadingAndError = {
  loading?: boolean
  error?: ApolloError
}

type Props = {
  [key: string]: LoadingAndError
}

export type RenderProps<P> = P & LoadingAndError

/**
 * Helper function that looks through the results of
 * an Adopt composition of Apollo queries and aggregates
 * the loading and error states.
 * - loading is true if any of the results has a loading value of true
 * - error is the ApolloError of the first result that has an error
 */
export function mapLoadingAndErrorToProps<P extends Props>(args: P): RenderProps<P> {
  const keys = Object.keys(args)
  const loading = keys.some(k => args[k] && args[k].loading)
  const errorKey = keys.find(k => args[k] && !!args[k].error)
  const error = errorKey && args[errorKey].error
  return Object.assign({}, args, { loading, error })
}

/**
 * HoF for Adopt composed Apollo queries that passes the
 * calculated loading and error states to the Adopt mapProps.
 * const Component = adopt(mapperOfQueries, withLoadingAndError(mapProps))
 */
export function withLoadingAndError<P extends Props, T>(
  mapProps: (args: RenderProps<P>) => T
): (props: P) => T {
  return compose(mapProps, mapLoadingAndErrorToProps)
}
