import * as React from 'react'
import * as classnames from 'classnames'
import { Box, BoxProps } from 'app/frontend/components/material/box'
import * as styles from './page-hero.css'

interface Props extends BoxProps {
  classNameInner?: string
}

/**
 * Page component for displaying header content. Complements PageBody.
 */
export const PageHero: React.FunctionComponent<Props> = ({
  children,
  classNameInner,
  ...otherProps
}) => (
  <Box
    full="horizontal"
    background="dark"
    direction="row"
    justify="center"
    pad={{ top: 'large', right: 'medium', left: 'medium' }}
    {...otherProps}
  >
    <Box full="page" flex={true} className={classnames(styles.innerBox, classNameInner)}>
      {children}
    </Box>
  </Box>
)

PageHero.displayName = 'PageHero'

export default PageHero
