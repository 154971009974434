import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Heading } from 'app/frontend/components/material/heading'
import { tns } from 'app/frontend/helpers/translations/i18n'
const t = tns('teach:assessment_questions')

export const CustomQuestionTitleBar: React.FunctionComponent = () => {

  return (
    <Box direction="row" full={'page'} justify="between">
      <Heading
        tag={'h6'}
        size={'h6'}
        weight={'semibold'}
        margin={{ vertical: 'small' }}
        data-test={'custom-heading'}
      >
        {t('custom_questions')}
      </Heading>
    </Box>
  )
}

CustomQuestionTitleBar.displayName = 'CustomQuestionTitleBar'
