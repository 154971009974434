import { AllCourseworkActions } from './all-coursework-actions'
import {
  AnalyticsCategory,
  CompletionType,
  AllCourseworkSortColumns,
  IAnalyticsSortOrder,
  SortOrder,
} from 'app/frontend/data/analytics-data'

export const getAllCourseworkAnalytics = (state): AllCourseworkReducerState =>
  state.ui.analytics.allCoursework

export const getCourseworkTypeFilter = (state): AnalyticsCategory =>
  getAllCourseworkAnalytics(state).courseworkTypeFilter

export const getAssignmentCompletionFilter = (state): CompletionType =>
  getAllCourseworkAnalytics(state).completionFilter

export const getAssignmentSortOrder = (state): IAnalyticsSortOrder =>
  getAllCourseworkAnalytics(state).sortOrder

export const getAssignmentSortColumn = (state): AllCourseworkSortColumns =>
  getAllCourseworkAnalytics(state).sortColumn

const defaultState = {
  courseworkTypeFilter: AnalyticsCategory.ADAPTIVE_ASSIGNMENT,
  sortOrder: SortOrder.ASC,
  sortColumn: AllCourseworkSortColumns.DUE,
}

export type AllCourseworkReducerState = {
  courseworkTypeFilter: AnalyticsCategory
  completionFilter?: CompletionType
  sortOrder: IAnalyticsSortOrder
  sortColumn: AllCourseworkSortColumns
}

export default (state: AllCourseworkReducerState = defaultState, action) => {
  switch (action.type) {
    case AllCourseworkActions.SET_COURSEWORK_TYPE_FILTER:
      return {
        ...state,
        courseworkTypeFilter: action.courseworkTypeFilter,
      }
    case AllCourseworkActions.SET_ASSIGNMENT_COMPLETION_TYPE:
      return {
        ...state,
        completionFilter: action.completionFilter,
      }
    case AllCourseworkActions.SORT_COURSEWORK_ANALYTICS:
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortOrder:
          state.sortColumn === action.sortColumn && action.sortOrder === SortOrder.ASC
            ? SortOrder.DESC
            : SortOrder.ASC,
      }
    default:
      return state
  }
}
