import * as React from 'react'
import { Box, BoxProps } from 'app/frontend/components/material/box'
import * as styles from 'app/frontend/components/material/alert-box/alert-box.css'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('dismissible_alert_box')

interface Props extends BoxProps {
  customStyle?: string
  onDismiss?: () => void
  label?: string
}

export const AlertBox: React.FunctionComponent<Props> = ({
  children,
  customStyle,
  onDismiss,
  label = t('dismiss'),
  ...boxProps
}) => {
  return (
    <Box
      className={customStyle ?? styles.warningBox}
      data-test="alertBox"
      full="page"
      {...boxProps}
    >
      {children}
      {onDismiss && (
        <Box alignItems="end" full="horizontal" margin={{ bottom: 'medium' }}>
          <ButtonMaterial
            label={label}
            theme="bordered"
            className={styles.button}
            onClick={onDismiss}
            data-bi="dismiss-warning-button"
          />
        </Box>
      )}
    </Box>
  )
}

AlertBox.displayName = 'AlertBox'
