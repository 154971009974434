import { Reducer } from 'redux'
import { CourseworkActions, SelectAssignmentUITabAction } from './coursework-actions'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { createSelector, Selector } from 'reselect'
import {
  LearningObjectiveTableColumns,
  OverallStatusTableColumns,
  SortOrder,
} from 'app/frontend/data/analytics-data'

export enum AssignmentUITab {
  OverallStatus = 'OVERALL_STATUS',
  IndividualLOStatus = 'INDIVIDUAL_LO_STATUS',
}

export interface AssignmentUIState {
  selectedTab: AssignmentUITab
  assignmentId: string
  selectedLearningObjectiveId: string
  selectedLearningObjectiveStatus: string
  learningObjectiveTableColumn: LearningObjectiveTableColumns
  learningObjectiveTableOrder: SortOrder
  overallStatusTableSortColumn: OverallStatusTableColumns
  overallStatusTableSortOrder: SortOrder
  selectedStudents: string[]
  selectedAssignmentStatus: Analytics.AssignmentStatus
}

export type SelectedLOStatus = {
  learningObjectiveId: string
  progressStatus: string
}

export type LearningObjectiveTableSort = {
  column: LearningObjectiveTableColumns
  order: SortOrder
}

export type OverallStatusTableSort = {
  sortColumn: OverallStatusTableColumns
  sortOrder: SortOrder
}

const getAssignmentUIStatus = (state: TeachControllerState): AssignmentUIState =>
  state.ui.course.coursework.assignment

export const getAssignmentUISelectedTab = (state: TeachControllerState): AssignmentUITab => {
  return getAssignmentUIStatus(state).selectedTab
}

export const getActiveAssignmentId: Selector<TeachControllerState, string> = createSelector<
  TeachControllerState,
  string,
  AssignmentUIState
>(getAssignmentUIStatus, (assignmentUIState: AssignmentUIState) => assignmentUIState.assignmentId)

export const getAssignmentUISelectedLOStatus: Selector<
  TeachControllerState,
  SelectedLOStatus
> = createSelector<TeachControllerState, SelectedLOStatus, AssignmentUIState>(
  getAssignmentUIStatus,
  (assignmentUIState: AssignmentUIState) => ({
    learningObjectiveId: assignmentUIState.selectedLearningObjectiveId,
    progressStatus: assignmentUIState.selectedLearningObjectiveStatus,
  })
)

export const getLearningObjectiveTableSort: Selector<
  TeachControllerState,
  LearningObjectiveTableSort
> = createSelector<TeachControllerState, LearningObjectiveTableSort, AssignmentUIState>(
  getAssignmentUIStatus,
  (assignmentUIState: AssignmentUIState) => ({
    column: assignmentUIState.learningObjectiveTableColumn,
    order: assignmentUIState.learningObjectiveTableOrder,
  })
)

export const getOverallStatusTableSort: Selector<
  TeachControllerState,
  OverallStatusTableSort
> = createSelector<TeachControllerState, OverallStatusTableSort, AssignmentUIState>(
  getAssignmentUIStatus,
  (assignmentUIState: AssignmentUIState) => ({
    sortColumn: assignmentUIState.overallStatusTableSortColumn,
    sortOrder: assignmentUIState.overallStatusTableSortOrder,
  })
)

export const getSelectedStudents = (state: TeachControllerState): string[] =>
  state.ui.course.coursework.assignment.selectedStudents

export const getSelectedAssignmentStatus = (
  state: TeachControllerState
): Analytics.AssignmentStatus => getAssignmentUIStatus(state).selectedAssignmentStatus

const defaultState: AssignmentUIState = {
  selectedTab: AssignmentUITab.OverallStatus,
  assignmentId: null,
  selectedLearningObjectiveId: null,
  selectedLearningObjectiveStatus: null,
  learningObjectiveTableColumn: LearningObjectiveTableColumns.LastName,
  learningObjectiveTableOrder: SortOrder.ASC,
  overallStatusTableSortColumn: OverallStatusTableColumns.LastName,
  overallStatusTableSortOrder: SortOrder.ASC,
  selectedStudents: [],
  selectedAssignmentStatus: null,
}

export function reducer(state: AssignmentUIState = defaultState, action) {
  switch (action.type) {
    case CourseworkActions.SelectAssignmentUITab:
      const selectAssignmentUITabAction = action as SelectAssignmentUITabAction
      return {
        ...state,
        selectedTab: selectAssignmentUITabAction.tab,
        selectedStudents: [],
        overallStatusTableSortColumn: OverallStatusTableColumns.LastName,
        overallStatusTableSortOrder: SortOrder.ASC,
      }
    case CourseworkActions.UpdateSelectedStudents:
      return {
        ...state,
        selectedStudents: action.selectedStudents,
      }
    case CourseworkActions.SetActiveAssignment:
      return {
        ...state,
        assignmentId: action.assignmentId,
      }
    case CourseworkActions.SelectLearningObjectiveStatus:
      return {
        ...state,
        selectedLearningObjectiveId: action.learningObjectiveId,
        selectedLearningObjectiveStatus: action.progressStatus,
        selectedStudents: [],
      }
    case CourseworkActions.SortLearningObjectiveTable:
      return {
        ...state,
        learningObjectiveTableColumn: action.column,
        learningObjectiveTableOrder: action.sortOrder,
      }
    case CourseworkActions.SelectAssignmentStatus:
      return {
        ...state,
        selectedStudents: [],
        selectedAssignmentStatus: action.assignmentStatus,
      }
    case CourseworkActions.SortOverallStatusTable:
      return {
        ...state,
        overallStatusTableSortColumn: action.column,
        overallStatusTableSortOrder: action.sortOrder,
      }
  }
  return state
}

export default reducer as Reducer<AssignmentUIState>
