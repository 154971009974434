import * as React from 'react'
import { AssessmentLoSelectionModalRenderProps } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import { Heading } from 'app/frontend/components/material/heading'
import { AssessmentTableOfContentsLoader } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-table-of-contents-loader'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('teach:assessment_lo_selection_modal')

/**
 * Body of the assessment LO selection modal
 */
export const AssessmentLoSelectionModalBody: React.FunctionComponent<AssessmentLoSelectionModalRenderProps> = ({
  options,
}) => (
  <ModalBody>
    <Heading tag="h2">{t('title')}</Heading>
    <AssessmentTableOfContentsLoader {...options} />
  </ModalBody>
)
AssessmentLoSelectionModalBody.displayName = 'AssessmentLoSelectionModalBody'
