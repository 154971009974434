import { createSelector } from 'reselect'
import {
  API_DELETE_COURSE,
  TeachCoursesAction,
} from 'app/frontend/pages/teach/entities/courses/course-actions'
import { getCoursesById } from 'app/frontend/pages/teach/entities/courses/courses-reducer'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'

export const getActiveCourseId = (state: TeachControllerState): string =>
  state.ui.course.activeCourse.courseId

export const getActiveCourse = createSelector(
  getActiveCourseId,
  getCoursesById,
  (courseId: string, coursesById: { [courseId: string]: Commons.IRetailClassLean }) => {
    return coursesById[courseId]
  }
)

const defaultState = {
  courseId: null,
  assignmentIds: [],
}

export type ActiveCourseState = {
  courseId: string
}

export default (state: ActiveCourseState = defaultState, action) => {
  switch (action.type) {
    case TeachCoursesAction.OPEN_COURSE:
      return {
        courseId: action.course.id,
        assignmentIds: action.course.assignmentIds.concat(action.course.boosterIds),
      }
    case API_DELETE_COURSE.SUCCESS:
      // If the course that got deleted is the active tab
      if (state.courseId === action.args[0]) {
        return defaultState
      }
      return state
    default:
      return state
  }
}
