export type UPDATE_MODE = 'UPDATE_MODE'
export const UPDATE_MODE: UPDATE_MODE = 'UPDATE_MODE'

/**
 * The different modes are as follows:
 * active: normal learn flow
 * cover: assignment cover page
 * custom: sequence preview
 * instruction: when student clicks 'View Related Instruction' from cover page
 * history: when student pushes back button to navigate to past content
 * practice: review mode after student finishes assignment
 * preview: instructor preview of an assignment
 */
export type MODES =
  | 'active'
  | 'cover'
  | 'custom'
  | 'instruction'
  | 'history'
  | 'practice'
  | 'preview'
  | 'quiz'
  | 'quizPreview'

export type UpdateModeAction = {
  type: UPDATE_MODE
  mode: MODES
}

export const updateMode = (mode: MODES) => {
  return {
    type: UPDATE_MODE,
    mode,
  }
}
