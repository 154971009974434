import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import * as ASSIGNMENTS_FOR_SECTION from 'app/frontend/compositions/connected/assignments-for-section/assignments-for-section.gql'
import * as ASSIGNMENTS_FOR_COURSE from 'app/frontend/pages/material/teach/compositions/connected/assignments-for-course/assignments-for-course.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

type Data = GQL.GetAssignmentsForSection.Query & GQL.GetAssignmentsForCourse.Query
type Variables = GQL.GetAssignmentsForSection.Variables | GQL.GetAssignmentsForCourse.Variables
type Assessment = GQL.GetAssignmentsForSection.Path | GQL.GetAssignmentsForCourse.Results
export type UseAssessmentsForCourseOrSectionResults = RenderProps<{
  assessments?: Assessment[]
}>

export const useAssessmentsForCourseOrSection = (
  parentEntityId: string,
  parentEntityType: Commons.ParentEntityType,
  // to resolve concurrency problem, we need to make this query as `cache-and-network` to sync
  // with the server, if we don't apply this here and if another instructor add / remove
  // assessment, those will not be in sync.
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
): UseAssessmentsForCourseOrSectionResults => {
  const query = parentEntityType === 'COURSE' ? ASSIGNMENTS_FOR_COURSE : ASSIGNMENTS_FOR_SECTION
  const variables: Variables =
    parentEntityType === 'COURSE'
      ? {
          courseId: parentEntityId,
          getAll: true,
        }
      : {
          sectionId: parentEntityId,
          getAll: true,
        }

  const { loading, error, data } = useQuery<Data, Variables>(query, {
    variables,
    fetchPolicy,
    nextFetchPolicy: 'cache-first', // TODO ALPACA-759
  })

  let assessments: Assessment[]
  if (data as Data) {
    const results: Assessment[] =
      parentEntityType === 'COURSE'
        ? data.assignmentsForCourse?.results
        : data.assignmentsForSection?.results.map(r => r.path)
    assessments = results.filter(path => path.type === 'QUIZ')
  }

  return {
    loading,
    error,
    assessments,
  }
}
