import * as React from 'react'
import { makeStyles } from '@mui/styles'
import {FormControlLabelProps, FormControlLabel, Typography} from '@mui/material'
import * as classnames from 'classnames'

export const useFormControlStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: '.8rem 0',
    margin: 0,
    display: 'flex',
  },
  checkboxLabel: {
    paddingLeft: 0,
    color: '#000000',
    display: 'inline-block',
    fontSize: '.875rem',
    lineHeight: '1.125rem',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
  },
  switchRoot: {
    margin: 0,
  },
  switchLabel: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: theme.palette?.grey[600],
  },
  radioGroupLabel: {
    ...theme.typography?.body2,
    color: theme.palette?.grey[600],
  },
}))

export const FormControlLabelCheckbox: React.FunctionComponent<FormControlLabelProps> = ({
  classes,
  ...props
}) => {
  const styles = useFormControlStyles()
  return (
    <FormControlLabel
      {...props}
      classes={{
        ...classes,
        root: classnames(styles.checkboxRoot, classes?.root),
        label: classnames(styles.checkboxLabel, classes?.label),
      }}
    />
  )
}

FormControlLabelCheckbox.displayName = 'FormControlLabelCheckbox'

export const FormControlLabelSwitch: React.FunctionComponent<FormControlLabelProps> = ({
  classes,
  ...props
}) => {
  const styles = useFormControlStyles()
  return (
    <FormControlLabel
      {...props}
      classes={{
        ...classes,
        root: classnames(styles.switchRoot, classes?.root),
        label: classnames(styles.switchLabel, classes?.label),
      }}
    />
  )
}

FormControlLabelSwitch.displayName = 'FormControlLabelSwitch'

export const FormControlLabelRadioGroup: React.FunctionComponent<FormControlLabelProps> = ({
  classes,
  ...props
}) => {
  const styles = useFormControlStyles()
  return (
    <FormControlLabel
      {...props}
      label={<Typography component="div">{props.label}</Typography>}
      classes={{
        ...classes,
        label: classnames(styles.radioGroupLabel, classes?.label),
      }}
    />
  )
}

FormControlLabelRadioGroup.displayName = 'FormControlLabelRadioGroup'
