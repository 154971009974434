/**
 * Enum that represent available view options
 */
export enum QuestionViewPreferencesOptionsView {
  BY_ORDER = 'BY_ORDER',
  BY_OBJECTIVE = 'BY_OBJECTIVE',
}

/**
 * Enum that represent available density options
 */
export enum QuestionViewPreferencesOptionsViewDensity {
  EXPANDED = 'EXPANDED',
  COLLAPSED = 'COLLAPSED',
}
/**
 * Enum that represent whether selected preferences applied or not
 */
export enum QuestionViewPreferencesStatus {
  APPLIED = 'APPLIED',
  NOT_APPLIED = 'NOT_APPLIED',
}

/**
 * Swaps the elements for given indexes.
 * Accept any type of array.
 * @param list - The list that required swap
 * @param fromIndex - Source index
 * @param toIndex - Destination index
 */
export const swapList = (list: any[], fromIndex: number, toIndex: number) => {
  const orderedList = Array.from(list)
  orderedList[toIndex] = list[fromIndex]
  orderedList[fromIndex] = list[toIndex]
  return orderedList
}
