import * as React from 'react'
import { createModal } from 'app/frontend/components/material/modal'
import { ModalOptions } from './types'
import FeedbackModalInner from './feedback-modal-inner'

export const CONTENT_FEEDBACK_MODAL = 'CONTENT_FEEDBACK_MODAL'

export const ModalComponent = createModal<ModalOptions>()

const FeedbackModal: React.FunctionComponent<{}> = () => {
  return (
    <ModalComponent name={CONTENT_FEEDBACK_MODAL} dataBi="content-feedback-modal" fullScreen={true}>
      {({ options, hideModal }) => <FeedbackModalInner {...options} hideModal={hideModal} />}
    </ModalComponent>
  )
}

export default FeedbackModal
