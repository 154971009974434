import { makeApiActions } from 'app/frontend/api/api-redux'

export const API_EDIT_ACCOUNT = makeApiActions('API_EDIT_ACCOUNT')
export type API_EDIT_ACCOUNT = 'API_EDIT_ACCOUNT'
export const API_DELETE_ACCOUNT = makeApiActions('API_DELETE_ACCOUNT')
export type API_DELETE_ACCOUNT = 'API_DELETE_ACCOUNT'

export const SUBMIT_EDIT_ACCOUNT_FORM = 'SUBMIT_EDIT_ACCOUNT_FORM'
export type SUBMIT_EDIT_ACCOUNT_FORM = 'SUBMIT_EDIT_ACCOUNT_FORM'
export const SUBMIT_EDIT_EMAIL_FORM = 'SUBMIT_EDIT_EMAIL_FORM'
export type SUBMIT_EDIT_EMAIL_FORM = 'SUBMIT_EDIT_EMAIL_FORM'
export const SUBMIT_EDIT_ACCOUNT_PASSWORD_FORM = 'SUBMIT_EDIT_ACCOUNT_PASSWORD_FORM'
export type SUBMIT_EDIT_ACCOUNT_PASSWORD_FORM = 'SUBMIT_EDIT_ACCOUNT_PASSWORD_FORM'
export const SUBMIT_EDIT_PERSONAL_INFO_FORM = 'SUBMIT_EDIT_PERSONAL_INFO_FORM'
export type SUBMIT_EDIT_PERSONAL_INFO_FORM = 'SUBMIT_EDIT_PERSONAL_INFO_FORM'
export const SUBMIT_EDIT_NOTIFICATIONS_FORM = 'SUBMIT_EDIT_NOTIFICATIONS_FORM'
export type SUBMIT_EDIT_NOTIFICATIONS_FORM = 'SUBMIT_EDIT_NOTIFICATIONS_FORM'
export const TOGGLE_ACCESSIBILITY = 'TOGGLE_ACCESSIBILITY'
export type TOGGLE_ACCESSIBILITY = 'TOGGLE_ACCESSIBILITY'
export const SUBMIT_DELETE_ACCOUNT = 'SUBMIT_DELETE_ACCOUNT'
export type SUBMIT_DELETE_ACCOUNT = 'SUBMIT_DELETE_ACCOUNT'

export type EditEmailAction = {
  type: SUBMIT_EDIT_EMAIL_FORM
  data
}

export type EditAccountAction = {
  type: SUBMIT_EDIT_ACCOUNT_FORM
  data
}

export type DeleteAccountAction = {
  type: SUBMIT_DELETE_ACCOUNT
  data
}

export type ToggleCourseNotificationsAction = {
  type: SUBMIT_EDIT_NOTIFICATIONS_FORM
  data
}

export type ToggleAccessibilityAction = {
  type: TOGGLE_ACCESSIBILITY
  enabled: boolean
}

export const submitEditEmailForm = (data): EditEmailAction => ({
  type: SUBMIT_EDIT_EMAIL_FORM,
  data,
})

export const submitEditAccountForm = (data): EditAccountAction => ({
  type: SUBMIT_EDIT_ACCOUNT_FORM,
  data,
})

export const submitDeleteAccountForm = (data): DeleteAccountAction => ({
  type: SUBMIT_DELETE_ACCOUNT,
  data,
})

export const toggleCourseDigestEmails = (data): ToggleCourseNotificationsAction => ({
  type: SUBMIT_EDIT_NOTIFICATIONS_FORM,
  data,
})

export const toggleAccessibility = (enabled: boolean): ToggleAccessibilityAction => ({
  type: TOGGLE_ACCESSIBILITY,
  enabled,
})
