import * as React from 'react'
import { AtomQueryResults } from 'app/frontend/compositions/connected/atom-query-results'
import { AtomVariationLoading } from 'app/frontend/components/loading/atom-variation-loading'
import {
  AtomVariationButtonActionsType,
  getMaxNumberOfVariations,
  isPoolQuestion,
} from 'app/frontend/helpers/atom-variation'
import { ParentEntityType, ResultOverrideType } from 'app/typings/commons'
import { Box } from 'app/frontend/components/material/box'
import { LearningObjectivesToAssignmentsMap } from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import { LearningObjectiveBar } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/learning-objective-bar/learning-objective-bar'
import { CustomQuestionTitleBar } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/custom-question-lo-bar/custom-question-title-bar'
import { isMarkedCorrect, isMarkedIncorrect } from 'app/frontend/helpers/compound'
import { useAtomVariation } from 'app/frontend/compositions/connected/get-atom-variation'
import { AtomVariationHeader } from './atom-variation-header'

export interface Props {
  assessmentId?: string
  sequenceId: string
  pathSequenceVariationId?: string
  sequenceAtomId: string
  sequenceAtomVariationIds: string[]
  includeExplanationAndAnswers: boolean
  parentEntityType?: ParentEntityType
  sequenceVariationsUsed?: number
  actionBar?: React.ComponentType<AtomVariationButtonActionsType>
  renderActionBar?: (props: AtomVariationButtonActionsType) => React.ReactNode
  isAssessmentOffline?: boolean
  isRepeated?: boolean
  isAssessmentLdb?: boolean
  override?: ResultOverrideType
  overrideValue?: number
  customHeading?: JSX.Element
  displayLearningObjectiveBar?: boolean
  isDraggable?: boolean
  isSequenceUsed?: boolean
  learningObjectives?: Commons.IPathLearningObjectiveLeanEntity[]
  learningObjective?: Partial<Content.ILearningObjective>
  chapterName?: string
  topicName?: string
  assignmentsByLearningObjectiveId?: LearningObjectivesToAssignmentsMap
  fromObjectivesModal?: boolean
  setCustomItemVariationLength?: (e: number) => void
}

export const AtomVariation: React.FunctionComponent<Props> = props => {
  const {
    assessmentId,
    sequenceId,
    pathSequenceVariationId,
    sequenceAtomId,
    includeExplanationAndAnswers,
    parentEntityType,
    sequenceVariationsUsed,
    actionBar = null,
    isAssessmentOffline,
    isRepeated,
    isAssessmentLdb,
    override,
    overrideValue,
    customHeading,
    displayLearningObjectiveBar,
    isDraggable,
    isSequenceUsed,
    learningObjectives,
    learningObjective,
    chapterName,
    topicName,
    assignmentsByLearningObjectiveId,
    fromObjectivesModal,
    sequenceAtomVariationIds,
    setCustomItemVariationLength,
    renderActionBar,
  } = props
  const [variationIndex, setVariationIndex] = React.useState(0)
  const [isVariationContentChanged, setIsVariationContentChanged] = React.useState(false)
  const onVariationContentChanged = (isChanged: boolean) => {
    setIsVariationContentChanged(() => isChanged)
  }
  const onRefreshAtomVariationContent = () => {
    // Reset variationIndex if all variations have been exhausted
    // Otherwise increament it
    setVariationIndex(prevState => {
      if (prevState === props.sequenceAtomVariationIds.length - 1) {
        return 0
      }
      return prevState + 1
    })
  }

  const variationId = sequenceAtomVariationIds[variationIndex]
  const ActionBarComp = actionBar
  const maxNumberVariations = getMaxNumberOfVariations(sequenceAtomVariationIds)
  const { loading, error, atom } = useAtomVariation(sequenceId, sequenceAtomId, variationId)

  const [heading, setHeading] = React.useState({
    ...customHeading,
    props: {
      ...customHeading?.props,
      questionVariationNumber: variationIndex + 1,
      isPoolQuestion: atom && atom.isCustomAssessmentItem && sequenceAtomVariationIds.length > 1,
    },
  })

  React.useEffect(() => {
    setHeading(prevState => ({
      ...prevState,
      props: {
        ...prevState?.props,
        questionVariationNumber: variationIndex + 1,
        isPoolQuestion: atom && atom.isCustomAssessmentItem && sequenceAtomVariationIds.length > 1,
      },
    }))
    // This comes from the AssessmentQuestionsWrapper component and was used to display
    // the "This assessment contains questions that may need to be reviewed" warning message.
    setCustomItemVariationLength?.(sequenceAtomVariationIds.length)
  }, [variationIndex, sequenceAtomVariationIds, atom])

  return (
    <>
      <AtomVariationHeader
        isPrintFriendly={atom && atom.isPrintFriendly}
        parentEntityType={parentEntityType}
        isLdbFriendly={atom && atom.isLdbFriendly}
        isPool={
          atom && atom.isCustomAssessmentItem
            ? sequenceAtomVariationIds.length > 1
            : isPoolQuestion(atom as Content.IAtom)
        }
        isAssessmentOffline={isAssessmentOffline}
        isRepeated={isRepeated}
        isAssessmentLdb={isAssessmentLdb}
        markedCorrect={isMarkedCorrect(override, overrideValue)}
        markedIncorrect={isMarkedIncorrect(override, overrideValue)}
        removed={override === ResultOverrideType.REMOVED_FROM_ASSESSMENT}
        customHeading={atom && atom.isCustomAssessmentItem ? heading : customHeading}
        isDraggable={isDraggable}
        isSequenceUsed={isSequenceUsed}
      />
      {displayLearningObjectiveBar && (
        <Box
          direction={'row'}
          separator={'horizontal'}
          pad={{ left: 'medium', right: 'medium' }}
          margin={{ top: 'small' }}
        >
          {learningObjective ? (
            <LearningObjectiveBar
              assessmentId={assessmentId}
              learningObjectiveId={learningObjective.id}
              learningObjective={learningObjective}
              chapterName={chapterName}
              topicName={topicName}
              pathLearningObjectives={learningObjectives}
              assignmentsByLearningObjectiveId={assignmentsByLearningObjectiveId}
              size="small"
              weight="semibold"
            />
          ) : (
            <CustomQuestionTitleBar />
          )}
        </Box>
      )}
      <AtomQueryResults
        atom={atom}
        loading={loading}
        error={error}
        loader={<AtomVariationLoading />}
        hideCorrectAnswers={!includeExplanationAndAnswers}
        hideExplanation={!includeExplanationAndAnswers}
        hideChoiceExplanation={true}
        includeAttribution={true}
        isQuiz={true}
        isVariationContentChanged={isVariationContentChanged}
      />
      {actionBar && (
        <ActionBarComp
          fromObjectivesModal={fromObjectivesModal}
          parentEntityType={parentEntityType}
          atom={atom as Content.IAtom}
          assessmentId={assessmentId}
          sequenceId={sequenceId}
          pathSequenceVariationId={pathSequenceVariationId}
          atomId={sequenceAtomId}
          variationId={variationId}
          sequenceVariationsUsed={sequenceVariationsUsed}
          maxNumberVariations={maxNumberVariations}
          onRefreshAtomVariation={onRefreshAtomVariationContent}
          onQuestionNavigation={setVariationIndex}
          questionVariationNumber={variationIndex}
          learningObjectiveId={learningObjective?.id}
          chapterName={chapterName}
          topicName={topicName}
          loDescription={learningObjective?.description}
          onVariationContentChange={onVariationContentChanged}
          customSequenceVariationSize={sequenceAtomVariationIds?.length}
        />
      )}
      {renderActionBar &&
        renderActionBar({
          fromObjectivesModal,
          parentEntityType,
          atom: atom as Content.IAtom,
          assessmentId,
          sequenceId,
          pathSequenceVariationId,
          atomId: sequenceAtomId,
          variationId,
          sequenceVariationsUsed,
          maxNumberVariations,
          onRefreshAtomVariation: onRefreshAtomVariationContent,
          learningObjectiveId: learningObjective?.id,
          chapterName,
          topicName,
          loDescription: learningObjective?.description,
          customSequenceVariationSize: sequenceAtomVariationIds?.length,
        })}
    </>
  )
}

AtomVariation.displayName = 'AtomVariation'
