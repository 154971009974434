import * as React from 'react'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { createModal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { Heading } from 'app/frontend/components/material/heading'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { ASSESSMENT_SETTINGS_FORM, FormNames } from './helper'

const t = tns('teach:assessment_builder')

export const CONFIRM_LDB_MODAL = 'CONFIRM_LDB_MODAL'

const ModalComponent = createModal()

export const ConfirmLdbModal: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  return (
    <ModalComponent name={CONFIRM_LDB_MODAL} dataBi="confirm-ldb-modal">
      {({ hideModal }) => (
        <>
          <ModalBody>
            <Heading tag="h1" size="h3">
              {t('settings_ldb_modal_heading')}
            </Heading>
            <Paragraph>{t('settings_ldb_modal_body')}</Paragraph>
          </ModalBody>
          <ModalFooter>
            <ButtonMaterial
              theme="secondary"
              label={t('settings_ldb_modal_cancel')}
              data-bi="cancel-button"
              onClick={() => {
                dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.USE_LDB, false))
                hideModal()
              }}
            />
            <ButtonMaterial
              theme="primary"
              label={t('settings_ldb_modal_ok')}
              onClick={hideModal}
              data-bi="confirm-ldb-modal-ok"
            />
          </ModalFooter>
        </>
      )}
    </ModalComponent>
  )
}

ConfirmLdbModal.displayName = 'ConfirmLdbModal'
