import * as React from 'react'
import * as moment from 'moment'
import { DateTimeFormats } from 'app/helpers/timezone'

type DatePickerReducerProps = {
  dateFormat: DateTimeFormats
  time: string
  dateTimeFormat: string
  value: moment.Moment
}

type DatePickerReducerState = [
  {
    inputValue: moment.Moment | false
    formattedInputValue: string
    isPickerOpen: boolean
    error: string
  },
  {
    showPicker: () => void
    hidePicker: () => void
    setText: (val: string) => void
    setError: (val: string) => void
    handleChange: (val: moment.Moment) => void
  }
]

type DateActions =
  | {
      type: 'KEYBOARD_INPUT'
      value: string
    }
  | {
      type: 'CHANGE'
      value: moment.Moment
    }
  | {
      type: 'SET_ERROR'
      value: string
    }
  | {
      type: 'SHOW_PICKER'
    }
  | {
      type: 'HIDE_PICKER'
    }

type DateState = {
  inputValue: moment.Moment | false
  formattedInputValue: string
  isPickerOpen: boolean
  error: string
}

export const datePickerReducer = ({
  dateFormat,
  time,
  dateTimeFormat,
  value,
}: DatePickerReducerProps): DatePickerReducerState => {
  const [{ inputValue, formattedInputValue, isPickerOpen, error }, dispatch] = React.useReducer(
    (state: DateState, action: DateActions): DateState => {
      switch (action.type) {
        case 'CHANGE':
          return {
            ...state,
            error: '',
            formattedInputValue: action.value ? action.value.format(dateFormat) : '',
            inputValue: action.value,
          }
        case 'KEYBOARD_INPUT':
          const momentString = `${action.value} ${time}`
          return {
            ...state,
            error: '',
            inputValue:
              moment(momentString, dateTimeFormat).isValid() &&
              moment(momentString, dateTimeFormat),
            formattedInputValue: action.value,
          }
        case 'SET_ERROR':
          return {
            ...state,
            error: action.value,
          }
        case 'SHOW_PICKER':
          return { ...state, isPickerOpen: true }
        case 'HIDE_PICKER':
          return { ...state, isPickerOpen: false }
        default:
          return state
      }
    },
    {
      isPickerOpen: false,
      inputValue: value,
      formattedInputValue: value ? value.format(dateFormat) : '',
      error: '',
    }
  )

  // Actions
  const showPicker = () => dispatch({ type: 'SHOW_PICKER' })
  const hidePicker = () => dispatch({ type: 'HIDE_PICKER' })
  const setText = (val: string) => dispatch({ type: 'KEYBOARD_INPUT', value: val })
  const setError = (val: string) => dispatch({ type: 'SET_ERROR', value: val })
  const handleChange = (val: moment.Moment) => dispatch({ type: 'CHANGE', value: val })

  return [
    { inputValue, formattedInputValue, isPickerOpen, error },
    { showPicker, hidePicker, setText, setError, handleChange },
  ]
}
