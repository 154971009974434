import * as React from 'react'
import { connect } from 'react-redux'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import { getModalOptions } from 'app/frontend/components/material/modal/modal-reducer'
import { CUSTOM_QUESTION_TYPES } from 'app/frontend/components/compound/learnosity'
import { CustomQuestionsTypePicker } from './custom-questions-type-picker'
import {
  CUSTOM_QUESTIONS_MODAL,
  CustomQuestionModalOptions,
  View,
} from './custom-questions-modal-types'
import { CustomQuestionsEditor } from './custom-questions-editor'

interface StateProps extends CustomQuestionModalOptions {}

type Props = StateProps

export const _CustomQuestionsModal: React.FunctionComponent<Props> = ({
  assessmentId,
  pathSequenceVariationId,
  questionJson,
  view,
  atom,
  editorMode,
  onVariationContentChange,
  customItemVariationSequence,
  currentQuestionIndex,
  poolSize,
}) => (
  <Modal name={CUSTOM_QUESTIONS_MODAL} dataBi="custom-questions-modal" fullScreen={true}>
    <ModalBody>
      {view === View.Select && <CustomQuestionsTypePicker />}
      {(view === View.MultipleChoice || view === View.FreeResponse) && (
        <CustomQuestionsEditor
          assessmentId={assessmentId}
          pathSequenceVariationId={pathSequenceVariationId}
          questionJson={questionJson}
          questionType={
            view === View.MultipleChoice
              ? CUSTOM_QUESTION_TYPES.MultipleChoice
              : CUSTOM_QUESTION_TYPES.FreeResponse
          }
          atom={atom}
          poolSize={poolSize}
          editorMode={editorMode}
          customItemVariationSequence={customItemVariationSequence}
          currentQuestionIndex={currentQuestionIndex}
          onVariationContentChange={onVariationContentChange}
        />
      )}
    </ModalBody>
  </Modal>
)

_CustomQuestionsModal.displayName = 'CustomQuestionsModal'

const mapStateToProps = (state: any): StateProps => ({
  ...getModalOptions(state, CUSTOM_QUESTIONS_MODAL),
})

export const CustomQuestionsModal = connect<StateProps, {}, {}>(mapStateToProps)(
  _CustomQuestionsModal
)
