import * as React from 'react'
import { ActionCreator } from 'redux'
import { connect } from 'react-redux'
import Snackbar from './snackbar'
import { SnackbarContainerState } from './snackbar-reducer'

interface ISnackbarStateProps {
  snackbars: SnackbarContainerState
}

interface ISnackbarDispatchProps {
  handleClick: (actionGenerator: ActionCreator<any>) => void
}

type ISnackbarContainerProps = ISnackbarStateProps & ISnackbarDispatchProps

export const SnackbarContainer: React.FunctionComponent<ISnackbarContainerProps> = props => {
  if (!props.snackbars || props.snackbars.length <= 0) {
    return null
  }

  const snackbar = props.snackbars[0]
  let handleClick
  if (snackbar.handleClick) {
    handleClick = snackbar.handleClick
  } else if (snackbar.actionSaga) {
    handleClick = () => props.handleClick(snackbar.actionSaga)
  }

  return (
    <Snackbar
      message={snackbar.message}
      iconName={snackbar.iconName}
      iconClassName={snackbar.iconClassName}
      actionLabel={snackbar.actionLabel}
      handleClick={handleClick}
      timeout={snackbar.timeout}
      key={snackbar.key}
    />
  )
}

const mapStateToProps = state => {
  return {
    snackbars: state.global.ui.snackbars,
  }
}

const mapDispatchToProps = (dispatch): ISnackbarDispatchProps => ({
  handleClick: actionGenerator => dispatch(actionGenerator()),
})

export default connect<ISnackbarStateProps, ISnackbarDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarContainer)
