import { Reducer } from 'redux'
import { UPDATE_QUESTION_COUNT, UpdateQuestionCountAction } from '../entities-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export interface IQuestionCountReducer {
  byAssignmentId: {
    [pathId: string]: Partial<Commons.IQuestionCount>
  }
}

export const getQuestionCountByAssignmentId = (
  state: LearnControllerState,
  assignmentId: string
): Partial<Commons.IQuestionCount> => {
  const questionCount = state.entities.questionCount.byAssignmentId[assignmentId]
  return questionCount || null
}

export const reducer = (
  state: Partial<IQuestionCountReducer> = { byAssignmentId: {} },
  action: UpdateQuestionCountAction
): Partial<IQuestionCountReducer> => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case UPDATE_QUESTION_COUNT:
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [action.assignmentId]: action.questionCount || {},
        },
      }
    default:
      return state
  }
}

export default reducer as Reducer<IQuestionCountReducer>
