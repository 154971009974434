import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
import * as GET_TABLE_OF_CONTENTS_FOR_COURSEPACK from './get-table-of-contents-for-coursepack.gql'

export interface GetTableOfContentsForCoursepackRenderProps {
  tableOfContents?: GQL.GetTableOfContentsForCoursepack.GetTableOfContentsForCoursepack
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryGetTableOfContentsForCoursepackArgs & {
  skip?: boolean
  children: (props: GetTableOfContentsForCoursepackRenderProps) => JSX.Element
}

export const GetTableOfContentsForCoursepackQuery: React.FunctionComponent<Props> = ({
  coursepackId,
  skip,
  children,
}) => (
  <Query<GQL.GetTableOfContentsForCoursepack.Query, GQL.GetTableOfContentsForCoursepack.Variables>
    query={GET_TABLE_OF_CONTENTS_FOR_COURSEPACK}
    variables={{ coursepackId, skip: !!skip }}
  >
    {({ data, loading, error }) =>
      children({
        tableOfContents: isEmpty(data) ? null : data.getTableOfContentsForCoursepack,
        loading,
        error,
      })
    }
  </Query>
)

GetTableOfContentsForCoursepackQuery.displayName = 'GetTableOfContentsForCoursepackQuery'

export default GetTableOfContentsForCoursepackQuery
