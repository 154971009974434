import * as React from 'react'
import { Props as RawAtomProps, RawAtom } from './raw-atom'
import * as styles from './atom.css'

export type Props = RawAtomProps

export const Atom: React.FunctionComponent<Props> = ({
  atom,
  hideCorrectAnswers,
  hideChoiceExplanation,
  hideExplanation,
  isVariationContentChanged,
  isFromPreview,
}) => (
  <section className={styles.atom}>
    <RawAtom
      atom={atom}
      hideChoiceExplanation={hideChoiceExplanation}
      hideCorrectAnswers={hideCorrectAnswers}
      hideExplanation={hideExplanation}
      isVariationContentChanged={isVariationContentChanged}
      isFromPreview={isFromPreview}
    />
  </section>
)

Atom.displayName = 'Atom'
