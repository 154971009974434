import { Reducer, Action } from 'redux'
import { SET_MATHJAX_STATUS, SetMathjaxStatus, MathjaxStatus } from './mathjax-actions'

export type MathjaxStateType = {
  status: MathjaxStatus
}

/**
 * This is our best guess approximation that all of the content on the page has been typeset.
 * Dynamically loading content may cause this to toggle.
 *
 * Typically content is loaded in a batch and is typeset much slower than it is added, so
 * toggling is not an issue.
 */
export const getMathjaxRenderComplete = (state: any): boolean => {
  return getMathjaxStatus(state) === 'IDLE'
}

export const getMathjaxStatus = (state: any): MathjaxStatus => {
  return state.global.ui.mathjax.status
}

const defaultState: MathjaxStateType = {
  status: 'INIT',
}

const MathjaxReducer = (
  state: MathjaxStateType = defaultState,
  action: Action & SetMathjaxStatus
): MathjaxStateType => {
  switch (action.type) {
    case SET_MATHJAX_STATUS:
      return {
        ...state,
        status: state.status === 'ERROR' ? 'ERROR' : action.status,
      }
    default:
      return state
  }
}

export default MathjaxReducer as Reducer<MathjaxStateType>
