import { isEmpty } from 'lodash'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Breadcrumbs as BreadcrumbsCore,
  Link,
  Theme,
  useMediaQuery,
  Typography,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Icon } from 'app/frontend/components/material/icon'
import { UnderliningLink } from 'app/frontend/components/material/link'
import { BoxBackground } from 'app/frontend/components/material/box/box'

export interface StyleProps {
  background: BoxBackground
}

export type Breadcrumb = {
  label: string
  path?: string
}

interface CrumbProps extends Breadcrumb {
  background: BoxBackground
}

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[]
  backLink: string
  backOnClick: () => void
  backLabel: string
  backDataBi: string
  background: BoxBackground
}

interface CrumbStyleProps {
  background: BoxBackground
}

const styles = (theme: Theme) =>
  createStyles<'root' | 'noLink' | 'backIcon', CrumbStyleProps>({
    root: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontWeight: theme.typography?.fontWeightMedium,
      fontSize: '0.75rem',
      lineHeight: 'normal',
    },
    noLink: {
      color: props =>
        props.background === 'dark' ? theme.palette?.common.white : theme.palette?.grey[600],
    },
    backIcon: {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: '0.5rem',
    },
  })

const useStyles = makeStyles(styles)

export const Crumb: React.FunctionComponent<CrumbProps> = ({ label, path, background }) => {
  const crumbStyles = useStyles({ background })
  return (
    <Typography classes={{ root: crumbStyles.root }}>
      {path ? (
        <UnderliningLink
          router={{ to: path }}
          data-bi={`breadcrumb-${label.toLowerCase()}`}
          styleProps={{
            color: theme =>
              background === 'dark' ? theme.palette?.custom_grey[7] : theme.palette?.grey[400],
            underlineColor: theme => theme.palette?.primary.main,
            underlineWidth: _theme => '0.0625rem',
          }}
        >
          {label}
        </UnderliningLink>
      ) : (
        <span className={crumbStyles.noLink}>{label}</span>
      )}
    </Typography>
  )
}

/**
 * When the user is on mobile (or a narrow screen), we only show
 * the final breadcrumb.
 */
export const useFilteredBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('lg'), { noSsr: true })
  return {
    breadcrumbs: React.useMemo(() => (isMobile ? breadcrumbs.slice(-1) : breadcrumbs), [isMobile]),
    isMobile,
  }
}

export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbs,
  backLink,
  backOnClick,
  backLabel,
  backDataBi,
  background,
}) => {
  const crumbStyles = useStyles({ background })
  const { breadcrumbs: filteredBreadcrumbs, isMobile } = useFilteredBreadcrumbs(breadcrumbs)

  if (isEmpty(filteredBreadcrumbs)) {
    return null
  }

  return (
    <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
      {isMobile && backLink && (
        <Link
          component={RouterLink}
          to={backLink}
          onClick={backOnClick}
          title={backLabel}
          data-bi={backDataBi}
        >
          <Icon name="icon-arrow-back" className={crumbStyles.backIcon} />
        </Link>
      )}
      <BreadcrumbsCore
        separator={'›'}
        classes={{
          separator: crumbStyles.root,
        }}
        aria-label={'Breadcrumb'}
      >
        {filteredBreadcrumbs.map(({ path, label }) => {
          return (
            <Crumb label={label} path={path} background={background} key={`breadcrumb-${label}`} />
          )
        })}
      </BreadcrumbsCore>
    </Box>
  )
}
