import * as React from 'react'
import {
  List,
  ListItem,
  ListCheckbox,
  ListSubHeader,
  ListDivider,
  ListItemAction,
  ListItemActions,
  ListItemContent,
  ListItemLayout,
  ListItemText,
  ListItemProps,
  ListItemActionProps,
  ListProps,
  ListDividerTheme,
} from 'accessible-react-toolbox/lib/list'
import { mergeStyles } from '../helpers'
const styles = require('./list.css')

export interface IListProps extends ListProps {}

export interface IListItemProps extends ListItemProps {
  id?: string
  target?: string
}

interface IListCheckboxProps {
  caption?: string
  className?: string
  checked?: boolean
  disabled?: boolean
  legend?: string
  name?: string
  onBlur?: () => void
  onChange?: () => void
  onFocus?: () => void
}

interface IListItemActionProps {
  action?: React.ReactNode
  [key: string]: any
}

interface IListItemActionsProps {
  children?: React.ReactNode
  type?: 'left' | 'right'
}

interface IListItemContentProps {
  caption?: React.ReactNode
  children?: React.ReactNode
  legend?: string
  type?: 'auto' | 'normal' | 'large'
}

interface IListItemLayoutProps {
  avatar?: string | React.ReactElement<any>
  caption?: string
  children?: React.ReactNode
  disabled?: boolean
  itemContent?: React.ReactChild
  leftActions?: React.ReactNode[]
  leftIcon?: string | React.ReactElement<any>
  legend?: string
  rightActions?: React.ReactNode[]
  rightIcon?: string | React.ReactElement<any>
  selectable?: boolean
  to?: string
}

interface IListDividerProps {
  inset?: boolean
  li?: boolean
  theme?: ListDividerTheme
}

interface IListItemTextProps {
  children?: React.ReactNode
  primary?: boolean
  [key: string]: any
}

interface IListSubHeaderProps {
  caption?: string
}

export const ListMaterial: React.FunctionComponent<IListProps> = (props): JSX.Element => {
  return <List {...props} theme={{ list: styles.list }} />
}
ListMaterial.displayName = 'ListMaterial'

export type ListItemMaterialProps = IListItemProps & IListItemLayoutProps & ListItemActionProps

export const ListItemMaterial: React.FunctionComponent<ListItemMaterialProps> = (
  props
): JSX.Element => {
  return (
    <ListItem
      {...props}
      theme={mergeStyles(
        {
          listItem: styles.listItem,
          selectable: styles.selectable,
          item: styles.listItem,
        },
        props.theme
      )}
    />
  )
}
ListItemMaterial.displayName = 'ListItemMaterial'

export const ListCheckboxMaterial: React.FunctionComponent<IListCheckboxProps> = (
  props
): JSX.Element => {
  return (
    <ListCheckbox
      {...props}
      theme={{
        checkbox: styles.checkbox,
        checkboxItem: styles.checkboxItem,
        disabled: styles.disabled,
        item: styles.item,
      }}
    />
  )
}
ListCheckboxMaterial.displayName = 'ListCheckboxMaterial'

export const ListItemActionMaterial: React.FunctionComponent<IListItemActionProps> = (
  props
): JSX.Element => {
  return <ListItemAction {...props} theme={{ itemAction: styles.itemAction }} />
}
ListItemActionMaterial.displayName = 'ListItemActionMaterial'

export const ListItemActionsMaterial: React.FunctionComponent<IListItemActionsProps> = (
  props
): JSX.Element => {
  return (
    <ListItemActions
      {...props}
      theme={{
        left: styles.left,
        right: styles.right,
      }}
    />
  )
}
ListItemActionsMaterial.displayName = 'ListItemActionsMaterial'

export const ListItemContentMaterial: React.FunctionComponent<IListItemContentProps> = (
  props
): JSX.Element => {
  return (
    <ListItemContent
      {...props}
      theme={{
        auto: styles.auto,
        itemContentRoot: styles.itemContentRoot,
        large: styles.large,
        normal: styles.normal,
      }}
    />
  )
}
ListItemContentMaterial.displayName = 'ListItemContentMaterial'

export const ListItemLayoutMaterial: React.FunctionComponent<IListItemLayoutProps> = (
  props
): JSX.Element => {
  return (
    <ListItemLayout
      {...props}
      theme={{
        item: styles.layoutItem,
        selectable: styles.selectable,
        disabled: styles.layoutDisabled,
      }}
    />
  )
}
ListItemLayoutMaterial.displayName = 'ListItemLayoutMaterial'

export const ListDividerMaterial: React.FunctionComponent<IListDividerProps> = ({
  theme,
  ...otherProps
}): JSX.Element => {
  return (
    <ListDivider
      {...otherProps}
      theme={{
        divider: styles.divider,
        inset: styles.inset,
        ...theme,
      }}
    />
  )
}
ListDividerMaterial.displayName = 'ListDividerMaterial'

export const ListItemTextMaterial: React.FunctionComponent<IListItemTextProps> = (
  props
): JSX.Element => {
  return <ListItemText {...props} theme={{ itemText: styles.itemText }} />
}
ListItemTextMaterial.displayName = 'ListItemTextMaterial'

export const ListSubHeaderMaterial: React.FunctionComponent<IListSubHeaderProps> = (
  props
): JSX.Element => {
  return <ListSubHeader {...props} theme={{ subheader: styles.subheader }} />
}
ListSubHeaderMaterial.displayName = 'ListSubHeaderMaterial'
