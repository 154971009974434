import * as React from 'react'
import { AssignmentType, AssessmentLabel } from 'app/typings/commons'
import { Tag, TagTheme } from 'app/frontend/components/tag'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { isEnabled } from 'app/frontend/helpers/feature'

const t = tns('assignment_tag')

interface Props {
  type: AssignmentType
  isPrintable?: boolean
  label?: AssessmentLabel
  dataBi?: string
}
interface AssignmentLabelAndTag {
  assignmentLabel: string
  theme: TagTheme
}

// Visible for testing
export const getAssignmentLabelAndTheme = (type, label, isPrintable): AssignmentLabelAndTag => {
  switch (type) {
    case isEnabled('homeworkAssessmentLabelFlag') && AssignmentType.ADAPTIVE:
      return {
        assignmentLabel: type,
        theme: 'primary50',
      }
    case AssignmentType.QUIZ: {
      return {
        assignmentLabel: isPrintable ? t('offline_assessment_label', { label }) : label,
        theme: label === AssessmentLabel.HOMEWORK ? 'primary100' : 'primary',
      }
    }
    default:
      return null
  }
}

export const AssignmentTag: React.FunctionComponent<Props> = ({
  type,
  label,
  isPrintable,
  dataBi,
}) => {
  const result = getAssignmentLabelAndTheme(type, label, isPrintable)

  // This happens only when the feature flag is disabled. We don't want to render an empty tag with
  // a primary theme.
  if (!result) {
    return null
  }

  return (
    <Tag
      label={result.assignmentLabel}
      theme={result.theme}
      margin={{ right: 'small' }}
      data-bi={dataBi}
      uppercase={true}
    />
  )
}

AssignmentTag.displayName = 'AssignmentTag'
