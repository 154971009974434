import { SORT_COURSE_TABLE } from './all-courses-actions'
import AllCourseTableColumn from './all-courses-columns'
import { combineReducers } from 'redux'

const defaultState = {
  courseSort: AllCourseTableColumn.END_DATE,
  courseSortAscending: true,
}
export function roster(state = defaultState, action) {
  switch (action.type) {
    case SORT_COURSE_TABLE:
      const newAscending =
        state.courseSort === action.courseSort ? !state.courseSortAscending : true
      return {
        ...state,
        courseSort: action.courseSort,
        courseSortAscending: newAscending,
      }
    default:
      return state
  }
}
export const getRoster = state => getAllCourses(state).roster

export default combineReducers({
  roster,
})
export const getAllCourses = state => state.allCourses
