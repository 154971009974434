import * as React from 'react'
import * as classnames from 'classnames/bind'
import * as styles from './tag.css'
import * as _ from 'lodash'
import { Tooltip, TooltipWidth } from '../tooltip'
import { Icon } from 'app/frontend/components/material/icon'
import { IconLoadingEllipsis } from 'app/frontend/components/material/icon'

const c = classnames.bind(styles)

export type TagTheme =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'error'
  | 'blue'
  | 'info'
  | 'primary50'
  | 'primary100'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  theme?: TagTheme
  /** The amount of margin around the tag. An object can be specified to distinguish horizontal
   * margin, vertical margin, and margin on a particular side of the box:
   * {horizontal: none|small|medium|large|xlarge|huge, vertical: none|small|medium|large|xlarge|huge,
   * top|left|right|bottom: none|small|medium|large|xlarge|huge}. Defaults to none.
   */
  margin?:
    | 'none'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'huge'
    | {
        horizontal?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        vertical?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
      }
    | {
        top?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        left?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        right?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        bottom?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
      }
  label?: string
  iconName?: string
  tooltip?: React.ReactNode
  tooltipWidth?: TooltipWidth
  isLoading?: boolean
  faded?: boolean
  /**
   * If label should be uppercase.
   * If undefined/null, will depend on the theme.
   */
  uppercase?: boolean
  tooltipDataBi?: string
}

export class Tag extends React.Component<Props, {}> {
  /**
   * Returns a css class name of the pattern `prefix-value`.  If an object is passed into
   * the value, the pattern is then `prefix-value.key-value.value`.
   */
  private classes(prefix: string, value: boolean | string | object): string[] {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [`${prefix}-${value}`]
    } else if (typeof value === 'object') {
      return Object.entries(value).map(([key, val]) => `${prefix}-${key}-${val}`)
    } else {
      return []
    }
  }

  renderTag(): JSX.Element {
    const {
      theme = 'primary',
      margin = 'none',
      label,
      iconName,
      isLoading,
      className,
      // `tooltipWidth` prop had to reference here to stop from merging into `rest` object
      // If we allow that, an exception will occur saying un-recognized prop to Div element
      tooltipWidth,
      faded,
      uppercase,
      ...rest
    } = this.props
    return (
      <div
        className={classnames(
          c(
            'default',
            this.classes('theme', theme),
            this.classes('margin', margin),
            this.classes('uppercase', _.isNil(uppercase) ? theme === 'primary' : uppercase),
            className
          ),
          { [styles.faded]: faded }
        )}
        data-test="tag"
        data-label={label}
        {...rest}
      >
        {isLoading ? (
          <IconLoadingEllipsis size="medium" />
        ) : (
          <>
            {iconName && <Icon name={iconName} size="small" />}
            {label && <span className={styles.label}>{label}</span>}
          </>
        )}
      </div>
    )
  }

  render() {
    const { tooltip, tooltipWidth, tooltipDataBi } = this.props

    return tooltip ? (
      <Tooltip title={tooltip} placement="top" width={tooltipWidth} data-bi={tooltipDataBi}>
        {this.renderTag()}
      </Tooltip>
    ) : (
      this.renderTag()
    )
  }
}
