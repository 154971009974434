import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import * as GET_ASSIGNMENT_OVERRIDE from './get-assignment-override.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseAssignmentOverrideResults = RenderProps<GQL.GetAssignmentOverride.Query>

export const useAssignmentOverride = (
  assignmentId: string,
  sectionId: string,
  skip?: boolean,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
): UseAssignmentOverrideResults => {
  const { loading, error, data } = useQuery<
    GQL.GetAssignmentOverride.Query,
    GQL.GetAssignmentOverride.Variables
  >(GET_ASSIGNMENT_OVERRIDE, {
    variables: {
      assignmentId,
      sectionId,
      skip: false,
    },
    fetchPolicy,
    nextFetchPolicy: 'cache-first', // TODO ALPACA-759
    skip,
  })

  return {
    loading,
    error,
    ...data,
  }
}
