import * as React from 'react'
import RetryableErrorBoundary from 'app/frontend/components/error/retryable-error-boundary'
import RetryableAtomError from './retryable-atom-error'
import FatalAtomError from './fatal-atom-error'

interface Props {
  source?: string
  children?: React.ReactNode
  maxRetries?: number
}

const defaultSource = 'atom-error'
const defaultMaxRetries = 3

const AtomErrorBoundary: React.FunctionComponent<Props> = ({
  source = defaultSource,
  maxRetries = defaultMaxRetries,
  children,
}) => {
  return (
    <RetryableErrorBoundary
      source={source}
      fallback={({ remount, error, numberOfMounts }) =>
        numberOfMounts < maxRetries ? (
          <RetryableAtomError onRetry={remount} errors={[error.message]} />
        ) : (
          <FatalAtomError errors={[error.message]} />
        )
      }
    >
      {children}
    </RetryableErrorBoundary>
  )
}

export default AtomErrorBoundary
