export { Atom, Props as AtomProps } from './atom'

/**
 * All components from this module *must* be wrapped in <AtomWrapper>.
 * Currently, this component provides three main mechanisms:
 *   1) Provides required Contexts to the sub-components listed here.
 *   2) Publishes metrics at a per-atom granularity for monitoring rendering.
 *   3) Provides an Error Boundary that prevents errors from crashing the entire page.
 */
export { default as AtomWrapper } from './atom-wrapper'
