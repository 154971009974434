import { Action } from 'redux'

export enum StudentsAnalyticsActions {
  SORT_STUDENTS_ANALYTICS = 'SORT_STUDENTS_ANALYTICS',
  UPDATE_SELECTED_STUDENTS = 'UPDATE_SELECTED_STUDENTS',
}

interface UpdateSelectedStudents extends Action {
  type: StudentsAnalyticsActions.UPDATE_SELECTED_STUDENTS
  selectedStudents: string[]
}
export function updateSelectedStudents(selectedStudents: string[]): UpdateSelectedStudents {
  return {
    type: StudentsAnalyticsActions.UPDATE_SELECTED_STUDENTS,
    selectedStudents,
  }
}
