import * as React from 'react'
import {
  LearningObjectivesToAssignmentsMap,
  AssessedConceptsLoMap,
} from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import {
  doesChapterContainSelectedLo,
  filterByTopicWithAnySelectedLearningObjective,
  filterByTopicWithAnyUnSelectedLearningObjective,
  filterToc,
  flattenTopicLoMapValues,
  searchTableOfContents,
  filterByAssignedConcepts,
  filterByUnassignedConcepts,
  filterByUnassessedConcepts,
  filterByAssessedConcepts,
} from 'app/frontend/pages/material/teach/compositions/data/table-of-contents/table-of-contents-helper'
import { flatten, isEmpty, memoize } from 'lodash'
import { Box } from 'app/frontend/components/material/box'
import { SearchInput } from 'app/frontend/compositions/data/search-input'
import { AssessmentTableOfContents } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-table-of-contents'
import { TopicLoMap } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import { QuestionPreferences } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences'
import { AssessmentQuestionsByConceptByLo } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-questions-by-concept-by-lo/use-assessment-questions-by-concept-by-lo'
import { ContentFilterGroups } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/content-filters/content-filter-groups'
import {
  AssessmentUsageFilter,
  AssignmentUsageFilter,
  FilterGroups,
  SelectionFilter,
  FilterGroupsStateType,
} from 'app/typings/commons'

export interface OwnProps {
  titles: GQL.TitleTaxonFields.Fragment[]
  assignmentsByLoId: LearningObjectivesToAssignmentsMap
  assessmentId: string
  persistedSelectedLosByTopic: TopicLoMap
  numSequencesByLo: { [loId: string]: number }
  hasStudentStartedAssessment: boolean
  assessmentQuestionsByConceptByLo: AssessmentQuestionsByConceptByLo
  assessedConceptsByLo: AssessedConceptsLoMap
  parentEntityType: Commons.ParentEntityType
}

type Props = OwnProps

interface State {
  searchQuery?: string
  filters?: Set<Filter>
  filterGroups: FilterGroupsStateType
}

export enum Filter {
  Assigned = 'ASSIGNED',
  Untested = 'UNTESTED',
  Selected = 'SELECTED',
}

export class AssessmentTableOfContentsFilterWrapper extends React.Component<Props, State> {
  private allChapters: string[]
  private expandedChaptersPrevious: string[]
  private isSearchActivePrevious: boolean

  constructor(props: Props) {
    super(props)
    this.allChapters = props.titles.reduce(
      (acc, title) => acc.concat(title.chapters.map(chapter => chapter.id)),
      []
    )
    this.isSearchActivePrevious = false
    this.expandedChaptersPrevious = this.getChaptersWithSelectedLos()
    this.state = {
      filterGroups: this.getInitialFilterGroupsState(),
    }
  }

  private getInitialFilterGroupsState = (): FilterGroupsStateType => {
    const initialState = {
      [FilterGroups.AssignmentUsage]: AssignmentUsageFilter.All_Assignment,
      [FilterGroups.AssessmentUsage]: AssessmentUsageFilter.All_Assessment,
      [FilterGroups.Selection]: SelectionFilter.All_Selection,
    }

    if (!isEmpty(this.props.persistedSelectedLosByTopic)) {
      initialState[FilterGroups.Selection] = SelectionFilter.Selected
    } else if (!isEmpty(this.props.assignmentsByLoId.assignedLearningObjectives)) {
      initialState[FilterGroups.AssignmentUsage] = AssignmentUsageFilter.Assigned
    }

    return initialState
  }

  private handleSelectedFilters = (
    selectedFilter: AssignmentUsageFilter | AssessmentUsageFilter | SelectionFilter,
    selectedFilterGroup: FilterGroups
  ) => {
    this.setState(state => {
      return {
        ...state,
        filterGroups: {
          ...state.filterGroups,
          [selectedFilterGroup]: selectedFilter,
        },
      }
    })
  }
  /**
   * Helper to apply search and other filters to the coursepack TOC and return a sparse TOC containing only
   * the LOs/topics/chapters that match the given filter options. Memoized to avoid extra work
   */
  private getFilteredToC = memoize(
    (
      searchQuery: string,
      filterGroups: FilterGroupsStateType,
      _selectedLos: string[] // used for memoization
    ): GQL.TitleTaxonFields.Fragment[] => {
      const {
        assignmentsByLoId,
        persistedSelectedLosByTopic,
        assessedConceptsByLo,
        titles,
        assessmentId,
      } = this.props
      let filteredToc = titles

      const trueLoFilterFn = (_args: any) => () => true

      const filterCallbacks = {
        [AssignmentUsageFilter.Assigned]: filterByAssignedConcepts,
        [AssignmentUsageFilter.Unassigned]: filterByUnassignedConcepts,
        [AssignmentUsageFilter.All_Assignment]: trueLoFilterFn,
        [AssessmentUsageFilter.Tested]: filterByAssessedConcepts,
        [AssessmentUsageFilter.Untested]: filterByUnassessedConcepts,
        [AssessmentUsageFilter.All_Assessment]: trueLoFilterFn,
        [SelectionFilter.Selected]: filterByTopicWithAnySelectedLearningObjective,
        [SelectionFilter.NotSelected]: filterByTopicWithAnyUnSelectedLearningObjective,
        [SelectionFilter.All_Selection]: trueLoFilterFn,
      }

      const assignmentFilterFn = filterCallbacks[filterGroups[FilterGroups.AssignmentUsage]](
        assignmentsByLoId.assignedObjectivesWithBlacklist
      )
      const assessmentFilterFn = filterCallbacks[filterGroups[FilterGroups.AssessmentUsage]](
        assessedConceptsByLo,
        assessmentId
      )
      const selectedFilterFn = filterCallbacks[filterGroups[FilterGroups.Selection]](
        persistedSelectedLosByTopic
      )

      filteredToc = filterToc(
        filteredToc,
        (lo, topicId, conceptId) =>
          assignmentFilterFn(lo, topicId, conceptId) &&
          assessmentFilterFn(lo, topicId, conceptId) &&
          selectedFilterFn(topicId, lo.id)
      )

      if (searchQuery) {
        filteredToc = searchTableOfContents(filteredToc, searchQuery)
      }

      return filteredToc
    },
    (...args) => JSON.stringify(args)
  )

  /**
   * Get the expanded chapters prop to pass on to the child. There are two basic goals here:
   * a) Resync the expanded chapters to the selected LO state whenever the scope of the filtered TOC is expanded
   *    (i.e. whenever filters grow less restrictive). This is to ensure chapters added back to the filtered TOC
   *    due are expanded according to the current LO selections, since any previous manual expand/collapse actions
   *    on those chaptesrs by the user would have been lost when they were removed from the filtered TOC.
   * b) Avoid overriding the user's manual expand/collpase actions in other cases
   *
   * - If there is an active search, expand all chapters
   * - If we just disabled search, expand all chapters with selected LOs
   * - If we just disabled a filter, expand all chapters with selected LOs.
   * - Otherwise don't change the expanded chapters. This is to prevent overriding any manual changes by the user
   *   to chapter expansion if a re-render of this component is triggered by LO selection changes or additional
   *   filters being enabled.
   *
   * TODO ALPACA-16: Update this as needed depending on the choice of implementation for new CollapsibleCard
   */
  private getExpandedChapters = (): string[] => {
    const isSearchActive = !!this.state.searchQuery
    const isSearchActivePrevious = this.isSearchActivePrevious
    this.isSearchActivePrevious = isSearchActive

    const expandedChapters = isSearchActive
      ? this.allChapters
      : isSearchActivePrevious && !isSearchActive
      ? this.getChaptersWithSelectedLos()
      : this.expandedChaptersPrevious
    this.expandedChaptersPrevious = expandedChapters
    return expandedChapters
  }

  /**
   * Get the IDs of chapters containing at least one selected LO
   */
  private getChaptersWithSelectedLos = (): string[] => {
    const { persistedSelectedLosByTopic, titles } = this.props
    return flatten(titles.map(title => title.chapters))
      .filter(chapter => doesChapterContainSelectedLo(chapter, persistedSelectedLosByTopic))
      .map(chapter => chapter.id)
  }

  render() {
    const props = this.props
    const { searchQuery, filterGroups } = this.state
    const shouldCalculateSelectedLos =
      filterGroups[FilterGroups.Selection] === SelectionFilter.Selected

    const selectedLos = shouldCalculateSelectedLos
      ? flattenTopicLoMapValues(props.persistedSelectedLosByTopic || {})
      : []

    const filteredToc = this.getFilteredToC(searchQuery, filterGroups, selectedLos)

    return (
      <Box>
        <SearchInput onSearch={query => this.setState({ searchQuery: query })} />
        <Box direction="row" responsive={false} pad={{ between: 'medium' }}>
          <ContentFilterGroups
            appliedFilters={this.state.filterGroups}
            onSelectFilters={this.handleSelectedFilters}
          />

          <QuestionPreferences assessmentId={this.props.assessmentId} />
        </Box>
        <AssessmentTableOfContents
          {...props}
          titles={filteredToc}
          unfilteredNumTitles={this.props.titles.length}
          defaultExpandedChapters={this.getExpandedChapters()}
          keyChange={`${!!searchQuery}`}
        />
      </Box>
    )
  }
}
