import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { MenuItem } from '@mui/material'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Icon } from 'app/frontend/components/material/icon'
import { MenuMaterial } from 'app/frontend/components/material/menu'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { SET_OVERRIDE_MODAL } from 'app/frontend/pages/material/teach/compositions/connected/set-override-modal/set-override-modal'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Tooltip } from 'app/frontend/components/tooltip'
import { CORRECT_VALUE, isMarkedCorrect } from 'app/frontend/helpers/compound'
import { ResultOverrideType } from 'app/typings/commons'
import * as styles from './sequence-result-override-buttons.css'

const t = tns('teach:mark_correct_for_all')

type RouterProps = RouteComponentProps<{ courseId: string; sectionId: string }>

interface OwnProps {
  sequenceId: string
  override?: GQL.SequenceOverride
  assessmentId: string
}

interface DispatchProps {
  onOverride: (options: any) => void
}

type OverrideProps = OwnProps & DispatchProps & RouterProps

export const _SequenceResultOverrideButtons: React.FunctionComponent<OverrideProps> = ({
  assessmentId,
  onOverride,
  sequenceId,
  override,
  match: {
    params: { courseId },
  },
}) => {
  const hasBeenDeleted =
    override && override.resultOverride === ResultOverrideType.REMOVED_FROM_ASSESSMENT
  if (hasBeenDeleted) {
    return null
  }

  const hasBeenMarkedCorrect =
    override && isMarkedCorrect(override.resultOverride, override.overrideValue)
  const menuItemText = hasBeenMarkedCorrect
    ? t('undo_mark_as_correct')
    : t('mark_as_correct_for_all')
  const isCourse = !!courseId

  const onClickSetResultOverride = () => {
    const resultOverride = hasBeenMarkedCorrect ? null : ResultOverrideType.OVERRIDDEN
    const overrideValue = hasBeenMarkedCorrect ? null : CORRECT_VALUE

    onOverride({
      sequenceId,
      assessmentId,
      resultOverride,
      overrideValue,
      isCourse,
    })
  }

  const onDeleteQuizSequence = () => {
    onOverride({
      sequenceId,
      assessmentId,
      resultOverride: ResultOverrideType.REMOVED_FROM_ASSESSMENT,
      isCourse,
    })
  }

  return (
    <div data-test="override-buttons">
      <Tooltip title={t('remove_tooltip')} placement="bottom">
        <ButtonMaterial
          label={t('remove')}
          theme="bordered"
          data-bi="remove-question-button"
          data-sequence-id={sequenceId}
          onClick={onDeleteQuizSequence}
          className={styles.removeButton}
          icon={<Icon name="icon-delete" />}
        />
      </Tooltip>

      <MenuMaterial data-bi="override-question-menu" icon={<Icon name="icon-dots" />}>
        <MenuItem data-bi="toggle-question-override-correct" onClick={onClickSetResultOverride}>
          {menuItemText}
        </MenuItem>
      </MenuMaterial>
    </div>
  )
}

_SequenceResultOverrideButtons.displayName = 'SequenceResultOverrideButtons'

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    onOverride: options => {
      dispatch(showModal(SET_OVERRIDE_MODAL, options))
    },
  }
}

export const SequenceResultOverrideButtons = withRouter<OwnProps & RouterProps>(
  connect<{}, DispatchProps, OwnProps & RouterProps>(
    null,
    mapDispatchToProps
  )(_SequenceResultOverrideButtons)
)
