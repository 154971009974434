import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { LearningObjectiveMenu } from './learning-objective-menu'
import {
  AssessmentQuestionsTitleBar,
  SizeType,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-title-bar'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { LearningObjectivesToAssignmentsMap } from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import { useAssessmentBuilderPermissionState } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-builder-permission-state/use-assessment-builder-permission-state'
const t = tns('teach:assessment_questions')

interface Props {
  assessmentId: string
  learningObjectiveId: string
  learningObjective: Partial<Content.ILearningObjective>
  numAssessmentSequences?: string
  pathLearningObjectives: Commons.IPathLearningObjectiveLeanEntity[]
  chapterName: string
  topicName: string
  assignmentsByLearningObjectiveId?: LearningObjectivesToAssignmentsMap
  weight?: 'semibold' | 'regular'
  size?: SizeType
}

export const LearningObjectiveBar: React.FunctionComponent<Props> = ({
  assessmentId,
  learningObjectiveId,
  learningObjective,
  numAssessmentSequences,
  pathLearningObjectives,
  chapterName,
  topicName,
  assignmentsByLearningObjectiveId,
  weight,
  size,
}) => {
  const {
    hasStudentStartedAssignment,
    isCourseAssignmentOnSection,
    hasEditPermission,
    parentEntityType,
  } = useAssessmentBuilderPermissionState()

  const assignedLos =
    !!assignmentsByLearningObjectiveId &&
    assignmentsByLearningObjectiveId.assignedLearningObjectives
  const isUnassigned = assignedLos && !assignedLos[learningObjectiveId]

  const loDescription = learningObjective?.description ?? 'Unknown Learning Objective'

  return (
    <Box direction="row" full={'page'}>
      <AssessmentQuestionsTitleBar
        title={loDescription}
        tagLabel={numAssessmentSequences}
        tagTooltip={t('num_questions', { count: numAssessmentSequences })}
        unassignedTag={isUnassigned ? t('unassigned_lo') : undefined}
        unassignedTooltip={isUnassigned ? t('unassigned_lo_tooltip') : undefined}
        size={size}
        weight={weight}
      >
        {!isCourseAssignmentOnSection && !hasStudentStartedAssignment && hasEditPermission && (
          <LearningObjectiveMenu
            learningObjectiveId={learningObjectiveId}
            assessmentId={assessmentId}
            parentEntityType={parentEntityType}
            loDescription={loDescription}
            chapterName={chapterName}
            topicName={topicName}
            pathLearningObjectives={pathLearningObjectives}
          />
        )}
      </AssessmentQuestionsTitleBar>
    </Box>
  )
}

LearningObjectiveBar.displayName = 'LearningObjectiveBar'
