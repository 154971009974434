import { createStore, applyMiddleware, compose } from 'redux'
import reduxFreeze from 'redux-freeze'

const REDUX_DEVTOOLS =
  typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : typeof window.devToolsExtension !== 'undefined'
    ? window.devToolsExtension()
    : f => f

/**
 * This method creates a redux store, it adds debugging/tooling middlewares or store enhancers for
 * development environments.
 */
export function createDirectStore(reducer, preloadedState, enhancer) {
  if (typeof preloadedState === 'function' && typeof enhancer === 'undefined') {
    enhancer = preloadedState
    preloadedState = undefined
  }

  const enhancers = []
  if (enhancer) {
    enhancers.push(enhancer)
  }

  // Dev is a flag set by webpack for the dev build, see webpack.config.js
  if (window.__DEV__) {
    // Redux freeze prevents mutation of the state
    enhancers.push(applyMiddleware(reduxFreeze))
  }

  // Dev tools in all the environments for now
  enhancers.push(REDUX_DEVTOOLS)

  return createStore(reducer, preloadedState, compose(...enhancers))
}
