import * as React from 'react'
import { ButtonMenu, ButtonMenuProps } from 'app/frontend/components/menu/menu'
import { makeStyles } from '@mui/styles'

interface MenuMaterialProps extends ButtonMenuProps {
  isDarkIconTheme?: boolean
  icon: JSX.Element
  label?: null
}

const useDarkStyle = makeStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette?.grey[400],
    },
    '&:active': {
      backgroundColor: theme.palette?.grey[600],
    },
    '&:active svg': {
      fill: theme.palette?.common.white,
    },
    '&:focus svg': {
      fill: theme.palette?.common.white,
    },
    // Emulate react-toolbox behavior
    height: '36px',
    width: '36px',
    padding: 0,
  },
}))

const useLightStyle = makeStyles(theme => ({
  root: {
    '&:active': {
      backgroundColor: theme.palette?.common.white,
    },
    '&:focus svg': {
      fill: theme.palette?.blue[1000],
    },
    '&:active svg': {
      fill: theme.palette?.blue[1000],
    },
    // Emulate react-toolbox behavior
    height: '36px',
    width: '36px',
    padding: 0,
  },
}))

export const IconMenu: React.FunctionComponent<MenuMaterialProps> = ({
  isDarkIconTheme,
  children,
  buttonClassName,
  ...rest
}): JSX.Element => {
  const style = isDarkIconTheme ? useDarkStyle() : useLightStyle()

  return (
    <ButtonMenu buttonClassName={buttonClassName || style.root} {...rest}>
      {children}
    </ButtonMenu>
  )
}
