import * as Clipboard from 'clipboard'
import * as Papa from 'papaparse'
import saveAs from './file-saver'

export const downloadDataset = (csvString: string) => {
  const filename = `knewton_dataset_${Date.now()}.csv`
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' })
  saveAs(blob, filename)
}

export async function copyToClipboardAsTsv(
  parsedRows: string[][],
  onError: (event: Event) => void
) {
  try {
    const tsvString = Papa.unparse(parsedRows, { delimiter: '\t' })
    await copyToClipboard(tsvString)
  } catch (error) {
    onError(error)
  }
}

/***
 * Helper function that copies any text to the clipboard.
 * @param text: the content to be copied
 * @returns {Promise<Event>} a clipboard copy event will be resolved/rejected
 */
export function copyToClipboard(text) {
  return new Promise<Event>((resolve, reject) => {
    // Create a button and put it in the DOM.
    // If the element in not in the DOM, IE11 will not copy the text.
    const button = document.createElement('button')
    button.setAttribute('style', 'display: none')
    button.classList.add('copy-to-clipboard')
    document.body.appendChild(button)

    const clipboard = new Clipboard(button, {
      text() {
        return text
      },
    })

    clipboard.on('success', event => {
      event.clearSelection()
      resolve(event)
      document.body.removeChild(button)
      clipboard.destroy()
    })

    clipboard.on('error', event => {
      reject(event)
      document.body.removeChild(button)
      clipboard.destroy()
    })

    button.click()
  })
}
