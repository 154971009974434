import * as React from 'react'
import { Heading } from 'app/frontend/components/material/heading'
import { Tag } from 'app/frontend/components/tag'
import { Box } from 'app/frontend/components/material/box'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as Feature from 'app/frontend/helpers/feature'
import { ParentEntityType } from 'app/typings/commons'
import * as styles from './atom-variation-header.css'

const t = tns('teach:atom_variation_header')

interface Props {
  isAssessmentOffline: boolean
  parentEntityType?: ParentEntityType
  isRepeated?: boolean
  isPrintFriendly?: boolean
  isAssessmentLdb: boolean
  isLdbFriendly?: boolean
  isPool?: boolean
  showAtomTags?: boolean
  markedCorrect?: boolean
  markedIncorrect?: boolean
  removed?: boolean
  customHeading?: JSX.Element
  isDraggable?: boolean
  isSequenceUsed?: boolean
}

export const AtomVariationHeader: React.FunctionComponent<Props> = ({
  showAtomTags,
  isAssessmentOffline,
  parentEntityType,
  isPrintFriendly,
  isRepeated,
  isAssessmentLdb,
  isLdbFriendly,
  isPool,
  markedCorrect,
  markedIncorrect,
  removed,
  customHeading,
  isDraggable,
  isSequenceUsed,
}) => {
  return (
    <Box
      direction="row"
      full="page"
      alignItems="center"
      pad={{ top: 'medium', right: 'medium', bottom: 'none', left: 'medium' }}
    >
      <Heading
        tag="h6"
        size="h6"
        margin="none"
        uppercase={true}
        weight="semibold"
        className={styles.header}
      >
        {customHeading ? customHeading : t('question')}
      </Heading>

      {isDraggable && <Box className={styles.handlebar} data-test="drag-handle" />}

      {showAtomTags && (
        <Box direction="row" pad={{ between: 'small', horizontal: 'small' }}>
          {!isPool && isSequenceUsed && (
            <Tag
              label={t('used_question')}
              iconName="icon-warning"
              theme="warning"
              tooltip={t('used_question_warning', {
                courseOrSection:
                  parentEntityType === ParentEntityType.Course ? t('course') : t('section'),
              })}
            />
          )}
          {isPrintFriendly && (
            <Tag label={t('print')} theme="secondary" tooltip={t('print_friendly')} />
          )}
          {!isPrintFriendly && isAssessmentOffline && (
            <Tag
              label={t('not_printable')}
              iconName="icon-warning"
              theme="warning"
              tooltip={t('not_printable_warning')}
            />
          )}
          {isLdbFriendly && <Tag label={t('ldb')} theme="secondary" tooltip={t('ldb_friendly')} />}
          {!isLdbFriendly && isAssessmentLdb && (
            <Tag
              label={t('no_ldb')}
              iconName="icon-warning"
              theme="warning"
              tooltip={t('no_ldb_warning')}
            />
          )}
          <Tag
            data-test="question-type"
            label={isPool ? t('pool') : t('static')}
            theme="secondary"
          />
          {markedCorrect && (
            <Tag
              data-test="override-correct-tag"
              label={t('result_override_correct')}
              theme="success"
              iconName="icon-check"
            />
          )}
          {markedIncorrect && (
            <Tag
              data-test="override-incorrect-tag"
              label={t('result_override_incorrect')}
              theme="error"
              iconName="icon-clear"
            />
          )}
          {removed && (
            <Tag
              data-test="override-removed-tag"
              label={t('result_override_removed')}
              theme="warning"
              iconName="icon-delete"
            />
          )}
          {Feature.isEnabled('alertOnMultipleAttemptsVersionFlag') && isRepeated && (
            <Tag
              data-test="repeated-tag"
              label={t('repeated')}
              iconName="icon-warning"
              theme="warning"
              tooltip={t('repeat_warning', {
                attemptsOrVersions: isAssessmentOffline ? t('versions') : t('attempts'),
              })}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

AtomVariationHeader.displayName = 'AtomVariationHeader'
AtomVariationHeader.defaultProps = {
  showAtomTags: true,
}
