import * as React from 'react'
import { SagaIterator } from 'redux-saga'
import { Action } from 'redux'
import { put } from 'redux-saga/effects'
import { defaultErrorSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import CODES from 'app/frontend/api/codes'
import STATUS_CODES from 'app/brackend/status-codes'

export const mergeStyles = (defaults: object, custom: object): object => {
  if (!custom) {
    return defaults
  }

  const merged = Object.assign({}, defaults)

  Object.keys(custom).forEach(field => {
    if (defaults[field]) {
      merged[field] = defaults[field] + ' ' + custom[field]
    } else {
      merged[field] = custom[field]
    }
  })

  return merged
}

export function* catchWrapper(
  apiCalls: () => SagaIterator,
  defaultResponse: () => Action = defaultErrorSnackbar
): SagaIterator {
  try {
    yield* apiCalls()
  } catch (e) {
    if (
      !(e.json && e.json.error) ||
      (e.json.error.code !== CODES.NOT_FOUND &&
        e.json.error.statusCode !== STATUS_CODES.NOT_FOUND &&
        e.json.error.code !== CODES.QUIZ_AVAILABLE_IN_PRINT &&
        e.json.error.code !== CODES.STUDENT_NOT_IN_SECTION)
    ) {
      // handle 404 with not found component in loading-reducer
      yield put(defaultResponse())
    }
  }
}

/**
 * Returns a value and callback used to toggle said value.
 */
export const useToggle = (initial: boolean) => {
  const [isSet, setIsSet] = React.useState(initial)
  const memoizedToggle = React.useCallback(() => setIsSet(prev => !prev), [])
  return [isSet, memoizedToggle] as const
}
