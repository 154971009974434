import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import {
  hideModal as hideModalAction,
  updateModal,
} from 'app/frontend/components/material/modal/modal-actions'
import { Heading } from 'app/frontend/components/material/heading'
import { tns } from 'app/frontend/helpers/translations/i18n'
import {
  CUSTOM_QUESTION_TYPES,
  getLearnosityIsReady,
  getLearnosityIsValid,
  INVALID_LEARNOSITY,
  LearnosityEditor,
} from 'app/frontend/components/compound/learnosity/'
import { ActivityNavigator } from 'app/frontend/components/activity-navigator/activity-navigator'
import type { _LearnosityEditor as LearnosityEditorType } from 'app/frontend/components/compound/learnosity/learnosity-editor'
import { Status } from 'app/frontend/components/compound/learnosity/learnosity-editor'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Box } from 'app/frontend/components/material/box'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import { Icon } from 'app/frontend/components/material/icon'
import { omitTypename } from 'app/frontend/helpers/apollo/client'
import { isEnabled } from 'app/frontend/helpers/feature'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import * as GET_ATOM_VARIATION from 'app/frontend/compositions/connected/atom-variation.gql'
import * as GET_SEQUENCE from 'app/frontend/compositions/connected/get-sequence/get-sequence.gql'
import * as GET_CUSTOM_ITEM_VARIATION_SEQUENCE from 'app/frontend/pages/material/teach/compositions/connected/get-custom-item-variation-sequence/get-custom-item-variation-sequence.gql'
import {
  CUSTOM_QUESTIONS_MODAL,
  CustomQuestionModalOptions,
  EditorMode,
  View,
} from './custom-questions-modal-types'
import * as ADD_CUSTOM_SEQUENCE from './add-custom-sequence-to-assessment.gql'
import * as UPDATE_CUSTOM_SEQUENCE from './update-custom-sequence-for-assessment.gql'
import * as styles from './custom-questions-modal.css'
import * as CREATE_CUSTOM_ITEM_VARIATION_FOR_ASSESSMENT from './create-custom-item-variation-for-assessment.gql'
import * as UPDATE_CUSTOM_ITEM_VARIATION_FOR_ASSESSMENT from './update-custom-item-variation-for-assessment.gql'

const t = tns('teach:custom_questions_modal')

export type Props = {
  assessmentId: string
  pathSequenceVariationId?: string
  questionJson?: GQL.QuestionJson
  questionType: CUSTOM_QUESTION_TYPES
  customSequence?: GQL.CustomItemVariationSequence
  atom?: Content.IAtom
  editorMode?: EditorMode
  poolSize?: number
  onVariationContentChange?: (isContentChanged: any) => void
  customItemVariationSequence?: GQL.CustomItemVariationSequence
  currentQuestionIndex?: number
}

export const CustomQuestionsEditor: React.FunctionComponent<Props> = ({
  assessmentId,
  pathSequenceVariationId,
  questionJson,
  questionType,
  atom,
  editorMode,
  onVariationContentChange,
  poolSize,
  customItemVariationSequence,
  currentQuestionIndex,
}) => {
  const sequenceId = atom?.id?.sequenceId
  const atomId = atom?.id?.atomId
  const variationId = atom?.id?.variationId
  const learnosityPayloads = customItemVariationSequence?.learnosityPayloads || []

  const [questionIndex, setQuestionIndex] = React.useState(currentQuestionIndex)
  const [newVariationId, setNewVariationId] = React.useState(variationId)
  const [learnosityEditorStatus, setLearnosityEditorStatus] = React.useState(Status.Ready)
  const [currentQuestionJson, setCurrentQuestionJson] = React.useState(questionJson)
  const learnosityEditor = React.useRef<LearnosityEditorType>()

  const dispatch = useDispatch()
  const showMessage = (message: string, iconName?: string) =>
    dispatch(showSnackbar({ message, iconName }))
  const [addSequenceMutation, { loading: addLoading, error: addError }] = useMutation(
    ADD_CUSTOM_SEQUENCE
  )
  const [updateSequenceMutation, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_CUSTOM_SEQUENCE
  )
  const [
    addCustomItemVariation,
    { loading: addVariationLoading, error: addVariationError },
  ] = useMutation(CREATE_CUSTOM_ITEM_VARIATION_FOR_ASSESSMENT)
  const [
    updateCustomItemVariation,
    { loading: updateVariationLoading, error: updateVariationError },
  ] = useMutation(UPDATE_CUSTOM_ITEM_VARIATION_FOR_ASSESSMENT)

  React.useEffect(() => {
    setCurrentQuestionJson(
      customItemVariationSequence ? learnosityPayloads[questionIndex]?.content : questionJson
    )
    setNewVariationId(customItemVariationSequence && learnosityPayloads[questionIndex]?.variationId)
  }, [questionIndex])

  const isLearnosityValid = useSelector(getLearnosityIsValid)
  const isLearnosityReady = useSelector(getLearnosityIsReady)

  React.useEffect(() => {
    if (addError || updateError || addVariationError || updateVariationError) {
      showMessage(t('save_error'))
    }
  }, [addError, updateError, addVariationError, updateVariationError])

  const addSequence = async (questionJsonContent: GQL.CustomSequenceInput): Promise<void> => {
    await addSequenceMutation({
      variables: {
        assessmentId,
        learnosity: questionJsonContent,
      },
      context: { silenceErrors: true },
    })
    hideModal()
    showMessage(t('add_success'), 'icon-progress-circle-complete')
  }

  const onAddVariation = async (questionJsonContent: GQL.CustomSequenceInput): Promise<void> => {
    await addCustomItemVariation({
      variables: {
        sequenceId,
        questionJson: omitTypename<GQL.CustomSequenceInput>(questionJsonContent),
      },
      refetchQueries: [
        { query: GET_CUSTOM_ITEM_VARIATION_SEQUENCE, variables: { sequenceId } },
        {
          query: GET_SEQUENCE,
          variables: { sequenceId },
        },
      ],
      awaitRefetchQueries: true,
      context: { silenceErrors: true },
    })
    hideModal()
    showMessage(t('add_variation_success'), 'icon-progress-circle-complete')
  }

  const onUpdateVariations = async (
    questionJsonContent: GQL.CustomSequenceInput
  ): Promise<void> => {
    await updateCustomItemVariation({
      variables: {
        variationId: newVariationId,
        sequenceId,
        questionJson: omitTypename<GQL.CustomSequenceInput>(questionJsonContent),
      },
      refetchQueries: [
        {
          query: GET_ATOM_VARIATION,
          variables: {
            sequenceId,
            atomId,
            variationId: newVariationId,
          },
        },
        { query: GET_CUSTOM_ITEM_VARIATION_SEQUENCE, variables: { sequenceId } },
      ],
      context: { silenceErrors: true },
    })
    onVariationContentChange(true)
    hideModal()
    showMessage(t('update_variation_success'), 'icon-progress-circle-complete')
  }

  const updateSequence = async (questionJsonContent: GQL.CustomSequenceInput): Promise<void> => {
    await updateSequenceMutation({
      variables: {
        assessmentId,
        sequenceId,
        pathSequenceVariationId,
        questionJson: omitTypename<GQL.CustomSequenceInput>(questionJsonContent),
      },
      context: { silenceErrors: true },
    })
    hideModal()
    showMessage(t('update_success'), 'icon-progress-circle-complete')
  }

  const onSave = async () => {
    const editorInstance = learnosityEditor.current
    try {
      const questionJsonContent = editorInstance.getJson()
      switch (editorMode) {
        case EditorMode.ADD_POOL_QUESTION:
          await onAddVariation(questionJsonContent)
          break
        case EditorMode.EDIT_CUSTOM_QUESTION:
          await updateSequence(questionJsonContent)
          break
        case EditorMode.EDIT_POOL_QUESTION:
        case EditorMode.FLAG_ENABLED_EDIT_CUSTOM_QUESTION:
          await onUpdateVariations(questionJsonContent)
          break
        default:
          await addSequence(questionJsonContent)
          break
      }
    } catch (e) {
      if (e.message === INVALID_LEARNOSITY) {
        showMessage(t('validation_error'))
      }
    }
  }

  const backToTypePicker = () => {
    const options: Partial<CustomQuestionModalOptions> = { view: View.Select }
    dispatch(updateModal(CUSTOM_QUESTIONS_MODAL, options))
  }

  const hideModal = () => dispatch(hideModalAction(CUSTOM_QUESTIONS_MODAL))

  const handleLearnosityLoadingState = (isLoading: Status) => {
    setLearnosityEditorStatus(isLoading)
  }

  return (
    <>
      <Box direction="row" responsive={false} alignItems="center">
        {!questionJson && isEnabled('customFreeResponseQuestions') && (
          <Icon
            className={styles.backButton}
            name="icon-back"
            size="large"
            focusable={true}
            onClick={backToTypePicker}
            role="button"
            title={t('back_to_type_picker')}
          />
        )}
        <Heading size="h3" id="modalTitle" margin={{ left: 'large' }}>
          {editorMode
            ? t('header', { context: editorMode })
            : questionJson
            ? t('header', { context: EditorMode.EDIT_CUSTOM_QUESTION })
            : t('header', { context: EditorMode.ADD_CUSTOM_QUESTION })}
        </Heading>
        {editorMode === EditorMode.ADD_POOL_QUESTION && (
          <Box alignItems="end" flex={true} margin={{ right: 'large', top: 'small' }}>
            <Paragraph size={'medium'} data-test="item-count">
              {t('question_number_of_the_pool', {
                questionNum: poolSize ? poolSize + 1 : 2,
                poolSize: poolSize ? poolSize + 1 : 2,
              })}
            </Paragraph>
          </Box>
        )}
        {editorMode === EditorMode.EDIT_POOL_QUESTION && (
          <Box alignItems="end" flex={true} margin={{ right: 'large', top: 'small' }}>
            <ActivityNavigator
              title={t('pool_question')}
              count={learnosityPayloads.length}
              index={questionIndex}
              onChange={setQuestionIndex}
              disabled={learnosityEditorStatus === Status.Loading}
            />
          </Box>
        )}
      </Box>
      <LearnosityEditor
        ref={learnosityEditor}
        questionType={questionType}
        questionData={currentQuestionJson}
        setLoadingState={handleLearnosityLoadingState}
      />
      <Box direction="row" justify="end" margin={{ right: 'large' }}>
        <ButtonMaterial
          theme="bordered"
          label={t('cancel')}
          onClick={hideModal}
          data-bi="cancel-button"
          className={styles.cancelButton}
        />
        <ButtonMaterial
          theme="primary"
          label={t('save')}
          onClick={onSave}
          disabled={
            !isLearnosityReady ||
            !isLearnosityValid ||
            addLoading ||
            updateLoading ||
            addVariationLoading ||
            updateVariationLoading
          }
          data-bi="save-custom-question-button"
        />
      </Box>
    </>
  )
}
