import { Reducer } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_GET_LEARN_FLOW,
  LearnFlowLoadAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'

export type DomainReducerState = Partial<Content.IDomain>

export const reducer = (
  state: DomainReducerState = {},
  action: LearnFlowLoadAction & ApiSuccessAction
): DomainReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_LEARN_FLOW.SUCCESS:
      return action.response.domain || state
    default:
      return state
  }
}

export default reducer as Reducer<DomainReducerState>
