import * as React from 'react'
import { sendTiming, TIME } from 'app/frontend/pages/metrics/metrics-actions'
import { connect } from 'react-redux'
import ContentImageAtom, {
  ImageInfo,
  OwnProps as ContentImageAtomOwnProps,
} from 'app/frontend/content/atoms/image-atom/image-atom'

export type OwnProps = ContentImageAtomOwnProps

type DispatchProps = {
  onSuccess: (imageInfo: ImageInfo) => void
}

type Props = OwnProps & DispatchProps

// A wrapper providing the flexibility to update the content component
export class ImageAtomWrapper extends React.Component<Props> {
  static displayName = 'ImageAtomWrapper'

  constructor(props: Props) {
    super(props)
  }

  onError = (imageInfo: ImageInfo) => {
    console.error('Image failed to load', imageInfo, { internalIssueId: 'CE-3445' })
  }

  onTimeout = (imageInfo: ImageInfo) => {
    console.error('Image took long time to load', imageInfo, { internalIssueId: 'CE-3424' })
  }

  render() {
    return <ContentImageAtom {...this.props} onError={this.onError} onTimeout={this.onTimeout} />
  }
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    onSuccess: (imageInfo: ImageInfo) => {
      dispatch(
        sendTiming([
          {
            name: 'content.image_load_time_' + Math.ceil(imageInfo.fileSize / 100000),
            value: imageInfo.loadTime,
            metricType: TIME,
          },
        ])
      )
    },
  }
}

export default connect<{}, DispatchProps, OwnProps>(null, mapDispatchToProps)(ImageAtomWrapper)
