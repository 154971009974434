import * as React from 'react'
import * as VideoSmallIconPng from 'app/public/img/video-small-icon.png'
import * as _ from 'lodash'
import { getStaticUrl } from 'app/frontend/helpers/assets'

/**
 * If the compound is wrapped in a table, we want the table to be the main element
 */
export const getHtmlToDisplaySummary = (element: any): string => {
  let html

  // For tables, we're only looking at th and tds
  if (element.childNodes.length > 1 && element.childNodes[0].tagName === 'TABLE') {
    const tableElement = element.querySelector('th, td')
    if (tableElement) {
      element = tableElement
    }
  }

  const htmlStripRegex = new RegExp('^(?:<b>|<strong>)(.*)(?:</b>|</strong>)$')

  // remove elements with no text inside
  const contentNodes: any[] = _.filter(element.childNodes, child => child.textContent.trim())

  // default html value is the first paragraph
  const htmlToDisplay = contentNodes[0].innerHTML
  if (htmlToDisplay && htmlToDisplay.length > 0) {
    // remove b and strong tags if they wrap htmlToDisplay
    const htmlStripped = htmlToDisplay.trim().match(htmlStripRegex)
    html = htmlStripped && htmlStripped.length > 1 ? htmlStripped[1] : htmlToDisplay
  } else if (contentNodes[0].innerText) {
    html = contentNodes[0].innerText
  } else if (contentNodes[0].nodeType === 3 && contentNodes[0].textContent) {
    // This is a text node
    html = contentNodes[0].textContent
  }

  return html
}

export type Props = {
  atom: Content.IAtom
  inline?: boolean
  className?: string
}

export class ContentSummary extends React.Component<Props, {}> {
  /**
   * Render a summary of the layout atom.
   */
  renderSummary(atom: Content.IAtom) {
    let html =
      atom.dataType === 'INSTRUCTIONAL_ATOM'
        ? (atom.data as GQL.AtomInstructionBlock).instruction
        : (atom.data as GQL.AtomLearnosityBlock | GQL.AtomMultiChoiceBlock).question

    // modify html a little bit
    const ele = document.createElement('div')
    ele.innerHTML = html
    const text = ele.textContent

    // is text empty?
    if (!_.trim(text)) {
      // then add a video icon (most likely it's a video only layout atom)
      const src = getStaticUrl(VideoSmallIconPng)
      html = `<img src="${src}" class="video-icon" aria-hidden="true" /> Video`
    } else {
      try {
        html = getHtmlToDisplaySummary(ele)
      } catch (e) {
        console.error('Failed to get html summary', {
          ...atom.id,
          dataType: atom.dataType,
          internalIssueId: 'CE-1865',
        })
      }
    }

    // If question sampling failed, catch it.
    if (typeof html !== 'string' || html.length === 0) {
      html = '<i>Click here to see content.</i>'
    }

    return html
  }

  /**
   * Renders the component.
   */
  render() {
    if (this.props.inline) {
      return (
        <span
          className={this.props.className}
          dangerouslySetInnerHTML={{ __html: this.renderSummary(this.props.atom) }}
        />
      )
    } else {
      return (
        <div
          className={this.props.className}
          dangerouslySetInnerHTML={{ __html: this.renderSummary(this.props.atom) }}
        />
      )
    }
  }
}

export default ContentSummary
