import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { from, ApolloClient, InMemoryCache } from '@apollo/client'
import { getTabId } from 'app/frontend/api'
import { Store } from 'redux'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { SESSION_EXPIRED_MODAL } from 'app/frontend/pages/material/session-expired-modal'
import { CSRF_MISMATCH_MODAL } from 'app/frontend/components/modals/csrf-mismtach-modal/csrf-mismatch-modal'
import CODES from 'app/frontend/api/codes'
import { fetch } from 'app/frontend/helpers/fetch'
import {
  showSnackbar,
  hideSnackbar,
} from 'app/frontend/components/material/snackbar/snackbar-actions'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { cacheBlockList, typePolicies, cacheRedirectQueries } from './policies'
import { hasGqlError } from 'app/frontend/helpers/apollo/error'

const t = tns('comps:query')

export function omitTypename<T>(json: T): T {
  return JSON.parse(JSON.stringify(json), (key, value) =>
    key === '__typename' ? undefined : value
  )
}

/**
 * If there's a client error, then the type is ErrorResponse, from @apollo/client/link/error.
 * If there's a server error, then the type is a hybrid of ErrorResponse, from @apollo/client/link/error,
 * and ApolloError, from @apollo/client/errors.
 *
 * Unfortunately these two have conflicting type properties that prevent a union, so the error
 * is difficult write a type for without building an entirely new type from scratch.
 */
const hasCsrfError = (error: any): boolean =>
  !!(
    error.networkError &&
    error.networkError?.result &&
    error.networkError?.result.code === CODES.CSRF_MISMATCH
  )
const hasGraphQLErrors = (error: any): boolean =>
  !!(error.graphQLErrors && error.graphQLErrors.length)
const hasAuthenticationError = (error: any): boolean =>
  hasGraphQLErrors(error) && hasGqlError(error, { statusCode: 401 })

export const errorResponseHandler = (error: ErrorResponse, store: Store<any>) => {
  const { silenceErrors, errorMessage } = error.operation.getContext()

  if (hasAuthenticationError(error)) {
    store.dispatch(showModal(SESSION_EXPIRED_MODAL))
  } else if (hasCsrfError(error)) {
    store.dispatch(showModal(CSRF_MISMATCH_MODAL))
  } else if (hasGraphQLErrors(error) && !silenceErrors) {
    // Log errors so that it gets reported to the backend and make UI tests fail.
    console.error('GraphQL request failed!', error.operation.operationName, error.graphQLErrors)

    store.dispatch(
      showSnackbar({
        message: errorMessage || t('generic_error'),
        timeout: null,
        actionLabel: t('dismiss'),
        handleClick: hideSnackbar,
      })
    )
  }
}

let client = null

export const getOrCreateClient = (store?: Store<any>): ApolloClient<any> => {
  if (client) {
    return client
  }

  if (!store) {
    throw new Error('Client needs a store on first instantiation.')
  }

  const sessionErrorLink = onError(err => errorResponseHandler(err, store))
  const batchHttpLink = new BatchHttpLink({
    uri: '/graphql',
    credentials: 'include',
    headers: { 'X-TAB-ID': getTabId() },
    fetch: fetch,
  })
  client = new ApolloClient({
    queryDeduplication: true,
    link: from([sessionErrorLink, batchHttpLink]),
    cache: new InMemoryCache({
      typePolicies: { ...typePolicies, ...cacheBlockList, ...cacheRedirectQueries },
      possibleTypes: {
        // https://github.com/apollographql/apollo-client/issues/1608
        LegacyAtom: ['Atom', 'LegacyLearnAtom'],
      },
    }),
  })
  return client
}
