export type NEW_ASSIGNMENT_ADDED_ANIMATION = 'NEW_ASSIGNMENT_ADDED_ANIMATION'
export const NEW_ASSIGNMENT_ADDED_ANIMATION: NEW_ASSIGNMENT_ADDED_ANIMATION =
  'NEW_ASSIGNMENT_ADDED_ANIMATION'

type NewAssignmentAddedAnimation = {
  type: NEW_ASSIGNMENT_ADDED_ANIMATION
  id: string
}

export const newAssignmentAddedAnimation = (id: string): NewAssignmentAddedAnimation => ({
  type: NEW_ASSIGNMENT_ADDED_ANIMATION,
  id,
})

export type ASSIGNMENT_ANIMATION_RUN = 'ASSIGNMENT_ANIMATION_RUN'
export const ASSIGNMENT_ANIMATION_RUN: ASSIGNMENT_ANIMATION_RUN = 'ASSIGNMENT_ANIMATION_RUN'

type AssignmentAnimationRun = {
  type: ASSIGNMENT_ANIMATION_RUN
}

export const assignmentAnimationRun = (): AssignmentAnimationRun => ({
  type: ASSIGNMENT_ANIMATION_RUN,
})

export type AssignmentMenuItemsActions = NewAssignmentAddedAnimation | AssignmentAnimationRun
