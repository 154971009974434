import * as React from 'react'
import * as _ from 'lodash'

import { useGetPresignedUrl } from './get-presigned-url'
import { useContentFeedback } from './use-content-feedback'
import { uploadScreenshot } from './upload-screenshot'

export type useSubmitContentFeedbackResults = {
  data?: GQL.ContentFeedback
}
export interface useSubmitContentFeedbackParams
  extends Commons.Omit<GQL.ContentFeedbackRequest, 'screenShot'> {
  screenShotDataUri?: string
}
export type useSubmitContentFeedbackCallback = (
  data: useSubmitContentFeedbackParams
) => Promise<useSubmitContentFeedbackResults>
export type UseSubmitContentFeedbackType = () => useSubmitContentFeedbackCallback

export const useSubmitContentFeedback: UseSubmitContentFeedbackType = () => {
  const [createContentFeedbackFn] = useContentFeedback()
  const { refetch: getPresignedUrlFn } = useGetPresignedUrl()

  const { current: fn } = React.useRef(
    async (data): Promise<useSubmitContentFeedbackResults> => {
      try {
        let screenShot
        if (data.screenShotDataUri) {
          const presignedResult = await getPresignedUrlFn()
          const uploadResult = await uploadScreenshot(
            data.screenShotDataUri,
            presignedResult.data.preSignedUrl
          )
          screenShot = uploadResult.url
        }
        const result = await createContentFeedbackFn({
          variables: { request: { ..._.omit(data, 'screenShotDataUri'), screenShot } },
          context: { silenceErrors: true },
        })
        return {
          data: result.data.createContentFeedback,
        }
      } catch (error) {
        console.error('Failed to submit content feedback', {
          internalIssueId: 'CE-3469',
          error,
          // omit any PII
          feedback: _.omit(data, ['screenShotDataUri']),
        })
        throw error
      }
    }
  )

  return fn
}
