import { Action } from 'redux'

export enum NavActions {
  UPDATE_PAGE_TITLE = 'UPDATE_PAGE_TITLE',
  TOGGLE_NAV_DRAWER = 'TOGGLE_NAV_DRAWER',
  TOGGLE_NAV_MENU = 'TOGGLE_NAV_MENU',
}

// namespacing allows import { Actions } to import both the enum and attached action methods
export namespace NavActions {
  export interface UpdatePageTitle extends Action {
    type: NavActions.UPDATE_PAGE_TITLE
    pageTitle: string
  }

  export const updatePageTitle = (pageTitle: string): UpdatePageTitle => {
    return {
      type: NavActions.UPDATE_PAGE_TITLE,
      pageTitle,
    }
  }

  export type ForceToggle = 'SHOW' | 'HIDE'

  export interface ToggleNavDrawer extends Action {
    type: NavActions.TOGGLE_NAV_DRAWER
    forceToggle?: ForceToggle
  }

  export const toggleNavDrawer = (forceToggle?: ForceToggle): ToggleNavDrawer => {
    return {
      type: NavActions.TOGGLE_NAV_DRAWER,
      forceToggle,
    }
  }

  export interface ToggleNavMenu extends Action {
    type: NavActions.TOGGLE_NAV_MENU
    forceToggle?: ForceToggle
  }

  export const toggleNavMenu = (forceToggle?: ForceToggle): ToggleNavMenu => {
    return {
      type: NavActions.TOGGLE_NAV_MENU,
      forceToggle,
    }
  }

  export type Any = ToggleNavMenu | ToggleNavDrawer | UpdatePageTitle
}

export default NavActions
