import * as React from 'react'
import { Input, InputProps } from 'accessible-react-toolbox/lib/input'
import * as classnames from 'classnames'
import * as _ from 'lodash'
const styles = require('./textinput.css')

/**
 * There is nothing yet unique about this component's props
 */
export interface ITextInputProps extends InputProps {
  placeholder?: string
  description?: any
  innerRef?: React.Ref<any>
  id?: string
}

export const TextInputMaterial: React.FunctionComponent<ITextInputProps> = (props): JSX.Element => {
  return (
    <Input
      {...props}
      theme={{
        ...props.theme,
        // merge provided theme with our default theme
        withIcon: classnames(styles.withIcon, _.get(props, 'theme.withIcon')),
        icon: classnames(styles.icon, _.get(props, 'theme.icon')),
        input: classnames(styles.textInput, _.get(props, 'theme.input')),
        bar: classnames(styles.bar, _.get(props, 'theme.bar')),
        inputElement: classnames(styles.inputElement, _.get(props, 'theme.inputElement')),
        label: classnames(styles.label, _.get(props, 'theme.label')),
        counter: classnames(styles.counter, _.get(props, 'theme.counter')),
        error: classnames(styles.error, _.get(props, 'theme.error')),
        errored: classnames(styles.errored, _.get(props, 'theme.errored')),
        description: classnames(styles.description, _.get(props, 'theme.description')),
        hint: classnames(styles.hint, _.get(props, 'theme.hint')),
      }}
    />
  )
}

TextInputMaterial.displayName = 'TextInputMaterial'
