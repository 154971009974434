export enum SortOrder {
  DESC = 'desc',
  ASC = 'asc',
}

export type IAnalyticsSortOrder = SortOrder.ASC | SortOrder.DESC

type CompletedSort = 'COMPLETED_SORT'
export const COMPLETED_SORT: CompletedSort = 'COMPLETED_SORT'

type OutlineSort = 'OUTLINE_SORT'
export const OUTLINE_SORT: OutlineSort = 'OUTLINE_SORT'

type WorkTimeSort = 'WORK_TIME_SORT'
export const WORK_TIME_SORT: WorkTimeSort = 'WORK_TIME_SORT'

type StatusSort = 'STATUS_SORT'
export const STATUS_SORT: StatusSort = 'STATUS_SORT'

type DueSort = 'DUE_SORT'
export const DUE_SORT: DueSort = 'DUE_SORT'

type MasterySort = 'MASTERY_SORT'
export const MASTERY_SORT: MasterySort = 'MASTERY_SORT'

export type IStudentAssignmentAnalyticsSortColumns =
  | StatusSort
  | WorkTimeSort
  | MasterySort
  | DueSort
  | OutlineSort

type ScoreSort = 'SCORE_SORT'
export const SCORE_SORT: ScoreSort = 'SCORE_SORT'

export type IStudentAssessmentAnalyticsSortColumns =
  | CompletedSort
  | ScoreSort
  | DueSort
  | OutlineSort

export enum StudentsMasterFilters {
  EXCELLING = 'EXCELLING',
  ON_TRACK = 'ON_TRACK',
  AT_RISK = 'AT_RISK',
}

export enum StudentsActivityFilters {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type IStudentsAnalyticsFilters =
  | StudentsMasterFilters
  | StudentsActivityFilters
  | IStudentsProgressFilters
  | boolean

export enum IStudentsProgressFilters {
  COMPLETE = 'complete',
  IN_PROGRESS = 'in_progress',
  STRUGGLING = 'struggling',
  NO_WORK = 'no_work',
  NOT_SELECTED_LO = 'not_selected_lo',
}

export const filtersDisplay = {
  EXCELLING: 'Above 90%',
  ON_TRACK: '60 to 90%',
  AT_RISK: 'Below 60%',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}

type DUE = 'due'
type ALL = 'all'
export type CompletionType = DUE | ALL
export const ALL: ALL = 'all'
export const DUE: DUE = 'due'

export enum AnalyticsCategory {
  ADAPTIVE_ASSIGNMENT = 'ADAPTIVE_ASSIGNMENT',
  QUIZ = 'QUIZ',
  TEST = 'TEST',
  HOMEWORK = 'HOMEWORK',
}

export enum LearningObjectiveTableColumns {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
}

export enum AllCourseworkSortColumns {
  DUE = 'DUE',
  MASTERY = 'MASTERY',
  OUTLINE = 'OUTLINE',
  STRUGGLING = 'STRUGGLING',
  REVIEWING_COUNTS = 'REVIEWING_COUNTS',
}

export enum OverallStatusTableColumns {
  FirstName = 'firstName',
  LastName = 'lastName',
  Status = 'status',
  Questions = 'numberOfQuestions',
  WorkTime = 'workTime',
  Mastery = 'progress',
  Score = 'score',
}

export enum AssessmentGradesFilters {
  BTW90AND100 = 'BTW90AND100',
  BTW80AND90 = 'BTW80AND90',
  BTW70AND80 = 'BTW70AND80',
  BTW0AND70 = 'BTW0AND70',
  NOT_STARTED = 'NOT_STARTED',
}
