import * as React from 'react'

export const usePopperMenu = () => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
      anchorRef.current?.focus()
    } else if (event.key === 'Escape') {
      setOpen(false)
      anchorRef.current?.focus()
    }
  }

  return {
    open,
    anchorRef,
    handleToggle,
    handleClose,
    handleListKeyDown,
  }
}
