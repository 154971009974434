/* tslint:disable:max-file-line-count */
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'

import { videoStop, videoPlay } from './video-actions'
import VideoHelper from 'app/frontend/helpers/video-helper'
import { tns } from 'app/frontend/helpers/translations/i18n'
import VideoControls from './video-controls'
import VideoError from './video-error'
import { VimeoLink } from './video-link'
import { getPlayingClipId } from './video-reducer'

import * as styles from './video-player-skin.css'
const t = tns('video_player')

interface OwnProps {
  end: number
  start: number
  atomId: string
  autoplay: boolean
  clipDuration: number
  clipId: string
  videoId: string
  providerId: string
  contentManager: ContentManagerActionsType
}

interface StateProps {
  playing: boolean
}

interface DispatchProps {
  videoPlay: () => void
  videoStop: () => void
}

interface State {
  captionsEnabled: boolean
  captionsOptions: VimeoCaptionOption[] // Captions options from Vimeo
  currentTimeSec: number // Time spent playing (s)
  playerState: 'LOADING' | 'LOADED' | 'ERROR'
  volume?: number
}

type Props = DispatchProps & StateProps & OwnProps

export interface VimeoCaptionOption {
  label: string
  language: string
  kind: 'captions' | 'subtitles'
  mode: 'showing' | 'disabled'
}

export class VimeoVideo extends React.Component<Props, State> {
  VimeoPlayer: any
  clipDuration: number
  interval: number
  videoAtom: Node
  controls: VideoControls
  jumpBackVolume: number
  playerContainer: Node
  playerAlertTimer: any
  reportAutoPlay: boolean
  player: any

  static readonly TIMEOUT_MS = 10000 // 10 seconds

  constructor(props: Props) {
    super(props)

    this.VimeoPlayer = null

    this.clipDuration = this.props.end - this.props.start || 0
    this.interval = this.clipDuration * 5

    this.state = {
      captionsEnabled: false,
      captionsOptions: [],
      currentTimeSec: 0,
      playerState: 'LOADING',
    }
  }

  /**
   * Component was mounted.
   */
  componentDidMount() {
    // Vimeo library requires the component be mounted because it accesses the DOM
    this.VimeoPlayer = require('@vimeo/player')
    this.videoAtom = ReactDOM.findDOMNode(this).parentNode
    this.loadPlayer()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Pause video if it was playing and now another one is playing
    if (prevProps.playing && !this.props.playing) {
      this.pauseVideo()
    }
    if (prevState.playerState === 'LOADING' && this.state.playerState === 'LOADED') {
      this.props.contentManager.success()
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.playerAlertTimer)
    this.props.videoStop()
  }

  /**
   * Loads the Vimeo player into our component.
   */
  loadPlayer = () => {
    // Create the Vimeo player and attach player actions to listeners

    const props = this.props

    const player = new this.VimeoPlayer(this.playerContainer, {
      id: props.videoId,
    })

    player
      .getTextTracks()
      .then(tracks => {
        this.setState({ captionsOptions: tracks })
      })
      .catch(err => {
        this.reportWarning('error loading captions', err)
      })

    // Add player listeners
    player.ready().then(this.onPlayerReady)
    player.on('pause', this.onPlayerPause)
    player.on('play', this.onPlayerPlay)
    player.on('ended', this.onPlayerEnded)
    player.on('error', this.onPlayerError)

    player.on('timeupdate', data => {
      /**
       * Listen for changes to the player time and setState to match it.
       *
       * In the case where the time is set before the video start time, we force it to the start,
       * thus re-triggering this callback.
       * In the case where the video has ended, or is past the end, we call onPlayerEnd() which
       * also forces the player's current time to the start, thus re-triggering this callback.
       */
      if (data.seconds < props.start) {
        // Are not yet at the start of our desired clip, so skip to beginning of clip.
        player.setCurrentTime(props.start)
        if (this.controls.controls !== 'native') {
          this.controls.scrubber.setValue(props.start)
        }
      } else if (data.seconds >= props.start && data.seconds < props.end) {
        // In our desired clip, so update currentTimeSec to update scrubber.
        this.setState({ currentTimeSec: data.seconds })
      } else if (data.seconds >= props.end) {
        this.onPlayerEnded()
      }
    })

    // If the Vimeo Player is not ready, timeout
    this.playerAlertTimer = setTimeout(() => {
      this.setState({ playerState: 'ERROR' })
      if (window.location.port) {
        // Video may have privacy settings that won't allow it to display
        // on localhost or ip addresses. See LEARN-2552.
        console.warn('Timeout waiting for the Vimeo api to load')
      } else {
        this.reportError('Timeout waiting for the Vimeo api to load')
      }
    }, VimeoVideo.TIMEOUT_MS)

    // save player
    this.player = player
  }

  /**
   * Plays the video.
   */
  autoplayVideo = () => {
    try {
      this.player.play()
    } catch (err) {
      this.reportWarning('error playing video', err)
    }
  }

  /**
   * Pauses the video.
   */
  pauseVideo = () => {
    try {
      this.player.pause()
      this.props.videoStop()
    } catch (err) {
      this.reportWarning('error pausing video', err)
    }
  }

  /**
   * Called by the vimeo player when it is ready.
   */
  onPlayerReady = () => {
    clearTimeout(this.playerAlertTimer)
    if (this.props.autoplay) {
      this.autoplayVideo()
    }
    const initialVolume = parseInt(window.localStorage.getItem('videoVolume'), 10)
    const initialMute = window.localStorage.getItem('videoMuted')
    if (initialVolume !== 0) {
      this.jumpBackVolume = initialVolume
    } else {
      this.jumpBackVolume = 100
    }
    this.setState({ playerState: 'LOADED' })

    if (initialMute) {
      this.setVolume(0)
    } else {
      this.setVolume(initialVolume)
    }
  }

  /**
   * Called when the user clicks on the player/pause button.
   */
  togglePlay = () => {
    this.props.playing ? this.player.pause() : this.player.play()
    this.onPlayerPlay()
  }

  /**
   * Called when the user slides the scrubber.
   */
  onScrubberSlide = (_event, value: number) => {
    this.player
      .setCurrentTime(value)
      .then()
      .catch(err => {
        this.reportWarning('error sliding scrubber', err)
      })
  }

  /**
   * Called when the video is starting playing.
   */
  onPlayerPlay = () => {
    this.props.videoPlay()
    this.reportAutoPlay = false
  }

  /**
   * Called when the video is paused.
   */
  onPlayerPause = () => {
    this.props.videoStop()
  }

  /**
   * Called when the video has finished playing.
   */
  onPlayerEnded = () => {
    this.player.setCurrentTime(this.props.start)
    this.pauseVideo()
    if (this.controls.controls !== 'native') {
      this.controls.scrubber.setValue(this.props.start)
    }
    this.props.videoStop()
  }

  /**
   * Called when an error happens after the player is initialized.
   * (e.g. click play and then video doesn't exist).
   *
   * We report this as a warning until we introspect on the errors and determine which
   * ones could benefit from re-mounting the component.
   */
  onPlayerError = (error: any) => {
    const name = error ? error.name : -1
    const message = error ? error.message : -1
    this.reportWarning('error playing video', {
      name,
      message,
    })
  }

  /**
   * Called when a user moves the volume slider.
   *
   * Returns the original volume before the change so it can be stored in this.jumpBackVolume
   *
   * @param pct 0-100, but Vimeo needs 0-1
   */
  setVolume = async (pct: number): Promise<number> => {
    const initialVolume = this.state.volume
    if (typeof pct !== 'number' || _.isNaN(pct)) {
      return initialVolume
    }

    if (this.player) {
      try {
        const actualVolume = await this.player.setVolume(VideoHelper.formatVolumeForVimeo(pct))
        // We store volume as a number between 0 and 100 while Vimeo volume is between 0 and 1
        this.setState({ volume: actualVolume * 100 })
        window.localStorage.setItem('videoVolume', `${pct}`)
      } catch (err) {
        this.reportWarning('error setting volume', err)
      }
    }
    return initialVolume
  }

  /**
   * Called when the user clicks on the volume button.
   */
  toggleMute = async (): Promise<void> => {
    // used to move slider back to previous level
    if (this.state.volume !== 0) {
      this.jumpBackVolume = await this.setVolume(0)
      window.localStorage.setItem('videoMuted', 'true')
    } else {
      this.setVolume(this.jumpBackVolume)
      window.localStorage.removeItem('videoMuted')
    }
  }

  /**
   * Called when the user toggles keypresses in the video
   */
  keyboardControls = (e: React.KeyboardEvent<any>) => {
    e.persist()
    const keyCode = e.keyCode

    // corresponds to: space
    if (keyCode === 32) {
      e.preventDefault()
      this.togglePlay()
    }

    // corresponds to [ and ]
    if (keyCode === 219 || keyCode === 221) {
      this.player
        .getVolume()
        .then(vol => {
          const currentVolume = Math.round(vol * 100)
          let newVolume = currentVolume
          if (keyCode === 221) {
            // corresponds to: ]
            // volume up
            e.preventDefault()
            newVolume = Math.min(100, currentVolume + 10)
            this.setVolume(newVolume)
          }
          if (keyCode === 219) {
            // corresponds to: [
            // volume down
            e.preventDefault()
            newVolume = Math.max(0, currentVolume - 10)
            this.setVolume(newVolume)
          }
        })
        .catch(err => {
          this.reportWarning('error getting volume', err)
        })
    }

    // corresponds to: f
    if (keyCode === 70) {
      e.preventDefault()
      this.toggleFullScreen(this.videoAtom.childNodes[0])
    }
  }

  /**
   * Toggle between fullscreen and not.
   */
  toggleFullScreen = node => {
    // is the player fullscreen?
    if (
      !(document as any).fullscreenElement &&
      !document.mozFullScreenElement &&
      !(document as any).webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // make player fullscreen
      if (document.documentElement.requestFullscreen) {
        node.requestFullscreen()
      } else if (document.documentElement.msRequestFullscreen) {
        node.msRequestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        node.mozRequestFullScreen()
      } else if ((document.documentElement as any).webkitRequestFullscreen) {
        node.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT)
      }
    } else {
      // exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if ((document as any).webkitExitFullscreen) {
        ;(document as any).webkitExitFullscreen()
      }
    }
  }

  /**
   * Called when the user clicks on the CC button to toggle captions on/off.
   */
  toggleCaptions = () => {
    if (this.state.captionsEnabled) {
      this.player
        .disableTextTrack()
        .then(() => {
          this.setState({ captionsEnabled: false })
        })
        .catch(err => {
          this.reportWarning('error disabling captions', err)
        })
    } else {
      const langCode = this.state.captionsOptions[0].language
      this.player
        .enableTextTrack(langCode)
        .then(() => {
          // track.language = the iso code for the language
          // track.kind = 'captions' or 'subtitles'
          // track.label = the human-readable label
          this.setState({ captionsEnabled: true })
        })
        .catch(err => {
          this.reportWarning('error enabling captions', err)
        })
    }
  }

  /**
   * Report a warning to our backend - the video player has loaded but is encountering
   * errors as the user interacts with it.
   */
  reportWarning = (message: string, error?) => {
    console.error(message, {
      error,
      videoId: this.props.videoId,
      internalIssueId: 'CE-3446',
    })
    this.props.contentManager.addWarning({ message })
  }

  /**
   * Report an error to our backend - the video player has encountered a fatal error.
   * Remounting the video may solve the problem.
   */
  reportError = (message: string, error?) => {
    console.error(message, {
      error,
      videoId: this.props.videoId,
      internalIssueId: 'CE-3446',
    })
    this.props.contentManager.addError({ message, isRetryable: true })
  }

  /**
   * Renders a link to this video on Vimeo
   */
  renderExternalLink = (text: string): JSX.Element => {
    return (
      <VimeoLink
        videoId={this.props.videoId}
        clipStartAtSeconds={this.props.start}
        clipEndAtSeconds={this.props.end}
      >
        {text}
      </VimeoLink>
    )
  }

  /**
   * Renders the component.
   */
  render(): JSX.Element {
    if (this.state.playerState === 'ERROR') {
      return <VideoError classNames={styles.videoAtom} videoLinkFn={this.renderExternalLink} />
    }

    // TODO CE-3816: We need a loading component

    return (
      <div
        className={styles.videoAtom}
        onKeyDown={this.keyboardControls}
        aria-busy={this.state.playerState === 'LOADING'}
      >
        <div className={styles.scale} />
        <div
          className={styles.loading}
          data-visible={this.state.playerState === 'LOADING'}
          aria-hidden={this.state.playerState !== 'LOADING'}
          aria-label={t('loading')}
        />
        <div id="player" ref={ref => (this.playerContainer = ref)} />
        /* It's simpler to not show controls until loaded */
        {this.state.playerState === 'LOADED' && (
          <VideoControls
            ref={controls => (this.controls = controls)}
            context={this.videoAtom}
            playButtonClass={this.props.playing ? 'play playing' : 'play'}
            state={this.state.playerState}
            playing={this.props.playing}
            onClickPlayButton={this.togglePlay}
            onScrubberSlide={this.onScrubberSlide}
            start={this.props.start}
            end={this.props.end}
            videoCurrentTime={this.state.currentTimeSec}
            onVolumeChange={this.setVolume}
            onVolumeButtonClick={this.toggleMute}
            volume={this.state.volume}
            onCaptionsButtonClick={this.toggleCaptions}
            captionsEnabled={this.state.captionsEnabled}
            captionsOptions={this.state.captionsOptions}
            toggleFullScreen={this.toggleFullScreen}
            aria-label="controls"
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  const { clipId } = ownProps
  const playingClipId = getPlayingClipId(state)
  const playing = playingClipId === clipId
  return {
    playing,
  }
}

const mapDispatchToProps = (dispatch, ownProps: OwnProps): DispatchProps => {
  return {
    videoPlay: () => dispatch(videoPlay(ownProps.clipId)),
    videoStop: () => dispatch(videoStop(ownProps.clipId)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps & { ref: any }>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(VimeoVideo)
