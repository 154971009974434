import {
  AssessmentLoSelectionAction,
  EditLosFailedAction,
  EditLosSucceededAction,
  LosStagedAction,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-lo-selection-actions'
import { entries } from 'lodash'
import {
  TopicLo,
  TopicLoErrorType,
  TopicLoStatus,
  TopicLoStatusMap,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'

interface SingleAssessmentLoSelectionState {
  topicLoStatuses: TopicLoStatusMap
}

export interface LoSelectionState {
  [assessmentId: string]: SingleAssessmentLoSelectionState
}

const defaultState = {}
const defaultSingleAssessmentState = {
  topicLoStatuses: {},
}

type RelevantActions = LosStagedAction | EditLosSucceededAction | EditLosFailedAction

/**
 * Reducer for redux state related to the assessment builder LO selection UI
 */
export const assessmentLoSelectionReducer = (
  state: LoSelectionState = defaultState,
  action: RelevantActions
): LoSelectionState => {
  const currentStateForAssessment =
    action.assessmentId && getSingleAssessmentState(state, action.assessmentId)
  let newSingleAssessmentState
  switch (action.type) {
    case AssessmentLoSelectionAction.EditStaged:
      newSingleAssessmentState = withNewTopicLoStatuses(
        currentStateForAssessment,
        action.affectedTopicLos,
        TopicLoStatus.Saving
      )
      return withSingleAssessmentState(state, action.assessmentId, newSingleAssessmentState)
    case AssessmentLoSelectionAction.EditSucceeded:
      newSingleAssessmentState = withNewTopicLoStatuses(
        withNoErrorStatuses(currentStateForAssessment),
        action.affectedTopicLos,
        TopicLoStatus.None
      )
      return withSingleAssessmentState(state, action.assessmentId, newSingleAssessmentState)
    case AssessmentLoSelectionAction.EditFailed:
      newSingleAssessmentState = withNewTopicLoStatuses(
        currentStateForAssessment,
        action.affectedTopicLos,
        TopicLoStatus.Error,
        action.errorType
      )
      return withSingleAssessmentState(state, action.assessmentId, newSingleAssessmentState)
  }

  return state
}

/**
 * Get the effective single-assessment lo selection state for a specific assessment
 */
function getSingleAssessmentState(
  state: LoSelectionState,
  assessmentId: string
): SingleAssessmentLoSelectionState {
  return state[assessmentId] || defaultSingleAssessmentState
}

/**
 * Update the reducer state with a new assessment-specific lo selection state
 */
function withSingleAssessmentState(
  state: LoSelectionState,
  assessmentId: string,
  singleAssessmentState: SingleAssessmentLoSelectionState
): LoSelectionState {
  return {
    ...state,
    [assessmentId]: singleAssessmentState,
  }
}

/**
 * Update the lo selection state for a specific assessement to clear all LO error states
 *
 * Note: Because LO selection requests are cumulative, not incremental, if any update request
 * completes successfully, we can be sure that all operations the user attempted up to the
 * point when the request was created are now persisted successfully.
 * This is tied to the behavior or the AssessmentTableOfContents component. If that component
 * listened for errors and undid the selection changes attempted by users when an error
 * occurred, this method would not be needed, as subsequent requests submitted by that
 * component would not act on LOs that previously entered the error state unless the user
 * had additional interactions with them.
 */
function withNoErrorStatuses(
  state: SingleAssessmentLoSelectionState
): SingleAssessmentLoSelectionState {
  const topicLoStatusUpdates: TopicLoStatusMap = {}

  for (const [topicId, loStatusMap] of entries(state.topicLoStatuses)) {
    const loStatusUpdates = topicLoStatusUpdates[topicId] || {}
    for (const [loId, { status }] of entries(loStatusMap)) {
      if (status === TopicLoStatus.Error) {
        loStatusUpdates[loId] = { status: TopicLoStatus.None }
        topicLoStatusUpdates[topicId] = loStatusUpdates
      }
    }
  }

  return withTopicLoStatusUpdates(state, topicLoStatusUpdates)
}

/**
 * Update the lo selection state for a specific assessment, setting the given status on
 * all of the given affected LOs.
 */
function withNewTopicLoStatuses(
  state: SingleAssessmentLoSelectionState,
  topicLos: TopicLo[],
  newStatus: TopicLoStatus,
  errorType?: TopicLoErrorType
): SingleAssessmentLoSelectionState {
  const topicLoStatusUpdates: TopicLoStatusMap = {}
  for (const topicLo of topicLos) {
    topicLoStatusUpdates[topicLo.topicId] = topicLoStatusUpdates[topicLo.topicId] || {}
    topicLoStatusUpdates[topicLo.topicId][topicLo.loId] = {
      status: newStatus,
      ...(errorType && { type: errorType }),
    }
  }

  return withTopicLoStatusUpdates(state, topicLoStatusUpdates)
}

/**
 * Update the state to reflect the given LO Status updates. Will create a new LO->Status map
 * for each topic affected by the specified updates. Will not create a new LO->Status map
 * for topics with no LO status changes.
 * @param state
 * @param topicLoStatusUpdates
 */
function withTopicLoStatusUpdates(
  state: SingleAssessmentLoSelectionState,
  topicLoStatusUpdates: TopicLoStatusMap
) {
  const newTopicLoStatuses = { ...state.topicLoStatuses }
  for (const [topicId, loStatusMap] of entries(topicLoStatusUpdates)) {
    // Make a copy of the LO status map for this topic and then overwrite the LOs with changes
    newTopicLoStatuses[topicId] = { ...newTopicLoStatuses[topicId], ...loStatusMap }
  }

  return {
    ...state,
    topicLoStatuses: newTopicLoStatuses,
  }
}
