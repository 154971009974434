import * as React from 'react'
import PopoverButton from 'app/frontend/components/material/button/popover-button'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/content-filters/filter-group.css'
import * as _ from 'lodash'
import { FilterGroup } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/content-filters/filter-group'
import { ButtonTheme } from 'app/frontend/components/material/button/button'
import {
  getFilterGroupTranslationText,
  getFilterTranslationText,
  isAllFilterSelected,
} from './helper'
import {
  FilterOption,
  FilterGroups,
  AssignmentUsageFilter,
  AssessmentUsageFilter,
  SelectionFilter,
} from 'app/typings/commons'

export interface Props {
  dataBi: string
  filterGroup: FilterGroups
  selectedFilter: AssignmentUsageFilter | AssessmentUsageFilter | SelectionFilter
  filterOptions: FilterOption[]
  onApply: (selectedFilterName: string, filterGroup: string) => void
}

export const FilterGroupWrapper: React.FunctionComponent<Props> = ({
  dataBi,
  filterGroup,
  selectedFilter,
  filterOptions,
  onApply,
}) => {
  const getTheme = filter => {
    if (isAllFilterSelected(filter)) {
      return 'bordered' as ButtonTheme
    }
    return 'primary' as ButtonTheme
  }

  const getLabel = filter => {
    // If 'All' selected, we display the filter group name. Otherwise, we show the selected filter name
    if (isAllFilterSelected(filter)) {
      return getFilterGroupTranslationText(filterGroup)
    }
    return getFilterTranslationText(filter)
  }

  const handlePopoverLabelAndTheme = filter => {
    getTheme(filter)
    getLabel(filter)
  }

  return (
    <PopoverButton
      id={filterGroup}
      label={getLabel(selectedFilter)}
      theme={getTheme(selectedFilter)}
      customPopoverCls={styles.customTheme}
      dataBi={dataBi}
    >
      <FilterGroup
        filterGroup={filterGroup}
        selectedFilter={selectedFilter}
        filterOptions={filterOptions}
        onApplyFilters={handlePopoverLabelAndTheme}
        onApply={onApply}
      />
    </PopoverButton>
  )
}

FilterGroupWrapper.displayName = 'FilterGroupWrapper'
