import * as React from 'react'
import { BoxProps, Box } from 'app/frontend/components/material/box'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './save-status-indicator.css'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { IconLoadingEllipsis } from 'app/frontend/components/material/icon'
import { IconSize } from 'app/frontend/components/material/icon'
const t = tns('save_indicator_status')

interface Props extends BoxProps {
  isSaving: boolean
  hasError: boolean
  className?: string
  iconSize?: IconSize
  savingLabel?: string
  errorLabel?: string
}

export const SaveStatusIndicator: React.FunctionComponent<Props> = ({
  isSaving,
  hasError,
  className,
  iconSize,
  savingLabel,
  errorLabel,
  ...boxProps
}) => {
  return (
    <Box
      direction="row"
      responsive={false}
      alignSelf="center"
      className={className}
      {...boxProps}
      data-test="save-status-indicator"
    >
      {isSaving && (
        <>
          <IconLoadingEllipsis size={iconSize} className={styles.loadingIcon} />
          <span data-test="saving-message">{savingLabel}</span>
        </>
      )}
      {!isSaving && hasError && (
        <>
          <Icon name="icon-error-outline" size={iconSize} className={styles.errorIcon} />
          <span className={styles.errorMsg} data-test="error-message">
            {errorLabel}
          </span>
        </>
      )}
    </Box>
  )
}
SaveStatusIndicator.defaultProps = {
  iconSize: 'large',
  savingLabel: t('saving_changes'),
  errorLabel: t('error_message'),
}

SaveStatusIndicator.displayName = 'SaveStatusIndicator'
