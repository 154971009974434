module.exports    = {
	"query": {
		"generic_error": "Something went wrong while loading this page. Please try again.",
		"dismiss": "Dismiss"
	},
	"copy_text_input": {
		"copied": "Copied",
		"copy": "Copy",
		"copy_error": "Something went wrong while copying. Please try again."
	},
	"search_input": {
		"search": "Search",
		"search_for": "Search for \"{{searchQuery}}\""
	}
}