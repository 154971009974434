import { useQuery } from '@apollo/client'
import * as GET_ASSESSMENT_SEQUENCES from './get-assessment-sequences.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseAssessmentSequencesResults = RenderProps<{
  assessmentSequences: GQL.AssessmentSequenceFields.PathSequences[]
}>

export const useAssessmentSequences = (assessmentId: string): UseAssessmentSequencesResults => {
  const { loading, error, data } = useQuery<
    GQL.GetAssessmentSequences.Query,
    GQL.GetAssessmentSequences.Variables
  >(GET_ASSESSMENT_SEQUENCES, {
    variables: {
      assessmentId,
    },
  })

  return {
    loading,
    error,
    assessmentSequences:
      data && data.getAssessmentSequences && data.getAssessmentSequences.pathSequences,
  }
}
