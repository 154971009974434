import { combineReducers } from 'redux'
import modalReducer, { ModalState } from 'app/frontend/pages/material/learn/modals/reducer'
import learnFlowUIReducer, { LearnFlowUIState } from './learn-flow/ui-reducer'
import quizFlowUIReducer, { QuizFlowUIState } from './quiz-flow/ui-reducer'
import { default as paymentsReducer, PaymentsState } from './payments/reducer'
import {
  SetActiveAssignmentAction,
  UiActionType,
  SetIsNewWileyPlus,
  SetIsNewWileyPlusAction,
  SetIsAnLms,
  SetIsAnLmsAction,
} from './ui-actions'
import {
  LearnControllerState,
  BootstrappedLearnControllerState,
} from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { MaterialControllerState } from 'app/frontend/layout/material/material-controller'
import reviewCenterUIReducer, {
  ReviewCenterReducerState,
} from 'app/frontend/pages/material/learn/review-center/review-center-reducer'
import * as Feature from 'app/frontend/helpers/feature'

export type LearnUiReducerState = {
  modal: ModalState
  learnFlow: LearnFlowUIState
  quizFlow: QuizFlowUIState
  payments: Partial<PaymentsState>
  reviewCenter: ReviewCenterReducerState
  mostRelevantCourseId: string
  activeAssignmentId: string | null
  isNewWileyPlus: boolean
  isAnLms: boolean
}

export const getActiveAssignmentId = (state: LearnControllerState) => state.ui.activeAssignmentId

export const activeAssignmentIdReducer = (state: string = null, action): string | null => {
  if (action.type === UiActionType.SetActiveAssignment) {
    const setActiveAssignmentAction = action as SetActiveAssignmentAction
    return setActiveAssignmentAction.activeAssignmentId || null
  } else {
    return state
  }
}

export const getIsNewWileyPlus = (state: LearnControllerState): boolean =>
  Feature.isEnabled('nwpReviewCenterFlag') || state.ui?.isNewWileyPlus || false

export const getIsAnLms = (state: LearnControllerState): boolean => {
  return state.ui?.isAnLms || false
}

export const isNewWileyPlusReducer = (state = false, action): boolean => {
  if (action.type === SetIsNewWileyPlus.actionType) {
    const typedAction = action as SetIsNewWileyPlusAction
    return typedAction.isNewWileyPlus
  }

  return state
}

export const isAnLmsReducer = (state = false, action): boolean => {
  if (action.type === SetIsAnLms.actionType) {
    const typedAction = action as SetIsAnLmsAction
    return typedAction.isAnLms
  }

  return state
}

/**
 * Return true if we are currently inside of an IFrame
 */
export const getInIFrame = (
  state: BootstrappedLearnControllerState | MaterialControllerState
): boolean => {
  return state.global.ui.inIFrame || false
}

export default combineReducers<LearnUiReducerState>({
  modal: modalReducer,
  learnFlow: learnFlowUIReducer,
  quizFlow: quizFlowUIReducer,
  payments: paymentsReducer,
  activeAssignmentId: activeAssignmentIdReducer,
  reviewCenter: reviewCenterUIReducer,
  mostRelevantCourseId: e => e || null,
  isNewWileyPlus: isNewWileyPlusReducer,
  isAnLms: isAnLmsReducer,
})
