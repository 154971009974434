import { makeApiActions } from 'app/frontend/api/api-redux'

export const API_REGISTER = makeApiActions('API_REGISTER')
export const SUBMIT_REGISTRATION_FORM = 'SUBMIT_REGISTRATION_FORM'

export const submitRegistrationForm = data => {
  return {
    type: SUBMIT_REGISTRATION_FORM,
    data,
  }
}
