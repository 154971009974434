import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { buildUrl } from './external-atom-util'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'
import { NewTabButton } from './external-button'
import * as styles from './external-atom.css'
import * as classNames from 'classnames'

const t = tns('external_atom')

export interface OwnProps {
  atom: GQL.AtomMedia
  contentManager: ContentManagerActionsType
  width?: string
  height?: string
}

export type Props = OwnProps

export type State = {
  loading: boolean
  error: boolean
}

export default class ExternalAtom extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
      error: false,
    }
  }

  onLoad = (): void => {
    this.props.contentManager.success()
    this.setState({
      loading: false,
    })
  }

  onError = (): void => {
    this.props.contentManager.addError({
      message: 'Error loading external content.',
      isRetryable: true,
    })
    this.setState({
      error: true,
      loading: false,
    })
    console.error('Error loading external media', {
      internalIssueId: 'ALPACA-249',
      media: {
        id: this.props.atom.id,
        ...this.props.atom.data,
      },
    })
  }

  renderErrorElement = (): JSX.Element => {
    const atom = this.props.atom
    const data = atom.data as GQL.AtomExternalUrlData

    return (
      <div className={classNames(styles.atomExternal, styles.error)}>
        {t('error_1', { uri: data.url })}
      </div>
    )
  }

  render(): JSX.Element {
    const atom = this.props.atom
    const data = atom.data as GQL.AtomExternalUrlData

    if (this.state.error) {
      return this.renderErrorElement()
    }

    const hasCustomDimensions = this.props.width && this.props.height

    const widthStyle = hasCustomDimensions ? { width: `${this.props.width}px` } : {}
    const heightStyle = hasCustomDimensions ? { height: `${this.props.height}px` } : {}
    const atomExternalStyle = hasCustomDimensions
      ? styles.atomExternalCustomDimension
      : styles.atomExternal

    return (
      <div
        className={styles.atomExternalContainer}
        data-test="atom-external-container"
        style={widthStyle}
        aria-busy={this.state.loading}
      >
        <NewTabButton url={data.url} />
        <div className={atomExternalStyle} data-test="atom-external" style={heightStyle}>
          <iframe
            src={buildUrl(window?.location?.origin, data.url)}
            onLoad={this.onLoad}
            onError={this.onError}
            seamless
            allow="fullscreen"
            allowFullScreen
          />
        </div>
      </div>
    )
  }
}
