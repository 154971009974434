import * as _ from 'lodash'
import { combineReducers } from 'redux'

import { ASSESSES, TEACHES } from 'app/frontend/data/purpose-type'
import {
  SELECT_TAXON,
  API_GET_ASSIGNMENT_COVER_DETAIL,
  API_CREATE_ASSIGNMENT_PREVIEW,
  SELECT_PURPOSE_TYPE,
  SELECT_LEARNING_OBJECTIVE,
  API_GET_SEQUENCES_FOR_SEARCH_FILTERS,
} from './assignment-cover-actions'

const taxonDefaultState = {
  isFetching: false,
  isPracticeFetching: false,
}
export const taxonErrorState = {
  isFetching: false,
  estimates: {
    totalTimeEstimateSeconds: 25 * 60,
    totalQuestionsEstimate: 30,
  },
  learningObjectives: {},
}

export function getAssessmentsAndInstructions(state: any = {}, action) {
  const purposeType = action.args[1].purposeType
  let storedSequences, returnedSequences, sequences

  switch (purposeType) {
    case ASSESSES:
      storedSequences = !_.isEmpty(state.assessments) ? state.assessments.sequences : []
      returnedSequences = action.response.content.sequences || []
      sequences = [...storedSequences, ...returnedSequences]

      return {
        ...state,
        assessments: {
          sequences: sequences,
          totalResultCount: action.response.content.totalResultCount,
          page: action.args[1].pageNum,
        },
      }
    case TEACHES:
      storedSequences = !_.isEmpty(state.instructions) ? state.instructions.sequences : []
      returnedSequences = action.response.content.sequences || []
      sequences = [...storedSequences, ...returnedSequences]

      return {
        ...state,
        instructions: {
          sequences: sequences,
          totalResultCount: action.response.content.totalResultCount,
          page: action.args[1].pageNum,
        },
      }
    default:
      return state
  }
}

export function taxonPreface(state = {}, action) {
  let taxonId, searchFilters, learningObjectiveState, selectedLearningObjectiveState

  switch (action.type) {
    case SELECT_PURPOSE_TYPE:
      taxonId = action.taxonId
      return {
        [taxonId]: {
          ...(state[taxonId] || taxonDefaultState),
          selectedPurposeType: action.purposeType,
        },
      }
    case SELECT_LEARNING_OBJECTIVE:
      taxonId = action.taxonId
      return {
        [taxonId]: {
          ...(state[taxonId] || taxonDefaultState),
          selectedLearningObjectiveId: action.learningObjectiveId,
        },
      }
    case API_GET_ASSIGNMENT_COVER_DETAIL.INIT:
      taxonId = action.args[0]
      searchFilters = action.args[1]
      return {
        [taxonId]: {
          ...(state[taxonId] || taxonDefaultState),
          isFetching: true,
          isContentFetching: true,
        },
      }
    case API_GET_ASSIGNMENT_COVER_DETAIL.SUCCESS:
      taxonId = action.args[0]
      searchFilters = action.args[1]

      return {
        [taxonId]: {
          ...(state[taxonId] || taxonDefaultState),
          isFetching: false,
          isContentFetching: false,
          estimates: {
            totalTimeEstimateSeconds: action.response.estimates.totalTimeEstimate,
            totalQuestionsEstimate: action.response.estimates.totalQuestionsEstimate,
          },
          selectedPurposeType: searchFilters.purposeType,
          selectedLearningObjectiveId: searchFilters.learningObjectiveId,
          learningObjectives: {
            [searchFilters.learningObjectiveId]: getAssessmentsAndInstructions({}, action),
          },
        },
      }
    case API_GET_ASSIGNMENT_COVER_DETAIL.ERROR:
      taxonId = action.args[0]
      return {
        [taxonId]: taxonErrorState,
      }
    case API_CREATE_ASSIGNMENT_PREVIEW.INIT:
      taxonId = action.args[0].taxonId
      return {
        [taxonId]: {
          ...(state[taxonId] || taxonDefaultState),
          isPracticeFetching: true,
        },
      }
    case API_CREATE_ASSIGNMENT_PREVIEW.SUCCESS:
    case API_CREATE_ASSIGNMENT_PREVIEW.ERROR:
    case API_CREATE_ASSIGNMENT_PREVIEW.CANCEL:
      taxonId = action.args[0].taxonId
      return {
        [taxonId]: {
          ...(state[taxonId] || taxonDefaultState),
          isPracticeFetching: false,
        },
      }
    case API_GET_SEQUENCES_FOR_SEARCH_FILTERS.INIT:
      taxonId = action.args[0]
      searchFilters = action.args[1]
      return {
        [taxonId]: {
          ...(state[taxonId] || {}),
          isContentFetching: true,
        },
      }
    case API_GET_SEQUENCES_FOR_SEARCH_FILTERS.SUCCESS:
      taxonId = action.args[0]
      searchFilters = action.args[1]
      learningObjectiveState = state[taxonId].learningObjectives || {}
      selectedLearningObjectiveState =
        learningObjectiveState[searchFilters.learningObjectiveId] || {}
      return {
        [taxonId]: {
          ...(state[taxonId] || taxonDefaultState),
          isContentFetching: false,
          selectedPurposeType: searchFilters.purposeType,
          selectedLearningObjectiveId: searchFilters.learningObjectiveId,
          learningObjectives: {
            ...learningObjectiveState,
            [searchFilters.learningObjectiveId]: getAssessmentsAndInstructions(
              selectedLearningObjectiveState,
              action
            ),
          },
        },
      }
    case API_GET_SEQUENCES_FOR_SEARCH_FILTERS.ERROR:
      taxonId = action.args[0]
      return {
        [taxonId]: {
          ...state[taxonId],
          isContentFetching: false,
        },
      }
    default:
      return state
  }
}
export const getTaxonPreface = taxonId => state => state.assignmentCover.taxonPreface[taxonId]

export const selectedTaxonIdDefaultState = null
export function selectedTaxonId(state = selectedTaxonIdDefaultState, action) {
  switch (action.type) {
    case SELECT_TAXON:
      return action.taxonId
    default:
      return state
  }
}

export default combineReducers({
  selectedTaxonId,
  taxonPreface,
})
