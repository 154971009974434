import * as React from 'react'
import { QuestionPoints } from 'app/frontend/compositions/ux/question-points'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './question-heading.css'

const t = tns('atom_variation')

interface Props {
  points: number
  questionNumber: number
}

export const QuestionHeadingWithPoints: React.FunctionComponent<Props> = ({
  points,
  questionNumber,
}) => {
  return (
    <span className={styles.questionHeadingWrapper}>
      <span data-test="question-number" className={styles.questionHeading}>
        {t('question_number', { number: questionNumber })}
      </span>
      <QuestionPoints points={points} />
    </span>
  )
}

QuestionHeadingWithPoints.displayName = 'QuestionHeadingWithPoints'
