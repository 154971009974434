import { Reducer } from 'redux'
import { createSelector } from 'reselect'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import { UiActionType, ComponentLoadAction } from 'app/frontend/pages/material/learn/ui/ui-actions'
import {
  API_START_QUIZ,
  API_GET_QUIZ_PREVIEW,
  StartQuizAction,
} from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { convertAtomsToCompoundStates, isCompoundAnswered } from 'app/frontend/helpers/compound'
import { API_SEND_LEARNING_EVENTS } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { SendEventsAction } from 'app/frontend/pages/material/learn/learn-flow/events/learn-flow-events-actions'
import { UnansweredCompoundsState } from 'app/frontend/pages/material/learn/ui/quiz-flow/unanswered-compounds-reducer'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export type CompoundsReducerState = Learn.IStateAndContent[]

type CompoundAction =
  | ((SendEventsAction | StartQuizAction) & ApiSuccessAction)
  | ComponentLoadAction

const getQuizFlowCompounds = (state: LearnControllerState): Learn.IStateAndContent[] =>
  state.entities.quizFlow.compounds

export const getNumberOfQuestionsAnswered = createSelector(
  getQuizFlowCompounds,
  compounds =>
    compounds.filter(compound => {
      return isCompoundAnswered(compound.compoundInstance)
    }).length
)

export const getUnansweredCompounds = (state: LearnControllerState): UnansweredCompoundsState => {
  const { compounds } = state.entities.quizFlow

  return compounds.reduce((unansweredCompounds, compound) => {
    if (!isCompoundAnswered(compound.compoundInstance)) {
      unansweredCompounds[compound.compoundInstance.id] = true
    }
    return unansweredCompounds
  }, {})
}

export const getCompoundInstanceIdsForQuiz = (state: LearnControllerState): string[] => {
  const { compounds } = state.entities.quizFlow
  return compounds.map(compound => compound.compoundInstance.id)
}

export const reducer = (
  state: CompoundsReducerState = [],
  action: CompoundAction
): CompoundsReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_START_QUIZ.SUCCESS:
      return (action as StartQuizAction & ApiSuccessAction).response.states
    case API_GET_QUIZ_PREVIEW.SUCCESS:
      const responseAction = action as ApiSuccessAction
      return convertAtomsToCompoundStates(
        responseAction.response.atoms,
        responseAction.response.pathSequences
      )
    case API_SEND_LEARNING_EVENTS.SUCCESS:
      const responseCompounds = (action as SendEventsAction & ApiSuccessAction).response.states
      const updatedCompounds = state.map(
        compound =>
          responseCompounds.find(c => c.compoundInstance.id === compound.compoundInstance.id) ||
          compound
      )
      return updatedCompounds
    case UiActionType.ComponentLoad:
      return (action as ComponentLoadAction).name === 'COURSE_COVER' ||
        (action as ComponentLoadAction).name === 'QUIZ_COVER'
        ? []
        : state
    default:
      return state
  }
}

export default reducer as Reducer<CompoundsReducerState>
