export enum LtiLaunchType {
  // This parameter is used to create a new section in an LMS, it should only be used by section
  // initialization links. This link should only be used by instructors
  SECTION_INITIALIZATION_LAUNCH_PARAM = 'course_id',

  // This parameter is used to launch an assignment (adaptive, quiz or test)
  COURSEWORK_LAUNCH_PARAM = 'coursework_id',

  // Goal id launches are deprecated, use coursework_id
  GOAL_LAUNCH_PARAM = 'goal_id',

  // Review center for a test
  COURSEWORK_REVIEW_CENTER_LAUNCH_PARAM = 'review_center_coursework_id',

  // Review center for a section
  SECTION_REVIEW_CENTER_LAUNCH_PARAM = 'review_center_section_id',
  // DEPRECATED - old link for a section review center (retailclasses were sometimes called courses
  // and became what are currently sections
  REVIEW_CENTER_LAUNCH_PARAM = 'course_review_center_id',

  // Review center for a course
  COURSE_REVIEW_CENTER_LAUNCH_PARAM = 'review_center_course_id',
}

// Launch urls
export const GOAL_LAUNCH = `/lti/launch?${LtiLaunchType.GOAL_LAUNCH_PARAM}=`
export const COURSEWORK_LAUNCH = `/lti/launch?${LtiLaunchType.COURSEWORK_LAUNCH_PARAM}=`
export const COURSEWORK_REVIEW_CENTER_LAUNCH = `/lti/launch?${LtiLaunchType.COURSEWORK_REVIEW_CENTER_LAUNCH_PARAM}=`
export const REVIEW_CENTER_LAUNCH = `/lti/launch?${LtiLaunchType.REVIEW_CENTER_LAUNCH_PARAM}=`
export const COURSE_REVIEW_CENTER_LAUNCH = `/lti/launch?${LtiLaunchType.COURSE_REVIEW_CENTER_LAUNCH_PARAM}=`
export const SECTION_LAUNCH = `/lti/launch?${LtiLaunchType.SECTION_INITIALIZATION_LAUNCH_PARAM}=`

// Error contact message
export const contactMessage =
  'If you are a student, please contact your instructor for assistance. ' +
  'Instructors should contact their LMS admin, or email us at support@knewton.com for more assistance.'

export const lmsErrorGuideLink =
  'https://support.knewton.com/en/s/article/Common-LMS-Errors-for-Knewton-Alta'

export const ltiGuidelineLink =
  'https://support.knewton.com/en/s/topic/0TO0W000001hPVwWAM/knewton-alta-lms-stepbystep-integration-guides?tabset-98643=2'
export const replaceLtiLinksGuidelineLink =
  'https://support.knewton.com/en/s/article/Replacing-Individual-LTI-Links-for-Alta-Coursework-Items-in-Learning-Management-Systems'

export const unsafeContactMessageWithSupportLink =
  `If you are a student, please contact your instructor for assistance. ` +
  `If you are an instructor, please go <a href=${lmsErrorGuideLink} target="_blank">here</a> ` +
  `for step by step instructions to address this issue.`

export const unsafeInitializationMessageWithLink =
  `If you are a student, please contact your instructor for assistance. ` +
  `If you are the instructor, please create your Alta section by clicking ` +
  `the initialization link (often titled Link this Section to Knewton Alta ` +
  `or the name of the course) that was imported as part of the Common ` +
  `Cartridge. Find step by step instructions per LMS <a href=${ltiGuidelineLink} target="_blank">here</a>.`

export const unsafeContactMsgWithLink =
  `If you are a student, please contact your instructor for assistance. ` +
  `Instructors should contact their LMS admin to check the Consumer Key and Secret. ` +
  `Please share the LMS admin guides from <a href=${ltiGuidelineLink} target="_blank">here</a> with your administrator.`

export const TESTING_PARAM = 'testing'

export const ltiLaunchTypes = [
  LtiLaunchType.GOAL_LAUNCH_PARAM,
  LtiLaunchType.COURSEWORK_REVIEW_CENTER_LAUNCH_PARAM,
  LtiLaunchType.REVIEW_CENTER_LAUNCH_PARAM,
  LtiLaunchType.SECTION_REVIEW_CENTER_LAUNCH_PARAM,
  LtiLaunchType.COURSE_REVIEW_CENTER_LAUNCH_PARAM,
  LtiLaunchType.SECTION_INITIALIZATION_LAUNCH_PARAM,
  LtiLaunchType.COURSEWORK_LAUNCH_PARAM,
]

// TODO enum
export const ltiLaunchErrorTypes = {
  LTI_ERROR_MISSING_PARAMETER: 'LTI_ERROR_MISSING_PARAMETER',
  LTI_ERROR_MISSING_CONSUMER_KEY: 'LTI_ERROR_MISSING_CONSUMER_KEY',
  LTI_ERROR_MISSING_CONTEXT_ID: 'LTI_ERROR_MISSING_CONTEXT_ID',
  LTI_ERROR_INVALID_TYPE_PARAM: 'LTI_ERROR_INVALID_TYPE_PARAM',
  LTI_OAUTH_SIGNATURE_INVALID: 'LTI_OAUTH_SIGNATURE_INVALID',
  LTI_TOKEN_INVALID: 'LTI_TOKEN_INVALID',
  LTI_CONSUMER_NOT_FOUND: 'LTI_CONSUMER_NOT_FOUND',
  NO_PATH_WITH_COURSEWORK_ID: 'NO_PATH_WITH_COURSEWORK_ID',
  LTI_ERROR_USER_ROLE_NOT_INSTRUCTOR: 'LTI_ERROR_USER_ROLE_NOT_INSTRUCTOR',
  LTI_ERROR_UNSUPPORTED_VERSION: 'LTI_ERROR_UNSUPPORTED_VERSION',
  LTI_ERROR_UNSUPPORTED_MESSAGE_TYPE: 'LTI_ERROR_UNSUPPORTED_MESSAGE_TYPE',
  LTI_ERROR_INSTRUCTOR_LAUNCHED_REVIEW_CENTER: 'LTI_ERROR_INSTRUCTOR_LAUNCHED_REVIEW_CENTER',
  LMS_CONTEXT_ID_MISMATCH: 'LMS_CONTEXT_ID_MISMATCH',
  LMS_CONTEXT_ID_NOT_FOUND: 'LMS_CONTEXT_ID_NOT_FOUND',
  MORE_THAN_ONE_PATH_WITH_COURSEWORK_ID: 'MORE_THAN_ONE_PATH_WITH_COURSEWORK_ID',
  LMS_CONTEXT_ID_ALREADY_USED_BY_ANOTHER_SECTION: 'LMS_CONTEXT_ID_ALREADY_USED_BY_ANOTHER_SECTION',
  COURSE_INSTITUTION_NOT_SET: 'COURSE_INSTITUTION_NOT_SET',
  LTI_ERROR_TOOL_STATE_NO_SESSION: 'LTI_ERROR_TOOL_STATE_NO_SESSION',
  LTI_ERROR_INVALID_TOOL_STATE: 'LTI_ERROR_INVALID_TOOL_STATE',
  LTI_ERROR_INVALID_LAUNCH_ID: 'LTI_ERROR_INVALID_LAUNCH_ID',
  LTI_ERROR_INVALID_DOMAIN: 'LTI_ERROR_INVALID_DOMAIN',
  LTI_OAUTH_TIMESTAMP_REFUSED: 'LTI_OAUTH_TIMESTAMP_REFUSED',
  LTI_OAUTH_NONCE_USED: 'LTI_OAUTH_NONCE_USED',
}
