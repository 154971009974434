import * as _ from 'lodash'
import { SagaIterator } from 'redux-saga'
import { put, select, all } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import {
  showSnackbar,
  hideSnackbar,
} from 'app/frontend/components/material/snackbar/snackbar-actions'
import { SHOW_MODAL } from 'app/frontend/components/material/modal/modal-actions'
import { MaterialControllerState } from 'app/frontend/layout/material/material-controller'
import { sendEvent } from 'app/frontend/helpers/mixpanel'
import * as i18next from 'i18next'
import * as Events from 'app/frontend/data/mixpanel-events'

// No interrupt whitelist for pages where we don't want to interrupt the user
const BLACK_LIST = ['quiz']

// Patch number difference threshold
const BUILD_THRESHOLD = 50

interface IFrackendVersion {
  major: number
  minor: number
  patch: number
}

const parseVersion = (version: string): IFrackendVersion => {
  const matches = version.match(/(\d+)\.(\d+)\.(\d+)/) || []
  if (matches.length !== 4) {
    return
  }

  return {
    major: parseInt(matches[1], 10),
    minor: parseInt(matches[2], 10),
    patch: parseInt(matches[3], 10),
  }
}

const handleReloadPromptClick = () => {
  sendEvent(Events.RELOAD_PROMPT_CLICK)
  window.location.reload()
}

export const isFrackendStale = (
  frontendVersion: string,
  backendVersion: string,
  delta: number
): boolean => {
  const frontend = frontendVersion && parseVersion(frontendVersion)
  const backend = backendVersion && parseVersion(backendVersion)

  // Frontend version should never be greater than backend version, this is a defensive check.
  if (!frontend || !backend || backend.major < frontend.major || backend.minor < frontend.minor) {
    return false
  }

  if (
    frontend.major < backend.major ||
    frontend.minor < backend.minor ||
    frontend.patch < backend.patch - delta
  ) {
    return true
  }
  return false
}

export function* hideReloadPrompt(): SagaIterator {
  yield put(hideSnackbar())
}

export function* showReloadPrompt(): SagaIterator {
  if (
    !isFrackendStale(window.context.frontendVersion, window.context.backendVersion, BUILD_THRESHOLD)
  ) {
    return
  }

  const modalShown = !_.isEmpty(
    yield select((state: MaterialControllerState) => state.global.ui.modal)
  )

  if (modalShown) {
    return
  }

  for (const url of BLACK_LIST) {
    if (window.location.href.match(url)) {
      // Hide the prompt in case it's already shown
      yield* hideReloadPrompt()
      return
    }
  }

  yield put(
    showSnackbar({
      message: i18next.t('reload_prompt:reload_prompt'),
      actionLabel: i18next.t('reload_prompt:refresh'),
      timeout: 50000,
      handleClick: handleReloadPromptClick,
    })
  )
  sendEvent(Events.RELOAD_PROMPT_SHOW)
}

export default function* reloadPromptSaga(): SagaIterator {
  yield all([takeEvery(LOCATION_CHANGE, showReloadPrompt), takeEvery(SHOW_MODAL, hideReloadPrompt)])
}
