import * as React from 'react'
import * as keycode from 'keycode'
import { connect } from 'react-redux'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { FFLIP_MODAL } from 'app/frontend/layout/material/modals/fflip'
import FFlipModal from './modals/fflip'
import { redirect } from 'app/frontend/helpers/redirect'
import { PendoTagger } from 'app/frontend/layout/material/pendo/pendo-tagger'

interface DispatchProps {
  showFFlipModal: () => void
}

type Props = DispatchProps

export class _InternalShortcuts extends React.Component<Props, {}> {
  /**
   * Shortcuts using
   * Alt + __
   */
  checkAlt(e: KeyboardEvent): void {
    if (!e.altKey) {
      return
    }

    switch (keycode(e)) {
      case 'f':
        this.props.showFFlipModal()
        break
      default:
        break
    }
  }

  /**
   * Shortcuts using
   * Alt + Shift + __
   */
  checkAltShift(e: KeyboardEvent): void {
    if (!e.altKey || !e.shiftKey) {
      return
    }

    switch (keycode(e)) {
      case 'l':
        redirect('/learn')
        break
      case 't':
        redirect('/teach')
        break
      case 'k':
        redirect('/knerdtools')
        break
      default:
        break
    }
  }

  onKeydown = (e: KeyboardEvent): void => {
    this.checkAlt(e)
    this.checkAltShift(e)
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this.onKeydown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydown, false)
  }

  render() {
    return (
      <>
        <FFlipModal />
        <PendoTagger />
      </>
    )
  }
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    showFFlipModal: () => dispatch(showModal(FFLIP_MODAL)),
  }
}

export const InternalShortcuts = connect<{}, DispatchProps, {}>(
  null,
  mapDispatchToProps
)(_InternalShortcuts)
