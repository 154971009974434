import * as React from 'react'
import * as classnames from 'classnames'
import * as styles from './circle.css'

const Circle: React.FunctionComponent<{
  style?: any
  className?: string
}> = ({ children, style, className }) => (
  <div className={classnames(styles.circle, className)} style={style}>
    {children}
  </div>
)

export default Circle
