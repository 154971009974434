import * as React from 'react'
import { useState } from 'react'
import { Box } from 'app/frontend/components/material/box'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './question-points.css'
import { convertToPercent2Decimals } from 'app/frontend/helpers/percentage'
import { EditQuestionPointsWrapper } from './edit-question-points-wrapper'
import { AssessmentSequenceWithOverride } from 'app/frontend/helpers/assignment'
import { QuestionPoints } from './question-points'
import { Tooltip } from 'app/frontend/components/tooltip'
import { hasGqlError } from 'app/frontend/helpers/apollo/error'
import {
  hideSnackbar,
  showSnackbar,
} from 'app/frontend/components/material/snackbar/snackbar-actions'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import * as UPDATE_ASSESSMENT_SEQUENCE_POINTS from 'app/frontend/compositions/connected/update-assessment-sequence-points/update-assessment-sequence-points.gql'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_POINTS_SAVE,
  TEACH_SECTION_ASSESSMENT_POINTS_SAVE,
} from 'app/frontend/data/mixpanel-events'

const t = tns('edit_question_points')
interface QuestionPointsWrapperProps {
  assessmentId: string
  sequenceId: string
  assessmentSequences: AssessmentSequenceWithOverride[]
  readOnly: boolean
  points: number
  weight: number
  isPoolQuestion: boolean
  parentEntityType?: Commons.ParentEntityType
}

type Props = QuestionPointsWrapperProps

export const EditableQuestionPoints: React.FunctionComponent<Props> = ({
  assessmentId,
  readOnly,
  points,
  weight,
  sequenceId,
  assessmentSequences,
  isPoolQuestion,
  parentEntityType,
}) => {
  const [isEditQuestionsActive, setIsEditQuestionsActive] = useState<boolean>(false)
  const decimalToPercentage = convertToPercent2Decimals(weight)
  const [updateMutation, { loading, error }] = useMutation<
    GQL.UpdateAssessmentSequencePoints.Mutation,
    GQL.UpdateAssessmentSequencePoints.Variables
  >(UPDATE_ASSESSMENT_SEQUENCE_POINTS)

  const dispatch = useDispatch()

  const onCancel = () => {
    setIsEditQuestionsActive(false)
  }

  const onSave = async (updatedPointsValue: number) => {
    try {
      await updateMutation({
        variables: { assessmentId, sequenceId, points: updatedPointsValue },
      })
    } catch (error) {
      dispatch(hideSnackbar()) // Hide any existing error snackbars before rendering new one
      if (hasGqlError(error, { errorType: 'ASSESSMENT_ALREADY_STARTED' })) {
        dispatch(showSnackbar({ message: t('assessment_started') }))
      }
    }
    // send edit assessment question points mixpanel event if readOnly is false
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course && !readOnly
        ? TEACH_COURSE_ASSESSMENT_POINTS_SAVE
        : TEACH_SECTION_ASSESSMENT_POINTS_SAVE,
      assessmentId
    )
    setIsEditQuestionsActive(false)
  }

  return (
    <>
      <Box
        onClick={() => {
          setIsEditQuestionsActive(true)
        }}
      >
        {readOnly ? (
          <QuestionPoints points={points} weight={weight} />
        ) : (
          <Tooltip title={t('edit_question_tooltip')} placement="top">
            <QuestionPoints points={points} weight={weight} className={styles.clickable} />
          </Tooltip>
        )}
      </Box>
      {!readOnly && (
        <EditQuestionPointsWrapper
          sequenceId={sequenceId}
          assessmentSequences={assessmentSequences}
          points={points}
          weight={decimalToPercentage}
          isActive={isEditQuestionsActive}
          isSaving={loading}
          hasError={!!error}
          onCancel={onCancel}
          onSave={onSave}
          isPoolQuestion={isPoolQuestion}
        />
      )}
    </>
  )
}

EditableQuestionPoints.displayName = 'EditableQuestionPoints'
