/**
 * Gets all of the names of the MathJax dependency files that have not loaded correctly.
 *
 * See http://docs.mathjax.org/en/latest/api/ajax.html for more details.
 */
export function getAjaxLoadErrors(): Set<string> {
  return new Set(
    Object.entries(MathJax.Ajax.loaded)
      .filter(([_path, status]) => status === MathJax.Ajax.STATUS.ERROR)
      .map(([path]) => path)
  )
}

/**
 * Returns whether or not the MathJax engine has loaded and initialized correctly.
 *
 * Because MathJax keeps a record of historical signals, the caller does note need to worry
 * about a race condition. If MathJax is already ready, the promise will be resolved immediately.
 *
 * We determine "success" by ensuring each dependency has been loaded correctly.
 *
 * @returns {Promise<boolean>} Returns when MathJax thinks it's ready with a boolean indicating
 * whether or not we think MathJax has started up in a successful state, or not.
 *
 * See http://docs.mathjax.org/en/latest/advanced/signals.html?highlight=StartupHook for more
 * details.
 */
export const whenReady = (): Promise<boolean> => {
  return new Promise(resolve => {
    // This hook won't fire until we're done loading our most important requirements.
    // We ensure that they all loaded successfully to consider ourselves successful.
    const id = MathJax.Hub.Register.StartupHook('End', () => {
      resolve(getAjaxLoadErrors().size === 0)
      MathJax.Hub.UnRegister.StartupHook(id)
    })
  })
}

/**
 * Registers a callback for Tex Jax errors.
 *
 * This error occurs when invalid LaTeX is parsed, either due to extensions not loading, or the
 * tex actually being invalid.
 *
 * @param cb the callback to execute whenever this error occurs
 * @returns a function to call in order to unsubscribe
 */
export const listenForTexJaxError = (cb: (message: string) => void) => {
  const id = MathJax.Hub.Register.MessageHook('TeX Jax - parse error', cb)
  return () => {
    MathJax.Hub.UnRegister.MessageHook(id)
  }
}

/**
 * Registers a callback for Math Processing errors.
 *
 * This error occurs when the MathJax processing engine explodes while attempting to typeset
 * math.
 *
 * @param cb the callback to execute whenever this error occurs
 * @returns a function to call in order to unsubscribe
 */
export const listenForMathProcessingError = (cb: (message: string) => void) => {
  const id = MathJax.Hub.Register.MessageHook('Math Processing Error', cb)
  return () => {
    MathJax.Hub.UnRegister.MessageHook(id)
  }
}
