import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Heading } from 'app/frontend/components/material/heading'
import { tns } from 'app/frontend/helpers/translations/i18n'
import ToggleButtonGroup from 'app/frontend/components/material/toggle-button-group/toggle-button-group'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import {
  QuestionViewPreferencesOptionsView,
  QuestionViewPreferencesOptionsViewDensity,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'

const t = tns('teach:assessment_question_view_preferences')

export interface Props {
  onRequestClose?: () => void
  viewBy: QuestionViewPreferencesOptionsView
  displayDensity: QuestionViewPreferencesOptionsViewDensity
  updateQuestionViewPreferences: (
    viewBy: QuestionViewPreferencesOptionsView,
    density: QuestionViewPreferencesOptionsViewDensity
  ) => void
}

export const QuestionViewPreferencesContent: React.FunctionComponent<Props> = ({
  onRequestClose,
  viewBy,
  displayDensity,
  updateQuestionViewPreferences,
}) => {
  const [viewByState, setViewByState] = React.useState(viewBy)
  const [densityState, setDensityState] = React.useState(displayDensity)
  /**
   * Functions that handles ToggleButtonGroup changes
   * Handles view-options
   * @param value
   */
  const handleOrderOption = (value: QuestionViewPreferencesOptionsView) => {
    setViewByState(value)
  }
  /**
   * Functions that handles ToggleButtonGroup changes
   * Handles display density
   * @param value
   */
  const handleDensity = (value: QuestionViewPreferencesOptionsViewDensity) => {
    setDensityState(value)
  }
  /**
   * Function that execute when click on apply button
   */
  const handleApply = () => {
    updateQuestionViewPreferences(viewByState, densityState)
    onRequestClose()
  }

  /**
   * Function that execute when click on cancel button
   */
  const handleCancel = () => {
    onRequestClose()
  }

  return (
    <Box>
      <Heading
        data-test="question-order-preference-heading"
        tag="h5"
        pad={{ left: 'small' }}
        size="h5"
        margin={'small'}
        id="viewGroupLabel"
        role="presentation"
      >
        {t('view_title')}
      </Heading>
      <Box pad={{ left: 'medium' }}>
        <ToggleButtonGroup
          options={[
            {
              value: QuestionViewPreferencesOptionsView.BY_OBJECTIVE,
              label: t('objective').toUpperCase(),
            },
            {
              value: QuestionViewPreferencesOptionsView.BY_ORDER,
              label: t('order').toUpperCase(),
            },
          ]}
          name={'view-option'}
          selectedOption={viewByState}
          onSelectionChange={handleOrderOption}
          labelledby="viewGroupLabel"
          ariaDescribedby="objectiveSecondaryLabel"
        />
        <Paragraph size="small" margin="small" id="objectiveSecondaryLabel">
          {t('view_description')}
          <strong data-test="questions-view-by">
            {viewByState === QuestionViewPreferencesOptionsView.BY_OBJECTIVE
              ? t('objective').toLowerCase()
              : t('order').toLowerCase()}
          </strong>
        </Paragraph>
      </Box>
      <Heading
        data-test="question-order-preference-heading"
        tag="h5"
        pad={{ left: 'small' }}
        size="h5"
        margin={'small'}
        id="displayGroupLabel"
        role="presentation"
      >
        {t('display_title')}
      </Heading>
      <Box margin={{ left: 'medium' }}>
        <ToggleButtonGroup
          options={[
            {
              value: QuestionViewPreferencesOptionsViewDensity.EXPANDED,
              label: t('expanded').toUpperCase(),
            },
            {
              value: QuestionViewPreferencesOptionsViewDensity.COLLAPSED,
              label: t('collapsed').toUpperCase(),
            },
          ]}
          name={'display-option'}
          selectedOption={densityState}
          onSelectionChange={handleDensity}
          labelledby="displayGroupLabel"
          ariaDescribedby="expandedSecondaryLabel"
        />
        <Paragraph size="small" margin="small" id="expandedSecondaryLabel">
          {t('display_description')}
          {densityState === QuestionViewPreferencesOptionsViewDensity.EXPANDED ? (
            <span>
              <strong data-test="display-density-expanded">{t('expanded').toLowerCase()}</strong>{' '}
              {t('all_content')}
            </span>
          ) : (
            <span>
              <strong data-test="display-density-collapsed">{t('collapsed').toLowerCase()}</strong>{' '}
              {t('uniform_height')}
            </span>
          )}
        </Paragraph>
      </Box>
      <Box
        direction={'row'}
        alignSelf="end"
        responsive={true}
        margin="medium"
        pad={{ between: 'medium' }}
      >
        <ButtonMaterial
          label={t('cancel')}
          theme={'bordered'}
          onClick={handleCancel}
          data-bi="question-order-preferences-cancel"
        />
        <ButtonMaterial
          label={t('apply')}
          onClick={handleApply}
          data-bi="question-order-preferences-apply"
        />
      </Box>
    </Box>
  )
}

QuestionViewPreferencesContent.displayName = 'QuestionViewPreferencesContent'
