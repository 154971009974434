import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
import * as GET_SECTION_QUERY from './get-section.gql'

export interface GetSectionRenderProps {
  section?: GQL.GetSection.Section
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QuerySectionArgs & {
  children: (props: GetSectionRenderProps) => JSX.Element
}

export const GetSectionQuery: React.FunctionComponent<Props> = ({ id, children }) => (
  <Query<GQL.GetSection.Query, GQL.GetSection.Variables>
    query={GET_SECTION_QUERY}
    variables={{ id, skip: !id }}
  >
    {({ data, loading, error }) =>
      children({
        section: isEmpty(data) ? null : data.section,
        loading,
        error,
      })
    }
  </Query>
)

GetSectionQuery.displayName = 'GetSectionQuery'

export default GetSectionQuery
