import { countBy, forEach, isEmpty, mapValues } from 'lodash'
import {
  AssessmentSequenceWithOverride,
  isPrintable,
  SequenceVariationCount,
} from 'app/frontend/helpers/assignment'
import { isPoolSequenceAtom } from 'app/frontend/helpers/atom-variation'

export interface SequenceIdToAssessmentsMapType {
  [id: string]: string[]
}
type Assessment =
  | Commons.IPath
  | GQL.Assignment
  | GQL.GetAssignment.Assignment
  | GQL.GetAssignmentsForCourse.Results

type StaticAndPoolCount = {
  staticQuestionCount: number
  poolQuestionCount: number
}

/**
 * function will return true if given sequence is used in other assessments
 * @ReturnType boolean
 */
export const isSequenceUsed = (
  sequenceId: string,
  assessmentId: string,
  assessedSequencesMap: SequenceIdToAssessmentsMapType
) => {
  return assessedSequencesMap?.[sequenceId]?.some(id => id !== assessmentId) ?? false
}

/**
 * Returns the count for the question that is used in other assessments
 * Pool questions are ignored
 *
 * @param sequences
 * @param assessedSequences
 * @param assessmentId
 * @returns {number} - used question count
 */
export const getUsedQuestionCount = (
  sequences: GQL.Sequence[],
  assessedSequences: SequenceIdToAssessmentsMapType,
  assessmentId: string
): number => {
  let questionCount = 0
  if (!isEmpty(sequences) && !isEmpty(assessedSequences)) {
    forEach(sequences, sequence => {
      if (!isPoolSequenceAtom(sequence.atoms[0] as Content.ISequenceAtom)) {
        questionCount += assessedSequences[sequence.id]?.some(aId => aId !== assessmentId) ? 1 : 0
      }
    })
  }

  return questionCount
}
/**
 * Returns the minimum count required for each sequence in the assessment to be displayed without
 * repetition across multiple versions and attempts.
 *
 * Groups the questions by sequenceId and multiplies them with the num of versions or attempts to
 * get the minimum count.
 */
export const getSequenceVariationCount = (
  assessment: Assessment,
  sequences: AssessmentSequenceWithOverride[]
): SequenceVariationCount => {
  const numVersionsOrAttempts = isPrintable(assessment)
    ? assessment.quizConfiguration.printableVariantsCount
    : assessment.quizConfiguration.maxNumAttempts
  return calculateMinVariationCount(numVersionsOrAttempts, sequences)
}

/**
 * Calculate the minimum variation count required for each sequence in the assessment to be displayed without
 * repetition across multiple versions and attempts.
 *
 * @param numVersionsOrAttempts - the versions or attempts count configured in the assessment
 * @param sequences - the sequence list in the assessment
 */
const calculateMinVariationCount = (
  numVersionsOrAttempts: number,
  sequences: AssessmentSequenceWithOverride[] | GQL.Sequence[]
): SequenceVariationCount => {
  const numQuestionsBySequenceId = countBy(
    sequences,
    (ps: AssessmentSequenceWithOverride | GQL.Sequence) => ('id' in ps ? ps.id : ps.sequenceId)
  )
  const minVariationCountForSequences = mapValues(
    numQuestionsBySequenceId,
    numQuestions => numQuestions * numVersionsOrAttempts
  )
  return minVariationCountForSequences
}

/**
 * Returns the total repeatable static and pool question
 * counts in the given sequence list
 *
 * @param versionsOrAttemptsCount - the version or attempt count
 * @param sequences - the sequence list
 */
export const getRepeatableStaticAndPoolCount = (
  versionsOrAttemptsCount: number,
  sequences: GQL.Sequence[]
): StaticAndPoolCount => {
  let poolQuestionCount = 0
  let staticQuestionCount = 0
  // count grouped sequence IDs
  const minVariationCountForSequences = calculateMinVariationCount(
    versionsOrAttemptsCount,
    sequences
  )
  sequences?.forEach(sequence => {
    // variation count
    const variationCount = sequence.atoms[0].variations.length
    // check whether custom item or not
    const isCustomAssessmentItem = sequence?.isCustomAssessmentItem
    // get min variation count for matching sequence ID
    const minVariationCount = minVariationCountForSequences[sequence.id]
    // check is repeatable
    if (variationCount < minVariationCount && isCustomAssessmentItem) {
      variationCount > 1 ? poolQuestionCount++ : staticQuestionCount++
    } else if (variationCount < minVariationCount) {
      isPoolSequenceAtom(sequence.atoms[0]) ? poolQuestionCount++ : staticQuestionCount++
    }
  })

  return {
    staticQuestionCount,
    poolQuestionCount,
  }
}
