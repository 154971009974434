/**
 * Wrap Atoms (groups of Content) with this Provider to provide Actions and State to descendant
 * components.
 */
export { default as ContentManagerProvider } from './content-manager-provider'

/**
 * Consume from the Context either to dispatch "loading" actions (i.e. useContentManagerActions)
 * or to inspect the state of loading (i.e. useContentManagerState)
 */
export {
  useContentManagerActions,
  ContentManagerActions,
  ContentManagerActionsType,
} from './content-manager-actions'
export { useContentManagerState } from './content-manager-state'

export type { ContentError, ContentWarning, State, Dispatch } from './context'
