import * as React from 'react'
import * as moment from 'moment'
import * as classnames from 'classnames'
import * as keycode from 'keycode'
import { IconButton, TextField } from '@mui/material'
import TodayIcon from '@mui/icons-material/Today'
import { DateTimeFormats } from 'app/helpers/timezone'
import { ComboCalendar } from './combo-calendar'
import { useDateTimePicker } from './use-datetimepicker'
import { useStyles } from './styles'
import { DateProps } from '../datepicker'
import { TimeProps } from '../timepicker'

export type Props = DateProps &
  TimeProps & { timePickerLabel: string; className?: string; beforeNow?: boolean }

export const Datetimepicker: React.FunctionComponent<Props> = ({
  value,
  label,
  error,
  dataBi,
  minDate = moment().add(-2, 'years'),
  maxDate = moment().add(2, 'years'),
  className,
  dateFormat = DateTimeFormats.MONTH_DATE,
  timeFormat = DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE,
  timeFormatRegex,
  disabled,
  onChange,
  allowUndefined = false,
  acceptAnyFormat,
  timePickerLabel,
  timepickerId,
  inputStyle,
  beforeNow,
  iconButtonAriaLabel,
}) => {
  const styles = useStyles()
  const dateTimeFormat = `${dateFormat}, ${timeFormat}`
  const container = React.useRef<HTMLDivElement>()
  const inputField = React.useRef<HTMLDivElement>()
  const buttonRef = React.useRef<HTMLButtonElement>()

  const {
    formattedInputValue,
    isPickerOpen,
    showPicker,
    hidePicker,
    setText,
    handleTextBlur,
    error: dateError,
  } = useDateTimePicker({
    dateFormat,
    value,
    minDate,
    maxDate,
    onChange,
    allowUndefined,
    acceptAnyFormat,
    timeFormat,
    timepickerId,
    container,
  })

  const handleIconKeyDown = (e: React.KeyboardEvent<SVGElement>) => {
    if (keycode(e.keyCode) === 'space' || keycode(e.keyCode) === 'enter') {
      e.preventDefault()
      showPicker()
      return
    }
  }

  const handleChangeInCombo = (val: moment.Moment) => {
    setText(val ? val.format(dateTimeFormat) : '')
  }

  const isError = error || dateError

  return (
    <div ref={container} className={classnames(styles.static)}>
      <div ref={inputField} className={className} data-bi={dataBi}>
        <TextField
          error={!!(error || dateError)}
          value={formattedInputValue}
          disabled={disabled || isPickerOpen}
          onChange={e => setText(e.target.value)}
          label={label}
          InputLabelProps={{
            classes: { root: styles.label },
          }}
          InputProps={{
            classes: {
              root: styles.inputRoot,
              underline: styles.underline,
            },
          }}
          inputProps={{
            'data-bi': `${dataBi}-input`,
          }}
          onBlur={handleTextBlur}
          className={inputStyle}
          variant={'standard'}
        />
        <IconButton
          ref={buttonRef}
          className={styles.icon}
          onClick={() => {
            if (isPickerOpen) {
              hidePicker()
            } else {
              showPicker()
            }
          }}
          disabled={disabled}
          aria-label={iconButtonAriaLabel}
          data-test="calendar-icon"
        >
          <TodayIcon onKeyDown={handleIconKeyDown} />
        </IconButton>
        {isError && (
          <span className={styles.error} role="alert">
            {error || dateError}
          </span>
        )}
      </div>
      <ComboCalendar
        pickerOpen={isPickerOpen}
        hidePicker={() => {
          hidePicker()
          // after closing the datepicker popover, the focus is getting reset to the root.
          // to avoid such behaviour, this explicit button focus event is added to keep
          // focus within the clicked datepicker field.
          buttonRef.current.focus()
        }}
        onChange={handleChangeInCombo}
        setChange={onChange}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        timeFormat={timeFormat}
        timeFormatRegex={timeFormatRegex}
        timePickerLabel={timePickerLabel}
        timepickerId={timepickerId}
        disabled={disabled}
        beforeNow={beforeNow}
      />
    </div>
  )
}
