import * as React from 'react'
import * as classnames from 'classnames'
import * as styles from './modal-footer.css'
import { Stack } from '@mui/material'

type Props = {
  className?: string
  sticky?: boolean
}

export const ModalFooter: React.FunctionComponent<Props> = ({ sticky, className, children }) => (
  <div className={classnames(styles.modalFooter, className, { [styles.sticky]: sticky })}>
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="flex-end"
      maxWidth={theme => theme?.modal?.maxWidth}
      width={'100%'}
      mx={{ sm: 'auto' }}
      spacing={{ xs: '0.5rem', sm: '1rem' }}
    >
      {children}
    </Stack>
  </div>
)

ModalFooter.displayName = 'ModalFooter'

export default ModalFooter
