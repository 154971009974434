import * as React from 'react'
import CircleSpinner from 'app/frontend/content/circle-spinner/circle-spinner'
import * as classNames from 'classnames'
import { tns } from 'app/frontend/helpers/translations/i18n'
import type { ContentManagerActionsType } from 'app/frontend/components/content/manager'

const t = tns('image_atom')
import * as styles from './image-atom.css'
const IMG_LOAD_TIME_REPORT_THRESHOLD = 7000

export interface ImageInfo {
  imageUrl: string
  loadTime: number
  numRetries: number
  fileSize: number
}

export interface OwnProps {
  atom: GQL.AtomMedia
  customWidth?: string
  contentManager: ContentManagerActionsType
}

export interface ActionProps {
  onTimeout: (imageInfo: ImageInfo) => void
  onError: (imageInfo: ImageInfo) => void
  onSuccess: (imageInfo: ImageInfo) => void
}

export type Props = OwnProps & ActionProps

export type State = {
  loading: boolean
  error: boolean
  numRetries: number
}

export default class ImageAtom extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
      error: false,
      numRetries: 0,
    }

    this.loadingStartTime = window.performance.now()
  }

  loadingStartTime: number

  /**
   * Returns the caption element if there is a caption.
   */
  caption = (data: GQL.AtomImageData) => {
    if (data.caption) {
      return (
        <div className={styles.caption}>
          <div data-test="caption-wrapper">{data.caption}</div>
        </div>
      )
    }
  }

  /**
   * Returns the accessibility long description element if there is a long description.
   */
  longDescription = (data: GQL.AtomImageData, id: string) => {
    if (data.longDescription) {
      return (
        <div
          className={styles.longDescription}
          id={id}
          dangerouslySetInnerHTML={{ __html: data.longDescription }}
        />
      )
    }
  }

  /**
   * Returns the src attribute for the <img> tag.
   */
  getImageSrc = (data: GQL.AtomImageData) => {
    // forces browser to look for new image
    return data.imageUrl
  }

  onLoad = (): void => {
    const loadTime = window.performance.now() - this.loadingStartTime
    const atomImage = this.props.atom.data as GQL.AtomImageData
    this.props.contentManager.success()
    if (loadTime > IMG_LOAD_TIME_REPORT_THRESHOLD) {
      this.props.onTimeout({
        imageUrl: atomImage.imageUrl,
        loadTime: loadTime,
        fileSize: atomImage.fileSize,
        numRetries: this.state.numRetries,
      })
    }
    if (atomImage.fileSize) {
      this.props.onSuccess({
        imageUrl: atomImage.imageUrl,
        loadTime: loadTime,
        fileSize: atomImage.fileSize,
        numRetries: this.state.numRetries,
      })
    }
    this.setState({
      loading: false,
    })
  }

  onError = (): void => {
    const loadTime = window.performance.now() - this.loadingStartTime
    const atomImage = this.props.atom.data as GQL.AtomImageData
    this.props.onError({
      imageUrl: atomImage.imageUrl,
      loadTime: loadTime,
      fileSize: atomImage.fileSize,
      numRetries: this.state.numRetries,
    })
    this.props.contentManager.addError({ message: t('error_for_manager'), isRetryable: true })
    this.setState({
      error: true,
      loading: true,
    })
  }

  renderErrorElement = (): JSX.Element => (
    <div className={classNames(styles.atomImage, styles.error)}>
      {t('error_1')}
      <span className={styles.link} onClick={this.onRetry}>
        {t('error_2')}
      </span>
      {t('error_3')}
    </div>
  )

  onRetry = (): void => {
    this.loadingStartTime = window.performance.now()
    this.setState(prevState => ({
      loading: true,
      error: false,
      numRetries: prevState.numRetries + 1,
    }))
  }

  /**
   * Renders the component.
   */
  render(): JSX.Element {
    const atom = this.props.atom
    const data = atom.data as GQL.AtomImageData
    let longDescriptionId = null
    if (data.longDescription) {
      longDescriptionId = atom.id + '-long-desc'
    }
    const widthStyle = this.props.customWidth ? { width: `${this.props.customWidth}px` } : {}

    if (this.state.error) {
      return this.renderErrorElement()
    }

    return (
      <div className={styles.atomImage} aria-busy={this.state.loading}>
        {this.state.loading && (
          <div aria-label={t('loading')} className={styles.spinner}>
            <CircleSpinner />
          </div>
        )}
        <div data-test="atom-image-wrapper">
          <div className={this.state.loading ? styles.hidden : ''}>
            <img
              onLoad={this.onLoad}
              onError={this.onError}
              src={`${this.getImageSrc(data)}?${this.state.numRetries}`}
              alt={data.altText}
              style={widthStyle}
              aria-describedby={longDescriptionId}
            />
          </div>
        </div>
        {this.caption(data)}
        {this.longDescription(data, longDescriptionId)}
      </div>
    )
  }
}
