import { Reducer, Action } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_GET_ENROLLMENT_CONTENT,
  API_GET_NEXT_ENROLLMENT_CONTENT,
  UPDATE_CONTENT,
  UpdateContentAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { API_GET_LEARN_COURSE_ANALYTICS } from 'app/frontend/pages/material/learn/entities/analytics/learn-analytics-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { getEnrollmentByAssignmentId } from 'app/frontend/pages/material/learn/entities/enrollments/enrollments-reducer'
import { getAssignmentById } from 'app/frontend/pages/material/learn/entities/assignments/assignments-reducer'
import { get } from 'lodash'

export type AssignmentAnalyticsState =
  | Analytics.IAnalytics
  | Analytics.IAnalyticsLean
  | Analytics.IAssignmentStats
  | undefined

export interface AnalyticsReducerState {
  loading?: boolean
  byAssignmentId: AnalyticsByAssignmentId
}

export type AnalyticsByAssignmentId = {
  [assignmentId: string]: AssignmentAnalyticsState
}

export namespace AnalyticsTypeguards {
  export const isFullAssignmentAnalytics = (
    analytics: AssignmentAnalyticsState
  ): analytics is Analytics.IAnalytics => {
    const fullAnalytics = analytics as Analytics.IAnalytics
    return fullAnalytics && fullAnalytics.completed !== undefined
  }

  export const isAssignmentAnalytics = (
    analytics: AssignmentAnalyticsState
  ): analytics is Analytics.IAssignmentStats => {
    const assignmentAnalytics = analytics as Analytics.IAssignmentStats
    return assignmentAnalytics && assignmentAnalytics.defaultWorkEstimate !== undefined
  }

  export const isAnalyticsLean = (
    analytics: AssignmentAnalyticsState
  ): analytics is Analytics.IAnalyticsLean => {
    const analyticsLean = analytics as Analytics.IAnalyticsLean
    return analyticsLean && analyticsLean.statusAndProgress !== undefined
  }
}

/**
 * Analytics selector
 * @param state The root redux store state
 * @param assignmentId The id of the assignment to get analytics for
 * @returns The analytics in the store for the current user in the given assignment id, or
 * undefined if there are none
 */
const getAnalyticsByAssignmentId = (
  state: LearnControllerState,
  assignmentId?: string
): AssignmentAnalyticsState => getAllAnalyticsByAssignmentId(state)[assignmentId]

export const getAllAnalyticsByAssignmentId = (
  state: LearnControllerState
): AnalyticsByAssignmentId => state.entities.analytics.byAssignmentId

/**
 * Status and progress selector
 * @param state The root redux store state
 * @param assignmentId The id of the assignment to get status and progress for
 * @returns The status and progress for the current user in the given assignment and its LOs, or
 * undefined if there is none.
 */
export const getStatusAndProgressByAssignmentId = (
  state: LearnControllerState,
  assignmentId?: string
): Analytics.IStatusAndProgressLean | undefined => {
  const analytics = getAnalyticsByAssignmentId(state, assignmentId)
  return AnalyticsTypeguards.isAnalyticsLean(analytics) ? analytics.statusAndProgress : undefined
}

/**
 * Assignment stats selector
 * @param state The root redux store state
 * @param assignmentId The id of the assignment to get analytics for
 * @returns The stats for the given assignment (not specific to any user), or undefined if there
 * are none.
 */
export const getAssignmentStats = (
  state: LearnControllerState,
  assignmentId?: string
): Analytics.IAssignmentStats | undefined => {
  const analytics = getAnalyticsByAssignmentId(state, assignmentId)
  return AnalyticsTypeguards.isAssignmentAnalytics(analytics) ? analytics : undefined
}

/**
 * Full analytics selector
 * @param state The root redux store state
 * @param assignmentId The id of the assignment to get full analytics for
 * @returns The full analytics in the store for the given assignment id. undefined if there
 * are no analytics or incomplete analytics in the store for the given assignment.
 */
export const getFullAnalyticsByAssignmentId = (
  state: LearnControllerState,
  assignmentId?: string
): Analytics.IAnalytics | undefined => {
  const analytics = getAnalyticsByAssignmentId(state, assignmentId)
  return AnalyticsTypeguards.isFullAssignmentAnalytics(analytics) ? analytics : undefined
}

export const isAnalyticsLoading = (state: LearnControllerState): boolean => {
  return !!state.entities.analytics.loading
}

export const getLosById = (
  state: LearnControllerState,
  activeAssignmentId: string
): { [id: string]: string } => {
  const enrollment = getEnrollmentByAssignmentId(state, activeAssignmentId)
  const path = getAssignmentById(state, activeAssignmentId)
  if (!enrollment || !path) {
    return {}
  }

  if (!path.targetTopicId) {
    // Booster assignments don't have targetTopicId
    const loId = get(path.pathLearningObjectives[0], 'learningObjectiveId', '')
    const losById = { [`lref-${loId}`]: path.name }
    return losById
  } else {
    const taxonomy = state.entities.taxonomy[path.targetTopicId]
    if (!taxonomy) {
      return {}
    }
    const losById = taxonomy.learningObjectives
      .reduce((allLos, los) => allLos.concat(los), [])
      .reduce(
        (obj, concept) => Object.assign(obj, { [`lref-${concept.id}`]: concept.description }),
        {}
      )
    return losById
  }
}

export const reducer = (
  state: AnalyticsReducerState = { byAssignmentId: {} },
  action: Action
): AnalyticsReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_ENROLLMENT_CONTENT.INIT:
    case API_GET_NEXT_ENROLLMENT_CONTENT.INIT:
    case API_GET_LEARN_COURSE_ANALYTICS.INIT:
      return withLoading(state, true)
    case API_GET_ENROLLMENT_CONTENT.SUCCESS:
    case API_GET_NEXT_ENROLLMENT_CONTENT.SUCCESS:
      const apiSuccessAction = action as ApiSuccessAction
      return withUpdatedAssignmentAnalytics(
        state,
        apiSuccessAction.response.assignmentId,
        apiSuccessAction.response.analytics
      )
    case API_GET_LEARN_COURSE_ANALYTICS.SUCCESS:
      const getCourseAnalyticsSuccessAction = action as ApiSuccessAction
      const assignmentsAnalytics: { [assignmentId: string]: Analytics.IAnalyticsLean } =
        getCourseAnalyticsSuccessAction.response
      // Note: This may replace existing full assignment analytics we may have previously
      // fetched. We favor freshness over completeness.
      // Pages that need full analytics should trigger a refetch.
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          ...assignmentsAnalytics,
        },
      }
    case API_GET_ENROLLMENT_CONTENT.ERROR:
    case API_GET_NEXT_ENROLLMENT_CONTENT.ERROR:
    case API_GET_LEARN_COURSE_ANALYTICS.ERROR:
      return withLoading(state, false)
    case UPDATE_CONTENT:
      const updateContentAction = action as UpdateContentAction
      return withUpdatedAssignmentAnalytics(
        state,
        updateContentAction.assignmentId,
        updateContentAction.content.analytics
      )
    default:
      return state
  }
}

const withLoading = (state: AnalyticsReducerState, loading: boolean): AnalyticsReducerState => {
  return {
    ...state,
    loading,
  }
}

const withUpdatedAssignmentAnalytics = (
  state: AnalyticsReducerState,
  assignmentId: string,
  assignmentAnalytics: AssignmentAnalyticsState
): AnalyticsReducerState => {
  if (!assignmentId || !assignmentAnalytics) {
    return withLoading(state, false)
  }

  return {
    byAssignmentId: {
      ...state.byAssignmentId,
      [assignmentId]: assignmentAnalytics,
    },
  }
}

export default reducer as Reducer<AnalyticsReducerState>
