import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './notice-box.css'

interface Props {
  iconName?: string
}

export const NoticeBox: React.FunctionComponent<Props> = ({ iconName, children }) => {
  return (
    <Box
      className={styles.messageBox}
      direction="row"
      responsive={true}
      alignItems="center"
      justify="center"
    >
      <Icon name={iconName} className={styles.icons} />
      {children}
    </Box>
  )
}

NoticeBox.displayName = 'NoticeBox'
