import * as React from 'react'
import { connect } from 'react-redux'
import { cloneDeep, isEmpty, isEqual, pick, chain, mapValues } from 'lodash'
import {
  TableOfContentsChapter,
  TableOfContentsSection,
  TableOfContentsTitle,
} from 'app/frontend/pages/material/teach/compositions/data/table-of-contents'
import { Box } from 'app/frontend/components/material/box'
import {
  LearningObjectiveId,
  LoConceptMap,
  TopicLo,
  TopicLoMap,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import {
  stageEditLos,
  addRandomAssessmentQuestions,
  deleteAssessmentQuestions,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-lo-selection-actions'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Heading } from 'app/frontend/components/material/heading'
import { AssessmentTableOfContentsTopic } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-table-of-contents-topic'
import { AssessmentQuestionsByConceptByLo } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-questions-by-concept-by-lo'

const t = tns('teach:assessment_lo_selection_modal')
const EMPTY_LOID_SET = new Set<LearningObjectiveId>()

type SequenceCountByLo = { [loId: string]: number }
type QuestionCountByConceptByLo = { [loId: string]: { [conceptId: string]: number } }
export interface OwnProps {
  titles: GQL.TitleTaxonFields.Fragment[]
  unfilteredNumTitles: number
  assessmentId: string
  persistedSelectedLosByTopic: TopicLoMap
  numSequencesByLo: SequenceCountByLo
  hasStudentStartedAssessment: boolean
  defaultExpandedChapters: string[]
  keyChange: string
  assessmentQuestionsByConceptByLo: AssessmentQuestionsByConceptByLo
  parentEntityType: Commons.ParentEntityType
}

interface DispatchProps {
  persistLoSelection: (
    assessmentId: string,
    selectedLosByTopic: TopicLoMap,
    affectedTopicLos: TopicLo[],
    affectedConceptsByLo: LoConceptMap
  ) => void
  addRandomQuestions: (
    assessmentId: string,
    topicId: string,
    loConcepts: Commons.LoConcept[]
  ) => void
  deleteQuestions: (
    assessmentId: string,
    topicId: string,
    loConcepts: Commons.LoConcept[],
    questionIds: string[]
  ) => void
}

export type Props = OwnProps & DispatchProps

interface State {
  searchQuery?: string
  selectedLosByTopic: TopicLoMap
}

export class _AssessmentTableOfContents extends React.Component<Props, State> {
  // Copy the initial LO selections from the backend into component state
  state = {
    selectedLosByTopic: cloneDeep(this.props.persistedSelectedLosByTopic),
  } as State
  savedNumSequencesByLoByTopic: {
    [topicId: string]: SequenceCountByLo
  } = {}
  savedPersistedSelectedLosByTopic: TopicLoMap = {}
  savedNumQuestionsForConceptForLoByTopic: { [topicId: string]: QuestionCountByConceptByLo } = {}

  /**
   * Helper ensuring that the lo -> sequence count map for a given topic is the same instance across renders
   * unless one or more of the LO sequence counts for the topic has actually changed.
   */
  private getNumSequencesByLoForTopic = (
    topic: GQL.TopicTaxonFields.Fragment,
    numSequencesByLo: SequenceCountByLo
  ): SequenceCountByLo => {
    const numSequencesByLoForTopic = pick(
      numSequencesByLo,
      topic.learningObjectives.map(lo => lo.id)
    )
    const savedNumSequencesByLoForTopic = this.savedNumSequencesByLoByTopic[topic.id]
    if (isEqual(numSequencesByLoForTopic, savedNumSequencesByLoForTopic)) {
      return savedNumSequencesByLoForTopic
    } else {
      this.savedNumSequencesByLoByTopic[topic.id] = numSequencesByLoForTopic
      return numSequencesByLoForTopic
    }
  }

  /**
   * Helper ensuring that the lo -> concept -> question count map for a given topic is the same instance across renders
   * unless one or more of the question counts for a concept in a lo in the given topic has actually changed. At any
   * given time, the returned QuestionCountByConceptByLo object will only contains LOs that have not been filtered
   * out by search or an applied filter.
   */
  private getNumQuestionsForConceptForLo = (
    topic: GQL.TopicTaxonFields.Fragment,
    assessmentQuestionsByConceptByLo: AssessmentQuestionsByConceptByLo
  ): QuestionCountByConceptByLo => {
    // Convert AssessmentQuestionsByConceptByLo into QuestionCountByConceptByLo. Instead of each concept having
    // the list of all questions for that concept, we just want the number of questions per concept
    const numQuestionsByConceptForLo = chain(assessmentQuestionsByConceptByLo)
      .pick(topic.learningObjectives.map(lo => lo.id))
      .mapValues(questionsByConcept => {
        return mapValues(questionsByConcept, concept => concept.length)
      })
      .value()
    const savedNumQuestionsForConceptForLo = this.savedNumQuestionsForConceptForLoByTopic[topic.id]
    if (isEqual(numQuestionsByConceptForLo, savedNumQuestionsForConceptForLo)) {
      return savedNumQuestionsForConceptForLo
    } else {
      this.savedNumQuestionsForConceptForLoByTopic[topic.id] = numQuestionsByConceptForLo
      return numQuestionsByConceptForLo
    }
  }

  /**
   * Helper ensuring that the persisted selected LO ID set for a given topic is the same instance across renders
   * unless the set of persisted selected LOs for the topic has actually changed.
   */
  private getPersistedSelectedLosForTopic = (
    topicId: string,
    persistedSelectedLosByTopic: TopicLoMap
  ): Set<LearningObjectiveId> => {
    const persistedSelectedLosForTopic = persistedSelectedLosByTopic[topicId] || EMPTY_LOID_SET
    const savedPersistedSelectedLosForTopic =
      this.savedPersistedSelectedLosByTopic[topicId] || EMPTY_LOID_SET
    if (
      persistedSelectedLosForTopic.size === savedPersistedSelectedLosForTopic.size &&
      Array.from(persistedSelectedLosForTopic).every(loId =>
        savedPersistedSelectedLosForTopic.has(loId)
      )
    ) {
      return savedPersistedSelectedLosForTopic
    } else {
      this.savedPersistedSelectedLosByTopic[topicId] = persistedSelectedLosForTopic
      return persistedSelectedLosForTopic
    }
  }

  /**
   * Update the component state to select or deselect the specified LO IDs
   * Note that we create new a new Set<LearningObjectiveId> rather than modifying the existing
   * one. This is to ensure that the memoized AssessmentTableOfContentsTopic component
   * re-renders as needed.
   *
   * @param topicId The ID of the toggled LOs
   * @param loIds The IDs of the toggled LOs
   * @param checked True if the LOs were toggled on, false if they were toggled off
   * @param loConcepts Map from lo to concepts
   */
  private onLosToggled = (
    topicId: string,
    loIds: string[],
    loConcepts: LoConceptMap,
    checked: boolean
  ): void => {
    const { assessmentId, persistLoSelection } = this.props
    this.setState(
      (state: Readonly<State>) => {
        const previousSelectedLos = state.selectedLosByTopic[topicId] || new Set()
        let newSelectedLos
        if (checked) {
          newSelectedLos = new Set([...previousSelectedLos, ...loIds])
        } else {
          newSelectedLos = new Set(Array.from(previousSelectedLos))
          for (const loId of loIds) {
            newSelectedLos.delete(loId)
          }
        }
        const newSelectedLosByTopic = {
          ...state.selectedLosByTopic,
          [topicId]: newSelectedLos,
        }
        if (newSelectedLos.size === 0) {
          delete newSelectedLosByTopic[topicId]
        }

        return {
          selectedLosByTopic: newSelectedLosByTopic,
        }
      },
      () => {
        const { selectedLosByTopic } = this.state
        persistLoSelection(
          assessmentId,
          selectedLosByTopic,
          loIds.map(loId => ({ topicId, loId })),
          loConcepts
        )
      }
    )
  }

  /**
   * Update the component state to select or deselect the specified concept IDs
   * This also triggers addRandomQuestions if checked is true or deleteQuestions
   * if it false
   *
   * @param conceptId The ID of the toggled concept
   * @param loId The ID of the lo the concept belongs to
   * @param topicId The ID of the topic the concept belongs to
   * @param checked True if the concept was toggled on, false if they were toggled off
   */
  private handleConceptToggled = (
    conceptId: string,
    loId: string,
    topicId: string,
    checked: boolean
  ) => {
    const {
      assessmentId,
      assessmentQuestionsByConceptByLo,
      addRandomQuestions,
      deleteQuestions,
    } = this.props
    this.setState(
      (state: Readonly<State>) => {
        const previousSelectedLos = state.selectedLosByTopic[topicId] || new Set()
        let newSelectedLos
        if (checked) {
          newSelectedLos = new Set([...previousSelectedLos, loId])
        } else {
          newSelectedLos = new Set(Array.from(previousSelectedLos))
          // If there are no concept selected, then the corresponding objective should be deselected
          if (Object.keys(assessmentQuestionsByConceptByLo[loId]).length <= 1) {
            newSelectedLos.delete(loId)
          }
        }

        const newSelectedLosByTopic = {
          ...state.selectedLosByTopic,
          [topicId]: newSelectedLos,
        }

        return {
          selectedLosByTopic: newSelectedLosByTopic,
        }
      },
      () => {
        const loConcepts = [
          {
            learningObjectiveId: loId,
            conceptIds: [conceptId],
          },
        ]
        if (checked) {
          addRandomQuestions(assessmentId, topicId, loConcepts)
        } else {
          const questionIds = assessmentQuestionsByConceptByLo[loId][conceptId]
          deleteQuestions(assessmentId, topicId, loConcepts, questionIds)
        }
      }
    )
  }

  private renderTopic = (topic: GQL.TopicTaxonFields.Fragment, chapter): JSX.Element => {
    const {
      assessmentId,
      persistedSelectedLosByTopic,
      numSequencesByLo,
      assessmentQuestionsByConceptByLo,
      parentEntityType,
    } = this.props
    const selectedLos = this.state.selectedLosByTopic[topic.id] || EMPTY_LOID_SET
    return (
      <AssessmentTableOfContentsTopic
        key={topic.id}
        parentEntityType={parentEntityType}
        assessmentId={assessmentId}
        chapterName={chapter.name}
        topic={topic}
        selectedLos={selectedLos}
        persistedSelectedLos={this.getPersistedSelectedLosForTopic(
          topic.id,
          persistedSelectedLosByTopic
        )}
        numSequencesByLo={this.getNumSequencesByLoForTopic(topic, numSequencesByLo)}
        onLosToggled={this.onLosToggled}
        onConceptToggled={this.handleConceptToggled}
        numQuestionsForConceptForLo={this.getNumQuestionsForConceptForLo(
          topic,
          assessmentQuestionsByConceptByLo
        )}
      />
    )
  }

  render() {
    const { titles, unfilteredNumTitles, defaultExpandedChapters, keyChange } = this.props

    if (isEmpty(titles)) {
      return (
        <Box margin={{ top: 'large' }}>
          <Heading tag="h2" size="h3" margin="none">
            {t('no_results_title')}
          </Heading>
          <Paragraph>{t('no_results_description')}</Paragraph>
        </Box>
      )
    }

    return (
      <Box full="page">
        {titles.map(title => (
          <TableOfContentsTitle title={title} showTitle={unfilteredNumTitles > 1} key={title.id}>
            {title.chapters.map((chapter, chapterIndex) => (
              <TableOfContentsChapter
                chapter={chapter}
                key={chapter.id}
                defaultExpanded={defaultExpandedChapters.includes(chapter.id)}
                keyChange={keyChange}
              >
                <>
                  {chapter.sections.map(section => {
                    return (
                      <TableOfContentsSection section={section} key={section.id}>
                        {section.topics.map(topic => this.renderTopic(topic, chapter))}
                      </TableOfContentsSection>
                    )
                  })}
                  <Box
                    separator={chapterIndex === title.chapters.length - 1 ? 'none' : 'all'}
                    alignSelf="stretch"
                    direction="column"
                    margin={{ top: 'medium', bottom: 'small' }}
                  >
                    <Box alignSelf="stretch" direction="column" separator="between">
                      {chapter.topics.map(topic => this.renderTopic(topic, chapter))}
                    </Box>
                  </Box>
                </>
              </TableOfContentsChapter>
            ))}
          </TableOfContentsTitle>
        ))}
      </Box>
    )
  }
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    persistLoSelection: (
      assessmentId: string,
      selectedLosByTopic: TopicLoMap,
      affectedTopicLos: TopicLo[],
      affectedConceptsByLo: LoConceptMap
    ) =>
      dispatch(
        stageEditLos(assessmentId, selectedLosByTopic, affectedTopicLos, affectedConceptsByLo)
      ),
    addRandomQuestions: (assessmentId: string, topicId: string, loConcepts: Commons.LoConcept[]) =>
      dispatch(addRandomAssessmentQuestions(assessmentId, topicId, loConcepts)),
    deleteQuestions: (
      assessmentId: string,
      topicId: string,
      loConcepts: Commons.LoConcept[],
      questionIds: string[]
    ) => dispatch(deleteAssessmentQuestions(assessmentId, topicId, loConcepts, questionIds)),
  }
}

/**
 * Component that renders the (possibly filtered) table of contents for the
 * LO selection modal. Provides the ability to browse the chapters, topics,
 * and learning objectives available in the course's coursepack, select and
 * unselect LOs for inclusion in the assessment, and launch the question
 * selection modal to edit the specific questions used for a selected LO
 */
export const AssessmentTableOfContents = connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(_AssessmentTableOfContents)
