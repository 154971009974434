import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'

export const SEARCH_TASK_STATUSES_QUERY = require('./search-task-statuses.gql')

export interface SearchTaskStatusesRenderProps {
  results: string[]
  cursor: GQL.TaskStatusCursor
  loading?: boolean
  error?: ApolloError
}

type Props = GQL.QuerySearchForTaskStatusesArgs & {
  children: (props: SearchTaskStatusesRenderProps) => JSX.Element
}

export const SearchTaskStatusesQuery: React.FC<Props> = ({ request, children }) => (
  // TODO ALPACA-759
  <Query<GQL.SearchTaskStatuses.Query, GQL.SearchTaskStatuses.Variables>
    variables={{ request }}
    query={SEARCH_TASK_STATUSES_QUERY}
    fetchPolicy={'network-only'}
    nextFetchPolicy={'cache-first'}
  >
    {({ data, loading, error }) => {
      const results = isEmpty(data) ? null : data.searchForTaskStatuses.results
      const cursor = isEmpty(data) ? null : data.searchForTaskStatuses.cursor

      return children({
        results,
        cursor,
        loading,
        error,
      })
    }}
  </Query>
)

SearchTaskStatusesQuery.displayName = 'SearchTaskStatusesQuery'

export default SearchTaskStatusesQuery
