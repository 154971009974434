import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Link } from 'react-router-dom'
import { getStaticUrl } from 'app/frontend/helpers/assets'
import * as LogoAltaWhite from 'app/public/img/logo-knewton-alta-white-2.svg'
import * as styles from './login/login.css'

const t = tns('common')

export interface OwnProps {
  isDistributor?: boolean
}

const SignedOutLogo: React.FunctionComponent<OwnProps> = ({ isDistributor }) => {
  return (
    <Link
      to={isDistributor ? '/orders/login' : '/login'}
      data-bi="login-link"
      className={styles.logo}
    >
      <img src={getStaticUrl(LogoAltaWhite)} height="34px" alt={t('knewton_alta_home_page')} />
    </Link>
  )
}

SignedOutLogo.displayName = 'SignedOutLogo'
export default SignedOutLogo
