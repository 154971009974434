import * as React from 'react'
import { GetAssessmentAttempts } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-attempts/get-assessment-attempts'
import { MultipleAttemptsSettingInner } from './multiple-attempts-setting-inner'

interface OwnProps {
  assessmentId: string
  isReadOnly: boolean
}

export const MultipleAttemptsSetting: React.FunctionComponent<OwnProps> = ({
  isReadOnly,
  assessmentId,
}) => {
  return (
    <GetAssessmentAttempts assessmentId={assessmentId} skip={isReadOnly}>
      {({ attemptsByUser, loading, error }) => {
        const numAttemptsForEachStudent = attemptsByUser.map(
          attemptsForUserAndAssessment => attemptsForUserAndAssessment.attempts.length
        )
        const maxAttemptsUsed = Math.max(0, ...numAttemptsForEachStudent)
        return (
          <MultipleAttemptsSettingInner
            isReadOnly={isReadOnly || loading || !!error}
            maxAttemptsUsed={maxAttemptsUsed}
          />
        )
      }}
    </GetAssessmentAttempts>
  )
}

MultipleAttemptsSetting.displayName = 'MultipleAttemptsSetting'
