import { replace, RouterAction, push } from 'connected-react-router'
import { SectionAssignmentFilters } from './section-assignment'
import {
  AnalyticsCategory,
  StudentsMasterFilters,
  StudentsActivityFilters,
} from 'app/frontend/data/analytics-data'
import { stringify } from 'app/helpers/query-string'

export enum QueryStringKeys {
  SectionAssignmentFilter = 'due',
  AssignmentStatus = 'status',
  AssignmentType = 'tab',
  StrugglingStudent = 'struggling',
  Mastery = 'mastery',
  Activity = 'activity',
}

export function getParamFromQueryStringObject<T>(
  queryStringObject: { [k: string]: string },
  queryStringKey: QueryStringKeys,
  queryStringEnum: T
): T[keyof T] {
  return queryStringEnum[queryStringObject[queryStringKey]]
}

export namespace QueryStringObjects {
  export declare type Students = {
    [QueryStringKeys.StrugglingStudent]: boolean
    [QueryStringKeys.Mastery]: StudentsMasterFilters
    [QueryStringKeys.Activity]: StudentsActivityFilters
  }

  export declare type Coursework = {
    [QueryStringKeys.SectionAssignmentFilter]: SectionAssignmentFilters
    [QueryStringKeys.AssignmentType]: AnalyticsCategory
    [QueryStringKeys.AssignmentStatus]: Analytics.AssignmentStatus | 'all'
  }

  export declare type Student = Coursework
}

export function emitQueryStringUpdateEvent<TQueryStringObj>(
  queryStringObj: TQueryStringObj
): RouterAction {
  return replace('?' + stringify(queryStringObj))
}

export function emitRouteWithQueryStringUpdateEvent<TQueryStringObj>(
  queryStringObj: TQueryStringObj,
  route: string
): RouterAction {
  return push(route + '?' + stringify(queryStringObj))
}
