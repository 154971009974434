import * as React from 'react'
import { useEffect } from 'react'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Box } from 'app/frontend/components/material/box'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Icon } from 'app/frontend/components/material/icon'
import { ButtonIconMaterial } from 'app/frontend/components/material/button/button-icon'
import { sendEvent } from 'app/frontend/helpers/mixpanel'
import * as styles from './activity-navigator.css'

const t = tns('activity_navigator')

interface ownProps {
  title?: string
  count: number
  index: number
  onChange: (index: number) => void
  goPrevSendEventName?: string
  goNextSendEventName?: string
  disabled?: boolean
}

export const ActivityNavigator: React.FunctionComponent<ownProps> = ({
  title = t('activity'),
  count,
  index,
  onChange,
  goPrevSendEventName,
  goNextSendEventName,
  disabled,
}) => {
  // Don't show this component if there is only one item
  if (count <= 1) {
    return null
  }

  const goPrev = () => {
    onChange((count + index - 1) % count)
    if (goPrevSendEventName) {
      sendEvent(goPrevSendEventName)
    }
  }
  const goNext = () => {
    onChange((index + 1) % count)
    if (goNextSendEventName) {
      sendEvent(goNextSendEventName)
    }
  }

  const onKeyPress = ({ keyCode, ctrlKey, altKey, metaKey, shiftKey }) => {
    // If none of the modifier keys were pressed (this is to avoid interfering with accessibility)
    if (!ctrlKey && !altKey && !metaKey && !shiftKey) {
      // is this the left arrow key?
      if (keyCode === 37) {
        goPrev()
      }
      // is this the right arrow key?
      if (keyCode === 39) {
        goNext()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress)
    return () => window.removeEventListener('keydown', onKeyPress)
  }, [onKeyPress])

  return (
    <Box direction={'row'} alignItems={'center'}>
      <Paragraph size={'medium'} className={styles.activityNavigator} data-test="item-count">
        {`${title} ${index + 1} of ${count}`}
      </Paragraph>
      <ButtonIconMaterial
        data-test="previous-arrow"
        ariaLabel={t('previous_button_aria')}
        onClick={goPrev}
        className={styles.icon}
        disabled={disabled}
        icon={<Icon size={'small'} name="icon-arrow-chevron-left" />}
      />
      <ButtonIconMaterial
        data-test="next-arrow"
        ariaLabel={t('next_button_aria')}
        className={styles.icon}
        onClick={goNext}
        disabled={disabled}
        icon={<Icon size={'small'} name="icon-arrow-chevron-right" />}
      />
    </Box>
  )
}
ActivityNavigator.displayName = 'ActivityNavigator'
