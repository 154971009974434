import { combineReducers, Reducer } from 'redux'
import { reducer as usersComponentReducer, UsersComponentState } from './users/reducer'
import {
  reducer as coursepackSearchReducer,
  CoursepacksSearchState,
} from './coursepacks/coursepacks-search-reducer'
import {
  reducer as institutionsComponentReducer,
  InstitutionsComponentState,
} from './institutions/reducer'
import {
  reducer as ltiCredentialsSearchReducer,
  LtiCredentialsSearchState,
} from 'app/frontend/pages/material/knerd/lti/lti-credentials-search-reducer'
import { reducer as formReducer, FormReducer } from 'redux-form'

export interface KnerdControllerState {
  ui: {
    usersComponent: UsersComponentState
    institutionsComponent: InstitutionsComponentState
    coursepackSearch: CoursepacksSearchState
    ltiCredentialsSearch: LtiCredentialsSearchState
  }
  form: FormReducer
}

export default combineReducers({
  ui: combineReducers({
    usersComponent: usersComponentReducer as Reducer<UsersComponentState>,
    institutionsComponent: institutionsComponentReducer as Reducer<InstitutionsComponentState>,
    coursepackSearch: coursepackSearchReducer,
    ltiCredentialsSearch: ltiCredentialsSearchReducer,
  }),
  form: formReducer,
})
