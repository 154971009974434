import * as React from 'react'
import * as _ from 'lodash'
import * as classnames from 'classnames'

import MathJaxQueue, { CommandId, Priority } from './queue'

interface MathJaxProps {
  inline?: boolean
  priority?: Priority
  onRender?: () => void
  className?: string
}

interface MathJaxState {
  mathjaxed: boolean
  rendering: boolean
}

export class MathJax extends React.Component<MathJaxProps, MathJaxState> {
  static displayName = 'MathJax'
  ele: HTMLElement
  typesetId: CommandId

  constructor(props, context) {
    super(props, context)
    this.state = {
      mathjaxed: false,
      rendering: true,
    }
  }

  componentDidMount(): void {
    this.renderMathIfNeeded()
  }

  componentDidUpdate(): void {
    this.renderMathIfNeeded()
  }

  componentWillUnmount(): void {
    MathJaxQueue.cancel(this.typesetId)
  }

  /**
   * If we haven't yet rendered the math content, ask Mathjax to render it.
   */
  renderMathIfNeeded(): void {
    // only call mathjax once
    if (!this.state.mathjaxed && this.ele) {
      const { onRender, priority } = this.props

      // cancel any outstanding requests
      MathJaxQueue.cancel(this.typesetId)

      const onDone = () => {
        this.setState({
          mathjaxed: true,
          rendering: false,
        })
        if (onRender) {
          onRender()
        }
      }

      // typeset the contents of this element
      this.typesetId = MathJaxQueue.typeset(this.ele, {
        onSuccess: onDone,
        onError: onDone,
        priority: priority || Priority.DEFAULT,
      })
    }
  }

  render(): JSX.Element {
    const props = _.omit(this.props, ['priority', 'className', 'inline', 'priority', 'onRender'])
    const classes = classnames('mathjax', {
      rendering: this.state.rendering,
      [this.props.className]: !!this.props.className,
    })

    if (this.props.inline) {
      return (
        <span className={classes}>
          <span {...props} ref={n => (this.ele = n)} />
        </span>
      )
    }

    return (
      <div className={classes}>
        <div {...props} ref={n => (this.ele = n)} />
      </div>
    )
  }
}

export default MathJax
