import * as moment from 'moment'
import * as React from 'react'
import { Field } from 'redux-form'
import { Datepicker, DateProps } from 'app/frontend/components/pickers'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'

/**
 * Datepicker
 */
type RenderDatepickerProps = WrappedFieldPropsStub & DateProps & FieldDatepickerCustomProps

// modifies timestamp to be start of day or end of day
export const getAdjustedDateTime = (value: moment.Moment, timeOfDay?: 'startOf' | 'endOf') => {
  if (timeOfDay === 'startOf') {
    return value.startOf('day')
  }

  return value.endOf('day')
}

export const renderDatepicker = (field: RenderDatepickerProps) => {
  return (
    <Datepicker
      minDate={field.minDate}
      maxDate={field.maxDate}
      label={field.label}
      value={field.input.value}
      error={field.meta.error}
      onChange={val => field.input.onChange(getAdjustedDateTime(val, field.timeOfDay))}
      dateFormat={field.dateFormat}
      dataBi={field.dataBi}
      setError={field.setError}
      iconButtonAriaLabel={field.iconButtonAriaLabel}
    />
  )
}

type FieldDatepickerCustomProps = {
  timeOfDay?: 'startOf' | 'endOf'
  onDateChange?: (_event: object, date: moment.Moment) => void
  warning?: (value: number) => JSX.Element
  setError?: (text: string) => void
}
export type FieldDatepickerProps = FieldDatepickerCustomProps & Partial<DateProps>
export const FieldDatepicker: React.FunctionComponent<FieldDatepickerProps & { name: string }> = ({
  onDateChange,
  error,
  ...otherProps
}) => {
  const isError = React.useCallback(() => (error ? error : undefined), [error])
  return (
    <Field
      onChange={onDateChange}
      {...otherProps}
      validate={isError}
      component={renderDatepicker}
    />
  )
}
