import {
  TopicLo,
  TopicLoMap,
  ConceptsByLoMap,
  LoConceptMap,
  TopicLoErrorType,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'

export enum AssessmentLoSelectionAction {
  EditStaged = 'ASSESSMENT_EDIT_LOS_STAGED',
  EditFailed = 'ASSESSMENT_EDIT_LOS_FAILED',
  EditSucceeded = 'ASSESSMENT_EDIT_LOS_SUCCEEDED',
}

export enum AssessmentConceptsSelectionAction {
  EditStaged = 'EDIT_CONCEPTS_STAGED',
  EditFailed = 'EDIT_CONCEPTS_FAILED',
  EditSucceeded = 'EDIT_CONCEPTS_SUCCEEDED',
}

export enum AssessmentQuestionAction {
  AddRandomQuestions = 'ADD_RANDOM_QUESTIONS',
  DeleteQuestions = 'DELETE_QUESTIONS',
}

export type LosStagedAction = {
  type: AssessmentLoSelectionAction.EditStaged
  assessmentId: string
  selectedLosByTopic: TopicLoMap
  affectedTopicLos: TopicLo[]
  affectedConceptsByLo: LoConceptMap
}

export function stageEditLos(
  assessmentId: string,
  selectedLosByTopic: TopicLoMap,
  affectedTopicLos: TopicLo[],
  affectedConceptsByLo: LoConceptMap
): LosStagedAction {
  return {
    type: AssessmentLoSelectionAction.EditStaged,
    assessmentId,
    selectedLosByTopic,
    affectedTopicLos,
    affectedConceptsByLo,
  }
}

export type EditLosFailedAction = {
  type: AssessmentLoSelectionAction.EditFailed
  assessmentId: string
  affectedTopicLos: TopicLo[]
  errorType?: TopicLoErrorType
}

export function editLosFailed(
  assessmentId: string,
  affectedTopicLos: TopicLo[],
  errorType?: TopicLoErrorType
): EditLosFailedAction {
  return {
    type: AssessmentLoSelectionAction.EditFailed,
    assessmentId,
    affectedTopicLos,
    errorType,
  }
}

export type EditLosSucceededAction = {
  type: AssessmentLoSelectionAction.EditSucceeded
  assessmentId: string
  affectedTopicLos: TopicLo[]
}

export function editLosSucceeded(
  assessmentId: string,
  affectedTopicLos: TopicLo[]
): EditLosSucceededAction {
  return {
    type: AssessmentLoSelectionAction.EditSucceeded,
    assessmentId,
    affectedTopicLos,
  }
}

export type EditConceptsStagedAction = {
  type: AssessmentConceptsSelectionAction.EditStaged
  assessmentId: string
  topicId: string
  affectedConceptsByLo: ConceptsByLoMap
}

export function editConceptsStaged(
  assessmentId: string,
  topicId: string,
  affectedConceptsByLo: ConceptsByLoMap
): EditConceptsStagedAction {
  return {
    type: AssessmentConceptsSelectionAction.EditStaged,
    assessmentId,
    topicId,
    affectedConceptsByLo,
  }
}

export type EditConceptsFailedAction = {
  type: AssessmentConceptsSelectionAction.EditFailed
  assessmentId: string
  topicId: string
  affectedConceptsByLo: ConceptsByLoMap
}

export function editConceptsFailed(
  assessmentId: string,
  topicId: string,
  affectedConceptsByLo: ConceptsByLoMap
): EditConceptsFailedAction {
  return {
    type: AssessmentConceptsSelectionAction.EditFailed,
    assessmentId,
    topicId,
    affectedConceptsByLo,
  }
}

export type EditConceptsSucceededAction = {
  type: AssessmentConceptsSelectionAction.EditSucceeded
  assessmentId: string
  topicId: string
  affectedConceptsByLo: ConceptsByLoMap
}

export function editConceptsSucceeded(
  assessmentId: string,
  topicId: string,
  affectedConceptsByLo: ConceptsByLoMap
): EditConceptsSucceededAction {
  return {
    type: AssessmentConceptsSelectionAction.EditSucceeded,
    assessmentId,
    topicId,
    affectedConceptsByLo,
  }
}

export type AddRandomAssessmentQuestionsAction = {
  type: AssessmentQuestionAction.AddRandomQuestions
  assessmentId: string
  topicId: string
  loConcepts: Commons.LoConcept[]
}

export function addRandomAssessmentQuestions(
  assessmentId: string,
  topicId: string,
  loConcepts: Commons.LoConcept[]
): AddRandomAssessmentQuestionsAction {
  return {
    type: AssessmentQuestionAction.AddRandomQuestions,
    assessmentId,
    topicId,
    loConcepts,
  }
}

export type DeleteAssessmentQuestionsAction = {
  type: AssessmentQuestionAction.DeleteQuestions
  assessmentId: string
  topicId: string
  loConcepts: Commons.LoConcept[]
  questionIds: string[]
}

export function deleteAssessmentQuestions(
  assessmentId: string,
  topicId: string,
  loConcepts: Commons.LoConcept[],
  questionIds: string[]
): DeleteAssessmentQuestionsAction {
  return {
    type: AssessmentQuestionAction.DeleteQuestions,
    assessmentId,
    topicId,
    loConcepts,
    questionIds,
  }
}
