import { Action } from 'redux'
import { makeApiActions } from 'app/frontend/api/api-redux'

export namespace FFlipActions {
  export enum Actions {
    ENABLE_FEATURE = 'ENABLE_FEATURE',
    DISABLE_FEATURE = 'DISABLE_FEATURE',
  }

  export interface EnableFeatureAction extends Action {
    type: Actions.ENABLE_FEATURE
    featureName: string
  }

  export function enableFeature(featureName: string): EnableFeatureAction {
    return {
      type: Actions.ENABLE_FEATURE,
      featureName,
    }
  }

  export const API_ENABLE_FEATURE = makeApiActions(Actions.ENABLE_FEATURE)

  export interface DisableFeatureAction extends Action {
    type: Actions.DISABLE_FEATURE
    featureName: string
  }

  export function disableFeature(featureName: string): DisableFeatureAction {
    return {
      type: Actions.DISABLE_FEATURE,
      featureName,
    }
  }

  export const API_DISABLE_FEATURE = makeApiActions(Actions.DISABLE_FEATURE)
}
