import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import uiReducer, { LearnUiReducerState } from 'app/frontend/pages/material/learn/ui/ui-reducer'
import * as actions from './edit-account/edit-actions'

export type AccountControllerState = {
  user: Commons.IUser
  ui: LearnUiReducerState
  form: {
    'account-edit': any
    'account-edit-personal-info': any
    'account-edit-digest': any
    'account-edit-accessibility': any
    'delete-account': any
  }
}

// User is loaded from Alexandria on
//  initial page load.
export function user(state = null, action) {
  switch (action.type) {
    case actions.API_EDIT_ACCOUNT.SUCCESS:
      return action.response
    default:
      return state
  }
}

export default combineReducers<AccountControllerState>({
  user,
  ui: uiReducer,
  form: formReducer,
})
