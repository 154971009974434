import * as React from 'react'
import * as moment from 'moment'
import { DateTimeFormats } from 'app/helpers/timezone'
import {
  defaultDateFormatRegex,
  defaultDateYearFormatRegex,
  defaultDateTimeYearFormatRegex,
  defaultDateTimeFormatRegex,
  anyDateFormatRegex,
  anyDateTimeFormatRegex,
  dateErrors,
} from '../helpers'
import { dateTimePickerReducer } from './reducer'

type UseDateTimePickerProps = {
  value: moment.Moment
  dateFormat?: DateTimeFormats
  timeFormat?: DateTimeFormats
  timeFormatRegex?: RegExp
  time?: string
  minDate: moment.Moment
  maxDate: moment.Moment
  onChange: (val: moment.Moment) => void
  allowUndefined: boolean
  acceptAnyFormat: boolean
  defaultTime?: string
  container: React.RefObject<HTMLDivElement>
  timepickerId: string
}

type UseDateTimepickerState = {
  formattedInputValue: string
  isPickerOpen: boolean
  showPicker: () => void
  hidePicker: () => void
  setText: (val: string) => void
  handleTextBlur: () => void
  error: string
}

export const useDateTimePicker = ({
  value,
  dateFormat = DateTimeFormats.MONTH_DATE,
  timeFormat = DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE,
  defaultTime = '12:00pm',
  minDate,
  maxDate,
  onChange,
  allowUndefined,
  acceptAnyFormat = true,
  container,
  timepickerId,
}: UseDateTimePickerProps): UseDateTimepickerState => {
  const dateTimeFormat = `${dateFormat}, ${timeFormat}`
  const [
    { formattedInputValue, isPickerOpen, error },
    { showPicker, hidePicker, setError, setText, handleChange },
  ] = dateTimePickerReducer({ dateTimeFormat, value })

  const dateInBounds = (val: moment.Moment) => !val.isBefore(minDate) && !val.isAfter(maxDate)

  React.useEffect(() => {
    const handleOutsideClick = e => {
      if (
        !container.current?.contains(e.target) &&
        !e.target?.id.includes(`${timepickerId}-option`)
      ) {
        hidePicker()
      }
    }
    window.addEventListener('mousedown', handleOutsideClick)

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleTextBlur = () => {
    // if empty input
    if (formattedInputValue.trim() === '') {
      if (allowUndefined) {
        return onChange(undefined)
      }
      // if we have a previous value, set to that.
      if (value) {
        return handleChange(value)
      }
      return
    }

    // if unchanged
    if (value && value.format(dateTimeFormat) === formattedInputValue) {
      return
    }

    // By default the year is not shown (you can see it by opening the calendar).
    // If you want to input date, year and time all at once, currently this
    // supports the following formats:
    // Feb 12, 2021 12:23 AM or July 4, 2022 22:12
    // or
    // 5/22/21, 10:04 PM or 2 10 22, 14:22
    // You can input date and year the same way you would for the date picker:
    // Jan 21, 2021 or March 13, 2022 or 1-4-22
    // You can input date and time in the same format as the default for the field (current year added implicitly):
    // Apr 7, 7:23 AM or September 12, 19:53
    // You can also input just date (current year added implicitly):
    // July 2, Oct 30

    let newValue
    if (formattedInputValue.match(defaultDateTimeYearFormatRegex)) {
      // Set date year and time
      // ex: Feb 12, 2021 12:23 AM or July 4, 2022 22:12
      newValue = moment(formattedInputValue, `${DateTimeFormats.MONTH_DATE_YEAR} ${timeFormat}`)
    } else if (acceptAnyFormat && formattedInputValue.match(anyDateTimeFormatRegex)) {
      // Set date year and time any format (year optional)
      // ex: 5/22/21 10:04 PM or 2 10 22 14:22 or 3-1 1:32
      const [, mm, dd, , yy = moment().format('YY'), t] = formattedInputValue.match(
        anyDateTimeFormatRegex
      )

      newValue = moment(`${mm} ${dd} ${yy.slice(-2)} ${t}`, `MM DD YY ${timeFormat}`)
    } else if (formattedInputValue.match(defaultDateYearFormatRegex)) {
      // Set date and year
      // Set time to previous time or default if not set.
      // ex: Jan 12, 2021 or September 22, 2022
      newValue = moment(
        `${formattedInputValue} ${value ? value.format(timeFormat) : defaultTime}`,
        `${DateTimeFormats.MONTH_DATE_YEAR} ${timeFormat}`
      )
    } else if (acceptAnyFormat && formattedInputValue.match(anyDateFormatRegex)) {
      // Set date any format
      // Set time to previous time or default if not set.
      // ex: 3/12/21 or 12/21/21 or 4/12
      const [, mm, dd, , yy = moment().format('YY')] = formattedInputValue.match(anyDateFormatRegex)
      newValue = moment(
        `${mm} ${dd} ${yy.slice(-2)} ${value ? value.format(timeFormat) : defaultTime}`,
        `MM DD YY ${timeFormat}`
      )
    } else if (formattedInputValue.match(defaultDateTimeFormatRegex)) {
      // Set date and time
      // Set year to current.
      // ex: Jan 12, 10:45 PM or September 22, 16:29
      newValue = moment(formattedInputValue, dateTimeFormat)
    } else if (formattedInputValue.match(defaultDateFormatRegex)) {
      // Set date
      // Set time to previous time or default if not set.
      // Set year to current.
      // ex: Feb 27 or September 1
      newValue = moment(
        `${formattedInputValue}, ${value ? value.format(timeFormat) : defaultTime}`,
        dateTimeFormat
      )
    }

    // If the input matched one of the above, check if it is in bounds
    if (newValue) {
      if (dateInBounds(newValue)) {
        return onChange(newValue)
      } else {
        return setError(dateErrors.OUTOFBOUNDS)
      }
    }

    return setError(dateErrors.INVALID)
  }

  React.useEffect(() => {
    handleChange(value)
  }, [value])

  return {
    showPicker,
    hidePicker,
    formattedInputValue,
    isPickerOpen,
    setText,
    handleTextBlur,
    error,
  }
}
