import * as React from 'react'
import * as classnames from 'classnames/bind'
import { BoxContextProps, withBoxContext } from 'app/frontend/components/material/box/box'

import * as styles from './heading.css'
const c = classnames.bind(styles)

export interface Props extends React.HTMLAttributes<HTMLHeadingElement>, BoxContextProps {
  /** The horizontal alignment of the Heading. Defaults to start. */
  align?: 'start' | 'center' | 'end'

  /** The amount of margin around the heading. An object can be specified to distinguish horizontal
   * margin, vertical margin, and margin on a particular side of the box:
   * {horizontal: none|small|medium|large, vertical: none|small|medium|large,
   * top|left|right|bottom: none|small|medium|large}. Defaults to { vertical: 'medium' }.
   */
  margin?:
    | 'none'
    | 'small'
    | 'medium'
    | 'large'
    | {
        horizontal?: 'none' | 'small' | 'medium' | 'large'
        vertical?: 'none' | 'small' | 'medium' | 'large'
      }
    | {
        top?: 'none' | 'small' | 'medium' | 'large'
        left?: 'none' | 'small' | 'medium' | 'large'
        right?: 'none' | 'small' | 'medium' | 'large'
        bottom?: 'none' | 'small' | 'medium' | 'large'
      }

  /**
   * The amount of padding to put around the contents. An object can be specified to distinguish
   * horizontal padding, vertical padding, and padding between child components:
   * {horizontal: none|small|medium|large, vertical: none|small|medium|large,
   * between: none|small|medium|large}. Defaults to none. Padding set using between only
   * affects components based on the direction set (adds horizontal padding between components
   * for row, or vertical padding between components for column).
   */
  pad?:
    | 'none'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'huge'
    | {
        horizontal?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        vertical?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        between?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
      }
    | {
        top?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        left?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        right?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
        bottom?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
      }

  /** Which HTML heading level should be used. Defaults to h1. */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

  /** Which tag size to use, defaults to equal the html heading level. */
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

  /** Restrict the text to a single line and truncate with ellipsis if it is too long to all fit.
   * Defaults to false.
   */
  truncate?: boolean

  /** Convert the heading to uppercase. Defaults to false. */
  uppercase?: boolean

  /** Set the heading to display inline. */
  inline?: boolean

  /** Sets the font weight */
  weight?: 'regular' | 'semibold'

  /** Add a separator */
  separator?: 'top' | 'bottom' | 'left' | 'right' | 'horizontal' | 'vertical' | 'all' | 'none'
}

class _Heading extends React.Component<Props, {}> {
  /**
   * Returns a css class name of the pattern `prefix-value`.  If an object is passed into
   * the value, the pattern is then `prefix-value.key-value.value`.
   */
  private classes(prefix: string, value: boolean | string | object): string[] {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [`${prefix}-${value}`]
    } else if (typeof value === 'object') {
      return Object.entries(value).map(([key, val]) => `${prefix}-${key}-${val}`)
    } else {
      return []
    }
  }

  render(): JSX.Element {
    const {
      align = 'start',
      margin = { vertical: 'medium' },
      pad = 'none',
      tag = 'h1',
      size = tag || 'h1',
      truncate = false,
      uppercase = false,
      weight,
      className,
      children,
      separator,
      inline,
      background,
      ...otherProps
    } = this.props

    const props = {
      className: classnames(
        c(
          this.classes('align', align),
          this.classes('margin', margin),
          this.classes('pad', pad),
          this.classes('size', size),
          this.classes('truncate', truncate),
          this.classes('uppercase', uppercase),
          this.classes('inline', inline),
          this.classes('weight', weight),
          this.classes('separator', separator),
          this.classes('context', { background })
        ),
        className
      ),
      ...otherProps,
    }

    switch (tag) {
      case 'h1':
        return <h1 {...props}>{children}</h1>
      case 'h2':
        return <h2 {...props}>{children}</h2>
      case 'h3':
        return <h3 {...props}>{children}</h3>
      case 'h4':
        return <h4 {...props}>{children}</h4>
      case 'h5':
        return <h5 {...props}>{children}</h5>
      default:
        return <h1 {...props}>{children}</h1>
    }
  }
}

export const Heading = withBoxContext<_Heading, Props>(_Heading)
