import * as React from 'react'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { AssessmentQuestionLoCountsLoader } from 'app/frontend/pages/material/teach/assessment-builder/assessment-question-lo-counts'
import { Box } from 'app/frontend/components/material/box'
import { connect } from 'react-redux'
import {
  isAnyTopicLoSaving,
  isAnyConceptSaving,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-reducer'
import { AssessmentLoSelectionModalRenderProps } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'

const t = tns('teach:assessment_lo_selection_modal')

interface StateProps {
  disableCta: boolean
}

export type Props = AssessmentLoSelectionModalRenderProps & StateProps

/**
 * Sticky Footer for the assessment LO selection modal
 */
export const _AssessmentLoSelectionModalFooter: React.FunctionComponent<Props> = ({
  options,
  hideModal,
  disableCta,
}) => {
  return (
    <Box
      dataBi="assessment-lo-selection-modal-footer"
      direction="row"
      full="page"
      justify="between"
      alignItems="center"
    >
      <AssessmentQuestionLoCountsLoader
        assessmentId={options.assessmentId}
        parentEntityId={options.parentEntityId}
        parentEntityType={options.parentEntityType}
        showWarnings={false}
      />
      <ButtonMaterial
        onClick={hideModal}
        disabled={disableCta}
        theme="primary"
        label={t('done')}
        data-bi="done-btn-selector"
      />
    </Box>
  )
}

_AssessmentLoSelectionModalFooter.displayName = 'AssessmentLoSelectionModalFooter'

function mapStateToProps(state, ownProps: AssessmentLoSelectionModalRenderProps): StateProps {
  const { assessmentId } = ownProps.options
  return {
    disableCta:
      (assessmentId &&
        (isAnyTopicLoSaving(state, assessmentId) || isAnyConceptSaving(state, assessmentId))) ||
      false,
  }
}

export const AssessmentLoSelectionModalFooter = connect<
  StateProps,
  {},
  AssessmentLoSelectionModalRenderProps
>(mapStateToProps)(_AssessmentLoSelectionModalFooter)
