import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
const GET_SEQUENCE = require('./get-sequence.gql')

interface SequenceQueryProps {
  sequence?: GQL.GetSequence.Sequence
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QuerySequenceArgs & {
  children: (props: SequenceQueryProps) => JSX.Element
}

export const SequenceQuery: React.FunctionComponent<Props> = ({ sequenceId, children }) => (
  <Query<GQL.GetSequence.Query, GQL.GetSequence.Variables>
    query={GET_SEQUENCE}
    variables={{ sequenceId }}
  >
    {({ data, loading, error }) =>
      children({
        sequence: isEmpty(data) ? null : data.sequence,
        loading,
        error,
      })
    }
  </Query>
)

SequenceQuery.displayName = 'SequenceQuery'
