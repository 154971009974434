import * as React from 'react'
import * as ErrorReporter from 'app/frontend/helpers/error-reporter'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('error_boundary')
import * as styles from './error-boundary.css'

interface Props {
  source: string
  onError?: (error: Error) => void
}

interface State {
  error?: Error
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Send error to our backend
    ErrorReporter.report(this.props.source, { error, info })
  }

  componentDidMount() {
    if (this.state.error) {
      this.props.onError?.(this.state.error)
    }
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (!prevState.error && this.state.error) {
      this.props.onError?.(this.state.error)
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <div className={styles.title}>{t('title')}</div>
          <div className={styles.message}>{t('message')}</div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
