import * as moment from 'moment-timezone'

export enum DateTimeFormats {
  MONTH_DATE_YEAR = 'MMM DD, YYYY',
  FULL_MONTH_DATE_YEAR = 'MMMM DD, YYYY',
  DAY_MONTH_DATE = 'dddd, MMM D',
  DAY_MONTH_DATE_YEAR = 'dddd, MMM D, YYYY',
  DAY_FULL_MONTH_DATE_YEAR = 'dddd, MMMM D, YYYY',
  MONTH_DATE = 'MMM DD',
  MONTH_DATE_ORDINAL = 'MMMM Do',
  FULL_MONTH_DATE = 'MMMM D',
  MONTH_DATE_TIME_12_WITH_ZONE = 'MMM DD h:mma z',
  MONTH_DATE_TIME_12_WITHOUT_ZONE_WITH_AT = 'MMM DD [at] h:mma',
  DAY_MONTH_DATE_TIME_12_WITH_ZONE = 'dddd, MMM DD h:mma z',
  TIME_12_WITH_ZONE = 'h:mma z',
  TIME_12_WITH_NO_ZONE = 'h:mma',
  PICKER_TIME_12_WITH_NO_ZONE = 'h:mm A',
  MONTH = 'MMM',
  DAY = 'DD',
}

export const formatMoment = (time: number, format: string, timezone?: string): string => {
  const zone = timezone || moment.tz.guess() || 'America/New_York'

  return moment.tz(time, zone).format(format)
}

export const getFullZoneName = (time: number, timezone?: string): string => {
  const zone = timezone || moment.tz.guess() || 'America/New_York'

  const abbrs: { [abbr: string]: string } = {
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
  }

  const abbr = moment.tz.zone(zone).abbr(time)

  // if the long form of the timezone doesn't exist in the longform above,
  // fall back to using the default abbreviation
  return abbrs[abbr] ? abbrs[abbr] : abbr
}
