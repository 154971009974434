import { useQuery } from '@apollo/client'
import * as GET_LEARNING_OBJECTIVES_FOR_COURSE from '../get-learning-objectives-for-course/get-learning-objectives-for-course.gql'
import * as GET_LEARNING_OBJECTIVES_FOR_SECTION from '../get-learning-objectives-for-section/get-learning-objectives-for-section.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import {
  buildLosToAssignmentMap,
  LearningObjectivesToAssignmentsMap,
} from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import { WatchQueryFetchPolicy } from '@apollo/client'

export type UseAssignedLearningObjectivesResults = RenderProps<{
  assignmentsByLearningObjectiveId: LearningObjectivesToAssignmentsMap
}>
type Data = GQL.GetLearningObjectivesForSection.Query | GQL.GetLearningObjectivesForCourse.Query
type Variables =
  | GQL.GetLearningObjectivesForSection.Variables
  | GQL.GetLearningObjectivesForCourse.Variables

export const useAssignedLearningObjectives = (
  parentEntityId: string,
  parentEntityType: Commons.ParentEntityType,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
): UseAssignedLearningObjectivesResults => {
  const query =
    parentEntityType === 'COURSE'
      ? GET_LEARNING_OBJECTIVES_FOR_COURSE
      : GET_LEARNING_OBJECTIVES_FOR_SECTION
  const variables =
    parentEntityType === 'COURSE'
      ? {
          courseId: parentEntityId,
        }
      : {
          sectionId: parentEntityId,
        }

  const { loading, error, data } = useQuery<Data, Variables>(query, {
    variables,
    fetchPolicy,
    nextFetchPolicy: 'cache-first', // TODO ALPACA-759
  })

  let learningObjectivesForParentEntity
  if (data) {
    if ('learningObjectivesForCourse' in data) {
      learningObjectivesForParentEntity = data.learningObjectivesForCourse
    } else {
      learningObjectivesForParentEntity = data.learningObjectivesForSection
    }
  }

  return {
    loading,
    error,
    assignmentsByLearningObjectiveId:
      learningObjectivesForParentEntity &&
      buildLosToAssignmentMap(learningObjectivesForParentEntity),
  }
}
