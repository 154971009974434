import { Reducer } from 'redux'
import {
  UPDATE_CONTENT,
  UpdateContentAction,
  UPDATE_DEMO_BOOSTERS,
  UpdateDemoBoostersAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export interface BoostersReducerState {
  stuckLo: string
  boosters: Commons.IPath[]
}

const defaultBoosters: BoostersReducerState = {
  stuckLo: null,
  boosters: [],
}

export const getBoosters = (state: LearnControllerState) => {
  return state.entities.learnFlow.boosters
}

export const reducer = (
  state: BoostersReducerState = defaultBoosters,
  action: UpdateContentAction | UpdateDemoBoostersAction
): BoostersReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case UPDATE_CONTENT:
      const updateContentAction = action as UpdateContentAction
      return updateContentAction.content.boosterAssignments
    case UPDATE_DEMO_BOOSTERS:
      const updateDemoBoostersAction = action as UpdateDemoBoostersAction
      return updateDemoBoostersAction.boosterAssignments
    default:
      return state
  }
}

export default reducer as Reducer<BoostersReducerState>
