import * as React from 'react'
import { CardMaterial } from 'app/frontend/components/material/card/card'
import { ListMaterial } from 'app/frontend/components/material/list/list'
import { ListItemMaterial, ListDividerMaterial } from 'app/frontend/components/material/list/list'
const styles = require('./spinner-text.css')

interface ITextSpinnerProps {
  title?: string
  removeCard?: boolean
  removeDividers?: boolean
}

export const TextSpinner: React.FunctionComponent<ITextSpinnerProps> = ({
  title,
  removeCard,
  removeDividers,
}) => {
  return (
    <div data-test="text-spinner">
      {title && <h2 className={styles.header}>{title}</h2>}
      <div>
        {removeCard ? (
          <ListMaterial selectable={false}>
            <DummyContents removeDividers={removeDividers} />
          </ListMaterial>
        ) : (
          <CardMaterial>
            <ListMaterial selectable={false}>
              <DummyContents removeDividers={removeDividers} />
            </ListMaterial>
          </CardMaterial>
        )}
      </div>
    </div>
  )
}

TextSpinner.displayName = 'TextSpinner'

const DummyContents: React.FunctionComponent<{ removeDividers?: boolean }> = ({
  removeDividers,
}) => (
  <>
    {dummyListItem('')}
    {!removeDividers && <ListDividerMaterial />}
    {dummyListItem('dummyContent1')}
    {!removeDividers && <ListDividerMaterial />}
    {dummyListItem('dummyContent2')}
    {!removeDividers && <ListDividerMaterial />}
    {dummyListItem('dummyContent3')}
    {!removeDividers && <ListDividerMaterial />}
    {dummyListItem('dummyContent4')}
  </>
)
DummyContents.displayName = 'DummyContents'

const dummyListItem = (className: string): JSX.Element => {
  return (
    <ListItemMaterial
      ripple={false}
      itemContent={dummyContentDiv(className)}
      theme={{ listItem: styles.dummyListItem }}
    />
  )
}

const dummyContentDiv = (className: string): JSX.Element => {
  const classes = `${styles.dummyContent} ${styles[className]} ${styles.animatedBackground}`
  return <div className={classes} />
}

export default TextSpinner
