import { sendTiming, TIME, DURATION } from 'app/frontend/pages/metrics/metrics-actions'
import { get as getCurrentUser } from 'app/frontend/helpers/current-user'

export function getDomainLookupTime(pageName) {
  const { timing } = window.performance
  return {
    name: `browser.page.${pageName}.DNS`,
    value: timing.domainLookupEnd - timing.domainLookupStart,
    metricType: TIME,
  }
}

export function getPageLoadMetrics(pageName) {
  const { timing } = window.performance
  return {
    name: `browser.page.${pageName}.load`,
    value: timing.domContentLoadedEventEnd - timing.navigationStart,
    metricType: TIME,
  }
}

function getUrlFragments(path) {
  return path.match(
    /^((http[s]?):\/)?\/?([^:\/\s]+)(:([^\/]*))?((\/[\w\/-]+)*\/)([\w\-\.]+[^#?\s]+)(\?([^#]*))?(#(.*))?$/i
  )
}

export function getResourcesTime(pageName) {
  const entries = window.performance.getEntries ? window.performance.getEntries() : []
  return entries.reduce((acc, entry) => {
    // Record first-paint and first-contentful-paint
    if (entry.entryType === 'paint') {
      acc.push({
        name: `browser.page.${pageName}.resource.paint.${entry.name}`,
        start: entry.startTime,
        end: entry.startTime,
        metricType: DURATION,
      })
    }

    // Record other resources excluding the ones in the blacklist
    const blackListResourceTypes = ['navigation', 'iframe', 'img']
    if (!blackListResourceTypes.includes((entry as any).initiatorType)) {
      const urlFragments = getUrlFragments(entry.name)
      if (urlFragments) {
        const domainName = urlFragments[3].replace(/[.-]/g, '_')
        const pathName = urlFragments[8].replace(/[.?\/-]/g, '_')
        const resourceName = `${domainName}_${pathName}`
        acc.push({
          name: `browser.page.${pageName}.resource.${resourceName}`,
          start: (entry as any).requestStart,
          end: (entry as any).responseEnd,
          metricType: DURATION,
        })
      }
    }
    return acc
  }, [])
}

export function getPageMetrics(pageName: string, dispatch: any) {
  if (!getCurrentUser()) {
    return
  }

  const metrics = []
  const domainLookupTime = getDomainLookupTime(pageName)
  const pageLoadTime = getPageLoadMetrics(pageName)
  const resourcesTime = getResourcesTime(pageName)

  metrics.push(domainLookupTime, pageLoadTime, ...resourcesTime)
  dispatch(sendTiming(metrics))
}
