import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import * as classnames from 'classnames'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Box } from 'app/frontend/components/material/box'
import {
  ASSESSMENT_SETTINGS_FORM,
  FormNames,
  FormValues,
} from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/form-fields/helper'
import * as baseStyles from 'app/frontend/layout/material/material-base.css'
import { useDidUpdateEffect } from 'app/frontend/hooks/use-did-update-effect'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { AlertBox } from 'app/frontend/components/material/alert-box/alert-box'
import { Icon } from 'app/frontend/components/material/icon'
import { useMaxExtensionDateForPath } from 'app/frontend/compositions/connected/get-enrollment-max-extension/use-enrollment-max-extension'
import { AnswerSettingField } from './answer-setting-field'
import { CommentSettingField } from './comment-setting-field'
import { ExplanationSettingField } from './explanation-setting-field'
import { GradeSettingField } from './grade-setting-field'
import { DropdownValues, isGreaterThan, SettingValueMap, SettingName } from './helper'
import { useStyles } from './styles'

const t = tns('teach:assessment_builder')
export const SUPPORT =
  'https://support.knewton.com/s/article/How-Students-View-Assessment-Quiz-Test-Results-Based-on-Assessment-Settings'

interface Props {
  isReadOnly: boolean
  assessmentId: string
  isLateSubmissionEnabled: boolean
  assessmentEndsAt: number
}

export const StudentResultsSetting: React.FunctionComponent<Props> = ({
  isReadOnly,
  assessmentId,
  isLateSubmissionEnabled,
  assessmentEndsAt,
}) => {
  const dispatch = useDispatch()
  const formValues: Partial<FormValues> = useSelector<FormValues>(
    getFormValues(ASSESSMENT_SETTINGS_FORM)
  )

  const styles = useStyles()
  const selectedSettingOptions: SettingValueMap = useDispatchNewSettingOptions(formValues)

  const isDefaultSettings =
    formValues.viewResultsGrade === DropdownValues.AFTER_SUBMISSION &&
    formValues.viewResultsAnswer === DropdownValues.AFTER_DUE_DATE &&
    formValues.viewResultsExplanation === DropdownValues.AFTER_DUE_DATE &&
    formValues.viewResultsComment === DropdownValues.AFTER_DUE_DATE

  const isAfterDueDateSelected =
    formValues.viewResultsGrade === DropdownValues.AFTER_DUE_DATE ||
    formValues.viewResultsAnswer === DropdownValues.AFTER_DUE_DATE ||
    formValues.viewResultsExplanation === DropdownValues.AFTER_DUE_DATE ||
    formValues.viewResultsComment === DropdownValues.AFTER_DUE_DATE

  const { extensionDate } = useMaxExtensionDateForPath(assessmentId, {
    skip: !isAfterDueDateSelected,
  })

  // By default, the user doesn't have any extension record. Once the instructor sets an extension,
  // the alert banner is displayed on the setting page. But if the instructor reverts the extension
  // back to the assessment due date, the extension record still will be in the table and that leads
  // code to think this assessment has extensions. to avoid the banner appearing in such cases, we check
  // that whether the extension date is greater than the assessment due date.
  const hasExtension = extensionDate != null && extensionDate > assessmentEndsAt

  const displayWarning = isAfterDueDateSelected && (isLateSubmissionEnabled || hasExtension)

  /**
   * function to handle setting reset
   * All setting set to default
   */
  const handleSettingsReset = () => {
    if (formValues.viewResultsGrade !== DropdownValues.AFTER_SUBMISSION) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_GRADE_SETTING,
          DropdownValues.AFTER_SUBMISSION
        )
      )
    }
    if (formValues.viewResultsAnswer !== DropdownValues.AFTER_DUE_DATE) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_ANSWER_SETTING,
          DropdownValues.AFTER_DUE_DATE
        )
      )
    }
    if (formValues.viewResultsExplanation !== DropdownValues.AFTER_DUE_DATE) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_EXPLANATION_SETTING,
          DropdownValues.AFTER_DUE_DATE
        )
      )
    }
    if (formValues.viewResultsComment !== DropdownValues.AFTER_DUE_DATE) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_COMMENT,
          DropdownValues.AFTER_DUE_DATE
        )
      )
    }
  }

  return (
    <Box className={styles.container} full="page">
      <Box direction={'row'} full="page" justify="between">
        <Box direction={'row'}>
          <Paragraph margin="none" weight="semibold">
            {t('setting_results_description')}
            <a
              target="_blank"
              href={SUPPORT}
              data-bi="setting-results-support-link"
              aria-label={t('setting_results_support_link_description')}
              className={classnames(styles.supportLink, baseStyles.textLinkFocus)}
            >
              {t('setting_results_support_link')}
            </a>
          </Paragraph>
        </Box>
        {!isDefaultSettings && !isReadOnly && (
          <ButtonMaterial
            className={styles.resetLink}
            onClick={handleSettingsReset}
            theme="bordered-light"
            label={t('setting_results_reset')}
            data-bi="settings-reset"
          />
        )}
      </Box>

      {displayWarning && (
        <AlertBox
          margin={{ top: 'medium' }}
          className={styles.warningBanner}
          data-test="warning-banner"
        >
          <Box direction="row" pad="medium" alignItems="center">
            <Icon name={'icon-info-outline'} className={styles.warningBannerIcon} />
            <Paragraph
              className={styles.warningBannerText}
              data-test="view-results-setting-due-date-warning"
              margin="none"
            >
              {t('setting_view_results_after_due_date_warning')}
            </Paragraph>
          </Box>
        </AlertBox>
      )}

      <GradeSettingField isReadOnly={isReadOnly} />
      <AnswerSettingField selectedSettingOptions={selectedSettingOptions} isReadOnly={isReadOnly} />
      <ExplanationSettingField
        selectedSettingOptions={selectedSettingOptions}
        isReadOnly={isReadOnly}
      />
      <CommentSettingField
        selectedSettingOptions={selectedSettingOptions}
        isReadOnly={isReadOnly}
      />
    </Box>
  )
}

export const useDispatchNewSettingOptions = (formValues: Partial<FormValues>): SettingValueMap => {
  const dispatch = useDispatch()
  const selectedSettingOptions: SettingValueMap = {
    [SettingName.GRADE]: formValues.viewResultsGrade,
    [SettingName.RESULTS]: formValues.viewResultsAnswer,
    [SettingName.EXPLANATION]: formValues.viewResultsExplanation,
    [SettingName.COMMENT]: formValues.viewResultsComment,
  }
  // the changes dispatching function should only be called when the component is updating but not
  // when the component mount. So the common wrapper for did update used for that purpose
  useDidUpdateEffect(() => {
    if (
      isGreaterThan(
        selectedSettingOptions[SettingName.GRADE],
        selectedSettingOptions[SettingName.RESULTS]
      )
    ) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_ANSWER_SETTING,
          selectedSettingOptions[SettingName.GRADE]
        )
      )
    }
    if (
      isGreaterThan(
        selectedSettingOptions[SettingName.RESULTS],
        selectedSettingOptions[SettingName.EXPLANATION]
      )
    ) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_EXPLANATION_SETTING,
          selectedSettingOptions[SettingName.RESULTS]
        )
      )
    }
    if (
      isGreaterThan(
        selectedSettingOptions[SettingName.RESULTS],
        selectedSettingOptions[SettingName.COMMENT]
      )
    ) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_COMMENT,
          selectedSettingOptions[SettingName.RESULTS]
        )
      )
    } else if (
      selectedSettingOptions[SettingName.COMMENT] === DropdownValues.NEVER &&
      isGreaterThan(
        selectedSettingOptions[SettingName.COMMENT],
        selectedSettingOptions[SettingName.RESULTS]
      )
    ) {
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.VIEW_RESULTS_COMMENT,
          selectedSettingOptions[SettingName.RESULTS] === DropdownValues.AFTER_SUBMISSION
            ? DropdownValues.IMMEDIATELY
            : selectedSettingOptions[SettingName.RESULTS]
        )
      )
    }
  }, [
    formValues.viewResultsGrade,
    formValues.viewResultsAnswer,
    formValues.viewResultsExplanation,
    formValues.viewResultsComment,
  ])
  return selectedSettingOptions
}
