import { Reducer } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_LEARN_CHEAT,
  LearnCheatAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export const getCheatForAtom = (
  state: LearnControllerState,
  variationId: string
): Commons.ICheat => {
  return state.entities.learnFlow.cheat[variationId] || null
}

export type CheatReducerState = {
  [variationId: string]: Commons.ICheat
}

export const reducer = (
  state: CheatReducerState = {},
  action: LearnCheatAction & ApiSuccessAction
): CheatReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_LEARN_CHEAT.SUCCESS:
      const variationId = action.args[2]

      return {
        ...state,
        [variationId]: action.response,
      }
    default:
      return state
  }
}

export default reducer as Reducer<CheatReducerState>
