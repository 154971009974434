import { makeApiActions } from 'app/frontend/api/api-redux'

export const SELECT_TAXON = 'SELECT_TAXON'
export function selectTaxon(taxonId) {
  return {
    type: SELECT_TAXON,
    taxonId,
  }
}

export const OPEN_ASSIGNMENT_PREVIEW = 'OPEN_ASSIGNMENT_PREVIEW'
export function openAssignmentPreview(previewData) {
  return {
    type: OPEN_ASSIGNMENT_PREVIEW,
    previewData,
  }
}

export const API_CREATE_ASSIGNMENT_PREVIEW = makeApiActions('API_CREATE_ASSIGNMENT_PREVIEW')

export const SELECT_PURPOSE_TYPE = 'SELECT_PURPOSE_TYPE'
export function selectPurposeType(taxonId, purposeType) {
  return {
    type: SELECT_PURPOSE_TYPE,
    taxonId,
    purposeType,
  }
}

export const SELECT_LEARNING_OBJECTIVE = 'SELECT_LEARNING_OBJECTIVE'
export function selectLearningObjective(taxonId, learningObjectiveId) {
  return {
    type: SELECT_LEARNING_OBJECTIVE,
    taxonId,
    learningObjectiveId,
  }
}

export const LOAD_MORE_CONTENT = 'LOAD_MORE_CONTENT'
export function loadMoreContent(taxonId, page) {
  return {
    type: LOAD_MORE_CONTENT,
    taxonId,
    page,
  }
}

export const API_GET_ASSIGNMENT_COVER_DETAIL = makeApiActions('API_GET_ASSIGNMENT_COVER_DETAIL')
export const API_GET_SEQUENCES_FOR_SEARCH_FILTERS = makeApiActions(
  'API_GET_SEQUENCES_FOR_SEARCH_FILTERS'
)
