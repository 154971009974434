import * as React from 'react'
import { Checkbox as CheckboxCore } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox'

export const useCheckboxGroupStyles = makeStyles(theme => ({
  checkBoxSelect: {
    outlineOffset: '0.063rem',
    outline: `0.125rem solid ${theme.palette?.blue[700]}`,
  },
  checkBoxVisible: {
    outlineOffset: '0.063rem',
    outline: `0.125rem solid ${theme.palette?.warning.contrastText}`,
  },
}))

export const Checkbox: React.FunctionComponent<CheckboxProps> = React.forwardRef(
  ({ checked, ...rest }, ref) => {
    const muiStyles = useCheckboxGroupStyles()
    return (
      <CheckboxCore
        ref={ref}
        checked={checked}
        {...rest}
        focusVisibleClassName={checked ? muiStyles.checkBoxSelect : muiStyles.checkBoxVisible}
        disableTouchRipple={!checked}
      />
    )
  }
)

Checkbox.displayName = 'Checkbox'
