import { mapLoadingAndErrorToProps, RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { isEmpty, mapValues } from 'lodash'
import { useAssessmentsForCourseOrSection } from 'app/frontend/compositions/connected/get-assessments-for-course-or-section'
import { useAssessmentQuestionsForAssessments } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-questions-for-assessments'

export type UseAssessedSequencesResults = RenderProps<{
  sequenceIdToAssessmentsMap?: {
    // This list contains assessement ids for the assessed sequence id
    [sequenceId: string]: string[]
  }
}>

/**
 * Returns a map of assessed sequences for a given course or section
 * @param parentEntityId courseId or sectionId
 * @param parentEntityType course or section
 */
export const useAssessedSequences = (
  parentEntityId: string,
  parentEntityType: Commons.ParentEntityType
): UseAssessedSequencesResults => {
  // Get all assessments for the given courseId or sectionId
  const assessmentsData = useAssessmentsForCourseOrSection(parentEntityId, parentEntityType)
  const assessmentIds = assessmentsData?.assessments?.map(assessment => assessment.id) || []
  // Get all assessment questions
  const assessmentQuestionsForAssessmentsData = useAssessmentQuestionsForAssessments(assessmentIds)

  const {
    loading,
    error,
    assessmentQuestionsForAssessmentsData: { assessmentQuestionsForAssessments },
  } = mapLoadingAndErrorToProps({
    assessmentQuestionsForAssessmentsData,
    assessmentsData,
  })

  // Build a set of assessment ids that assess a sequence. This set is keyed by the assessment sequence id.
  const sequenceIdToAssessmentsMap: { [sequenceId: string]: Set<string> } = {}
  if (!isEmpty(assessmentQuestionsForAssessments)) {
    for (const assessmentSequences of assessmentQuestionsForAssessments) {
      const assessmentId = assessmentSequences.assessmentId
      for (const assessmentQuestion of assessmentSequences.pathSequences) {
        const sequenceAssessments =
          sequenceIdToAssessmentsMap[assessmentQuestion.sequenceId] || new Set()
        sequenceAssessments.add(assessmentId)
        sequenceIdToAssessmentsMap[assessmentQuestion.sequenceId] = sequenceAssessments
      }
    }
  }

  return {
    loading,
    error,
    // convert set to an array
    sequenceIdToAssessmentsMap: mapValues(sequenceIdToAssessmentsMap, set => Array.from(set)),
  }
}
