import * as React from 'react'
import * as _ from 'lodash'
import { formatTime } from 'app/frontend/helpers/datetime'
import { isMobile } from 'app/frontend/helpers/device'
import VideoSlider from 'app/frontend/components/video/video-slider'
import * as DateTime from 'app/frontend/helpers/datetime'
import { tns } from 'app/frontend/helpers/translations/i18n'

import * as styles from './video-controls.css'
const t = tns('video_player')

interface Props {
  captionsOptions: any[] // Captions options from YouTube
  toggleFullScreen: (node: Node) => void
  timeRemaining?: number
  state: string
  videoCurrentTime: number
  playing: boolean
  end: number
  start: number
  volume: number
  playButtonClass: string
  captionsEnabled: boolean
  onVolumeButtonClick: () => void
  onVolumeChange: (value) => void
  onCaptionsButtonClick: () => void
  onClickPlayButton: () => void
  onScrubberSlide: (event, value) => void
  context: Node
}

export default class VideoControls extends React.Component<Props> {
  controls: string
  volume: VideoSlider
  scrubber: VideoSlider
  controlFullscreenFromKeyboard: () => void

  constructor(props) {
    super(props)

    this.controls = isMobile() ? 'native' : ''

    this.onClickFullscreen = this.onClickFullscreen.bind(this)
  }

  componentDidUpdate() {
    this.setVolume()
  }

  setVolume() {
    const volume = this.props.volume
    if (this.volume) {
      if (0 < volume && volume <= 100) {
        if (this.volume.getValue() !== volume) {
          this.volume.setValue(volume)
        }
      } else if (volume === 0) {
        this.volume.setValue(0)
      }
    }
  }

  /**
   * Returns the className for the root element of this component.
   */
  controlsClassName() {
    const ret = ['video-controls']
    if (this.props.captionsOptions.length > 0) {
      ret.push('with-captions-button')
    }

    return ret.join(' ')
  }

  /**
   * Returns true if fullscreen is supported, false otherwise.
   */
  hasFullscreenSupport() {
    const docEle = document.documentElement
    return (
      docEle.requestFullscreen ||
      (docEle as Commons.VideoHtmlElement).webkitRequestFullscreen ||
      docEle.mozRequestFullScreen ||
      docEle.msRequestFullscreen
    )
  }

  /**
   * Called when the user clicks the fullscreen button.
   */
  onClickFullscreen() {
    // get the player from inside the embed iframe
    const node = this.props.context.childNodes[0]
    this.props.toggleFullScreen(node)
  }

  /**
   * Renders the elapsed time.
   */
  renderTimeElapsed() {
    if (this.props.videoCurrentTime) {
      return formatTime(Math.max(Math.floor(this.props.videoCurrentTime) - this.props.start, 0))
    } else {
      return '0:00'
    }
  }

  /**
   * Renders the duration (or remaining time if video started).
   */
  renderTimeRemaining() {
    let timeRemaining = 0
    if (this.props.timeRemaining) {
      timeRemaining = this.props.timeRemaining
    } else if (this.props.end > 0) {
      timeRemaining = Math.max(Math.floor(this.props.end - (this.props.videoCurrentTime || 0)), 0)
    }

    return (
      <span aria-label={DateTime.humanizeTime(timeRemaining)}>
        {DateTime.formatTime(timeRemaining)}
      </span>
    )
  }

  /**
   * Returns the className for the volume button.
   */
  volumeButtonClass() {
    if (this.props.volume === 0) {
      return 'muted'
    } else {
      return ''
    }
  }

  /**
   * Returns the aria label for the volume button
   */
  volumeButtonLabel() {
    if (this.props.volume) {
      return t('mute')
    } else {
      return t('unmute')
    }
  }

  /*
   * Returns the className for the captions button
   */
  captionsButtonClass() {
    if (this.props.captionsEnabled) {
      return 'enabled'
    }

    if (_.isEmpty(this.props.captionsOptions)) {
      return 'unavailable'
    }
  }

  /**
   * Renders the controls for the video player.
   */
  render() {
    if (this.controls !== 'native' && this.props.state === 'LOADED') {
      return (
        <div className={this.controlsClassName()} onKeyDown={this.controlFullscreenFromKeyboard}>
          <div className="left-side">
            <button
              className={this.props.playButtonClass}
              type="button"
              onClick={this.props.onClickPlayButton}
              aria-label={this.props.playing ? t('pause') : t('play')}
            />
            <div className="time elapsed" aria-label={t('time_elapsed')}>
              {this.renderTimeElapsed()}
            </div>
          </div>
          <div className="video-scrubber" aria-label={t('seek_slider')}>
            <VideoSlider
              ref={scrubber => (this.scrubber = scrubber)}
              className={styles.trackSlider}
              min={this.props.start}
              max={this.props.end}
              onSlide={this.props.onScrubberSlide}
            />
          </div>
          <div className="right-side">
            <div className="time duration" aria-label={t('time_remaining')}>
              {this.renderTimeRemaining()}
            </div>
            <div className="captions">
              <button
                type="button"
                className={this.captionsButtonClass()}
                onClick={this.props.onCaptionsButtonClick}
                aria-label={t('toggle_captions')}
              >
                <span className="captions-button-text">CC</span>
              </button>
            </div>
            <div className="volume">
              <button
                type="button"
                className={this.volumeButtonClass()}
                onClick={this.props.onVolumeButtonClick}
                aria-label={this.volumeButtonLabel()}
              />
              <div className="slider-wrapper">
                <VideoSlider
                  ref={volume => (this.volume = volume)}
                  className={styles.volumeSlider}
                  vertical={true}
                  onChange={this.props.onVolumeChange}
                  min={0}
                  max={100}
                />
              </div>
            </div>
            {this.hasFullscreenSupport() && (
              <button
                className="full-screen"
                type="button"
                onClick={this.onClickFullscreen}
                aria-label={t('fullscreen')}
              />
            )}
          </div>
        </div>
      )
    } else {
      // do not render controls if mobile
      return null
    }
  }
}
