/**
 * A topic in the assessment table of contents.
 *
 * This component is a wrapper around the generic TableOfContentsTopic component. It is memoized to prevent
 * unnecessary re-renders. Since a very high percentage of the elements on the TOC page are at or under the
 * topic level, this dramatically decreases the overall cost of a re-render of the TOC upon LO toggles.
 */

import * as React from 'react'
import { useCallback } from 'react'
import {
  ConceptStatus,
  ConceptStatusesByLoMap,
  LearningObjectiveId,
  LoConceptMap,
  LoStatusMap,
  TopicLoErrorType,
  TopicLoStatus,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-types'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import {
  getConceptStatusesByTopic,
  getTopicLoStatusMap,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-reducer'
import {
  TableOfContentsLearningObjective,
  TableOfContentsTopic,
} from 'app/frontend/pages/material/teach/compositions/data/table-of-contents'
import { AssessmentBuilderModalType } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { TableOfContentsLoConcept } from 'app/frontend/pages/material/teach/compositions/data/table-of-contents/table-of-contents-lo-concept'
import { getConceptStatus, isAnyConceptSavingForLo } from './assessment-concept-selection-reducer'
import { TOCLearningObjectiveError } from 'app/frontend/pages/material/teach/compositions/data/table-of-contents/table-of-contents-learning-objective'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_EDIT_QUESTIONS,
  TEACH_SECTION_ASSESSMENT_EDIT_QUESTIONS,
} from 'app/frontend/data/mixpanel-events'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'

const t = tns('teach:coursework_builder')
const EMPTY_STATUS_MAP = {}

export interface OwnProps {
  /**
   * The ID of the assessment being edited
   */
  assessmentId: string
  /**
   * The name of the chapter containing this topic
   */
  chapterName: string
  /**
   * The topic whose contents to render
   */
  topic: GQL.TopicTaxonFields.Fragment
  /**
   * The set of learning objectives in this topic selected in the browser. The parent component must provide an
   * entirely new set whenever an LO in this topic is toggle or this component will not re-render.
   */
  selectedLos: Set<LearningObjectiveId>
  /**
   * The set of learning objectives in this topic selected in the saved assignment state. The parent component must
   * provide an entirely new set whenever the persisted selection state of an LO in this topic is updated, or
   * this component will not re-render.
   */
  persistedSelectedLos: Set<LearningObjectiveId>
  /**
   * The number of sequences for each LO in this topic. The parent component must provide an entirely new object
   * whenever the count of sequences for an LO in this topic changes, or this component will not rerender.
   */
  numSequencesByLo: { [loId: string]: number }

  /**
   * The number of questions for each concept for each LO in this topic. The parent component must provide an entirely
   * new object whenever the count of questions for LO concept in this topic changes, or this component will not
   * rerender.
   */
  numQuestionsForConceptForLo: { [loId: string]: { [conceptId: string]: number } }

  /**
   * Callback to be invoked when any of the LOs in this topic are toggled on or off
   * @param topicId The ID of the topic containing the toggled LOs
   * @param loIds The IDs of the toggled LOs
   * @param loConcepts map from LO IDs to selected Concept IDs
   * @param checked True if the specified LOs were toggled on, and false if they were toggled off
   */
  onLosToggled: (
    topicId: string,
    loIds: LearningObjectiveId[],
    loConcepts: LoConceptMap,
    checked: boolean
  ) => void

  /**
   * Callback to be invoked when any of the concepts in this lo and topic are toggled on or off
   * @param conceptId The ID of the toggled concept
   * @param loId The ID of LO of the concept
   * @param topicId The ID of the topic containing the toggled concept
   * @param checked True if the specified LOs were toggled on, and false if they were toggled off
   */
  onConceptToggled: (conceptId: string, loId: string, topicId: string, checked: boolean) => void

  /**
   * The parent entity type to determine course or section
   */
  parentEntityType: Commons.ParentEntityType
}

export interface StateProps {
  /**
   * The loading/error status of the LOs in this topic
   */
  loStatuses: LoStatusMap

  /**
   * The loading/error status of the concepts in the topic, mapped by LO
   */
  conceptStatuses: ConceptStatusesByLoMap
}

// Visible for test
export type Props = OwnProps

const _AssessmentTableOfContentsTopic: React.FunctionComponent<Props> = ({
  assessmentId,
  chapterName,
  topic,
  selectedLos,
  persistedSelectedLos,
  numSequencesByLo,
  numQuestionsForConceptForLo,
  onLosToggled,
  onConceptToggled,
  parentEntityType,
}) => {
  const dispatch = useDispatch()
  const stateProps: StateProps = useSelector<TeachControllerState, StateProps>(
    (state: TeachControllerState) => {
      const topicLoStatuses = getTopicLoStatusMap(state, assessmentId)
      const conceptStatusByTopic = getConceptStatusesByTopic(state, assessmentId)
      const loStatusesValues = topicLoStatuses[topic.id] || EMPTY_STATUS_MAP
      const conceptStatusesValues = conceptStatusByTopic[topic.id] || EMPTY_STATUS_MAP
      return {
        loStatuses: loStatusesValues,
        conceptStatuses: conceptStatusesValues,
      }
    },
    shallowEqual
  )

  const onLoClicked = useCallback(
    (_: string, lo: GQL.LearningObjectiveFields.Fragment, conceptId?: string) => {
      // send mixpanel event
      sendEventTeachAssessment(
        parentEntityType === ParentEntityType.Course
          ? TEACH_COURSE_ASSESSMENT_EDIT_QUESTIONS
          : TEACH_SECTION_ASSESSMENT_EDIT_QUESTIONS,
        assessmentId
      )
      // show questions modal
      dispatch(
        showModal(AssessmentBuilderModalType.QuestionSelection, {
          assessmentId,
          learningObjectiveId: lo.id,
          loDescription: lo.description,
          topicName: topic.name,
          chapterName: chapterName,
          fromObjectivesModal: true,
          conceptId,
        })
      )
    },
    [assessmentId, chapterName, topic.name]
  )

  const onToggleTopic = useCallback(
    (topicId: string, loIds: string[], checked: boolean) => {
      const loConcepts = topic.learningObjectives.reduce((obj, lo) => {
        obj[lo.id] = new Set(lo.conceptCoverage.map(concept => concept.id))
        return obj
      }, {})
      onLosToggled(topicId, loIds, loConcepts, checked)
    },
    [onLosToggled]
  )

  const onToggleLo = useCallback(
    (loId: string, topicId: string, checked: boolean) => {
      const lo = topic.learningObjectives.find(objective => objective.id === loId)
      const loConcepts = { [loId]: new Set(lo.conceptCoverage.map(concept => concept.id)) }
      onLosToggled(topicId, [loId], loConcepts, checked)
    },
    [onLosToggled]
  )

  const { conceptStatuses, loStatuses } = stateProps
  const losChecked: { [loId: string]: boolean } = {}
  for (const lo of topic.learningObjectives) {
    const anyConceptErrors = conceptStatuses[lo.id]
      ? Object.values(conceptStatuses[lo.id])?.some(status => status === ConceptStatus.Error)
      : false
    if (loStatuses[lo.id]?.status === TopicLoStatus.Error || anyConceptErrors) {
      losChecked[lo.id] = persistedSelectedLos.has(lo.id)
    } else {
      losChecked[lo.id] = selectedLos.has(lo.id)
    }
  }

  const isTopicChecked = Object.values(losChecked).every(isChecked => isChecked)

  return (
    <TableOfContentsTopic topic={topic} checked={isTopicChecked} toggleTopic={onToggleTopic}>
      {topic.learningObjectives.map(lo => {
        const isLoChecked = losChecked[lo.id]
        const isSaving = loStatuses[lo.id]?.status === TopicLoStatus.Saving
        const numSequences = isLoChecked ? numSequencesByLo[lo.id] : undefined
        // In the rare case that an LO exists in multiple topics, we want to ensure that it is disabled if the LO has
        // already been added on another topic
        const loEnabledInDifferentTopic = !isLoChecked && !!numSequencesByLo[lo.id]
        const warningText =
          loEnabledInDifferentTopic && !isSaving ? t('learning_objective_exists') : null
        const disabled = isSaving || loEnabledInDifferentTopic
        const numQuestionsForConceptsByLo =
          numQuestionsForConceptForLo && numQuestionsForConceptForLo[lo.id]
        const isExpanded = lo.hasLoConcepts && isLoChecked
        let error
        if (loStatuses[lo.id]?.status === TopicLoStatus.Error) {
          error = {
            type: loStatuses[lo.id].type || TopicLoStatus.Error,
            // If LO status has `TooManyLearningObjectives` error, show related error message,
            // otherwise show nothing in the tooltip
            ...(loStatuses[lo.id].type === TopicLoErrorType.TooManyObjectives && {
              message: t('too_many_learning_objectives'),
            }),
          } as TOCLearningObjectiveError
        }
        return (
          <TableOfContentsLearningObjective
            key={lo.id}
            objective={lo}
            topicId={topic.id}
            numSequences={numSequences}
            checked={isLoChecked}
            toggleObjective={onToggleLo}
            isSaving={isSaving}
            disabled={disabled}
            warningText={warningText}
            error={error}
            onClick={isLoChecked ? onLoClicked : undefined}
            isExpanded={isExpanded}
          >
            {lo.hasLoConcepts &&
              lo.conceptCoverage.map(concept => {
                const numQuestions =
                  numQuestionsForConceptsByLo && numQuestionsForConceptsByLo[concept.id]
                    ? numQuestionsForConceptsByLo[concept.id]
                    : 0
                const conceptStatus = getConceptStatus(conceptStatuses, concept.id, lo.id)
                const isLoading = conceptStatus === ConceptStatus.Saving
                const isError = conceptStatus === ConceptStatus.Error
                // Disable concept when any concept is saving to prevent
                // race cond from parallel requests when multiple concept is selected
                // fast enough.
                const conceptDisabled =
                  disabled || isLoading || isAnyConceptSavingForLo(conceptStatuses, lo.id)
                const isChecked = isLoChecked ? numQuestions > 0 : false
                return (
                  <TableOfContentsLoConcept
                    key={concept.id}
                    topicId={topic.id}
                    objective={lo}
                    loConceptId={concept.id}
                    loConceptChecked={isLoading ? !isChecked : isChecked}
                    numQuestions={numQuestions}
                    disabled={conceptDisabled}
                    triggerPreviewModal={isLoChecked ? onLoClicked : undefined}
                    toggleLoConcept={onConceptToggled}
                    isSaving={isLoading}
                    isError={isError}
                  />
                )
              })}
          </TableOfContentsLearningObjective>
        )
      })}
    </TableOfContentsTopic>
  )
}

_AssessmentTableOfContentsTopic.displayName = 'AssessmentTableOfContentsTopic'

export const AssessmentTableOfContentsTopic = React.memo(_AssessmentTableOfContentsTopic)
