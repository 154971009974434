import * as React from 'react'
import { Field } from 'redux-form'
import { useToggle } from 'app/frontend/components/material/helpers'
import * as NewInstructorPasswordReset from 'app/public/img/new-instructor-password-reset.svg'
import { getStaticUrl } from 'app/frontend/helpers/assets'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Heading } from 'app/frontend/components/material/heading'
import { HiddenField, Password } from './reset-fields'
import * as styles from './new-instructor-reset-password.css'

const t = tns('forgot_password')
const tNewInstructor = tns('new_instructor_password_page')

interface Props {
  handleSubmit?: () => void
  valid?: boolean
  submitting?: boolean
}

export const NewInstructorResetPassword: React.FunctionComponent<Props> = ({
  handleSubmit,
  valid,
  submitting,
}) => {
  const [newPasswordVisible, toggleNewPasswordVisible] = useToggle(false)
  return (
    <>
      <img src={getStaticUrl(NewInstructorPasswordReset)} role="presentation" alt="" />
      <Heading tag={'h1'} pad={{ top: 'medium' }} size={'h2'} className={styles.almostThere}>
        {tNewInstructor('almost_there')}
      </Heading>
      <Paragraph className={styles.subHeader}>{tNewInstructor('set_password_subheader')}</Paragraph>
      <Field component={HiddenField} name="email" />
      <Field component={HiddenField} name="token" />
      <Field component={HiddenField} name="valid" />
      <Field
        component={Password}
        name="password"
        label={tNewInstructor('create_a_password')}
        visible={newPasswordVisible}
        toggle={toggleNewPasswordVisible}
        passwordText={t('show_password')}
        displayRequirements={true}
        describedBy={'passwordRequirements'}
      />
      <Field component={HiddenField} name="newInstructor" />

      <ButtonMaterial
        type="submit"
        fullWidth={true}
        onClick={handleSubmit}
        data-bi="set-password-button"
        label={tNewInstructor('set_password')}
        disabled={submitting || !valid}
      />
    </>
  )
}
