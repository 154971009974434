import { Action } from 'redux'

export type VIDEO_PLAY = 'VIDEO_PLAY'
export const VIDEO_PLAY = 'VIDEO_PLAY'
export interface VideoPlayAction extends Action {
  type: VIDEO_PLAY
  clipId: string
}
export function videoPlay(clipId: string): VideoPlayAction {
  return {
    type: VIDEO_PLAY,
    clipId,
  }
}

export type VIDEO_STOP = 'VIDEO_STOP'
export const VIDEO_STOP = 'VIDEO_STOP'
export interface VideoStopAction extends Action {
  type: VIDEO_STOP
  clipId: string
}
export function videoStop(clipId: string): VideoStopAction {
  return {
    type: VIDEO_STOP,
    clipId,
  }
}
