import { useLearningObjectiveForCourse } from 'app/frontend/pages/material/teach/compositions/connected/get-learning-objectives-for-course'
import { useLearningObjectiveForSection } from 'app/frontend/pages/material/teach/compositions/connected/get-learning-objectives-for-section'
import { useAssessmentSequences } from 'app/frontend/compositions/connected/get-assessment-sequences'
import { ParentEntityType } from 'app/typings/commons'
import { useSequencesWithIndividualQueries } from 'app/frontend/compositions/connected/get-sequences'
import { mapLoadingAndErrorToProps, RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { useAssignment } from 'app/frontend/pages/material/teach/compositions/connected/get-assignment'
import { useAssessedSequences } from 'app/frontend/pages/material/teach/compositions/connected/get-assessed-sequences'
import { getRepeatableStaticAndPoolCount } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-helper'
import * as Feature from 'app/frontend/helpers/feature'
import { getUsedQuestionCount } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-helper'

type useAssessmentQuestionsMetadataResults = RenderProps<{
  assessmentQuestionMetadata: {
    notPrintFriendlyCount: number
    notLdbFriendlyCount: number
    learningObjectiveCount: number
    incompatibleQuestionCount: number
    notAssignedLoCount: number
    repeatablePoolQuestionCount: number
    repeatableStaticQuestionCount: number
    maxNumAttempts: number
    printableVariantsCount: number
    usedQuestionsCount: number
  }
}>

export const useAssessmentQuestionsMetadata = (
  assessmentId: string,
  parentEntityId: string,
  parentEntityType: ParentEntityType,
  customItemVariationLength?: number
): useAssessmentQuestionsMetadataResults => {
  let learningObjectiveData
  const assessmentData = useAssignment(assessmentId)
  const assessmentSequencesData = useAssessmentSequences(assessmentId)
  const assessedSequencesData = useAssessedSequences(parentEntityId, parentEntityType)

  const sequenceIds =
    assessmentSequencesData?.assessmentSequences?.map(sequence => sequence.sequenceId) || []

  const triggerRefetchVariables = { customItemVariationLength: customItemVariationLength }
  const sequencesData = useSequencesWithIndividualQueries(sequenceIds, triggerRefetchVariables)
  if (parentEntityType === ParentEntityType.Section) {
    learningObjectiveData = useLearningObjectiveForSection(parentEntityId)
  } else {
    learningObjectiveData = useLearningObjectiveForCourse(parentEntityId)
  }
  const {
    loading,
    error,
    sequencesData: { sequences },
    learningObjectiveData: { assignmentsByLearningObjectiveId },
    assessmentData: { assignment: assessment },
    assessedSequencesData: { sequenceIdToAssessmentsMap },
  } = mapLoadingAndErrorToProps({
    assessmentSequencesData,
    sequencesData,
    learningObjectiveData,
    assessmentData,
    assessedSequencesData,
  })
  let repeatableStaticQuestionCount = 0
  let repeatablePoolQuestionCount = 0
  let maxNumAttempts = 0
  let printableVariantsCount = 0

  const usedQuestionsCount = getUsedQuestionCount(
    sequences,
    sequenceIdToAssessmentsMap,
    assessmentId
  )
  const isAssessmentOffline = assessment?.quizConfiguration.printable
  // TODO : when cleaning up the feature flag, directly assign values to variables
  if (Feature.isEnabled('alertOnMultipleAttemptsVersionFlag')) {
    // if the assessment is in offline mode, then max attempts count is considered as 1 (default)
    maxNumAttempts = !isAssessmentOffline ? assessment?.quizConfiguration.maxNumAttempts ?? 0 : 1
    printableVariantsCount = assessment?.quizConfiguration.printableVariantsCount ?? 0
    const versionsOrAttemptsCount = isAssessmentOffline ? printableVariantsCount : maxNumAttempts
    const { staticQuestionCount, poolQuestionCount } = getRepeatableStaticAndPoolCount(
      versionsOrAttemptsCount,
      sequences
    )
    repeatableStaticQuestionCount = staticQuestionCount
    repeatablePoolQuestionCount = poolQuestionCount
  }
  const learningObjectiveCount = assessment?.pathLearningObjectives.length ?? 0
  const notPrintFriendlyCount =
    (isAssessmentOffline && sequences?.filter(seq => !seq.isPrintFriendly).length) || 0
  const isAssessmentLdb = assessment?.quizConfiguration.useLdb
  const notLdbFriendlyCount =
    (isAssessmentLdb && sequences?.filter(seq => !seq.isLdbFriendly).length) || 0
  const hasNotPrintFriendlyWarning = isAssessmentOffline && notPrintFriendlyCount > 0
  const hasNotLdbFriendlyWarning = isAssessmentLdb && notLdbFriendlyCount > 0
  const incompatibleQuestionCount = hasNotPrintFriendlyWarning
    ? notPrintFriendlyCount
    : hasNotLdbFriendlyWarning
    ? notLdbFriendlyCount
    : 0
  const assignedLosInAssessment = assessment?.pathLearningObjectives.map(
    plo => plo.learningObjectiveId
  )
  const assignedLosInCourseOrSection =
    assignmentsByLearningObjectiveId && assignmentsByLearningObjectiveId.assignedLearningObjectives
  const notAssignedLoCount =
    assignedLosInCourseOrSection &&
    assignedLosInAssessment?.filter(loId => !assignedLosInCourseOrSection[loId]).length

  return {
    error,
    loading,
    assessmentQuestionMetadata: {
      notPrintFriendlyCount,
      notLdbFriendlyCount,
      learningObjectiveCount,
      incompatibleQuestionCount,
      notAssignedLoCount,
      repeatableStaticQuestionCount,
      repeatablePoolQuestionCount,
      maxNumAttempts,
      printableVariantsCount,
      usedQuestionsCount,
    },
  }
}
