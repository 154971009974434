import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { Box, Drawer } from '@mui/material'
import NavActions from './nav-actions'
import * as LogoAltaColorSvg from 'app/public/img/logo-knewton-alta-color-2.svg'
import { getStaticUrl } from 'app/frontend/helpers/assets'

const navBarStyles = theme => ({
  root: {},
  paper: {
    width: '17.5rem', // mobile size used by react-toolbox
    [theme.breakpoints?.up('sm')]: {
      width: '20rem', // desktop size used by react-toolbox.
    },
  },
  logo: {
    height: '2.125rem',
  },
  logoBox: {
    alignItems: 'flex-end',
    display: 'flex',
    paddingLeft: '1rem',
    paddingBottom: '0.8rem',
    height: '4rem',
  },
})

export type NavDrawerStyles = WithStyles<typeof navBarStyles>

interface INavDrawerProps extends NavDrawerStyles {
  Content: React.ComponentType
  isActive: boolean
  modalActive: boolean
  onToggleDrawer: (joinCourseActive: boolean) => void
}

export class NavDrawer extends React.Component<INavDrawerProps, {}> {
  componentDidUpdate(prevProps: INavDrawerProps) {
    if (this.props.isActive && !prevProps.isActive) {
      setTimeout(() => {
        const focusableElements = this.getFocusableElements()
        if (focusableElements.length > 0) {
          focusableElements[0].focus()
        }
      })
    }

    if (this.props.isActive) {
      document.addEventListener('keydown', this.handleKeyboardTrap)
    } else if (!this.props.isActive && prevProps.isActive) {
      document.removeEventListener('keydown', this.handleKeyboardTrap)
    }
  }

  handleKeyboardTrap = event => {
    const focusableElements = this.getFocusableElements()
    const current = event.target
    const firstElement = focusableElements[0] as any
    const lastElement = focusableElements[focusableElements.length - 1] as any
    // if user hits shift+tab on the first element, move focus to the end of the NavDrawer
    if (current === focusableElements[0] && event.shiftKey && event.key === 'Tab') {
      event.preventDefault()
      lastElement.focus()
      // if user hits tab on the last element, move focus to the top of the NavDrawer
    } else if (
      current === focusableElements[focusableElements.length - 1] &&
      !event.shiftKey &&
      event.key === 'Tab'
    ) {
      event.preventDefault()
      firstElement.focus()
    }
  }

  getFocusableElements = (): HTMLElement[] => {
    // get all elements that are links or have a tabIndex
    return [
      ...document.body.querySelectorAll(
        '[id=nav-drawer-content] a[href], [id=nav-drawer-content] [tabindex]'
      ),
    ] as HTMLElement[]
  }

  render(): JSX.Element {
    const { isActive, onToggleDrawer, Content, modalActive } = this.props

    const { logo, logoBox, ...drawerStyles } = this.props.classes

    return (
      <Drawer
        open={isActive}
        classes={drawerStyles}
        onClose={() => onToggleDrawer(modalActive)}
        // Join course modal won't have the focus if this property not set
        ModalProps={{ disableEnforceFocus: true }}
      >
        <Box>
          <Box className={logoBox}>
            <img src={getStaticUrl(LogoAltaColorSvg)} alt="Knewton Alta logo" className={logo} />
          </Box>
          <Box id="nav-drawer-content">
            <Content />
          </Box>
        </Box>
      </Drawer>
    )
  }
}

function mapStateToProps(state: any): Partial<INavDrawerProps> {
  const isActive = _.get<boolean>(state, 'global.ui.nav.navDrawer', false)
  const modalActive = !_.isEmpty(state.global.ui.modal)
  return {
    isActive,
    modalActive,
  }
}

function mapDispatchToProps(dispatch: any): Partial<INavDrawerProps> {
  return {
    onToggleDrawer: (modalActive: boolean): void => {
      if (!modalActive) {
        dispatch(NavActions.toggleNavDrawer())
      }
    },
  }
}

export default connect<{}, Partial<INavDrawerProps>, Partial<INavDrawerProps>>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(navBarStyles)(NavDrawer))
