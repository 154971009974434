import { combineReducers } from 'redux'
import uiReducer, { UIState } from './ui/ui-reducer'
import entitiesReducer, { EntitiesState } from './entities/entities-reducer'

export type InstitutionAdminControllerState = {
  ui: UIState
  entities: EntitiesState
}

export default combineReducers<InstitutionAdminControllerState>({
  ui: uiReducer,
  entities: entitiesReducer,
})
