// Returns whether or not the device being used is a mobile device.
// This includes tablets
export const isMobile = () => {
  const regex =
    '(android|bbd+|meego).+mobile|avantgo|bada/|blackberry|blazer|compal|' +
    'elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo' +
    '|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)/|plucker|pocket|psp|' +
    'series(4|6)0|symbian|treo|up.(browser|link)|vodafone|wap|windows (ce|phone)|' +
    'xda|xiino'
  return new RegExp(regex, 'i').test(window.navigator.userAgent)
}

// Returns whether or not the device being used is an iPad
export const isIpad = () => {
  return new RegExp('ipad', 'i').test(window.navigator.userAgent)
}

// Returns whether or not the device being used on ChromeOS
export const isChromebook = () => {
  return new RegExp('CrOS', 'i').test(window.navigator.userAgent)
}
