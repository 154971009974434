import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

interface UseDialogStylesProps {
  fullScreen?: boolean
  noPad?: boolean
}

export const useDialogStyles = makeStyles<Theme, UseDialogStylesProps>(() =>
  createStyles({
    paper: { padding: 0 },
    container: props => {
      if (props.fullScreen) {
        return {
          width: '100vw',
          maxWidth: '100vw',
          height: '100vh',
          maxHeight: '100vh',
          borderRadius: 0,
          overflow: 'auto',
          alignSelf: 'start',
        }
      }
    },
  })
)

export const useContainerStyles = makeStyles({
  container: {
    display: 'flex',
  },
  leftAction: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  rightAction: {
    flex: 1,
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '-0.5rem -0.5rem 0 -0.5rem',
  },
})

export const useDialogTitleStyles = makeStyles<Theme, UseDialogStylesProps>(theme =>
  createStyles({
    root: props => ({
      padding: props.noPad ? '0' : props.fullScreen ? '1rem 1rem 0' : '1.5 rem 1.5rem 0',
      backgroundColor: theme.palette?.common.white,
    }),
  })
)

export const useDialogContentStyles = makeStyles<Theme, UseDialogStylesProps>(theme =>
  createStyles({
    root: props => {
      const base = {
        backgroundColor: theme.palette?.common.white,
        padding: '0 1.5rem 1.5rem',
      }

      if (props.noPad) {
        base.padding = '0'
      } else if (props.fullScreen) {
        base.padding = '0 1rem 1rem'
      }

      if (!props.fullScreen) {
        base['maxWidth'] = '628px'
      }

      return base
    },
  })
)

export const useDialogActionStyles = makeStyles({
  root: {
    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.16)',
    height: '4.25rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'flexEnd',
    padding: 0,
  },
  container: {
    margin: 'auto',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    aligntItems: 'center',
    maxWidth: '794px',
  },
})
