import { groupBy, uniqBy } from 'lodash'
import { useAssessmentSequences } from 'app/frontend/compositions/connected/get-assessment-sequences/use-assessment-sequences'
import { useSequencesWithIndividualQueries } from 'app/frontend/compositions/connected/get-sequences'
import { RenderProps, mapLoadingAndErrorToProps } from 'app/frontend/helpers/apollo/adopt'

export type AssessmentQuestionsByConceptByLo = {
  [loId: string]: {
    [conceptId: string]: string[] // pathSequenceVariationIds[]
  }
}

/**
 * Given a list of assessment sequences and a list
 * of their parent sequences, return pathSequenceVariationIds
 * grouped by concept and by LO.
 */
export const prepareResults = (
  assessmentSequences: GQL.AssessmentSequenceFields.PathSequences[],
  sequences: GQL.Sequence[]
): AssessmentQuestionsByConceptByLo => {
  const results = {} as AssessmentQuestionsByConceptByLo
  // Group the assessment sequences by parent sequence id.
  // If assessment sequences are under the same parent sequence,
  // it means they target the same concepts under an LO.
  const groupedAssessmentSequences = groupBy(assessmentSequences, 'sequenceId')
  const uniqSequences = uniqBy(sequences, sequence => sequence.id)

  for (const sequence of uniqSequences) {
    // The parent sequence tells us the target LO and the target concepts
    // under that LO. It's possible for two parent sequences to target
    // different concepts under the same LO.
    const loId = sequence.atoms[0].learningObjectiveId
    const conceptIds = sequence.atoms[0].conceptIds
    // We know the assessment sequences have to target the concepts that
    // are on their parent sequence. So we'll populate results[loId][conceptId]
    // with these assessment sequence ids.
    const variationIds =
      groupedAssessmentSequences[sequence.id]?.map(as => as.pathSequenceVariationId) || []

    if (variationIds.length) {
      if (!results[loId]) {
        results[loId] = {}
      }

      for (const conceptId of conceptIds) {
        if (!results[loId][conceptId]) {
          results[loId][conceptId] = []
        }
        results[loId][conceptId].push(...variationIds)
      }
    }
  }

  return results
}

export type UseAssessmentQuestionsByConceptByLoResults = RenderProps<{
  results: AssessmentQuestionsByConceptByLo
}>

export const useAssessmentQuestionsByConceptByLo = (
  assessmentId: string
): UseAssessmentQuestionsByConceptByLoResults => {
  const asData = useAssessmentSequences(assessmentId)
  const sequenceIds = asData?.assessmentSequences?.map(as => as.sequenceId) || []
  const sData = useSequencesWithIndividualQueries(sequenceIds)
  const {
    loading,
    error,
    asData: { assessmentSequences },
    sData: { sequences },
  } = mapLoadingAndErrorToProps({ asData, sData })

  return {
    loading,
    error,
    results:
      !assessmentSequences || !sequences ? null : prepareResults(assessmentSequences, sequences),
  }
}
