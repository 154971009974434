import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/assessment-setting-reset-warning/assessment-setting-reset-warning.css'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { AlertBox } from 'app/frontend/components/material/alert-box/alert-box'
import { Icon } from 'app/frontend/components/material/icon'
import { Paragraph } from 'app/frontend/components/material/paragraph'

const t = tns('teach:assessment_builder')

interface Props {
  warningMessage: string
  onReset: () => void
  dataBi?: string
}

export const AssessmentSettingResetWarning: React.FunctionComponent<Props> = ({
  warningMessage,
  onReset,
  dataBi,
}) => (
  <AlertBox customStyle={styles.bannerContainer}>
    <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
      <Icon name="icon-warning" className={styles.bannerIcon} />
      <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
        <Paragraph className={styles.bannerMessage} data-test={`${dataBi}-paragraph`}>
          {warningMessage}
        </Paragraph>
        <ButtonMaterial
          className={styles.bannerResetText}
          onClick={onReset}
          theme="bordered-light"
          label={t('reset_to_course_setting')}
          data-bi={`${dataBi}-link`}
        />
      </Box>
    </Box>
  </AlertBox>
)

AssessmentSettingResetWarning.displayName = 'AssessmentSettingResetWarning'
