import * as React from 'react'
import { Icon } from './icon'
import * as styles from './icon-with-shadow.css'
import { IconProps } from 'app/frontend/components/material/icon/index'

export const ShadowedIcon: React.FunctionComponent<IconProps> = props => (
  <div className={styles.iconWrapper}>
    <Icon {...props} />
  </div>
)
