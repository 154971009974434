import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useContentManagerState } from '../content-manager-state'
import { reportAtomRenderFailure, reportAtomRenderSuccess } from './actions'

/**
 * This component consumes from our Content Manager Context. When it detects that all sub-content
 * has been loaded or any have errored, it will report the success or failure to the backend.
 */
export const ContentMetrics: React.FunctionComponent<{}> = ({ children }) => {
  useContentMetrics()
  return <>{children}</>
}

/**
 * Consumes from the Content Manager to report when all of an Atom's asynchronous sub-content has
 * finished loading or has hit an error.
 */
export const useContentMetrics = () => {
  const managerState = useContentManagerState()
  const isReported = React.useRef(false)
  const renderStartTime = React.useRef(window.performance.now())
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!isReported.current && managerState.status.length > 0 && managerState.atom) {
      const errored = managerState.status.filter(status => status.errors.length > 0)

      if (errored.length > 0) {
        dispatch(
          reportAtomRenderFailure(
            errored[errored.length - 1].debug, // the first item to reach failure
            managerState.atom,
            window.performance.now() - renderStartTime.current,
            managerState.renderLocation
          )
        )
        isReported.current = true
      } else if (!managerState.status.some(status => status.loading)) {
        dispatch(
          reportAtomRenderSuccess(
            managerState.status[0].debug, // the last item to reach success
            managerState.atom,
            window.performance.now() - renderStartTime.current,
            managerState.renderLocation
          )
        )
        isReported.current = true
      }
    }
  }, [managerState])
}

export default ContentMetrics
