// https://www.apollographql.com/docs/react/caching/cache-configuration/

import { cacheRedirectQueries } from './cache-redirect-queries'
import { typePolicies } from './type-policies'
import { cacheBlockList } from './cache-block-list'
export { cacheRedirectQueries, cacheBlockList, typePolicies }

// Make sure that there is no overlap with the keys of these objects
type NoIntersection<T extends { [k in keyof T & keyof U]: never }, U> = unknown

type foo = NoIntersection<typeof cacheBlockList, typeof typePolicies>
type bar = NoIntersection<typeof cacheRedirectQueries, typeof typePolicies>
type baz = NoIntersection<typeof cacheRedirectQueries, typeof cacheBlockList>

// @ts-ignore
type intersectionDetection = foo & bar & baz
