import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import * as styles from './edit-question-points.css'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { CardMaterial } from 'app/frontend/components/material/card/card'
import { Heading } from 'app/frontend/components/material/heading'
import * as classnames from 'classnames'
import { useState, useEffect } from 'react'
import {
  AssessmentSequenceWithOverride,
  calculateTotalAssessmentPoints,
} from 'app/frontend/helpers/assignment'
import { convertToPercent2Decimals } from 'app/frontend/helpers/percentage'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { EditQuestionPointsInput, EditQuestionInputType } from './edit-question-points-input'
import { SaveStatusIndicator } from '../save-status-indicator'
import { Paragraph } from 'app/frontend/components/material/paragraph'

const t = tns('edit_question_points')
const MIN_POINT_VALUE = 0
const MAX_POINT_VALUE = 1000

interface Props {
  sequenceId: string
  assessmentSequences: AssessmentSequenceWithOverride[]
  points: number
  weight: string
  isActive: boolean
  isPoolQuestion: boolean
  hasError?: boolean
  isSaving?: boolean
  onSave: (updatedPointValue: number) => void
  onCancel: () => void
}

export const EditQuestionPointsWrapper: React.FunctionComponent<Props> = ({
  points,
  weight,
  isActive,
  hasError,
  isSaving,
  isPoolQuestion,
  onSave,
  onCancel,
  assessmentSequences,
  sequenceId,
}) => {
  const [currentPointValue, setCurrentPointValue] = useState<EditQuestionInputType>(points)
  const [currentWeight, setCurrentWeight] = useState<string>(weight)
  const [inputValidationError, setInputValidationError] = useState<string>(null)
  useEffect(() => {
    resetState()
  }, [points, weight])

  /**
   * Resets the state of the component to match the props
   */
  const resetState = () => {
    setCurrentWeight(weight)
    setCurrentPointValue(points)
  }

  /**
   * Calculates the weight of a question if it given the value passed in
   * @param newPointValue
   */
  const calcNewWeight = (newPointValue: number) => {
    const updatedAssessmentSequence = assessmentSequences.map(assessmentSequence => {
      if (assessmentSequence.sequenceId !== sequenceId) {
        return assessmentSequence
      } else {
        return {
          ...assessmentSequence,
          points: newPointValue,
        }
      }
    })

    const newTotalPoints = calculateTotalAssessmentPoints(updatedAssessmentSequence)
    return convertToPercent2Decimals(newPointValue / newTotalPoints)
  }

  /**
   * Sets the current point value state to the input value and runs it through validation.
   * If it is valid, re-calculate the weight and clear any existing validations. Otherwise,
   * set the validation error
   * @param input
   */
  const onChange = (input: EditQuestionInputType) => {
    setCurrentPointValue(input)
    const validationError = validateInput(input)

    if (validationError) {
      setInputValidationError(validationError)
    } else {
      setCurrentWeight(calcNewWeight(input as number))
      setInputValidationError(null)
    }
  }

  return (
    <CardMaterial className={classnames({ [styles.hidden]: !isActive }, styles.editQuestionPoints)}>
      <Box>
        <Heading
          tag="h5"
          margin={{ top: 'medium' }}
          className={styles.setPoints}
          data-test="edit-points-heading"
        >
          {t('set_points')}
        </Heading>
        {isPoolQuestion && (
          <Paragraph data-test="edit-points-pool-question" className={styles.poolQuestion}>
            {t('pool_question_warning')}
          </Paragraph>
        )}
        <EditQuestionPointsInput
          points={currentPointValue}
          weight={currentWeight}
          onChange={onChange}
          validationError={inputValidationError}
        />
        <Box direction="row" reverse={true} full="horizontal">
          <Box pad={{ left: 'medium' }}>
            <ButtonMaterial
              data-bi="edit-points-save"
              label={t('save')}
              disabled={!!inputValidationError || !!isSaving}
              onClick={() => {
                // currentPointValue is of type EditQuestionInputType, but if we get
                // this far then it must be a number and we treat it as so
                onSave(currentPointValue as number)
              }}
            />
          </Box>
          <Box>
            <ButtonMaterial
              data-bi="edit-points-cancel"
              label={t('cancel')}
              theme="bordered"
              onClick={() => {
                resetState()
                onCancel()
              }}
            />
          </Box>
          <SaveStatusIndicator
            flex={true}
            isSaving={isSaving}
            hasError={hasError}
            className={styles.saveState}
            iconSize="medium"
            savingLabel={t('saving')}
            errorLabel={t('error')}
            data-test="edit-points-save-state"
          />
        </Box>
      </Box>
    </CardMaterial>
  )
}

/**
 * Returns a validation error string if the input is not valid. If there is no return
 * value then there are no validation errors.
 *
 * An input value is not valid if:
 *   a. It is not an integer
 *   b. It is less than 0 or greater than 1000
 *
 * @param input
 */
export const validateInput = (input: EditQuestionInputType): string => {
  if (
    input === '' ||
    !Number.isInteger(input) ||
    input < MIN_POINT_VALUE ||
    input > MAX_POINT_VALUE
  ) {
    return t('invalid_input')
  }
}

EditQuestionPointsWrapper.displayName = 'EditQuestionPointsWrapper'
