import { combineReducers } from 'redux'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { NavActions } from './nav-actions'

function navDrawerReducer(state = false, action: NavActions.Any | LocationChangeAction): boolean {
  if (!action) {
    return state
  }
  switch (action.type) {
    case NavActions.TOGGLE_NAV_DRAWER:
      if (action.forceToggle === 'SHOW') {
        return true
      }
      if (action.forceToggle === 'HIDE') {
        return false
      }
      return !state
    case LOCATION_CHANGE:
      return false
    default:
      return state
  }
}

function navMenuReducer(state = false, action: NavActions.Any): boolean {
  if (!action) {
    return state
  }
  switch (action.type) {
    case NavActions.TOGGLE_NAV_MENU:
      if (action.forceToggle === 'SHOW') {
        return true
      }
      if (action.forceToggle === 'HIDE') {
        return false
      }
      return !state
    default:
      return state
  }
}

function pageTitleReducer(state = '', action: NavActions.Any): string {
  if (!action) {
    return state
  }
  switch (action.type) {
    case NavActions.UPDATE_PAGE_TITLE:
      return action.pageTitle
    default:
      return state
  }
}

export type UiState = {
  navDrawer: boolean
  navMenu: boolean
  pageTitle: string
}

export default combineReducers({
  navDrawer: navDrawerReducer,
  navMenu: navMenuReducer,
  pageTitle: pageTitleReducer,
})
