import * as React from 'react'
import { useSelector } from 'react-redux'
import * as _ from 'lodash'
import { GradeUpdateSnackbar } from 'app/frontend/components/material/grade-update-snackbar/grade-update-snackbar'
import {
  getGradeUpdateSnackbarState,
  GradeUpdateSnackbarReducerState,
} from './grade-update-snackbar-reducer'

const GradeUpdateSnackbarContainer: React.FunctionComponent = () => {
  const {
    userId,
    updatedAfter,
    lastShownAt,
    sectionId,
  } = useSelector((state: GradeUpdateSnackbarReducerState) => getGradeUpdateSnackbarState(state))

  if ((!userId && !sectionId) || _.isNil(updatedAfter) || _.isNil(lastShownAt)) {
    return null
  }

  return <GradeUpdateSnackbar />
}

export default GradeUpdateSnackbarContainer
