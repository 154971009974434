import { Theme } from '@mui/material'
import { createStyles } from '@mui/styles'

export const radioGroupStyles = (theme: Theme) =>
  createStyles({
    label: {
      ...theme.typography.body2,
      color: theme.palette?.grey[600],
    },
    labelRoot: {},
    formControl: {},
  })

export const radioStyles = (theme: Theme) =>
  createStyles({
    root: {
     padding: '0.45rem 0.5rem',
      '&$optionChecked': {
        color: theme.palette?.primary.main,
        '&$optionDisabled': {
          color: theme.palette?.grey[300],
        },
      },
    },
    optionChecked: {},
    optionDisabled: {},
    radioVisible: {
        outlineOffset: '-0.4rem',
        outline: `0.125rem solid ${theme.palette?.warning.contrastText}`,
    },
  })
