import { useQuery, QueryHookOptions } from '@apollo/client'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import * as GET_RAW_ASSIGNMENT from './get-raw-assignment.gql'

export type UseRawAssignment = RenderProps<{
  assignment: GQL.GetRawAssignment.RawAssignment
}>

export const useRawAssignment = (
  assignmentId: string,
  opts?: QueryHookOptions<GQL.GetRawAssignment.Query, GQL.GetRawAssignment.Variables>
): UseRawAssignment => {
  const { loading, error, data } = useQuery<
    GQL.GetRawAssignment.Query,
    GQL.GetRawAssignment.Variables
  >(GET_RAW_ASSIGNMENT, {
    ...opts,
    variables: {
      assignmentId,
    },
  })

  return {
    loading,
    error,
    assignment: data && data.rawAssignment,
  }
}
