import { combineReducers } from 'redux'
import { taxonomyReducer } from 'app/frontend/helpers/taxonomy/taxonomy-reducer'
import coursesReducer, { CoursesState, getCourseById } from './courses/courses-reducer'
import assignmentsReducer, {
  IAssignmentsReducer,
  isAssignmentAdaptive,
} from './assignments/assignments-reducer'
import enrollmentsReducer, {
  IEnrollmentsReducer,
  getEnrollmentByAssignmentId,
} from './enrollments/enrollments-reducer'
import gradesReducer, { GradesState } from './grades/grades-reducer'
import analyticsReducer, {
  AnalyticsReducerState,
  getStatusAndProgressByAssignmentId,
} from './analytics/analytics-reducer'
import historyReducer, { IHistoryReducer } from './history/history-reducer'
import questionCountReducer, { IQuestionCountReducer } from './questionCount/questionCount-reducer'
import learnFlowEntitiesReducer, {
  LearnFlowEntitiesState,
} from './learn-flow/learn-flow-entities-reducer'
import quizFlowEntitiesReducer, {
  QuizFlowEntitiesState,
} from './quiz-flow/quiz-flow-entities-reducer'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

/**
 * Return true if analytics have been loaded for all started assignments in the given course
 *
 * @param state The root redux state
 * @param courseId The id of the course to check for
 */
export const areCourseAnalyticsLoaded = (
  state: LearnControllerState,
  courseId: string
): boolean => {
  const course = getCourseById(state, courseId)
  if (!course || !course.paths) {
    return false
  }

  const assignmentIds = course.paths
  const adaptiveAssignmentIds = assignmentIds.filter(assignmentId =>
    isAssignmentAdaptive(state, assignmentId)
  )
  for (const assignmentId of adaptiveAssignmentIds) {
    const enrollment = getEnrollmentByAssignmentId(state, assignmentId)
    const existingStatusAndProgress = getStatusAndProgressByAssignmentId(state, assignmentId)
    if (enrollment && enrollment.startedAt && !existingStatusAndProgress) {
      return false
    }
  }

  return true
}

export type EntitiesState = {
  courses: CoursesState
  assignments: IAssignmentsReducer
  enrollments: IEnrollmentsReducer
  grades: GradesState
  analytics: AnalyticsReducerState
  historyStates: IHistoryReducer
  questionCount: IQuestionCountReducer
  learnFlow: LearnFlowEntitiesState
  quizFlow: QuizFlowEntitiesState
  taxonomy: { [id: string]: Taxonomy.ITaxon }
}

export default combineReducers<EntitiesState>({
  courses: coursesReducer,
  assignments: assignmentsReducer,
  enrollments: enrollmentsReducer,
  grades: gradesReducer,
  analytics: analyticsReducer,
  historyStates: historyReducer,
  questionCount: questionCountReducer,
  learnFlow: learnFlowEntitiesReducer,
  quizFlow: quizFlowEntitiesReducer,
  taxonomy: taxonomyReducer,
})
