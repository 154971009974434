import * as React from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { ButtonMaterial, ButtonTheme } from 'app/frontend/components/material/button/button'
import { Heading } from 'app/frontend/components/material/heading'
import { createModal } from 'app/frontend/components/material/modal'
import { ModalBody } from 'app/frontend/components/material/modal/modal-body'
import { ModalFooter } from 'app/frontend/components/material/modal/modal-footer'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import * as GET_SEQUENCE_OVERRIDES from 'app/frontend/compositions/connected/get-sequence-overrides/get-sequence-overrides.gql'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import {
  TEACH_SEQUENCE_OVERRIDE_COURSE,
  TEACH_SEQUENCE_OVERRIDE_SECTION,
} from 'app/frontend/data/mixpanel-events'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { isMarkedCorrect } from 'app/frontend/helpers/compound'
import { ResultOverrideType } from 'app/typings/commons'
import * as SET_SEQUENCE_OVERRIDE from './set-sequence-override.gql'
import * as DELETE_SEQUENCE_OVERRIDE from './delete-sequence-override.gql'

const t = tns('teach:mark_correct_for_all')
export const SET_OVERRIDE_MODAL = 'SET_OVERRIDE_MODAL'

interface Props {
  sequenceId: string
  assessmentId: string
  resultOverride: GQL.ResultOverrideType
  overrideValue?: number
  isCourse: boolean
}

interface InnerProps extends Props {
  hideModal: () => void
}

const ModalComponent = createModal<Props>()

export const SetOverrideModal: React.FunctionComponent = () => (
  <ModalComponent
    name={SET_OVERRIDE_MODAL}
    dataBi="set-override-modal"
    fullScreen={false}
    preventClose={true}
  >
    {({ hideModal, options }) => <ModalInner {...options} hideModal={hideModal} />}
  </ModalComponent>
)

export const ModalInner: React.FunctionComponent<InnerProps> = ({
  sequenceId,
  hideModal,
  assessmentId,
  isCourse,
  overrideValue,
  resultOverride,
}) => {
  const dispatch = useDispatch()
  const showMessage = (message: string) => dispatch(showSnackbar({ message }))

  const refetchQueries = [
    {
      query: GET_SEQUENCE_OVERRIDES,
      variables: { assessmentId },
    },
  ]

  const handleCompleted = () => {
    sendEventTeachAssessment(
      isCourse ? TEACH_SEQUENCE_OVERRIDE_COURSE : TEACH_SEQUENCE_OVERRIDE_SECTION,
      assessmentId,
      {
        sequence_id: sequenceId,
        result_override: resultOverride,
        override_value: overrideValue,
      }
    )
    hideModal()
  }

  const handleError = () => showMessage(t('error'))

  const [deleteOverrides, { loading: deleteOverridesLoading }] = useMutation(
    DELETE_SEQUENCE_OVERRIDE,
    {
      variables: {
        sequenceId,
        assessmentId,
      },
      refetchQueries,
      context: { silenceErrors: true },
      onCompleted: handleCompleted,
      onError: handleError,
    }
  )
  const [setOverrides, { loading: setOverridesLoading }] = useMutation(SET_SEQUENCE_OVERRIDE, {
    variables: {
      sequenceId,
      resultOverride,
      assessmentId,
      overrideValue,
    },
    refetchQueries,
    context: { silenceErrors: true },
    onCompleted: handleCompleted,
    onError: handleError,
  })

  let header: string
  let text: string
  let confirm: string
  let ctaTheme: ButtonTheme = 'secondary'

  if (resultOverride === null) {
    header = t('undo_header')
    text = isCourse ? t('undo_text') : t('undo_text_section')
    confirm = t('proceed')
  } else if (resultOverride === ResultOverrideType.REMOVED_FROM_ASSESSMENT) {
    header = t('delete_header')
    text = isCourse ? t('delete_text') : t('delete_text_section')
    confirm = t('remove')
    ctaTheme = 'warn'
  } else if (isMarkedCorrect(resultOverride, overrideValue)) {
    header = t('mark_all_header')
    text = isCourse ? t('mark_all_text') : t('mark_all_text_section')
    confirm = t('proceed')
  }
  return (
    <>
      <ModalBody>
        <Heading tag="h1" size="h4" weight="semibold" margin={{ bottom: 'medium' }}>
          {header}
        </Heading>
        <Paragraph data-test="text">{text}</Paragraph>
        <Paragraph>{t('student_override_warning')}</Paragraph>
      </ModalBody>
      <ModalFooter>
        <ButtonMaterial
          theme="secondary"
          label={t('cancel')}
          data-bi="cancel-button"
          onClick={hideModal}
        />
        <ButtonMaterial
          theme={ctaTheme}
          label={confirm}
          disabled={deleteOverridesLoading || setOverridesLoading}
          onClick={() => (resultOverride ? setOverrides() : deleteOverrides())}
          data-bi="set-override-modal-confirm"
        />
      </ModalFooter>
    </>
  )
}
