import { useQuery, useApolloClient, ApolloClient } from '@apollo/client'
import { isEmpty } from 'lodash'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { useQueryForCollectionWithIndividualQuery } from 'app/frontend/helpers/apollo/hooks'
import * as GET_SEQUENCE from 'app/frontend/compositions/connected/get-sequence/get-sequence.gql'
import * as SEQUENCE_FIELDS from 'app/frontend/compositions/connected/fragments/sequence-fields.gql'
import * as GET_SEQUENCES from './get-sequences.gql'

export type UseSequencesResults = RenderProps<{ sequences: GQL.Sequence[] }>

/**
 * Fetches sequences using individual GET_SEQUENCE gql queries.
 *
 * @param sequenceIds             An array of sequence IDs to fetch.
 * @param triggerRefetchVariables An object with key-value pairs that trigger refetching based on variable changes.
 *
 * note: This should be used if you know that the sequenceIds argument is small or
 * if you know that the most of the sequences already exist in the cache. This will only
 * make gql queries to the backend for sequences that aren't already in the cache
 */
export const useSequencesWithIndividualQueries = (
  sequenceIds: string[],
  triggerRefetchVariables?: { [key: string]: any }
): UseSequencesResults => {
  const queryArgs = sequenceIds.map(sequenceId => ({ sequenceId }))
  const { loading, error, data } = useQueryForCollectionWithIndividualQuery<
    GQL.GetSequence.Query,
    GQL.GetSequence.Variables
  >(GET_SEQUENCE, queryArgs, triggerRefetchVariables)
  const sequences = data?.map(r => r.sequence)
  return { loading, error, sequences }
}

/**
 * Fetches sequences for ids that are not available in the cache using the batch query
 * @param sequenceIds list of sequence ids
 */
export const useCachedSequencesWithBatchQuery = (sequenceIds: string[]): UseSequencesResults => {
  const client = useApolloClient()
  const { cachedSequences, sequenceIdsNotInCache } = getSequencesFromApolloCache(
    sequenceIds,
    client
  )

  const { loading, error, data } = useQuery<GQL.GetSequences.Query, GQL.GetSequences.Variables>(
    GET_SEQUENCES,
    {
      variables: {
        sequenceIds: sequenceIdsNotInCache,
      },
    }
  )

  return {
    loading,
    error,
    sequences:
      loading && !isEmpty(cachedSequences)
        ? cachedSequences
        : [...cachedSequences, ...(data?.sequences ?? [])],
  }
}

type GetSequencesFromApolloCacheType = {
  cachedSequences: GQL.Sequence[]
  sequenceIdsNotInCache: string[]
}

/**
 * Returns a list of cached sequences and a list of sequence ids that are not available in the cache
 * @param sequenceIds List of sequenceIds to fetch from cache
 * @param client Apollo client
 */
const getSequencesFromApolloCache = (
  sequenceIds: string[],
  client: ApolloClient<object>
): GetSequencesFromApolloCacheType => {
  return sequenceIds.reduce(
    (res: GetSequencesFromApolloCacheType, sequenceId: string) => {
      const sequence = client.readFragment<GQL.Sequence>({
        id: `Sequence:${sequenceId}`,
        fragment: SEQUENCE_FIELDS,
      })

      if (sequence) {
        res.cachedSequences.push(sequence)
      } else {
        res.sequenceIdsNotInCache.push(sequenceId)
      }

      return res
    },
    {
      cachedSequences: [],
      sequenceIdsNotInCache: [],
    }
  )
}
