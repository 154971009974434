import { Reducer } from 'redux'
import * as _ from 'lodash'

export type InstitutionsByIdState = {
  [id: string]: Commons.IInstitution
}
const userInstitutions = _.get(window, 'context.user.institutions', {})
const defaultInstitutionsById: InstitutionsByIdState = _.keyBy(userInstitutions, 'id')

export const reducer = (state: InstitutionsByIdState = defaultInstitutionsById) => {
  return state
}

export const getInstitutionById = (state, institutionId) =>
  _.get(state, `entities.institutions.byId[${institutionId}]`, {})

export default reducer as Reducer<InstitutionsByIdState>
