export const LEARNOSITY_MOUNT_ID = 'learnosity-question-editor'
export const INVALID_LEARNOSITY = 'INVALID_LEARNOSITY'

export enum CUSTOM_QUESTION_TYPES {
  MultipleChoice = 'mcq',
  FreeResponse = 'formulaV2',
}

// The basic settings for a new multiple choice question
export const DEFAULT_MC_QUESTION_DATA = {
  multiple_responses: false,
  is_math: true,
  math_renderer: 'mathquill',
  options: [
    { label: 'Choice A', value: '0' },
    { label: 'Choice B', value: '1' },
    { label: 'Choice C', value: '2' },
    { label: 'Choice D', value: '3' },
  ],
  stimulus: '<p>Your question here</p>',
  type: CUSTOM_QUESTION_TYPES.MultipleChoice,
}

// The basic settings for a new free response question
export const DEFAULT_FR_QUESTION_DATA = {
  is_math: true,
  math_renderer: 'mathquill',
  stimulus: 'Your question here',
  ui_style: { type: 'block-on-focus-keyboard' },
  validation: {
    scoring_type: 'exactMatch',
    valid_response: {
      score: 1,
      value: [{ method: 'equivSymbolic', options: { decimalPlaces: 10 } }],
    },
  },
  type: CUSTOM_QUESTION_TYPES.FreeResponse,
  symbols: [
    'algebra',
    'basic',
    'comparison',
    'grouping',
    'qwerty',
    'sets',
    'misc',
    'matrices',
    'trigonometry',
    'greek',
    'calculus',
  ],
}

export const isMultipleChoice = (
  questionType?: Content.LearnosityType,
  questionData?: Commons.ILearnosityPayload
): boolean =>
  questionType === CUSTOM_QUESTION_TYPES.MultipleChoice ||
  (!!questionData && questionData.type === CUSTOM_QUESTION_TYPES.MultipleChoice)

export const isFreeResponse = (
  questionType?: Content.LearnosityType,
  questionData?: Commons.ILearnosityPayload
): boolean =>
  questionType === CUSTOM_QUESTION_TYPES.FreeResponse ||
  (!!questionData && questionData.type === CUSTOM_QUESTION_TYPES.FreeResponse)
