import * as moment from 'moment'

/**
 * These methods help with feeding in props
 * for Datepickers - minDate, maxDate, and the
 * default selected date if applicable.
 */

/**
 * Calculate the boundary for a selectable start date.
 * If the entity's start date is in the future, use that.
 * If not, make it today.
 */
export const getStartDateLimit = (startsAt = 0): moment.Moment =>
  moment(Math.max(startsAt, moment().startOf('day').valueOf()))

/**
 * Calculate the boundary for a selectable end date.
 * If the entity's end date is in the future, use that.
 * If not, make it today. This complements getStartDateLimit()
 * to disable all dates if this is the case.
 */
export const getEndDateLimit = (endsAt = 0): moment.Moment =>
  moment(Math.max(endsAt, moment().endOf('day').valueOf()))

/**
 * Calculate the default start date to display.
 * Stay within the bounds of the entity's start and end dates,
 * even if they're in the past.
 */
export const getStartDateDefault = (startsAt: number, endsAt: number): moment.Moment => {
  const today = moment().startOf('day').valueOf()

  return startsAt < today && today < endsAt ? moment(today) : moment(startsAt)
}

/**
 * Calculate the default end date to display.
 * This should always be the end date provided.
 */
export const getEndDateDefault = (endsAt: number): moment.Moment => moment(endsAt)

/**
 * Calculate the start date to display.
 * for the given start date and end date, check its
 * validity after 1 week gap from today, if it doesn't bound
 * with endsAt limit, start date will be the SOD
 * of the endsAt
 *
 * @param startsAt - the start date
 * @param endsAt - the end date
 */
export const getStartDateWeekAfterToday = (startsAt: number, endsAt: number): moment.Moment => {
  const today = moment()
  const startDate = today.valueOf() > startsAt ? today : moment(startsAt)
  const weekAfter = startDate.add(1, 'week').startOf('day').valueOf()
  return weekAfter < endsAt ? moment(weekAfter) : moment(endsAt).startOf('day')
}

/**
 * Calculate the end date to display.
 * for the given start date, check its validity after
 * adding 1 week gap (EOD) to it, if it doesn't bound
 * with endsAt limit, display end date will be same as
 * the given endsAt
 *
 * @param startsAt - the start date
 * @param endsAt - the end date
 */
export const getEndDateWeekAfterToday = (startsAt: number, endsAt: number): moment.Moment => {
  const today = moment()
  const startDate = today.valueOf() > startsAt ? today : moment(startsAt)
  const weekAfter = startDate.add(1, 'week').endOf('day').valueOf()
  return weekAfter < endsAt ? moment(weekAfter) : moment(endsAt)
}
