import * as React from 'react'
import * as classnames from 'classnames'
import { Box } from '@mui/material'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './question-score.css'

const t = tns('atom_variation')

interface QuestionScoreProps {
  maxPoints: number
  score: number
}

export const QuestionScore: React.FunctionComponent<QuestionScoreProps> = ({
  maxPoints,
  score,
}) => {
  return (
    <Box component={'span'} letterSpacing={'0.0625rem'} flexDirection={'row'}>
      <Box component={'span'} paddingLeft={'0.5rem'} paddingRight={'0.5rem'}>
        ·
      </Box>
      <span
        data-test="question-score"
        className={classnames({
          // for correct and partial-credit options, the score will be greater than 0
          // and highlighted color will be the same.
          [styles.correct]: score > 0,
          [styles.incorrect]: score === 0,
        })}
      >
        {t('score', {
          score,
          maxPoints,
        })}
      </span>
    </Box>
  )
}

QuestionScore.displayName = 'QuestionScore'
