import {
  SHOW_GOAL_UPDATE_SNACKBAR,
  HIDE_GOAL_UPDATE_SNACKBAR,
  GoalUpdateSnackbarActions,
} from './goal-update-snackbar-actions'
import { getId } from 'app/frontend/helpers/current-user'

export interface GoalUpdateSnackbarReducerState {
  userId: string
  courseId?: string
  updatedAfter: number
  lastShownAt: number
}

const defaultState = {
  userId: null,
  courseId: null,
  updatedAfter: null,
  lastShownAt: null,
}

export const getGoalUpdateSnackbarState = (
  state: any
): GoalUpdateSnackbarReducerState | undefined => {
  return state.global.ui.goalupdatesnackbars
}

export function goalUpdateSnackbarReducer(
  state: GoalUpdateSnackbarReducerState = defaultState,
  action: GoalUpdateSnackbarActions
): GoalUpdateSnackbarReducerState {
  switch (action.type) {
    case SHOW_GOAL_UPDATE_SNACKBAR:
      const now = Date.now()
      return {
        userId: getId(),
        courseId: action.payload.courseId,
        updatedAfter: state.updatedAfter === null ? now - 10000 : state.updatedAfter,
        lastShownAt: now,
      }
    case HIDE_GOAL_UPDATE_SNACKBAR:
      return defaultState
    default:
      return state
  }
}
