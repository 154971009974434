import * as React from 'react'
import { QuestionViewPreferencesOptionsViewDensity } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'
import { AssessmentSequenceWithOverride } from 'app/frontend/helpers/assignment'
import { SequenceIdToAssessmentsMapType } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-questions-helper'
import { ParentEntityType } from 'app/typings/commons'
import { useAssignedLearningObjectives } from 'app/frontend/pages/material/teach/compositions/connected/get-assigned-learning-objectives/use-assigned-learning-objectives'
import { QuestionViewPreferencesOrder } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/question-view-preferences-order'

interface Props {
  assessment: GQL.GetAssignment.Assignment
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  titles: GQL.TitleTaxonFields.Fragment[]
  displayDensity?: QuestionViewPreferencesOptionsViewDensity
  assessmentSequences: AssessmentSequenceWithOverride[]
  isLmsIntegrated: boolean
  allAssessedSequences: SequenceIdToAssessmentsMapType
  hasEditPermission: boolean
}

export const AssessmentQuestionOrderViewWrapper: React.FunctionComponent<Props> = ({
  assessment,
  isCourseAssignmentOnSection,
  hasStudentStartedAssignment,
  titles,
  assessmentSequences,
  isLmsIntegrated,
  allAssessedSequences,
  hasEditPermission,
}) => {
  const parentEntityId = assessment.sectionId || assessment.courseId
  const parentEntityType = assessment.sectionId ? ParentEntityType.Section : ParentEntityType.Course

  const { assignmentsByLearningObjectiveId } = useAssignedLearningObjectives(
    parentEntityId,
    parentEntityType
  )

  return (
    <QuestionViewPreferencesOrder
      assessment={assessment}
      hasStudentStartedAssignment={hasStudentStartedAssignment}
      isCourseAssignmentOnSection={isCourseAssignmentOnSection}
      titles={titles}
      assessmentSequences={assessmentSequences}
      isLmsIntegrated={isLmsIntegrated}
      allAssessedSequences={allAssessedSequences}
      assignmentsByLearningObjectiveId={assignmentsByLearningObjectiveId}
      hasEditPermission={hasEditPermission}
    />
  )
}

AssessmentQuestionOrderViewWrapper.displayName = 'AssessmentQuestionOrderViewWrapper'
