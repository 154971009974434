
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AssessmentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Assignment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"label"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"startsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"orderIndex"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pathLearningObjectives"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PathLearningObjectiveFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"courseId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sectionId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetTopicId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lmsCourseworkId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"quizConfiguration"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuizConfigurationFields"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"password"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":475}};
    doc.loc.source = {"body":"#import \"app/frontend/compositions/connected/fragments/quiz-configuration-fields.gql\"\n#import \"app/frontend/compositions/connected/fragments/path-learning-objective-fields.gql\"\n\nfragment AssessmentFields on Assignment {\n  id\n  type\n  label\n  name\n  startsAt\n  endsAt\n  orderIndex\n  pathLearningObjectives {\n    ...PathLearningObjectiveFields\n  }\n  courseId\n  sectionId\n  targetTopicId\n  lmsCourseworkId\n  quizConfiguration {\n    ...QuizConfigurationFields\n    password\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("app/frontend/compositions/connected/fragments/quiz-configuration-fields.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("app/frontend/compositions/connected/fragments/path-learning-objective-fields.gql").definitions));


      module.exports = doc;
    
