import * as React from 'react'
import { Link } from 'react-router-dom'
import { TextSeparator } from 'app/frontend/components/material/text-separator/text-separator'
import * as styles from './register.css'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('registration_page')

interface SwitchToSignInProps {
  redir?: string
  isDistributor?: boolean
}

const SwitchToSignIn: React.FunctionComponent<SwitchToSignInProps> = ({ redir, isDistributor }) => {
  const redirQuery = redir ? `?r=${encodeURIComponent(redir)}` : ''

  return (
    <div>
      <TextSeparator className={styles.registerSeparator} />
      <p className={styles.signInText}>
        {t('already_have')}{' '}
        <Link
          to={`/${isDistributor ? 'orders/login' : 'login'}${redirQuery}`}
          className={isDistributor ? styles.distributorSignInTextLink : styles.signInTextLink}
          data-bi="login-link"
        >
          {t('sign_in')}
        </Link>
      </p>
    </div>
  )
}

SwitchToSignIn.displayName = 'SwitchToSignIn'
export default SwitchToSignIn
