import * as React from 'react'

export type ContentError = {
  message: string
  isRetryable?: boolean
}

export type ContentWarning = {
  message: string
}

export type Action =
  | {
      type: 'ADD_WARNING'
      id: Content.ContentId
      value: ContentWarning
    }
  | {
      type: 'ADD_ERROR'
      id: Content.ContentId
      value: ContentError
    }
  | {
      type: 'INITIALIZE'
      id: Content.ContentId
      details: Content.ContentDetails
    }
  | {
      type: 'SUCCESS'
      id: Content.ContentId
    }

export type Status = {
  /* used internally to identify unique content renderings */
  _id: Content.ContentId

  /* is this content still loading? */
  loading: boolean

  /* any fatal errors encountered while trying to load or use the content */
  errors: ContentError[]

  /* any non-fatal errors encountered while trying to load or use the content */
  warnings: ContentWarning[]

  /* informational data about the content that didn't load */
  debug: Content.ContentDetails
}

export interface State {
  status: Status[]
  atom?: Content.IAtom
  renderLocation: Content.AtomRenderLocation
}

export type Dispatch = (action: Action) => void

const defaultState: State = {
  status: [],
  renderLocation: 'UNKNOWN',
}

const defaultDispatch: Dispatch = (action: Action) => {
  console.warn('Content-Manager is not Provided, ignoring dispatch', action)
}

export const StatusStateContext = React.createContext<State>(defaultState)
export const StatusDispatchContext = React.createContext<Dispatch>(defaultDispatch)
