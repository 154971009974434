import * as React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { GroupWork } from '@mui/icons-material'
import { RemoveAssessmentSequence } from 'app/frontend/pages/material/teach/compositions/data/remove-assessment-sequence/remove-assessment-sequence.tsx'
import { Box } from 'app/frontend/components/material/box'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import {
  CUSTOM_QUESTIONS_MODAL,
  CustomQuestionsView,
} from 'app/frontend/pages/material/teach/assessment-builder/custom-questions-modal'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import {
  CustomQuestionModalOptions,
  EditorMode,
  View,
} from 'app/frontend/pages/material/teach/assessment-builder/custom-questions-modal/custom-questions-modal-types'
import {
  isFreeResponse,
  isMultipleChoice,
} from 'app/frontend/components/compound/learnosity/learnosity-helpers'
import { useCustomItemVariationSequence } from 'app/frontend/pages/material/teach/compositions/connected/get-custom-item-variation-sequence/use-custom-item-variation-sequence'
import { AtomVariationButtonActionsType } from 'app/frontend/helpers/atom-variation'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { SaveStatusIndicator } from 'app/frontend/compositions/ux/save-status-indicator'
import { eachGqlError } from 'app/frontend/helpers/apollo'
import { CustomErrorInfo } from 'app/frontend/helpers/apollo/error'
import { isEnabled } from 'app/frontend/helpers/feature'
import { Tooltip } from 'app/frontend/components/tooltip'
import { useAssessmentBuilderPermissionState } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-builder-permission-state/use-assessment-builder-permission-state'
import { useSequenceOverrides } from 'app/frontend/compositions/connected/get-sequence-overrides'
import { ActivityNavigator } from 'app/frontend/components/activity-navigator/activity-navigator'
import { RemoveCustomAssessmentMenu } from 'app/frontend/pages/material/teach/compositions/data/remove-assessment-sequence/remove-custom-assessment-menu'
import { SequenceResultOverrideButtons } from './sequence-result-override-buttons/sequence-result-override-buttons'
import * as styles from './assessment-question-actions.css'

const t = tns('teach:assessment_questions')

export interface OwnProps extends AtomVariationButtonActionsType {}

export interface DispatchProps {
  showCustomQuestionsModal: (modalOptions: Partial<CustomQuestionModalOptions>) => void
}

type Props = OwnProps & DispatchProps

export const _CustomAssessmentQuestionActions: React.FunctionComponent<Props> = ({
  atom,
  sequenceId,
  assessmentId,
  pathSequenceVariationId,
  parentEntityType,
  showCustomQuestionsModal,
  onQuestionNavigation,
  questionVariationNumber,
  onVariationContentChange,
  customSequenceVariationSize,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [hasError, setHasError] = useState(false)

  const {
    hasStudentStartedAssignment,
    hasEditPermission,
    isCourseAssignmentOnSection,
    loading: permissionLoading,
    error: permissionError,
  } = useAssessmentBuilderPermissionState()

  const {
    customItemVariationSequence,
    loading: customVariationSequenceLoading,
    error: customVariationSequenceError,
  } = useCustomItemVariationSequence(sequenceId, {
    context: { silenceErrors: true },
  })

  let isUserNotCustomQuestionOwner = false
  let isCustomSequenceUnexpectedError = false
  if (customVariationSequenceError) {
    eachGqlError(customVariationSequenceError, (e: CustomErrorInfo) => {
      if (e.data && e.data.errorType === 'PERMISSION_DENIED') {
        isUserNotCustomQuestionOwner = true
      } else {
        isCustomSequenceUnexpectedError = true
      }
    })
  }

  const {
    sequenceOverridesMap,
    error: sequenceOverrideError,
    loading: sequenceOverrideLoading,
  } = useSequenceOverrides(assessmentId, !hasStudentStartedAssignment)

  if (
    !hasEditPermission ||
    isCourseAssignmentOnSection ||
    permissionError ||
    permissionLoading ||
    isCustomSequenceUnexpectedError ||
    customVariationSequenceLoading ||
    sequenceOverrideError ||
    sequenceOverrideLoading
  ) {
    return null
  }

  const onEdit = () => {
    const variationId = atom?.id?.variationId
    const targetIndex =
      customItemVariationSequence &&
      customItemVariationSequence.learnosityPayloads.findIndex(
        obj => obj.variationId === variationId
      )
    const questionJson = customItemVariationSequence.learnosityPayloads[targetIndex]?.content
    showCustomQuestionsModal({
      questionJson,
      view: isMultipleChoice(null, questionJson)
        ? View.MultipleChoice
        : isFreeResponse(null, questionJson)
        ? View.FreeResponse
        : null,
      customItemVariationSequence: customItemVariationSequence,
      currentQuestionIndex: targetIndex,
      atom,
      poolSize: customSequenceVariationSize,
      onVariationContentChange,
      editorMode:
        customSequenceVariationSize > 1
          ? EditorMode.EDIT_POOL_QUESTION
          : EditorMode.FLAG_ENABLED_EDIT_CUSTOM_QUESTION,
    })
  }

  const onPoolQuestionAdd = () => {
    showCustomQuestionsModal({
      view: isEnabled('customFreeResponseQuestions')
        ? CustomQuestionsView.Select
        : CustomQuestionsView.MultipleChoice,
      assessmentId: assessmentId,
      atom,
      poolSize: customSequenceVariationSize,
      editorMode: EditorMode.ADD_POOL_QUESTION,
    })
  }

  return (
    <Box
      direction="row"
      full="page"
      alignItems="center"
      pad="medium"
      separator="top"
      justify="between"
    >
      <SaveStatusIndicator isSaving={isSaving} hasError={hasError} />
      {customSequenceVariationSize > 1 && (
        <Box margin={{ bottom: 'small' }} className={styles.navigation}>
          <ActivityNavigator
            title={t('question_number_of_the_pool')}
            count={customSequenceVariationSize}
            index={questionVariationNumber}
            onChange={onQuestionNavigation}
          />
        </Box>
      )}
      <Box direction="row" responsive={false}>
        {hasStudentStartedAssignment ? (
          <SequenceResultOverrideButtons
            sequenceId={sequenceId}
            override={sequenceOverridesMap && sequenceOverridesMap[sequenceId]}
            assessmentId={assessmentId}
          />
        ) : (
          <>
            <ButtonMaterial
              icon={<GroupWork style={{ marginTop: '0.75rem' }} />}
              theme="bordered"
              label={t('add_pool_question')}
              disabled={isUserNotCustomQuestionOwner}
              className={styles.addPoolQuestionBtn}
              data-bi="add-pool-question-button"
              onClick={() => onPoolQuestionAdd()}
            />
            <Tooltip
              disabled={!isUserNotCustomQuestionOwner}
              title={t('custom_question_only_editable_by_owner')}
            >
              <ButtonMaterial
                icon="edit"
                theme="bordered"
                label={t('edit')}
                disabled={isUserNotCustomQuestionOwner}
                className={styles.actionButton}
                data-bi="edit-custom-question-button"
                onClick={() => onEdit()}
              />
            </Tooltip>
            {customSequenceVariationSize > 1 ? (
              <RemoveCustomAssessmentMenu
                assessmentId={assessmentId}
                sequenceId={sequenceId}
                pathSequenceVariationId={pathSequenceVariationId}
                parentEntityType={parentEntityType}
                onRemoveEntireQuestion={() => setIsSaving(true)}
                onRemoveEntireQuestionComplete={() => setIsSaving(false)}
                onRemoveEntireQuestionError={() => setHasError(true)}
                atom={atom}
                onRemoveVariation={() => setIsSaving(true)}
                onRemoveVariationComplete={() => setIsSaving(false)}
                onRemoveVariationError={() => setHasError(true)}
                onQuestionNavigation={onQuestionNavigation}
              />
            ) : (
              <RemoveAssessmentSequence
                assessmentId={assessmentId}
                sequenceId={sequenceId}
                pathSequenceVariationId={pathSequenceVariationId}
                parentEntityType={parentEntityType}
                onRemove={() => setIsSaving(true)}
                onRemoveComplete={() => setIsSaving(false)}
                onRemoveError={() => setHasError(true)}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
_CustomAssessmentQuestionActions.displayName = '_CustomAssessmentQuestionActions'

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps => {
  const { assessmentId, pathSequenceVariationId } = ownProps
  const modalOptions: Partial<CustomQuestionModalOptions> = {
    assessmentId,
    pathSequenceVariationId,
  }
  return {
    showCustomQuestionsModal: (options: Partial<CustomQuestionModalOptions>) =>
      dispatch(showModal(CUSTOM_QUESTIONS_MODAL, { ...modalOptions, ...options })),
  }
}

export const CustomAssessmentQuestionActions = connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(_CustomAssessmentQuestionActions)

CustomAssessmentQuestionActions.displayName = 'CustomAssessmentQuestionActions'
