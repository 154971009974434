import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as HAS_STUDENT_STARTED_ASSIGNMENT_QUERY from 'app/frontend/pages/material/teach/compositions/connected/has-student-started-assignment/has-student-started-assignment.gql'
import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { useDispatch } from 'react-redux'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import {
  CUSTOM_QUESTIONS_MODAL,
  CustomQuestionsView,
} from 'app/frontend/pages/material/teach/assessment-builder/custom-questions-modal'
import { isEnabled } from 'app/frontend/helpers/feature'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_ADD_CUSTOM,
  TEACH_SECTION_ASSESSMENT_ADD_CUSTOM,
} from 'app/frontend/data/mixpanel-events'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './add-custom-question-button.css'

const t = tns('teach:assessment_questions')

interface AddCustomQuestionButtonProps {
  assessment: GQL.GetAssignment.Assignment
}

export const AddCustomQuestionButton: React.FunctionComponent<AddCustomQuestionButtonProps> = ({
  assessment,
}) => {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [isDisabled, setDisabled] = useState(false)
  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  /**
   * On Add Custom Question handler
   *
   * this will validate the has student started assessment
   * state prior to show `CustomQuestionsModal`.
   */
  const onClickHandler = async (): Promise<void> => {
    const { id, sectionId } = assessment
    // determine the `parentEntity` type
    const parentEntityType = sectionId ? ParentEntityType.Section : ParentEntityType.Course
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_ADD_CUSTOM
        : TEACH_SECTION_ASSESSMENT_ADD_CUSTOM,
      id
    )
    // disable the `add custom question` button
    setDisabled(true)

    try {
      // fetch the status of the student assessment started
      const { data } = (await client.query({
        query: HAS_STUDENT_STARTED_ASSIGNMENT_QUERY,
        variables: {
          assignmentId: id,
        },
        fetchPolicy: 'network-only',
        context: { silenceErrors: true },
      })) as ApolloQueryResult<GQL.HasStudentStartedAssignment.Query>

      // check students have started the assessment or not
      if (!data.hasStudentStartedAssignment) {
        dispatch(
          showModal(CUSTOM_QUESTIONS_MODAL, {
            view: isEnabled('customFreeResponseQuestions')
              ? CustomQuestionsView.Select
              : CustomQuestionsView.MultipleChoice,
            assessmentId: id,
          })
        )
      }
    } finally {
      if (isMountedRef.current) {
        // enable the `add custom question` button
        setDisabled(false)
      }
    }
  }

  return (
    <ButtonMaterial
      data-bi="add-custom-question-button"
      theme="bordered"
      label={t('add_custom_question')}
      icon={<Icon name="icon-add-mat" size="medium" className={styles.addIcon} />}
      ariaLabel={t('add_custom_question')}
      disabled={isDisabled}
      onClick={onClickHandler}
    />
  )
}

AddCustomQuestionButton.displayName = 'AddCustomQuestionButton'
