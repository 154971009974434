import * as React from 'react'
import * as _ from 'lodash'
import { Autocomplete, TextField, Checkbox } from '@mui/material'
import { PropsWithChildren } from 'react'
import { makeStyles } from '@mui/styles'
import { FormControlLabelCheckbox } from 'app/frontend/components/material/form-control-label'
import { Box } from 'app/frontend/components/material/box'
import { Heading } from 'app/frontend/components/material/heading'
import { t } from 'app/frontend/helpers/translations/i18n'
import * as selectStyles from 'app/frontend/compositions/ux/react-select/select.css'
import * as styles from './async-selector.css'

/**
 * A callback of this type must be called with the results that are loaded asynchronously.
 */
export type Callback<T> = (option: T[]) => void

export interface OwnProps<T> {
  /**
   * The selected option.
   * Needs to be an array of multi is true (empty array for an empty state).
   */
  value: T | T[] | null
  /**
   * Callback fired when the search string entered by the user is updated.
   * @param inputValue the string input of the user.
   * @param callback a function you can use to set the new list of options.
   */
  onQueryChange: (inputValue: string, callback: Callback<T>) => void
  /**
   * Callback fired when the selection is changed.
   * Based on https://react-select.com/props#statemanager-propsselection
   * can be null, undefined, array,or a single object.
   *
   * @param value the selected value/s.
   */
  onSelectionChange: (value?: T | T[]) => void
  /**
   * Get the displayed label for the given option. Used by default for displaying
   * the option list entry and the multi-select pills.
   */
  getOptionLabel: (option: T) => string
  /**
   * Get the unique key for the option. Must be unique for all options.
   */
  getOptionKey: (option: T) => string
  /**
   * A string to display in place of the selected option when no option is selected
   */
  placeholder: string
  /**
   * Prop that helps with writing selectors so tests can interact with this component.
   */
  dataBi: string
  /**
   * Allows you to override the rendered element for the options in the list.
   * The element you return must have a "key" prop on the root component as
   * required by react. If not given, this will use AsyncSelectorBetaOption
   * component with key=getOptionKey and content=getOptionLabel.
   *
   * @param props props provided by mui that should be applied to your custom component.
   * @param option the data for an option to be rendered.
   */
  optionRenderer?: (props: React.HTMLAttributes<HTMLLIElement>, option: T) => JSX.Element
  /**
   * Allows you to override the rendering of selected values if multi is true (tags/pills).
   */
  multiSelectRenderer?: (values: T[]) => JSX.Element
  /**
   * Whether or not selection is disabled
   */
  disabled?: boolean
  /**
   * Whether a value should be selected.
   * Shows an error message when there's value/s and then all removed.
   */
  required?: boolean
  /**
   * Callback fired when the user toggles between showing an hiding test options. Only
   * applicable when testToggle is set to true.
   */
  onIncludeTestChange?: (v: boolean) => void
  /**
   * A header to show above the component
   */
  title?: string
  /**
   * A string to display if no results are returned and when the input is empty.
   */
  noResultsText?: string
  /**
   * True to show all options. False to filter out test options.
   */
  includeTest?: boolean
  /**
   * A note about the current search or selection to be displayed under the selector
   */
  note?: React.ReactNode
  /**
   * An error about the current search or selection to be displayed under the selector
   */
  error?: React.ReactNode
  /**
   * True to enable multi-selection. False to allow only one option to be selected.
   */
  multi?: boolean // defaults to false
  /**
   * True to include a toggle that allows the user to choose whether or not test options
   * should be shown.
   */
  testToggle?: boolean
  /**
   * Label to use for the checkbox that enables/disables showing test options.
   */
  testToggleLabel?: string
  /**
   * Additional classname to add to the async selector
   */
  className?: string
  /**
   * True to include a cancel icon to clear out the selected option
   */
  isClearable?: boolean
  /**
   * The text to show in the dropdown when the input is empty.
   * True to include a cancel icon to clear out the selected option
   */
  searchPromptText?: string
  /**
   * Label that will appear INSIDE the text input.
   */
  textInputLabel?: string
  /**
   * Helper text to appear below the text field.
   */
  helperText?: string
}

export type Props<T> = OwnProps<T>

export type PropsOption = {
  optionProps: object
}

export const AsyncSelectorOption: React.FunctionComponent<PropsOption> = ({
  optionProps,
  children,
}) => <div {...optionProps}>{children}</div>

const useAsyncSelectorStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette?.common?.white,
  },
  input: {
    outline: 'none !important',
  },
  inputRoot: {
    '&:focus-within': {
      borderRadius: '0.125rem',
      outline: `solid 0.125rem ${theme.palette?.action?.focus} !important`,
    },
  },
}))

export const AsyncSelector = <T,>({
  value,
  onQueryChange,
  onSelectionChange,
  getOptionLabel,
  getOptionKey,
  placeholder,
  dataBi,
  optionRenderer,
  multiSelectRenderer,
  disabled,
  required,
  onIncludeTestChange,
  title,
  noResultsText,
  includeTest,
  note,
  error,
  multi = false,
  testToggle,
  testToggleLabel,
  className,
  isClearable,
  searchPromptText,
  textInputLabel,
  helperText,
}: PropsWithChildren<Props<T>>): React.ReactElement<any, any> => {
  const classes = useAsyncSelectorStyles()
  const [options, setOptions] = React.useState([])
  // See https://github.com/mui-org/material-ui/issues/{19423, 20939, 19318}.
  // Basically, value and inputValue needs to be controlled.
  const [inputValue, setInputValue] = React.useState('')
  const [isValid, setIsValid] = React.useState(true)

  const handleInputChange = React.useCallback(
    (_event: React.SyntheticEvent, changeValue: string) => {
      setInputValue(changeValue)
      // Passing callback makes it easier to mock.
      _.debounce(cb => cb(), 500)(() => onQueryChange(changeValue, setOptions))
    },
    [onQueryChange]
  )

  const handleOptionRenderer = React.useCallback(
    (optionProps: object, option: T, _state: object) =>
      optionRenderer ? (
        optionRenderer(optionProps, option)
      ) : (
        <AsyncSelectorOption optionProps={optionProps} key={getOptionKey(option)}>
          {getOptionLabel(option)}
        </AsyncSelectorOption>
      ),
    [optionRenderer, getOptionLabel]
  )

  const isValidValue = (valueToCheck: T | T[]) => {
    if (Array.isArray(valueToCheck)) {
      // @ts-ignore
      if (valueToCheck.length !== 0) {
        return true
      }
    } else {
      if (!_.isNil(valueToCheck)) {
        return true
      }
    }
    return false
  }

  const handleOnChange = React.useCallback(
    (_event: React.SyntheticEvent, changeValue: T | T[]) => {
      onSelectionChange(changeValue)
      if (!required || isValidValue(changeValue)) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    },
    [onSelectionChange, required]
  )

  return (
    <Box full="horizontal" pad={{ bottom: 'medium' }} data-bi={dataBi} className={className}>
      <Box direction="row" pad={{ between: 'large' }}>
        {title && (
          <Heading tag="h4" margin={{ vertical: 'medium' }}>
            {title}
          </Heading>
        )}
        {testToggle &&
          (testToggleLabel ? (
            <FormControlLabelCheckbox
              control={
                <Checkbox
                  checked={includeTest}
                  onChange={e => onIncludeTestChange(e.target.checked)}
                />
              }
              label={testToggleLabel}
            />
          ) : (
            <Checkbox checked={includeTest} onChange={e => onIncludeTestChange(e.target.checked)} />
          ))}
      </Box>
      <Autocomplete
        id={dataBi}
        multiple={multi}
        value={value}
        inputValue={inputValue}
        onChange={handleOnChange}
        disableClearable={!isClearable}
        // Required filterOptions like this on dynamic options.
        // Also, to avoid warning "no option match value", we put the
        // selected value in the options but filter those out.
        filterOptions={_x => options}
        options={multi ? [...value, ...options] : [value, ...options]}
        getOptionLabel={getOptionLabel}
        renderOption={handleOptionRenderer}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={_.isEmpty(value) ? placeholder : ''}
            variant="standard"
            error={!isValid}
            label={textInputLabel}
            // @ts-ignore
            FormHelperTextProps={{ component: 'span' }}
            helperText={(helperText ?? '') + (!isValid ? t('required') : '')}
          />
        )}
        renderTags={multiSelectRenderer}
        onInputChange={handleInputChange}
        disabled={disabled}
        className={selectStyles.reactSelectContainer}
        noOptionsText={
          _.isEmpty(value) ? searchPromptText || t('default_search_promp') : noResultsText
        }
        classes={classes}
        isOptionEqualToValue={(o, v) => getOptionKey(o) === getOptionKey(v)}
      />
      <div className={styles.asyncSelectorNote}>{note}</div>
      <div className={styles.asyncSelectorError}>{error}</div>
    </Box>
  )
}
