'use strict'

import * as moment from 'moment'
import { isEmpty, values } from 'lodash'
import { GRACE_PERIOD_MINUTES } from 'app/frontend/data/course-data'

export function getLearningObjectivesMapFromTaxonomy(flatTaxonomy: {
  [taxonId: string]: Taxonomy.ITaxon
}): { [loId: string]: Content.ILearningObjective } {
  const learningObjectivesById = {}
  values(flatTaxonomy).forEach(t =>
    t.learningObjectives.forEach(lo => (learningObjectivesById[lo.id] = lo))
  )

  return learningObjectivesById
}

/**
 * Return true if the student is late
 */
export function isLate(
  endsAt: number,
  markedDoneAt: number,
  completedAt: number,
  userDueDate: number = null
): boolean {
  const actualDueDate = moment(userDueDate) > moment(endsAt) ? userDueDate : endsAt
  const endsAtWithGracePeriod = moment(actualDueDate).add(GRACE_PERIOD_MINUTES, 'minutes')

  return (
    !markedDoneAt &&
    ((endsAtWithGracePeriod < moment() && !completedAt) ||
      moment(completedAt) > endsAtWithGracePeriod)
  )
}

export function isLateWithAttempts(
  endsAt: number,
  markedDoneAt: number,
  attempts: GQL.EnrollmentAttempt[],
  userDuedate: number = null
): boolean {
  return (
    (isEmpty(attempts) && isLate(endsAt, markedDoneAt, null, userDuedate)) ||
    (!isEmpty(attempts) &&
      attempts.every(attempt => isLate(endsAt, markedDoneAt, attempt.completedAt, userDuedate)))
  )
}
