import { combineReducers } from 'redux'
import studentsReducer, { StudentsReducerState } from './students-reducer'
import studentReducer, { StudentReducerState } from './student-reducer'
import allCourseworkReducer, { AllCourseworkReducerState } from './all-coursework-reducer'

export type AnalyticsReducerState = {
  students: StudentsReducerState
  student: StudentReducerState
  allCoursework: AllCourseworkReducerState
}

export default combineReducers<AnalyticsReducerState>({
  students: studentsReducer,
  student: studentReducer,
  allCoursework: allCourseworkReducer,
})
