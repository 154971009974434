import { Pattern, call, fork, take } from 'redux-saga/effects'

/**
 * Executes the first action that was dispatch.
 * Ignores other actions dispatched while the action is being executed.
 *
 * Copied from:
 * https://github.com/redux-saga/redux-saga/issues/589
 */
export function* takeFirst(pattern: Pattern, saga, ...args): any {
  const task = yield fork(function* () {
    while (true) {
      const action = yield take(pattern)
      yield (call as any)(saga, ...args.concat(action))
    }
  })
  return task
}
