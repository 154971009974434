import { Reducer } from 'redux'
import * as _ from 'lodash'
import { API_GET_LTI_CREDENTIALS, API_GENERATE_LTI_CREDENTIALS } from './credentials-actions'

export type InstitutionLtiCredentials = {
  credentials?: Commons.LtiCredential[]
  isFetching?: boolean
  isGenerating?: boolean
  errorFetching?: boolean
}

export type CredentialByInstitutionIdState = {
  [id: string]: InstitutionLtiCredentials
}

export const reducer = (state: CredentialByInstitutionIdState = {}, action) => {
  let institutionId
  switch (action.type) {
    case API_GET_LTI_CREDENTIALS.INIT:
      institutionId = action.args && action.args.length ? action.args[0] : null
      if (institutionId) {
        return {
          ...state,
          [institutionId]: {
            ...state[institutionId],
            isFetching: true,
            errorFetching: false,
          },
        }
      }
    case API_GET_LTI_CREDENTIALS.ERROR:
    case API_GET_LTI_CREDENTIALS.CANCEL:
      institutionId = action.args && action.args.length ? action.args[0] : null
      if (institutionId) {
        return {
          ...state,
          [institutionId]: {
            ...state[institutionId],
            isFetching: false,
            errorFetching: true,
          },
        }
      }
    case API_GET_LTI_CREDENTIALS.SUCCESS:
      institutionId = action.args && action.args.length ? action.args[0] : null
      if (institutionId) {
        return {
          ...state,
          [institutionId]: {
            credentials: action.response,
            isFetching: false,
            errorFetching: false,
          },
        }
      }
    case API_GENERATE_LTI_CREDENTIALS.INIT:
      institutionId = action.args && action.args.length ? action.args[0] : null
      if (institutionId) {
        return {
          ...state,
          [institutionId]: {
            ...state[institutionId],
            isGenerating: true,
          },
        }
      }
    case API_GENERATE_LTI_CREDENTIALS.ERROR:
    case API_GENERATE_LTI_CREDENTIALS.CANCEL:
      institutionId = action.args && action.args.length ? action.args[0] : null
      if (institutionId) {
        return {
          ...state,
          [institutionId]: {
            ...state[institutionId],
            isGenerating: false,
          },
        }
      }
    case API_GENERATE_LTI_CREDENTIALS.SUCCESS:
      institutionId = action.args && action.args.length ? action.args[0] : null
      if (institutionId) {
        return {
          ...state,
          [institutionId]: {
            ...state[institutionId],
            credentials: [action.response],
            isGenerating: false,
          },
        }
      }
    default:
      return state
  }
}

export const getInstitutionCredentials = (state, institutionId): InstitutionLtiCredentials =>
  _.get(state, `entities.institutions.credentials.byInstitutionId[${institutionId}]`, {})

export default reducer as Reducer<CredentialByInstitutionIdState>
