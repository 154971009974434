import { makeApiActions } from 'app/frontend/api/api-redux'

export type GET_COURSE = 'GET_COURSE'
export const GET_COURSE: GET_COURSE = 'GET_COURSE'
export interface GetCourseAction {
  type: GET_COURSE
  courseId: string
}
export function getCourse(courseId: string): GetCourseAction {
  return {
    type: GET_COURSE,
    courseId,
  }
}

export const API_GET_COURSE = makeApiActions('API_GET_COURSE')

export type GET_LTI_ENROLLMENT = 'GET_LTI_ENROLLMENT'
export const GET_LTI_ENROLLMENT: GET_LTI_ENROLLMENT = 'GET_LTI_ENROLLMENT'
export interface GetLtiEnrollmentAction {
  type: GET_LTI_ENROLLMENT
  enrollmentId: string
}
export function getLtiEnrollment(enrollmentId: string): GetLtiEnrollmentAction {
  return {
    type: GET_LTI_ENROLLMENT,
    enrollmentId,
  }
}

export const API_GET_LTI_ENROLLMENT = makeApiActions('API_GET_LTI_ENROLLMENT')
export const API_REFRESH_ENROLLMENT_DUE_DATE = makeApiActions('API_REFRESH_ENROLLMENT_DUE_DATE')

export type GET_ENROLLMENT_DUE_DATES_FOR_STUDENT = 'GET_ENROLLMENT_DUE_DATES_FOR_STUDENT'
export const GET_ENROLLMENT_DUE_DATES_FOR_STUDENT: GET_ENROLLMENT_DUE_DATES_FOR_STUDENT =
  'GET_ENROLLMENT_DUE_DATES_FOR_STUDENT'
export interface GetEnrollmentDueDatesForStudentAction {
  type: GET_ENROLLMENT_DUE_DATES_FOR_STUDENT
  studentId: string
  courseId: string
}
export function getEnrollmentDueDatesForStudent(
  studentId: string,
  courseId: string
): GetEnrollmentDueDatesForStudentAction {
  return {
    type: GET_ENROLLMENT_DUE_DATES_FOR_STUDENT,
    studentId,
    courseId,
  }
}

export const API_GET_ENROLLMENT_DUE_DATES_FOR_STUDENT = makeApiActions(
  'API_GET_ENROLLMENT_DUE_DATES_FOR_STUDENT'
)

export type GET_ENROLLMENT_GRADE = 'GET_ENROLLMENT_GRADE'
export const GET_ENROLLMENT_GRADE: GET_ENROLLMENT_GRADE = 'GET_ENROLLMENT_GRADE'
export interface GetEnrollmentGradeAction {
  type: GET_ENROLLMENT_GRADE
  enrollmentId: string
}
export function getEnrollmentGrade(enrollmentId: string): GetEnrollmentGradeAction {
  return {
    type: GET_ENROLLMENT_GRADE,
    enrollmentId,
  }
}

export const API_GET_ENROLLMENT_GRADE = makeApiActions('API_GET_ENROLLMENT_GRADE')

export const API_GET_REGISTERED_ENROLLMENT = makeApiActions('API_GET_REGISTERED_ENROLLMENT')
