import * as React from 'react'
import { flatten } from 'lodash'
import * as classNames from 'classnames'
import { useContentManagerState, ContentError } from 'app/frontend/components/content/manager'

import * as styles from './atom-wrapper-overlay.css'

interface Props {
  renderErrors: (errors: ContentError[]) => React.ReactNode
  renderLoading: () => React.ReactNode
  children: React.ReactNode
}

/**
 * Consumes from our Content Manager to overlay a loading or error component *over* any
 * children. This allows us to mount, but not display, an Atom until all of its component pieces
 * have finished loading.
 */
const AtomWrapperOverlay: React.FunctionComponent<Props> = ({
  children,
  renderLoading,
  renderErrors,
}) => {
  const state = useContentManagerState()
  const errors = flatten(state.status.map(s => s.errors))
  const isLoading = state.status.length === 0 || state.status.some(s => s.loading)
  const hasError = errors.length > 0

  return (
    <div className={styles.container}>
      <div className={styles.stacked}>
        {hasError && renderErrors(errors)}
        {!hasError && isLoading && renderLoading()}
      </div>
      {/* We could conditionally render children, thus unmounting upon errors, but we'd have to
      double check all of our components to make sure they can handle unmounting while loading.
      At first glance, it seems like Learnosity cannot. */}
      <div className={classNames(styles.stacked, { [styles.hidden]: isLoading || hasError })}>
        {children}
      </div>
    </div>
  )
}

export default AtomWrapperOverlay
