import { post } from './index'

export const login = (data: any): Promise<any> => {
  return post('/login', data, {
    showSessionExpiredModal: false,
  })
}

export const register = (data: any): Promise<any> => {
  return post('/account/register', data, {
    showSessionExpiredModal: false,
  })
}

export const registerOauth = (data: any): Promise<Response> => {
  return post('/oauth/register', data, {
    showSessionExpiredModal: false,
  })
}
