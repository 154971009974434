import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import * as LtiConstants from 'app/frontend/data/lti-constants'
import { Box } from 'app/frontend/components/material/box'
import { CopyTextInput } from 'app/frontend/compositions/data/copy-text-input'

const t = tns('teach:assessment_builder')

interface Props {
  courseworkId: string
}

export const LtiLinkSetting: React.FunctionComponent<Props> = ({ courseworkId }) => {
  const ltiLaunchUrl = `${window.location.origin}${LtiConstants.COURSEWORK_LAUNCH}${courseworkId}`
  return (
    <Box full="page" pad="medium" data-test="lti-link">
      <Paragraph margin="none" weight="semibold">
        {t('settings_lti_link_header')}
      </Paragraph>
      <Paragraph size="small" margin="small">
        {t('settings_lti_link_description')}
      </Paragraph>
      <Box full="page" direction="row">
        <CopyTextInput
          hideIcon
          value={ltiLaunchUrl}
          copyLabel={t('settings_copy_link')}
          dataBi="copy-lti-link"
        />
      </Box>
    </Box>
  )
}

LtiLinkSetting.displayName = 'LtiLinkSetting'
