// NOTE: There's also a teach-specific table-of-contents helper
// These ones are for use across the application
import { flatten, memoize, sortBy, groupBy, Dictionary } from 'lodash'

/**
 * Given a coursepack's table of contents, return a list of Learning Objectives
 * in order of appearance.
 */
export const getOrderedLoIdsFromToc = (titles: GQL.TitleTaxonFields.Fragment[]): string[] => {
  const flattenedChapters = flatten(titles.map(title => title.chapters))

  const loIds = flattenedChapters.reduce((acc, chapter) => {
    const sectionLos = chapter.sections.reduce((sectionAcc, section) => {
      section.topics.forEach(topic => {
        topic.learningObjectives.forEach(lo => sectionAcc.push(lo.id))
      })
      return sectionAcc
    }, [])

    const chapterLos = chapter.topics.reduce((chapterAcc, topic) => {
      topic.learningObjectives.forEach(lo => chapterAcc.push(lo.id))
      return chapterAcc
    }, [])

    return acc.concat(chapterLos, sectionLos)
  }, [])

  return loIds
}

/**
 * Memo key for helper methods that take in
 * a coursepack's table of contents and path learning
 * objectives
 */
export const memoKeyFunctionForSortPathLearningObjectives = (
  titles: GQL.TitleTaxonFields.Fragment[],
  pathLearningObjectives: GQL.PathLearningObjectiveFields.Fragment[]
): string =>
  titles.map(title => title.id).join('') +
  pathLearningObjectives.map(plo => plo.learningObjectiveId).join('')

/**
 * Given a coursepack's table of contents, order a list of
 * path learning objectives by order of appearance in the
 * coursepack's table of contents.
 */
export const sortPathLearningObjectivesByToc = memoize(
  (
    titles: GQL.TitleTaxonFields.Fragment[],
    pathLearningObjectives: GQL.AssessmentFields.PathLearningObjectives[]
  ): GQL.PathLearningObjectiveFields.Fragment[] => {
    const sortedLos = getOrderedLoIdsFromToc(titles)
    return sortBy(pathLearningObjectives, plo => sortedLos.indexOf(plo.learningObjectiveId))
  },
  memoKeyFunctionForSortPathLearningObjectives
)

export type SortedPathLearningObjectiveByTopic = {
  topicId: string
  pathLearningObjectives?: Commons.PathLearningObjective[]
}

/**
 * Given a coursepack's table of contents, return a list
 * of learning objective IDs grouped by topic.
 */
export const sortPathLearningObjectiveTopicsByToc = (
  titles: GQL.TitleTaxonFields.Fragment[],
  pathLearningObjectives: GQL.AssessmentFields.PathLearningObjectives[]
): SortedPathLearningObjectiveByTopic[] => {
  // Sort the path learning objectives
  const sortedPlos = sortPathLearningObjectivesByToc(titles, pathLearningObjectives)
  // Prepare to dedup the topics. Retains insertion order.
  const sortedTopicIds = new Set<string>()
  // Create a hash map of topic to path learning objectives.
  // This keeps order for learning objectives within a topic,
  // but not for topics themselves, so `sortedTopicIds` is still needed.
  const sortedPlosByTopic: Dictionary<GQL.AssessmentFields.PathLearningObjectives[]> = groupBy(
    sortedPlos,
    plo => plo.topicId
  )
  sortedPlos.forEach(({ topicId }) => {
    sortedTopicIds.add(topicId)
  })

  return Array.from(sortedTopicIds).map(topicId => ({
    topicId,
    pathLearningObjectives: sortedPlosByTopic[topicId],
  }))
}
