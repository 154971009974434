'use strict'

import * as moment from 'moment-timezone'
import { TranslationFunction } from 'react-i18next'

import * as NumberToWords from 'number-to-words'
export type Urgency = 'before' | 'later' | 'soon' | 'urgent'

/**
 * Computes the urgency.
 */
export function urgency(startTime: number, endTime: number): { value: number; code: Urgency } {
  const value = (moment.utc().unix() * 1000 - startTime) / (endTime - startTime)
  let code
  if (value < 0) {
    code = 'before'
  } else if (value < 0.5) {
    code = 'later'
  } else if (value < 0.8) {
    code = 'soon'
  } else {
    code = 'urgent'
  }

  return {
    value,
    code,
  }
}

/**
 * Displays a "human-friendly" timestamp as a calendar date and time or as another string
 * such as 'tomorrow at 5:00' depending on how far away from the now the given time is.
 * See moment.calendar for more details.
 * @param {number} time
 * @param {TranslationFunction} t called to obtain locale-dependent time format strings
 * @returns {string} a human-friendly time
 */
export function humanCalendar(time: number, t: TranslationFunction): string {
  return moment(time).calendar(moment(), { sameElse: t('human_calendar_same_else') })
}

/**
 * Get a human friendly description of the given timestamp relative to the current time.
 * e.g. "A few seconds ago", "3 minutes ago", "A day ago", "in a few days"
 * @param timestampSecs
 */
export function humanTimeFromNow(timestampSecs: number): string {
  return moment.unix(timestampSecs).fromNow()
}

/**
 * Format a timestamp into "xx ago" or a date with time, based on the requirements for answer
 * history page.
 */
export function timeToDateForHistory(timestampSecs: number): string {
  const historyMoment = moment.unix(timestampSecs)
  const now = moment()
  return timeToDateForHistoryWithReference(historyMoment, now)
}

/**
 * Format a timestamp into "xx ago" or a date with time, based on the requirements for answer
 * history page. Allows a reference time to be passed in for testability.
 */
export function timeToDateForHistoryWithReference(
  historyMoment: moment.Moment,
  referenceMoment: moment.Moment
): string {
  const doDaysMatch = historyMoment.dayOfYear() === referenceMoment.dayOfYear()
  const doYearsMatch = historyMoment.year() === referenceMoment.year()

  // technically this will be incorrect for Dec 31 / Jan 1... but it's ok.
  const isOneDayApart = referenceMoment.dayOfYear() - historyMoment.dayOfYear() === 1

  if (doDaysMatch && doYearsMatch) {
    return historyMoment.from(referenceMoment)
  } else if (!doYearsMatch) {
    return historyMoment.format('MMM D[,] YYYY [at] h:mma')
  } else if (!isOneDayApart) {
    // At this point, we know the years match.
    return historyMoment.format('MMM D [at] h:mma')
  } else {
    return historyMoment.format('[Yesterday at ]h:mma')
  }
}

/**
 * Format a timestamp into Day, Date, Time, Timezone
 */
export function formatDateTimeTimeZone(timestampSeconds: number): string {
  const theMoment = moment.unix(timestampSeconds)
  return theMoment.format('dddd[,] MMM D[,] YYYY [at] h:mma ') + currentTimeZone()
}

/**
 * Format a timestamp into "xx ago" or a date.
 */
export function timeToAgoDate(time: number, recentText?: string): string {
  const historyMoment = moment(time)
  const now = moment()
  const weeks = now.diff(historyMoment, 'weeks')
  if (weeks >= 1) {
    return historyMoment.format('MMMM Do')
  } else {
    const days = now.diff(historyMoment, 'days')
    if (days >= 1) {
      const daysWord = days > 1 ? ' days ago' : ' day ago'
      return days + daysWord
    } else {
      let minutes = now.diff(historyMoment, 'minutes')
      if (minutes > 0) {
        const hours = now.diff(historyMoment, 'hours')
        minutes -= hours * 60

        return hours > 0 ? hours + 'h ' + minutes + 'm ago' : minutes + 'm ago'
      } else {
        return recentText || 'a few seconds ago'
      }
    }
  }
}

/**
 * Formats the seconds given into a string like "MMM:SS".
 */
export function formatTime(seconds: number): string {
  if (seconds === 0) {
    return '0:00'
  }

  const secs: string = (seconds % 60) + ''
  const mins: string = Math.floor(seconds / 60) + ''
  return `${mins.padStart(2, '0')}:${secs.padStart(2, '0')}`
}

export function humanizeTime(seconds: number): string {
  if (seconds === 0) {
    return 'zero seconds'
  }

  // Test if seconds should be plural
  let pluralSec = ''
  let and = ' and '
  if (seconds % 60 === 0) {
    and = ''
  } else if (seconds % 60 !== 1) {
    pluralSec = 's'
  }

  let secs = ''
  if (seconds % 60 !== 0) {
    secs = NumberToWords.toWords(seconds % 60) + ` second${pluralSec}`
  }

  // Test if minutes should be plural
  let mins = ''
  let pluralMin = ''
  if (Math.floor(seconds / 60) !== 1) {
    pluralMin = 's'
  }

  if (Math.floor(seconds / 60) !== 0) {
    mins = NumberToWords.toWords(Math.floor(seconds / 60)) + ` minute${pluralMin}${and}`
  }

  return `${mins}${secs}`
}

export function formatDate(date: number, format: string): string {
  if (date) {
    return moment(date).format(format)
  } else {
    return 'Unknown'
  }
}

export function formatDuration(seconds: number): string {
  const secs = seconds % 60
  const mins = Math.floor(seconds / 60) % 60
  const hours = Math.floor(seconds / 3600) % 24
  const days = Math.floor(seconds / 86400)

  if (days > 0) {
    return days + 'd ' + hours + 'h'
  } else if (hours > 0) {
    return hours + 'h ' + mins + 'm'
  } else if (mins > 0) {
    return mins + 'm ' + secs + 's'
  } else {
    return secs + 's'
  }
}

/**
 * Determines the largest level of granularity (days, hours, min)
 * and rounds the number of seconds to the closest value
 * ie: 1,392 secs (23.2 min) would round to 23 mins
 * 10,080 sec (2.8 hrs) would round to 3 hrs
 *
 * returns a string rounded to the nearest min
 */
export function formatRoundedDuration(seconds: number): string {
  const mins = Math.round(seconds / 60)
  const hours = Math.floor(mins / 60) % 24
  const days = Math.floor(mins / 1440)

  if (days > 0) {
    return days + 'd ' + hours + 'h'
  } else if (hours > 0) {
    return hours + 'h ' + (mins % 60) + 'm'
  } else if (mins > 0) {
    return mins + 'm'
  } else {
    return null
  }
}

export function currentTimeZone(): string {
  return moment.tz(moment.tz.guess()).format('z')
}

/**
 * Additional formatting to string returned by moment.fromNow() method
 */
export function formatFromNowString(value: string, endsAt: number): string {
  // if output is 'a few seconds', replace with number returned by moment.diff()
  if (value.startsWith('a few')) {
    const secondsLeft = Math.abs(moment(endsAt).diff(moment(), 'seconds'))
    if (secondsLeft <= 60) {
      value = secondsLeft + ' seconds'
    }
  }

  // replace a/an with number 1
  if (value.startsWith('a ') || value.startsWith('an ')) {
    value = value.replace(/^an?\s/i, '1 ')
  }
  // replace 'month(s)', 'minute(s)' with abbreviations
  value = value.replace(/months?/i, 'mon')
  value = value.replace(/minutes?/i, 'min')
  value = value.replace(/seconds?/i, 'sec')
  return value
}

export function formatActiveSecondsOrPattern(seconds?: number, pattern?: string): string {
  return seconds ? formatActiveSeconds(seconds) : pattern
}
export function formatActiveSeconds(seconds = 0): string {
  // convert seconds to minutes
  const minutes = seconds / 60
  return formatActiveMinutes(minutes)
}

export function formatActiveMinutes(minutes?: number): string {
  if (!minutes || isNaN(minutes)) {
    return '0m'
  }

  const totalHours = Math.floor(minutes / 60)
  const days = Math.floor(totalHours / 24)
  const hours = totalHours % 24
  const min = Math.round(minutes % 60)

  let formattedTime = ''
  if (days) {
    formattedTime += `${days}d `
  }
  if (days || hours) {
    formattedTime += `${hours}h `
  }
  formattedTime += `${min}m`

  return formattedTime
}

export function formatActiveMilliSec(ms = 0): string {
  // convert milliseconds to minutes
  const minutes = ms / (60 * 1000)

  return formatActiveMinutes(minutes)
}

export function formatActiveMilliSecOrPattern(ms: number, pattern: string): string {
  return ms ? formatActiveMilliSec(ms) : pattern
}

export function formatActiveTime(value?: number): string {
  if (!value || isNaN(value)) {
    return '0hr 0m'
  }

  const hours = Math.floor(value / 60)
  const minutes = value % 60
  return hours + 'hr ' + minutes + 'm'
}

export function formatDateForPicker(date: Date | number): string {
  const dateFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(date)
}

export function updateDateWithTime(dateWithNoTime: Date, dateWithTime: Date): Date {
  const date = dateWithNoTime
  const currentStartHours = dateWithTime.getHours()
  const currentStartMinutes = dateWithTime.getMinutes()

  date.setHours(currentStartHours)
  date.setMinutes(currentStartMinutes)

  return date
}

export function toEndOfDay(dateTime: number): number {
  return moment(dateTime).endOf('day').valueOf()
}

export function toStartOfDay(dateTime: number): number {
  return moment(dateTime).startOf('day').valueOf()
}

export function areDatesAfterNow(oldDateTime: number, newDateTime: number): boolean {
  const now = Date.now()
  return oldDateTime > now && newDateTime > now
}
