import { Reducer } from 'redux'
import {
  SET_DOMAIN_FILTER,
  SELECT_CATALOG_COURSEPACK,
  SELECT_CATALOG_TOPIC,
  SELECT_CATALOG_LEARNING_OBJECTIVE,
  SelectCatalogLearningObjectiveAction,
  SELECT_CATALOG_CHAPTER,
  SelectCatalogChapterAction,
  SelectCatalogTopicAction,
  SetDomainFilterAction,
  SelectCatalogCoursepackAction,
  SELECT_CATALOG_SAMPLE_PURPOSE_TYPE,
  SelectCatalogSamplePurposeTypeAction,
  OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL,
  OpenCatalogLearningObjectiveModalAction,
  SelectCatalogConceptAction,
  SELECT_CATALOG_CONCEPT,
  SET_CONTENT_FETCHING,
  SetContentFetchingAction,
} from './browse-actions'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { ASSESSES } from 'app/frontend/data/purpose-type'

export const getBrowseCatalog = (state: TeachControllerState) => state.ui.browse

export const getFilteredDomainId = (state: TeachControllerState): string => {
  return getBrowseCatalog(state).filteredDomainId
}

export const getSelectedCoursepackId = (state: TeachControllerState): string => {
  return getBrowseCatalog(state).selectedCoursepackId
}

export const getSelectedCatalogChapters = (
  state: TeachControllerState
): { [key: string]: boolean } => {
  return getBrowseCatalog(state).selectedChapterIds
}

export const getSelectedTopicId = (state: TeachControllerState): Taxonomy.TaxonId => {
  return getBrowseCatalog(state).selectedTopicId
}

export const getPurposeType = (state: TeachControllerState): string => {
  return getBrowseCatalog(state).purposeType
}

export const getSelectedLearningObjectiveId = (state: TeachControllerState): string => {
  return getBrowseCatalog(state).selectedLoId
}

export const getSelectedConceptId = (state: TeachControllerState): string => {
  return getBrowseCatalog(state).selectedConceptId
}

export const getIsContentFetching = (state: TeachControllerState): boolean => {
  return getBrowseCatalog(state).isContentFetching
}

export type BrowseState = {
  filteredDomainId?: string
  selectedCoursepackId?: string
  selectedLoId?: string
  selectedChapterIds: { [key: string]: boolean }
  selectedTopicId?: string
  purposeType: string
  selectedConceptId?: string
  isContentFetching?: boolean
}

export const reducer = (
  state: BrowseState = {
    filteredDomainId: null,
    selectedCoursepackId: null,
    selectedLoId: null,
    selectedChapterIds: {},
    selectedTopicId: null,
    selectedConceptId: null,
    purposeType: ASSESSES,
    isContentFetching: false,
  },
  action: SetDomainFilterAction &
    SelectCatalogCoursepackAction &
    SelectCatalogLearningObjectiveAction &
    SelectCatalogConceptAction &
    SelectCatalogChapterAction &
    SelectCatalogTopicAction &
    SelectCatalogSamplePurposeTypeAction &
    OpenCatalogLearningObjectiveModalAction &
    SetContentFetchingAction & { response: any; args: any }
): BrowseState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case SET_DOMAIN_FILTER:
      return {
        ...state,
        filteredDomainId: action.domainId,
        selectedCoursepackId: null,
      }
    case SELECT_CATALOG_COURSEPACK:
      return {
        ...state,
        filteredDomainId: null,
        selectedCoursepackId: action.coursepackId,
      }
    case OPEN_CATALOG_LEARNING_OBJECTIVE_MODAL:
    case SELECT_CATALOG_LEARNING_OBJECTIVE:
      return {
        ...state,
        selectedLoId: action.learningObjectiveId,
      }
    case SELECT_CATALOG_CONCEPT:
      return {
        ...state,
        selectedConceptId: action.conceptId,
      }
    case SELECT_CATALOG_SAMPLE_PURPOSE_TYPE:
      return {
        ...state,
        purposeType: action.purposeType,
      }
    case SELECT_CATALOG_TOPIC:
      return {
        ...state,
        selectedTopicId: state.selectedTopicId === action.topicId ? null : action.topicId,
      }
    case SELECT_CATALOG_CHAPTER:
      return {
        ...state,
        selectedChapterIds: {
          ...state.selectedChapterIds,
          [action.chapterId]: !state.selectedChapterIds[action.chapterId],
        },
      }
    case SET_CONTENT_FETCHING:
      return {
        ...state,
        isContentFetching: action.isContentFetching,
      }
    default:
      return state
  }
}

export default reducer as Reducer<BrowseState>
