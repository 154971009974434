import { Reducer } from 'redux'
import { ApplyFilterAction, FilterActionType } from './filter-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { ComponentLoadAction, UiActionType } from 'app/frontend/pages/material/learn/ui/ui-actions'

export type FilterState =
  | 'all'
  | 'unanswered'
  | 'incorrect'
  | 'correct'
  | 'partial_credit'
  | 'comment'

export const getFilter = (state: LearnControllerState): FilterState => {
  return state.ui.quizFlow.filter
}

export const reducer = (
  state: FilterState = 'all',
  action: ApplyFilterAction & ComponentLoadAction
): FilterState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case FilterActionType.ApplyFilter:
      return action.filter
    case UiActionType.ComponentLoad:
      return action.name === 'QUIZ_COVER' ? 'all' : state
    default:
      return state
  }
}

export default reducer as Reducer<FilterState>
