import * as React from 'react'
import { Box, Collapse, IconButton, styled } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Inert } from 'app/frontend/compositions/ux/inert'
import { CardMaterial } from 'app/frontend/components/material/card/card'
import * as Feature from 'app/frontend/helpers/feature'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('atom_variation')

export interface QuestionCardProps {
  expanded: boolean
  includeSpaceForLearningObjectiveBar: boolean
  /** Don't set this prop if toggling is disabled */
  toggleState?: () => void
  dataSequenceId?: string
  className?: string
  questionNumber?: number
}

export const QuestionCard: React.FunctionComponent<QuestionCardProps> = ({
  expanded,
  includeSpaceForLearningObjectiveBar,
  toggleState,
  children,
  dataSequenceId,
  className,
  questionNumber,
}) => {
  return (
    <RelativeAnchor
      sx={{ px: 1, my: 6, marginBottom: '3.2rem' }}
      data-sequence-id={dataSequenceId}
      data-test="question_item"
    >
      {/** IMPORTANT! The button is *first* in the tab order */}
      {Feature.isEnabled('questionOrderingFeatureFlag') && toggleState && (
        <ToggleButtonWrapper>
          <IconButton
            onClick={toggleState}
            aria-label={t('question_number', { number: questionNumber })}
            data-bi={expanded ? 'question-collapse-btn' : 'question-expand-btn'}
            sx={{ '&:focus': { outlineColor: '#064560 !important' } }}
            aria-expanded={expanded}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ToggleButtonWrapper>
      )}

      <CardMaterial className={className}>
        {/** IMPORTANT! The content should not be keyboard navigateable when collapsed  */}
        <Inert disabled={expanded}>
          <Collapse
            in={expanded}
            collapsedSize={includeSpaceForLearningObjectiveBar ? '9.5rem' : '6.5rem'}
          >
            <Box minHeight={'12.5rem'} whiteSpace={expanded ? 'inherit' : 'nowrap'}>
              {children}
            </Box>
          </Collapse>
        </Inert>
        {!expanded && (
          <RelativeAnchor>
            <GradientMask data-test-gradient-mask />
          </RelativeAnchor>
        )}
      </CardMaterial>
    </RelativeAnchor>
  )
}

/**
 * Anything with position absolute should go in a relative parent
 */
const RelativeAnchor = styled('div')({
  position: 'relative',
  width: '100%',
})

/**
 * Gives a stylized (and filled!) circle for the button to render on
 */
const ToggleButtonWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: '-1.25rem',
  backgroundColor: 'white',
  borderRight: '1px solid',
  borderLeft: '1px solid',
  borderColor: theme.palette.grey[100],
  borderRadius: '50%',
  boxShadow: '0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.24), 0rem 0rem 0.125rem rgba(0, 0, 0, 0.12)',
  zIndex: 1,
}))

/**
 * Floats an a semi-opaque mask over the contents to grey it out
 */
const GradientMask = styled('div')(({ theme }) => ({
  height: '2.5rem',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  background: `linear-gradient(to top, ${theme.palette?.common.white}, rgba(255, 255, 255, 0.42))`,
  backgroundClip: 'content-box',
}))
