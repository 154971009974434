import * as React from 'react'
import * as _ from 'lodash'
import { Button } from 'accessible-react-toolbox/lib/button'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import * as classnames from 'classnames/bind'
import * as styles from './button.css'
const cx = classnames.bind(styles)

export type ButtonTheme = 'primary' | 'secondary' | 'warn' | 'bordered' | 'bordered-light'

export interface ButtonOwnProps {
  name?: string
  theme?: ButtonTheme
  className?: string
  ariaLabel?: string
  ariaDescribedby?: string
  disabled?: boolean
  flat?: boolean
  floating?: boolean
  href?: string
  target?: string
  label: string
  mini?: boolean
  onMouseLeave?: () => void
  onMouseUp?: () => void
  ripple?: boolean
  raised?: boolean
  icon?: string | JSX.Element
  style?: object
  onClick?: (e?: Event) => void
  path?: string
  type?: string
  ref?: (elem: any) => void
  id?: string
  fullWidth?: boolean
  // A function that will receive a reference to the button element, see https://reactjs.org/docs/refs-and-the-dom.html
  setButtonRef?: (ref: HTMLOrSVGElement) => void
}

interface ButtonDispatchProps {
  goToPath: () => void
}

export const isModifiedEvent = (event: MouseEvent): boolean => {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
}

export type Props = React.PropsWithChildren<ButtonOwnProps & ButtonDispatchProps>
export class _ButtonMaterial extends React.Component<Props, {}> {
  goToPath = (event: MouseEvent): void => {
    if (this.props.path && !isModifiedEvent(event)) {
      event.preventDefault()
      this.props.goToPath()
    }
  }

  render(): JSX.Element {
    let props = _.omit(this.props, [
      'goToPath',
      'path',
      'fullWidth',
      'ariaLabel',
      'setButtonRef',
      'ariaDescribedby',
    ])

    if (this.props.path) {
      props = { ...props, href: this.props.path }
      if (this.props.onClick) {
        const onClick = (e: MouseEvent) => {
          this.props.onClick(e)
          this.goToPath(e)
        }
        props = { ...props, onClick }
      } else {
        props = { ...props, onClick: this.goToPath }
      }
    }

    const buttonTheme = cx(styles[`button-${this.props.theme || 'primary'}`], {
      [styles.fullWidth]: this.props.fullWidth,
    })

    return (
      <Button
        {...(this.props.ariaLabel && { 'aria-label': this.props.ariaLabel })}
        {...(this.props.ariaDescribedby && { 'aria-describedby': this.props.ariaDescribedby })}
        {...props}
        theme={{
          button: buttonTheme,
          label: styles[`button-label-${this.props.theme || 'primary'}`],
        }}
        neutral={false}
        setRef={r => {
          if (this.props.setButtonRef) {
            this.props.setButtonRef(r)
          }
        }}
      />
    )
  }
}

function mapDispatchToProps(dispatch, ownProps: ButtonOwnProps): ButtonDispatchProps {
  return {
    goToPath: () => dispatch(push(ownProps.path)),
  }
}

export const ButtonMaterial = connect<{}, ButtonDispatchProps, ButtonOwnProps>(
  null,
  mapDispatchToProps
)(_ButtonMaterial)

ButtonMaterial.displayName = 'ButtonMaterial'
