import { SagaIterator } from 'redux-saga'
import { startSubmit, stopSubmit, change } from 'redux-form'
import { apiCall } from 'app/frontend/api/api-redux'
import { put, takeLatest, all } from 'redux-saga/effects'
import { SUBMIT_FORM, API_LOGIN, LoginAction } from './login/login-actions'
import { SUBMIT_REGISTRATION_FORM, API_REGISTER } from './register/register-actions'
import {
  LOGIN_ATTEMPT,
  REGISTER_ATTEMPT,
  REGISTER_OAUTH_ATTEMPT,
  RESET_PASSWORD_CLICK,
} from 'app/frontend/data/mixpanel-events'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import { sendEvent } from 'app/frontend/helpers/mixpanel'
import { login, register, registerOauth } from 'app/frontend/api/login'
import { t } from 'i18next'
import redirect from 'app/frontend/helpers/redirect'

import {
  forgotPasswordIterator,
  resetPasswordIterator,
} from './forgot-password/forgot-password-saga'
import {
  SUBMIT_FORGOT_PASSWORD_FORM,
  SUBMIT_RESET_PASSWORD_FORM,
} from './forgot-password/forgot-password-actions'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { CHILD_USER_MODAL } from './modals/child-user'

export function* submitLoginForm(action: LoginAction): SagaIterator {
  const errors = {
    password: undefined,
    recaptcha: undefined,
  }

  yield put(startSubmit('login'))

  try {
    sendEvent(LOGIN_ATTEMPT, {})
    const data = yield* apiCall(login, API_LOGIN, action.data)
    redirect(data.redirect || '/home')
  } catch (e) {
    const status = e.response && e.response.status
    if (status === 401 || status === 403) {
      yield put(change('login', 'recaptcha', ''))
      errors.password = t('invalid_password')
    } else if (status === 429) {
      errors.recaptcha = 'required'
      yield put(
        showSnackbar({
          message: t('confirm_not_robot'),
        })
      )
    } else {
      yield put(
        showSnackbar({
          message: t('generic_error'),
        })
      )
    }
  }

  yield put(stopSubmit('login', errors))
}

export function* submitRegistration(action): SagaIterator {
  const errors = {
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    username: undefined,
    password: undefined,
    dob: undefined,
    recaptcha: undefined,
  }

  yield put(startSubmit('register'))

  const newUser = {
    firstName: action.data.firstName,
    lastName: action.data.lastName,
    email: action.data.email,
    password: action.data.password,
    roleForUi: action.data.role,
    'birthday-day': action.data.day,
    'birthday-month': action.data.month,
    'birthday-year': action.data.year,
    r: action.data.redir,
  }

  try {
    // oauth registration flow
    if (action.data.googleId) {
      sendEvent(REGISTER_OAUTH_ATTEMPT)
      const newOauthUser = {
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        email: action.data.email,
        googleId: action.data.googleId,
        signature: action.data.signature,
        roleForUi: action.data.role,
        r: action.data.redir,
      }

      const oauthData = yield* apiCall(registerOauth, API_REGISTER, newOauthUser)
      redirect(oauthData.redirect || '/home')
      return
    }

    sendEvent(REGISTER_ATTEMPT)

    const data = yield* apiCall(register, API_REGISTER, newUser)
    redirect(data.redirect || '/home')
  } catch (e) {
    const status = e.response && e.response.status
    if (status === 429) {
      errors.recaptcha = 'required'
      yield put(showSnackbar({ message: t('confirm_not_robot') }))
    } else if (status === 412) {
      // Show any validation errors that occurred
      const fields = e.json.error.context
      Object.keys(fields).map(field => {
        errors[field] = fields[field]
      })
      if (errors['birthDate']) {
        yield put(showModal(CHILD_USER_MODAL))
      }
    } else {
      yield put(showSnackbar({ message: t('generic_error') }))
    }
  }

  yield put(stopSubmit('register', errors))
}

export default function* signedOutSaga(): SagaIterator {
  yield all([
    takeLatest(SUBMIT_FORM, submitLoginForm),
    takeLatest(SUBMIT_REGISTRATION_FORM, submitRegistration),
    takeLatest(SUBMIT_FORGOT_PASSWORD_FORM, forgotPasswordIterator),
    takeLatest(SUBMIT_RESET_PASSWORD_FORM, resetPasswordIterator),
    takeLatest(SUBMIT_RESET_PASSWORD_FORM, () => sendEvent(RESET_PASSWORD_CLICK)),
  ])
}
