import { combineReducers } from 'redux'
import institutionsReducerById from './institutions-reducer-by-id'
import credentialsReducer, { CredentialsReducerState } from './credentials/credentials-reducer'
import { InstitutionsByIdState } from 'app/frontend/pages/institution-admin/entities/institutions/institutions-reducer-by-id'

export type InstitutionsReducerState = {
  byId: InstitutionsByIdState
  credentials: CredentialsReducerState
}

export default combineReducers<InstitutionsReducerState>({
  byId: institutionsReducerById,
  credentials: credentialsReducer,
})
