import * as React from 'react'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material'
import { applyMiddleware, compose } from 'redux'
import { all, takeEvery } from 'redux-saga/effects'
import createSagaMiddleware, { SagaIterator } from 'redux-saga'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import * as _ from 'lodash'
import { History, Location } from 'history'
import * as ReactGA from 'react-ga'
import { createDirectStore } from 'app/frontend/helpers/redux-helpers'
import MaterialLayout from 'app/frontend/layout/material/material-layout'
import createHistory from 'history/createBrowserHistory'
import snackbarReducer from 'app/frontend/components/material/snackbar/snackbar-reducer'
import { regradeSnackbarReducer } from 'app/frontend/components/material/regrade-snackbar/regrade-snackbar-reducer'
import screenreaderAlertReducer from 'app/frontend/components/material/screenreader-alert/reducer'
import modalReducer from 'app/frontend/components/material/modal/modal-reducer'
import {
  thirdPartyModalReducer,
  ThirdPartyModalReducerState,
} from 'app/frontend/layout/material/third-party-modal-reducer'
import metricsSaga from 'app/frontend/pages/metrics/metrics-saga'
import reloadPromptSaga from 'app/frontend/components/reload-prompt/reload-prompt-saga'
import { getPageMetrics } from 'app/frontend/pages/metrics/metrics'
import { PaymentsModalState } from 'app/frontend/pages/material/learn/modals/payments/reducer'
import { MoreInstructionModalState } from 'app/frontend/pages/material/learn/modals/more-instruction/'
import { ConnectionErrorModalOptions } from 'app/frontend/components/modals/connection-error'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import learnosityReducer from 'app/frontend/components/compound/learnosity/learnosity-reducer'
import learnositySaga from 'app/frontend/components/compound/learnosity/learnosity-saga'
import { i18n } from 'app/frontend/helpers/translations/i18n'
import { getOrCreateClient } from 'app/frontend/helpers/apollo'
import { getId } from 'app/frontend/helpers/current-user'
import { SHOW_MODAL, ShowModalAction } from 'app/frontend/components/material/modal/modal-actions'
import 'app/frontend/helpers/fetch-ga'
import ErrorBoundary from 'app/frontend/components/error/error-boundary'
import * as ErrorReporter from 'app/frontend/helpers/error-reporter'
import mathjaxReducer from 'app/frontend/components/compound/mathjax/mathjax-reducer'
import WhatInput from 'app/frontend/layout/material/what-input'
import contentMetricsSaga from 'app/frontend/components/content/manager/metrics/saga'
import { syncCsrfToken } from 'app/frontend/helpers/csrf'
import * as ServiceCloud from 'app/frontend/helpers/service-cloud'
import { altaTheme } from 'app/frontend/theme'
import { PendoContext } from 'app/frontend/layout/material/pendo/pendo-context'
import MainMaterial from 'app/frontend/layout/material/main'
import { NavAppBarTheme } from './nav-app-bar'
import fflipSaga from './modals/fflip-saga'
import {
  syncReduxRouterReducer,
  SyncReduxRouterReducerState,
  syncReduxRouterWithStore,
} from './sync-redux-router'
import navReducer from './nav-reducer'
import { goalUpdateSnackbarReducer } from 'app/frontend/components/material/goal-update-snackbar/goal-update-snackbar-reducer'
import { gradeUpdateSnackbarReducer } from 'app/frontend/components/material/grade-update-snackbar/grade-update-snackbar-reducer'

try {
  ReactGA.initialize('UA-5585757-8', {
    gaOptions: { userId: getId() },
  })

  ReactGA.set({
    appName: 'frackend',
    appVersion: window.context.frontendVersion,
  })
} catch (e) {
  console.warn('Error initializing GA', e)
}

export type MaterialControllerState<TRouteParams = any> = {
  global: {
    ui: {
      modal: {
        JOIN_COURSE_MODAL?: any
        PAYMENTS_MODAL?: PaymentsModalState
        ACTIVITY_MODAL?: any
        MORE_INSTRUCTION_MODAL?: MoreInstructionModalState
        SESSION_EXPIRED_MODAL?: any
        CONNECTION_ERROR_MODAL?: ConnectionErrorModalOptions
        DELETE_ROLE_MODAL?: any
      }
      thirdPartyModal: ThirdPartyModalReducerState
      nav: {
        navDrawer: boolean
        navMenu: boolean
        pageTitle: string
      }
      snackbars: any[]
      screenreaderAlerts: string[]
      /**
       * True if we are currently inside of an IFrame
       */
      inIFrame: boolean
    }
  }
  apollo: { [id: string]: any }
  router: SyncReduxRouterReducerState<TRouteParams>
}

/**
 * Helper method which finds the path of a reducer in a webpack require context
 * This removes the need to specify the file extension by removing the extension
 * from the context keys before matching
 */
function findContextKey(path: string, requireContext: any): string {
  for (const key of requireContext.keys()) {
    if (key.replace(/\.[^/.]+$/, '') === `./${path}-controller-reducer`) {
      return key
    }
  }
}

/**
 * Bootstraps a root controller reducer with default root reducers
 */
function bootstrapReducer<TRouteParams>(history: History): (reducer: any) => any {
  const historyReducer = syncReduxRouterReducer<TRouteParams>(history)

  return reducer => {
    return (state, action) => ({
      ...reducer(_.omit(state, ['global', 'router', 'apollo']), action),
      global: {
        ui: {
          modal: modalReducer(_.get(state, 'global.ui.modal'), action),
          thirdPartyModal: thirdPartyModalReducer(
            _.get(state, 'global.ui.thirdPartyModal'),
            action
          ),
          nav: navReducer(_.get(state, 'global.ui.nav'), action),
          snackbars: snackbarReducer(_.get(state, 'global.ui.snackbars'), action),
          regradesnackbars: regradeSnackbarReducer(
            _.get(state, 'global.ui.regradesnackbars'),
            action
          ),
          goalupdatesnackbars: goalUpdateSnackbarReducer(
            _.get(state, 'global.ui.goalupdatesnackbars'),
            action
          ),
          gradeupdatesnackbars: gradeUpdateSnackbarReducer(
            _.get(state, 'global.ui.gradeupdatesnackbars'),
            action
          ),
          screenreaderAlerts: screenreaderAlertReducer(
            _.get(state, 'global.ui.screenreaderAlerts'),
            action
          ),
          learnosity: learnosityReducer(_.get(state, 'global.ui.learnosity'), action),
          mathjax: mathjaxReducer(_.get(state, 'global.ui.mathjax'), action),
          inIFrame: window.top !== window.self,
        },
      },
      router: historyReducer(state.router, action),
    })
  }
}

declare const window: any

function* controllerSaga(): SagaIterator {
  yield all([
    takeEvery(SHOW_MODAL, (action: ShowModalAction) => {
      ReactGA.modalview(action.name)
    }),
  ])
}

interface IMaterialControllerProps {
  NavDrawerContent?: React.ComponentType<any>
  routes?: any
  reducer?: string
  saga?: any
  preloadedState?: any
  enhancer?: any
  showNavBar?: boolean
  navBarTheme?: NavAppBarTheme

  // Display side menu only on mobile.
  // Display dropdown menu only desktop.
  // Display admin shortcuts in dropdown menu.
  useGlobalNavConfig?: boolean
  navLinks?: {
    label: string
    path: string
    dataBi: string
  }[]

  // Routing
  onHistoryChange?: (location: Location) => any

  // Style
  className?: string

  // metrics
  metricsName?: string

  // pass banners to the layout
  banners: React.ReactNode
}

/**
 * Use this component in your controller to setup all things redux and routing related.
 */
export default class MaterialController<TRouteParams> extends React.Component<
  IMaterialControllerProps,
  any
> {
  store: any
  history: History
  router: any
  client: ApolloClient<any>

  static defaultProps: Partial<IMaterialControllerProps> = {
    className: '',
    onHistoryChange: () => null,
    preloadedState: {},
    showNavBar: true,
  }

  constructor(props) {
    super(props)

    this.history = createHistory()
    this.store = this.getDefaultStore()
    this.client = getOrCreateClient(this.store)
    syncReduxRouterWithStore(this.store, this.props.routes, this.history)
    if (this.props.onHistoryChange) {
      this.history.listen(this.props.onHistoryChange)
      if (this.history.location) {
        // call handler for initial page load
        this.props.onHistoryChange(this.history.location)
      }
    }

    // This will put the new csrf token in localStorage.
    // This way, other browser tabs can get the new token.
    syncCsrfToken()
  }

  getDefaultStore() {
    const { reducer, preloadedState, enhancer, saga } = this.props

    const enhancers = []
    if (enhancer) {
      enhancers.push(enhancer)
    }

    // Middlewares
    const middlewares = []
    middlewares.push(routerMiddleware(this.history))

    const sagaMiddleware = createSagaMiddleware({
      onError(error) {
        console.error(error)
      },
    })
    middlewares.push(sagaMiddleware)

    enhancers.push(applyMiddleware(...middlewares))

    // Setup the main reducer and create the store
    let store
    if (reducer) {
      // Help webpack dynamically require the reducer (required for hot reloading)
      const requireContext = (require as any).context(
        'app/frontend/pages/',
        true,
        /.*-controller-reducer\.(tsx|ts|js)$/
      )
      const req = requireContext(findContextKey(reducer, requireContext)).default
      store = createDirectStore(
        bootstrapReducer<TRouteParams>(this.history)(req),
        preloadedState,
        (compose as any)(...enhancers)
      )
    } else {
      store = createDirectStore(
        bootstrapReducer<TRouteParams>(this.history)(state => state),
        preloadedState,
        (compose as any)(...enhancers)
      )
    }

    sagaMiddleware.run(fflipSaga)
    sagaMiddleware.run(learnositySaga)

    if (saga) {
      sagaMiddleware.run(controllerSaga)
      sagaMiddleware.run(saga)
      sagaMiddleware.run(metricsSaga)
      sagaMiddleware.run(reloadPromptSaga)
      sagaMiddleware.run(contentMetricsSaga)
    }

    return store
  }

  componentDidMount() {
    // Necessary to capture the router to pass to the ToastrWrapper
    if (this.router) {
      this.forceUpdate()
    }

    if (this.props.metricsName) {
      window.addEventListener('load', () =>
        getPageMetrics(this.props.metricsName, this.store.dispatch)
      )
    }

    this.showFlashMessages()
    ServiceCloud.instrument()
  }

  /**
   * Called by React when a render() function failed with an exception.
   * At this point the page is completely blank.
   */
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    ErrorReporter.report('controller.componentDidCatch', { error, info })
  }

  showFlashMessages() {
    _.each(window.context.flash, ({ message }) => {
      _.debounce(() => {
        this.store.dispatch(showSnackbar({ message }))
      }, 300 + Math.random() * 500)()
    })
  }

  render() {
    // Override the render method used by Router to capture the router
    const {
      NavDrawerContent,
      className,
      showNavBar,
      navBarTheme,
      useGlobalNavConfig,
      navLinks,
      banners,
      children,
    } = this.props

    // for some routes, the main-material container is not required to wrap since they are included
    // with some common content inside their page. so this condition is to validate those controllers.
    const pageContent = showNavBar ? <MainMaterial>{children}</MainMaterial> : <div>{children}</div>

    return (
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary source={'controller.render'}>
          <ApolloProvider client={this.client}>
            <Provider store={this.store}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={altaTheme}>
                  <WhatInput>
                    <MaterialLayout
                      className={className}
                      NavDrawerContent={
                        NavDrawerContent &&
                        (props => (
                          <Provider store={this.store}>
                            <NavDrawerContent {...props} />
                          </Provider>
                        ))
                      }
                      showNavBar={showNavBar}
                      navBarTheme={navBarTheme}
                      useGlobalNavConfig={useGlobalNavConfig}
                      navLinks={navLinks}
                      banners={banners}
                    >
                      <CssBaseline />
                      <div id="app">
                        <ConnectedRouter history={this.history}>
                          <PendoContext>{pageContent}</PendoContext>
                        </ConnectedRouter>
                      </div>
                    </MaterialLayout>
                  </WhatInput>
                </ThemeProvider>
              </StyledEngineProvider>
            </Provider>
          </ApolloProvider>
        </ErrorBoundary>
      </I18nextProvider>
    )
  }
}
