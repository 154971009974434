import { makeApiActions } from 'app/frontend/api/api-redux'

export type SEND_TIMING = 'SEND_TIMING'
export const SEND_TIMING = 'SEND_TIMING'

export const DURATION: Metrics.DURATION = 'duration'
export const TIME: Metrics.TIME = 'time'

type SendTimingActionType = {
  type: SEND_TIMING
  metrics: Metrics.TimingMetricsType
}

/**
 * Action to capture frontend metrics
 * @param metrics
 */
export function sendTiming(metrics: Metrics.TimingMetricsType[]) {
  return {
    type: SEND_TIMING,
    metrics,
  }
}
export const API_SEND_TIMING = makeApiActions('API_SEND_TIMING')

export type MetricsActions = SendTimingActionType
