import * as React from 'react'
import { PendoContextInner } from 'app/frontend/layout/material/pendo/pendo-context-inner'
import { useLocation } from 'react-router'

interface Ids {
  pathId?: string
  sectionId?: string
  courseId?: string
}

// Regular expressions used to extract uuids from the current url
const COURSE_REGEX = /\/courses?\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/
const SECTION_REGEX = /\/sections?\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/
const ASSIGNMENT_REGEX = /\/(assessments?|assignments?|quiz|test)\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/

/**
 * Extracts the path/section/course id from the given path.
 * Prefers returning the path id because it's more precise and <PendoContextInner /> adds special
 * attributes for paths.
 */
export const extractIds = (path: string): Ids => {
  const ret: Ids = {}

  if (!path || (!path.startsWith('/learn') && !path.startsWith('/teach'))) {
    // We don't try extracting ids from pages other than teach and learn.
    return ret
  }

  // Try to extract a path id
  let match = ASSIGNMENT_REGEX.exec(path)
  if (match) {
    ret.pathId = match[2]
  }

  // Try to extract a course id
  match = COURSE_REGEX.exec(path)
  if (match) {
    if (path.startsWith('/learn/course/')) {
      // The id following /course/ in learn pages is a section id
      ret.sectionId = match[1]
    } else {
      ret.courseId = match[1]
    }
  }

  // Try to extract a section id
  match = SECTION_REGEX.exec(path)
  if (match) {
    ret.sectionId = match[1]
  }

  return ret
}

export const PendoContext: React.FunctionComponent = ({ children }) => {
  const location = useLocation()
  return <PendoContextInner {...extractIds(location?.pathname)}>{children}</PendoContextInner>
}
