/*

To test this modal manually, find a mathjax request in the network tab in Chrome in the learn flow,
then right click it and select "block request", then edit the blocked url to include everything
mathjax, for example when running against QACD: `d1873o2okbzyn2.cloudfront.net/mathjax/2.7/*`

 */
import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { getModalOptions } from 'app/frontend/components/material/modal/modal-reducer'
import { connect } from 'react-redux'
import * as styles from './connection-error-modal.css'

const t = tns('connection_error_modal')

export const CONNECTION_ERROR_MODAL = 'CONNECTION_ERROR_MODAL'

export interface ConnectionErrorModalOptions {
  isFatal: boolean
}

interface StateProps {
  options: ConnectionErrorModalOptions
}

type Props = StateProps

/**
 * Prompt the user with a Modal expressing discontent with their internets.
 */
export const ConnectionErrorModal: React.FunctionComponent<Props> = ({ options }) => {
  return options.isFatal ? <FatalModal /> : <SoftModal />
}
ConnectionErrorModal.displayName = 'ConnectionErrorModal'

export function mapStateToProps(state: any): StateProps {
  const options: ConnectionErrorModalOptions = getModalOptions(state, CONNECTION_ERROR_MODAL)
  return {
    options: options || {
      isFatal: false,
    },
  }
}

export default connect<StateProps, {}, {}>(mapStateToProps)(ConnectionErrorModal)

/**
 * Reload the page by calling this
 */
const reloadPage = () => location.reload()

/**
 * We've encountered an unrecoverable scenario based on the user's connection.
 * Instructs them to reload the page and provides a handy button to do so.
 */
export const FatalModal: React.FunctionComponent<{}> = () => {
  // Ideally we'd lock the user out of alta with preventClose because nothing good can come of them
  // progressing. But that would require us to be 100% confident in our assessment that a reload
  // will fix the issue.
  return (
    <Modal name={CONNECTION_ERROR_MODAL} dataBi="connection-error-modal">
      <ModalBody>
        <h1 id="modalTitle" className={styles.modalTitle}>
          {t('title')}
        </h1>
        <p>{t('connection_problem')}</p>
        <p>{t('fatal_solution')}</p>
      </ModalBody>
      <ModalFooter>
        <ButtonMaterial
          onClick={reloadPage}
          data-bi="reload-page-button"
          label={t('reload_button')}
        />
      </ModalFooter>
    </Modal>
  )
}
FatalModal.displayName = 'FatalModal'

/**
 * Lightly suggest to the user there may be a problem. This would be great if it stayed open
 * until we detected a more stable connection.
 */
export const SoftModal: React.FunctionComponent<{}> = () => {
  return (
    <Modal name={CONNECTION_ERROR_MODAL}>
      <ModalBody>
        <h1 id="modalTitle" className={styles.modalTitle}>
          {t('title')}
        </h1>
        <p>{t('connection_problem')}</p>
      </ModalBody>
    </Modal>
  )
}
SoftModal.displayName = 'SoftModal'
