import * as React from 'react'
import * as moment from 'moment'
import * as classnames from 'classnames'
import * as keycode from 'keycode'
import { StaticDatePicker, LocalizationProvider, PickersDayProps } from '@mui/lab'
import AdapterMoment from '@mui/lab/AdapterMoment'
import { makeStyles } from '@mui/styles'
import { IconButton, Popper } from '@mui/material'
import { DateTimeFormats } from 'app/helpers/timezone'
import { handleFocusTrap } from '../helpers'
import { useCalendarStyles } from './styles'

export type Props = {
  pickerOpen: boolean
  hidePicker?: () => void
  onChange: (value: moment.Moment) => void
  value: moment.Moment | undefined
  maxDate?: moment.Moment
  minDate?: moment.Moment
  disabled?: boolean
  dateFormat?: DateTimeFormats
  timeFormat?: DateTimeFormats
  time?: string
  defaultTime?: string
  setRefs?: any
  anchorEl?: any
  calendarStyles?: string
}

export type PopperProps = Props & {
  /**
   * What element should the Popper consider as an
   * anchor.
   */
  anchorEl: any
}

export const Calendar: React.FunctionComponent<Props> = ({
  hidePicker,
  onChange,
  value,
  maxDate,
  minDate,
  disabled,
  pickerOpen,
  dateFormat = DateTimeFormats.MONTH_DATE_YEAR,
  timeFormat = DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE,
  time = value && value.format(timeFormat),
  defaultTime = '11:59pm',
  setRefs,
  calendarStyles,
}) => {
  const styles = useCalendarStyles()
  const calendarRef = React.useRef<HTMLDivElement>()
  const leftIconRef = React.useRef<HTMLDivElement>()
  const rightIconRef = React.useRef<HTMLDivElement>()
  const { leftIcon = leftIconRef, rightIcon = rightIconRef, calendar = calendarRef } = setRefs ?? {}
  const datePickerRefs = [leftIcon, rightIcon, calendar]
  const dateTimeFormat = `${dateFormat} ${timeFormat}`
  const [key, setKey] = React.useState(true)

  const [focusedValue, setFocused] = React.useState<moment.Moment>(value ?? moment())

  // Adds the current time to the minimum and maximum date
  const { minDateTime, maxDateTime } = React.useMemo(() => {
    return {
      minDateTime: moment(`${minDate.format(dateFormat)} ${time || defaultTime}`, dateTimeFormat),
      maxDateTime: moment(`${maxDate.format(dateFormat)} ${time || defaultTime}`, dateTimeFormat),
    }
  }, [maxDate, minDate, time, dateTimeFormat, defaultTime])

  React.useEffect(() => {
    if (value && focusedValue && !focusedValue.isSame(value)) {
      setFocused(value)
    }
  }, [value])

  React.useEffect(() => {
    if (pickerOpen) {
      calendar.current?.focus()
    }
    if (!pickerOpen) {
      setFocused(value)
    }
  }, [pickerOpen, calendar])

  const handleChange = (val: moment.Moment) => {
    // If date is out of bounds (before min or after max) then the calendar will
    // automatically trigger onChange with the new value (min or max).
    // When the calendar automatically triggers the onChange, we want to change
    // the focus value, but not the actual underlying value.
    if (focusedValue.isBefore(minDate.startOf('day'))) {
      setFocused(moment(`${minDate.format(dateFormat)} ${time || defaultTime}`, dateTimeFormat))
    } else if (focusedValue.isAfter(maxDate)) {
      setFocused(moment(`${maxDate.format(dateFormat)} ${time || defaultTime}`, dateTimeFormat))
    } else {
      const dateWithTime = moment(
        `${val.format(dateFormat)} ${time || defaultTime}`,
        dateTimeFormat
      )
      setFocused(dateWithTime)
      onChange(dateWithTime)
      hidePicker()
    }
  }

  // We dont want the user to be able to navigate outside of the min
  // and max date
  const setFocusedInBounds = React.useCallback(
    (val: moment.Moment) => {
      if (val.isBefore(minDateTime)) {
        return setFocused(minDateTime)
      } else if (val.isAfter(maxDateTime)) {
        return setFocused(maxDateTime)
      } else {
        return setFocused(val)
      }
    },
    [maxDateTime, minDateTime]
  )

  const handleEnterOrSpace = (e: React.KeyboardEvent<HTMLDivElement>) => {
    let focusedRefIndex = 0 // start with calendar index as default
    // check if left or right icon are focused
    for (let i = 0; i <= 2; i++) {
      if ((e.target as HTMLDivElement) === datePickerRefs[i].current) {
        focusedRefIndex = i
      }
    }
    if (focusedRefIndex === 0) {
      // focus is on left arrow (to change month)
      setFocusedInBounds(moment(focusedValue).add(-1, 'month'))
      // if prev month is out of bounds icon will have tabIndex -1 and lose focus
      // so we are setting focus back to calendar
      if (moment(focusedValue).add(-2, 'month').isBefore(minDate)) {
        calendar.current.focus()
      }
      return
    } else if (focusedRefIndex === 1) {
      // focus is on right arrow (to change month)
      setFocusedInBounds(moment(focusedValue).add(1, 'month'))
      // if next month is out of bounds icon will have tabIndex -1 and lose focus
      // so we are setting focus back to calendar
      if (moment(focusedValue).add(2, 'month').isAfter(maxDate)) {
        calendar.current.focus()
      }
      return
    } else {
      // focus is on calendar
      if (hidePicker) {
        hidePicker()
      }
      return onChange(focusedValue)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (keycode(e.keyCode) === 'esc') {
      if (hidePicker) {
        hidePicker()
      }
      return
    }
    e.preventDefault()
    if (keycode(e.keyCode) === 'tab') {
      setKey(!key)
      if (!setRefs) {
        return handleFocusTrap(e, datePickerRefs)
      }
    }

    switch (keycode(e.keyCode)) {
      case 'left':
        return setFocusedInBounds(moment(focusedValue).add(-1, 'day'))
      case 'up':
        return setFocusedInBounds(moment(focusedValue).add(-1, 'weeks'))
      case 'right':
        return setFocusedInBounds(moment(focusedValue).add(1, 'day'))
      case 'down':
        return setFocusedInBounds(moment(focusedValue).add(1, 'weeks'))
      case 'space':
      case 'enter':
        return handleEnterOrSpace(e)
      default:
        return
    }
  }

  const renderDay = (
    day: moment.Moment,
    selectedDates: moment.Moment[],
    props: PickersDayProps<moment.Moment>
  ) => {
    const isFocusedDay = moment(day).isSame(selectedDates[0])
    const isSelectedDay = value && day.format(dateFormat) === value.format(dateFormat)
    const isOutOfBoundsDay = moment(day).isBefore(minDate) || moment(day).isAfter(maxDate)
    const isCalendarFocus = document.activeElement === calendar.current
    return (
      <IconButton
        key={props.key}
        className={classnames(
          styles.day,
          props.outsideCurrentMonth && styles.hidden,
          props.today && styles.today,
          isSelectedDay && styles.daySelected,
          isFocusedDay && isCalendarFocus && styles.dayFocused,
          isFocusedDay && !isCalendarFocus && styles.dayFocusedNotCalendar,
          isOutOfBoundsDay && styles.dayDisabled
        )}
        data-test={moment(day).dayOfYear()}
        data-disabled={isOutOfBoundsDay}
        tabIndex={-1}
        onClick={() => {
          if (day) {
            handleChange(day)
          }
        }}
      >
        {day.format('D')}
      </IconButton>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <>
        {pickerOpen && (
          <div
            onKeyDown={handleKeyDown}
            tabIndex={0}
            className={classnames(styles.calendar, calendarStyles)}
            ref={calendar}
          >
            <StaticDatePicker
              showDaysOutsideCurrentMonth={false}
              displayStaticWrapperAs="desktop"
              renderInput={() => null}
              componentsProps={{
                leftArrowButton: { ref: leftIcon, 'data-test': 'month-prev-btn' },
                rightArrowButton: { ref: rightIcon, 'data-test': 'month-next-btn' },
              }}
              inputFormat={dateFormat}
              value={focusedValue}
              onChange={handleChange}
              maxDate={maxDate}
              minDate={minDate}
              disabled={disabled}
              views={['day']}
              renderDay={renderDay}
            />
          </div>
        )}
      </>
    </LocalizationProvider>
  )
}

const popperCalendarStyles = makeStyles({
  calendarPopper: {
    position: 'unset',
    zIndex: 'unset',
  },
})

/**
 * Popper wrapped Calendar.
 */
export const PopperCalendar: React.FunctionComponent<PopperProps> = ({ anchorEl, ...props }) => {
  const styles = popperCalendarStyles()
  return (
    <Popper open={props.pickerOpen} anchorEl={anchorEl} disablePortal={true} style={{ zIndex: 1 }}>
      <Calendar {...props} calendarStyles={styles.calendarPopper} />
    </Popper>
  )
}
