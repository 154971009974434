import {
  ADD_SCREENREADER_ALERT,
  REMOVE_SCREENREADER_ALERT,
  AlertScreenreaderActions,
} from './actions'

const defaultState = []

export default (state: string[] = defaultState, action: AlertScreenreaderActions): string[] => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case ADD_SCREENREADER_ALERT:
      return state.concat([action.alert])
    case REMOVE_SCREENREADER_ALERT:
      return state.slice(1)
    default:
      return state
  }
}
