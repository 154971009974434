import * as React from 'react'
import { reduxForm, Field, initialize, getFormSubmitErrors } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { omit } from 'lodash'
import * as moment from 'moment'
import {
  validateRequired,
  validateEmail,
  validatePassword,
  validatePasswordsEqual,
} from 'app/frontend/helpers/validation'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { CardMaterial } from 'app/frontend/components/material/card/card'
import LegalStuff from 'app/frontend/components/material/legal-stuff/legal-stuff'
import { MainMaterial } from 'app/frontend/layout/material/main'
import { submitRegistrationForm } from 'app/frontend/pages/signed-out/register/register-actions'
import { useForm } from 'app/frontend/compositions/connected/redux-form'
import { t } from 'app/frontend/helpers/translations/i18n'
import GoogleOauthMessaging from './google-oauth-messaging'
import PersonalInfo from './personal-info'
import LegalLinks from './legal-links'
import HiddenFields from './hidden-fields'
import SwitchToSignIn from './switch-to-sign-in'
import IntroMessaging from './intro-messaging'
import { Recaptcha } from './register-fields'
import * as styles from './register.css'
import SignedOutLogo from '../signed-out-logo'

export const REGISTER = 'register'

interface Props {
  google?: any
  handleSubmit?: (fn: (e) => any) => React.EventHandler<React.FormEvent<HTMLFormElement>>
  redir?: string
  role?: any
}

export const Register: React.FunctionComponent<Props> = ({ google, redir, role, handleSubmit }) => {
  const registerFormRef: React.RefObject<HTMLFormElement> = React.useRef<HTMLFormElement>()
  const errors = useSelector(state => getFormSubmitErrors(REGISTER)(state))
  const { isValid, isSubmitting } = useForm(REGISTER)
  const dispatch = useDispatch()
  const googled = !!(google && google.email)

  React.useEffect(() => {
    document.title = t('registration_page:page_title')
    const initialValues = {
      role: role || 'LEARNER',
      day: '1',
      month: '1',
      // birth year must be over 13
      year: moment().year() - 13,
      firstName: google && google.firstName,
      lastName: google && google.lastName,
      email: google && google.email,
      signature: google && google.signature,
      googleId: google && google.googleId,
      redir: redir,
    }

    dispatch(initialize(REGISTER, initialValues))
  }, [])

  React.useEffect(() => {
    // props.errors is a object with all the possible error filed like firstName, email, password,
    // etc... the userName key should be omit since it has no match with the fields here. Once an
    // error occurred, the focus will set to firstName. But when editing it again will refocus to
    // firstName filed because the props.errors still have values. To avoid this had to filter the
    // error object values with undefined
    if (!!errors) {
      const errorData = Object.values(omit({ ...errors }, ['username'])).filter(i => !!i)
      if (errorData.length > 0) {
        registerFormRef.current.getElementsByTagName('input')['firstName'].focus()
      }
    }
  }, [errors])

  const onSubmit = formValues => {
    dispatch(submitRegistrationForm(formValues))
  }

  return (
    <div className={styles.container}>
      <SignedOutLogo />
      {!googled && <IntroMessaging t={t} />}
      <MainMaterial>
        <CardMaterial className={styles.registrationCard}>
          <h1 className={styles.registerTitle}>
            {google && google.email
              ? t('registration_page:verify_your_info')
              : t('registration_page:create_your_account')}
          </h1>
          <GoogleOauthMessaging google={google} googled={googled} t={t} />
          <form ref={registerFormRef} onSubmit={handleSubmit(onSubmit)}>
            <PersonalInfo googled={googled} />
            <LegalLinks />
            <ButtonMaterial
              label="CREATE ACCOUNT"
              onClick={() => handleSubmit(onSubmit)}
              className={styles.createAccountBtn}
              type="submit"
              data-bi="create-account-button"
              disabled={!isValid || isSubmitting}
            />
            {!googled && <SwitchToSignIn redir={redir} />}
            <HiddenFields googled={googled} />
          </form>
          <Field name="recaptcha" component={Recaptcha} />
        </CardMaterial>
      </MainMaterial>
      <LegalStuff />
    </div>
  )
}

// Only call validation function if NOT using oauth
const validateNonOauth = values => !values.googleId

const validate = values => ({
  firstName: validateRequired(values.firstName),
  lastName: validateRequired(values.lastName),
  email: validateNonOauth(values) && validateEmail(values.email),
  password: validateNonOauth(values) && validatePassword(values.password),
  passwordConfirm:
    validateNonOauth(values) && validatePasswordsEqual(values.password, values.passwordConfirm),
  month: validateNonOauth(values) && validateRequired(values.month),
  day: validateNonOauth(values) && validateRequired(values.day),
  year: validateNonOauth(values) && validateRequired(values.year),
})

export const RegisterForm = reduxForm({
  form: REGISTER,
  validate,
  enableReinitialize: false,
})(Register)

RegisterForm.displayName = 'Register'

export default RegisterForm
