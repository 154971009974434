import * as React from 'react'
import { Field, FieldProps as ReduxFieldProps } from 'redux-form'
import * as classnames from 'classnames'
import {
  FormControl,
  FormControlLabelProps,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from '@mui/material'
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup'
import Dropdown, { IDropdownProps } from 'app/frontend/components/material/dropdown/dropdown'
import { Icon } from 'app/frontend/components/material/icon'
import { Checkbox } from 'app/frontend/components/material/checkbox'
import {
  ITextInputProps,
  TextInputMaterial,
} from 'app/frontend/components/material/textinput/textinput'
import { t } from 'app/frontend/helpers/translations/i18n'
import { FormControlLabelCheckbox } from 'app/frontend/components/material/form-control-label'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import {
  AsyncSelector,
  Callback as AsyncSelectorCallback,
  Props as AsyncSelectorProps,
} from 'app/frontend/compositions/connected/async-selector'
import { Switch, SwitchProps } from 'app/frontend/components/switch'
import { RadioGroup } from 'app/frontend/components/radio-group'
import * as styles from './index.css'

// The real WrappedFieldProps is in @types/redux-form
// but that package throws too many errors to incorporate
// right now.
export type WrappedFieldPropsStub = {
  meta?: {
    pristine: boolean
    error: string
    dirty: boolean
    initial: number
    form: string
    submitting: boolean
  }
  input: {
    name: string
    onChange: (val) => void
    value: any
  }
  toggle?: (enabled: boolean) => void
}

export type FieldValidate = (
  value: any,
  allValues: any,
  props: any,
  name: string
) => string | undefined

export type FieldProps = {
  validate?: FieldValidate | FieldValidate[]
  name: string
}

export interface WrappedFieldArrayProps<FieldValue> {
  fields: FieldsProps<FieldValue>
  meta: FieldArrayMetaProps
}

type FieldIterate<FieldValue, R = void> = (
  name: string,
  index: number,
  fields: FieldsProps<FieldValue>
) => R

export interface FieldsProps<FieldValue> {
  forEach(callback: FieldIterate<FieldValue>): void
  get(index: number): FieldValue
  getAll(): FieldValue[]
  insert(index: number, value: FieldValue): void
  length: number
  map<R>(callback: FieldIterate<FieldValue, R>): R[]
  pop(): FieldValue
  push(value: FieldValue): void
  remove(index: number): void
  removeAll(): void
  shift(): FieldValue
  swap(indexA: number, indexB: number): void
  unshift(value: FieldValue): void
}

export interface FieldSwitchProps extends SwitchProps {
  toggle?: (enabled: boolean) => void
}

interface FieldArrayMetaProps {
  dirty: boolean
  error?: any
  form: string
  invalid: boolean
  pristine: boolean
  submitFailed: boolean
  submitting: boolean
  valid: boolean
  warning?: any
}

/**
 * TEXT INPUT
 */
export const renderTextField = (field: WrappedFieldPropsStub & ITextInputProps) => (
  <TextInputMaterial
    describedBy={field.describedBy}
    className={field.className || null}
    disabled={field.disabled}
    description={field.description}
    error={!field.meta.pristine && field.meta.error}
    label={field.label}
    maxLength={field.maxLength}
    multiline={field.multiline}
    name={field.input.name}
    onChange={field.input.onChange}
    required={!!field.required}
    theme={field.theme}
    type={field.type || 'text'}
    value={field.input.value}
    hint={field.hint}
    id={field.id}
  />
)

export type FieldTextInputProps = ITextInputProps

export const FieldTextInput: React.FunctionComponent<FieldTextInputProps & FieldProps> = props => (
  <Field {...props} component={renderTextField} />
)
FieldTextInput.displayName = 'FieldTextInput'

export const renderFormFieldTextInput = (field: WrappedFieldPropsStub & ITextInputProps) => {
  return (
    <FormControl variant="standard" fullWidth>
      <TextField
        label={field.label}
        name={field.input.name}
        value={field.input.value}
        onChange={field.input.onChange}
        variant="standard"
        multiline={field.multiline}
        inputProps={{
          maxLength: field.maxLength,
          'aria-describedby': `${field.label}-counter`,
          'aria-label': field.label as string,
        }}
      />
      <FormHelperText sx={{ textAlign: 'right' }} component="span" id={`${field.label}-counter`}>
        {`${field.input.value.length}/${field.maxLength}`}
      </FormHelperText>
    </FormControl>
  )
}

export const FormFieldTextInputWithCounter: React.FunctionComponent<
  FieldTextInputProps & FieldProps
> = props => <Field {...props} component={renderFormFieldTextInput} />

FormFieldTextInputWithCounter.displayName = 'FieldDisabledTextField'

export const renderDisabledTextField = (field: WrappedFieldPropsStub & ITextInputProps) => {
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ marginTop: '0.625rem', marginBottom: '1.25rem' }}
    >
      <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
      <Input
        id={field.id}
        name={field.input.name}
        value={field.input.value}
        inputProps={{ 'aria-label': field.label as string }}
        aria-describedby="titleOrISBN"
        disabled={field.disabled}
      />
      <FormHelperText component="span" id="titleOrISBN">
        {field.description}
      </FormHelperText>
    </FormControl>
  )
}

export const FieldDisabledTextField: React.FunctionComponent<
  FieldTextInputProps & FieldProps
> = props => <Field {...props} component={renderDisabledTextField} />

FieldDisabledTextField.displayName = 'FieldDisabledTextField'

/**
 * TEXT INPUT FOR CURRENCY ($0.00)
 */

// <input type="number" /> will filter out non-numerics by default
export const normalizeCurrency = (value?: string): string =>
  value ? `${Math.abs(Number(value)).toFixed(2)}` : value // 0.00 or undefined

export const renderCurrency = (field: WrappedFieldPropsStub & ITextInputProps) => (
  <Box direction="row" responsive={false} alignItems="center" full="horizontal">
    <Paragraph margin="none">$</Paragraph>
    <TextInputMaterial
      className={field.className}
      disabled={field.disabled}
      description={field.description}
      error={!field.meta.pristine && field.meta.error}
      label={field.label}
      maxLength={field.maxLength}
      multiline={false}
      name={field.input.name}
      onChange={field.input.onChange}
      required={!!field.required}
      theme={field.theme}
      type="number"
      value={field.input.value}
      onBlur={(e: React.FormEvent<HTMLInputElement>) =>
        field.input.onChange(normalizeCurrency(e.currentTarget.value))
      }
    />
  </Box>
)

export const FieldCurrency: React.FunctionComponent<FieldTextInputProps> = props => (
  <Field {...props} component={renderCurrency} />
)
FieldCurrency.displayName = 'FieldCurrency'

/**
 * DROPDOWN
 */
export const renderDropdown = (field: WrappedFieldPropsStub & IDropdownProps) => {
  // set an 'unselected' option
  const defaultSelectOption = {
    disabled: true,
    value: '',
    label: t('select'),
  }

  return (
    <Dropdown
      dataBi={`${field.input.name}-dropdown`}
      choices={[defaultSelectOption, ...field.choices]}
      className={field.className || null}
      disabled={field.disabled}
      label={field.label}
      labelledBy={field.labelledBy}
      name={field.input.name}
      onChange={field.input.onChange}
      value={field.input.value}
      required={field.required}
      description={field.description}
      id={field.id}
    />
  )
}

export type FieldDropdownProps = Partial<IDropdownProps>
export const FieldDropdown: React.FunctionComponent<FieldDropdownProps> = props => (
  <Field {...props} component={renderDropdown} />
)
FieldDropdown.displayName = 'FieldDropdown'

/**
 * ASYNC DROPDOWN (e.g. Search with async results)
 *
 * We should eventually replace this with the material-ui auto-complete from the lab.
 * https://material-ui.com/components/autocomplete/
 */

export type AsyncSelectorQueryCallback<T> = (
  value: string,
  includeTest: boolean,
  callback: AsyncSelectorCallback<T>
) => void

export type SomeAsyncSelectorProps<T> = Omit<
  AsyncSelectorProps<T>,
  /* redefined to expose test toggle */
  | 'onQueryChange'
  /* value / onChange is managed by the field */
  | 'onSelectionChange'
  | 'value'
  /* handled internally */
  | 'onIncludeTestChange'
  | 'includeTest'
  /* tbd */
  | 'note'
  | 'error'
  | 'multi'
> & {
  onQueryChange?: AsyncSelectorQueryCallback<T>
}

export const renderAsyncSelector = <T,>(
  field: WrappedFieldPropsStub & SomeAsyncSelectorProps<T>
) => {
  const [includeTestChange, setIncludeTestChange] = React.useState(false)
  const handleQueryChange = React.useCallback(
    (value: string, callback: AsyncSelectorCallback<T>) => {
      field.onQueryChange?.(value, includeTestChange, callback)
    },
    [field.onQueryChange, includeTestChange]
  )

  return (
    <AsyncSelector<T>
      // Async Selector doesn't want a blank string as empty
      value={field.input.value === '' ? null : field.input.value}
      onQueryChange={handleQueryChange}
      onSelectionChange={field.input.onChange}
      getOptionLabel={field.getOptionLabel}
      getOptionKey={field.getOptionKey}
      optionRenderer={field.optionRenderer}
      placeholder={field.placeholder}
      required={field.required}
      disabled={field.disabled}
      onIncludeTestChange={setIncludeTestChange}
      title={field.title}
      dataBi={field.dataBi}
      noResultsText={field.noResultsText}
      includeTest={includeTestChange}
      multi={false}
      testToggle={field.testToggle}
      testToggleLabel={field.testToggleLabel}
      isClearable={field.isClearable}
      searchPromptText={field.searchPromptText}
    />
  )
}

export interface FieldAsyncSelectorProps<T> extends SomeAsyncSelectorProps<T>, FieldProps {}

export const FieldAsyncSelector = <T,>(props: FieldAsyncSelectorProps<T>) => (
  <Field name={name} {...props} component={renderAsyncSelector} />
)

/**
 * SWITCH
 */
export const renderSwitch = (field: WrappedFieldPropsStub & SwitchProps) => (
  <Switch
    inputProps={field.inputProps}
    id={field.id}
    checked={!!field.input.value}
    disabled={field.disabled}
    name={field.input.name}
    onChange={event => {
      if (!field.disabled) {
        field.input.onChange(event.target.checked)
        if (field.toggle) {
          field.toggle(event.target.checked)
        }
      }
    }}
  />
)

export const FieldSwitch: React.FunctionComponent<FieldSwitchProps> = props => (
  <Field {...props} component={renderSwitch} />
)

FieldSwitch.displayName = 'FieldSwitch'

/**
 * CHECKBOX WITH LABEL
 */
const renderCheckboxLabel = (field: ReduxFieldProps & FormControlLabelProps) => (
  <FormControlLabelCheckbox
    control={
      <Checkbox
        checked={!!field.input.value}
        onChange={(!field.disabled && field.input.onChange) || null}
        inputProps={{ 'aria-describedby': field['aria-describedby'] }}
      />
    }
    label={field.label}
    checked={!!field.input.value}
    className={field.className || null}
    disabled={field.disabled}
    name={field.input.name}
    classes={{ root: field.className, label: field.textStyles }}
    labelPlacement={field.labelPlacement}
  />
)

export const FieldCheckboxLabel: React.FunctionComponent<
  ReduxFieldProps & FormControlLabelProps
> = props => <Field {...props} type="checkbox" component={renderCheckboxLabel} />
FieldCheckboxLabel.displayName = 'FieldCheckboxLabel'

/**
 * Component to render out the error message for a redux-form field.
 * This intentionally does NOT display the actual field.
 */
export const FieldErrorMessage: React.FunctionComponent<{ name: string }> = ({ name }) => (
  <Field
    name={name}
    component={field =>
      field.meta && field.meta.error ? (
        <div role="alert" className={styles.errorMessage}>
          {field.meta.error}
        </div>
      ) : null
    }
  />
)
FieldErrorMessage.displayName = 'FieldErrorMessage'

/**
 * Component to render out the warning message for a redux-form field.
 * This intentionally does NOT display the actual field.
 */
export const FieldWarningMessage: React.FunctionComponent<{ name: string }> = ({ name }) => (
  <Field
    name={name}
    component={field =>
      field.meta && field.meta.warning ? (
        <Box full="page" role="alert" direction="row" className={styles.warningMessageBox}>
          <Icon name="icon-warning" size="large" className={styles.warningMessageIcon} />
          <div className={styles.warningMessage}>{field.meta.warning}</div>
        </Box>
      ) : null
    }
  />
)
FieldWarningMessage.displayName = 'FieldWarningMessage'

/**
 * Component to render out an info message for a redux-form field.
 * This intentionally does NOT display the actual field.
 */
export const FieldInfoMessage: React.FunctionComponent<{ name: string; className?: string }> = ({
  name,
  className,
}) => (
  <Field
    name={name}
    component={field =>
      field.meta?.warning ? (
        <Box
          full="horizontal"
          role="alert"
          direction="row"
          className={classnames(styles.infoMessageBox, className)}
        >
          <Icon name="icon-info-outline" size="large" className={styles.infoMessageIcon} />
          <div className={styles.infoMessage}>{field.meta.warning}</div>
        </Box>
      ) : null
    }
  />
)
FieldInfoMessage.displayName = 'FieldInfoMessage'

export interface RadioGroupOwnProps extends Partial<RadioGroupProps> {
  disabled?: boolean
  options: { label: string; value: any }[]
}

/**
 * RadioGroup
 */
export const renderRadioGroup = (field: WrappedFieldPropsStub & RadioGroupOwnProps) => (
  <RadioGroup
    name={field.input.name}
    radioGroupProps={{
      onChange: field.input.onChange,
      value: field.input.value,
    }}
    disabled={field.disabled}
    radioProps={field.options}
  />
)

export const FieldRadioGroup: React.FunctionComponent<RadioGroupOwnProps> = props => (
  <Field {...props} type="radioGroup" component={renderRadioGroup} />
)

FieldRadioGroup.displayName = 'FieldRadioGroup'
