import * as React from 'react'
import { connect } from 'react-redux'
import MaterialSnackbar from 'accessible-react-toolbox/lib/snackbar'
import { hideSnackbar } from './snackbar-actions'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './snackbar.css'
import * as classNames from 'classnames/bind'
import { ActionCreator } from 'redux'

const cx = classNames.bind(styles)

export interface ISnackbarProps {
  message: string | React.ReactElement<any>
  actionLabel?: string
  handleClick?: () => void
  actionSaga?: ActionCreator<any>
  timeout?: number
  hideSnackbar?: () => void // get this from mapDispatchToProps, don't pass in
  iconName?: string
  iconClassName?: string
  key?: string
}

interface ISnackbarState {
  active: boolean
  clicked: boolean
}

const defaultTime = 5000

interface OwnProps {
  message: string | React.ReactElement<any>
  actionLabel?: string
  handleClick?: () => void
  actionSaga?: ActionCreator<any>
  timeout?: number
  iconName?: string
  iconClassName?: string
  key?: string
}

interface DispatchProps {
  hideSnackbar?: () => void // get this from mapDispatchToProps, don't pass in
}

type Props = OwnProps & DispatchProps

export class Snackbar extends React.Component<Props, ISnackbarState> {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      clicked: false,
    }
  }

  componentDidMount(): void {
    this.setState({ active: true })
  }

  UNSAFE_componentWillReceiveProps(): void {
    this.setState({ active: true, clicked: false })
  }

  private handleTimeout = (): void => {
    if (!this.state.clicked && this.state.active) {
      this.hide()
    }
  }

  private hide = (): void => {
    this.setState({ active: false })
    // delay needed for slide out animation
    if (this.props.hideSnackbar) {
      setTimeout(() => {
        this.props.hideSnackbar()
      }, 750)
    }
  }

  private onClick = (): void => {
    if (!this.state.clicked) {
      this.setState({ clicked: true })
      this.props.handleClick()
      this.hide()
    }
  }

  getClassName() {
    const { actionLabel } = this.props

    return cx({
      action: !!actionLabel,
    })
  }

  getLabel(): string | React.ReactElement<any> {
    const { message, iconName, iconClassName } = this.props
    let label: string | React.ReactElement<any> = message

    if (iconName) {
      const className = cx(styles.icon, iconClassName)

      label = (
        <div className={styles.iconWrapper}>
          <Icon name={iconName} className={className} />
          {message}
        </div>
      )
    }
    return label
  }

  render(): JSX.Element {
    const { actionLabel, timeout } = this.props
    const calcTimeout = timeout === null || timeout > 0 ? timeout : defaultTime

    const theme = {
      snackbar: styles.snackbar,
      label: styles.label,
      button: styles.button,
      active: styles.active,
    }

    return (
      <MaterialSnackbar
        label={this.getLabel()}
        action={actionLabel}
        onClick={this.onClick}
        active={this.state.active}
        timeout={calcTimeout}
        onTimeout={this.handleTimeout}
        theme={theme}
        className={this.getClassName()}
      />
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideSnackbar: () => dispatch(hideSnackbar()),
  }
}

export default connect<{}, DispatchProps, OwnProps>(null, mapDispatchToProps)(Snackbar)
