import { ILearnFlowHistoryReducer } from 'app/frontend/pages/material/learn/ui/learn-flow/history-reducer'

export const getLearnFlowHistory = (history: Commons.IHistory): ILearnFlowHistoryReducer => {
  if (!history) {
    return {
      questionsCompleted: 0,
      instructionsCompleted: 0,
      questionsAnswered: 0,
    }
  }

  let questionsCompleted = 0
  let instructionsCompleted = 0
  let questionsAnswered = 0

  history.sequences.forEach(sequence => {
    questionsCompleted += sequence.numAssessment
    instructionsCompleted += sequence.numInstructional
    questionsAnswered += sequence.numIncorrectResponses + sequence.numCorrectResponses
  })

  return {
    questionsCompleted,
    instructionsCompleted,
    questionsAnswered,
  }
}
