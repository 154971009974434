import { Reducer } from 'redux'

import { VIDEO_PLAY, VIDEO_STOP, VideoPlayAction, VideoStopAction } from './video-actions'

export type VideoState = {
  playingClipId?: string
}

const initialState = {
  playingClipId: undefined,
}

export const getPlayingClipId = (state: any): string | undefined => {
  return state.video && state.video.playingClipId
}

const VideoReducer = (
  state: VideoState = initialState,
  action: VideoPlayAction | VideoStopAction
): VideoState => {
  switch (action.type) {
    case VIDEO_PLAY:
      return {
        playingClipId: action.clipId,
      }
    case VIDEO_STOP:
      // Remove this video's clipId from playingClipId if it is present
      return {
        playingClipId: state.playingClipId === action.clipId ? undefined : state.playingClipId,
      }
    default:
      return state
  }
}

export default VideoReducer as Reducer<VideoState>
