import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { ApolloError, ApolloQueryResult } from '@apollo/client'
import { isEmpty } from 'lodash'
import * as GET_ASSESSMENT_ATTEMPTS from './get-assessment-attempts.gql'

export interface ChildrenProps {
  attemptsByUser: GQL.EnrollmentAttemptsForUserAndAssessment[]
  loading?: boolean
  error?: ApolloError
  onLoadMore: () => Promise<ApolloQueryResult<GQL.GetAssessmentAttempts.Query>>
}

type Props = GQL.QueryGetAttemptsForAssessmentByUserIdArgs & {
  children: (props: ChildrenProps) => JSX.Element
  skip?: boolean
}

export const GetAssessmentAttempts: React.FunctionComponent<Props> = ({
  assessmentId,
  skip,
  count,
  after,
  loadAll,
  children,
}) => (
  <Query<GQL.GetAssessmentAttempts.Query, GQL.GetAssessmentAttempts.Variables>
    query={GET_ASSESSMENT_ATTEMPTS}
    variables={{ assessmentId, count, after, loadAll }}
    fetchPolicy="cache-and-network" // TODO ALPACA-759
    nextFetchPolicy="cache-first"
    skip={skip}
  >
    {({ data, loading, error, fetchMore }) => {
      return children({
        attemptsByUser: isEmpty(data) ? [] : data.getAttemptsForAssessmentByUserId.results,
        loading,
        error,
        onLoadMore:
          isEmpty(data) || !data.getAttemptsForAssessmentByUserId.after
            ? null
            : () =>
                fetchMore({
                  variables: {
                    count,
                    after: data.getAttemptsForAssessmentByUserId.after,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    return {
                      ...prev,
                      attemptsByUser: {
                        ...prev.getAttemptsForAssessmentByUserId,
                        after: fetchMoreResult.getAttemptsForAssessmentByUserId.after,
                        results: [
                          ...prev.getAttemptsForAssessmentByUserId.results,
                          ...fetchMoreResult.getAttemptsForAssessmentByUserId.results,
                        ],
                      },
                    }
                  },
                }),
      })
    }}
  </Query>
)

GetAssessmentAttempts.displayName = 'GetAssessmentAttempts'

export default GetAssessmentAttempts
