import { Reducer, Action } from 'redux'
import {
  API_LEARNOSITY_CREDENTIALS,
  SET_LEARNOSITY_IS_VALID,
  SET_LEARNOSITY_DATA,
  PUT_LEARNOSITY_IS_READY,
  SET_LEARNOSITY_CREDENTIALS_FAIL,
} from './learnosity-actions'

export type FETCHING_CREDENTIALS_STATES = 'INIT' | 'LOADING' | 'SUCCESS' | 'ERROR'

export interface ILearnosityReducerState {
  learnosityCredentials: object
  fetchingCredentialsState: FETCHING_CREDENTIALS_STATES
  isValidLearnosity: boolean
  learnosityData: any
  isLearnosityReady: boolean
}

const DEFAULT_LEARNOSITY_REDUCER_STATE: ILearnosityReducerState = {
  learnosityCredentials: null,
  fetchingCredentialsState: 'INIT',
  isValidLearnosity: false,
  learnosityData: null,
  isLearnosityReady: false,
}

// selectors

export const getLearnosityState = (state: any): ILearnosityReducerState => {
  return state.global && state.global.ui && state.global.ui.learnosity
}

export const getLearnosityData = (state: any): any => {
  return (
    state.global &&
    state.global.ui &&
    state.global.ui.learnosity &&
    state.global.ui.learnosity.learnosityData
  )
}

export const getLearnosityCredentials = (state: any): any => {
  return (
    state.global &&
    state.global.ui &&
    state.global.ui.learnosity &&
    state.global.ui.learnosity.learnosityCredentials
  )
}

export const getLearnosityIsValid = (state: any): boolean => {
  return (
    state.global &&
    state.global.ui &&
    state.global.ui.learnosity &&
    state.global.ui.learnosity.isValidLearnosity
  )
}

export const getLearnosityIsReady = (state: any): boolean => {
  return (
    state.global &&
    state.global.ui &&
    state.global.ui.learnosity &&
    state.global.ui.learnosity.isLearnosityReady
  )
}

const LearnosityReducer = (
  state: ILearnosityReducerState = DEFAULT_LEARNOSITY_REDUCER_STATE,
  action: Action & any = null
): ILearnosityReducerState => {
  switch (action.type) {
    case API_LEARNOSITY_CREDENTIALS.INIT:
      return {
        ...state,
        fetchingCredentialsState: 'LOADING',
      }
    case API_LEARNOSITY_CREDENTIALS.SUCCESS:
      return {
        ...state,
        learnosityCredentials: action.response,
        fetchingCredentialsState: 'SUCCESS',
      }
    // Do not change state on error and cancel api actions, handle independently
    case API_LEARNOSITY_CREDENTIALS.ERROR:
    case API_LEARNOSITY_CREDENTIALS.CANCEL:
      return {
        ...state,
      }
    case SET_LEARNOSITY_CREDENTIALS_FAIL:
      return {
        ...state,
        fetchingCredentialsState: 'ERROR',
      }
    case SET_LEARNOSITY_IS_VALID:
      return {
        ...state,
        isValidLearnosity: action.isValid,
      }
    case PUT_LEARNOSITY_IS_READY:
      return {
        ...state,
        isLearnosityReady: action.ready,
      }
    case SET_LEARNOSITY_DATA:
      const dataForSequenceId = { [action.sequenceId]: action.learnosityData }
      return {
        ...state,
        learnosityData: dataForSequenceId,
      }
    default:
      return state
  }
}

export default LearnosityReducer as Reducer<ILearnosityReducerState>
