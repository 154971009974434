import * as _ from 'lodash'
import { Permission } from 'app/helpers/common/permissions'

const EMPLOYEE_EMAIL_SUFFIX = ['@knewton.com', '@wiley.com']

/**
 * Checks if the current user has a given permission.
 */
export function hasPermission(permissionName: Commons.PermissionName) {
  const currentUser = get()
  if (currentUser) {
    return _.find(currentUser.permissions, { name: permissionName }) !== undefined
  }
  return false
}

/**
 * Check if the user has any of the given permissions.
 */
export function hasAnyPermission(permissionNames: Commons.PermissionName[]) {
  return _.some(permissionNames, hasPermission)
}

export function canViewTeachAndLearn(): boolean {
  return hasAnyPermission([Permission.TEACH_LEARN_VIEW, Permission.KNERD_TOOLS_VIEW])
}

export function canViewKnerdTools(): boolean {
  return hasAnyPermission([Permission.KNERD_TOOLS_VIEW])
}

/**
 * Returns the id of the current user.
 */
export function getId(): string | undefined {
  const user = get()
  return user && user.id
}

/**
 * Get the user's first name, and fallback to the email address.
 */
export function getNameForUi(): string | undefined {
  const user = get()
  return user && (user.firstName || user.email)
}

/**
 * Get the user's email address.
 */
export function getEmail(): string | undefined {
  const user = get()
  return user && user.email
}

/**
 * Checks if a current user is set (user is logged in).
 */
export function isSet(): boolean {
  return !!get()
}

/**
 * Checks if the user is a learner.
 */
export function isLearner(): boolean {
  const user = get()
  return (user && user.roleForUi === 'LEARNER') || false
}

export function isDistributor(): boolean {
  const user = get()
  return (user && user.roleForUi === 'DISTRIBUTOR') || false
}

/**
 * Returns the user that logged in as the current user
 */
export function getLoginAsOriginalUser(): Commons.IUser | undefined {
  return window.context && window.context.originalUser
}

export function isLoginAs(): boolean {
  return !!getLoginAsOriginalUser()
}

/**
 * Gets the current user.
 */
export function get(): Commons.IUser | undefined {
  return window.context && window.context.user
}

/**
 * Gets the current user institutions.
 */
export function getUserInstitutions(): Commons.IInstitution[] {
  const user = get()
  return (user && user.institutions) || []
}

/**
 * Checks if current user is a test user.
 */
export function isTestUser(): boolean {
  const user = get()
  return (user && user.testing) || false
}

/**
 * Returns true if the user in the institution with the given name.
 */
export function isInInstitution(institutionName: string): boolean {
  const institutions = getUserInstitutions()
  return !!_.find(institutions, { name: institutionName })
}

/**
 * Returns true if the user in any institution outside of the default.
 */
export function isInNonDefaultInstitution(): boolean {
  const institutions = getUserInstitutions()
  return !!_.find(institutions, i => i.name !== 'k_12')
}

/**
 * Returns true if the user is a nightwatch test user (see base.jsx)
 *
 */
export function isNightwatchUser(): boolean {
  const user = get()
  return (user && user.isNightwatch) || false
}

export function isInstitutionAdmin(): boolean {
  return !!getAdminInstitutionId()
}

export function getInstitutionById(id: string): Commons.IInstitution {
  const institutions = getUserInstitutions()
  return _.find(institutions, i => i.id === id)
}

export function getAdminInstitutionId(): string {
  const user = get()
  return user && user.adminInstitutionId
}

export function isVerifiedEmployee(): boolean {
  const user = get()
  return (
    (user &&
      user.emailVerified &&
      EMPLOYEE_EMAIL_SUFFIX.some(suffix => user.email.endsWith(suffix))) ||
    false
  )
}
