import { QueryHookOptions, useQuery } from '@apollo/client'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import * as GET_CUSTOM_ITEM_VARIATION_SEQUENCE from './get-custom-item-variation-sequence.gql'

export type UseCustomSequenceResults = RenderProps<GQL.GetCustomItemVariationSequence.Query>

export const useCustomItemVariationSequence = (
  sequenceId: string,
  opts?: QueryHookOptions<
    GQL.GetCustomItemVariationSequence.Query,
    GQL.GetCustomItemVariationSequence.Variables
  >
): UseCustomSequenceResults => {
  const { loading, error, data } = useQuery<
    GQL.GetCustomItemVariationSequence.Query,
    GQL.GetCustomItemVariationSequence.Variables
  >(GET_CUSTOM_ITEM_VARIATION_SEQUENCE, {
    variables: {
      sequenceId,
    },
    ...opts,
  })

  return {
    loading,
    error,
    ...data,
  }
}
