import * as React from 'react'
import { Field } from 'redux-form'
import * as classnames from 'classnames'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as Feature from 'app/frontend/helpers/feature'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/option-boxes.css'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { AssessmentLabel } from 'app/typings/commons'
import { RadioGroup } from 'app/frontend/components/radio-group'
const t = tns('teach:assessment_builder')

type Props = {
  className?: string
  disabled?: boolean
}

export class SelectLabelInput extends React.Component<Props, {}> {
  renderLabel = (field: WrappedFieldPropsStub & { disabled?: boolean }) => {
    const radioGroupLabel = (
      <>
        <Paragraph margin="none" weight="semibold">
          {t('select_label')}
        </Paragraph>
        <Paragraph size="small" margin="small">
          {t('select_label_body')}
        </Paragraph>
      </>
    )

    const options = [
      { label: t('label_test'), value: AssessmentLabel.TEST },
      { label: t('label_quiz'), value: AssessmentLabel.QUIZ },
    ]

    // add Homework as the first option
    if (Feature.isEnabled('homeworkAssessmentLabelFlag')) {
      options.unshift({ label: t('label_homework'), value: AssessmentLabel.HOMEWORK })
    }

    return (
      <RadioGroup
        name="label"
        label={radioGroupLabel}
        disabled={field.disabled}
        radioGroupProps={{
          onChange: field.input.onChange,
          value: field.input.value,
        }}
        radioProps={options}
        dataBi="select-label-input"
      />
    )
  }

  render() {
    const { className, disabled } = this.props
    return (
      <Box
        full="page"
        className={classnames(styles.assessmentOptions, className)}
        data-test="select-label-setting"
      >
        <Box full="page" pad="medium">
          <Field name="label" component={this.renderLabel} disabled={disabled} />
        </Box>
      </Box>
    )
  }
}

export default SelectLabelInput
