import { Reducer } from 'redux'
import { CourseworkActions } from './coursework-actions'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { SortOrder } from 'app/frontend/data/analytics-data'

export interface StudentAssignmentUIState {
  masterySortOrder: SortOrder.ASC | SortOrder.DESC
}

export const getStudentAssignmentUI = (state: TeachControllerState): StudentAssignmentUIState =>
  state.ui.course.coursework.studentAssignment
export const getStudentAssignmentMasterySortOrder = (
  state: TeachControllerState
): SortOrder.ASC | SortOrder.DESC => getStudentAssignmentUI(state).masterySortOrder

const defaultState: StudentAssignmentUIState = {
  masterySortOrder: SortOrder.ASC,
}

export function reducer(state: StudentAssignmentUIState = defaultState, action) {
  switch (action.type) {
    case CourseworkActions.SortStudentAssignmentMastery:
      const newOrder = action.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC

      return {
        ...state,
        masterySortOrder: newOrder,
      }
  }
  return state
}

export default reducer as Reducer<StudentAssignmentUIState>
