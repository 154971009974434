import * as React from 'react'
import * as classnames from 'classnames'
import * as styles from './placeholder.css'

interface Props {
  /* Size of the placeholder */
  width?: string
  height?: string
  marginTop?: string
  marginBottom?: string
  className?: string
}

/** This has to be a Component for Storybook */
export class PlaceholderText extends React.Component<Props, {}> {
  render(): JSX.Element {
    const { width = '10rem', height = '1rem', marginTop, marginBottom, className } = this.props

    return (
      <span
        data-test="placeholder-text"
        className={classnames(styles.placeholder, className)}
        style={{ maxWidth: width, height: height, marginBottom, marginTop }}
      />
    )
  }
}

export default PlaceholderText
