import * as React from 'react'
import { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Menu } from '@mui/material'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { QuestionViewPreferencesContent } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/question-view-preferences-content'
import { setQuestionViewPreferences } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/question-view-preferences-action'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_CHANGE_VIEW,
  TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_APPLY,
  TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_BY_ORDER_APPLY,
  TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_COLLAPSE_APPLY,
  TEACH_SECTION_ASSESSMENT_CHANGE_VIEW,
  TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_APPLY,
  TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_BY_ORDER_APPLY,
  TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_COLLAPSE_APPLY,
} from 'app/frontend/data/mixpanel-events'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import {
  QuestionViewPreferencesOptionsView,
  QuestionViewPreferencesOptionsViewDensity,
  QuestionViewPreferencesStatus,
} from './helper'
import { useStyles } from './question-view-preferences-styles'

const t = tns('teach:assessment_question_view_preferences')

export interface OwnProps {
  assessmentId: string
  parentEntityType: Commons.ParentEntityType
}

interface DispatchProps {
  updateQuestionViewPreferences: (
    viewBy: QuestionViewPreferencesOptionsView,
    displayDensity: QuestionViewPreferencesOptionsViewDensity
  ) => void
}

interface StateProps {
  viewBy: QuestionViewPreferencesOptionsView
  displayDensity: QuestionViewPreferencesOptionsViewDensity
  preferencesStatus: QuestionViewPreferencesStatus
}

type Props = StateProps & OwnProps & DispatchProps

export const _QuestionViewPreferences: React.FunctionComponent<Props> = ({
  assessmentId,
  viewBy,
  displayDensity,
  updateQuestionViewPreferences,
  preferencesStatus,
  parentEntityType,
}) => {
  const styles = useStyles()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const anchorRef = useRef<Element>()

  const onOpenMenu = () => {
    setMenuOpen(true)
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_CHANGE_VIEW
        : TEACH_SECTION_ASSESSMENT_CHANGE_VIEW,
      assessmentId
    )
  }

  const onQuestionPreferencesUpdate = (
    preferencesViewBy: QuestionViewPreferencesOptionsView,
    preferencesDensity: QuestionViewPreferencesOptionsViewDensity
  ) => {
    // check to send a common event when display density is not collapsed or view by is not by_order
    if (
      preferencesDensity !== QuestionViewPreferencesOptionsViewDensity.COLLAPSED ||
      preferencesViewBy !== QuestionViewPreferencesOptionsView.BY_ORDER
    ) {
      // send mixpanel event
      sendEventTeachAssessment(
        parentEntityType === ParentEntityType.Course
          ? TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_APPLY
          : TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_APPLY,
        assessmentId
      )
    }
    // check view by order
    if (preferencesViewBy === QuestionViewPreferencesOptionsView.BY_ORDER) {
      // send view by order mixpanel event
      sendEventTeachAssessment(
        parentEntityType === ParentEntityType.Course
          ? TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_BY_ORDER_APPLY
          : TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_BY_ORDER_APPLY,
        assessmentId
      )
    }
    // check preference density
    if (preferencesDensity === QuestionViewPreferencesOptionsViewDensity.COLLAPSED) {
      // collapsed mode mixpanel event
      sendEventTeachAssessment(
        parentEntityType === ParentEntityType.Course
          ? TEACH_COURSE_ASSESSMENT_CHANGE_VIEW_COLLAPSE_APPLY
          : TEACH_SECTION_ASSESSMENT_CHANGE_VIEW_COLLAPSE_APPLY,
        assessmentId
      )
    }
    // update question preferences
    updateQuestionViewPreferences(preferencesViewBy, preferencesDensity)
  }

  return (
    <>
      <ButtonMaterial
        label={
          viewBy === QuestionViewPreferencesOptionsView.BY_ORDER
            ? t('view_by_order')
            : t('view_by_objective')
        }
        id={assessmentId}
        data-bi="question-view-order-preference"
        theme={preferencesStatus === QuestionViewPreferencesStatus.APPLIED ? 'primary' : 'bordered'}
        onClick={onOpenMenu}
        aria-haspopup="true"
        setButtonRef={el => (anchorRef.current = el as HTMLButtonElement)}
      />
      <Menu
        open={menuOpen}
        anchorEl={anchorRef.current}
        onClose={() => setMenuOpen(false)}
        className={styles.customMenu}
        PaperProps={{
          style: {
            width: '19rem',
          },
        }}
      >
        <QuestionViewPreferencesContent
          viewBy={viewBy}
          displayDensity={displayDensity}
          updateQuestionViewPreferences={onQuestionPreferencesUpdate}
          onRequestClose={() => setMenuOpen(false)}
        />
      </Menu>
    </>
  )
}

/**
 * Dispatching SetQuestionViewOrderPreferences action to the store
 */
function mapDispatchToProps(dispatch): DispatchProps {
  return {
    updateQuestionViewPreferences: (
      viewBy: QuestionViewPreferencesOptionsView,
      displayDensity: QuestionViewPreferencesOptionsViewDensity
    ) => dispatch(setQuestionViewPreferences(viewBy, displayDensity)),
  }
}

/**
 * Map redux properties/states as a component props
 * @param state assessment builder state in the redux layer
 */
function mapStateToProps(state: TeachControllerState): StateProps {
  const { questionViewPreferences } = state.ui && state.ui.assessmentBuilder

  return {
    viewBy: questionViewPreferences.viewBy,
    displayDensity: questionViewPreferences.displayDensity,
    preferencesStatus: questionViewPreferences.status,
  }
}

/**
 * Connect QuestionViewOrderPreferences component to redux store
 * with props
 */
export const QuestionViewPreferences = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(_QuestionViewPreferences)

QuestionViewPreferences.displayName = 'QuestionViewPreferences'
