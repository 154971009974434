import { palette } from 'app/frontend/theme/palette'

export const overrides = {
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        body: {
          fontSize: '1rem',
          lineHeight: 'initial',
          backgroundColor: '#F8F8F8',
        },
        '.MuiBox-root': {
          borderColor: palette.grey['100'],
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: palette.grey['500'],
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#000000',
        margin: '0 .8rem',
        padding: 0,
        '&.Mui-checked': {
          borderColor: palette.primary.main,
        },
      },
    },
    defaultProps: {
      'data-bi': 'checkbox',
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'flex-start',
        height: 'auto',
        padding: '.75rem 1rem',
        cursor: 'pointer',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationThickness: '0.1rem',
        textDecorationColor: 'inherit',
        textUnderlineOffset: '0.3rem',
      },
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '.MuiSwitch-thumb': {
          borderColor: palette.grey['500'],
          borderWidth: 1,
          borderStyle: 'solid',
        },
        '.MuiSwitch-track': {
          opacity: 0.5,
        },
        '.Mui-checked .MuiSwitch-thumb': {
          borderStyle: 'none',
        },
      },
    },
  },
}
