export { Link } from './link'

/**
 * This is equivalent to using the following in a stylesheet:
 *   composes: textLinkFocus from 'app/frontend/layout/material/material-base.css'
 *
 * If you need to override any properties, you can either add a styleProp or compose this with
 * another via className.
 *
 * The props are callbacks so callers don't need to `useTheme()` in their
 * components and can declare the callbacks near other useStyles declarations in the module.
 */
export {
  default as UnderliningLink,
  useStyles as useUnderliningLinkStyles,
} from './underlining-link'
export type { StyleProps as UnderliningLinkStyleProps } from './underlining-link'
