import * as React from 'react'
import { useSelector } from 'react-redux'
import * as _ from 'lodash'
import { GoalUpdateSnackbar } from 'app/frontend/components/material/goal-update-snackbar/goal-update-snackbar'
import {
  getGoalUpdateSnackbarState,
  GoalUpdateSnackbarReducerState,
} from './goal-update-snackbar-reducer'

const GoalUpdateSnackbarContainer: React.FunctionComponent = () => {
  const {
    userId,
    updatedAfter,
    lastShownAt,
    courseId,
  } = useSelector((state: GoalUpdateSnackbarReducerState) => getGoalUpdateSnackbarState(state))

  if ((!userId && !courseId) || _.isNil(updatedAfter) || _.isNil(lastShownAt)) {
    return null
  }

  return <GoalUpdateSnackbar />
}

export default GoalUpdateSnackbarContainer
