import * as React from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import * as TimePicker from 'rc-time-picker'
import { connect } from 'react-redux'
import { Checkbox } from 'app/frontend/components/material/checkbox'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Field, formValueSelector, change } from 'redux-form'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/settings-modal.css'
import { tns } from 'app/frontend/helpers/translations/i18n'
import {
  FieldErrorMessage,
  WrappedFieldPropsStub,
} from 'app/frontend/compositions/data/redux-form-fields'
import './rc-time-picker.css'
import { FormNames, ASSESSMENT_SETTINGS_FORM, MaxDurationSettingType } from '../helper'

const t = tns('teach:assessment_builder')

const DURATION_FORMAT = 'H[hr] m[min]'
export const DEFAULT_DURATION = moment({ hour: 2 })

interface OwnProps {
  isReadOnly: boolean
}

interface StateProps {
  maxDuration: MaxDurationSettingType
}

interface DispatchProps {
  setMaxDuration: (maxDuration: MaxDurationSettingType) => void
}

interface State {
  isTimerEnabled: boolean
}

type Props = OwnProps & StateProps & DispatchProps

export class _TimedAssessmentSetting extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const isTimerEnabled = props.maxDuration !== null
    this.state = { isTimerEnabled }
  }

  toggleMaxDuration = () => {
    const { setMaxDuration } = this.props

    const { isTimerEnabled } = this.state
    const newDuration = isTimerEnabled ? null : DEFAULT_DURATION
    this.setState({
      isTimerEnabled: !this.state.isTimerEnabled,
    })
    setMaxDuration(newDuration)
  }

  renderTimer = () => (field: WrappedFieldPropsStub) => {
    return (
      <TimePicker
        disabled={this.props.isReadOnly}
        showSecond={false}
        disabledHours={() => _.range(12, 24)}
        disabledMinutes={() => _.pullAll(_.range(0, 60), _.range(0, 60, 5))}
        value={field.input.value}
        onChange={field.input.onChange}
        format={DURATION_FORMAT}
        hideDisabledOptions={true}
      />
    )
  }

  render() {
    const { isReadOnly: isReadOnly } = this.props
    const { isTimerEnabled } = this.state

    return (
      <Box full="page" separator="top" pad="medium" data-test="timer-setting">
        <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
          <Paragraph margin="none" weight="semibold" id="settings-enabled-timer-header">
            {t('settings_enable_timer_header')}
          </Paragraph>
          <Checkbox
            onChange={this.toggleMaxDuration}
            className={styles.checkboxPadding}
            disabled={isReadOnly}
            checked={isTimerEnabled}
            inputProps={{
              'aria-labelledby': 'settings-enabled-timer-header',
              'aria-describedby': 'settings-enable-timer-description',
            }}
          />
        </Box>
        <Paragraph size="small" margin="small" id="settings-enable-timer-description">
          {t('settings_enable_timer_description')}
        </Paragraph>
        {isTimerEnabled && (
          <>
            <Box margin={{ bottom: 'small' }}>
              <Field name={FormNames.MAX_DURATION} component={this.renderTimer()} />
            </Box>
            {/* Only try to show the error message if selected time is lower than 5 minutes */}
            <FieldErrorMessage name={FormNames.TIMER} />
          </>
        )}
      </Box>
    )
  }
}

const mapStateToProps = (state: any): StateProps => {
  const selector = formValueSelector(ASSESSMENT_SETTINGS_FORM)
  return {
    maxDuration: selector(state, FormNames.MAX_DURATION),
  }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    setMaxDuration: (maxDuration: MaxDurationSettingType) => {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.MAX_DURATION, maxDuration))
    },
  }
}

export const TimedAssessmentSetting = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(_TimedAssessmentSetting)
