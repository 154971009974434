import * as React from 'react'
import { omit, values } from 'lodash'
import { AssessmentTableOfContentsFilterWrapper } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-table-of-contents-filter-wrapper'
import { GetAssessmentTableOfContentsData } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/get-assessment-table-of-contents-data'
import TextSpinner from 'app/frontend/components/material/loading/spinner-text'
import { useAssessedConcepts } from '../../compositions/connected/get-assessed-concepts'

export interface OwnProps {
  coursepackId: string
  assessmentId: string
  parentEntityType: Commons.ParentEntityType
  parentEntityId: string
}

type Props = OwnProps

export const AssessmentTableOfContentsLoader: React.FunctionComponent<Props> = ({
  coursepackId,
  assessmentId,
  parentEntityType,
  parentEntityId,
}) => {
  const { loading, error, assessedConceptsByLo: assessedConcepts } = useAssessedConcepts(
    parentEntityId,
    parentEntityType
  )
  const initialLoading = loading && !assessedConcepts
  const initialError = error
  const assessedConceptsByLo = assessedConcepts

  return (
    <GetAssessmentTableOfContentsData
      parentEntityType={parentEntityType}
      parentEntityId={parentEntityId}
      assessmentId={assessmentId}
      coursepackId={coursepackId}
    >
      {results => {
        if (
          results.loading ||
          results.error ||
          initialLoading ||
          initialError ||
          values(omit(results, 'error', 'loading')).some(
            value => value === undefined || value === null
          )
        ) {
          return (
            <>
              <TextSpinner />
              <TextSpinner />
              <TextSpinner />
            </>
          )
        }

        return (
          <AssessmentTableOfContentsFilterWrapper
            {...results}
            assessedConceptsByLo={assessedConceptsByLo}
          />
        )
      }}
    </GetAssessmentTableOfContentsData>
  )
}

AssessmentTableOfContentsLoader.displayName = 'AssessmentTableOfContentsLoader'
