import * as React from 'react'
import { Heading } from 'app/frontend/components/material/heading'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import ToggleButtonGroup from 'app/frontend/components/material/toggle-button-group/toggle-button-group'
import {
  isAllFilterSelected,
  getFilterGroupTranslationText,
  getFilterTranslationText,
} from './helper'
import {
  FilterOption,
  AssignmentUsageFilter,
  AssessmentUsageFilter,
  SelectionFilter,
  FilterGroups,
} from 'app/typings/commons'

const t = tns('teach:assessment_lo_selection_modal')

export interface Props {
  filterGroup: FilterGroups
  selectedFilter: AssessmentUsageFilter | AssignmentUsageFilter | SelectionFilter
  filterOptions: FilterOption[]
  onApply: (selectedFilterName: string, filterGroup: string) => void
  onApplyFilters: (filter: AssessmentUsageFilter | AssignmentUsageFilter | SelectionFilter) => void
  onRequestClose?: () => void
}

export const FilterGroup: React.FunctionComponent<Props> = ({
  filterGroup,
  onApply,
  onRequestClose,
  onApplyFilters,
  filterOptions,
  selectedFilter,
}) => {
  const [filter, setFilter] = React.useState(selectedFilter)

  const handleToggle = (
    selectedFilterVal: AssignmentUsageFilter | AssessmentUsageFilter | SelectionFilter
  ) => {
    setFilter(selectedFilterVal)
  }

  const onClickApply = () => {
    onApplyFilters(filter)
    onApply(filter, filterGroup)
    onRequestClose()
  }

  const getFilterDesc = (): JSX.Element => {
    // If 'All' selected, we display the desc with filter group name. Otherwise, show it with selected filter name
    if (isAllFilterSelected(filter)) {
      return (
        <>
          {t('filter_all_desc', {
            filterGroup: getFilterGroupTranslationText(filterGroup).toLowerCase(),
          })}
        </>
      )
    } else if (filter === SelectionFilter.NotSelected) {
      return (
        <>
          {t('filter_not_selected_desc1')}
          <b>{getFilterTranslationText(filter).toLowerCase()}</b>
          {t('filter_not_selected_desc2')}
        </>
      )
    }
    return (
      <>
        {t('filter_type_desc1')}
        <b>{getFilterTranslationText(filter).toLowerCase()}</b>
        {t('filter_type_desc2')}
      </>
    )
  }

  return (
    <Box>
      <Heading
        tag="h5"
        margin={{ vertical: 'none' }}
        pad={{ top: 'small', horizontal: 'medium' }}
        size="h5"
      >
        {getFilterGroupTranslationText(filterGroup)}
      </Heading>
      <Box margin={{ vertical: 'small', horizontal: 'medium' }}>
        <ToggleButtonGroup
          name={filterGroup}
          options={filterOptions}
          selectedOption={filter}
          onSelectionChange={handleToggle}
        />
        <Paragraph id="description" size="small" margin="small">
          {getFilterDesc()}
        </Paragraph>
      </Box>
      <Box
        direction={'row'}
        alignSelf="end"
        responsive={true}
        margin="medium"
        pad={{ between: 'medium' }}
      >
        <ButtonMaterial
          label={t('label_cancel')}
          theme={'bordered'}
          onClick={onRequestClose}
          data-bi="cancel-button"
        />
        <ButtonMaterial
          label={t('label_apply')}
          theme={'primary'}
          onClick={onClickApply}
          data-bi="apply-button"
        />
      </Box>
    </Box>
  )
}

FilterGroup.displayName = 'FilterGroup'
