import { Action, Reducer } from 'redux'
import * as _ from 'lodash'

import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_CREATE_COURSE,
  API_DELETE_COURSE,
  API_GET_ALL_COURSES,
  API_GET_COURSE,
  API_GET_COURSE_DATA,
} from 'app/frontend/pages/teach/entities/courses/course-actions'

export type CoursesReducerById = {
  [id: string]: Commons.IRetailClassLean
}

export const reducer = (
  state: CoursesReducerById = {},
  action: Action & ApiSuccessAction
): CoursesReducerById | {} => {
  let courseId
  switch (action.type) {
    case API_CREATE_COURSE.SUCCESS:
      return {
        ...state,
        [action.response.id]: action.response,
      }
    case API_GET_COURSE.SUCCESS:
      return {
        ...state,
        [action.response.id]: action.response,
      }
    case API_DELETE_COURSE.SUCCESS:
      courseId = action.args[0]
      return _.omit(state, courseId)
    case API_GET_ALL_COURSES.SUCCESS:
      const courses: Commons.IRetailClassLean[] = action.response
      const coursesByKey: CoursesReducerById = _.keyBy(courses, 'id')
      return {
        ...state,
        ...coursesByKey,
      }
    case API_GET_COURSE_DATA.INIT:
      courseId = action.args[0]
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          isLoadingCourseData: true,
        },
      }
    case API_GET_COURSE_DATA.ERROR:
    case API_GET_COURSE_DATA.CANCEL:
      courseId = action.args[0]
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          isLoadingCourseData: false,
        },
      }
    case API_GET_COURSE_DATA.SUCCESS:
      courseId = action.args[0]
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          isLoadingCourseData: false,
          assignmentIds: _.keys(action.response.assignments),
          boosterIds: _.keys(action.response.boosters),
          fullData: true,
        },
      }
    default:
      return state
  }
}

export default reducer as Reducer<CoursesReducerById>
