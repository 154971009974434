import * as React from 'react'
import { Tooltip as TooltipCore, TooltipProps as TooltipCoreProps, Theme } from '@mui/material'
import { WithStyles, createStyles, makeStyles } from '@mui/styles'
import * as classnames from 'classnames'

export type TooltipWidth = 'small' | 'medium' | 'large' | 'auto'

interface OwnProps extends TooltipStyles {
  disabled?: boolean
  textAlign?: 'left' | 'center' | 'right'
  width?: TooltipWidth
  children?: string | React.ReactElement<any, any> | React.ReactNode
}

type Props = OwnProps & Omit<TooltipCoreProps, 'children'>

const tooltipStyles = (theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 11000,
    },
    tooltip: {
      fontSize: '0.75rem',
      backgroundColor: theme.palette?.grey[600],
      lineHeight: '1.5',
      textAlign: (props: Partial<Props>) => props?.textAlign ?? 'left',
      // @TODO: Test these widths with all tooltips and tweak it accordingly.
      width: ({ width }: Partial<Props>) => {
        if (width === 'small') {
          return '6rem'
        } else if (width === 'medium') {
          return '8rem'
        } else if (width === 'large') {
          return 'fit-content'
        }
        return 'auto'
      },
    },
    wrapper: {
      display: 'inline-block',
    },
  })

const useStyles = makeStyles(tooltipStyles)
export type TooltipStyles = Partial<WithStyles<typeof tooltipStyles>>

export const Tooltip: React.FunctionComponent<Props> = ({
  title,
  children,
  disabled,
  textAlign,
  width,
  classes = {},
  ...rest
}) => {
  const styles = useStyles({ textAlign, width })

  // When the you do not have a wrapping div,
  // the children receives onMouseOver and onMouseLeave as part of its props.
  // With this change, the div recieves them
  return (
    <TooltipCore
      title={title}
      arrow={true}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      {...rest}
      classes={{
        tooltip: classnames(styles.tooltip, classes?.tooltip),
        popper: styles.popper,
      }}
    >
      <span className={classnames(styles.wrapper, classes?.wrapper)}>{children}</span>
    </TooltipCore>
  )
}

Tooltip.displayName = 'Tooltip'
