export enum QuestionPreferencesAction {
  SetQuestionPreferences = 'SET_QUESTION_PREFERENCES',
}

/**
 * Definition for setQuestionPreferencesAction
 */
export type setQuestionPreferencesAction = {
  type: QuestionPreferencesAction.SetQuestionPreferences
  questionType: string
  numOfQuestionPerLo: number
}

/**
 * Function that execute when dispatch setQuestionPreferencesAction
 * @param questionType - The parameter that holds question type
 * @param numOfQuestionPerLo - The parameter that holds number of question per LO
 */
export const setQuestionPreferences = (
  questionType: string,
  numOfQuestionPerLo: number
): setQuestionPreferencesAction => ({
  type: QuestionPreferencesAction.SetQuestionPreferences,
  questionType,
  numOfQuestionPerLo,
})
