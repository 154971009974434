import * as React from 'react'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
import { Query } from 'app/frontend/components/query'
import * as HAS_STUDENT_STARTED_ASSIGNMENT_QUERY from './has-student-started-assignment.gql'

export interface HasStudentStartedAssignmentRenderProps {
  hasStudentStartedAssignment?: boolean
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryHasStudentStartedAssignmentArgs & {
  children: (props: HasStudentStartedAssignmentRenderProps) => JSX.Element
}

export const HasStudentStartedAssignmentQuery: React.FunctionComponent<Props> = ({
  assignmentId,
  children,
}) => (
  <Query<GQL.HasStudentStartedAssignment.Query, GQL.HasStudentStartedAssignment.Variables>
    query={HAS_STUDENT_STARTED_ASSIGNMENT_QUERY}
    variables={{ assignmentId }}
  >
    {({ data, loading, error }) =>
      children({
        hasStudentStartedAssignment: isEmpty(data) ? null : data.hasStudentStartedAssignment,
        loading,
        error,
      })
    }
  </Query>
)

HasStudentStartedAssignmentQuery.displayName = 'HasStudentStartedAssignmentQuery'

export default HasStudentStartedAssignmentQuery
