import * as GET_PRESIGNED_URL from './get-presigned-url.gql'
import { useQuery, ApolloQueryResult } from '@apollo/client'

import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseGetPresignedUrlResults = RenderProps<{
  result: GQL.ScreenshotUrl
  refetch: (
    variables?: GQL.GetPreSignedUrl.Variables
  ) => Promise<ApolloQueryResult<GQL.GetPreSignedUrl.Query>>
}>

export const useGetPresignedUrl = (): UseGetPresignedUrlResults => {
  const { loading, error, data, refetch } = useQuery<
    GQL.GetPreSignedUrl.Query,
    GQL.GetPreSignedUrl.Variables
  >(GET_PRESIGNED_URL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first', // TODO ALPACA-759
    skip: true,
    context: { silenceErrors: true },
  })

  return {
    loading,
    error,
    result: data?.preSignedUrl,
    refetch,
  }
}
