import {
  START_DOWNLOAD_COMMON_CARTRIDGE,
  FINISH_DOWNLOAD_COMMON_CARTRIDGE,
} from 'app/frontend/pages/teach/ui/course/common-cartridge-actions.tsx'

export type CommonCartridgeReducerState = {
  isDownloading: boolean
}

export function commonCartridge(
  state: CommonCartridgeReducerState = { isDownloading: false },
  action
) {
  switch (action.type) {
    case START_DOWNLOAD_COMMON_CARTRIDGE:
      return { isDownloading: true }
    case FINISH_DOWNLOAD_COMMON_CARTRIDGE:
      return { isDownloading: false }
    default:
      return state
  }
}

export default commonCartridge
