import * as React from 'react'
import * as classnames from 'classnames'
import { FeedbackType } from 'app/helpers/common/content-types'

import * as styles from './feedback-emoji.css'

interface Props {
  category: FeedbackType
}

const FeedbackEmoji: React.FunctionComponent<Props> = ({ category }) => {
  switch (category) {
    case FeedbackType.CannotAnswer:
    case FeedbackType.HaveIdea:
    case FeedbackType.LooksBroken:
    case FeedbackType.NotRight:
      return <span className={classnames(styles.emoji, styles[category])} />
    default:
      return null
  }
}

export default FeedbackEmoji
