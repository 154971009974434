import ExtendableError from 'app/frontend/helpers/extendable-error'
import * as _ from 'lodash'
import CODES from 'app/frontend/api/codes'
import { fetch } from 'app/frontend/helpers/fetch'

const tabIdErrorOnce = _.once(console.error)

export class ApiError extends ExtendableError {
  json: object
  response: Response

  constructor(response: Response, json: object) {
    super('Error while submitting request')
    this.response = response
    this.json = json
  }
}

export class SessionExpiredError extends ApiError {
  public showSessionExpiredModal: boolean
  constructor(response: Response, json: object) {
    super(response, json)
    this.showSessionExpiredModal = true
  }
}

export class CSRFMismatchError extends ApiError {
  public csrfMismatch: boolean
  constructor(response: Response, json: object) {
    super(response, json)
    this.csrfMismatch = true
  }
}

/**
 * Get a unique identifier for the current browser tab. Tab IDs are monotonically increasing.
 * @returns {string}
 */
export function getTabId(): string {
  try {
    // sessionStorage is per-browser-tab and localStorage is per-browser.
    let tabId = window.sessionStorage.getItem('tabId')
    if (!_.isNil(tabId)) {
      return tabId
    }

    // reset the tab count if it gets too high.
    const tabCount = window.localStorage.getItem('tabCount') || '0'
    tabId = `${1 + (Number(tabCount) % 500)}`
    window.localStorage.setItem('tabCount', tabId)
    window.sessionStorage.setItem('tabId', tabId)

    // default to returning 0 if something's wrong with local storage.
    // this means we will only get an ID of 0 if local storage doesn't work.
    if (_.isNil(window.localStorage.getItem('tabCount'))) {
      return '0'
    }
    return tabId
  } catch (e) {
    // localStorage or sessionStorage is probably unavailable (if 3rd party cookies are disabled
    // and using in an iframe it will fail).
    // even tho the documentation doesn't say it, any access to localStorage/sessionStorage can
    // result in an exception.
    tabIdErrorOnce('Failed to get tab id', e)
    return '-1'
  }
}

export function get(url: string, options?: object): Promise<Response> {
  options = _.merge(
    {
      headers: {
        Accept: 'application/json',
      },
    },
    options
  )

  return fetchJsonWithThrow(url, options)
}

export function post(url: string, body?: object, options?: object): Promise<Response> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  options = _.merge(
    {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    },
    options
  )

  return fetchJsonWithThrow(url, options)
}

export function put(url: string, body?: object, options?: object): Promise<Response> {
  options = _.merge(
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    },
    options
  )

  return fetchJsonWithThrow(url, options)
}

export function deleteR(url: string, options?: object): Promise<Response> {
  options = _.merge(
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    },
    options
  )

  return fetchJsonWithThrow(url, options)
}

interface IFetchJsonProps {
  showSessionExpiredModal?: boolean
  recordBackendVersion?: boolean
}

function fetchJsonWithThrow(url: string, options?: IFetchJsonProps & any): Promise<Response> {
  options = _.merge(
    {
      credentials: 'include',
      showSessionExpiredModal: true,
      recordBackendVersion: true,
      headers: {
        'X-TAB-ID': getTabId(),
      },
    },
    options
  )

  return fetch(url, options)
    .then(response => {
      if (!response.ok) {
        const throwError = (json?: any) => {
          if (options.showSessionExpiredModal && response.status === 401) {
            throw new SessionExpiredError(response, json)
          } else if (response.status === 403 && json.code === CODES.CSRF_MISMATCH) {
            throw new CSRFMismatchError(response, json)
          }
          throw new ApiError(response, json)
        }
        return response
          .json()
          .catch(() => throwError())
          .then(json => throwError(json)) as Promise<Response>
      }
      const backendVersion = response.headers.get('X-Build-Num')
      // Don't override window.context.backendVersion if X-Build-Num is undefined
      if (options.recordBackendVersion && backendVersion) {
        window.context.backendVersion = backendVersion
      }
      return response
    })
    .then(response => {
      if (response.status !== 204) {
        return response.json()
      }
      return null
    })
}
