import { makeApiActions } from 'app/frontend/api/api-redux'

export enum SortBy {
  PreviousMastery = 'previous-mastery',
  CurrentMastery = 'current-mastery',
  LastWorkedOn = 'last-worked-on',
  OrderIndex = 'order-index',
}

export namespace SortBy {
  export const getDefault = (): SortBy => SortBy.PreviousMastery
}

export type ReviewCenterActions = SortReviewAssignmentsAction

export enum ReviewCenterActionTypes {
  SortReviewAssignments = 'SORT_REVIEW_ASSIGNMENTS',
}

export type SortReviewAssignmentsAction = {
  type: ReviewCenterActionTypes.SortReviewAssignments
  sortBy: SortBy
}

export const sortReviewAssignments = (sortBy: SortBy): SortReviewAssignmentsAction => ({
  type: ReviewCenterActionTypes.SortReviewAssignments,
  sortBy,
})

export const API_GET_REVIEW_ASSIGNMENTS = makeApiActions('API_GET_REVIEW_ASSIGNMENTS')
