import * as React from 'react'
import { connect } from 'react-redux'
import { removeScreenreaderAlert } from './actions'
import * as _ from 'lodash'
import * as styles from './index.css'

interface ScreenreaderAlertStateProps {
  alerts: string[]
}
interface ScreenreaderAlertDispatchProps {
  removeAlert: () => void
}

type ScreenreaderAlertProps = ScreenreaderAlertStateProps & ScreenreaderAlertDispatchProps

export class ScreenreaderAlert extends React.Component<ScreenreaderAlertProps, {}> {
  // if we added an alert, set a timeout to remove it after its read
  componentDidUpdate(prevProps: ScreenreaderAlertProps): void {
    if (prevProps.alerts.length < this.props.alerts.length) {
      setTimeout(this.props.removeAlert, 5000)
    }
  }

  render(): JSX.Element {
    return (
      <div role="alert" aria-live="polite" aria-atomic="true" className={styles.alert}>
        {_.first(this.props.alerts)}
      </div>
    )
  }
}

const mapStateToProps = (state: any): ScreenreaderAlertStateProps => {
  return {
    alerts: state.global.ui.screenreaderAlerts,
  }
}

const mapDispatchToProps = (dispatch): ScreenreaderAlertDispatchProps => {
  return {
    removeAlert: (): void => dispatch(removeScreenreaderAlert()),
  }
}

export default connect<ScreenreaderAlertStateProps, ScreenreaderAlertDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(ScreenreaderAlert)
