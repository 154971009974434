import { Reducer } from 'redux'
import { API_GET_QUIZ } from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'

export type LdbLaunchUrlState = {
  ldbLaunchUrl: string
}

const defaultLdbLaunchUrlsState = {
  ldbLaunchUrl: null,
}

export function ldbLaunchUrlsReducer(
  state: LdbLaunchUrlState = defaultLdbLaunchUrlsState,
  action: ApiSuccessAction
) {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_QUIZ.SUCCESS:
      return {
        ldbLaunchUrl: action.response.ldbLaunchUrl,
      }
    default:
      return state
  }
}

export default ldbLaunchUrlsReducer as Reducer<LdbLaunchUrlState>
