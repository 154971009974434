import * as React from 'react'
import {
  AssessmentBuilderModalType,
  ModalComponent,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import { AssessmentLoSelectionModalFooter } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-lo-selection-modal-footer'
import { AssessmentLoSelectionModalBody } from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/assessment-lo-selection-modal-body'

/**
 * The assessment LO selection modal, which displays the TOC for the coursepack an allows the instructor
 * to choose the topics, learning objectives, and sub-LOs to include in their assessment, and to open
 * the question selection modal for any LO.
 */
export const AssessmentLoSelectionModal: React.FunctionComponent<{}> = () => {
  return (
    <ModalComponent
      name={AssessmentBuilderModalType.LoSelection}
      dataBi="assessment-lo-select-modal"
      fullScreen={true}
      belowDialog={props => <AssessmentLoSelectionModalFooter {...props} />}
      preventClose={true}
    >
      {AssessmentLoSelectionModalBody}
    </ModalComponent>
  )
}

AssessmentLoSelectionModal.displayName = 'AssessmentLoSelectionModal'
