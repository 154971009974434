import * as LtiConstants from 'app/frontend/data/lti-constants'
import { AnalyticsCategory } from 'app/frontend/data/analytics-data'
import { stringify } from 'app/helpers/query-string'
import { QueryStringKeys } from 'app/frontend/helpers/query-string-events'
import { LMSTypeForCartridge } from 'app/typings/commons'

export interface TeachRouteParams {
  assessmentId?: string
  assignmentId?: string
  copyToken?: string
  courseId?: string
  sectionId?: string
}

export const notFoundRoute = (): string => '/teach/not-found'

export const allCoursesRoute = (): string => '/teach/courses'

export const courseRoute = (courseId: string): string => `/teach/course/${courseId}`
export const courseSectionsRoute = (courseId: string): string =>
  `/teach/course/${courseId}/sections`
export const courseCourseworkRoute = (courseId: string): string =>
  `/teach/course/${courseId}/coursework`

export const courseSettingsRoute = (courseId: string): string =>
  `/teach/course/${courseId}/settings`
export const courseManageRoute = (courseId: string): string => `/teach/course/${courseId}/manage`

export const courseAssessmentRoute = (courseId: string, assessmentId: string) =>
  `/teach/course/${courseId}/assessment/${assessmentId}`

export const sectionRoute = (sectionId: string, assignmentType?: AnalyticsCategory): string => {
  const qs = assignmentType
    ? '?' +
      stringify({
        [QueryStringKeys.AssignmentType]: assignmentType,
      })
    : ''
  return `/teach/section/${sectionId}/coursework${qs}`
}
export const sectionStudentsRoute = (sectionId: string): string =>
  `/teach/section/${sectionId}/student`
export const sectionStudentRoute = (sectionId: string, studentId: string): string =>
  `/teach/section/${sectionId}/student/${studentId}`
export const enrollmentStudentRoute = (
  sectionId: string,
  assignmentId: string,
  studentId: string,
  tab?: 'objectives' | 'questions'
): string =>
  `/teach/section/${sectionId}/coursework/student/${studentId}/assignment/${assignmentId}${
    tab ? '?tab=' + tab : ''
  }`
export const enrollmentStudentActivityRoute = (
  sectionId: string,
  assignmentId: string,
  studentId: string,
  learningObjectiveId?: string
): string => {
  const qs = learningObjectiveId
    ? `?${stringify({
        loId: learningObjectiveId,
      })}`
    : ''
  return `/teach/section/${sectionId}/coursework/student/${studentId}/assignment/${assignmentId}/activity${qs}`
}

export const sectionSettingsRoute = (sectionId: string): string =>
  `/teach/section/${sectionId}/settings`
export const sectionManageRoute = (sectionId: string): string =>
  `/teach/section/${sectionId}/manage`

export const sectionAssessmentRoute = (sectionId: string, assessmentId: string) =>
  `/teach/section/${sectionId}/assessment/${assessmentId}`

export const catalogRoute = (): string => '/teach/browse/catalog'

export const assignmentAnalyticsRoute = (sectionId: string, assignmentId: string): string =>
  `/teach/section/${sectionId}/coursework/assignment/${assignmentId}`

export const assessmentAnalyticsTabRoute = (
  sectionId: string,
  assignmentId: string,
  tab: string
): string => `/teach/section/${sectionId}/coursework/assessment/${assignmentId}/${tab}`

export const enrollmentAssignmentRoute = (
  sectionId: string,
  assignmentId: string,
  studentId: string
): string =>
  `/teach/section/${sectionId}/coursework/assignment/${assignmentId}/student/${studentId}`
export const enrollmentAssignmentActivityRoute = (
  sectionId: string,
  assignmentId: string,
  studentId: string,
  learningObjectiveId?: string
): string => {
  const qs = learningObjectiveId
    ? `?${stringify({
        loId: learningObjectiveId,
      })}`
    : ''

  return `/teach/section/${sectionId}/coursework/assignment/${assignmentId}/student/${studentId}/activity${qs}`
}

export const enrollmentAssessmentRoute = (
  sectionId: string,
  assignmentId: string,
  studentId: string,
  tab?: 'objectives' | 'questions' | ':tab(objectives|questions)'
): string =>
  `/teach/section/${sectionId}/coursework/assessment/${assignmentId}/student/${studentId}${
    tab ? '?tab=' + tab : ''
  }`

export const enrollmentAssessmentActivityRoute = (
  sectionId: string,
  assignmentId: string,
  studentId: string,
  learningObjectiveId?: string
): string => {
  const qs = learningObjectiveId
    ? `?${stringify({
        loId: learningObjectiveId,
      })}`
    : ''

  return `/teach/section/${sectionId}/coursework/assessment/${assignmentId}/student/${studentId}/activity${qs}`
}

export const downloadSectionCsv = (sectionId: string, timezone: string, downloadToken: string) =>
  `/teach/${sectionId}/export-course-report-csv?timezone=${encodeURIComponent(
    timezone
  )}&downloadtoken=${downloadToken}`

export const ltiInitializationLink = (courseId: string) => {
  return `${LtiConstants.SECTION_LAUNCH}${courseId}`
}

export const ltiCourseReviewCenterLink = (courseId: string) => {
  return `${LtiConstants.COURSE_REVIEW_CENTER_LAUNCH}${courseId}`
}

export const getLMSCartridgeTypeForLMS = (lmsType: string): LMSTypeForCartridge => {
  switch (lmsType) {
    case 'BLACKBOARD':
      return LMSTypeForCartridge.BLACKBOARD
    case 'CANVAS':
      return LMSTypeForCartridge.CANVAS
    default:
      return LMSTypeForCartridge.IMS
  }
}

export const downloadCourseCartridge = (courseId: string, lmsType: string, downloadToken: string) =>
  `/teach/course/${courseId}/settings/download-common-cartridge/${getLMSCartridgeTypeForLMS(
    lmsType
  )}` + `?downloadtoken=${downloadToken}`

export const downloadSectionCartridge = (
  sectionId: string,
  lmsType: string,
  downloadToken: string
) =>
  `/teach/section/${sectionId}/settings/download-common-cartridge/` +
  getLMSCartridgeTypeForLMS(lmsType) +
  `?downloadtoken=${downloadToken}`

export const assignmentPreviewRoute = (assignmentId: string) =>
  `/assignment/${assignmentId}/create-preview-from-path`

export const copyCourseTimeoutRoute = (copyToken: string) =>
  `/teach/course/copy/${copyToken}/timeout`

export const printableTestsRoute = (
  assignmentId: string,
  parentEntityId: string,
  parentEntityType: Commons.ParentEntityType,
  options: {
    version?: string
    shuffleAnswers?: boolean
    shuffleQuestions?: boolean
    randomSeed?: string
    randomAtomVariationSeed?: number
  } = {}
) =>
  `/teach/${parentEntityType.toLowerCase()}/${parentEntityId}/test/${assignmentId}/print?version=${
    options.version
  }&shuffleQuestions=${!!options.shuffleQuestions}&randomSeed=${
    options.randomSeed
  }&randomAtomVariationSeed=${options.randomAtomVariationSeed}`

// Assessment analytics
export const assessmentAnalytics = (
  sectionId: string,
  assessmentId: string,
  tab?:
    | undefined
    | 'students'
    | 'learning-objectives'
    | 'questions'
    | ':tab(students|learning-objectives|questions)'
) => `/teach/section/${sectionId}/coursework/assessment/${assessmentId}${tab ? '/' + tab : ''}`

export const learningObjectiveReadiness = (
  sectionId: string,
  assessmentId: string,
  learningObjectiveId: string
): string =>
  `/teach/section/${sectionId}/coursework/assessment/${assessmentId}/lo-readiness/${learningObjectiveId}`
