export type ADD_SCREENREADER_ALERT = 'ADD_SCREENREADER_ALERT'
export const ADD_SCREENREADER_ALERT: ADD_SCREENREADER_ALERT = 'ADD_SCREENREADER_ALERT'
export type REMOVE_SCREENREADER_ALERT = 'REMOVE_SCREENREADER_ALERT'
export const REMOVE_SCREENREADER_ALERT: REMOVE_SCREENREADER_ALERT = 'REMOVE_SCREENREADER_ALERT'

export type AddScreenreaderAlertAction = {
  type: ADD_SCREENREADER_ALERT
  alert: string
}
export const addScreenreaderAlert = (alert: string): AddScreenreaderAlertAction => {
  return {
    type: ADD_SCREENREADER_ALERT,
    alert,
  }
}

export type RemoveScreenreaderAlertAction = {
  type: REMOVE_SCREENREADER_ALERT
}
export const removeScreenreaderAlert = (): RemoveScreenreaderAlertAction => {
  return {
    type: REMOVE_SCREENREADER_ALERT,
  }
}

export type AlertScreenreaderActions = AddScreenreaderAlertAction | RemoveScreenreaderAlertAction
