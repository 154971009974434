import * as React from 'react'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { ModalLegacy as Modal } from 'app/frontend/components/material/modal'
import ModalBody from 'app/frontend/components/material/modal/modal-body'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Heading } from 'app/frontend/components/material/heading'
import { Paragraph } from 'app/frontend/components/material/paragraph'

const t = tns('csrf_mismatch')

export const CSRF_MISMATCH_MODAL = 'CSRF_MISMATCH_MODAL'

export const CSRFMismatchModal: React.FunctionComponent<{}> = () => {
  return (
    <Modal name={CSRF_MISMATCH_MODAL} dataBi="csrf-mismatch-modal" preventClose={true}>
      <ModalBody>
        <Heading tag="h1" size="h2">
          {t('modal_title')}
        </Heading>
        <Paragraph>{t('modal_body')}</Paragraph>
      </ModalBody>
      <ModalFooter>
        <ButtonMaterial
          onClick={() => window.location.reload()}
          data-bi="csrf-refresh-page"
          label={t('refresh')}
        />
      </ModalFooter>
    </Modal>
  )
}

CSRFMismatchModal.displayName = 'CSRFMismatchModal'
