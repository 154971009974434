import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { Menu, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import * as DELETE_ASSESSMENT_SEQUENCE_VARIATION from 'app/frontend/pages/material/teach/compositions/connected/delete-assessment-sequence-variation/delete-assessment-sequence-variation.gql'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import {
  TEACH_COURSE_ASSESSMENT_REMOVE_QUESTION,
  TEACH_SECTION_ASSESSMENT_REMOVE_QUESTION,
} from 'app/frontend/data/mixpanel-events'
import { ParentEntityType } from 'app/typings/commons'
import { useDeleteCustomAssessmentVariation } from 'app/frontend/compositions/connected/custom-assessment-variation-delete/custom-assessment-variation-delete'
import * as GET_SEQUENCE from 'app/frontend/compositions/connected/get-sequence/get-sequence.gql'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/assessment-questions/assessment-question-actions.css'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import * as GET_CUSTOM_ITEM_VARIATION_SEQUENCE from 'app/frontend/pages/material/teach/compositions/connected/get-custom-item-variation-sequence/get-custom-item-variation-sequence.gql'

const t = tns('teach:remove_assessment_sequence')
const ts = tns('teach:assessment_questions')

export interface Props {
  assessmentId: string
  sequenceId: string
  pathSequenceVariationId: string
  parentEntityType?: Commons.ParentEntityType
  onRemoveEntireQuestion: () => void
  onRemoveEntireQuestionComplete: () => void
  onRemoveEntireQuestionError: () => void
  onRemoveVariation: () => void
  onRemoveVariationComplete: () => void
  onRemoveVariationError: () => void
  label?: string
  atom: Content.IAtom
  onQuestionNavigation?: (index: number) => void
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette?.common.white,
  },
  list: {
    padding: 0,
  },
}))

export const RemoveCustomAssessmentMenu: React.FunctionComponent<Props> = ({
  assessmentId,
  sequenceId,
  pathSequenceVariationId,
  parentEntityType,
  onRemoveEntireQuestion,
  onRemoveEntireQuestionComplete,
  onRemoveEntireQuestionError,
  onRemoveVariation,
  onRemoveVariationComplete,
  onRemoveVariationError,
  atom,
  onQuestionNavigation,
}) => {
  const dispatch = useDispatch()
  const menuStyle = useStyles()

  const anchorRef = React.useRef<Element>()
  const [menuOpen, setMenuOpen] = React.useState(false)
  const showMessage = (msg: string, iconName: string) =>
    dispatch(showSnackbar({ message: msg, iconName }))

  const [deleteAssessmentSequence, { loading }] = useMutation(
    DELETE_ASSESSMENT_SEQUENCE_VARIATION,
    {
      context: { silenceErrors: true },
      onCompleted: () => {
        onRemoveEntireQuestionComplete()
        showMessage(t('question_removed'), 'icon-progress-circle-complete')
      },
      onError: onRemoveEntireQuestionError,
    }
  )

  const [onDeleteVariation] = useDeleteCustomAssessmentVariation({
    onCompleted: () => {
      onRemoveVariationComplete()
      showMessage(ts('variation_removed'), 'icon-progress-circle-complete')
      setMenuOpen(false)
      // After removing a question, switch to the first question in the view.
      onQuestionNavigation(0)
    },
    onError: () => {
      onRemoveVariationError()
      showMessage(ts('variation_removed_error'), 'icon-warning')
      setMenuOpen(false)
    },
  })

  const handleDeleteVariation = () => {
    onRemoveVariation()
    onDeleteVariation({
      variables: {
        sequenceId: sequenceId,
        variationId: atom.id.variationId,
      },
      refetchQueries: [
        {
          query: GET_SEQUENCE,
          variables: { sequenceId },
        },
        { query: GET_CUSTOM_ITEM_VARIATION_SEQUENCE, variables: { sequenceId } },
      ],
    })
  }

  const handleDeleteEntirePoolQuestion = async () => {
    onRemoveEntireQuestion()
    // send remove assessment question mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_REMOVE_QUESTION
        : TEACH_SECTION_ASSESSMENT_REMOVE_QUESTION,
      assessmentId
    )

    await deleteAssessmentSequence({
      variables: {
        assessmentId,
        sequenceId,
        pathSequenceVariationId,
      },
    })
  }

  return (
    <>
      <ButtonMaterial
        icon={<Icon name="icon-delete" className={styles.removeIcon} />}
        label={ts('remove_pool_questions')}
        onClick={() => setMenuOpen(true)}
        aria-haspopup="true"
        theme="bordered"
        data-bi="remove-pool-question-btn"
        setButtonRef={el => (anchorRef.current = el as HTMLButtonElement)}
      />
      <Menu
        open={menuOpen}
        anchorEl={anchorRef.current}
        onClose={() => setMenuOpen(false)}
        classes={menuStyle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        data-bi="remove-pool-menu"
      >
        <MenuItem data-bi="remove-pool-question-mi" onClick={handleDeleteVariation}>
          {ts('remove_one_pool_question')}
        </MenuItem>
        <MenuItem
          data-bi="remove-entire-pool-mi"
          disabled={loading}
          onClick={handleDeleteEntirePoolQuestion}
        >
          {ts('remove_entire_question_pool')}
        </MenuItem>
      </Menu>
    </>
  )
}
