import { get } from './index'

export function getCredentials(): Promise<Response> {
  return get('/learnosity/security')
}

export class QuestionStates {
  static INITIAL = 'initial'
  static REVIEW = 'review'
  static PREVIEW = 'preview'
  static RESUME = 'resume'
}
