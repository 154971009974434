import { Reducer } from 'redux'
import { API_UNLOCK_QUIZ } from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import { HIDE_MODAL, ModalActions } from 'app/frontend/components/material/modal/modal-actions'
import { PASSWORD_MODAL } from 'app/frontend/pages/material/learn/modals'
import * as _ from 'lodash'

export type AuthState = {
  unlocked: boolean
}

const defaultState = {}

export function authReducer(
  state: Partial<AuthState> = defaultState,
  action: ApiSuccessAction | ModalActions
) {
  if (!action) {
    return state
  }
  switch (action.type) {
    case API_UNLOCK_QUIZ.SUCCESS:
      const apiSuccessAction = action as ApiSuccessAction
      return {
        unlocked: apiSuccessAction.response.success,
      }
    case HIDE_MODAL:
      // clear the state when closing the password modal so error message doesn't show again
      const modalAction = action as ModalActions
      if (modalAction.name !== PASSWORD_MODAL) {
        return state
      } else {
        if (!_.isEmpty(state) && !state.unlocked) {
          return {}
        } else {
          return state
        }
      }
    default:
      return state
  }
}

export default authReducer as Reducer<AuthState>
