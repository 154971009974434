import * as React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import {
  getFormValues,
  isPristine,
  isDirty,
  isValid,
  isInvalid,
  submit,
  reset,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
} from 'redux-form'

export type UseFormFunctions<T> = {
  isPristine: boolean
  isDirty: boolean
  isValid: boolean
  isInvalid: boolean
  isSubmitting: boolean
  hasSubmitSucceeded: boolean
  hasSubmitFailed: boolean
  reset: () => void
  submit: () => void
  /*
   * This is "optional" because I've noticed that even in the case of providing
   * initialValues to your form, the first render may return undefined values.
   * https://github.com/redux-form/redux-form/issues/4344
   */
  values?: T
}

/**
 * Selects all relevant information for a form from state and generates useful
 * callbacks.
 *
 * This hook returns a reference stable object.
 */
export const useForm = <T extends any>(formName: string): UseFormFunctions<T> => {
  const dispatch = useDispatch()
  const formState = useSelector(
    state => ({
      isPristine: isPristine(formName)(state),
      isDirty: isDirty(formName)(state),
      isValid: isValid(formName)(state),
      isInvalid: isInvalid(formName)(state),
      isSubmitting: isSubmitting(formName)(state),
      hasSubmitSucceeded: hasSubmitSucceeded(formName)(state),
      hasSubmitFailed: hasSubmitFailed(formName)(state),
      values: getFormValues(formName)(state),
    }),
    shallowEqual
  )

  const resetForm = React.useCallback(() => dispatch(reset(formName)), [formName])
  const submitForm = React.useCallback(() => dispatch(submit(formName)), [formName])

  return React.useMemo(() => {
    return {
      ...formState,
      reset: resetForm,
      submit: submitForm,
    }
  }, [formState, resetForm, submitForm])
}
