import { useRef, useEffect } from 'react'

/**
 * Replaces a value with a new one, returning the previous value.
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * NOTE: This does not have the same behavior as usePrevious from ReactUse.
 *  This version will return the first value on the first render, as opposed to
 *  undefined.
 *
 * @param value The value to store
 */
export const usePrevious = <T extends any>(value: T): T => {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
