import { combineReducers, Reducer } from 'redux'
import coursesReducerById, { CoursesReducerById } from './courses-reducer-by-id'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'

export const getCourses = (state: TeachControllerState): CourseReducer => state.entities.courses
export const getCoursesById = (state: TeachControllerState): CoursesReducerById =>
  getCourses(state).byId

export type CourseReducer = {
  byId: CoursesReducerById
}

export default combineReducers({
  byId: coursesReducerById,
}) as Reducer<CourseReducer>
