import * as React from 'react'
import { Field } from 'redux-form'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { useToggle } from 'app/frontend/components/material/helpers'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './forgot-password.css'
import { Password, HiddenField } from './reset-fields'

const t = tns('forgot_password')

interface Props {
  handleSubmit?: () => void
  valid?: boolean
  submitting?: boolean
}

export const ResetPasswordFormContents: React.FunctionComponent<Props> = ({
  handleSubmit,
  valid,
  submitting,
}) => {
  const [newPasswordVisible, toggleNewPasswordVisible] = useToggle(false)
  const [confirmPasswordVisible, toggleConfirmPasswordVisible] = useToggle(false)
  return (
    <>
      <p>{t('password_requirements')}</p>
      <Field component={HiddenField} name="email" />
      <Field component={HiddenField} name="token" />
      <Field component={HiddenField} name="valid" />
      <Field
        component={Password}
        name="password"
        label={t('type_new_password')}
        visible={newPasswordVisible}
        toggle={toggleNewPasswordVisible}
        passwordText={t('show_password')}
        displayRequirements={true}
        describedBy={'passwordRequirements'}
      />
      <Field
        component={Password}
        name="confirmPassword"
        label={t('retype_new_password')}
        visible={confirmPasswordVisible}
        passwordText={t('show_confirm_password')}
        toggle={toggleConfirmPasswordVisible}
      />
      <ButtonMaterial
        type="submit"
        data-bi="update-password-button"
        className={styles.button}
        onClick={handleSubmit}
        label={t('update_password')}
        disabled={submitting || !valid}
      />
    </>
  )
}
