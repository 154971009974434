import { Reducer } from 'redux'
import { SET_ACTIVE_INSTITUTION } from 'app/frontend/pages/institution-admin/ui/institution/active-institution-actions'

export const reducer = (state: Commons.InstitutionId = '', action) => {
  switch (action.type) {
    case SET_ACTIVE_INSTITUTION:
      return action.institutionId
    default:
      return state
  }
}

export const getActiveInstitutionId = (state): Commons.InstitutionId => state.ui.activeInstitution

export default reducer as Reducer<Commons.InstitutionId>
