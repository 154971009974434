import * as React from 'react'
import * as moment from 'moment'
import { DateTimeFormats } from 'app/helpers/timezone'
import { datePickerReducer } from './reducer'
import {
  defaultDateYearFormatRegex,
  anyDateFormatRegex,
  dateErrors,
  defaultDateFormatRegex,
} from '../helpers'

type UseDatePickerProps = {
  value: moment.Moment
  dateFormat?: DateTimeFormats
  timeFormat?: DateTimeFormats
  minDate: moment.Moment
  maxDate: moment.Moment
  onChange: (val: moment.Moment) => void
  dateFormatRegex?: RegExp
  allowUndefined: boolean
  acceptAnyFormat: boolean
  setError?: (text: string) => void
  customError?: string
}

type UseDatepickerState = {
  formattedInputValue: string
  isPickerOpen: boolean
  showPicker: () => void
  hidePicker: () => void
  setText: (val: string) => void
  handleTextBlur: () => void
  error: string
}

export const useDatePicker = ({
  value,
  dateFormat = DateTimeFormats.MONTH_DATE_YEAR,
  timeFormat = DateTimeFormats.PICKER_TIME_12_WITH_NO_ZONE,
  minDate,
  maxDate,
  onChange,
  allowUndefined,
  acceptAnyFormat = true,
  setError,
  customError,
}: UseDatePickerProps): UseDatepickerState => {
  const dateTimeFormat = `${dateFormat} ${timeFormat}`
  const date = value && value.format(dateFormat)
  const time = value && value.format(timeFormat)
  const [
    { formattedInputValue, isPickerOpen, error },
    { handleChange, hidePicker, setText, setError: setPickerError, showPicker },
  ] = datePickerReducer({
    dateFormat,
    time,
    dateTimeFormat,
    value,
  })

  const dateInBounds = (val: moment.Moment) => !val.isBefore(minDate) && !val.isAfter(maxDate)

  const handleTextBlur = () => {
    // if empty input set to current value
    if (formattedInputValue.trim() === '') {
      if (allowUndefined) {
        return onChange(undefined)
      }
      if (value) {
        return handleChange(value)
      }
      return
    }

    // if unchanged
    if (value && value.format(dateFormat) === formattedInputValue) {
      return
    }

    let newValue
    if (formattedInputValue.match(defaultDateYearFormatRegex)) {
      // Set date and year
      // Set time to previous time or 12:00 AM if not set.
      // ex: Jan 12, 2021 or September 22, 2022
      newValue = moment(
        `${formattedInputValue} ${time || '12:00 AM'}`,
        `${DateTimeFormats.MONTH_DATE_YEAR} ${timeFormat}`
      )
    } else if (acceptAnyFormat && formattedInputValue.match(anyDateFormatRegex)) {
      // Set date any format
      // Set time to previous time or 12:00 AM if not set.
      // ex: 3/12/21 or 12/21/21 or 4/12
      const [, mm, dd, , yy = moment().format('YY')] = formattedInputValue.match(anyDateFormatRegex)
      newValue = moment(
        `${mm} ${dd} ${yy.slice(-2)} ${time || '12:00 AM'}`,
        `MM DD YY ${timeFormat}`
      )
    } else if (formattedInputValue.match(defaultDateFormatRegex)) {
      // Set date
      // Set time to previous time or 12:00 AM if not set.
      // Set year to current.
      // ex: Feb 27 or September 1
      newValue = moment(
        `${formattedInputValue}, ${moment().format('YY')} ${time || '12:00 AM'}`,
        dateTimeFormat
      )
    }

    // If the input matched one of the above, check if it is in bounds
    if (newValue) {
      if (dateInBounds(newValue)) {
        return onChange(newValue)
      } else {
        return setPickerError(customError || dateErrors.OUTOFBOUNDS)
      }
    }

    return setPickerError(dateErrors.INVALID)
  }

  // We should only change/reset the underlying value if the *date* has changed
  React.useEffect(() => {
    handleChange(value)
  }, [date])

  React.useEffect(() => {
    if (setError) {
      setError(error)
    }
  }, [error])

  return {
    showPicker,
    hidePicker,
    formattedInputValue,
    isPickerOpen,
    setText,
    handleTextBlur,
    error,
  }
}
