/**
 * List of error codes the frontend can receive from Frackend APIs.
 */
const CODES = {
  CONFLICT: 'CONFLICT',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
  COURSE_NOT_FOUND: 'COURSE_NOT_FOUND',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  PERMISSION_DENIED: 'PERMISSION_DENIED',

  /**
   * Returned when Robotector wants to limit the number of requests.
   */
  HUMAN_VERIFICATION_REQUIRED: 'HUMAN_VERIFICATION_REQUIRED',

  /**
   * Returned when the user answer does not match the content type. This means the content has
   * changed or the answer submitted if for the wrong atom.
   */
  ANSWER_DOESNT_MATCH_CONTENT: 'ANSWER_DOESNT_MATCH_CONTENT',

  /**
   * Returned when an expired or invalid token is submitted with the forgot password page.
   */
  INVALID_TOKEN: 'INVALID_TOKEN',

  /**
   * Returned when a sequence is not found.
   */
  SEQUENCE_NOT_FOUND: 'SEQUENCE_NOT_FOUND',

  /**
   * Returned when CSRF check fails.
   */
  CSRF_MISMATCH: 'CSRF_MISMATCH',

  /**
   * Returned when starting a quiz that is available in print format.
   */
  QUIZ_AVAILABLE_IN_PRINT: 'QUIZ_AVAILABLE_IN_PRINT',

  SECTION_NOT_FOUND: 'SECTION_NOT_FOUND',

  /**
   * Returned when the backend refuses to apply a change to an assessment's maxNumAttempts
   */
  INVALID_NEW_MAX_NUM_ATTEMPTS: 'INVALID_NEW_MAX_NUM_ATTEMPTS',

  /**
   * Returned when performing an action on an already completed assessment
   */
  QUIZ_ALREADY_COMPLETED: 'QUIZ_ALREADY_COMPLETED',

  /**
   * Returned when performing an action on an already assessment attempt
   */
  QUIZ_ATTEMPT_ALREADY_COMPLETED: 'QUIZ_ATTEMPT_ALREADY_COMPLETED',

  /**
   * Returned when performing an action on a quiz whose timer has expired
   */
  TIMER_ALREADY_EXPIRED: 'TIMER_ALREADY_EXPIRED',

  /**
   * Returned when an automated flow is submitting a quiz whose timer has not yet expired
   */
  TIMER_NOT_YET_EXPIRED: 'TIMER_NOT_YET_EXPIRED',

  /**
   * This error is thrown when a user tries to pay for a course but already has an active
   * subscription for that course.
   */
  ALREADY_HAS_ACTIVE_SUBSCRIPTION: 'ALREADY_HAS_ACTIVE_SUBSCRIPTION',

  /**
   * This error is thrown when a chargebee request fails because the user's
   * billing address is invalid.
   */
  INVALID_BILLING_ADDRESS: 'INVALID_BILLING_ADDRESS',

  /**
   * Returned when a student is not in a section
   */
  STUDENT_NOT_IN_SECTION: 'STUDENT_NOT_IN_SECTION',

  /**
   * Returned when requesting instructional content for a set of concepts and no instructional content exists
   */
  INSTRUCTIONAL_CONTENT_NOT_FOUND: 'INSTRUCTIONAL_CONTENT_NOT_FOUND',

  /**
   * Returned when learning objective count reach maximum allowed count
   */
  TOO_MANY_LEARNING_OBJECTIVES: 'TOO_MANY_LEARNING_OBJECTIVES',

  /**
   * Returned when comments are not allowed to view
   */
  ASSESSMENT_COMMENTS_VIEW_NOT_ALLOWED: 'ASSESSMENT_COMMENTS_VIEW_NOT_ALLOWED',
}

export default CODES
