import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as classnames from 'classnames'

import * as styles from './video-error.css'
const t = tns('video_player')

interface OwnProps {
  classNames?: any
  videoLinkFn?: (text: string) => JSX.Element
}

/**
 * This error component should be rendered any time a Video player encounters a fatal error
 * resulting in the video not being able to be played.
 */
const VideoError: React.FunctionComponent<OwnProps> = props => {
  return (
    <div className={classnames(props.classNames, styles.errorLoading)}>
      <div className={styles.errorMessage}>
        <p className={styles.description}>{t('provider_error')}</p>
        <p className={styles.refresh}>
          {t('refresh')} {props.videoLinkFn && props.videoLinkFn(t('open_in_tab'))}
        </p>
      </div>
    </div>
  )
}

VideoError.displayName = 'VideoError'

export default VideoError
