import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
import {
  buildLosToAssignmentMap,
  LearningObjectivesToAssignmentsMap,
} from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import * as GET_LEARNING_OBJECTIVES_FOR_SECTION from './get-learning-objectives-for-section.gql'

export interface GetLearningObjectivesForSectionRenderProps {
  assignmentsByLearningObjectiveId?: LearningObjectivesToAssignmentsMap
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryLearningObjectivesForSectionArgs & {
  children: (props: GetLearningObjectivesForSectionRenderProps) => JSX.Element
}

export const GetLearningObjectivesForSection: React.FunctionComponent<Props> = ({
  sectionId,
  children,
}) => (
  <Query<GQL.GetLearningObjectivesForSection.Query, GQL.GetLearningObjectivesForSection.Variables>
    query={GET_LEARNING_OBJECTIVES_FOR_SECTION}
    variables={{ sectionId }}
    fetchPolicy="cache-and-network" // TODO ALPACA-759
    nextFetchPolicy="cache-first"
  >
    {({ data, loading, error }) =>
      children({
        assignmentsByLearningObjectiveId: isEmpty(data)
          ? null
          : buildLosToAssignmentMap(data.learningObjectivesForSection),
        loading,
        error,
      })
    }
  </Query>
)

GetLearningObjectivesForSection.displayName = 'GetLearningObjectivesForSection'
