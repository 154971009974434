import * as React from 'react'
import { Menu, IconButton } from '@mui/material'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { ButtonTheme } from 'app/frontend/components/material/button/button'

export type ButtonPosition = 'auto' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

interface AnchorCoordinates {
  vertical: 'top' | 'center' | 'bottom'
  horizontal: 'left' | 'center' | 'right'
}

interface TransformValue {
  anchorOrigin?: AnchorCoordinates
  transformOrigin?: AnchorCoordinates
}

interface MenuTheme {
  /**
   * Styles the paper component (the menu box).
   */
  paper?: string

  /**
   * Styles to the actual unordered list.
   */
  list?: string
}

export interface ButtonMenuProps {
  position?: ButtonPosition
  buttonTheme?: ButtonTheme
  menuTheme?: MenuTheme
  buttonClassName?: string
  label?: string
  icon?: JSX.Element
  iconAriaLabel?: string
  dataBi?: string
  /**
   * Make the menu close whenever you click.
   *
   * Useful when you want the menu to be gone when
   * you click on an item.
   */
  shouldCloseOnClick?: boolean
  onClick?: () => void
  onMenuHide?: () => void
}

export const ButtonMenu: React.FunctionComponent<ButtonMenuProps> = ({
  label,
  icon,
  iconAriaLabel,
  buttonTheme = 'bordered',
  dataBi,
  shouldCloseOnClick,
  buttonClassName,
  position = 'auto',
  menuTheme,
  onClick,
  onMenuHide,
  children,
}) => {
  const [buttonRef, setButtonRef] = React.useState<HTMLOrSVGElement>(null)
  const [isActive, setIsActive] = React.useState(false)

  const handleButtonCLick = () => {
    setIsActive(true)
    if (onClick) {
      onClick()
    }
  }

  const handleMenuHide = () => {
    setIsActive(false)
    if (onMenuHide) {
      onMenuHide()
    }
  }

  const handleKeyDownCapture = e => {
    if (shouldCloseOnClick) {
      if (e.key === 'Enter') {
        handleMenuHide()
      }
    }
  }

  const handleMouseUpCapture = () => {
    if (shouldCloseOnClick) {
      handleMenuHide()
    }
  }

  return (
    <>
      {label ? (
        <ButtonMaterial
          icon={icon}
          aria-expanded={isActive}
          aria-haspopup="true"
          onClick={isActive ? null : handleButtonCLick}
          label={label}
          theme={buttonTheme}
          className={buttonClassName}
          data-bi={dataBi}
          setButtonRef={r => setButtonRef(r)}
        />
      ) : (
        <IconButton
          onClick={isActive ? null : handleButtonCLick}
          aria-expanded={isActive}
          aria-haspopup="true"
          aria-label={iconAriaLabel}
          data-bi={dataBi}
          ref={r => setButtonRef(r)}
          className={buttonClassName}
        >
          {icon}
        </IconButton>
      )}
      <Menu
        open={isActive}
        onClose={handleMenuHide}
        onKeyDownCapture={handleKeyDownCapture}
        onMouseUpCapture={handleMouseUpCapture}
        {...transformPosition(position)}
        anchorEl={buttonRef as HTMLElement}
        classes={menuTheme}
        autoFocus={true}
        variant={'menu'}
      >
        {children}
      </Menu>
    </>
  )
}

/**
 * Transform the given position to anchor coordinates. The position dictates
 * where the menu will seemingly appear with respect to the reference element.
 *
 * Say when position is "bottom right", the menu will appear such that the
 * reference element is covered on the menu's bottom right side.
 *
 *  __________
 * |          |
 * |          |
 * |          |
 * |_____XXXXX|  -> XXXXX is ref element
 *
 */
const transformPosition = (btnPos: ButtonPosition): TransformValue => {
  /*
        anchorOrigin = point in the reference element which will be the basis
        of the menu's position.

        transformOrigin = point in the menu that will sit in the anchor origin point.
     */
  switch (btnPos) {
    case 'bottomRight':
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }
    case 'bottomLeft':
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }
    case 'topLeft':
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }
    case 'topRight':
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }
    case 'auto':
    default:
      return {}
  }
}
