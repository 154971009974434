import { combineReducers } from 'redux'

import coursesReducer, { CourseReducer } from './courses/courses-reducer'
import assignmentsReducer, { AssignmentsReducerState } from './assignments/assignments-reducer'
import coursepacksReducer from './coursepacks-reducer'
import taxonomyReducer, { TaxonomyReducerState } from './taxonomy-reducer'
import taxonStatsReducer, { TaxonStatsReducerState } from './taxon-stats/taxon-stats-reducer'
import learningObjectivesReducer, {
  LearningObjectiveReducerState,
} from './learning-objectives/learning-objectives-reducer'
import sequencesReducer, { SequencesReducerState } from './sequences/sequences-reducer'

export type EntitiesState = {
  courses: CourseReducer
  assignments: AssignmentsReducerState
  coursepacks: {
    [id: string]: Commons.ICoursepack
  }
  taxonomy: TaxonomyReducerState
  learningObjectives: LearningObjectiveReducerState
  sequences: SequencesReducerState
  taxonStats: TaxonStatsReducerState
}

export default combineReducers<EntitiesState>({
  courses: coursesReducer,
  assignments: assignmentsReducer,
  coursepacks: coursepacksReducer,
  taxonomy: taxonomyReducer,
  learningObjectives: learningObjectivesReducer,
  sequences: sequencesReducer,
  taxonStats: taxonStatsReducer,
})
