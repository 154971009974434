type Desmos = Window['Desmos']
const DESMOS_GRAPH_CALCULATOR_URL = '//d1873o2okbzyn2.cloudfront.net/desmos/1.8/calculator.js'

export let isLoading = false
export let loadingPromise: Promise<Desmos>

/**
 * It loads one Desmos globally. If it's in the loading process, it will reuse the same promise.
 * It does not have the re-try logic, as fast fail is resilient. Instead, It turns down the
 * isLoading state when the loading process is done, which allows to reload the script.
 */
export const getDesmos = (): Promise<Desmos> => {
  if (window.Desmos) {
    return Promise.resolve(window.Desmos)
  }

  if (!isLoading) {
    isLoading = true
    loadingPromise = loadDesmos().finally(() => {
      isLoading = false
    })
  }

  return loadingPromise
}

export const loadDesmos = (): Promise<Desmos> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = DESMOS_GRAPH_CALCULATOR_URL

    script.onerror = (err: ErrorEvent) => {
      console.error('Desmos failed to load', {
        internalIssueId: 'CE-3634',
      })
      reject(err)
    }

    script.onload = () => {
      if (!window.Desmos) {
        console.error('Desmos failed to mount', {
          internalIssueId: 'CE-3634',
        })
        this.setState({ status: 'ERROR' })
        reject(new Error('Failed to mount Desmos to window'))
      } else {
        resolve(window.Desmos)
      }
    }
    document.head.appendChild(script)
  })
}
