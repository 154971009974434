import 'isomorphic-fetch'
import * as LogReporter from 'app/frontend/helpers/log-reporter'

let INITIALIZED = false

/**
 * Installs global handler for catching errors and reporting them to frackend.
 */
export const init = () => {
  // Only do this once
  if (INITIALIZED) {
    return
  }
  INITIALIZED = true

  const originalOnError = window.onerror
  const originalConsoleError = window.console ? window.console.error : null

  // intercept calls to onerror
  window.onerror = (...args) => {
    report('onerror', args)
    if (originalOnError) {
      originalOnError.apply(window, args)
    }
  }

  if (window.console) {
    // intercept calls to console.error
    window.console.error = (...args) => {
      report('console.error', args)
      if (originalConsoleError) {
        originalConsoleError.apply(window, args)
      }
    }
  }
}

/**
 * Returns whether we should be ignoring this error or not.
 */
export const shouldIgnore = (source: string, params: any) => {
  const isIntercepted = source === 'onerror' || source === 'console.error'
  if (isIntercepted && params.length > 0) {
    // Do not report errors from Learnosity (we report them in learnosity.tsx already)
    if (typeof params[0] === 'string' && params[0].match(/^\d+:.*LearnosityApp.errors\[\d+\]$/)) {
      return true
    }
  }
  return false
}

/**
 * Called when an uncaught client side error happens or when console.error is called.
 * This can also be called directly to report an errors with a specific source that can be
 * alerted on.
 */
export const report = (source: string, params: any) => {
  if (shouldIgnore(source, params)) {
    return
  }
  LogReporter.error(source, params)
}
