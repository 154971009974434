import * as _ from 'lodash'
import * as CurrentUser from '../current-user'

/**
 * Generates mixpanel event metadata, for use in sendEvent
 */
export function createMixpanelProps(
  enrollment: Commons.IEnrollment
): { section_id: string; path_id: string; timestamp: number } {
  // if we are in 'vew related instruction' mode, the enrollment does not have a path
  return {
    section_id: enrollment.path ? enrollment.path.sectionId : null,
    path_id: enrollment.path ? enrollment.path.id : enrollment.pathId,
    timestamp: Math.floor(Date.now() / 1000),
  }
}

/**
 * A wrapper for the mixpanel track() call that adds user metadata to every call
 *
 * @param name The string that will be used to label this event in mixpanel
 * @param additionalProps An object containing any metadata fields that should be tracked beyond standard user metadata
 */
export function sendEvent(name: string, additionalProps: object = {}): void {
  const props = {}
  if (CurrentUser.isSet()) {
    props['user_id'] = CurrentUser.getId()
    props['role'] = CurrentUser.get().roleForUi
    props['as_sudo'] = !_.isEmpty(window.context.originalUser)

    if (CurrentUser.get().institutions && CurrentUser.get().institutions.length > 0) {
      const institutions = CurrentUser.get().institutions
      if (institutions.length > 1) {
        const institution = _.find(institutions, (i: Commons.IInstitution) => {
          return i.name !== 'k_12'
        })
        props['institution_id'] = !institution ? null : institution.id
      } else {
        props['institution_id'] = institutions[0].id
      }
    }
  }

  Object.assign(props, additionalProps)

  if (window.mixpanel) {
    window.mixpanel.track(name, props)
  }
}
