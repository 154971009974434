import * as React from 'react'
import { Query } from 'app/frontend/components/query'
import { isEmpty } from 'lodash'
import type { ApolloError } from '@apollo/client'
import * as QUERY from './learning-objective-by-id.gql'

interface ChildrenProps {
  lo: GQL.LearningObjective
  loading?: boolean
  error?: ApolloError
}
type Props = GQL.QueryLearningObjectiveByIdArgs & {
  skip?: boolean
  children: (props: ChildrenProps) => JSX.Element
}

export const LearningObjectiveByIdQuery: React.FunctionComponent<Props> = ({
  id,
  skip,
  children,
}) => (
  <Query<GQL.GetLearningObjectiveById.Query, GQL.GetLearningObjectiveById.Variables>
    query={QUERY}
    variables={{ id, skip: !!skip }}
  >
    {({ data, loading, error }) => {
      return children({
        lo: isEmpty(data) ? null : data.learningObjectiveById,
        loading,
        error,
      })
    }}
  </Query>
)
LearningObjectiveByIdQuery.displayName = 'LearningObjectiveByIdQuery'
