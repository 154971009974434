export const REQUIRED_FIELD_ERROR = 'This field is required.'
export const INVALID_EMAIL_ERROR = 'This is not a valid email address.'
export const NOT_EQUAL_ERROR = otherFieldName =>
  `This field does not match the field ${otherFieldName}.`
export const LENGTH_ERROR = len => `Please enter at least ${len} characters.`
export const PASSWORD_CASE_ERROR = 'Please enter at least 1 lower case and 1 upper case letter.'
export const PASSWORD_NUMBER_ERROR = 'Please enter at least one number.'
export const PASSWORDS_DIFFERENT_ERROR = 'Passwords do not match.'
export const END_DATE_NOT_VALID = 'End date must be after the start date.'
export const UNIQUE_ASSIGNMENT_NAME_ERROR =
  'This name is already in use. Please select a different name.'
export const UNIQUE_ASSIGNMENT_LO_ERROR =
  'There is a duplicate assignment with this selection of learning objectives.' +
  ' Please make a different choice.'
export const INVALID_DATE_FORMAT = 'Please enter the date in mm/dd/yyyy, i.e. 11/24/1990'
export const INVALID_PERCENTAGE_ERROR = 'Percentage must be between 0 and 100.'
export const INVALID_INTEGER_ERROR = 'Decimals are not allowed.'
export const INVALID_UUID_ERROR = 'Invalid identifier'
