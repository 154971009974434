import * as React from 'react'
import { change, Field, formValueSelector, hasSubmitFailed, stopSubmit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from 'app/frontend/components/material/checkbox'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import {
  FieldErrorMessage,
  WrappedFieldPropsStub,
} from 'app/frontend/compositions/data/redux-form-fields'
import { Password as PasswordField } from 'app/frontend/components/material/password-field'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { TeachControllerState } from 'app/frontend/pages/material/teach/teach-controller-reducer'
import { PasswordOptions } from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/form-fields/password-options'
import { ASSESSMENT_SETTINGS_FORM, FormNames, PasswordType } from './helper'
import * as styles from '../settings-modal.css'

const t = tns('teach:assessment_builder')

interface OwnProps {
  isReadOnly: boolean
  isCourseAssignmentOnSection?: boolean
  hasCoursePassword: boolean
  onReset: () => void
}
type StateProps = {
  password: string
  passwordType: PasswordType
  submitFailed: boolean
  passwordEnabled: boolean
}

export const PasswordSetting: React.FunctionComponent<OwnProps> = ({
  isReadOnly,
  isCourseAssignmentOnSection,
  hasCoursePassword,
  onReset,
}) => {
  const dispatch = useDispatch()
  const stateProps: StateProps = useSelector<TeachControllerState, StateProps>(getStateProps)
  const showPasswordField = stateProps.passwordEnabled

  const onTogglePassword = () => {
    dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD_ENABLED, !showPasswordField))
    dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD, null))
    if (isCourseAssignmentOnSection) {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD_OVERRIDDEN, true))
      // toggle action for the password option will be considered as password customization and
      // `customize-for-section` option will be selected
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD_TYPE, PasswordType.SECTION))
      // reset the form level submitting and submitFailed status
      dispatch(stopSubmit(ASSESSMENT_SETTINGS_FORM))
    }
  }

  const onPasswordChange = () => {
    if (isCourseAssignmentOnSection) {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD_OVERRIDDEN, true))
    }
  }

  const onPasswordTypeChange = (_event: React.FormEvent, newType: string) => {
    if (newType === PasswordType.COURSE) {
      // if password type change is changed as COURSE, that will be considered as reset action
      onReset()
    } else {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD_OVERRIDDEN, true))
      // erase the entered password
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD, null))
    }
  }

  // password submit error, Only show the error message after a failed submission
  const passwordError = stateProps.submitFailed && <FieldErrorMessage name={FormNames.PASSWORD} />

  return (
    <Box full="page" pad="medium" data-test="password-setting">
      <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
        <Paragraph margin="none" weight="semibold" id="settings-password-header">
          {t('settings_password_header')}
        </Paragraph>
        <Checkbox
          data-test="password-checkbox"
          onChange={onTogglePassword}
          className={styles.checkboxPadding}
          disabled={isReadOnly}
          checked={showPasswordField}
          inputProps={{
            'aria-labelledby': 'settings-password-header',
            'aria-describedby': 'settings-password-description',
          }}
        />
      </Box>
      <Paragraph size="small" margin="small" id="settings-password-description">
        {t('settings_password_description')}
      </Paragraph>
      {showPasswordField &&
        (isCourseAssignmentOnSection ? (
          <PasswordOptions
            isReadOnly={isReadOnly}
            hasCoursePassword={hasCoursePassword}
            passwordType={stateProps.passwordType}
            onOptionChange={onPasswordTypeChange}
            onPasswordChange={onPasswordChange}
            passwordError={passwordError}
          />
        ) : (
          <>
            <Box direction="row" responsive={false} alignItems="center">
              <Field
                name={FormNames.PASSWORD}
                component={renderPassword}
                props={{
                  isReadOnly,
                }}
                onChange={onPasswordChange}
              />
            </Box>
            {passwordError}
          </>
        ))}
    </Box>
  )
}

PasswordSetting.displayName = 'PasswordSetting'

export const renderPassword = (field: WrappedFieldPropsStub & OwnProps): JSX.Element => {
  return (
    <PasswordField
      value={field.input.value}
      disabled={field.isReadOnly}
      onChange={field.input.onChange}
    />
  )
}

const getStateProps = (state: TeachControllerState) => {
  const selector = formValueSelector(ASSESSMENT_SETTINGS_FORM)
  return {
    password: selector(state, FormNames.PASSWORD),
    passwordType: selector(state, FormNames.PASSWORD_TYPE),
    passwordEnabled: selector(state, FormNames.PASSWORD_ENABLED),
    passwordOverridden: selector(state, FormNames.PASSWORD_OVERRIDDEN),
    submitFailed: hasSubmitFailed(ASSESSMENT_SETTINGS_FORM)(state),
  }
}
