import { combineReducers } from 'redux'
import { reducer as formReducer, FormReducer } from 'redux-form'

export interface OrdersControllerState {
  form: FormReducer
}

export default combineReducers<OrdersControllerState>({
  form: formReducer,
})
