import { tns } from 'app/frontend/helpers/translations/i18n'
import {
  FilterGroups,
  SelectionFilter,
  AssessmentUsageFilter,
  AssignmentUsageFilter,
} from 'app/typings/commons'

const t = tns('teach:assessment_lo_selection_modal')

export const isAllFilterSelected = (
  filter: AssignmentUsageFilter | AssessmentUsageFilter | SelectionFilter
) => {
  return (
    filter === AssignmentUsageFilter.All_Assignment ||
    filter === AssessmentUsageFilter.All_Assessment ||
    filter === SelectionFilter.All_Selection
  )
}

export const getFilterGroupTranslationText = (filterGroup: FilterGroups): string => {
  const translatedMap = {
    [FilterGroups.AssignmentUsage]: t('assignment_usage'),
    [FilterGroups.AssessmentUsage]: t('assessment_usage'),
    [FilterGroups.Selection]: t('selection'),
  }

  return translatedMap[filterGroup]
}

export const getFilterTranslationText = (
  filter: AssessmentUsageFilter | AssignmentUsageFilter | SelectionFilter
): string => {
  const translatedMap = {
    [AssignmentUsageFilter.Assigned]: t('filter_assigned'),
    [AssignmentUsageFilter.Unassigned]: t('filter_unassigned'),
    [AssessmentUsageFilter.Tested]: t('filter_tested'),
    [AssessmentUsageFilter.Untested]: t('filter_untested'),
    [SelectionFilter.Selected]: t('filter_selected'),
    [SelectionFilter.NotSelected]: t('filter_not_selected'),
  }

  return translatedMap[filter]
}
