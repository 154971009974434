export enum ParentEntityType {
  Course = 'COURSE',
  Section = 'SECTION',
}

export enum AssignmentType {
  ADAPTIVE = 'ADAPTIVE',
  QUIZ = 'QUIZ',
  REVIEW = 'REVIEW',
}

export enum AdaptiveBehavior {
  PRACTICE = 'PRACTICE',
  INTRODUCE = 'INTRODUCE',
  REVIEW = 'REVIEW',
}

export enum AgencySetting {
  IMMEDIATE = 'IMMEDIATE',
  AFTER_WORK = 'AFTER_WORK',
  NEVER = 'NEVER',
}

export enum AssessmentLabel {
  TEST = 'TEST',
  QUIZ = 'QUIZ',
  HOMEWORK = 'HOMEWORK',
}

export enum ViewResultsConfig {
  GRADE_ONLY = 'GRADE_ONLY',
  GRADE_AND_GRADED_QUESTIONS = 'GRADE_AND_GRADED_QUESTIONS',
  GRADE_AND_ANSWER_KEY = 'GRADE_AND_ANSWER_KEY',
}

export enum ResultSettingType {
  AFTER_SUBMISSION = 'AFTER_SUBMISSION',
  AFTER_DUE_DATE = 'AFTER_DUE_DATE',
  NEVER = 'NEVER',
  IMMEDIATELY = 'IMMEDIATELY',
  MANUALLY = 'MANUALLY',
}

export enum ResultSettingState {
  RELEASED = 'RELEASED',
  WAITING_FOR_SUBMISSION = 'WAITING_FOR_SUBMISSION',
  WAITING_FOR_INSTRUCTOR = 'WAITING_FOR_INSTRUCTOR',
  WAITING_FOR_RELEASE_ON = 'WAITING_FOR_RELEASE_ON',
  NEVER = 'NEVER',
}

export enum GradeAttemptsConfig {
  BEST = 'BEST',
}

export enum LMSTypeForCartridge {
  BLACKBOARD = 'blackboard',
  D2L = 'd2l',
  CANVAS = 'canvas',
  IMS = 'ims',
}

export enum ResultOverrideType {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  IGNORED = 'IGNORED',
  REMOVED_FROM_ASSESSMENT = 'REMOVED_FROM_ASSESSMENT',
  OVERRIDDEN = 'OVERRIDDEN',
}

export enum StatusType {
  no_work = 'no_work',
  complete = 'complete',
  in_progress = 'in_progress',
  struggling = 'struggling',
  complete_max_work = 'complete_max_work',
}

export enum FilterGroups {
  AssignmentUsage = 'ASSIGNMENT_USAGE',
  AssessmentUsage = 'ASSESSMENT_USAGE',
  Selection = 'SELECTION',
}

export enum AssignmentUsageFilter {
  All_Assignment = 'ALL_ASSIGNMENT',
  Assigned = 'ASSIGNED',
  Unassigned = 'UNASSIGNED',
}

export enum AssessmentUsageFilter {
  All_Assessment = 'ALL_ASSESSMENT',
  Tested = 'TESTED',
  Untested = 'UNTESTED',
}

export enum SelectionFilter {
  All_Selection = 'ALL_SELECTION',
  Selected = 'SELECTED',
  NotSelected = 'NOT_SELECTED',
}

export type FilterGroupsStateType = {
  [FilterGroups.AssignmentUsage]: AssignmentUsageFilter
  [FilterGroups.AssessmentUsage]: AssessmentUsageFilter
  [FilterGroups.Selection]: SelectionFilter
}

export type FilterOption = {
  value: string
  disabled?: boolean
  label: string
}

export enum CompoundInstanceState {
  RECOMMENDED = 'RECOMMENDED',
  SHOWN = 'SHOWN',
  ATTEMPTED = 'ATTEMPTED',
  ANSWERED = 'ANSWERED',
  DONE = 'DONE',
  SKIPPED = 'SKIPPED',
}

export enum CompoundInstanceSource {
  INTRODUCTION = 'INTRODUCTION',
  PRACTICE = 'PRACTICE',
  QUIZ = 'QUIZ',
  RECOMMENDATION = 'RECOMMENDATION',
  SEQUENCE_ON_DEMAND = 'SEQUENCE_ON_DEMAND',
  SCAFFOLDED_PRACTICE = 'SCAFFOLDED_PRACTICE',
  USER_STUCK_REPEAT = 'USER_STUCK_REPEAT', // deprecated, but keep for historical data
  USER_STUCK = 'USER_STUCK', // deprecated, but keep for historical data
  VIEW_RELATED_INSTRUCTION = 'VIEW_RELATED_INSTRUCTION',
  VIEW_RELATED_INSTRUCTION_REPEAT = 'VIEW_RELATED_INSTRUCTION_REPEAT',
  MORE_INSTRUCTION = 'MORE_INSTRUCTION',
  MORE_INSTRUCTION_REPEAT = 'MORE_INSTRUCTION_REPEAT',
}

export enum CompoundInstanceType {
  TEACH = 'TEACH',
  ASSESS = 'ASSESS',
}

export enum StudentActivityFilterType {
  ALL = 'ALL',
  INSTRUCTIONAL = 'INSTRUCTIONAL',
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  PARTIAL_CREDIT = 'PRATIAL_CREDIT',
}

export enum StudentActivityCategoryFilterType {
  ON_TARGET = 'ON_TARGET',
  PREREQ = 'PREREQ',
  PRACTICE = 'PRACTICE',
}

/**
 * Enum representing logical groupings when querying for user attempts for assessment dashboards.
 */
export enum AttemptOptionType {
  FIRST = 'FIRST',
  BEST = 'BEST',
}

export enum InclusiveAccessStatus {
  OPT_IN = 'OPT_IN',
  OPT_OUT = 'OPT_OUT',
}

export enum InstitutionBillingModelType {
  STUDENT_PAYGATE = 'STUDENT_PAYGATE',
  STUDENT_PAYGATE_THIRD_PARTY = 'STUDENT_PAYGATE_THIRD_PARTY',
  OPEN_ACCESS = 'OPEN_ACCESS',
}
