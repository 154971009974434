import * as React from 'react'
import * as styles from './login-alert.css'
import * as AlertIconSvg from 'app/public/img/error-alert-icon.svg'
import { getStaticUrl } from 'app/frontend/helpers/assets'

export const LoginAlert: React.FunctionComponent<{ error: string }> = ({ error }) => (
  <div className={styles.alertBox} role="alert">
    <img
      src={getStaticUrl(AlertIconSvg)}
      className={styles.alertImage}
      alt=""
      role="presentation"
    />
    {error}
  </div>
)

LoginAlert.displayName = 'LoginAlert'
