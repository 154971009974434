import { getId } from 'app/frontend/helpers/current-user'
import {
  SHOW_GRADE_UPDATE_SNACKBAR,
  HIDE_GRADE_UPDATE_SNACKBAR,
  UPDATE_GRADE_UPDATE_SNACKBAR_STATUS,
  GradeUpdateSnackbarActions,
} from './grade-update-snackbar-actions'

export interface GradeUpdateSnackbarReducerState {
  userId: string
  sectionId?: string
  updatedAfter: number
  lastShownAt: number
}

const defaultState = {
  userId: null,
  sectionId: null,
  updatedAfter: null,
  lastShownAt: null,
}

export const getGradeUpdateSnackbarState = (
  state: any
): GradeUpdateSnackbarReducerState | undefined => {
  return state.global.ui.gradeupdatesnackbars
}

export function gradeUpdateSnackbarReducer(
  state: GradeUpdateSnackbarReducerState = defaultState,
  action: GradeUpdateSnackbarActions
): GradeUpdateSnackbarReducerState {
  switch (action.type) {
    case SHOW_GRADE_UPDATE_SNACKBAR:
      const now = Date.now()
      return {
        userId: getId(),
        sectionId: action.payload.sectionId,
        updatedAfter: state.updatedAfter === null ? now - 10000 : state.updatedAfter,
        lastShownAt: now,
      }
    case HIDE_GRADE_UPDATE_SNACKBAR:
      return defaultState
    case UPDATE_GRADE_UPDATE_SNACKBAR_STATUS:
      return {
        ...state,
        sectionId: action.payload.sectionId,
      }
    default:
      return state
  }
}
