import { useAssessmentSequencesWithSequenceOverrides } from './use-assessment-sequences-with-sequence-overrides'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import {
  calculateTotalAssessmentPoints,
  AssessmentSequenceWithOverride,
} from 'app/frontend/helpers/assignment'

export type UseTotalAssessmentPointsResults = RenderProps<{
  totalAssessmentPoints: number
  assessmentSequences: AssessmentSequenceWithOverride[]
}>

export const useTotalAssessmentPoints = (assessmentId: string): UseTotalAssessmentPointsResults => {
  const {
    loading,
    error,
    assessmentSequencesWithOverrides,
  } = useAssessmentSequencesWithSequenceOverrides(assessmentId)

  let totalAssessmentPoints: number
  if (assessmentSequencesWithOverrides) {
    totalAssessmentPoints = calculateTotalAssessmentPoints(assessmentSequencesWithOverrides)
  }
  return {
    loading,
    error,
    totalAssessmentPoints,
    assessmentSequences: assessmentSequencesWithOverrides,
  }
}
