/**
 * Wraps the connect-react-router default reducer - which only syncs location to the redux store -
 * to also extract react router path parameters and sync those to the redux store as well.
 *
 * This allows those parameters to be accessed outside of components (which have these extracted
 * path parameters provided through props by react-router).
 *
 * Based off of https://github.com/hoppula/react-router-redux-params
 *
 * Usage:
 *
 * // replace connected-react-router's reducer with this reducer in the routing key
 * const reducer = combineReducers(Object.assign({}, reducers, {
 *  router: syncReduxRouterReducer
 * }))
 *
 * // then sync routes, history and the store
 * syncReduxRouterWithStore(store, routes, browserHistory)
 *
 */

import * as React from 'react'
import { matchPath, match, Route, RouteProps } from 'react-router-dom'
import { Store } from 'redux'
import { connectRouter, LocationChangeAction, RouterState } from 'connected-react-router'
import { History, Location } from 'history'

export interface SyncReduxRouterReducerState<TRouteParams> extends RouterState {
  params: Partial<TRouteParams>
}

/**
 * Action dispatched whenever route history changes. Similar to LocationChangeAction, but
 * includes extracted route params in addition to the new route location.
 * Handled by the syncReduxRouterReducer to update the details of the current route in
 * the redux store.
 */
export enum UpdateLocationActionType {
  UpdateLocationWithParams = '@@router/UPDATE_LOCATION_WITH_PARAMS',
}
export interface UpdateLocationWithParamsAction<TRouteParams> {
  type: UpdateLocationActionType.UpdateLocationWithParams
  payload: {
    location: Location
    params: Partial<TRouteParams>
  }
}

function findRouteAndDispatchParamUpdate<TRouteParams>(
  location: Location,
  store: Store<any>,
  routes: JSX.Element
) {
  let matchedPath: match<TRouteParams>
  React.Children.forEach(routes.props.children, element => {
    const childRoute = (element as unknown) as Route<RouteProps>
    if (!React.isValidElement(childRoute) || !childRoute.props.path) {
      return
    }

    const { path, exact, strict } = childRoute.props
    if (matchedPath == null) {
      matchedPath = matchPath(location.pathname, { path, exact, strict })
    }
  })

  if (matchedPath) {
    const action: UpdateLocationWithParamsAction<TRouteParams> = {
      type: UpdateLocationActionType.UpdateLocationWithParams,
      payload: {
        location,
        params: matchedPath.params,
      },
    }
    store.dispatch(action)
  }
}

export const syncReduxRouterWithStore = <TRouteParams extends {}>(
  store: Store<any>,
  routes: JSX.Element,
  history: History
): any => {
  if (!routes) {
    return
  }

  history.listen(location => {
    findRouteAndDispatchParamUpdate<TRouteParams>(location, store, routes)
  })
  findRouteAndDispatchParamUpdate<TRouteParams>(history.location, store, routes)
}

export const syncReduxRouterReducer = <TRouteParams extends {}>(
  history: History
): ((
  state: SyncReduxRouterReducerState<TRouteParams>,
  action: UpdateLocationWithParamsAction<TRouteParams>
) => SyncReduxRouterReducerState<TRouteParams>) => {
  const routerReducer = connectRouter(history)

  return (
    state: SyncReduxRouterReducerState<TRouteParams>,
    action: UpdateLocationWithParamsAction<TRouteParams> | LocationChangeAction
  ) => {
    if (action.type === UpdateLocationActionType.UpdateLocationWithParams) {
      return {
        ...state,
        params: action.payload.params,
      }
    } else {
      return {
        ...state,
        ...routerReducer(state, action),
      }
    }
  }
}
