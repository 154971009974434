import { useQuery, ApolloError } from '@apollo/client'
import * as GET_LO_CONCEPT_BY_ID from './get-lo-concept-by-id.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseLoConceptByIdResults = RenderProps<{
  loading?: boolean
  error?: ApolloError
  loConcept: GQL.LoConcept
}>

export const useLoConceptById = (loConceptId: string): UseLoConceptByIdResults => {
  const { loading, error, data } = useQuery<
    GQL.GetLoConceptById.Query,
    GQL.GetLoConceptById.Variables
  >(GET_LO_CONCEPT_BY_ID, {
    variables: {
      loConceptId,
    },
  })

  return {
    loading,
    error,
    loConcept: data ? data.loConceptById : null,
  }
}
