import { isEmpty, memoize, sortBy } from 'lodash'

/**
 * Given a flat taxonomy and a taxon id,
 * returns an array containing the taxon id
 * and all ancestors, starting from the root
 */
export function getTaxonAncestors(flatTaxonomy, taxonId) {
  const taxon = flatTaxonomy[taxonId]
  let res = []

  if (!taxon) {
    return res
  }

  res.push(taxonId)

  if (taxon.parentId) {
    res = getTaxonAncestors(flatTaxonomy, taxon.parentId).concat(res)
  }

  return res
}

/**
 * Create list of taxons to start rendering with. These will usually
 * be chapter taxons. In the event the chapter taxon does not exist, just
 * start with the leaf taxon.
 *
 * A chapter in this definition is the closest parent of a leaf taxon and all its leaf taxon
 * descendants. This logic is here to support cases where taxonomies have leaf taxons at
 * different depths: it will find the lowest level taxons that have leaf children and use those
 * as chapters.
 *
 * @deprecated use a gql query that provides chapters instead of having the browser work through the taxonomy.
 * This logic has been moved to the backend, there's a variant of this method in brackend.
 */
export function getChapterTaxons(taxons: GQL.GetCoursepack.Taxons[]): GQL.GetCoursepack.Taxons[] {
  // Map taxons by id
  const taxonsById: { [taxonId: string]: GQL.GetCoursepack.Taxons } = {}
  taxons.forEach(t => (taxonsById[t.id] = t))

  // Find the top level taxon(s)
  const topLevelTaxons = sortBy(
    taxons.filter(t => !taxonsById[t.parentId]),
    'displayPosition'
  )

  // Those will be the chapters
  let taxonsToRender: GQL.GetCoursepack.Taxons[] = []

  // Find the chapters
  topLevelTaxons.forEach(t => {
    if (isEmpty(t.children)) {
      // Add the taxon as chapter because it doesn't have any children
      taxonsToRender.push(t)
    } else {
      const hasLeafChild = t.children.find(
        childId => taxonsById[childId] && isEmpty(taxonsById[childId].children)
      )
      if (hasLeafChild) {
        // Add the top level taxon as a chapter because it has at least one leaf taxon child
        taxonsToRender.push(t)
      } else {
        // Add the children as chapters
        sortBy(t.children, 'displayPosition').forEach(childId => {
          if (taxonsById[childId]) {
            taxonsToRender.push(taxonsById[childId])
          }
        })
      }
    }
  })

  // Now for every taxon to render we're going to find all the descendant leaf taxons
  taxonsToRender = taxonsToRender.map(t => {
    const toVisit = sortBy(t.children, 'displayPosition').map(
      childTaxonId => taxonsById[childTaxonId]
    )
    const leafTaxonIds: string[] = []

    let taxon
    while (toVisit.length > 0) {
      taxon = toVisit.shift()
      if (!taxon) {
        continue
      }

      if (!isEmpty(taxon.children)) {
        const children = taxon.children.map(childTaxonId => taxonsById[childTaxonId])
        // Add to the beginning of `toVisit` to ensure depth-first traversing and maintain the ordering
        toVisit.unshift.apply(toVisit, sortBy(children, 'displayPosition'))
      } else {
        // A taxon is a leaf taxon if it does not have children
        leafTaxonIds.push(taxon.id)
      }
    }

    return {
      ...t,
      children: leafTaxonIds,
    }
  })

  return taxonsToRender
}

type ChapterAndTopic = {
  chapter: GQL.ChapterTaxonFields.Fragment | null
  topic: GQL.TopicTaxonFields.Fragment | null
}

/**
 * Memoized helper to map topicId to chapter and topic from the taxonomy described by the given list of titles
 */
const getChapterAndTopicByTopicId = memoize((titles: GQL.TitleTaxonFields.Fragment[]): {
  [topicId: string]: ChapterAndTopic
} => {
  const chapterAndTopicByTopicId = {}
  for (const title of titles) {
    for (const chapter of title.chapters) {
      for (const topic of chapter.topics) {
        chapterAndTopicByTopicId[topic.id] = {
          chapter,
          topic,
        }
      }

      for (const section of chapter.sections) {
        for (const sectionTopic of section.topics) {
          chapterAndTopicByTopicId[sectionTopic.id] = {
            chapter,
            topic: sectionTopic,
          }
        }
      }
    }
  }

  return chapterAndTopicByTopicId
})

export const getChapterAndTopicForTopicId = (
  titles: GQL.TitleTaxonFields.Fragment[],
  topicId: string
): ChapterAndTopic => {
  const chapterAndTopicByTopicId = getChapterAndTopicByTopicId(titles)
  return (
    chapterAndTopicByTopicId[topicId] || {
      chapter: null,
      topic: null,
    }
  )
}

/**
 * @param topic taxon to search
 * @param learningObjectiveId id of the LO to retrieve
 */
export const getObjectiveForObjectiveId = (
  topic: GQL.TopicTaxonFields.Fragment,
  learningObjectiveId: string
): GQL.LearningObjectiveFields.Fragment | null => {
  if (!topic) {
    return null
  }

  for (const objective of topic.learningObjectives) {
    if (objective.id === learningObjectiveId) {
      return objective
    }
  }
  return null
}
