import { combineReducers } from 'redux'
import { API_GET_TAXON_STATS } from 'app/frontend/pages/teach/entities/taxon-stats/taxon-stats-actions'

export interface TaxonStatsByIdReducerState {
  [taxonId: string]: Teach.ITaxonStats
}

export type TaxonStatsReducerState = {
  byId: TaxonStatsByIdReducerState
}

const defaultState = {}
export function taxonStatsByIdReducer(
  state: TaxonStatsByIdReducerState = defaultState,
  action
): TaxonStatsByIdReducerState {
  if (!action) {
    return state
  }
  let taxonId

  switch (action.type) {
    case API_GET_TAXON_STATS.INIT:
      taxonId = action.args[0]
      return {
        ...state,
        [taxonId]: {
          ...state[taxonId],
          isFetching: true,
        },
      }
    case API_GET_TAXON_STATS.ERROR:
      taxonId = action.args[0]
      return {
        ...state,
        [taxonId]: {
          ...state[taxonId],
          isFetching: false,
          totalTimeEstimateSeconds: 25 * 60,
          totalQuestionsEstimate: 30,
          minimumQuestionsEstimate: 15,
        },
      }
    case API_GET_TAXON_STATS.SUCCESS:
      taxonId = action.args[0]
      return {
        ...state,
        [taxonId]: {
          ...state[taxonId],
          isFetching: false,
          totalTimeEstimateSeconds: action.response.totalTimeEstimate,
          totalQuestionsEstimate: action.response.totalQuestionsEstimate,
          minimumQuestionsEstimate: action.response.minimumQuestionsEstimate,
        },
      }
    default:
      return state
  }
}

export const getTaxonStats = (state, taxonId: Taxonomy.TaxonId) =>
  state.entities.taxonStats.byId[taxonId]

export default combineReducers<TaxonStatsReducerState>({
  byId: taxonStatsByIdReducer,
})
