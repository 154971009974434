import { Reducer } from 'redux'
import {
  ASSIGNMENT_ANIMATION_RUN,
  AssignmentMenuItemsActions,
  NEW_ASSIGNMENT_ADDED_ANIMATION,
} from './assignment-menu-items-actions'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'

export type IAssignmentMenuItemsReducerState = {
  newAssignmentAnimation: string
}

export function reducer(
  state: IAssignmentMenuItemsReducerState = { newAssignmentAnimation: '' },
  action: AssignmentMenuItemsActions
) {
  switch (action.type) {
    case NEW_ASSIGNMENT_ADDED_ANIMATION:
      return {
        ...state,
        newAssignmentAnimation: action.id,
      }
    case ASSIGNMENT_ANIMATION_RUN:
      return {
        ...state,
        newAssignmentAnimation: '',
      }
    default:
      return state
  }
}

/**
 * Get the redux new assignment animation value
 *
 * @param state - TeachControllerState
 * @returns the new assignment animation string
 */
export const getNewAssignmentAnimation = (state: TeachControllerState): string =>
  state.ui.newAssignments.newAssignmentAnimation

export default reducer as Reducer<IAssignmentMenuItemsReducerState>
