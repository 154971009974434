import * as React from 'react'
import * as _ from 'lodash'
import { TextInputMaterial, ITextInputProps } from './textinput'

interface IFormTextInputMaterialProps extends Partial<ITextInputProps> {
  field: any
  label: string
}

export const FormTextInputMaterial: React.FunctionComponent<IFormTextInputMaterialProps> = props => {
  return (
    <TextInputMaterial
      value={props.field.input.value}
      name={props.field.input.name}
      error={!props.field.meta.pristine && props.field.meta.error}
      onChange={val => props.field.input.onChange(val)}
      {..._.omit(props, 'field')}
      theme={props.theme}
    />
  )
}

export default FormTextInputMaterial
