import * as React from 'react'
import * as classnames from 'classnames'
import * as styles from './modal-body.css'
import { Box, BoxProps } from '@mui/material'

type Props = {
  className?: string
}

type SpacerProps = BoxProps

/**
 * When the ModalFooter is sticky, it is helpful to use a FooterSpacer in the ModalBody
 * to allocate extra space for the footer. e.g.
 *
 * <ModalBody>
 *   ...
 *   <FooterSpacer />
 * </ModalBody>
 * <ModalFooter sticky={true}>
 *   ...
 * </ModalFooter>
 */
export const FooterSpacer: React.FunctionComponent<SpacerProps> = ({ ...props }) => (
  <Box height={'4.25rem'} flexGrow={0} flexShrink={0} {...props} />
)

export const ModalBody: React.FunctionComponent<Props> = ({ className, children }) => (
  <div className={classnames(styles.modalBody, className)}>{children}</div>
)

ModalBody.displayName = 'ModalBody'

export default ModalBody
