import {
  QuestionViewPreferencesOptionsView,
  QuestionViewPreferencesOptionsViewDensity,
} from './helper'
/**
 * Available actions for QuestionViewOrderPreferences
 */
export enum QuestionViewPreferencesAction {
  SetQuestionViewPreferences = 'SET_QUESTION_VIEW_ORDER_PREFERENCES',
  SetQuestionDisplayDensity = 'SET_QUESTION_DISPLAY_DENSITY',
}

/**
 * Definition for setQuestionViewOrderPreferences
 */
export type SetQuestionViewPreferencesAction = {
  type: QuestionViewPreferencesAction.SetQuestionViewPreferences
  viewBy: QuestionViewPreferencesOptionsView
  displayDensity: QuestionViewPreferencesOptionsViewDensity
}

/**
 * Definition for SetQuestionDisplayDensityAction
 */
export type SetQuestionDisplayDensityAction = {
  type: QuestionViewPreferencesAction.SetQuestionDisplayDensity
  questionId: string
  displayDensity: QuestionViewPreferencesOptionsViewDensity
}

/**
 * Function that execute when dispatch setQuestionViewOrderPreferencesAction
 * @param viewBy - The parameter that holds question type
 * @param displayDensity - The parameter that holds number of question per LO
 */
export const setQuestionViewPreferences = (
  viewBy: QuestionViewPreferencesOptionsView,
  displayDensity: QuestionViewPreferencesOptionsViewDensity
): SetQuestionViewPreferencesAction => ({
  type: QuestionViewPreferencesAction.SetQuestionViewPreferences,
  viewBy,
  displayDensity,
})

/**
 * Function that execute when dispatch SetQuestionDisplayDensityAction
 * @param questionId - Id of the EXPANDED or COLLAPSED sequence
 * @param displayDensity - EXPANDED or COLLAPSED
 */
export const setQuestionDisplayDensity = (
  questionId: string,
  displayDensity: QuestionViewPreferencesOptionsViewDensity
): SetQuestionDisplayDensityAction => ({
  type: QuestionViewPreferencesAction.SetQuestionDisplayDensity,
  questionId,
  displayDensity,
})
