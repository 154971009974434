import { API_GET_COURSE } from 'app/frontend/pages/material/learn/learn-actions'
import { API_SUBMIT_JOIN_COURSE_TOKEN } from 'app/frontend/pages/material/learn/modals/join-course/actions'
import { Action } from 'redux'

// a course can be undefined in the case of assignment preview
export type CoursesReducer = {
  [id: string]: Commons.IRetailClass
}

export default (
  state: CoursesReducer = {},
  action: Action & { [id: string]: any }
): CoursesReducer => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_COURSE.SUCCESS:
      const { coursesById } = action.response
      return Object.assign({}, state, coursesById)
    case API_SUBMIT_JOIN_COURSE_TOKEN.SUCCESS:
      const { courseId } = action.response
      if (courseId in state) {
        return state
      }
      return {
        ...state,
        [courseId]: {
          id: courseId,
          paths: [],
        },
      }
    default:
      return state
  }
}
