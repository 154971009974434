import { useQuery, QueryHookOptions } from '@apollo/client'
import * as GET_SECTION from './get-section.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'

export type UseSectionResults = RenderProps<GQL.GetSection.Query>

export const useSection = (
  sectionId: string,
  opts?: QueryHookOptions<GQL.GetSection.Query, GQL.GetSection.Variables>
): UseSectionResults => {
  const { loading, error, data } = useQuery<GQL.GetSection.Query, GQL.GetSection.Variables>(
    GET_SECTION,
    {
      ...opts,
      variables: {
        id: sectionId,
        skip: !sectionId,
      },
    }
  )

  return {
    loading,
    error,
    ...data,
  }
}
