import { Reducer } from 'redux'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_GET_ENROLLMENT_CONTENT,
  API_GET_SEQUENCE_PREVIEW,
  LearnFlowLoadAction,
  UPDATE_CONTENT,
  UpdateContentAction,
} from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { ComponentLoadAction } from 'app/frontend/pages/material/learn/ui/ui-actions'
import {
  UpdateModeAction,
  UPDATE_MODE,
} from 'app/frontend/pages/material/learn/ui/learn-flow/mode-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export type CompoundsReducerState = Learn.IStateAndContent[]
type CompoundAction =
  | (LearnFlowLoadAction & ApiSuccessAction)
  | UpdateContentAction
  | ComponentLoadAction

/**
 * Get the focusedTopicId of the first compound in the store
 */
export const getFocusedTopicIdFromCompounds = (state: LearnControllerState): string =>
  state.entities.learnFlow.compounds?.[0]?.compoundInstance?.focusedTopicId

/**
 * Get the focusedLoId of the first compound in the store
 */
export const getFocusedLoIdFromCompounds = (state: LearnControllerState): string =>
  state.entities.learnFlow.compounds?.[0]?.compoundInstance?.focusedLoId

/**
 * Get the covered LO of the first compound in the store
 */
export const getCoveredLoIdFromCompounds = (state: LearnControllerState): string =>
  state.entities.learnFlow.compounds?.[0]?.atom?.learningObjectiveId

/**
 * Get the source of the first compound in the store
 */
export const getSourceFromCompounds = (
  state: LearnControllerState
): Commons.CompoundInstanceSource =>
  state.entities.learnFlow.compounds?.[0]?.compoundInstance?.source

export const reducer = (
  state: CompoundsReducerState = [],
  action: CompoundAction | UpdateModeAction
): CompoundsReducerState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_ENROLLMENT_CONTENT.SUCCESS:
    case API_GET_SEQUENCE_PREVIEW.SUCCESS:
      return (action as LearnFlowLoadAction & ApiSuccessAction).response.states || state
    case UPDATE_CONTENT:
      return (action as UpdateContentAction).content.compounds
    case UPDATE_MODE:
      return (action as UpdateModeAction).mode === 'cover' ? [] : state
    default:
      return state
  }
}

export default reducer as Reducer<CompoundsReducerState>
