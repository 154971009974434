import { Reducer, Action } from 'redux'
import * as _ from 'lodash'

import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_GET_SAMPLE_SEQUENCES,
  UPDATE_SAMPLE_SEQUENCES,
  UpdateSampleSequencesAction,
} from 'app/frontend/pages/teach/browse/browse-actions'
import { ASSESSES, TEACHES } from 'app/frontend/data/purpose-type'

export interface ISampleSequencesById {
  [learningObjectiveId: string]: ISampleSequences
}
export interface ISampleSequencesByConceptId {
  [learningObjectiveId: string]: {
    [conceptId: string]: ISampleSequences
  }
}

export const getSampleSequences = (state): ISampleSequencesById => {
  return state.entities.learningObjectives.sampleSequences.byId
}

export const getSampleSequencesById = (
  state: any,
  learningObjectiveId: string
): Content.ISequence => {
  return _.get(getSampleSequences(state), `${learningObjectiveId}`, null)
}

export const getSampleSequencesByConceptId = (
  state: any,
  objectiveId: string,
  conceptId: string
) => {
  const sampleSequencesById = getSampleSequencesById(state, objectiveId)
  return sampleSequencesById ? sampleSequencesById[conceptId] : null
}

export const getSampleSequencesByIdContentFetching = (
  state,
  learningObjectiveId: string,
  purposeType
): boolean => {
  return _.get(
    getSampleSequencesById(state, learningObjectiveId),
    `${purposeType}.isContentFetching`
  ) as boolean
}

type SampleSequencesState = {
  sequenceIds: string[]
  totalResultCount: number
  page: number
  isContentFetching: boolean
}

interface ISampleSequences {
  assessments?: SampleSequencesState
  instructions?: SampleSequencesState
}

export const getAssessmentsAndInstructionsState = (
  state: ISampleSequences = {},
  purpose: string,
  page: number,
  content: Commons.ContentForSearchFilters
): ISampleSequences | {} => {
  let storedSequenceIds, returnedSequenceIds, sequenceIds

  switch (purpose) {
    case ASSESSES:
      storedSequenceIds = state?.assessments?.sequenceIds || []
      returnedSequenceIds = content?.sequences?.map(seq => seq.id) || []
      sequenceIds = _.uniq([...storedSequenceIds, ...returnedSequenceIds])

      return {
        ...state,
        assessments: {
          sequenceIds,
          totalResultCount: content.totalResultCount,
          page,
          isContentFetching: false,
        },
      }
    case TEACHES:
      storedSequenceIds = state?.instructions?.sequenceIds || []
      returnedSequenceIds = content?.sequences?.map(seq => seq.id) || []
      sequenceIds = _.uniq([...storedSequenceIds, ...returnedSequenceIds])

      return {
        ...state,
        instructions: {
          sequenceIds,
          totalResultCount: content.totalResultCount,
          page,
          isContentFetching: false,
        },
      }
    default:
      return state
  }
}

const defaultState = {}
const sampleSequences = (
  state: ISampleSequencesById | ISampleSequencesByConceptId = defaultState,
  action: Action & ApiSuccessAction & UpdateSampleSequencesAction
): ISampleSequencesById => {
  let searchFilters, learningObjectiveId, learningOjectiveState

  switch (action.type) {
    case API_GET_SAMPLE_SEQUENCES.SUCCESS:
      searchFilters = action.args[1]
      learningObjectiveId = searchFilters.learningObjectiveId
      learningOjectiveState = state[learningObjectiveId] || {}
      return {
        [learningObjectiveId]: getAssessmentsAndInstructionsState(
          learningOjectiveState,
          searchFilters.purposeType,
          searchFilters.pageNum,
          action.response.content
        ),
      }
    case API_GET_SAMPLE_SEQUENCES.INIT:
      learningObjectiveId = action.args[1].learningObjectiveId
      const purposeTypeProperty =
        action.args[1].purposeType === 'ASSESSES' ? 'assessments' : 'instructions'

      return {
        ...state,
        [learningObjectiveId]: {
          [purposeTypeProperty]: {
            ..._.get(state, `[${learningObjectiveId}].${purposeTypeProperty}`, {}),
            isContentFetching: true,
          },
        },
      }
    case UPDATE_SAMPLE_SEQUENCES:
      const { purpose, page, content, conceptId } = action as UpdateSampleSequencesAction
      learningObjectiveId = action.objectiveId
      learningOjectiveState = state[learningObjectiveId] || {}

      const conceptState =
        learningOjectiveState && learningOjectiveState[conceptId]
          ? learningOjectiveState[conceptId]
          : {}

      return {
        ...state,
        [learningObjectiveId]: {
          ...learningOjectiveState,
          [conceptId]: getAssessmentsAndInstructionsState(conceptState, purpose, page, content),
        },
      }
    default:
      return state
  }
}

export default sampleSequences as Reducer<ISampleSequencesById>
