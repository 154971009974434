// Add types that should not be cached to this list
import { TypePolicy } from '@apollo/client'

export const cacheBlockList = {
  ActivityMetrics: {
    keyFields: false,
  } as TypePolicy,
  ActivitySummary: {
    keyFields: false,
  } as TypePolicy,
  AdaptiveAssignmentMetrics: {
    keyFields: false,
  } as TypePolicy,
  AdminResetPasswordToken: {
    keyFields: false,
  } as TypePolicy,
  AggregateEnrollmentMetrics: {
    keyFields: false,
  } as TypePolicy,
  AssignmentCountsForCourse: {
    keyFields: false,
  } as TypePolicy,
  AssignmentCursorPaginated: {
    keyFields: false,
  } as TypePolicy,
  AtomAttribution: {
    keyFields: false,
  } as TypePolicy,
  AtomDatasetData: {
    keyFields: false,
  } as TypePolicy,
  AtomDesmosGraphData: {
    keyFields: false,
  } as TypePolicy,
  AtomExternalUrlData: {
    keyFields: false,
  } as TypePolicy,
  AtomId: {
    keyFields: false,
  } as TypePolicy,
  AtomImageData: {
    keyFields: false,
  } as TypePolicy,
  AtomInstructionBlock: {
    keyFields: false,
  } as TypePolicy,
  AtomLearnosityBlock: {
    keyFields: false,
  } as TypePolicy,
  AtomLearnosityContent: {
    keyFields: false,
  } as TypePolicy,
  AtomMediaMetadata: {
    keyFields: false,
  } as TypePolicy,
  AtomMultiChoiceAnswer: {
    keyFields: false,
  } as TypePolicy,
  AtomMultiChoiceBlock: {
    keyFields: false,
  } as TypePolicy,
  AtomVariationForSequenceId: {
    keyFields: false,
  } as TypePolicy,
  AtomVideoData: {
    keyFields: false,
  } as TypePolicy,
  AverageStudentLoMastery: {
    keyFields: false,
  } as TypePolicy,
  CompletionEstimatesForLos: {
    keyFields: false,
  } as TypePolicy,
  ConceptCoverageType: {
    keyFields: false,
  } as TypePolicy,
  CopyCourseId: {
    keyFields: false,
  } as TypePolicy,
  CourseAssignmentDates: {
    keyFields: false,
  } as TypePolicy,
  CourseAssignmentOrder: {
    keyFields: false,
  } as TypePolicy,
  CourseCursorPaginated: {
    keyFields: false,
  } as TypePolicy,
  CourseInstructorCursorPaginated: {
    keyFields: false,
  } as TypePolicy,
  DeletedResultOverride: {
    keyFields: false,
  } as TypePolicy,
  DeletedSequenceOverride: {
    keyFields: false,
  } as TypePolicy,
  EnrollmentAnalytics: {
    keyFields: false,
  } as TypePolicy,
  EnrollmentAnswerAvailability: {
    keyFields: false,
  } as TypePolicy,
  EnrollmentAttemptsForUserAndAssessmentCursorPaginated: {
    keyFields: false,
  } as TypePolicy,
  EnrollmentStatusAndProgress: {
    keyFields: false,
  } as TypePolicy,
  EnrollmentTopicWorkRemaining: {
    keyFields: false,
  } as TypePolicy,
  GoalType: {
    keyFields: false,
  } as TypePolicy,
  HistoryQuestionCount: {
    keyFields: false,
  } as TypePolicy,
  LOMastery: {
    keyFields: false,
  } as TypePolicy,
  LastAttemptDeleted: {
    keyFields: false,
  } as TypePolicy,
  LearningObjectiveToAssignments: {
    keyFields: false,
  } as TypePolicy,
  LearningObjectivesWithConceptBlacklist: {
    keyFields: false,
  } as TypePolicy,
  LearnosityCheat: {
    keyFields: false,
  } as TypePolicy,
  LoStatusAndProgress: {
    keyFields: false,
  } as TypePolicy,
  MarkedDoneEnrollment: {
    keyFields: false,
  } as TypePolicy,
  MultipleChoiceCheat: {
    keyFields: false,
  } as TypePolicy,
  NextSectionDueDates: {
    keyFields: false,
  } as TypePolicy,
  PathLearningObjectiveType: {
    keyFields: false,
  } as TypePolicy,
  PathSequenceStats: {
    keyFields: false,
  } as TypePolicy,
  Plan: {
    keyFields: false,
  } as TypePolicy,
  PredictedScore: {
    keyFields: false,
  } as TypePolicy,
  PredictedScoreForLo: {
    keyFields: false,
  } as TypePolicy,
  PredictedScoresForAssessment: {
    keyFields: false,
  } as TypePolicy,
  PredictedScoresForStudent: {
    keyFields: false,
  } as TypePolicy,
  QuestionJson: {
    keyFields: false,
  } as TypePolicy,
  QuestionStatsForAssessment: {
    keyFields: false,
  } as TypePolicy,
  QuizAssignmentMetrics: {
    keyFields: false,
  } as TypePolicy,
  QuizConfiguration: {
    keyFields: false,
  } as TypePolicy,
  RemovedCourseInstructor: {
    keyFields: false,
  } as TypePolicy,
  RemovedSectionInstructor: {
    keyFields: false,
  } as TypePolicy,
  RemovedStudent: {
    keyFields: false,
  } as TypePolicy,
  ScreenshotUrl: {
    keyFields: false,
  } as TypePolicy,
  ScreenshotUrlField: {
    keyFields: false,
  } as TypePolicy,
  SectionAssignmentCursorPaginated: {
    keyFields: false,
  } as TypePolicy,
  SectionAssignmentDates: {
    keyFields: false,
  } as TypePolicy,
  SectionAssignmentOrder: {
    keyFields: false,
  } as TypePolicy,
  SectionCursorPaginated: {
    keyFields: false,
  } as TypePolicy,
  SectionInstructorCursorPaginated: {
    keyFields: false,
  } as TypePolicy,
  SequenceIdOffsetPaginated: {
    keyFields: false,
  } as TypePolicy,
  SequenceIdsForConceptIds: {
    keyFields: false,
  } as TypePolicy,
  SequencesForSearchFilters: {
    keyFields: false,
  } as TypePolicy,
  StateAndContent: {
    keyFields: false,
  } as TypePolicy,
  StateAndOptionalContent: {
    keyFields: false,
  } as TypePolicy,
  StatusCounts: {
    keyFields: false,
  } as TypePolicy,
  StudentReviewCenterMetrics: {
    keyFields: false,
  } as TypePolicy,
  StudentStatusBreakdownForLearningObjective: {
    keyFields: false,
  } as TypePolicy,
  StudentsReviewingAssessmentCount: {
    keyFields: false,
  } as TypePolicy,
  StudentsReviewingLoCountForAssessment: {
    keyFields: false,
  } as TypePolicy,
  TaskStatusCursor: {
    keyFields: false,
  } as TypePolicy,
  TaskStatusMetadata: {
    keyFields: false,
  } as TypePolicy,
  TaskStatusRecord: {
    keyFields: false,
  } as TypePolicy,
  TaskStatusSearchResponse: {
    keyFields: false,
  } as TypePolicy,
  UpdatedEnrollmentExtensions: {
    keyFields: false,
  } as TypePolicy,
  VerifyCourseDate: {
    keyFields: false,
  } as TypePolicy,
  VerifyCourseEndDate: {
    keyFields: false,
  } as TypePolicy,
  VerifyCourseStartDate: {
    keyFields: false,
  } as TypePolicy,
  hasStudentStartedAssignment: {
    keyFields: false,
  } as TypePolicy,
  TransferredStudent: {
    keyFields: false,
  } as TypePolicy,
  AreMetricsAvailableForAssessment: {
    keyFields: false,
  } as TypePolicy,
  ContentFeedback: {
    keyFields: false,
  } as TypePolicy,
  WithinAssignmentPeriodForSection: {
    keyFields: false,
  } as TypePolicy,
}
