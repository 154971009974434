export const START_DOWNLOAD_COMMON_CARTRIDGE = 'START_DOWNLOAD_COMMON_CARTRIDGE'
export function startDownloadCommonCartridge() {
  return {
    type: START_DOWNLOAD_COMMON_CARTRIDGE,
  }
}

export const FINISH_DOWNLOAD_COMMON_CARTRIDGE = 'FINISH_DOWNLOAD_COMMON_CARTRIDGE'
export function finishDownloadCommonCartridge(success) {
  return {
    type: FINISH_DOWNLOAD_COMMON_CARTRIDGE,
    success,
  }
}
