import * as actions from './forgot-password-actions'
import { forgotPassword, resetPassword } from 'app/frontend/api/account'
import { put } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { startSubmit, stopSubmit } from 'redux-form'
import { apiCall } from 'app/frontend/api/api-redux'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import { push } from 'connected-react-router'
import { t } from 'i18next'
import { catchWrapper } from 'app/frontend/components/material/helpers'
import CODES from 'app/frontend/api/codes'

/**
 * Submits user-provided email address for password reset
 */
export function* forgotPasswordIterator(action): SagaIterator {
  yield put(startSubmit('forgot-password'))

  yield* catchWrapper(function* () {
    yield* apiCall(forgotPassword, actions.API_FORGOT_PASSWORD, action.data)

    // Show confirmation screen that email was sent
    yield put(showSnackbar({ message: t('forgot_password:email_sent2') }))
    yield put(actions.showSentEmail())
  })

  // No errors need to be passed back to the form --
  //  cases such as non-existent emails should fail silently
  //  for security purposes.
  yield put(stopSubmit('forgot-password', {}))
}

/**
 * Reset a user's password
 */
export function* resetPasswordIterator(action): SagaIterator {
  yield put(startSubmit('reset-password'))

  try {
    yield* apiCall(resetPassword, actions.API_RESET_PASSWORD, action.data)
    yield put(push('/login'))
    yield put(showSnackbar({ message: t('forgot_password:password_updated') }))
  } catch (e) {
    const error = e.json && e.json.error
    if (error === CODES.INVALID_TOKEN) {
      yield put(showSnackbar({ message: t('forgot_password:expired_link') }))
    } else {
      yield put(showSnackbar({ message: t('generic_error') }))
    }
  }

  yield put(stopSubmit('reset-password', {}))
}
