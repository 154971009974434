import * as React from 'react'
import GoogleRecaptchaWidget from 'app/frontend/components/google-recaptcha-widget'

import * as styles from './recaptcha-widget.css'

interface IRecaptchaWidgetProps {
  recaptcha: any
}

const RecaptchaWidget: React.FunctionComponent<IRecaptchaWidgetProps> = ({ recaptcha }) => {
  if (recaptcha.meta.error || recaptcha.input.value) {
    return (
      <div className={styles.recaptchaWidget}>
        <GoogleRecaptchaWidget {...recaptcha.input} />
      </div>
    )
  }

  return null
}

RecaptchaWidget.displayName = 'RecaptchaWidget'

export default RecaptchaWidget
