import * as React from 'react'
import { QuestionScore } from 'app/frontend/compositions/ux/question-points'
import { tns } from 'app/frontend/helpers/translations/i18n'
import * as styles from './question-heading.css'

const t = tns('atom_variation')
interface Props {
  maxPoints: number
  score: number
  questionNumber: number
}

export const QuestionHeadingWithScore: React.FunctionComponent<Props> = ({
  maxPoints,
  score,
  questionNumber,
}) => {
  return (
    <span className={styles.questionHeadingWrapper}>
        <span data-test="question-number" className={styles.questionHeading}>
            {t('question_number', { number: questionNumber })}
        </span>
        <QuestionScore maxPoints={maxPoints} score={score} />
    </span>
  )
}

QuestionHeadingWithScore.displayName = 'QuestionHeadingWithScore'
