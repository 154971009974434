import { MutationHookOptions, useMutation } from '@apollo/client'
import * as CUSTOM_ASSESSMENT_VARIATION_DELETE from './custom-assessment-variation-delete.gql'

export const useDeleteCustomAssessmentVariation = (
  opts?: MutationHookOptions<
    GQL.DeleteCustomItemVariationFromAssessment.Mutation,
    GQL.DeleteCustomItemVariationFromAssessment.Variables
  >
) => {
  return useMutation<
    GQL.DeleteCustomItemVariationFromAssessment.Mutation,
    GQL.DeleteCustomItemVariationFromAssessment.Variables
  >(CUSTOM_ASSESSMENT_VARIATION_DELETE, { ...opts })
}
