
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LearningObjectiveToAssignmentsFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LearningObjectiveToAssignments"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"learningObjectiveId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"assignmentIds"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":122}};
    doc.loc.source = {"body":"fragment LearningObjectiveToAssignmentsFields on LearningObjectiveToAssignments {\n  learningObjectiveId\n  assignmentIds\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
