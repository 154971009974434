import { SHOW_SNACKBAR, HIDE_SNACKBAR, SnackbarActions } from './snackbar-actions'
import { ISnackbarProps } from './snackbar'
import uuid = require('uuid')

export type SnackbarContainerState = ISnackbarProps[]

const defaultState = []

export default (
  state: SnackbarContainerState = defaultState,
  action: SnackbarActions
): SnackbarContainerState => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return state.concat([{ ...action.options, key: uuid.v4() }])
    case HIDE_SNACKBAR:
      return state.slice(1)
    default:
      return state
  }
}
