import { Action } from 'redux'

export namespace InstitutionsComponentActions {
  export enum Actions {
    UPDATE_INSTITUTIONS_QUERY = 'UPDATE_INSTITUTIONS_QUERY',
    UPDATE_INSTITUTIONS_SORT_BY = 'UPDATE_INSTITUTIONS_SORT_BY',
    UPDATE_INSTITUTIONS_SORT_DIRECTION = 'UPDATE_INSTITUTIONS_SORT_DIRECTION',
    UPDATE_INSTITUTIONS_DEBOUNCED_QUERY = 'UPDATE_INSTITUTIONS_DEBOUNCED_QUERY',
    TOGGLE_INCLUDE_TEST_INSTITUTIONS = 'TOGGLE_INCLUDE_TEST_INSTITUTIONS',
  }

  export interface UpdateInstitutionsQueryAction extends Action {
    type: Actions.UPDATE_INSTITUTIONS_QUERY
    query: string
  }

  export function updateQuery(query: string): UpdateInstitutionsQueryAction {
    return {
      type: Actions.UPDATE_INSTITUTIONS_QUERY,
      query,
    }
  }

  export interface UpdateInstitutionsSortByAction extends Action {
    type: Actions.UPDATE_INSTITUTIONS_SORT_BY
    sortBy: string
  }

  export const updateSortBy = (sortBy: string): UpdateInstitutionsSortByAction => ({
    type: Actions.UPDATE_INSTITUTIONS_SORT_BY,
    sortBy,
  })

  export interface UpdateInstitutionsSortDirectionAction extends Action {
    type: Actions.UPDATE_INSTITUTIONS_SORT_DIRECTION
    sortDirection: string
  }

  export const updateSortDirection = (
    sortDirection: string
  ): UpdateInstitutionsSortDirectionAction => ({
    type: Actions.UPDATE_INSTITUTIONS_SORT_DIRECTION,
    sortDirection,
  })

  export interface UpdateDebouncedQueryAction extends Action {
    type: Actions.UPDATE_INSTITUTIONS_DEBOUNCED_QUERY
    query: string
  }

  export function updateDebouncedQuery(query: string): UpdateDebouncedQueryAction {
    return {
      type: Actions.UPDATE_INSTITUTIONS_DEBOUNCED_QUERY,
      query,
    }
  }

  export interface ToggleIncludeTestInstitutionsAction extends Action {
    type: Actions.TOGGLE_INCLUDE_TEST_INSTITUTIONS
  }

  export const toggleIncludeTestInstitutions = (): ToggleIncludeTestInstitutionsAction => ({
    type: Actions.TOGGLE_INCLUDE_TEST_INSTITUTIONS,
  })

  export type AllActions = UpdateInstitutionsQueryAction &
    UpdateDebouncedQueryAction &
    UpdateInstitutionsSortByAction &
    UpdateInstitutionsSortDirectionAction &
    ToggleIncludeTestInstitutionsAction &
    Action
}
