import { Reducer } from 'redux'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { ApiSuccessAction } from 'app/frontend/api/api-redux'
import {
  API_START_QUIZ,
  API_GET_QUIZ,
  StartQuizAction,
} from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { UiActionType, ComponentLoadAction } from 'app/frontend/pages/material/learn/ui/ui-actions'

export type ActiveIdState = {
  activeId: string
}

export const getActiveId = (state: LearnControllerState) => state.entities.quizFlow.activeId

export function activeIdReducer(
  state: ActiveIdState = null,
  action: (StartQuizAction & ComponentLoadAction) & ApiSuccessAction
) {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_QUIZ.SUCCESS:
      const { enrollment } = (action as ApiSuccessAction).response
      if (enrollment) {
        return enrollment.pathId
      } else {
        return state
      }
    case API_START_QUIZ.SUCCESS:
      return action.response.enrollment.pathId
    case UiActionType.ComponentLoad:
      if (action.name === 'QUIZ_COVER') {
        return null
      }
    default:
      return state
  }
}

export default activeIdReducer as Reducer<ActiveIdState>
