import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { HeroTitleNav } from 'app/frontend/compositions/data/hero-title-nav'
import { Icon } from 'app/frontend/components/material/icon'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import {
  allCoursesRoute,
  courseManageRoute,
  courseRoute,
  sectionManageRoute,
  sectionRoute,
} from 'app/frontend/pages/material/teach/get-teach-routes'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { ParentEntityType } from 'app/typings/commons'
import { useAssessmentBuilderPermissionState } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-builder-permission-state/use-assessment-builder-permission-state'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import {
  TEACH_COURSE_ASSESSMENT_SETTINGS,
  TEACH_SECTION_ASSESSMENT_SETTINGS,
} from 'app/frontend/data/mixpanel-events'
import { mergeAssignmentOverride } from 'app/frontend/helpers/assignment'
import { AssignmentTag } from 'app/frontend/compositions/ux/assignment-tag'
import HeroDates from './hero-dates'
import * as styles from './hero.css'
import { ASSESSMENT_SETTINGS_MODAL } from '../settings-modal/settings-modal'

const t = tns('teach:assessment_builder')
const tBreadcrumb = tns('teach:breadcrumb')

type Props = {
  section?: GQL.GetSection.Section
  course: GQL.GetCourse.Course
  assessment?: GQL.GetAssignment.Assignment
  assignmentOverrides?: GQL.GetAssignmentOverride.AssignmentOverride
}
export const Hero: React.FunctionComponent<Props> = ({
  assessment,
  course,
  section,
  assignmentOverrides,
}) => {
  const dispatch = useDispatch()
  const {
    hasStudentStartedAssignment,
    isCourseAssignmentOnSection,
    hasEditPermission,
  } = useAssessmentBuilderPermissionState()

  const onSettings = () => {
    const { id, sectionId } = assessment
    // determine the `parentEntity` type
    const parentEntityType = sectionId ? ParentEntityType.Section : ParentEntityType.Course
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_SETTINGS
        : TEACH_SECTION_ASSESSMENT_SETTINGS,
      id
    )
    // open settings modal
    dispatch(
      showModal(ASSESSMENT_SETTINGS_MODAL, {
        assessment,
        course,
        section,
        isCourseAssignmentOnSection,
        hasStudentStartedAssignment,
        hasEditPermission,
        assignmentOverrides,
      })
    )
  }

  if (!assessment || !course) {
    return null
  }

  return (
    <div className={styles.heroWrapper} data-test="assessment-builder-hero">
      <div className={styles.titleWrapper}>
        <HeroTitleNav
          headingLabel={assessment.name}
          breadcrumbs={[
            {
              label: tBreadcrumb('all_courses'),
              path: allCoursesRoute(),
            },
            {
              label: !section ? tBreadcrumb('course') : tBreadcrumb('section'),
              path: !section ? courseRoute(course.id) : sectionRoute(section.id),
            },
            {
              label: tBreadcrumb('edit_assessment', { label: assessment.label }),
            },
          ]}
          backPath={!section ? courseManageRoute(course.id) : sectionManageRoute(section.id)}
          backLabel={t('back_label')}
          backDataBi={!section ? 'back-to-course' : 'back-to-section'}
        />
        <div>
          <ButtonMaterial
            icon={<Icon name="icon-settings" size="large" className={styles.gearIcon} />}
            label={t('settings')}
            theme="bordered"
            onClick={onSettings}
            data-bi="assessment-settings-button"
          />
        </div>
      </div>
      <Box marginBottom={2}>
        <AssignmentTag
          type={assessment.type}
          label={assessment.label}
          isPrintable={assessment.quizConfiguration.printable}
          dataBi="assessment-builder-hero-tag"
        />
      </Box>
      <HeroDates assessment={mergeAssignmentOverride(assessment, assignmentOverrides)} />
    </div>
  )
}

export default Hero
