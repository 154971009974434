import { Action } from 'redux'

export namespace UsersComponentActions {
  export enum Actions {
    UPDATE_USERS_QUERY = 'UPDATE_USERS_QUERY',
    UPDATE_USERS_DEBOUNCED_QUERY = 'UPDATE_USERS_DEBOUNCED_QUERY',
    TOGGLE_ACTION_MENU = 'TOGGLE_ACTION_MENU',
    UPDATE_USERS_SORT_BY = 'UPDATE_USERS_SORT_BY',
    UPDATE_USERS_SORT_DIRECTION = 'UPDATE_USERS_SORT_DIRECTION',
    UPDATE_USERS_INSTITUTION_ID = 'UPDATE_USERS_INSTITUTION_ID',
    TOGGLE_INCLUDE_TEST_USERS = 'TOGGLE_INCLUDE_TEST_USERS',
  }

  export interface CreateInstructorAccountObj {
    firstName: string
    lastName: string
    email: string
    institutionId: string
  }

  export interface UpdateUsersInstitutionIdAction extends Action {
    type: Actions.UPDATE_USERS_INSTITUTION_ID
    institutionId: string | undefined
  }

  export function updateInstitutionId(
    institutionId: string | undefined
  ): UpdateUsersInstitutionIdAction {
    return {
      type: Actions.UPDATE_USERS_INSTITUTION_ID,
      institutionId,
    }
  }

  export interface UpdateUsersQueryAction extends Action {
    type: Actions.UPDATE_USERS_QUERY
    query: string
  }

  export function updateQuery(query: string): UpdateUsersQueryAction {
    return {
      type: Actions.UPDATE_USERS_QUERY,
      query,
    }
  }

  export interface UpdateUsersDebouncedQueryAction extends Action {
    type: Actions.UPDATE_USERS_DEBOUNCED_QUERY
    query: string
  }

  export function updateDebouncedQuery(query: string): UpdateUsersDebouncedQueryAction {
    return {
      type: Actions.UPDATE_USERS_DEBOUNCED_QUERY,
      query,
    }
  }

  type ForceToggle = 'SHOW' | 'HIDE'
  export interface ToggleActionMenuAction extends Action {
    type: Actions.TOGGLE_ACTION_MENU
    forceToggle?: ForceToggle
  }

  export const toggleActionMenu = (forceToggle?: ForceToggle): ToggleActionMenuAction => {
    return {
      type: Actions.TOGGLE_ACTION_MENU,
      forceToggle,
    }
  }

  export interface UpdateUsersSortByAction extends Action {
    type: Actions.UPDATE_USERS_SORT_BY
    sortBy: string
  }

  export const updateSortBy = (sortBy: string): UpdateUsersSortByAction => ({
    type: Actions.UPDATE_USERS_SORT_BY,
    sortBy,
  })

  export interface UpdateUsersSortDirectionAction extends Action {
    type: Actions.UPDATE_USERS_SORT_DIRECTION
    sortDirection: string
  }

  export const updateSortDirection = (sortDirection: string): UpdateUsersSortDirectionAction => ({
    type: Actions.UPDATE_USERS_SORT_DIRECTION,
    sortDirection,
  })

  export interface ToggleIncludeTestUsersAction extends Action {
    type: Actions.TOGGLE_INCLUDE_TEST_USERS
  }

  export const toggleIncludeTestUsers = (): ToggleIncludeTestUsersAction => ({
    type: Actions.TOGGLE_INCLUDE_TEST_USERS,
  })

  export type AllActions = UpdateUsersQueryAction &
    UpdateUsersDebouncedQueryAction &
    ToggleActionMenuAction &
    UpdateUsersSortDirectionAction &
    UpdateUsersSortByAction &
    ToggleIncludeTestUsersAction &
    UpdateUsersInstitutionIdAction &
    Action
}
