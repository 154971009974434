import * as React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'accessible-react-toolbox/lib/layout'
import * as ErrorReporter from 'app/frontend/helpers/error-reporter'
import NavAppBar, { NavAppBarTheme } from './nav-app-bar'
import NavAppLoginBar from './nav-app-login-bar'
import NavDrawer from './nav-drawer'
import './material-base.css'
import './learnosity.css'
import './service-cloud.css'
import LearnGlobals from './globals'
import * as CurrentUser from 'app/frontend/helpers/current-user'
import ConnectionErrorModal from 'app/frontend/components/modals/connection-error'
import {
  getIsNewWileyPlus,
  getIsAnLms,
  getInIFrame,
} from 'app/frontend/pages/material/learn/ui/ui-reducer'
import { getIsRootPageHiddenFromScreenReader } from './material-selectors'
import { MaterialControllerState } from './material-controller'
import { BackToTopPortal } from './back-to-top'
import { InternalShortcuts } from './internal-shortcuts'
import * as styles from './material-layout.css'
import { Knewcons } from 'app/frontend/components/knewcons/knewcons'
import { EulaUpdateBanner } from 'app/frontend/compositions/ux/eula-update-banner/eula-update-banner'
import {
  EULA_DESCRIPTION,
  EULA_URL,
  EULA_EFFECTIVE_DATE,
} from 'app/frontend/compositions/ux/eula-update-banner/eula-data'
import { MaterialBanners } from './material-banners'

type OwnProps = React.PropsWithChildren<{
  NavDrawerContent?: React.ComponentType
  className?: string
  showNavBar?: boolean
  navBarTheme?: NavAppBarTheme
  useGlobalNavConfig?: boolean
  navLinks?: {
    label: string
    path: string
    dataBi: string
  }[]
  banners?: React.ReactNode
}>

interface StateProps {
  hideRootFromScreenReader: boolean
  isNewWileyPlus: boolean
  isAnLms: boolean
  isInIFrame: boolean
}

interface DispatchProps {
  showFFlipModal: () => void
}

type Props = StateProps & DispatchProps & OwnProps

export class MaterialLayout extends React.Component<Props, {}> {
  static defaultProps: Partial<OwnProps> = {
    className: '',
  }

  mainContentLink: HTMLElement

  componentDidMount() {
    // track client side errors
    ErrorReporter.init()
  }

  onMainContentLinkClicked = () => {
    const main = this.getMainContentElement()
    if (main) {
      main.focus()
    }
  }

  getMainContentElement = (): HTMLElement => {
    const tags = document.getElementsByTagName('main')
    if (tags && tags.length > 0) {
      return tags[0] as HTMLElement
    }
  }

  /**
   * Only show the main content link if main is defined in the page
   */
  onMainContentLinkBlur = () => {
    this.mainContentLink.classList.remove(styles.linkVisible)
  }

  onMainContentLinkFocus = () => {
    if (this.getMainContentElement()) {
      this.mainContentLink.classList.add(styles.linkVisible)
    }
  }

  render() {
    const {
      NavDrawerContent,
      navBarTheme,
      className,
      hideRootFromScreenReader,
      useGlobalNavConfig,
      navLinks,
      isNewWileyPlus,
      isAnLms,
      isInIFrame,
      banners,
    } = this.props
    // On mobile, LMS launch in new tab and on tis case make logo clickable and
    // show nav bar.
    const clickableLogo = !(isAnLms && isInIFrame)
    const showNavBar = !!NavDrawerContent && !(isAnLms && isInIFrame)

    return (
      <>
        <MaterialBanners>
          {CurrentUser.isSet() && !CurrentUser.isTestUser() && (
            <EulaUpdateBanner
              description={EULA_DESCRIPTION}
              eulaUrl={EULA_URL}
              effectiveDate={EULA_EFFECTIVE_DATE}
            />
          )}
          {banners}
        </MaterialBanners>
        <Layout className={styles.printableMode}>
          <Knewcons />
          {CurrentUser.isVerifiedEmployee() && <InternalShortcuts />}
          <ConnectionErrorModal />
          <div className={`material-layout ${className}`} aria-hidden={hideRootFromScreenReader}>
            <div className={styles.mainContentLinkContainer}>
              <a
                role="button"
                href="#"
                id="skip-to-main" // used by renderBackToTop
                ref={e => (this.mainContentLink = e)}
                onFocus={this.onMainContentLinkFocus}
                onBlur={this.onMainContentLinkBlur}
                onClick={this.onMainContentLinkClicked}
                className={styles.mainContentLink}
              >
                Skip to main content
              </a>
            </div>
            {showNavBar && <NavDrawer Content={NavDrawerContent} />}
            {this.props.showNavBar && !isNewWileyPlus && (
              <NavAppBar
                showMenu={showNavBar}
                theme={navBarTheme}
                useGlobalNavConfig={useGlobalNavConfig}
                navLinks={navLinks}
                clickableLogo={clickableLogo}
              />
            )}
            {CurrentUser.isLoginAs() && <NavAppLoginBar />}
            <div className="container">{this.props.children}</div>
            <LearnGlobals />
          </div>
          <BackToTopPortal />
        </Layout>
      </>
    )
  }
}

function mapStateToProps(state: MaterialControllerState): StateProps {
  return {
    isNewWileyPlus: getIsNewWileyPlus(state as any),
    isAnLms: getIsAnLms(state as any),
    isInIFrame: getInIFrame(state as any),
    hideRootFromScreenReader: getIsRootPageHiddenFromScreenReader(state),
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(MaterialLayout)
