import * as React from 'react'
import { t } from 'app/frontend/helpers/translations/i18n'
import * as styles from './register.css'

interface Props {
  forDistributor?: boolean
}

const LegalLinks: React.FunctionComponent<Props> = ({ forDistributor }) => {
  const linkStyle = forDistributor ? styles.distributorRegisterTextLink : styles.registerTextLink

  return (
    <div>
      <p className={styles.registerText}>
        {forDistributor ? t('distributor_signin:legal_text') : t('registration_page:legal_text')}
        {forDistributor && (
          <>
            <a
              href="https://www.knewton.com/resources/terms-of-service-order-form"
              className={linkStyle}
              data-bi="orders-terms-link"
            >
              {t('distributor_signin:terms_of_service_order_form')}
            </a>{' '}
          </>
        )}
        <a
          href="https://www.knewton.com/terms-of-service"
          className={linkStyle}
          data-bi="terms-link"
        >
          {t('registration_page:terms_of_service')}
        </a>
        {t('registration_page:and')}
        <a
          href="https://www.knewton.com/privacy-policy"
          className={linkStyle}
          data-bi="privacy-policy-link"
        >
          {t('registration_page:privacy_policy')}
        </a>
        .
      </p>
    </div>
  )
}

LegalLinks.displayName = 'LegalLinks'
export default LegalLinks
