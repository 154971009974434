import * as GET_ASSESSMENT_QUESTIONS_FOR_ASSESSMENTS from './get-assessment-questions-for-assessments.gql'
import { RenderProps } from 'app/frontend/helpers/apollo/adopt'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'

export type UseAssessmentQuestionsForAssessmentsResults = RenderProps<{
  assessmentQuestionsForAssessments?: GQL.AssessmentSequenceListType[]
}>
export const useAssessmentQuestionsForAssessments = (
  assessmentIds: string[],
  // to resolve concurrency problem, we need to make this query as `cache-and-network` to sync
  // with the server, if we don't apply this here and if another instructor do any modifications
  // to assessment questions, those will not be reflected in the TOC if the current user move
  // to `Edit`
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
): UseAssessmentQuestionsForAssessmentsResults => {
  const { loading, error, data } = useQuery<
    GQL.GetAssessmentQuestionsForAssessments.Query,
    GQL.GetAssessmentQuestionsForAssessments.Variables
  >(GET_ASSESSMENT_QUESTIONS_FOR_ASSESSMENTS, {
    variables: { assessmentIds },
    fetchPolicy,
    nextFetchPolicy: 'cache-first', // TODO ALPACA-759
  })
  return {
    loading,
    error,
    assessmentQuestionsForAssessments: data?.getAssessmentSequencesForAssessments,
  }
}
