import { Reducer } from 'redux'
import { UPDATE_HISTORY_STATES, UpdateHistoryStatesAction } from '../entities-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'

export interface IHistoryReducer {
  byAssignmentId: {
    [pathId: string]: Learn.IStateAndContent[]
  }
}

export const getHistoryByAssignmentId = (
  state: LearnControllerState,
  assignmentId: string
): Learn.IStateAndContent[] => {
  const compounds = state.entities.historyStates.byAssignmentId[assignmentId]
  return compounds || null
}

export const reducer = (
  state: Partial<IHistoryReducer> = { byAssignmentId: {} },
  action: UpdateHistoryStatesAction
): Partial<IHistoryReducer> => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case UPDATE_HISTORY_STATES:
      return {
        byAssignmentId: {
          ...state.byAssignmentId,
          [action.assignmentId]: action.historyStates,
        },
      }
    default:
      return state
  }
}

export default reducer as Reducer<IHistoryReducer>
