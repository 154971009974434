import { makeApiActions } from 'app/frontend/api/api-redux'

export const API_GET_ENROLLMENT_CONTENT = makeApiActions('API_GET_ENROLLMENT_CONTENT')
export const API_GET_NEXT_ENROLLMENT_CONTENT = makeApiActions('API_GET_NEXT_ENROLLMENT_CONTENT')
export const API_GET_LEARN_FLOW = makeApiActions('API_GET_LEARN_FLOW')
export const API_GET_INSTRUCTION_BY_CONCEPT = makeApiActions('API_GET_INSTRUCTION_BY_CONCEPT')
export const API_GET_HISTORY_CONTENT = makeApiActions('API_GET_HISTORY_CONTENT')
export const API_SEND_LEARNING_EVENTS = makeApiActions('API_SEND_LEARNING_EVENTS')
export const API_GET_SEQUENCE_PREVIEW = makeApiActions('API_GET_SEQUENCE_PREVIEW')
export const API_LEARN_CHEAT = makeApiActions('API_LEARN_CHAT')
export const API_GET_ASSIGNMENT = makeApiActions('API_GET_ASSIGNMENT')
export const API_GET_BOOSTERS_FOR_DEMO = makeApiActions('API_GET_BOOSTERS_FOR_DEMO')

export type LEARN_FLOW_LOAD = 'LEARN_FLOW_LOAD'
export const LEARN_FLOW_LOAD: LEARN_FLOW_LOAD = 'LEARN_FLOW_LOAD'
export type ENROLLMENT_COMPLETE = 'ENROLLMENT_COMPLETE'
export const ENROLLMENT_COMPLETE: ENROLLMENT_COMPLETE = 'ENROLLMENT_COMPLETE'
export type GET_NEXT_ENROLLMENT_CONTENT = 'GET_NEXT_ENROLLMENT_CONTENT'
export const GET_NEXT_ENROLLMENT_CONTENT: GET_NEXT_ENROLLMENT_CONTENT =
  'GET_NEXT_ENROLLMENT_CONTENT'
export type GET_SEQUENCE_PREVIEW = 'GET_SEQUENCE_PREVIEW'
export const GET_SEQUENCE_PREVIEW: GET_SEQUENCE_PREVIEW = 'GET_SEQUENCE_PREVIEW'
export type GET_HISTORY_CONTENT = 'GET_HISTORY_CONTENT'
export const GET_HISTORY_CONTENT: GET_HISTORY_CONTENT = 'GET_HISTORY_CONTENT'
export type LEARN_CHEAT = 'LEARN_CHEAT'
export const LEARN_CHEAT: LEARN_CHEAT = 'LEARN_CHEAT'
export type UPDATE_CONTENT = 'UPDATE_CONTENT'
export const UPDATE_CONTENT: UPDATE_CONTENT = 'UPDATE_CONTENT'

export type DEMO_BOOSTER = 'DEMO_BOOSTER'
export const DEMO_BOOSTER: DEMO_BOOSTER = 'DEMO_BOOSTER'
export type UPDATE_DEMO_BOOSTERS = 'UPDATE_DEMO_BOOSTERS'
export const UPDATE_DEMO_BOOSTERS: UPDATE_DEMO_BOOSTERS = 'UPDATE_DEMO_BOOSTERS'

export type LearnFlowLoadAction = {
  type: LEARN_FLOW_LOAD
  pathId: string
  courseId: string
}
export const learnFlowLoad = (pathId: string, courseId: string): LearnFlowLoadAction => {
  return {
    type: LEARN_FLOW_LOAD,
    pathId,
    courseId,
  }
}

export type AgencyOptions = {
  learningObjectiveId?: string
  topicId?: string
}
export type GetNextEnrollmentContentAction = {
  type: GET_NEXT_ENROLLMENT_CONTENT
  pathId: string
  practice: boolean
  /**
   * Agency remediation options for
   * the next content.
   */
  agencyOptions?: AgencyOptions
  /**
   * Calls the agency endpoint which will also
   * update the cache.
   */
  updateAgency?: boolean
}
export const getNextEnrollmentContent = (
  pathId: string,
  practice: boolean,
  agencyOptions?: AgencyOptions,
  updateAgency?: boolean
): GetNextEnrollmentContentAction => {
  return {
    type: GET_NEXT_ENROLLMENT_CONTENT,
    pathId,
    practice,
    agencyOptions,
    updateAgency,
  }
}

export type EnrollmentCompleteAction = {
  type: ENROLLMENT_COMPLETE
  response: {
    enrollment: Commons.IEnrollment
  }
}
export function enrollmentComplete(enrollment: Commons.IEnrollment): EnrollmentCompleteAction {
  return {
    type: ENROLLMENT_COMPLETE,
    response: { enrollment },
  }
}

export type GetSequencePreviewAction = {
  type: GET_SEQUENCE_PREVIEW
  sequenceId: string
  atomId: string
  variationId: string
}
export const getSequencePreview = (
  sequenceId: string,
  atomId: string,
  variationId: string
): GetSequencePreviewAction => {
  return {
    type: GET_SEQUENCE_PREVIEW,
    sequenceId,
    atomId,
    variationId,
  }
}

export type GetHistoryContentAction = {
  type: GET_HISTORY_CONTENT
  sequenceId: string
}
export const getHistoryContent = (sequenceId: string): GetHistoryContentAction => {
  return {
    type: GET_HISTORY_CONTENT,
    sequenceId,
  }
}

export type LearnCheatAction = {
  type: LEARN_CHEAT
  sequenceId: string
  atomId: string
  variationId: string
}
export const learnCheat = (
  sequenceId: string,
  atomId: string,
  variationId: string
): LearnCheatAction => {
  return {
    type: LEARN_CHEAT,
    sequenceId,
    atomId,
    variationId,
  }
}

export type NewContent = {
  compounds: Learn.IStateAndContent[]
  history: Commons.IHistory
  analytics?: Analytics.IAnalytics
  historySequenceInstanceId?: string
  goalCompleted?: boolean
  boosterAssignments?: {
    stuckLo: string
    boosters: Commons.IPath[]
  }
}
export type UpdateContentAction = {
  type: UPDATE_CONTENT
  content: NewContent
  assignmentId: string
}
export function updateContent(content: NewContent, assignmentId: string): UpdateContentAction {
  return {
    type: UPDATE_CONTENT,
    content,
    assignmentId,
  }
}

export type DemoBoosterAction = {
  type: DEMO_BOOSTER
  loId: string
}

export function demoBooster(loId: string): DemoBoosterAction {
  return {
    type: DEMO_BOOSTER,
    loId,
  }
}

export type NewDemoBoosters = {
  stuckLo: string
  boosters: Commons.IPath[]
}

export type UpdateDemoBoostersAction = {
  type: UPDATE_DEMO_BOOSTERS
  boosterAssignments: NewDemoBoosters
}

export function updateDemoBoosters(
  loId: string,
  boosters: Commons.IPath[]
): UpdateDemoBoostersAction {
  return {
    type: UPDATE_DEMO_BOOSTERS,
    boosterAssignments: {
      stuckLo: loId,
      boosters,
    },
  }
}
