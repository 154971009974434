import { API_PAYMENT_INIT_SITE_NAME } from 'app/frontend/pages/material/learn/modals/payments/actions'

export type PaymentsState = {
  chargebeeInit?: boolean
}

export const reducer = (state: PaymentsState = {}, action): PaymentsState => {
  switch (action.type) {
    case API_PAYMENT_INIT_SITE_NAME.SUCCESS:
      return {
        ...state,
        chargebeeInit: true,
      }
    default:
      return state
  }
}

export default reducer
