import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SequenceQuery } from 'app/frontend/compositions/connected/get-sequence/get-sequence'
import { AtomVariation } from 'app/frontend/compositions/data/question-viewer/atom-variation/atom-variation'
import { AtomVariationWithHeaderLoading } from 'app/frontend/components/loading/atom-variation-loading'
import { AtomVariationButtonActionsType } from 'app/frontend/helpers/atom-variation'
import { ParentEntityType, ResultOverrideType } from 'app/typings/commons'
import LazyLoad from 'react-lazyload'
import { QuestionViewPreferencesOptionsViewDensity } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/helper'
import { LearningObjectivesToAssignmentsMap } from 'app/frontend/pages/material/teach/helpers/assignments-by-learning-objective-id'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import { setQuestionDisplayDensity } from 'app/frontend/pages/material/teach/assessment-builder/assessment-body/question-view-preferences/question-view-preferences-action'
import { getDisplayDensity } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-reducer'
import { QuestionCard } from './question-card'

export interface OwnProps {
  assessmentId?: string
  sequenceId: string
  sequenceAtomId?: string
  pathSequenceVariationId?: string
  includeExplanationAndAnswers: boolean
  parentEntityType?: ParentEntityType
  sequenceVariationsUsed?: number
  className?: any
  actionBar?: React.ComponentType<AtomVariationButtonActionsType>

  // Use a render prop to render the action bar, useful if you need to pass props down from a closure
  // The downside of using this renderProp is that it may defeat having React.memo on the QuestionViewer
  // since in most cases we will define a function that will be recreated on each render of the parent component.
  // See https://reactjs.org/docs/render-props.html#be-careful-when-using-render-props-with-reactpurecomponent
  // Prefer using actionBar if you do not need any props/states from the parent component
  renderActionBar?: (props: AtomVariationButtonActionsType) => React.ReactNode

  isAssessmentOffline?: boolean
  isAssessmentLdb?: boolean
  minVariationCount?: number
  override?: ResultOverrideType
  overrideValue?: number
  customHeading?: JSX.Element
  lazyLoad?: boolean
  displayLearningObjectiveBar?: boolean
  displayDensity?: QuestionViewPreferencesOptionsViewDensity
  isDraggable?: boolean
  canUpdateDisplayDensity?: boolean
  isSequenceUsed?: boolean
  learningObjectives?: Commons.IPathLearningObjectiveLeanEntity[]
  learningObjective?: Partial<Content.ILearningObjective>
  chapterName?: string
  topicName?: string
  assignmentsByLearningObjectiveId?: LearningObjectivesToAssignmentsMap
  fromObjectivesModal?: boolean
  questionNumber?: number
  setCustomItemVariationLength?: (e: number) => void
}

type Props = OwnProps

export const _QuestionViewer: React.FunctionComponent<Props> = ({
  sequenceId,
  sequenceAtomId,
  className,
  minVariationCount,
  customHeading,
  lazyLoad,
  displayLearningObjectiveBar,
  canUpdateDisplayDensity,
  displayDensity,
  questionNumber,
  ...propsForAtomVariation
}) => {
  const dispatch = useDispatch()

  /**
   * Get the ViewDensity value from redux.
   *
   * NOTE: I have no idea why we also pass density into the component.
   */
  const densityView: any =
    useSelector((state: TeachControllerState) => getDisplayDensity(state, sequenceId)) ??
    displayDensity

  const toggleDisplayDensityForSequence = React.useCallback(() => {
    dispatch(
      setQuestionDisplayDensity(
        sequenceId,
        densityView === QuestionViewPreferencesOptionsViewDensity.COLLAPSED
          ? QuestionViewPreferencesOptionsViewDensity.EXPANDED
          : QuestionViewPreferencesOptionsViewDensity.COLLAPSED
      )
    )
  }, [sequenceId, densityView])

  const sequenceQueryComponent = (
    <SequenceQuery sequenceId={sequenceId}>
      {({ sequence, loading, error }) => {
        if (loading || error) {
          return <AtomVariationWithHeaderLoading />
        }

        const sequenceAtoms: Content.ISequenceAtom = sequenceAtomId
          ? sequence.atoms.find(atom => atom.id === sequenceAtomId)
          : sequence.atoms[0]

        const poolSize = sequenceAtoms?.variations ? sequenceAtoms.variations.length : 1
        const isRepeated = poolSize < minVariationCount
        return (
          <AtomVariation
            {...propsForAtomVariation}
            sequenceId={sequenceId}
            sequenceAtomId={sequenceAtoms.id}
            sequenceAtomVariationIds={sequenceAtoms.variations}
            isRepeated={isRepeated}
            customHeading={customHeading}
            displayLearningObjectiveBar={displayLearningObjectiveBar}
          />
        )
      }}
    </SequenceQuery>
  )

  return (
    <QuestionCard
      expanded={densityView === QuestionViewPreferencesOptionsViewDensity.EXPANDED}
      toggleState={canUpdateDisplayDensity && toggleDisplayDensityForSequence}
      includeSpaceForLearningObjectiveBar={displayLearningObjectiveBar}
      dataSequenceId={sequenceId}
      className={className}
      questionNumber={questionNumber}
    >
      {lazyLoad ? (
        <LazyLoad
          offset={window.innerHeight}
          debounce={500}
          placeholder={<AtomVariationWithHeaderLoading />}
        >
          {sequenceQueryComponent}
        </LazyLoad>
      ) : (
        sequenceQueryComponent
      )}
    </QuestionCard>
  )
}
_QuestionViewer.displayName = '_QuestionViewer'

export const QuestionViewer = React.memo(_QuestionViewer)
QuestionViewer.displayName = 'QuestionViewer'
