import { LoadingState } from 'app/frontend/pages/material/learn/ui/loading-state'

export enum QuizFlowLoadingActionType {
  UpdateLoading = 'UPDATE_LOADING',
}

export interface UpdateLoadingAction {
  type: QuizFlowLoadingActionType.UpdateLoading
  state: LoadingState
}

export const updateLoading = (state: LoadingState): UpdateLoadingAction => {
  return {
    type: QuizFlowLoadingActionType.UpdateLoading,
    state,
  }
}
