import * as React from 'react'
import GoogleOAuthButtonMaterial from 'app/frontend/components/material/google-oauth-button-material'
import { LoginAlert } from 'app/frontend/components/material/login-alert'
import * as classNames from 'classnames'

import * as styles from './google-oauth-widget.css'
import { t } from 'app/frontend/helpers/translations/i18n'

interface IGoogleOAuthWidgetProps {
  authContext: string
  google?: {
    error?: string
  }
  className?: string
  role?: string
  label?: string
}

const GoogleOAuthWidget: React.FunctionComponent<IGoogleOAuthWidgetProps> = ({
  google,
  authContext,
  className,
  role,
  label,
}) => {
  const isLogin = authContext === 'login'
  const isRegister = authContext === 'register'
  const hasGoogleError = google && google.error

  if (google && isLogin && !hasGoogleError) {
    return <LoginAlert error={t('no_matching_google_account')} />
  }

  if (isRegister && hasGoogleError) {
    return <LoginAlert error={t('Registration error')} />
  }

  if (hasGoogleError) {
    return <LoginAlert error={t('google_error')} />
  }

  return (
    <div className={classNames(styles.googleBtn, className)}>
      <GoogleOAuthButtonMaterial
        role={role}
        authUrl={window.kn.redir}
        authContext={authContext}
        label={label || t('sign_in_with_google')}
      />
    </div>
  )
}

GoogleOAuthWidget.displayName = 'GoogleOAuthWidget'

export default GoogleOAuthWidget
