import {
  setQuestionPreferencesAction,
  QuestionPreferencesAction,
} from 'app/frontend/pages/material/teach/assessment-builder/assessment-lo-selection-modal/question-preferences/question-preferences-action'
import { MIN_NUM_QUESTION_PER_LO } from 'app/frontend/data/question-preferences-constants'
import {
  QuestionPreference,
  QuestionPreferencesStatus,
} from 'app/frontend/data/question-preference-enum'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'

export interface QuestionPreferencesState {
  questionType: string
  count: number
  status: string
}

type RelevantActionType = setQuestionPreferencesAction

const defaultState = {
  questionType: QuestionPreference.STATIC,
  count: MIN_NUM_QUESTION_PER_LO,
  status: QuestionPreferencesStatus.NOT_APPLIED,
}
export const getQuestionPreferencesState = (
  state: TeachControllerState
): QuestionPreferencesState => state.ui.assessmentBuilder.questionPreferences

/**
 * Reducer for keeping track of question preference state in assessment builder
 * @param state - State that holds question preferences information (type/numQuestionPerLO)
 * @param action - Action that required to call for update question preferences state
 */
export function QuestionPreferencesReducer(
  state: QuestionPreferencesState = defaultState,
  action: RelevantActionType
): QuestionPreferencesState {
  switch (action.type) {
    case QuestionPreferencesAction.SetQuestionPreferences:
      return {
        questionType: action.questionType,
        count: action.numOfQuestionPerLo,
        status: QuestionPreferencesStatus.APPLIED,
      }
  }

  return state
}
