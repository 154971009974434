import * as React from 'react'
import { Field } from 'redux-form'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { RadioGroup } from 'app/frontend/components/radio-group'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Box } from 'app/frontend/components/material/box'
import { ViewResultsConfig } from 'app/typings/commons'
import { FormNames } from './helper'

const t = tns('teach:assessment_builder')

type Props = {
  className?: string
  isReadOnly: boolean
}

interface ViewResultsOption {
  label: string
  value: GQL.ViewResultsConfig
}

const viewResultsOptions: ViewResultsOption[] = [
  {
    label: t('settings_view_results_test_grade_label'),
    value: ViewResultsConfig.GRADE_ONLY,
  },
  {
    label: t('settings_view_results_graded_questions_label'),
    value: ViewResultsConfig.GRADE_AND_GRADED_QUESTIONS,
  },
  {
    label: t('settings_view_results_answers_label'),
    value: ViewResultsConfig.GRADE_AND_ANSWER_KEY,
  },
]

export class ViewResultsSetting extends React.Component<Props, {}> {
  renderField = (field: WrappedFieldPropsStub) => {
    const radioGroupLabel = (
      <Paragraph margin="none" weight="semibold">
        {t('settings_view_results_header')}
      </Paragraph>
    )

    return (
      <RadioGroup
        label={radioGroupLabel}
        name={FormNames.VIEW_RESULTS_CONFIG}
        radioGroupProps={{
          value: field.input.value,
          onChange: field.input.onChange,
        }}
        radioProps={viewResultsOptions}
        disabled={this.props.isReadOnly}
      />
    )
  }

  render() {
    return (
      <Box full="page" separator="top" pad="medium" data-test="view-result-setting">
        <Box pad={{ top: 'medium' }}>
          <Field name={FormNames.VIEW_RESULTS_CONFIG} component={this.renderField} />
        </Box>

        <Paragraph size="small" margin="small">
          {t('settings_view_results_description')}
        </Paragraph>
      </Box>
    )
  }
}
