import * as React from 'react'
import { RemoveAssessmentSequence } from 'app/frontend/pages/material/teach/compositions/data/remove-assessment-sequence/remove-assessment-sequence'
import { Box } from 'app/frontend/components/material/box'
import FeedbackMenu from 'app/frontend/components/feedback/feedback-menu'
import * as styles from './assessment-question-actions.css'
import { AtomVariationButtonActionsType, isPoolQuestion } from 'app/frontend/helpers/atom-variation'
import { RefreshAtomVariationButton } from 'app/frontend/compositions/ux/refresh-atom-variation-button/refresh-atom-variation-button'
import { SequenceResultOverrideButtons } from './sequence-result-override-buttons/sequence-result-override-buttons'
import { SaveStatusIndicator } from 'app/frontend/compositions/ux/save-status-indicator'
import { useAssessmentBuilderPermissionState } from 'app/frontend/pages/material/teach/compositions/connected/get-assessment-builder-permission-state/use-assessment-builder-permission-state'
import { Icon } from 'app/frontend/components/material/icon'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { useDispatch } from 'react-redux'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { AssessmentBuilderModalType } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_CHANGE_QUESTION,
  TEACH_SECTION_ASSESSMENT_CHANGE_QUESTION,
} from 'app/frontend/data/mixpanel-events'
import { useSequenceOverrides } from 'app/frontend/compositions/connected/get-sequence-overrides'

const t = tns('teach:assessment_questions')

export interface Props extends AtomVariationButtonActionsType {}

export const AssessmentQuestionActions: React.FunctionComponent<Props> = ({
  atom,
  assessmentId,
  sequenceId,
  pathSequenceVariationId,
  parentEntityType,
  onRefreshAtomVariation,
  learningObjectiveId,
  chapterName,
  topicName,
  loDescription,
  fromObjectivesModal,
}) => {
  const [{ isSaving, hasError }, setState] = React.useState({
    isSaving: false,
    hasError: false,
  })
  const dispatch = useDispatch()

  const {
    hasStudentStartedAssignment,
    isCourseAssignmentOnSection,
    hasEditPermission,
  } = useAssessmentBuilderPermissionState()

  const {
    sequenceOverridesMap,
    error: sequenceOverrideError,
    loading: sequenceOverrideLoading,
  } = useSequenceOverrides(
    assessmentId,
    !(!isCourseAssignmentOnSection && hasEditPermission && hasStudentStartedAssignment)
  )

  const onRemove = () => setState({ isSaving: true, hasError: false })
  const onRemoveComplete = () => setState({ isSaving: false, hasError: false })
  const onRemoveError = () => setState({ isSaving: false, hasError: true })

  const onChangeQuestion = () => {
    // send change question mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_CHANGE_QUESTION
        : TEACH_SECTION_ASSESSMENT_CHANGE_QUESTION,
      assessmentId
    )
    dispatch(
      showModal(AssessmentBuilderModalType.QuestionSelection, {
        assessmentId,
        learningObjectiveId,
        loDescription,
        chapterName,
        topicName,
        fromObjectivesModal,
      })
    )
  }

  return (
    <Box
      direction="row"
      full="page"
      alignItems="center"
      pad="medium"
      separator="top"
      justify="between"
    >
      <SaveStatusIndicator isSaving={isSaving} hasError={hasError} />
      <Box direction="row">
        {!isCourseAssignmentOnSection && hasEditPermission && !hasStudentStartedAssignment && (
          <Box margin={{ bottom: 'small' }}>
            <ButtonMaterial
              label={t('change_question')}
              theme="bordered"
              icon={<Icon name="icon-double-arrows" className={styles.changeQuestionIcon} />}
              onClick={onChangeQuestion}
              data-bi="change-question"
            />
          </Box>
        )}
        <FeedbackMenu
          learn={false}
          isQuiz={true}
          atom={atom}
          buttonMenuClassName={styles.actionButton}
        />
        {isPoolQuestion(atom) && (
          <RefreshAtomVariationButton onRefreshAtomVariation={onRefreshAtomVariation} />
        )}

        {!isCourseAssignmentOnSection && hasEditPermission && (
          <>
            {hasStudentStartedAssignment ? (
              !(sequenceOverrideError || sequenceOverrideLoading) && (
                <SequenceResultOverrideButtons
                  sequenceId={sequenceId}
                  override={sequenceOverridesMap && sequenceOverridesMap[sequenceId]}
                  assessmentId={assessmentId}
                />
              )
            ) : (
              <RemoveAssessmentSequence
                assessmentId={assessmentId}
                sequenceId={sequenceId}
                pathSequenceVariationId={pathSequenceVariationId}
                parentEntityType={parentEntityType}
                onRemove={onRemove}
                onRemoveComplete={onRemoveComplete}
                onRemoveError={onRemoveError}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
