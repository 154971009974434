import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import * as styles from './two-row-label-count.css'
import { TooltipIcon, TooltipWidth } from 'app/frontend/components/tooltip'

interface Props {
  label?: string
  count?: number
  className?: string
  dataTestLabel?: string
  dataTestCounter?: string
  tooltipWidth?: TooltipWidth
  warning?: React.ReactNode
}

export const TwoRowLabelCount: React.FunctionComponent<Props> = ({
  label,
  dataTestLabel,
  count,
  dataTestCounter,
  tooltipWidth,
  warning,
  className,
}) => {
  return (
    <Box direction="column" responsive={true} className={className} margin="small">
      <Paragraph
        size="small"
        weight="semibold"
        margin="none"
        uppercase={true}
        className={styles.label}
        data-test={dataTestLabel}
      >
        {label}
      </Paragraph>
      <Paragraph size="large" margin="none" data-test={dataTestCounter}>
        {count}
        {warning && (
          <TooltipIcon
            tooltip={{
              title: warning,
              placement: 'bottom',
            }}
            icon={{
              name: 'icon-warning',
              size: 'medium',
              className: styles.warningIcon,
            }}
            width={tooltipWidth}
            data-bi="questions-count-tooltip"
          />
        )}
      </Paragraph>
    </Box>
  )
}

TwoRowLabelCount.displayName = 'TwoRowLabelCount'
TwoRowLabelCount.defaultProps = {
  label: '',
  count: 0,
}
