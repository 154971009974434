import {
  API_START_QUIZ,
  API_GET_QUIZ_PREVIEW,
  QuizFlowActionType,
} from 'app/frontend/pages/material/learn/quiz-flow/quiz-flow-actions'
import { API_GET_COURSE } from 'app/frontend/pages/material/learn/learn-actions'
import { LearnControllerState } from 'app/frontend/pages/material/learn/learn-controller-reducer'
import { QuizFlowLoadingActionType } from './loading-actions'
import STATUS_CODES from 'app/brackend/status-codes'
import FRONTEND_CODES from 'app/frontend/api/codes'
import { LoadingState } from 'app/frontend/pages/material/learn/ui/loading-state'

export const getQuizFlowLoading = (state: LearnControllerState): LoadingState => {
  return state.ui.quizFlow.loading
}

export default (state: LoadingState = LoadingState.None, action: any): LoadingState => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case QuizFlowLoadingActionType.UpdateLoading:
      return action.state
    case QuizFlowActionType.StartQuiz:
      return LoadingState.Loading
    case API_START_QUIZ.SUCCESS:
    case API_GET_QUIZ_PREVIEW.SUCCESS:
      return LoadingState.Success
    case API_GET_COURSE.ERROR:
    case API_GET_QUIZ_PREVIEW.ERROR:
      const notFound =
        action.error.json && action.error.json.error.statusCode === STATUS_CODES.NOT_FOUND

      return notFound ? LoadingState.NotFound : LoadingState.Error
    case API_START_QUIZ.ERROR:
      if (
        action.error.json &&
        action.error.json.error.code === FRONTEND_CODES.QUIZ_AVAILABLE_IN_PRINT
      ) {
        return LoadingState.NotFound
      }
    default:
      return state
  }
}
