
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EnrollmentAttemptsForUserAndAssessmentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EnrollmentAttemptsForUserAndAssessment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assessmentId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attempts"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enrollmentId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"startedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"completedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"grade"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unadjustedGrade"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attemptNumber"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"maxNumAttempts"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userId"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":253}};
    doc.loc.source = {"body":"fragment EnrollmentAttemptsForUserAndAssessmentFields on EnrollmentAttemptsForUserAndAssessment {\n  assessmentId\n  attempts {\n    enrollmentId\n    startedAt\n    completedAt\n    grade\n    unadjustedGrade\n    attemptNumber\n  }\n  maxNumAttempts\n  userId\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
