import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { SyncReduxRouterReducerState } from 'layout/material/sync-redux-router'
import { TeachRouteParams } from './get-teach-routes'

export type TeachControllerState = {
  form: any
  shareUrl: string
  router?: SyncReduxRouterReducerState<TeachRouteParams>
}

const combiner = combineReducers({
  form: formReducer,
  shareUrl: (state = null) => state,
})

export default function teachReducer(state, action) {
  const combined = combiner(state, action)
  return {
    ...combined,
  }
}
