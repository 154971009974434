import * as React from 'react'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Box } from '@mui/material'
import { convertToPercent2Decimals } from 'app/frontend/helpers/percentage'
import * as styles from './question-points.css'

const t = tns('atom_variation')

interface QuestionPointsProps {
  points: number
  weight?: number
  className?: string
}

export const QuestionPoints: React.FunctionComponent<QuestionPointsProps> = ({
  points,
  weight,
  className,
}) => {
  const decimalToPercentage = convertToPercent2Decimals(weight)
  return (
    <Box
      component={'span'}
      letterSpacing={'0.0625rem'}
      flexDirection={'row'}
      fontSize={'0.75rem'}
      fontWeight={'600'}
    >
      <Box component={'span'} paddingLeft={'0.5rem'} paddingRight={'0.5rem'}>
        ·
      </Box>
      <Box component={'span'} flexDirection={'row'} className={className}>
        <span data-test="question-points">{t('points', { count: points })}</span>
        {decimalToPercentage !== '0' && (
          <span data-test="question-weight" className={styles.weight}>
            {t('question_weight', { weight: decimalToPercentage })}
          </span>
        )}
      </Box>
    </Box>
  )
}

QuestionPoints.displayName = 'QuestionPoints'
