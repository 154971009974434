import * as React from 'react'
import { Box } from 'app/frontend/components/material/box'
import Content from 'app/frontend/components/content'
import * as classNames from 'classnames'

const atomStyles = require('app/frontend/components/atom/atom.css')
const styles = require('./multi-choice.css')

export interface Props {
  answers: GQL.AtomMultiChoiceAnswer[]
  media: GQL.AtomMedia[]
  isMultiAnswer: boolean
  hideExplanation?: boolean
  hideCorrectAnswers?: boolean
}

export const MultiChoiceAnswers: React.FunctionComponent<Props> = ({
  answers,
  media,
  isMultiAnswer,
  hideExplanation,
  hideCorrectAnswers,
}) => {
  return (
    <div className={atomStyles.userContent}>
      {answers.map((answer, index) => {
        const checked = !hideCorrectAnswers && answer.answerType === 'correct'
        return (
          <div data-test="multi-choice-answers" className={styles.answer} key={index}>
            <Box direction="row" alignItems="center">
              {isMultiAnswer ? (
                <input type="checkbox" disabled={true} aria-checked={checked} checked={checked} />
              ) : (
                <input type="radio" disabled={true} aria-checked={checked} checked={checked} />
              )}
              <Content
                className={classNames(
                  styles.text,
                  atomStyles.userContent,
                  atomStyles.userContentMc
                )}
                html={answer.answer}
                media={media}
              />
              {answer.explanation && !hideExplanation && (
                <Content
                  data-test="multi-choice-answer-explanation"
                  className={classNames(styles.explanation, atomStyles.userContent)}
                  html={answer.explanation}
                  media={media}
                />
              )}
            </Box>
          </div>
        )
      })}
    </div>
  )
}

MultiChoiceAnswers.displayName = 'MultiChoiceAnswers'
