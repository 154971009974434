import * as React from 'react'
import {
  ByAuthor,
  SourceName,
  LicensedUnder,
  OpenStaxDownloadLinkOrNot,
  useLicenseInfoOrDefault,
} from './components'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('content_attribution')

interface Props {
  media: GQL.AtomMedia
}

const MediaAttribution: React.FunctionComponent<Props> = ({ media }) => {
  if (!media.metadata) {
    return null // just abort
  }

  const licenseInfo = useLicenseInfoOrDefault(media.metadata.license)
  if (licenseInfo.customAttributionComponent) {
    // customAttributionComponent does not apply to MediaAtom
    return null
  }

  return (
    <>
      <SourceName
        name={mediaTranslatedName[media.dataType] || t('media')}
        url={media.metadata.sourceUrl}
      />{' '}
      <ByAuthor name={media.metadata.authorName} /> <LicensedUnder license={licenseInfo} />
      {'. '}
      <OpenStaxDownloadLinkOrNot
        author={media.metadata.authorName}
        sourceUrl={media.metadata.sourceUrl}
      />
    </>
  )
}

const mediaTranslatedName = {
  VIDEO_LOCATION: t('video'),
  IMAGE_LOCATION: t('image'),
  DATASET: t('dataset'),
  DESMOS_GRAPH: t('desmos'),
  ALCHEMIE: t('alchemie'),
  EXTERNAL_URL: t('external_url'),
}

export default MediaAttribution
