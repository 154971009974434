import * as React from 'react'
import { connect } from 'react-redux'
import { THIRD_PARTY_COOKIE_MODAL } from './third-party-cookie-modal'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import ThirdPartyCookieModal from './third-party-cookie-modal'

export interface OwnProps {}
interface StateProps {
  isInFrame: boolean
}
interface DispatchProps {
  showThirdPartyModal: () => void
}

export type Props = OwnProps & StateProps & DispatchProps

export class ThirdPartyCookieChecker extends React.Component<Props, {}> {
  constructor(props) {
    super(props)
  }

  receiveMessage = (event: { data: string }): void => {
    if (event.data === 'MM:3PCunsupported') {
      this.props.showThirdPartyModal()
    }
    // If supported: 'MM:3PCsupported'
  }

  componentDidMount(): void {
    if (!this.props.isInFrame) {
      return
    }
    const iframe = document.createElement('iframe')
    iframe.src = 'https://s3.amazonaws.com/knewton-public/3rdpartycookiecheck-2/start.html'
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    window.addEventListener('message', this.receiveMessage, false)
  }

  componentWillUnmount(): void {
    if (!this.props.isInFrame) {
      return
    }
    window.removeEventListener('message', this.receiveMessage, false)
  }

  render(): JSX.Element {
    return <ThirdPartyCookieModal />
  }
}

export function mapStateToProps(state): StateProps {
  return {
    isInFrame: state.global.ui.inIFrame,
  }
}

export function mapDispatchToProps(dispatch): DispatchProps {
  return {
    showThirdPartyModal: () => dispatch(showModal(THIRD_PARTY_COOKIE_MODAL)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyCookieChecker)
