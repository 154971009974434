import * as React from 'react'
import { Link as RRDLink, LinkProps as RRDLinkProps } from 'react-router-dom'
import * as classnames from 'classnames/bind'
import { BoxContextProps, withBoxContext } from 'app/frontend/components/material/box/box'

import * as styles from './link.css'
const c = classnames.bind(styles)

interface Props extends RRDLinkProps, BoxContextProps {}

class _Link extends React.Component<Props, {}> {
  /**
   * Returns a css class name of the pattern `prefix-value`.  If an object is passed into
   * the value, the pattern is then `prefix-value.key-value.value`.
   */
  private classes(prefix: string, value: boolean | string | object): string[] {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [`${prefix}-${value}`]
    } else if (typeof value === 'object') {
      return Object.entries(value).map(([key, val]) => `${prefix}-${key}-${val}`)
    } else {
      return []
    }
  }
  render(): JSX.Element {
    const { children, className, background, ...otherProps } = this.props
    return (
      <RRDLink
        className={classnames(c(`default`, this.classes('context', { background })), className)}
        {...otherProps}
      >
        {children}
      </RRDLink>
    )
  }
}

export const Link = withBoxContext<_Link, Props>(_Link)
