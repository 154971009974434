/* TODO: The file is dupped from app/frontend/components/material/loading/circle-spinner.tsx for
decoupling the dependency. We need eliminate the duplication after moving content ui to a library.
*/
import * as React from 'react'
import * as _ from 'lodash'
import * as classNames from 'classnames'
import MaterialProgressBar, { ProgressBarProps } from 'accessible-react-toolbox/lib/progress_bar'
import * as styles from './circle-spinner.css'

interface ICircleSpinnerProps extends ProgressBarProps {}

export const CircleSpinner: React.FunctionComponent<ICircleSpinnerProps> = props => {
  return (
    <MaterialProgressBar
      type="circular"
      mode="indeterminate"
      className={classNames('circle-spinner', styles.circleSpinner, props.className)}
      {..._.omit(props, ['theme', 'className'])}
      theme={_.merge({ circle: styles.circleSpinnerTheme }, props.theme || {})}
    />
  )
}

CircleSpinner.displayName = 'CircleSpinner'

export default CircleSpinner
