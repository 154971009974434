import { sendEvent } from './mixpanel'
import { getCourseId, getSectionId } from 'app/frontend/helpers/url'

export function sendEventTeachAssessment(
  name: string,
  assessmentId: string,
  additionalProps: object = {}
): void {
  const props = { path_id: assessmentId, ...additionalProps }
  sendEventTeachGeneric(name, props)
}

export function sendEventTeachGeneric(name: string, additionalProps: object = {}) {
  try {
    const courseId = getCourseId()
    const sectionId = getSectionId()
    const courseOrSectionId = courseId ? { course_id: courseId } : { section_id: sectionId }

    sendEvent(name, {
      ...courseOrSectionId,
      url: window.location.href,
      ...additionalProps,
    })
  } catch (e) {
    console.error('Could not track teach generic event in mixpanel: ', e)
  }
}
