import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import * as CurrentUser from 'app/frontend/helpers/current-user'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './nav-app-login-bar.css'

interface NavAppLoginBarState {
  expanded: boolean
  visible: boolean
}

interface NavAppLoginBarProps {
  pathname?: string
}

export class NavAppLoginBar extends React.Component<NavAppLoginBarProps, NavAppLoginBarState> {
  private trigger: () => void
  private dismiss: () => void

  constructor(props, context) {
    super(props, context)
    this.state = {
      expanded: false,
      visible: true,
    }
    this.trigger = this.setVisibility.bind(this, true)
    this.dismiss = this.setVisibility.bind(this, false)
  }

  UNSAFE_componentWillReceiveProps(nextProps: NavAppLoginBarProps): void {
    if (nextProps.pathname !== this.props.pathname) {
      this.trigger()
    }
  }

  setVisibility = (visible: boolean): void => {
    this.setState({ visible })
  }

  toggleDetails = (): void => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const user = CurrentUser.get()
    const { id, institutions, email } = user

    if (!this.state.visible) {
      return null
    }

    return (
      <div className={styles.loginBar}>
        <div className={styles.loginBarContents}>
          <div className={styles.loginBarHeader}>
            <button
              className={styles.button}
              onClick={this.toggleDetails}
              title="View more user details"
            >
              <p>
                Currently logged in as: <br />
                <span className={styles.email}>{email}</span>
                <Icon name="icon-expand" className={styles.detailsIcon} />
              </p>
            </button>
            <button className={styles.dismiss} onClick={this.dismiss}>
              DISMISS
            </button>
          </div>
          {this.state.expanded && (
            <div className={styles.details}>
              <div>{id}</div>
              <div>{institutions.map(i => i.name).join(', ')}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state): NavAppLoginBarProps {
  return {
    pathname: _.get<string>(state.router, 'location.pathname', ''),
  }
}

export default connect<NavAppLoginBarProps, {}, {}>(mapStateToProps)(NavAppLoginBar)
