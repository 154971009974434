import * as actions from './forgot-password-actions'

const initialState = {
  confirmationScreen: false,
}

export default function forgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_SENT_EMAIL:
      return {
        ...state,
        confirmationScreen: true,
      }
    default:
      return state
  }
}
