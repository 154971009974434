import * as _ from 'lodash'
import { ADD_DISPLAYED_RESCUE_MODAL, RescueModalActions } from './actions'
import { API_GET_ENROLLMENT_CONTENT } from 'app/frontend/pages/material/learn/learn-flow/learn-flow-actions'
import { ANSWER_QUESTION_SUCCESS } from 'app/frontend/pages/material/learn/learn-flow/events/learn-flow-events-actions'
import { CompoundInstanceState } from 'app/typings/commons'

export interface RescueModalState {
  compoundInstance: Commons.ICompoundInstance
  questionsAnswered: number
  questionsAnsweredThisSession: number
  strugglingTargetLearningObjectives: Content.ILearningObjective[]
}

const reducer = (
  state: Partial<RescueModalState> = {},
  action?: RescueModalActions | ({ type: string } & any)
): Partial<RescueModalState> => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case API_GET_ENROLLMENT_CONTENT.SUCCESS:
      // compute the # of questions since the last modal was shown
      const history =
        (action.response.history && action.response.history.sequences) ||
        ([] as Commons.ICompletedSequenceData[])
      const questionsAnswered = _.orderBy(history, ['completedOn']).reduce(
        (sum: number, sequence: Commons.ICompletedSequenceData) => {
          const displayedModalTypes = sequence.displayedModalTypes || []
          if (
            displayedModalTypes.indexOf('STRUGGLING') !== -1 ||
            displayedModalTypes.indexOf('WORKING_TOO_LONG') !== -1
          ) {
            return 0
          }
          return sum + sequence.numAssessment
        },
        0
      ) as number
      return {
        questionsAnsweredThisSession: 0,
        ...state,
        questionsAnswered,
      }
    case ANSWER_QUESTION_SUCCESS:
      let compoundInstance

      if (action.compound) {
        compoundInstance = action.compound.compoundInstance
      }

      // Only increase the counts if the state of the compound is ANSWERED. Compound instances
      // can also have state ATTEMPTED and we do not want to count those.
      if (compoundInstance.state === CompoundInstanceState.ANSWERED) {
        return {
          ...state,
          compoundInstance,
          questionsAnswered: (state.questionsAnswered || 0) + 1,
          questionsAnsweredThisSession: (state.questionsAnsweredThisSession || 0) + 1,
        }
      } else {
        return state
      }
    case ADD_DISPLAYED_RESCUE_MODAL:
      return {
        ...state,
        questionsAnswered: 0,
        questionsAnsweredThisSession: 0,
      }
    default:
      return state
  }
}

export default reducer
