import { Action } from 'redux'
import { CoursepackSearchActions } from './coursepacks-search-actions'

const ActionTypes = CoursepackSearchActions.ActionTypes

export interface CoursepacksSearchState {
  query: string
  debouncedQuery: string
  sortBy: string
  sortOrder: string
  includeTestCoursepacks: boolean
}

const defaultState = {
  query: '',
  debouncedQuery: '',
  sortBy: 'NAME',
  sortOrder: 'ASC',
  includeTestCoursepacks: false,
}

export function reducer(state: CoursepacksSearchState = defaultState, action: Action & any) {
  switch (action.type) {
    case ActionTypes.UpdateCoursepacksQuery:
      return {
        ...state,
        query: action.query,
      }
    case ActionTypes.UpdateCoursepacksDebouncedQuery:
      return {
        ...state,
        debouncedQuery: action.query,
      }
    case ActionTypes.UpdateCoursepacksSortBy:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case ActionTypes.UpdateCoursepacksSortOrder:
      return {
        ...state,
        sortOrder: action.sortOrder,
      }
    case ActionTypes.ToggleIncludeTestCoursepacks:
      return {
        ...state,
        includeTestCoursepacks: !state.includeTestCoursepacks,
      }
    default:
      return state
  }
}
